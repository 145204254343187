import React from 'react';
import { SocialIcon } from 'react-social-icons';
import './shortRun-studentCards.css';

const ShortRunStudentCards = ({props}) => {
    const studentData = [
      {
        'name': 'Peyton Lee',
        'cardContent': 'Peyton is, among other things, a game developer, artist, microbusiness owner, and student at the University of Washington. He currently serves as one of foundry10’s VR testers, reviewing VR content for classroom use.',
        'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Short+Run/Peyton.jpg',
        'userImg': 'https://s3-us-west-2.amazonaws.com/f10-people/Re-edited+Team+Photos+230x300/Tim.jpg',
        'imgUrl': 'https://www.espn.com',
        // 'twitter': '',
        'instagram': 'https://www.instagram.com/peyton.a.lee',
        'website': 'https://www.etsy.com/shop/wolfboxshop',
        'websiteTitle': 'Peyton\'s Etsy Shop'
      },
      {
        'name': 'Matthew Grinnell',
        'cardContent': 'Matthew is an art student at the University of Washington. They’ve dabbled in making comics, animation, and video games, and was a VR game development intern at foundry10 in 2017.',
        'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Short+Run/Matthew.png',
        'userImg': 'https://s3-us-west-2.amazonaws.com/f10-people/Re-edited+Team+Photos+230x300/Tim.jpg',
        'imgUrl': 'https://www.espn.com',
        // 'twitter': 'https://twitter.com/teledoor24',
        'instagram': 'https://www.instagram.com/teledoor24',
        'website': 'https://gumroad.com/teledoor24',
        'websiteTitle': 'Matthew\'s Website'
      },
      {
        'name': 'Ellie Works',
        'cardContent': 'Ellie Works is a teenage artist living in the Seattle area. She works primarily in watercolor and gouache and fills her artwork with bold colors. Ellie hopes that in the future her passion for illustration and comics will take her far.',
        'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Short+Run/Ellie.png',
        'userImg': 'https://s3-us-west-2.amazonaws.com/f10-people/Re-edited+Team+Photos+230x300/Tim.jpg',
        'imgUrl': 'https://www.espn.com',
        // 'twitter': 'https://twitter.com/teledoor24',
        'instagram': 'https://www.instagram.com/eeorks',
        'website': 'https://www.foundry10.org',
        'websiteTitle': 'foundry10'
      },
      {
        'name': 'Alex Warn',
        'cardContent': 'Alex Warn is a PNW artist living in Seattle. She is working towards going to art school, but in the meantime enjoys making characters and stories to share with others.',
        'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Short+Run/selfportrait.png',
        'userImg': 'https://s3-us-west-2.amazonaws.com/f10-people/Re-edited+Team+Photos+230x300/Tim.jpg',
        'imgUrl': 'https://www.espn.com',
        // 'twitter': 'https://twitter.com/teledoor24',
        'instagram': 'https://www.instagram.com/mewkiki._',
        'website': 'https://www.foundry10.org',
        'websiteTitle': 'foundry10'
      }
    ];
    const socialIconStyles = {
      height: 30,
      width: 30,
      marginRight: 5,
      marginLeft: 5
    };
    const allStudentData = studentData.map((records, index) => (
      <li key={index}>
        <div class="shortRun-card">
          <div class="shortRun-card-image">
            <img src={records.cardImg} alt={records.name}/>
          </div>
          <div class="shortRun-card-content">
            <h4>{records.name}</h4>
            <div class="shortRun-card-text-student">
              <p>{records.cardContent}</p>
            </div>
            <div class="shortRun-social">
              <SocialIcon url={records.instagram} style={socialIconStyles}/>
              {/* <SocialIcon url={records.twitter} style={socialIconStyles}/> */}
            </div>
            <p>
              <a href={records.website} target="_blank" rel="noopener noreferrer" class="hashtag">{records.websiteTitle}</a>
            </p>
          </div>
        </div>
      </li>
    ));
  
    return (
      <div id="shortRun-studentCards-Container">
        <div class="everyday-section-title-shortRun">
          <h4>STUDENTS</h4>
        </div>
        <div class="shortRun-studentCards-content-container">
          <ul class="shortRun-studentCards-list">
            {allStudentData}
          </ul>
        </div>
      </div>
        
    );
  }
  
  export default ShortRunStudentCards;