import React, { Component } from 'react';
import './annualReport2021Dance-Overview.css';

export default class AnnualReportDanceOverview extends Component {
  render() {
    return(
      <div id="annualReport2021Dance-overview-container">
          <div className="annualReport2021Dance-overview-text-container">
            <h1>Dance Team</h1>
            <p>
            This past year was an exciting time of expansion for the Dance team at foundry10 as we shifted from a focus on just Hip Hop dance to overall movement and dance. The shift has enabled us to explore a variety of movement styles and more accurately represent the history and culture of Hip Hop within the larger context of dance. The dance community suffered greatly during the pandemic as many opportunities to dance were shut down. The cultural shifts in the past year as well as the quarantines presented us with opportunities to revamp our work to better meet the needs of the dance community. Whether in-person or virtually, we continue striving to do work that is both responsive to and supportive of the teaching artists, dance organizations, and, most importantly, youth in our dance community.
            </p>
          </div>
      </div>
    );
  }
}