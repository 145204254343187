import React, { Component } from 'react';
import {
  useQuery,
  gql
} from "@apollo/client";

// Get Last Segment
const segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
const last_segment = segment_str.split("/").pop();

//Get page header based on ID
const pageQuery = gql`
  query pageHeaderQuery($last_segment: [String]) {
    categories(slug: $last_segment) {
      title
      slug
      ... on audiences_Category { 
        customTitle
        blurb
        featuredImage {
          url
        }
      }
    }
  }
`
function Hero(slug) {
  const { data, loading, error } = useQuery(pageQuery, {
    variables: {last_segment}
  });
  if (loading) return null;
  if (error) return `Error! ${error}`;
  let bgImage = null;
  console.log(last_segment);
  // console.log(slug);
  console.log(data);
  const pageHeader = data.categories[0];
  //console.log(pageHeader.featuredImage[0].url);
  const blurb = pageHeader.blurb;
  //const bgImage = pageHeader.featuredImage[0];
  //const bgImage = pageHeader.featuredImage[0];
  if (pageHeader.featuredImage.length > 0) {
    //console.log('image here')
    bgImage = pageHeader.featuredImage[0];
  }


  let myStyle = {};

  let title;
  if (pageHeader.customTitle) {
    title = pageHeader.customTitle
  } else {
    title = pageHeader.title
  }
  const bg = bgImage ? 'bgImage' : 'orange';
  const withBlurb = blurb ? 'withBlurb' : '';
  const classes = `${bg} ${withBlurb}`

  // console.log(typeof(bgImage));
  // console.log(bgImage);
  // console.log(bgImage.url);
   // Set Image URL if availible
   
  if(bgImage !== null ) {
    //console.log('getting here');
    myStyle = {
      backgroundImage: `url(${bgImage.url})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }
  } else {
    myStyle = {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }
  }
  
  //console.log( myStyle );
  
  //let bgImage = pageHeader.featuredImage[0].url;
  //console.log(bgImage);
  return (
    <div id="heroContent" className={classes}>
      <div className="mainHeroContent" style={myStyle}>
        <h2>{title}</h2>
      </div>
       
       {/* <h3>
          {teamMember.teamMemberName}
        </h3>
        <div
            dangerouslySetInnerHTML={{
            __html: teamMember.teamMemberBio,
            }}
        ></div> */}
        <Blurb />
    </div>
  );
  

}

//This query has to address ALL Channels
function Blurb(slug) {
  const { data, loading, error } = useQuery(pageQuery, {
    variables: {last_segment}
  });
  if (loading) return null;
  if (error) return `Error! ${error}`;
 
  const blurb = data.categories[0].blurb;
  if(blurb) {
    return (
      
        <div className="blurb">
            <div className='container'>
              <div
                  dangerouslySetInnerHTML={{
                    __html:blurb,
                  }}
              ></div>
          </div>
        </div>
      
    );
  } else {
    return ( "" );
  }
  
}

export default class ThemeHeader extends Component {
  render() {
    return(
      <section id="hero">
        <Hero slug={last_segment}/>
          <div className="container">
            {this.props.blurb && (
              <Blurb slug={this.props.slug}/>
            )}
          </div>
        </section>
    );
  }
}