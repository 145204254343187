import React, { Component } from 'react';
import './researchRedesign-Header.css';

export default class ResearchRedesignHeader extends Component {
  render() {
    return(
      <div id="researchRedesign-header-container">
          <div className="researchRedesign-header-content-container">
            <h1>Research at foundry10</h1>
          </div>
      </div>
    );
  }
}
