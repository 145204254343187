import React, { Component } from 'react';
import AnnualReportDigitalAudioOverview from './annualReport2021DigitalAudio-Overview';
import AnnualReportDigitalAudioSpotlights from './annualReport2021DigitalAudio-Spotlights';
import AnnualReport2021ProgramLinks from '../annualReport2021ProgramLinks/AnnualReport2021-ProgramLinks';
import NewFooter from '../../../../pages-other/NewFooter';
import './annualReport2021DigitalAudio-Content.css';

export default class AnnualReportDigitalAudioContent extends Component {
  render() {
    return(
      <div id="annualReport2021DigitalAudio-content-container">
        <AnnualReportDigitalAudioOverview />
        <AnnualReportDigitalAudioSpotlights />
        <AnnualReport2021ProgramLinks />
        <NewFooter />
      </div>
    );
  }
}