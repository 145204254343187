import React, { Component } from 'react';
import './PhilanthropyDirector-Qualifications.css';

export default class PhilanthropyDirectorQualifications extends Component {
  render() {
    return(
      <div id="philanthropyDirectorQualifications-container">
        <div class="philanthropyDirectorQualifications-content-container">
          <ul class="philanthropyDirectorQualifications-list">
            <li>
                <div class="philanthropyDirectorQualifications-column">
                    <div class="philanthropyDirectorQualifications-content-title">
                        <h4>Qualifications</h4>
                        <h4>Experience & Education</h4>
                    </div> 
                    <ul>
                        <li>Bachelor's degree or equivalent and five years of relevant experience in areas related to: Education, Administration, Communications, Youth Development, Community Partnerships, Philanthropy, Social Sciences, Social Work or other similar fields.</li>
                        <li>Demonstrated experience in coordinating or managing philanthropic work is strongly preferred.</li>
                        <li>Experience with managing or overseeing a team.</li>
                        <li>Has experience in a collaborative, team-based office environment.</li>
                        <li>Experience in creative problem solving, project management, and self-directed work.</li>
                        <li>We work with a diverse population of students and adults and it is imperative that this role have experience successfully collaborating with a wide range of community members.</li>
                    </ul>
                </div>
            </li>
            <li>
                <div class="philanthropyDirectorQualifications-column">
                    <div class="philanthropyDirectorQualifications-content-title">
                        <h4>Qualifications</h4>
                        <h4>Skills & Knowledge</h4>
                    </div> 
                    <ul>
                        <li>Experience with challenges students and teachers face within the educational system.</li>
                        <li>Familiarity and comfort with database systems (e.g., Monday), Google Suite, and presentation software.</li>
                        <li>Self-starter who is comfortable and confident as an independent worker as well as collaborating in a team environment.</li>
                        <li>Comfortable with ambiguity and have experience creating solutions while navigating unknowns.</li>
                        <li>Thrives in thinking outside the box and stepping outside their comfort zone to learn new things.</li>
                        <li>Knowledge of general philanthropic processes, principles and theories, especially tailored to educational spaces.</li>
                        <li>Experiencing building and sustaining partnerships and relationships with schools, educators, organizations, and communities.</li>
                    </ul>
                </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}