import React, { Component } from 'react';
import VrStressTestIntro from './vrStressTest-Intro';
import VrStressTestAccordion from './vrStressTest-Accordion';
import NewFooter from '../../../../pages-other/NewFooter';
import './vrStressTest-Content.css';

export default class VrStressTestContent extends Component {
  render() {
    return(
      <div id="vrStressTest-content-container">
        <VrStressTestIntro />
        <VrStressTestAccordion />
        <NewFooter />
      </div>
    );
  }
}