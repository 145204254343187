import React from "react";
import './Scuba-Operations.css';

export default function ScubaOperations({ title, subtitle, dark, id }) {
  return (
    // <div className={"section" + (dark ? " section-dark" : "")}>
    <div className="scubaOperations-container">
      <div className="scubaOperations-content-container" id={id}>
        <div className="scubaOperations-final-content-container">
          <div className="scubaOperations-illustration">
            <h1>Click below to learn more!</h1>
            <a class="scubaOperations-link" href="/marine-science-careers/scuba-operations">
              <img src='https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/scuba1.png' alt='scuba operations illustration'/>
              <p class="scubaOperations-title">Meet Heidi & Jessie!</p>
              <div class="scubaOperations-cards-overlay"></div>
            </a>
          </div>
          <div className="scubaOperations-description">
              <h2>"Scuba Wrangler"</h2>
              <p>RELATED TITLES: DSO, Dive Manger, SCUBA Instructor</p>
              <p>From SCUBA equipment, to depth limits and decompression, breathing underwater comes with risks that must be carefully monitored. This job also comes with it’s fair share of of slinging heavy tanks of air, paperwork, repairing gear, and good fun times underwater.</p>
            <div className="scubaOperations-requirements">
              <h2>Basic Degree/Training Requirements</h2>
              <ul className="scubaOperations-iconList">
                <li>
                  <div class="scubaOperations-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/black-HS-Diploma.png" alt="high school diploma" />
                  </div>
                </li>
                <li>
                  <div class="scubaOperations-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/black-Undergrad-Cap.png" alt="undergrad degree" />
                  </div>
                </li>
                <li>
                  <div class="scubaOperations-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/black-SCUBA.png" alt="scuba certificate" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}