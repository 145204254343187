import React, { Component } from 'react';
import './AnnualReport-Team.css';
// Import Component Elements Here

export default class AnnualReportTeam extends Component {
  render() {
    return(
      <div id="annualReportTeam-container">
        <div class="annualReportTeam-content-container">
          <div class="annualReportTeam-whoWeAre-container">
            <h2>Who We Are & What We Do</h2>
            <p>foundry10 is a philanthropic educational research organization devoted to expanding the way people think about learning and creating direct value for youth.  We do this with an integrated approach that combines four key areas: research in both applied and controlled settings, youth programming, philanthropic giving, and accessible sharing of our findings.  We are excited to share what we have learned during the past year.</p>
          </div>
          <div class="annualReportTeam-symbiosis-container">
            <h2>The Symbiosis of</h2>
            <h4>RESEARCH - PROGRAMS - PHILANTHROPY - SHARING</h4>
            <p>By working in all four of these areas simultaneously, we are able to take a comprehensive approach to creating value for youth that extends and fuses ideas in interesting ways.  Each pillar supports the others, and this results in work that is both broadly applicable and academically meaningful.</p>
            
            {/* <div class="annualReportTeam-cardsList2-container"> */}
              <ul id="annualReportTeam-cardsList2" class="annualReportTeam-events-lists2">
                <h2>Our Values</h2>
                <li>
                  <div class="annualReportTeam-cards-container">
                    <h3>YOUTH-CENTERED</h3>
                    <p>We ground our work in youth voice and empower those who directly support learners.</p>
                  </div>
                </li>
                <li>
                  <div class="annualReportTeam-cards-container">
                    <h3>EXPANSIVE</h3>
                    <p>We explore and investigate a broader picture of possibility in learning and education.</p>
                  </div>
                </li>
               <li>
                  <div class="annualReportTeam-cards-container">
                    <h3>GROUNDED</h3>
                    <p>Our work is centered in research, and we use data to paint a human picture of learning.</p>
                  </div>
                </li>
                <li>
                  <div class="annualReportTeam-cards-container">
                    <h3>ACTIVE</h3>
                    <p>We put ideas to the test in applied settings with real youth to know what works and why.</p>
                  </div>
                </li>
                <li>
                  <div class="annualReportTeam-cards-container">
                    <h3>COMMUNITY RESPONSIVE</h3>
                    <p>We seek to understand community needs and follow their lead in building intuitive solutions.</p>
                  </div>
                </li>
              </ul>
            {/* </div> */}
          </div>
        </div>
      </div>
    );
  }
}