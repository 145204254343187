import React, { Component, useMemo } from "react";
import { useQuery, gql } from "@apollo/client";

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

//Set Categories
const queryParams = new URLSearchParams(window.location.search);
const cat = queryParams.get("cat");
const themeCat = queryParams.get("themeCat");
const themeCaingt = queryParams.get("themeCaingt");

let allCats = [cat, themeCaingt].join(",");
if (allCats == ",") {
  allCats = null;
}
//console.log(allCats);

// Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
let searchParam = params.filter;

// Set search param as empty in value not availible
if (!searchParam) {
  searchParam = "";
}

// Get Page content
const entriesQuery = gql`
  query pageQuery(
    $channel: [String]
    $limit: Int!
    $offset: Int!
    $filter: String!
    $themeCat: [QueryArgument]
  ) {
    entries(
      section: $channel
      limit: $limit
      offset: $offset
      search: $filter
      relatedTo: $themeCat
    ) {
      sectionHandle
      title
      slug
      url
      dateCreated @formatDateTime(format: "F j, Y")
      author {
        fullName
      }
      ... on stories_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        interestAreaTeam {
          ... on interestAreaTeam_BlockType {
            interestAreaTeamMember {
              title
            }
          }
        }
        researchExternalAuthors {
          ... on researchExternalAuthors_BlockType {
            researchExternalAuthorsAuthor
          }
        }
        themes {
          title
        }
        categories {
          title
        }
      }
      ... on resources_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        themes {
          title
        }
        resourceType {
          title
        }
      }
      ... on programs_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        themes {
          title
        }
        interestAreas {
          title
        }
        guidingQuestions {
          title
        }
        audiences {
          title
        }
      }
      ... on projects_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        themes {
          title
        }
        interestAreas {
          title
        }
        guidingQuestions {
          title
        }
        audiences {
          title
        }
      }
      ... on opportunities_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        themes {
          title
        }
        interestAreas {
          title
        }
        guidingQuestions {
          title
        }
      }
      ... on press_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        themes {
          title
        }
        audiences {
          title
        }
        interestAreas {
          title
        }
        guidingQuestions {
          title
        }
      }
    }
    entryCount(section: $channel, search: $filter, relatedTo: $themeCat)
  }
`;

const ArchiveCards = (channel) => {
  const searchFilter = searchParam;
  const { data, loading, error, fetchMore } = useQuery(entriesQuery, {
    variables: {
      channel: channel,
      offset: 0,
      limit: 10,
      filter: searchFilter,
      themeCat: allCats,
    },
  });
  const showLoadMore = useMemo(() => {
    if (data) {
      return data.entryCount > data.entries.length;
    }

    return false;
  }, [data]);

  const onLoadMore = () => {
    fetchMore({
      variables: {
        offset: data.entries.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          entries: [...prev.entries, ...fetchMoreResult.entries],
        });
      },
    });
  };

  if (loading) return null;
  if (error) return `Error! ${error}`;

  return (
    <div>
      {data.entries.map((block) => {
        let category = null;
        if (block.categories && block.categories.length) {
          category = block.categories[0].title;
        }

        return (
          <div className="archiveCard large">
            <div className="imageContainer">
              <img
                src={
                  block.featuredImage[0]
                    ? block.featuredImage[0].url
                    : "/news-archive-spacer.png"
                }
                alt={block.title}
              />
            </div>
            <div className="archiveListContent">
              {category && (
                <div className="cardCat">
                  <h5>{category}</h5>
                </div>
              )}
              {block.resourceType && (
                <div className="cardCat">
                  <h5>{block.resourceType[0].title}</h5>
                </div>
              )}
              <h3>
                <a href={block.url}>{block.title}</a>
              </h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: block.blurb,
                }}
              ></div>
            </div>
          </div>
        );
      })}
      {showLoadMore && (
        <button className="button loadMore" onClick={onLoadMore}>
          Load More
        </button>
      )}
    </div>
  );
};

const ThemeData = () => {
  const query = gql`
    query pageQuery($cat_id: [QueryArgument]) {
      categories(group: "themes", id: $cat_id) {
        title
      }
    }
  `;
  const { data, loading, error } = useQuery(query, {
    variables: {
      cat_id: cat,
    },
  });

  if (loading) {
    return <div></div>;
  }
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <React.Fragment>
      {data.categories.length > 0 && (
        <div>
          <p>You filtered for : {data.categories[0].title}</p>
        </div>
      )}
    </React.Fragment>
  );
};

const InterestData = () => {
  const query = gql`
    query pageQuery($cat_id: [QueryArgument]) {
      categories(group: "interestAreas", id: $cat_id) {
        title
      }
    }
  `;
  const { data, loading, error } = useQuery(query, {
    variables: {
      cat_id: cat,
    },
  });

  if (loading) {
    return <div></div>;
  }
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <React.Fragment>
      {data.categories.length > 0 && (
        <div>
          <p>You filtered for : {data.categories[0].title}</p>
        </div>
      )}
    </React.Fragment>
  );
};

const AudienceFilterData = () => {
  const query = gql`
    query pageQuery($cat_id: [QueryArgument]) {
      categories(group: "audiences", id: $cat_id) {
        title
      }
    }
  `;
  const { data, loading, error } = useQuery(query, {
    variables: {
      cat_id: cat,
    },
  });

  if (loading) {
    return <div></div>;
  }
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <React.Fragment>
      {data.categories.length > 0 && (
        <div>
          <p>You filtered for : {data.categories[0].title}</p>
        </div>
      )}
    </React.Fragment>
  );
};

const ProgramList = ({ channel }) => (
  <section id="archiveList">
    <div className="container">
      {cat && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "25px",
          }}
        >
          <div>
            <ThemeData />
            <InterestData />
            <AudienceFilterData />
          </div>
          <button
            className="button "
            style={{
              background: "transparent",
              color: "#007A8A",
              border: "1px solid",
              padding: "12px",
              lineHeight: "normal",
              fontSize: "13px",
              height: "40px",
            }}
            onClick={() =>
              window.location.replace(window.location.href.split("?")[0])
            }
          >
            Clear Filter
          </button>
        </div>
      )}
      <ArchiveCards channel={channel} />
    </div>
  </section>
);

export default ProgramList;
