import React, { Component } from 'react';
import './annualReport2021Trades-Overview.css';

export default class AnnualReportTradesOverview extends Component {
  render() {
    return(
      <div id="annualReport2021Trades-overview-container">
          <div className="annualReport2021Trades-overview-text-container">
            <h1>Trades Team</h1>
            <p>
            This year, the Trades Team has been actively working with local schools to ensure that trades education, which is hands-on by its very nature, could successfully transition to distance learning during the pandemic. We continued to expand our outreach and program support in new ways, including branching into the Culinary Arts education space as well as middle school trades education efforts. We also continued to offer scholarships, funds for tools, and funding to support certifications for students in the trades; assisting those students who were interested in continuing their education post-high school.
            </p>
          </div>
      </div>
    );
  }
}