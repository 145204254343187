import React, { Component } from 'react';
import './marineScienceCollaboration-Intro.css';

export default class MarineScienceCollaborationIntro extends Component {
  render() {
    return(
      <div id="marineScienceCollaboration-intro-container">
        <div class="marineScienceCollaboration-intro-content-container">
          <div class="marineScienceCollaboration-intro-bold">
            <h3>We all benefit from an environmentally mindful society.</h3>
          </div>
          <div class="marineScienceCollaboration-intro-normal">
            <p>We aim to invest in marine science programs, research, and philanthropy that empower youth to consider their impact on the world and take environmental action in their community.</p>
          </div>
        </div>
      </div>
    );
  }
}