import React from 'react';
import Grid from '@material-ui/core/Grid';

const FeaturedPerson = ({ block }) => {
  return (
    <>
      {block.featuredPerson && block.featuredPerson.length > 0 && (
        <section className='featuredPerson'>
          <div className='container'>
            {block.featuredPerson.map((featuredPerson, index) => (
              <div className="content">
                <Grid container spacing={40} key={index}>
                  <Grid item md={3} >
                    <img
                      className='featuredPersonImage'
                      src={featuredPerson.teamMemberImage[0] ? featuredPerson.teamMemberImage[0].url : '/news-archive-spacer-2.png'}
                      alt={featuredPerson.title}
                    />
                  </Grid>
                  <Grid className='featuredPersonContent' item md={8}>
                    <div class="teamMemberTitle">
                      <h2 className='text-large'>
                        {featuredPerson.title}
                      </h2>
                      {featuredPerson.teamMembersPronouns && (
                        <p className='text-body'>
                          {featuredPerson.teamMembersPronouns}
                        </p>
                      )}
                    </div>
                    {featuredPerson.teamMemberPosition && (
                      <p className='text-half-step_bold textTeal'>{featuredPerson.teamMemberPosition}</p>
                    )}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: featuredPerson.teamMemberBio,
                      }}
                    ></div>
                    <a className='text-small_strong text-uppercase' href={featuredPerson.url}>
                      Read More <i class="fa-solid fa-arrow-right"></i>
                    </a>
                  </Grid>
                </Grid>
              </div>
            ))}
          </div>
        </section>
      )}
    </>
  )
}

export default FeaturedPerson