import React, { useState } from 'react';
import './ResearchTopics-One.css';

function ResearchTopicsOne() {
    const [isActive, setIsActive] = useState(false);
    // const [hasMore, setHasMore] = useState(false);

    return (


            <div className="technologyMediaInformationLiteracy-researchTopicsOneAccordion-item">
                <div className="technologyMediaInformationLiteracy-researchTopicsOneAccordion-title" onClick={() => setIsActive(!isActive)}>
                    <div className="technologyMediaInformationLiteracy-researchTopicsOneAccordion-image">
                        <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Technology%2C+Media%2C+and+Information+Literacy/Research+Topics/IA+Team+Page+Icons_Digital+Tools+in+Learning+Environments+1.png" alt="three classmates using virtual reality headset"/>
                    </div>
                    <div className="technologyMediaInformationLiteracy-researchTopicsOneAccordion-text">
                        <h2 className="technologyMediaInformationLiteracy-researchTopicsOneAccordion-title-text">Digital Tools in Learning Environments</h2>
                        {/* <h3>{research.subtitle}</h3> */}
                    </div>
                    <i className={ isActive ?  `fa-solid fa-angle-up`:`fa-solid fa-angle-down`}></i>                  
                </div>
                {isActive && <div className="technologyMediaInformationLiteracy-researchTopicsOneAccordion-content">
                    <div className="technologyMediaInformationLiteracy-researchTopicsOneAccordion-description">
                        <p>The landscape of technology and information literacy is constantly changing due to new developments and innovative applications in both formal and informal educational settings. Often, these tools aren't initially designed for young learners in educational contexts. Nonetheless, students and teachers adapt them to enrich their learning experiences. Our goal is to collaborate with youth and K-12 educators to study the impact of integrating these resources into learning environments, including research on both conventional and emerging technologies.</p>
                    </div>  
                    <div className="technologyMediaInformationLiteracy-researchTopicsOneAccordion-descriptionTwo">
                        <h2>Guiding Questions:</h2>
                        <div className="technologyMediaInformationLiteracy-researchTopicsOneAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How are these tools and resources being used in K-12 classrooms and what are the impacts on both students and teachers?</p>
                        </div>
                        <div className="technologyMediaInformationLiteracy-researchTopicsOneAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>Can student and teacher experiences be used to inform and enhance future policy, practice, and tool/resource development?</p>
                        </div>
                    </div>
                    <div className="technologyMediaInformationLiteracy-researchTopicsOneAccordion-descriptionTwo">
                        <h2>Explore foundry10 Research and Resources Related to This Topic:</h2>
                        <div className="technologyMediaInformationLiteracy-researchTopicsOneAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p><a href="https://www.foundry10.org/resources/perspectives-on-the-value-of-adaptive-learning-technologies-in-k12-education" target="_blank" rel="noopener noreferrer">Perspectives On the Value of Adaptive Learning Technologies in K-12 Education (peer-reviewed publication)</a></p>
                        </div>
                        <div className="technologyMediaInformationLiteracy-researchTopicsOneAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p><a href="https://www.foundry10.org/research/teacher-attitudes-toward-technology" target="_blank" rel="noopener noreferrer">Teacher Attitudes Toward Technology</a></p>
                        </div>
                        <div className="technologyMediaInformationLiteracy-researchTopicsOneAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p><a href="https://www.foundry10.org/research/sensors-students-and-self" target="_blank" rel="noopener noreferrer">Exploring Knowledge, Self-Efficacy, and Interest Impact of Ocean Sensor Learning (peer-reviewed publication)</a></p>
                        </div>
                        <div className="technologyMediaInformationLiteracy-researchTopicsOneAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p><a href="https://www.foundry10.org/research/don%E2%80%99t-forget-to-assess:-how-teachers-check-for-new-and-deeper-learning-when-integrating-virtual-reality-in-the-classroom" target="_blank" rel="noopener noreferrer">Integrating Virtual Reality in the Classroom (peer-reviewed publication)</a></p>
                        </div>
                    </div>
                    {/* <div className="technologyMediaInformationLiteracy-researchTopicsOneAccordion-descriptionThree">
                        <h2>VR/AR</h2>
                        <div className="technologyMediaInformationLiteracy-researchTopicsOneAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How are young people using AR/VR as a tool to participate, create, and innovate?</p>
                        </div>
                        <div className="technologyMediaInformationLiteracy-researchTopicsOneAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How can VR be used as a training tool for soft skills (e.g., creative thinking skills, decision-making ability, interpersonal communication skills)? How do young people implement soft skills experienced through VR in non-virtual settings?</p>
                        </div>
                        <div className="technologyMediaInformationLiteracy-researchTopicsOneAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>We plan to use a variety of approaches to answer these questions such as: usability studies (measuring if the emerging technology is easy to use), feasibility studies (measuring if the emerging technology is feasible to use as intended within the classroom), and correlational studies (what relationship might exist between VR and soft skills attainment).</p>
                        </div>
                    </div> */}
                </div>}
            </div>

    );
}

export default ResearchTopicsOne;
