import React, { Component } from 'react';
import './annualReport2021Internships-Header.css';

export default class AnnualReportInternshipsHeader extends Component {
  render() {
    return(
      <div id="annualReport2021Internships-header-container">
        <div className="annualReport2021Internships-header-content">
          <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Subpages/Final+Header+Images/interns-8.png" alt="foundry10 annual report 2020-2021 internships team header" />
        </div>
      </div>
    );
  }
}