import React, { Component } from 'react';
import MarineEndlessBlueIntro from './marineEndlessBlue-Intro';
import MarineEndlessBlueBody from './marineEndlessBlue-Body';
import NewFooter from '../../../pages-other/NewFooter';
import './marineEndlessBlue-Content.css';

export default class MarineEndlessBlueContent extends Component {
  render() {
    return(
      <div id="marineEndlessBlue-content-container">
        <MarineEndlessBlueIntro />
        <MarineEndlessBlueBody />
        <NewFooter />
      </div>
    );
  }
}