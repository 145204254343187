import React, { Component } from 'react';
import './marineScienceCollaboration-Body.css';

export default class MarineScienceCollaborationBody extends Component {
  render() {
    return(
      <div id="marineScienceCollaboration-body-container">
        <div class="marineScienceCollaboration-body-content-container">
          <div class="interestArea-domain-title">
            <h2>Innovative Ocean Technology</h2>
          </div>
          <div class="autoResearch-womenInAuto-paragraph">
            <p>Technology is a key tool we at foundry10 use to introduce students to marine science. From basic pH sensors to complex ROV systems, innovative marine technologies continue to deepen our understanding of our water planet. Humans’ earliest expeditions to ocean trenches challenged prevailing assumptions that life could not exist in the deepest parts of the ocean, revealing entire alien ecosystems and previously undocumented energy sources. We are excited to expose students to the latest discoveries through after school or alternative community programs, connect professionals in the field to classrooms and learners of all ages, and invest in research and philanthropy that advance ocean technology for all.</p>
          </div>
          <div class="interestArea-domain-title">
            <h2>Climate Resilience</h2>
          </div>
          <div class="autoResearch-womenInAuto-paragraph">
            <p>Building climate resilience depends on engaging youth and their passion for the environment. From beach cleanups to organized advocacy campaigns, foundry10 seeks to support and empower local youth to respond to challenges facing our marine environment. We are actively seeking partners who share this vision, have connections to youth programs with a marine science focus, or have practical expertise in the realm of building climate resilience.</p>
          </div>
          <div class="interestArea-domain-title">
            <h2>Sustainable Seas</h2>
          </div>
          <div class="autoResearch-womenInAuto-paragraph">
            <p>There is a consumer movement for sustainability from seafood choices to recreational practices. We at foundry10 hope to bring a fresh perspective to the conversation through cross-disciplinary partnerships, multi-media endeavors, and virtual reality experiences like <a href="https://infinitytide.itch.io/bycatch-22" target="_blank" rel="noopener noreferrer">Bycatch-22</a>. Our foundry10 programs are constantly informed by relationships with students, educators, and community partners. Our marine science team is always open to new ideas and would love to hear from you at: ocean@foundry10.org</p>
          </div>
        </div>
      </div>
    );
  }
}