import React, { Component } from 'react';
import './annualReport2021Interventions-Spotlights.css';

export default class AnnualReportInterventionsSpotlights extends Component {
  render() {
    return(
      <div id="annualReport2021Interventions-spotlights-container">
        <div className="annualReport2021Interventions-spotlight-first-title-bar">
          <p>Spotlight: Mariner HS Life Coach Groups</p>
        </div>
        <div className="annualReport2021Interventions-spotlights-content-container">
          <div className="annualReport2021Interventions-spotlights-text-container">
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Subpages/lifecoach.jpg" alt="friends supporting each other in black teal and orange"/>
            <p>
            During the pandemic, we have heard from so many schools that there is a need <a href="https://medium.com/the-foundry10-voice/how-to-enrich-social-connections-in-the-virtual-classroom-f74203d4b23a" target="_blank" rel="noopener noreferrer">for more social support and peer connection</a>. For the past couple years, foundry10 has partnered with Mariner High School Student Success Coordinators in Mukilteo to offer Life Coach Groups for students who would benefit from additional focused emotional and academic support. We continued that effort this year, with coaches from <a href="https://www.mepowerment.org/" target="_blank" rel="noopener noreferrer">MePowerment U</a> and <a href="https://www.b4bu.com/" target="_blank" rel="noopener noreferrer">A Better Track</a> working in small groups as well as large seminars to offer mentoring and grow students’ social-emotional skills, confidence, and self-awareness. foundry10 also collaborated with teachers to develop a survey and collect data on students’ confidence, peer relationships, and connections to school before and after programming. This survey data will be analyzed to help our partners understand the impact of their work and better support the youth who attend this program. It will also help inform how we at foundry10 structure our programs to better mentor and support youth going forward.
            </p>
          </div>
        </div>
        <div className="annualReport2021Interventions-spotlight-second-title-bar">
          <p>Spotlight: Edmonds Schools Take Home Books</p>
        </div>
        <div className="annualReport2021Interventions-spotlights-content-container">
          <div className="annualReport2021Interventions-spotlights-text-container">
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Subpages/takehome+books.png" alt="teal books against an orange background"/>
            <p>
            We heard from educators and school administrators that one of the fundamental needs for younger learners was for them to have access to quality and culturally relevant reading materials while they were learning from home. foundry10 purchased over 350 teacher-selected books from Third Place Books and Edmonds Bookshop. foundry10 staff, teachers, and volunteers from the Foundation for Edmonds School District passed out books to students at meal sites or delivered them directly to students’ homes. We provided 42 students and their families with 2-3 books, a personal note from their teacher, and a tip sheet on questions to ask while reading at home with children provided by the Edmonds School District. The tip sheet was translated into the home language of each student. We <a href="https://medium.com/the-foundry10-voice/equity-considerations-for-running-a-take-home-book-program-during-a-pandemic-6c3525eb1044" target="_blank" rel="noopener noreferrer">wrote a blog post</a> to describe the process and considerations we thought through as we worked on this project that others can use to replicate aspects of this support program in their own communities.
            </p>
          </div>
        </div>
      </div>
    );
  }
}