import React, { Component } from 'react';
import BenefitsAccordion from './Benefits-Accordion';
import './Benefits.css'
import BenefitsData from './Benefits-Data';

export default class Benefits extends Component {

  
  render() {

    return(
        <div className="benefitsAccordion-section-container">
          <div className="benefitsAccordion-content-container">

            <h2><span>Benefits Highlights</span></h2>

            <div className="researchBenefits-accordion-container">
              <div className="researchBenefits-accordion-content">
                {BenefitsData.map(({ benefit }) => (
                  <BenefitsAccordion benefit={benefit} />
                  ))}
              </div>
          </div>

        </div>
    </div>
    );
  }
}