import React, { Component } from 'react';
import './oneC.css';

class ONEC extends Component {
  render() {
    return (
      <div id="oneC-container">
        <div class="oneC-content-container">
          <h2>It was all falling into place,</h2>
          <p>but one thing was still missing:</p>
        </div>
      </div>
    );
  }
}

export default ONEC;