import React from 'react';
import './creativityDesignPlay-Intro.css';

const CreativityDesignPlayIntro = () => {
   return (
    <div class="creativityDesignPlay-intro-container">
      <div class="creativityDesignPlay-intro-content-container">
        <div class="creativityDesignPlay-intro-paragraph">
          <p>Creativity and critical-thinking skills are essential for success in nearly every area of today’s complex and fast-paced world. Through a unique blend of research, educational programming, and philanthropy, foundry10 seeks to ensure young people have the space and tools they need to innovate, build creative abilities, and learn through play—both inside and outside of classrooms.</p>
        </div>
      </div>
    </div>
   )
}
export default CreativityDesignPlayIntro;