import React, { Component } from 'react';
import './newEquity-News.css';

export default class NewEquityNews extends Component {
  render() {
    return (
      <div className="newEquityNews-container">
        <div className="newEquityNews-content-container">
          <h2>Latest News</h2>
          <ul className="newEquityNews-cardsList">
            <a href="https://medium.com/the-foundry10-voice/hilltop-school-supporting-pregnant-and-parenting-students-4bce2825f197" target="_blank" rel="noopener noreferrer">
              <li>
                <div className="newEquityNews-card">
                  <div className="newEquityNews-card-image">
                    <img src='https://f10-equity.s3.us-west-2.amazonaws.com/Equity+Page+Images/Equity-page-thumbnail_Hilltop.jpg' width="100%" alt="Illustration of two young pregnant people interacting."/>
                  </div>
                  <div className="newEquityNews-card-content">   
                    <div className="newEquityNews-card-content-container">
                      <div className="newEquityNews-text">
                        <div className="newEquityNews-textOne">
                          <h2>Supporting Pregnant and Parenting Students</h2>
                        </div>
                        <div className="newEquityNews-textTwo">
                          <h3>October 23, 2023</h3>
                        </div>
                      </div>
                    </div>                 
                  </div>
                </div>
              </li>
            </a>
            <a href="https://medium.com/p/d2ea54a06d76" target="_blank" rel="noopener noreferrer">
              <li>
                <div className="newEquityNews-card">
                  <div className="newEquityNews-card-image">
                    <img src='https://f10-equity.s3.us-west-2.amazonaws.com/Equity+Page+Images/Sawhorse_Equity-Page_Thumbnail.jpg' width="100%" alt="Student using a buzz saw to cut materials."/>
                  </div>
                  <div className="newEquityNews-card-content">   
                    <div className="newEquityNews-card-content-container">
                      <div className="newEquityNews-text">
                        <div className="newEquityNews-textOne">
                          <h2>Youth at Sawhorse Revolution Build 3 New Community Projects</h2>
                        </div>
                        <div className="newEquityNews-textTwo">
                          <h3>September 5, 2023</h3>
                        </div>
                      </div>
                    </div>                 
                  </div>
                </div>
              </li>
            </a>
            <a href="https://medium.com/the-foundry10-voice/how-to-make-your-own-kind-of-music-in-library-makerspaces-451a095aada6" target="_blank" rel="noopener noreferrer">
              <li>
                <div className="newEquityNews-card">
                  <div className="newEquityNews-card-image">
                    <img src='https://f10-equity.s3.us-west-2.amazonaws.com/Equity+Page+Images/Equity-Page_Makerspace_Thumbnail.jpg' width="100%" alt="Jessica Hughes and Kate Falconer"/>
                  </div>
                  <div className="newEquityNews-card-content">   
                    <div className="newEquityNews-card-content-container">
                      <div className="newEquityNews-text">
                        <div className="newEquityNews-textOne">
                          <h2>How to Make Your Own Kind of Music in Library Makerspaces</h2>
                        </div>
                        <div className="newEquityNews-textTwo">
                          <h3>July 19, 2023</h3>
                        </div>
                      </div>
                    </div>                 
                  </div>
                </div>
              </li>
            </a>
          </ul>
        </div>
      </div>
    );
  }
}