import React, { Component } from 'react';
import './roboticsLandingPage-Header.css';

export default class RoboticsLandingPageHeader extends Component {
  render() {
    return(
      <div id="roboticsLandingPage-header-container">
          <div class="roboticsLandingPage-header-content-container">
            <h1>Robotics in the Classroom</h1>
          </div>
      </div>
    );
  }
}