import React, { Component } from 'react';
import './Error404Page-Header.css';

export default class Error404PageHeader extends Component {
  render() {
    return(
      <div id="error404Page-header-container">
          <div class="error404Page-header-content-container">
            <h1>Page Not Found</h1>
          </div>
      </div>
    );
  }
}