import React, { Component } from 'react';
import './fiveD.css';

export default class FIVED extends Component {
  render() {
    return (
      <div id="fiveD-container">
        <div class="fiveD-content-container">
          <a href="/student-work">
            <div class="fiveD-header-image">
              <h1>Gallery Artwork</h1>
              <img src="https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/EverydayUnseen-Gallery-Work-Tinted.JPG" alt="gallery artwork"/>
            </div>
          </a>
        </div>
      </div>
    );
  }
}