import React, { Component } from 'react';
import './careerConnectedLearningLifeSkills-Header.css';

export default class CareerConnectedLearningLifeSkillsHeader extends Component {
  render() {
    return(
      <div id="careerConnectedLearningLifeSkills-header-container">
          <div class="careerConnectedLearningLifeSkills-header-content-container">
            <h1>Career-Connected Learning and Life Skills</h1>
          </div>
      </div>
    );
  }
}