import React, { Component } from 'react';
import './Contact-Header.css';

export default class ContactHeader extends Component {
  render() {
    return(
      <div id="contact-header-container">
          <div class="contact-header-content-container">
            <h1>Contact Us</h1>
          </div>
      </div>
    );
  }
}