import React from 'react';
import { Helmet } from 'react-helmet';
import renderHTML from 'react-render-html';

const Seomatic = ({ data }) => {
    return (
        <Helmet>
            {renderHTML(data.metaTagContainer)}
            {renderHTML(data.metaTitleContainer)}
            {renderHTML(data.metaLinkContainer)}
            {renderHTML(data.metaJsonLdContainer)}
        </Helmet>
    );
}

export default Seomatic;