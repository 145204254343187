import React from "react";
import './Education-Outreach.css';

export default function EducationOutreach({ title, subtitle, dark, id }) {
  return (
    // <div className={"section" + (dark ? " section-dark" : "")}>
    <div className="educationOutreach-container">
      <div className="educationOutreach-content-container" id={id}>
        <div className="educationOutreach-final-content-container">
          <div className="educationOutreach-illustration">
            <h1>Click below to learn more!</h1>
            <a class="educationOutreach-link" href="/marine-science-careers/education-outreach">
              <img src='https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/educationoutreach1.png' alt='education outreach illustration'/>
              <p class="educationOutreach-title">Meet Joanne!</p>
              <div class="educationOutreach-cards-overlay"></div>
            </a>
          </div>
          <div className="educationOutreach-description">
            <h2>"Ocean Influencer"</h2>
            <p>RELATED TITLES: Outreach Coordinator, Community Outreach, Specialist, Outreach & Education Coordinator</p>
            <p>Educators can inspire future protectors and stewards of our oceans by reaching out to our communities and bringing us all together.</p>
            <div className="educationOutreach-requirements">
              <h2>Basic Degree/Training Requirements</h2>
              <ul className="educationOutreach-iconList">
                <li>
                  <div class="educationOutreach-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/white-HS-Diploma.png" alt="high school diploma" />
                  </div>
                </li>
                <li>
                  <div class="marineBiology-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/white-Undergrad-Cap.png" alt="undergrad degree" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}