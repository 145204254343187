import React, { Component } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import Tooltip from '@material-ui/core/Tooltip';
import './Navbar.css';

export default class Navbar extends Component {
  scrollToTop = () => {
    scroll.scrollToTop();
  };
  render() {
    return (
      <nav className="marineNav" id="marineNavbar">
        <div className="marineNav-content">
          <div class="marineNav-title">
              <h2>Explore the careers!</h2>
              {/* <p>Below are some of the many Marine Science Careers out there.</p> */}
            </div>
          <ul className="marineNav-items">
            <li className="marineNav-item">
              <Link
                activeClass="active"
                to="marineGeology"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <Tooltip title="Marine Geology" placement="top">
                  <div class="marineNav-careerButton">
                    <img src='https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/marinegeology1.png' alt='marine geology illustration'/>
                  </div>
                </Tooltip>
              </Link>
            </li>
            <li className="marineNav-item">
              <Link
                activeClass="active"
                to="biologicalOceanographer"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <Tooltip title="Oceanography" placement="top">
                  <div class="marineNav-careerButton">
                    <img src='https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/oceanography1.png' alt='oceanography illustration'/>
                  </div>
                </Tooltip>
              </Link>
            </li>
            <li className="marineNav-item">
              <Link
                activeClass="active"
                to="sharkResearch"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <Tooltip title="Shark Research" placement="top">
                  <div class="marineNav-careerButton">
                    <img src='https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/sharkresearch1.png' alt='shark research illustration'/>
                  </div>
                </Tooltip>
              </Link>
            </li>
            <li className="marineNav-item">
              <Link
                activeClass="active"
                to="veterinaryMedicine"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <Tooltip title="Veterinary Medicine" placement="top">
                  <div class="marineNav-careerButton">
                    <img src='https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/veterinarymed1.png' alt='veterinary medicine illustration'/>
                  </div>
                </Tooltip>
              </Link>
            </li>
            <li className="marineNav-item">
              <Link
                activeClass="active"
                to="scienceCommunication"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <Tooltip title="Science Communication" placement="top">
                  <div class="marineNav-careerButton">
                    <img src='https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/sciencecommunicator1.png' alt='science communication illustration'/>
                  </div>
                </Tooltip>
              </Link>
            </li>
            <li className="marineNav-item">
              <Link
                activeClass="active"
                to="professionalMerfolk"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <Tooltip title="Professional Merfolk" placement="top">
                  <div class="marineNav-careerButton">
                    <img src='https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/mermaiding1.png' alt='professional merfolk illustration'/>
                  </div>
                </Tooltip>
              </Link>
            </li>
            <li className="marineNav-item">
              <Link
                activeClass="active"
                to="oceanPolicy"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <Tooltip title="Ocean Policy" placement="top">
                  <div class="marineNav-careerButton">
                    <img src='https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/ocean+policy1.png' alt='ocean policy illustration'/>
                  </div>
                </Tooltip>
              </Link>
            </li>
            <li className="marineNav-item">
              <Link
                activeClass="active"
                to="artDesign"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <Tooltip title="Art & Design" placement="top">
                  <div class="marineNav-careerButton">
                    <img src='https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/art1.png' alt='art design illustration'/>
                  </div>
                </Tooltip>
              </Link>
            </li>
            <li className="marineNav-item">
              <Link
                activeClass="active"
                to="marineBiology"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <Tooltip title="Marine Biology" placement="top">
                  <div class="marineNav-careerButton">
                    <img src='https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/marinebiology1.png' alt='marine biology illustration'/>
                  </div>
                </Tooltip>
              </Link>
            </li>
            <li className="marineNav-item">
              <Link
                activeClass="active"
                to="scubaOperations"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <Tooltip title="Scuba Operations" placement="top">
                  <div class="marineNav-careerButton">
                    <img src='https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/scuba1.png' alt='scuba operations illustration'/>
                  </div>
                </Tooltip>
              </Link>
            </li>
            <li className="marineNav-item">
              <Link
                activeClass="active"
                to="submersibleOperations"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <Tooltip title="Submersible Operations" placement="top">
                  <div class="marineNav-careerButton">
                    <img src='https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/submersiveoperations1.png' alt='submersible operations illustration'/>
                  </div>
                </Tooltip>
              </Link>
            </li>
            <li className="marineNav-item">
              <Link
                activeClass="active"
                to="academia"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <Tooltip title="Academia" placement="top">
                  <div class="marineNav-careerButton">
                    <img src='https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/academia1.png' alt='academia illustration'/>
                  </div>
                </Tooltip>
              </Link>
            </li>
            <li className="marineNav-item">
              <Link
                activeClass="active"
                to="educationOutreach"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <Tooltip title="Education Outreach" placement="top">
                  <div class="marineNav-careerButton">
                    <img src='https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/educationoutreach1.png' alt='education outreach illustration'/>
                  </div>
                </Tooltip>
              </Link>
            </li>
            <li className="marineNav-item">
              <Link
                activeClass="active"
                to="oceanEngineering"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <Tooltip title="Ocean Engineering" placement="top">
                  <div class="marineNav-careerButton">
                    <img src='https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/oceanengineering1.png' alt='ocean engineering illustration'/>
                  </div>
                </Tooltip>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

