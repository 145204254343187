const QualityData = [
    {
        element: {
            title: 'Curriculum',
            subtitle: 'Can this software easily fit into your curriculum? Do you have a learning goal in mind when using this software?',
            img: 'https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/EdTech/VR+Software+Stress+Test/EdTech+VR+Stress+Test+Accordion+Icon-Curriculum.png',
            alt: 'pencil and clipboard with checklist',
            content: 'It is important to not stray away from your curriculum’s subject, even if a VR software seems interesting. Introducing and using the software can take a long amount of time, so you need to ensure that it fits into your curriculum’s schedule.',
            contentCont: 'Educators have much better results with implementing VR in their classes when they identify and use learning goals, so it is smart to think of potential learning goals before purchasing a software.'
        }
    },
    {
        element: {
            title: 'Learning',
            subtitle: 'Do you think the software can be used to better meet the individual needs of your students? Do you think spectators/people not in VR can also benefit from this software? Does the software effectively do what it is intended to do?',
            img: 'https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/EdTech/VR+Software+Stress+Test/EdTech+VR+Stress+Test+Accordion+Icon-Learning.png',
            alt: 'monitor displaying book icon',
            content: 'It takes a lot of energy and time to introduce a software into the classroom, so you need to make sure that your students’ learning will benefit from it. Really critique the software and make sure it will add to your students’ learning experiences before investing in it.',
            contentCont: 'You also need to consider the spectators, or the students watching other students use the software. You want their learning experiences to be heightened by the software as well. It is also vital that the information and teaching provided by the software is correct and valid–it doesn’t need to be perfect, but it cannot misinform your students.'
        }
    }
]

export default QualityData;