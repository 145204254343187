import React, { Component } from 'react';
import AnnualReportPhilanthropyOverview from './annualReport2021Philanthropy-Overview';
import AnnualReportPhilanthropyHighlights from './annualReport2021Philanthropy-Highlights';
import AnnualReportPhilanthropyPartnerships from './annualReport2021Philanthropy-Partnerships';
import AnnualReportLinks from '../annualReport2021_Components/10_Links/AnnualReport-Links';
import NewFooter from '../../../pages-other/NewFooter';
import './annualReport2021Philanthropy-Content.css';

export default class AnnualReportPhilanthropyContent extends Component {
  render() {
    return(
      <div id="annualReport2021philanthropy-content-container">
        <AnnualReportPhilanthropyOverview />
        <AnnualReportPhilanthropyHighlights />
        <AnnualReportPhilanthropyPartnerships />
        <AnnualReportLinks />
        <NewFooter />
      </div>
    );
  }
}