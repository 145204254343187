import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ProjectsLandingPageHeader from './projectsLandingPage-Header';
import ProjectsLandingPageContent from './projectsLandingPage-Content';
import './projectsLandingPage.css';

export default class ProjectsLandingPage extends Component {
  render() {
    return(
      <div id="projectsLandingPage-container">
        <Helmet>
          <title>foundry10 Educational Automotive Programs</title>
          <meta
            name="title"
            content="foundry10 Educational Automotive Programs"
          />
          <meta
            name="description"
            content="Explore our project-based learning in automotive education. Collaborating with educators, our programs offer students industry experiences and advanced technology."
          />
          <meta property="og:title" content="foundry10 Educational Automotive Programs" />
          <meta
            property="og:description"
            content="Explore our project-based learning in automotive education. Collaborating with educators, our programs offer students industry experiences and advanced technology."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/automotive-projects" />
          <link rel="canonical" href="https://www.foundry10.org/automotive-projects" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Educational Automotive Programs",
                "description": "Explore our project-based learning in automotive education. Collaborating with educators, our programs offer students industry experiences and advanced technology.",
                "url": "https://www.foundry10.org/automotive-projects"
              }
            `}
          </script>
        </Helmet>
        <ProjectsLandingPageHeader />
        <ProjectsLandingPageContent />
      </div>
    );
  }
}