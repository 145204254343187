import React, { Component } from 'react';
import './Program-Team.css';

export default class ProgramTeam extends Component {
  constructor(props) {
    super(props)
    this.state = {
      allPeople: []
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      allPeople: nextProps.all
    })
  }

  render() {
    if(this.state.allPeople) {
      const programTeamCards = this.state.allPeople.map((records, index) => (
        <h4 key={index}>{records.name}</h4>
      ));
        return(
            <div id="programProject-team-container">
              <div class="programProject-team-content-container">
                <h3>Program Team</h3>
                <div class="programProject-names">
                  {programTeamCards}
                </div>
              </div>
            </div>
        );
     } else {
      return (
        <p className='VisibilityOff'>

        </p>
      )
    }
  }
}