import React, { Component } from 'react';
import './smart-Description.css';

export default class SmartDescription extends Component {
  render() {
    return(
      <div id="smart-description-container">
        <div class="smart-description-content-container">
            <p>foundry10 supports research at the UW <a href="https://smartcenter.uw.edu/" rel="noopener noreferrer" target="_blank">School Mental Health Assessment, Research, and Training (SMART) Center</a> focused on improving long-term student outcomes by reducing mental health disparities in preschool. In partnership with local schools and the historically marginalized communities they serve, the goal is to study interventions strengthening parent-teacher relationships to reduce rates of preschool suspension or expulsion.</p>
            <p>The mission of the UW SMART Center is to promote quality improvement of school-based mental and behavioral health services, to reduce youth mental health problems, and to support their social-emotional and academic success in all contexts.</p>
        </div>
      </div>
    );
  }
}