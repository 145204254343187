import React, { Component } from 'react';
import './educatorResources-Intro.css';

export default class EducatorResourcesIntro extends Component {
  render() {
    return(
      <div id="educatorResources-intro-container">
        <div className="educatorResources-intro-content-container">
          <div className="educatorResources-normal">
            <p>foundry10 works closely with educators—both inside and outside of classrooms—to co-create, develop, and share a wide range of teaching resources, including curricula, guides, and professional development tools. Explore highlights of these resources below.</p>
          </div>
        </div>
      </div>
    );
  }
}