import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

const Card = ({ data }) => {
  // let displayStyle = data.linkListDisplay;
  let bgImage = null;
  if (data.linkListBg.length > 0) {
    //console.log('image here')
    bgImage = data.linkListBg[0];
  }
  let myStyle = {};
  const bg = bgImage ? 'bgImage' : '';
  // const classes = `linkListCard ${displayStyle} ${bg}`;
  const classes = `linkListCard ${bg}`;

  if(bgImage !== null ) {
    //console.log('getting here');
    myStyle = {
      backgroundImage: `url(${bgImage.url})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }
  } else {
    myStyle = {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }
  }

  const ariaLinkText = `Learn more about ${data.linkListTitle}`;

  return (
    <div className={classes} style={myStyle}>
      <div className='cardInner'>
        <div className='cardFront'>
          <div className='cardContent'>
            <h3>{data.linkListTitle}</h3>
          </div>
        </div>
        <div className='cardBack'>
        <a href={data.linkUrl}>
          <div className='cardContent'>
            {data.linkListHoverTxt && (
              <div
                className='linklist_hovertxt'
                dangerouslySetInnerHTML={{
                __html: data.linkListHoverTxt,
                }}
              ></div>
            )}
            {data.linkUrl && (
              <a href={data.linkUrl} aria-label={ariaLinkText} ><i class="fa-solid fa-arrow-right"></i></a>
            )}
            </div>
        </a>
        </div>
      </div>
    </div>
  )
}
const LinkList = ({ block }) => {
  let heading = block.linkListTitle;
  let displayStyle = block.linkListDisplay;
  let size = block.linkListSize;
  let columnCount = block.columnCount;
  let cards = block.linkListList;
  const classes = `linkListCards ${size} ${displayStyle}`;
  
  return (
      <section className="linkList">
        <div className="container">
          {heading && (
            <h2 className='text-xl_bold'>{heading}</h2>
          )}
          {cards && (
            <Grid container spacing={4} className={classes}>
              {cards.map((block) => (
                <Grid item key={heading} xs={12} md={columnCount}>
                  <Card data={block} />
                </Grid>
              ))}
            </Grid>
          )}
        </div>
      </section>
  )
}

export default LinkList