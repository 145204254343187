import React, { Component } from 'react';
import './fourC.css';
import lottie from 'lottie-web';

export default class FOURC extends Component {
  componentDidMount() {
    lottie.loadAnimation({
      container: document.getElementById("fourC"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "/svg/4c.json"
    });
  }
  render() {
    return (
      <div id="fourC-container">
        <div class="everyday-section-title">
          <h4>FOUR (EVERYDAY UNSEEN)</h4>
        </div>
        <div class="fourC-content-container">
          <div class="fourC-svg-container">
            <div id="fourC" class="fourC-svg-content"></div>
          </div>
          <div class="fourC-text-container">
            <h2>Then we opened Everyday Unseen to the world!</h2>
            <p>A stream of people flowed in and out of the gallery throughout the night. Over three hundred people came to see what these artists had created.</p>
            <p>Family, friends, community members and complete strangers were in awe of what they found...</p>
          </div>
        </div>
      </div>
    );
  }
}