import React, { Component } from 'react';
import './annualReport2021DramaticArts-Overview.css';

export default class AnnualReportDramaticArtsOverview extends Component {
  render() {
    return(
      <div id="annualReport2021DramaticArts-overview-container">
          <div className="annualReport2021DramaticArts-overview-text-container">
            <h1>Dramatic Arts Team</h1>
            <p>
            There is no Zoom corollary for live theatre and the magic of creative drama in the classroom. When the pandemic hit, we knew that the core tenants of our form— imaginative transformation, play, story, creativity, and collaboration— would be our guide in the discovery of new methods for learning and creating community in a time of isolation and remote instruction. We did this by establishing a cohort of teaching artists and professional theatre makers to directly assist classroom teachers, developing adaptable content, altering entire courses to function in an online environment, and finding ways to assist families and educators in storytelling integration and play based learning.   Our methods may have evolved and expanded but the fundamentals of imaginative transformation, play, creativity, and collaboration that we provide are more essential now than ever before.
            </p>
          </div>
      </div>
    );
  }
}