import React, { Component } from 'react';
import './savvyLadies-Header.css';

export default class SavvyLadiesHeader extends Component {
  render() {
    return(
      <div id="savvyLadies-header-container">
          <div class="savvyLadies-header-content-container">
            <h1>Assessing Women’s Financial Attitudes after Taking Part in a Debt Management Program</h1>
          </div>
      </div>
    );
  }
}