import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import VrLearningMapHeader from './vrLearningMap-Header';
import VrLearningMapContent from './vrLearningMap-Content';
import './vrLearningMap.css';

export default class VrLearningMap extends Component {
  render() {
    return(
      <div id="vrLearningMap-container">
        <Helmet>
          <title>foundry10 VR Learning Map</title>
          <meta
            name="title"
            content="foundry10 VR Learning Map"
          />
          <meta
            name="description"
            content="The VR Learning Map displays all of the educational interactive VR experiences currently being offered in the Seattle Metro area."
          />
          <meta property="og:title" content="foundry10 VR Learning Map" />
          <meta
            property="og:description"
            content="The VR Learning Map displays all of the educational interactive VR experiences currently being offered in the Seattle Metro area."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/vr-learning-map" />
          <link rel="canonical" href="https://www.foundry10.org/vr-learning-map" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 VR Learning Map",
                "description": "The VR Learning Map displays all of the educational interactive VR experiences currently being offered in the Seattle Metro area.",
                "url": "https://www.foundry10.org/vr-learning-map"
              }
            `}
          </script>
        </Helmet>
        <VrLearningMapHeader />
        <VrLearningMapContent />
      </div>
    );
  }
}