import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

let ctaHeader = '';
let ctaContent = '';
let ctaButton = '';

function PreFooterContent({blocks}) {
    //console.log(blocks);
    return(
        blocks.entries.map(
          (block, index) => {
            
            // Set which content we need
            const principles = block.guidingPrinciples;
            const quote = block.footerQuote;
            const quoteAttr = block.footerQuoteAttribution;
            const quoteBg = block.footerQuoteBg;
            if( block.footerCTA && block.footerCTA.length > 0) {
                ctaHeader = block.footerCTA[0].footerCTATitle;
                ctaContent = block.footerCTA[0].footerCTAContent;
                ctaButton = block.footerCTA[0].footerCTALinkUrl;
            }
            // const ctaHeader = block.footerCTA[0].footerCTATitle;
            // const ctaContent = block.footerCTA[0].footerCTAContent;
            // const ctaButton = block.footerCTA[0].footerCTALinkUrl;

            //console.log(ctaHeader);

            // Set BG Image
            let myStyle = {};

            //console.log( typeof(bgImage) );
            if(typeof(quoteBg) != 'undefined') {
                myStyle = {
                backgroundImage: `url(${quoteBg.url})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                }
            } else {
                myStyle = {
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                }
            }
    
            // Determine the type of content
            if(principles !== undefined && principles.length > 0) {
              return (
                <section id="preFooter" className="principles">
                    <div className="container">
                        <Grid container spacing={24} className="gpGrid">
                            <Grid item md={1} xs={0} className="gpSpacer">
                            </Grid>
                            <Grid item md={4} xs={12} className="guidingPrinciples">
                                <h3>Guiding Priniciples</h3>
                            </Grid>
                            <Grid item md={7} xs={12} className="guidingPrinciplesBlurb">
                                <p>Guiding principles are our broad philosophy that encompass the beliefs and values within this interest area.</p>
                            </Grid>
                        </Grid>
                        {principles.map(item=>(
                            <Grid container spacing={24} className="principlesGrid">
                                <Grid item md={3} xs={12} className="principlesGridItems">
                                    <p>{item.guidingPrinciplesPrinciple}</p>
                                </Grid>
                            </Grid>
                        ))}
                        
                    </div>
                </section>
              );
            } else if (quote) {
                return (
                    <section id="preFooter" style={myStyle}>
                        <div className="container">
                            <Grid container spacing={24}>
                                <Grid item lg={1} xs={12} className="quote">
                                    <h3>“</h3>
                                </Grid>
                                <Grid item md={10} xs={12} className="guidingPrinciplesQuote">
                                    <h4>{quote}</h4>
                                </Grid>
                                {quoteAttr && (
                                    <Grid item xs={12} className="guidingPrinciplesAttr">
                                        <p><span>{ quoteAttr }</span></p>
                                    </Grid>
                                    )
                                }
                            </Grid>
                        </div>
                    </section>
                );
            } else if (ctaHeader) {
                return (
                    <section id="preFooter" className="cta">
                        <div className="container">
                            <Grid container spacing={24} className="cta">
                                <Grid item xs={12}>
                                    <h3>{ ctaHeader }</h3>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="ctaContent"
                                        dangerouslySetInnerHTML={{
                                        __html: ctaContent,
                                        }}
                                    ></div>
                                </Grid>
                                {ctaButton && (
                                    <Grid item xs={12}>
                                        <a href={ctaButton} className="button white text-teal">Read More</a>
                                    </Grid>
                                    )
                                }
                            </Grid>
                        </div>
                    </section>
                );
            }
          }
        )
    );
//   const pageHeader = data.entries[0];
//   const bgImage = pageHeader.featuredImage[0];
//   let myStyle = {};
//   //console.log(bgImage.url);
//   let customTitle;
//   const bg = bgImage ? 'bgImage' : 'orange';
//   const classes = `${bg}`

//   console.log( typeof(bgImage) );
//   if(typeof(bgImage) != 'undefined') {
//     myStyle = {
//       backgroundImage: `url(${bgImage.url})`,
//       backgroundSize: 'cover',
//       backgroundRepeat: 'no-repeat',
//     }
//   } else {
//     myStyle = {
//       backgroundSize: 'cover',
//       backgroundRepeat: 'no-repeat',
//     }
//   }
//   console.log( myStyle );
  
  //let bgImage = pageHeader.featuredImage[0].url;
  //console.log(bgImage);
  return (
    <div className="container">
        <Grid container spacing={24}>

            <Grid item md={4} xs={4} className="guidingPrinciples">
                <h3>Guiding Priniciples</h3>
            </Grid>
            <Grid item md={4} xs={8} className="guidingPrinciplesBlurb">
            <p>Guiding principles are our broad philosophy that encompass the beliefs and values within this interest area.</p>
            </Grid>
        </Grid>
    </div>
    // <div id="heroContent" className={classes} style={myStyle} >
    //    {pageHeader.customTitle && (
    //       <h2>
    //         {pageHeader.customTitle}
    //       </h2>
    //    )} 
    //    {/* <h3>
    //       {teamMember.teamMemberName}
    //     </h3>
    //     <div
    //         dangerouslySetInnerHTML={{
    //         __html: teamMember.teamMemberBio,
    //         }}
    //     ></div> */}
    // </div>
  );

}

export default class PreFooter extends Component {
  render() {
    return(
        <PreFooterContent blocks={this.props.data}/>
    );
  }
}