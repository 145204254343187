import React, { Component } from 'react';
import AnnualReportFinEdOverview from './annualReport2021FinEd-Overview';
import AnnualReportFinEdSpotlights from './annualReport2021FinEd-Spotlights';
import AnnualReport2021ProgramLinks from '../annualReport2021ProgramLinks/AnnualReport2021-ProgramLinks';
import NewFooter from '../../../../pages-other/NewFooter';
import './annualReport2021FinEd-Content.css';

export default class AnnualReportFinEdContent extends Component {
  render() {
    return(
      <div id="annualReport2021FinEd-content-container">
        <AnnualReportFinEdOverview />
        <AnnualReportFinEdSpotlights />
        <AnnualReport2021ProgramLinks />
        <NewFooter />
      </div>
    );
  }
}