import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import {
  useQuery,
  gql
} from "@apollo/client";
import PageHeader from '../components/page-header.js';
import Grid from '@material-ui/core/Grid';
import ContentBlocks from '../components/ContentBlocks';
import StoryCard from '../components/storyCard';
// import GlobalFooter from '../components/base/GlobalFooter';
import NewFooter from '../../pages-other/NewFooter.js';
import '../styles/pages/newsMedia.css';


const QUERY = gql`
    query NewQuery($slug: [String]) {
    entry(slug: $slug) {
      title
      postDate @formatDateTime(format: "d M Y")
      postDateAlt: postDate @formatDateTime(format: "Y-m-d")
      url
      ... on newsArchive_newsArchive_Entry {
        customTitle
        blurb
        featuredImage {
          title
          url
        }
        featuredImageCaption
        contentOptions {
          ... on contentOptions_standardContent_BlockType {
            contentTitle
            contentContent
            linkUrl {
              url
              customText
              target
            }
            contentAlignment
          }
          ... on contentOptions_icp_BlockType {
            icpTitle
            icpContent
              icpImage {
                  url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
          }
          ... on contentOptions_fwi_BlockType {
            fwiTitle
            fwiImage {
                url
            }
            fwiCaption
            fwiSize
          }
          ... on contentOptions_emphasized_BlockType {
            emphasizedTitle
            emphasizedSubheadline
            emphasizedOverline
            emphasizedImage {
              url
            }
            emphasizedImageCaption
            emphasizedContent
            emphasizedLink
            emphasizedBackgroundColor
          }
          ... on contentOptions_quote_BlockType {
                pullQuote
                quoteSocial
                quoteImage {
                  url
                }
                quoteAttribution
          }
          ... on contentOptions_quoteSlideshow_BlockType {
            quoteSliderTitle
            quotes {
              __typename
            }
          }
          ... on contentOptions_video_BlockType {
            videoTitle
            videoLink
            videoCaption
            videoImage {
              url
            }
          }
        }
      }
    }
  }
`

const researchQuery = gql`
    query researchQuery {
      entries(section: "stories", limit: 3, relatedToCategories: [{ id: 689 }]) {
        title
        slug
        url
        author {
          fullName
        }
        postDate @formatDateTime (format: "M j, Y")
        ... on stories_default_Entry {
          researchExternalAuthors {
            ... on researchExternalAuthors_BlockType {
              researchExternalAuthorsAuthor
            }
          }
          interestAreaTeam {
            ... on interestAreaTeam_BlockType {
              interestAreaTeamMember {
                title
              }
            }
          }
          blurb
          featuredImage {
            url
          }  
          categories {
            title
          }
        }
      }
    }
`
const blogQuery = gql`
  query blogQuery {
    entries(section: "stories", limit: 3, relatedToCategories: [{ id: 690 }]) {
      title
      slug
      url
      author {
        fullName
      }
      postDate @formatDateTime (format: "M j, Y")
      ... on stories_default_Entry {
        researchExternalAuthors {
          ... on researchExternalAuthors_BlockType {
            researchExternalAuthorsAuthor
          }
        }
        interestAreaTeam {
          ... on interestAreaTeam_BlockType {
            interestAreaTeamMember {
              title
            }
          }
        }
        blurb
        featuredImage {
          title
          url
        }
        categories {
          title
        }
      }
    }
  }
`

const pressReleaseQuery = gql`
  query pressReleaseQuery {
    entries(section: "stories", limit: 3, relatedToCategories: [{ id: 691 }]) {
      title
      slug
      author {
        fullName
      }
      url
      postDate @formatDateTime (format: "M j, Y")
      ... on stories_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        researchExternalAuthors {
          ... on researchExternalAuthors_BlockType {
            researchExternalAuthorsAuthor
          }
        }
        interestAreaTeam {
          ... on interestAreaTeam_BlockType {
            interestAreaTeamMember {
              title
            }
          }
        }
        themes {
          title
        }
      }
    }
  }
`

const newsletterQuery = gql`
  query newsletterQuery {
    entries(section: "newsletters", limit: 4, orderBy: "postDate DESC") {
      title
      slug
      url
      postDate @formatDateTime (format: "M j, Y")
      ... on newsletters_default_Entry {
        linkUrl
      }
    }
  }
`

const newsQuery = gql`
  query newsQuery {
    entries(section: "press", limit: 3, orderBy: "postDate DESC") {
      title
      slug
      url
      postDate @formatDateTime (format: "M j, Y")
    }
  }
`

const ResearchHighlights = () => {
  const { data, loading, error } = useQuery(researchQuery);
  if (loading) return null;
  if (error) return `Error! ${error}`;

  return (
    data.entries.map(
      (block, index) => {
       
        //console.log(block.slug);
        //console.log(block.categoryId);
        let props = {
          block: {block}
        }
        return (
          <Grid item xs={12} sm={4}>
            <StoryCard {...props} />
          </Grid>
        )
      }
    )
  )
}

const Blog = () => {
  const { data, loading, error } = useQuery(blogQuery);
  if (loading) return null;
  if (error) return `Error! ${error}`;

  return (
    data.entries.map(
      (block, index) => {
      
        let props = {
          block: {block},
          orientation: 'wide'
        }
        return (
          <Grid item sm={12}>
            <StoryCard {...props} />
          </Grid>
        )
      }
    )
  )
}

const PressReleases = () => {
  const { data, loading, error } = useQuery(pressReleaseQuery);
  if (loading) return null;
  if (error) return `Error! ${error}`;

  console.log(data);

  return (
    data.entries.map(
      (block) => {
        
        let props = {
          block: {block}
        }
        return (
          <Grid item sm={6} md={4}>
            <StoryCard {...props} />
          </Grid>
        )
      }
    )
  )
}

const NewsletterList = () => {
  const { data, loading, error } = useQuery(newsletterQuery);
  if (loading) return null;
  if (error) return `Error! ${error}`;

  return (
    data.entries.map(
      (block, index) => {
        return (
          <li><a href={block.linkUrl}><h3>{block.title}</h3></a></li>
        )
      }
    )
  )
}

const NewsList = () => {
  const { data, loading, error } = useQuery(newsQuery);
  if (loading) return null;
  if (error) return `Error! ${error}`;

  return (
    data.entries.map(
      (block, index) => {
        return (
          <li className="text-medium_bold"><a href={block.url}><h3>{block.title}</h3></a></li>
        )
      }
    )
  )
}

const ResearchBlock = (teamData) => {

  return (
    <section className='featuredResearch'>
      <div className="container">
        <h2 className="text-xl_bold">Latest Research News</h2>
        <Grid container spacing={4}>
          <ResearchHighlights />
        </Grid>
        <a className="button orange" href="/stories?cat=689">View All  <i class="fas fa-arrow-right"></i></a>
      </div>

    </section>
  )
}

const BlogBlock = (teamData) => {

  return (
    <section className='featuredBlog'>
      <div className="container">
        <h2 className="text-xxl">From the Blog</h2>
        <Blog />
        <a className="button" href="/stories?cat=690">View All  <i class="fas fa-arrow-right"></i></a>
      </div>

    </section>
  )
}

const PressReleaseBlock = () => {

  return (
    <section className='pressReleases'>
      <div className="container">
        <h2 className="text-xxl">Press Releases</h2>
        <Grid container spacing={4}>
          <PressReleases />
        </Grid>
        <a className="button orange" href="/stories?cat=691">View All  <i class="fas fa-arrow-right"></i></a>
      </div>

    </section>
  )
}

const NewsletterNewsSplit = () => {

  return (
    <section className='newsletterNewSplit'>
      <div className="container">
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} className="newsletterBlock">
          <div>
            <h3 className="text-large_bold">Past Newsletters</h3>
            <ul>
              <NewsletterList />
            </ul>
            <a href="/news/newsletters" className="button">View All <i class="fas fa-arrow-right"></i></a>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className="newsBlock">
          <div>
            <h3 className="text-large_bold">foundry10 in the News</h3>
            <ul>
              <NewsList />
            </ul>

            <a href="/news/press"  className="button">View All <i class="fas fa-arrow-right"></i></a>
          </div>
        </Grid>
        </Grid>
      </div>

    </section>
  )
}

const InlineContent = (slug) => {
  const { data, loading, error } = useQuery(QUERY, {
    variables: {slug}
  });
  if (loading) { return <div>loading</div> }
  if (error) { return <div>{error}</div> }

  return (
    <ContentBlocks data={data.entry} />
  );
}

const NewsletterButton = () => {

  return (
    <div className='newsMedia-header-announcement-container'>
      <a href='/press-resources'>
        <div className='newsMedia-header-announcement-button'>
          <i class="fa fa-bullhorn" aria-hidden="true"></i>
          <p>View Press Resources →</p>
        </div>
      </a>
    </div>
  );

}


export default class NewsMedia extends Component {
  render() {
    return(
        <section className='newsMedia'>
            <Helmet>
              <title>foundry10 - News and Media</title>
              <meta
                name="title"
                content="foundry10 - News and Media"
              />
              <meta
                name="description"
                content="Discover the latest news from founry10's research, educational programs, and philanthropic endeavors."
              />
              <meta property="og:title" content="foundry10 - News and Media" />
              <meta
                property="og:description"
                content="Discover the latest news from founry10's research, educational programs, and philanthropic endeavors."
              />
              <meta property="og:type" content="website" />
              <meta property="og:url" content="https://www.foundry10.org/news" />
              <link rel="canonical" href="https://www.foundry10.org/news" />
              {/* Structured Data Script Tag */}
              <script type="application/ld+json">
                {`
                  {
                    "@context": "https://schema.org",
                    "@type": "WebPage",
                    "name": "foundry10 - News and Media",
                    "description": "Discover the latest news from founry10's research, educational programs, and philanthropic endeavors.",
                    "url": "https://www.foundry10.org/news"
                  }
            `   }
              </script>
            </Helmet>
            <PageHeader slug={this.props.slug} />
            <NewsletterButton />
            <ResearchBlock />
            <BlogBlock />
            <InlineContent slug='news-media-archive'/>
            <PressReleaseBlock />
            <NewsletterNewsSplit />
            {/* <GlobalFooter /> */}
            <NewFooter />
        </section>
      
    );
  }
}