import React, { Component } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import './charts.css'

HighchartsMore(Highcharts);

export default class BoxPlotGenderSplit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // To avoid unnecessary update keep all options in the state.
    chartOptions: {
        chart: {
          type: 'boxplot',
          // backgroundColor: '#007A8A',
      },

      title: {
        text: 'Self-Regulated Learning Scores',
        style: {
          color: '#00464F'
        }
      },
      subtitle: {
        text: 'Split by Gender'
       },
      legend: {
          enabled: true
      },

      xAxis: {
        categories: ['Planning', 'Monitoring', 'Evaluating','Reflecting','Effort'],
        title: {
          style: {
            color: '#00464F'
          },
          text: ''
          },
      },

      yAxis: {
          title: {
            style: {
              color: '#00464F'
            },  
            text: 'Score'
          },
      },
      //Data: An array of arrays with 6 or 5 values. In this case, the values correspond to x,low,q1,median,q3,high. If the first value is a string, it is applied as the name of the point, and the x value is inferred. The x value can also be omitted, in which case the inner arrays should be of length 5. Then the x value is automatically calculated, either starting at 0 and incremented by 1, or from pointStart and pointInterval given in the series options.
      plotOptions: {
        series: {
            colorByPoint: false
        }
    },
      colors: ['#D14E1D','#575757'],
      series: [{
          name: 'Female',
          data: [
              ['Planning - Female',1.5,2.833,3.167,3.583,5],
              ['Monitoring - Female',1.444,3,3.556,4.111,5],
              ['Evaluating - Female',2,3.5,4.25,4,5],
              ['Reflecting - Female',2.8,3.6,4.2,4,5],
              ['Effort - Female',1.148,2.926,3.667,4.148,5],
          ],
          style: {
            color: '#E77C53'
          },
          tooltip: {
              headerFormat: '<em>{point.key}</em><br/>'
          },
      }, 
    {
        name: 'Male',
        data: [
            ['Planning - Male',1,2.5,3.167,3.5,4.333],
            ['Monitoring - Male',1,2.833,3.444,3.667,4.778],
            ['Evaluating - Male',1,3.125,3.625,4,5],
            ['Reflecting - Male',1,3.4,3.7,4,5],
            ['Effort - Male',1,2.815,3.074,3.667,5]
        ],
        style: {
          color: '#E77C53'
        },
        tooltip: {
            headerFormat: '<em>{point.key}</em><br/>'
        },
    }
    ],
      }
    };
  }


  render() {
    const { chartOptions } = this.state;

    return (
      <div class="chart">
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
        />
      </div>
    )
  }
}
