import React, { Component } from 'react';
import './researchRedesign-Labs.css';

export default class ResearchLabs extends Component {
  render() {
    return (
      <div className="researchRedesignLabs-container">
        <div className="researchRedesignLabs-content-container">
          <h1>Explore Our Research Labs</h1>
          {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dictum fusce ut placerat orci nulla pellentesque dignissim enim. Velit sed ullamcorper morbi tincidunt ornare massa eget.</p> */}
          <div className="researchRedesignLabs-info-container">
            <div className="researchRedesignLabs-info-image">
              <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Research+Landing+Page/2022+Redesign/Labs/Conceptual+Development+Lab+Research+Landing+Page+Card.jpeg" alt="woman educator teaching a group of elementary aged children"/>
            </div>
            <div className="researchRedesignLabs-info-text">
              <h2>Conceptual Development Lab</h2>
              <p>How do young learners develop an understanding of abstract ideas? This lab aims to answer that question and design more effective instruction methods to promote learning and reduce misconceptions around these ideas.</p>
              <a href="https://www.foundry10.org/conceptual-development-lab" className="researchRedesignLabs-link">Learn more<i className="fa-solid fa-arrow-right-long"></i></a>
            </div>
          </div>
          <div className="researchRedesignLabs-info-container">
            <div className="researchRedesignLabs-info-image">
              <img src="https://f10-research.s3.us-west-2.amazonaws.com/Digital+Technologies+%26+Education+Lab/Digital+Technologies+Lab+card.jpeg" alt="young children playing with virtual reality headset"/>
            </div>
            <div className="researchRedesignLabs-info-text">
              <h2>Digital Technologies and Education Lab</h2>
              <p>This lab explores the intersections of technology with teaching and learning, with a particular focus on emerging technologies and the development of civic voice in collaborative digital contexts.</p>
              <a href="https://www.foundry10.org/digital-technologies-and-education-lab" className="researchRedesignLabs-link">Learn more<i className="fa-solid fa-arrow-right-long"></i></a>
            </div>
          </div>
          <div className="researchRedesignLabs-info-container">
            <div className="researchRedesignLabs-info-image">
              <img src="https://craft-images.s3.us-west-2.amazonaws.com/STEM-DBR-Lab/STEM+DBR+Research+Card+Crop.jpg" alt="youth wearing safety glasses and soldering"/>
            </div>
            <div className="researchRedesignLabs-info-text">
              <h2>STEM Design-Based Research Lab</h2>
              <p>This lab strives to expand our understanding of how instructional STEM activities that are designed for youth work in real world settings such as classrooms, afterschool programs, and informal environments.</p>
              <a href="https://www.foundry10.org/stem-design-based-research-lab" className="researchRedesignLabs-link">Learn more<i className="fa-solid fa-arrow-right-long"></i></a>
            </div>
          </div>
          <div className="researchRedesignLabs-info-container">
            <div className="researchRedesignLabs-info-image">
              <img src="https://f10-research.s3.us-west-2.amazonaws.com/Youth+and+Educator+SEL+Lab/Youth+and+Educator+SEL+Lab+Card.jpg" alt="two youth kids gardening outdoors"/>
            </div>
            <div className="researchRedesignLabs-info-text">
              <h2>Youth and Educator SEL Lab</h2>
              <p>This lab explores the contextual, pedagogical, and individual factors that impact the social and emotional development of young people and those who work closely with them.</p>
              <a href="https://www.foundry10.org/youth-and-educator-sel-lab" className="researchRedesignLabs-link">Learn more<i className="fa-solid fa-arrow-right-long"></i></a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}