import React, { Component } from 'react';
import './annualReport2021Internships-Spotlights.css';

export default class AnnualReportInternshipsSpotlights extends Component {
  render() {
    return(
      <div id="annualReport2021Internships-spotlights-container">
        <div className="annualReport2021Internships-spotlight-first-title-bar">
          <p>Spotlight: Virtual Internships Teach Life Skills You Can’t Learn in School</p>
        </div>
        <div className="annualReport2021Internships-spotlights-content-container">
          <div className="annualReport2021Internships-spotlights-text-container">
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Subpages/internships+teach+life+lessons.jpeg" alt="two figures in orange run relay"/>
            <p>
            While participating in a virtual internship program at foundry10, students were able to gain <a href="https://medium.com/the-foundry10-voice/virtual-internships-teach-life-skills-you-cant-learn-in-school-2613046a28ec" target="_blank" rel="noopener noreferrer">valuable life experience while working remotely</a>. The Intern team was impressed that, despite the emotional toll of the global pandemic, our summer interns maintained motivation and determination to progress through their passion projects. Through the internship, the interns learned about time-management, resource acquisition, remote communication and workplace goal setting, all in a virtual setting. The team made several large pivots in our plan to modify the program so that it provided a valuable experience for all involved, and we look forward to applying what we have learned in future iterations of our internship program. We are also excited to share our learnings about virtual internships with others through a conference presentation to Career Educators this summer.
            <br /> <br />
            <a href="https://ashukla9.github.io/index.html" target="_blank" rel="noopener noreferrer">Anya Shukla’s Internship Project: Before I Forget</a>
            </p>
          </div>
        </div>
        <div className="annualReport2021Internships-spotlight-second-title-bar">
          <p>Spotlight: Consider Hiring a Virtual Intern</p>
        </div>
        <div className="annualReport2021Internships-spotlights-content-container">
          <div className="annualReport2021Internships-spotlights-text-container">
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Subpages/virtual+interns.jpeg" alt="young person in orange considers two paths - one up a jagged mountain, the other toward gentle hills and a second person"/>
            <p>Internships are a fantastic way for high school students to gain real world professional experience and make connections for future job opportunities. But with a global pandemic shifting worklife to a remote set-up, we began to think about how we could encourage other companies to hire virtual interns. This is of particular importance to students who live in more rural areas that perhaps do not offer the range of in-person internships one might find in a more populated city. Taking a more equitable approach to internship access helps students and employers. We recently wrote an article to share some of the reasons <a href="https://medium.com/the-foundry10-voice/why-you-should-consider-hiring-a-virtual-intern-5850d9a64c6f" target="_blank" rel="noopener noreferrer">why hiring a virtual intern could be beneficial</a> to any employer and are happy to contribute insights and lessons learned to this larger discussion.
            <br /> <br />
            <a href="https://sofialeotta.wixsite.com/sustainability" target="_blank" rel="noopener noreferrer">Seattle’s Sustainable Future</a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}