const ClassData = [
    {
        element: {
            title: 'Space',
            subtitle: 'Do you have enough space in your classroom for your students to use this software? If not, do you have another space you can use?',
            img: 'https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/EdTech/VR+Software+Stress+Test/EdTech+VR+Stress+Test+Accordion+Icon-Space.png',
            alt: 'pinpoint on map',
            content: 'Consider how many people are going to be in the room, how many headsets are going to be active at one time, and the space needed to use each headset. Keep in mind that different headsets and experiences have different needs when it comes to space. Some experiences and headsets can allow students to stay in their seats. Others may need dedicated areas for the users to walk around, or for a PC and its wiring, if applicable.',
            contentCont: 'If your classroom isn’t substantial, are there any other areas that you can use? It needs to have enough space and not be too busy or noisy since that can affect the VR experience.'
        }
    },
    {
        element: {
            title: 'Students',
            subtitle: 'Does the VR software have any features that could be triggering to your students? Or too intense? Are the controls accessible to all of your students? Do your students show interest in the software?',
            img: 'https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/EdTech/VR+Software+Stress+Test/EdTech+VR+Stress+Test+Accordion+Icon-Student.png',
            alt: 'figure working on computer at a desk',
            content: 'VR experiences can be very triggering or intense for students, so identifying if a software is triggering and how you work around that is necessary. In addition, students may not be able to use the controls of the software, so you also need to know if your hardware and software is accessible to all students.',
            contentCont: 'If a software is not accessible or it is triggering to some students, you can have them watch others use the software through a screen, have another student use the controls and headset for them, or look for an alternative software. Don’t be afraid to ask your students for their opinion on the software in question.'
        }
    }
]

export default ClassData;