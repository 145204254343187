import React, { Component } from 'react';
import './twoD.css';
import lottie from 'lottie-web';

class TWOD extends Component {
  componentDidMount() {
    lottie.loadAnimation({
      container: document.getElementById("twoD"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "/svg/2d.json"
    });
  }
  render() {
    return (
      <div id="twoD-container">
        <div class="everyday-section-title">
          <h4>TWO (THE ARTISTS)</h4>
        </div>
        <div class="twoD-content-container">
          <div class="twoD-svg-container">
            <div id="twoD" class="twoD-svg-content"></div>
          </div>
          <div class="twoD-text-container">
              <h2>We still had two problems.</h2>
              <p>Most of the artists were youth that had already worked with foundry10 and we wanted to open this up to EVERYBODY. Also, we had come to realize that we couldn't keep calling this "The Gallery." It needed a name...</p>
              <p>Finding a name that fit proved to be a daunting task. We had meeting after meeting, and nothing seemed to work.</p>
              <h4>Finally, inspiration struck! "The Gallery" would be called:</h4>
          </div>
        </div>
      </div>
    );
  }
}

export default TWOD;