import React, { Component } from 'react';
import Error404PageHeader from './Error404Page-Header';
import Error404PageContent from './Error404Page-Content';
import './newSite404.css';

export default class Error404Page extends Component {
  render() {
    return(
      <div id="error404Page-container">
        <Error404PageHeader />
        <Error404PageContent />
      </div>
    );
  }
}