//imported in LearningConcept.js, SubjectArea.js, People.js, Research.js

import React, { Component } from 'react';
// 
import './GetNonAcademicFromDb2.css';
// import Tooltip from '@material-ui/core/Tooltip';

class GetNonAcademicFromDb extends Component {
  constructor(props) {
    super(props)
    this.state = {
      programList: []
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      programList: nextProps.all
    })
  }

  render() {
    // //FIND ITEMS GOING OVER THE VIEWPORT
    // var docWidth = document.documentElement.offsetWidth;
    //
    // [].forEach.call(
    //   document.querySelectorAll('*'),
    //   function(el) {
    //     // console.log('going over...');
    //     if (el.offsetWidth > docWidth) {
    //       console.log('you are going over the edge in getprogramsfromdb.js. I will find you and get you... ', el);
    //     }
    //   }
    // );
    // const toolTipTitle = 'Within the Non-Academic Domain, these are the programs & projects we are currently running.';
    // if (this.state.programList.length > 0) {
    //   const getPrograms = this.state.programList.map((records, index) => {
    //     return (
    //       <div className='getProgramsFromWidth'>
    //         <div className='linkGetFromDbHolder' key={index}>
    //           <p className='linkGetFromDBP'>
    //             <a className='link teal linkGetFromDb' href={`/programs/${records.interestArea}/${records.nameShort}`}>{records.name}</a>
    //           </p>
    //         </div>
    //       </div>
    //     )
    //   })
    //   return (
    //     <div className='getFromDbContainer'>
    //       <h3 className='getFromDbHeader orange'>Non-Academic Domain
    //         <Tooltip style={{width: '10px', overflow: 'hidden'}} id="tooltip-bottom" title={toolTipTitle} placement="top">
    //           <span className='getFromDbToolTip'>?</span>
    //         </Tooltip>
    //       </h3>
    //       <div className='alignLeft'>
    //         {getPrograms}
    //       </div>
    //     </div>
    //   )
    // } else {
    //   return (
    //     <p></p>
    //   )
    // }


    if(this.state.programList.length > 0) {
      const nonAcademicDomainsCards = this.state.programList.map((records, index) => (
        <a href={'/programs/'+records.interestArea+'/'+records.nameShort}>
          <li>
            <div class="final-cards-container">
              <img src={records.programCardImage} alt={records.name} />
              <p id={records.name} class="final-cards-title">{records.name}</p>
              <div id={records.name} class="final-cards-overlay"></div>
            </div>
          </li>
        </a>
      ));
        return(
              <div class="interestArea-cardsList2-container">
                  <div class="interestArea-domain-title">
                    <h2>Non-Academic Domain</h2>
                  </div>
                  <ul id="interestArea-cardsList2" class="interestArea-events-list2">
                    {nonAcademicDomainsCards}
                  </ul>
              </div>
        );
     } else {
      return (
        <p className='VisibilityOff'>

        </p>
      )
    }
  
  // if (this.state.programList.length > 0) {
  //   const techDomainCards = this.state.programList.map((records, index) => {
  //     return (
  //       <a href={'/programs/'+records.domain+'/'+records.nameShort}>
  //         <li>
  //           <div class="final-cards-container">
  //             <img src={records.programCardImage} alt={records.name} />
  //             <p id={records.name} class="final-cards-title">{records.name}</p>
  //             <div id={records.name} class="final-cards-overlay"></div>
  //           </div>
  //         </li>
  //       </a>
  //     )
  //   })
  //   return (
  //             <div class="interestArea-cardsList2-container">
  //                 <ul id="interestArea-cardsList2" class="interestArea-events-list2">
  //                   {techDomainCards}
  //                 </ul>
  //             </div>
  //   )
  // } else {
  //   return (
  //     <p></p>
  //   )
  // }
  }
}

export default GetNonAcademicFromDb;