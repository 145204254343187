import React, { Component } from 'react';
import './smart-Header.css';

export default class SmartHeader extends Component {
  render() {
    return(
      <div id="smart-header-container">
          <div class="smart-header-content-container">
            <h1>Parent Educator Action Response (PEAR)</h1>
          </div>
      </div>
    );
  }
}