import React, { Component } from 'react';
import './annualReport2021Programs-Overview.css';

export default class AnnualReportProgramsOverview extends Component {
  render() {
    return(
      <div id="annualReport2021programs-overview-container">
          <div className="annualReport2021programs-overview-text-container">
            <h1>Programs Overview</h1>
            <p>
              Our approach to programs combines an expansive view of the possibilities in learning with a drive to put those ideas into action in applied settings.  We seek out exciting and innovative ideas, then put them to the test with real students, teachers, and communities.  foundry10 has a wide variety of program interest areas that work both independently of, and collaboratively with, each other. We all benefit from the cross-pollination of ideas and techniques that comes from applying ourselves to new subjects.
              <br />
              <br />
              Over the past year, our program teams have had to adjust to teaching remotely and pivot in multiple ways, all while still keeping a focus on their specific interests and areas.  The stories below highlight the work that our program teams have been doing over the past eighteen months.
            </p>
          </div>
          <div className="annualReport2021programs-overview-card-container">
            <a href="/annual-report/2021/programs/artistic-design" rel="noopener noreferrer">
              <div className="annualReport2021programs-overview-card-individ" id="annualReport2021programs-artisticDesign">
                <h1>ARTISTIC DESIGN</h1>
                <img alt="artistic design" src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Illustrations+Final/Artistic+Design_1-8.png" />
              </div>
            </a>
            <a href="/annual-report/2021/programs/dance" rel="noopener noreferrer">
              <div className="annualReport2021programs-overview-card-individ" id="annualReport2021programs-dance">
              <img alt="dance" src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Illustrations+Final/dance-8.png" />
                <h1>DANCE</h1>
              </div>
            </a>
            <a href="/annual-report/2021/programs/digital-audio" rel="noopener noreferrer">
              <div className="annualReport2021programs-overview-card-individ" id="annualReport2021programs-digitalAudio">
                <img alt="digital audio" src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Illustrations+Final/Digital+Audio-8.png" />
                <h1>DIGITAL AUDIO</h1>
              </div>
            </a>
            <a href="/annual-report/2021/programs/dramatic-arts" rel="noopener noreferrer">
              <div className="annualReport2021programs-overview-card-individ" id="annualReport2021programs-dramaticArts">
                <img alt="dramatic arts" src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Illustrations+Final/Drama2-8.png" />
                <h1>DRAMATIC ARTS</h1>
                <img alt="dramatic arts" src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Illustrations+Final/Drama1-8.png" />
              </div>
            </a>
            <a href="/annual-report/2021/programs/ed-tech" rel="noopener noreferrer">
              <div className="annualReport2021programs-overview-card-individ" id="annualReport2021programs-educationTechnology">
                <h1>EDUCATION TECHNOLOGY</h1>
                <img alt="education technology" src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Illustrations+Final/Ed+Tech-8.png" />
              </div>
            </a>
            <a href="/annual-report/2021/programs/fin-ed" rel="noopener noreferrer">
              <div className="annualReport2021programs-overview-card-individ" id="annualReport2021programs-financialEducation">
                <img alt="financial education" src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Illustrations+Final/Financial+Ed-8.png" />
                <h1>FINANCIAL EDUCATION</h1>
              </div>
            </a>
            <a href="/annual-report/2021/programs/games-learning" rel="noopener noreferrer">
              <div className="annualReport2021programs-overview-card-individ" id="annualReport2021programs-gamesLearning">
                <img alt="games and learning" src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Illustrations+Final/Games1-8.png" />
                <h1>GAMES & LEARNING</h1>
                <img alt="games and learning" src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Illustrations+Final/Games2-8.png" />
              </div>
            </a>
            <a href="/annual-report/2021/programs/internships" rel="noopener noreferrer">
              <div className="annualReport2021programs-overview-card-individ" id="annualReport2021programs-internships">
                <img alt="internships" src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Illustrations+Final/Intern1-8.png" />
                <h1>INTERNSHIPS</h1>
                <img alt="internships" src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Illustrations+Final/Intern2-8.png" />
              </div>
            </a>
            <a href="/annual-report/2021/programs/interventions" rel="noopener noreferrer">
              <div className="annualReport2021programs-overview-card-individ" id="annualReport2021programs-interventions">
                <img alt="interventions" src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Illustrations+Final/interventions-8.png" />
                <h1>INTERVENTIONS</h1>
              </div>
            </a>
            <a href="/annual-report/2021/programs/marine-science" rel="noopener noreferrer">
              <div className="annualReport2021programs-overview-card-individ" id="annualReport2021programs-marineScience">
                <img alt="marine science" src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Illustrations+Final/marine-8.png" />
                <h1>MARINE SCIENCE</h1>
              </div>
            </a>
            <a href="/annual-report/2021/programs/stem" rel="noopener noreferrer">
              <div className="annualReport2021programs-overview-card-individ" id="annualReport2021programs-stem">
                <img alt="stem" src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Illustrations+Final/STEM_1-8.png" />
                <h1>STEM</h1>
              </div>
            </a>
            <a href="/annual-report/2021/programs/trades" rel="noopener noreferrer">
              <div className="annualReport2021programs-overview-card-individ" id="annualReport2021programs-trades">
                <img alt="trades" src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Illustrations+Final/Trades2-8.png" />
                <h1>TRADES</h1>
                <img alt="trades" src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/Illustrations+Final/Trades1-8.png" />
              </div>
            </a>
            <a href="#annualReport2021-programs-spotlight-story" rel="noopener noreferrer">
              <div id="annualReport2021programs-spotlight">
                <img alt="arrow to spotlight story" src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/arrow-cropped-with-text.png" />
              </div>
            </a>
          </div>
      </div>
    );
  }
}