import React, { Component } from 'react';
import './october21-HighlightZero.css';

export default class HighlightZero21PageStory extends Component {
  render() {
    return(
      <div id="highlightZero21Page-story-container">
        <div className="highlightZero21Page-story-text-container">
            <h2 className="highlightZero21Page-story-title">Teaching Digital Audio Production Basics: Curriculum for K-12 Educators</h2>
            <p className="highlightZero21Page-story-content">Teach youth how to use audio and MIDI to build songs, record and edit dialogue, and polish their work using basic mixing and mastering techniques through a series of 11 total lessons and assignments.</p>
            <a href="https://www.foundry10.org/research/teaching-digital-audio-production-basics-curriculum-for-k12-educators" target="_blank" rel="noopener noreferrer">Learn More</a>
        </div>
        <div className="highlightZero21Page-story-img-container">
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Hub/Oct+2021/Digital+Audio+Production+Basics.png" alt="Nelson Hunter using the digital audio equipment available at the Federal Way Library Makerspace with teaching artist Kevin Keogh"/>
        </div>
      </div>
    );
  }
}