
const SunburstData = [
    {
        // name: 'All Categories',
        // id: '0'
    }, 
// Categories Addressed
    {
        name: 'Meeting Health and Wellness Needs',
        displayName: `<span class="sunburst-healthed-main-category">Meeting Health and<br>Wellness Needs</span>`,
        // detailName: 'Meeting Health and Wellness Needs',
        icon: '<br><br><i class="fa-solid fa-suitcase-medical fa-4x"></i><br>',
        percentage: '33',
        detailContent: 'Explore how we met health and wellness needs in our community this year.',
        id: '1',
        // parent: '0',
    }, {
        name: 'Generating and Sharing Knowledge',
        displayName: '<span class="sunburst-healthed-main-category sharing-knowledge">Generating and<br>Sharing Knowledge</span>',
        // detailName: 'Generating and Sharing Knowledge',
        icon: '<br><br><i class="fa-solid fa-share-nodes fa-4x"></i><br>',
        percentage: '36',
        detailContent: 'Learn about the different ways we generated and shared Health Ed knowledge from our work in the community.',
        id: '2',
        // parent: '0',
    },
    {
        name: 'Supporting Careers in Health Sciences',
        displayName: '<span class="sunburst-healthed-main-category supporting-careers">Supporting Careers<br>in Health Sciences</span>',
        // detailName: 'Supporting Careers in Health Sciences',
        icon: '<br><br><i class="fa-solid fa-staff-snake fa-4x"></i><br>',
        percentage: '30',
        detailContent: 'The past couple of years highlighted the continued need for the development, training, and support of students interested in pursuing health careers. Through a wide variety of efforts targeting high school and college students, we found ways to enhance learning, fill gaps that enable students to better focus on their studies, and provide opportunities for exposure and experiences that prepare them for their fields.',
        id: '3',
        // parent: '0',
    },
        // Meeting Health and Wellnes Needs
        {
            name: 'Eye-Care Funding',
            // formerly 'Vision Funding'
            displayName: 'Eye-Care Funding',
            id: '1.1',
            parent: '1',
            value: 1,
            detailContent: 'In order to fill gaps in the optical healthcare process, we worked with school nurses in several local districts to set up relationships with local eye-care facilities and get students access to the exams, glasses, and other healthcare items they needed.',
            detailContentCont: 'Our program helped offset resources school districts may receive to help with exams and/or hardware and provided unique pathways and opportunities for students and families to receive eye care support they may not otherwise have access to.'
        },
            // Vision Funding Details
            // {
            //     name: 'Everett',
            //     // displayName: 'Everett',
            //     id: '1.1.1',
            //     parent: '1.1',
            //     value: 18900,
            //     detailContent: 'Addressing gaps in vision funding',    
            //     linkText: '',
            //     url: ''
            // },
            // {
            //     name: 'Anacortes',
            //     // displayName: 'Anacortes',
            //     id: '1.1.2',
            //     parent: '1.1',
            //     value: 3000,
            //     detailContent: 'Addressing gaps in vision funding',    
            //     linkText: '',
            //     url: ''
            // },
            // {
            //     name: 'Edmonds',
            //     // displayName: 'Edmonds',
            //     id: '1.1.3',
            //     parent: '1.1',
            //     value: 15000,
            //     detailContent: 'Addressing gaps in vision funding.  Note: these funds are being housed with the Edmonds Foundation',    
            //     linkText: '',
            //     url: ''
            // },
            // 
        // {
        //     name: 'Sensory Toolkits',
        //     displayName: 'Sensory Toolkits',
        //     id: '1.2',
        //     parent: '1',
        //     detailContent: 'To help teachers best meet their students’ emotional needs, we helped fund equipment to form dedicated areas where students could recenter themselves and engage with sensory support items to feel more ready to engage with their peers.'
        // },
            // Sensory Tools & Mental Health Details
            // {
            //     name: 'Edmonds School District',
            //     // displayName: 'Edmonds School District',
            //     id: '1.2.1',
            //     parent: '1.2',
            //     value: 1600,
            //     detailContent: 'Minim commodo tempor proident id magna.',
            //     linkText: '',
            //     url: ''    
            // },
            // {
            //     name: 'Olivia Park',
            //     // displayName: 'Olivia Park',
            //     id: '1.2.2',
            //     parent: '1.2',
            //     value: 2200,
            //     detailContent: 'Ipsum in ex laboris aliquip et pariatur proident incididunt incididunt fugiat deserunt.',
            //     linkText: '',
            //     url: ''    
            // },            
            // {
            //     name: 'Quil Ceda Tulalip',
            //     // displayName: 'Quil Ceda Tulalip',
            //     id: '1.2.3',
            //     parent: '1.2',
            //     value: 5700,
            //     detailContent: 'Eiusmod in enim nisi nostrud.',
            //     linkText: '',
            //     url: ''    
            // },            
            // {
            //     name: 'Lake Stickney',
            //     // displayName: 'Lake Stickney',
            //     id: '1.2.4',
            //     parent: '1.2',
            //     value: 2000,
            //     detailContent: 'Amet laborum nulla sit quis mollit consequat ea culpa esse Lorem nostrud.',
            //     linkText: '',
            //     url: ''    
            // },
        {
            name: 'Audiology Support',
            displayName: 'Audiology Support',
            id: '1.2',
            parent: '1',
            value: 1,
            detailContent: 'In partnership with Edmonds School District, we provided microphones and printing tools to elementary students. This allowed classrooms to better support students with hearing difficulties. One of our primary areas of interest was how additional audiology support helped students engage successfully with their peers and teachers. We are excited to find new opportunities to provide funding that fills gaps for audiology support that other programs might not cover.'
        },
        {
            name: 'Sensory Toolkits',
            // formerly 'Sensory Equipment for Youth'
            displayName: 'Sensory Toolkits',
            id: '1.3',
            parent: '1',
            value: 1,
            detailContent: 'Ample research suggests that sensory tools and sensory spaces can provide powerful support for students who are struggling with anxiety, behavioral issues, or simply struggling with strong emotions. To help teachers best meet their students’ emotional needs, we helped fund equipment to form dedicated areas where students could recenter themselves and engage with sensory support items to feel more ready to engage with their peers.',
            detailContentCont: 'In working with a variety of schools in a range of ways, we were able to customize the types of support we offered so that the tools met the needs of specific school populations. Additionally, we utilized what we learned from each of these settings to help guide the choices of the other schools we partnered with. We are excited to gather more data about what seems to work well in what settings this year.',
            linkText: '',
            url: ''
        },
        {
            name: 'CHS Therapist Position',
            displayName: 'CHS Therapist Position',
            id: '1.4',
            parent: '1',
            value: 1,
            detailContent: 'In response to a widespread shortage of therapists and mental health support for students, we funded two part-time positions for a local high school in an effort to better understand if this is a feasible way to increase access on school campuses for student mental health services.',
            detailContentCont: 'This funding allowed informal student drop-in sessions to occur and teachers to have wider classroom support than traditionally funded mental health services. Our partner organization gathered data on how effective the additional services are to assist us in thinking about how best to enhance student mental health care.',
            linkText: 'Learn More',
            url: 'https://www.foundry10.org/center-for-human-services-partnership'
        },
        {
            name: 'Nutrition Support',
            displayName: 'Nutrition Support',
            id: '1.5',
            parent: '1',
            value: 1,
            detailContent: 'We provided healthy snack and food support to students at a number of locations. Often daytime snacks are not covered in other food support operations, so even though students may be eligible for free lunch, they may not have access to healthy snacks during the day. As we work in schools, if a need for supportive snacks comes up, we make our best effort to address that need.',
            linkText: '',
            url: ''
        },
    // Generating and Sharing Knowledge
        {
            name: 'SMART Center Study',
            // formerly 'Preschool expulsion study w/ SMART Center'
            displayName: 'SMART Center Study',
            id: '2.1',
            parent: '2',
            value: 1,
            detailContent: 'We funded a case study at the University of Washington which examines preschool suspension and expulsion. This study considered teachers, parents, their communication styles, as well as cultural barriers, to collaboratively understand student behavior and how to provide effective support.',
            detailContentCont: 'Early suspensions and expulsions have profound impacts on student health, academic success and well-being, so finding ways to decrease their occurrence was vital. Our hope is that this work provides actionable steps that can be implemented in other places to help improve outcomes for students.',
            linkText: 'Learn More',
            url:'https://smartcenter.uw.edu/'
        },    
        {
            name: 'Meadowdale Health Clinic',
            displayName: 'Meadowdale Health Clinic',
            id: '2.2',
            parent: '2',
            value: 1,
            detailContent: 'We provided funding that helped create an in-school health clinic in the Edmonds School District. The clinic will increase student access to and awareness around mental, dental, and reproductive health and provide a crucial pathway to care for students on-site in a safe and supportive environment.',
            linkText: '',
            url: ''
        },
        {
            name: 'Sensory and Mental Health Support',
            // formerly 'Research on Mobility Issues'
            displayName: 'Sensory and Mental Health Support',
            id: '2.3',
            parent: '2',
            value: 1,
            detailContent: 'A great deal of research has demonstrated a crucial need for tools, training, and adult-led interventions and support for young people and their mental health. Though we did not ask specific research questions ourselves this year, we incorporated community knowledge and best practices to support community-based problem solving in these areas.',
            detailContentCont: 'We made direct connections between practices that showed promise and direct connections for youth by sending teachers to mindfulness training and helping implement it in classrooms, providing a range of sensory tools (e.g., fidget, balls, support materials, PT tools specifically for sensory/behavioral challenges), and helping set up an array of sensory paths and spaces.',
            linkText: '',
            url: ''
        },
        {
            name: 'Traumatic Brain Injury Research',
            displayName: 'Traumatic Brain Injury Research',
            id: '2.4',
            parent: '2',
            value: 1,
            detailContent: 'This project focused on concussion injuries in high school students and their recovery process through a program offered by the Harborview Injury Prevention and Research Center (HIPRC). We funded a research coordinator position to gather data on concussion rates in students and how HIPRC’s toolkit was implemented in classrooms to better support holistic, responsive recovery for students who sustained injuries.',
            detailContentCont: 'We also sat on the advisory board for this project to weigh-in on decisions about direction and stay connected to the wider implementation of protocols that improve student recovery in school settings. We are in the second year of supporting this project and are particularly interested in how the Return to Learn tools designed by HIPRC help students and teachers in day-to-day school settings.',
            linkText: '',
            url: ''
        },
    // Supporting Careers in Health Sciences
        {
            name: 'Healthcare Exploration Scholarships',
            // formerly 'Tacoma Scholarships'
            displayName: 'Healthcare Exploration Scholarships',
            id: '3.1',
            parent: '3',
            value: 1,
            detailContent: 'We’re working with the Dean of Nursing and Director of Workforce Education at Tacoma College to fill gaps for their students interested in various health fields.',
            linkText: '',
            url: ''
        },    
        {
            name: 'Radiology and General Health Sciences Support',
            // formerly 'Radiology Scholarships'
            displayName: 'Radiology and General Health Sciences Support',
            id: '3.2',
            parent: '3',
            value: 1,
            detailContent: 'We worked with Bellevue College’s Radiology program to provide learning tools and equipment for two-year and four-year students.This included radiation therapy training simulators and controllers, ultrasound reproductive organ simulators, radiologic technology training tools, and clinical internship support and evaluation.',
            detailContentCont: 'Additionally, we worked collaboratively to fill funding gaps for the additional costs students face when completing their practicums and externships. From transportation costs to PPE protection and immunization fees, there are many hidden costs and not a clear funding pathway for these students. These funds and our associated collaborative research on effectiveness for students helped mitigate some of these challenges.',
            linkText: '',
            url: ''
        },
            // Bellevue College Funding Details
            // {
            //     name: 'Radiation Therapy training simulator and controllers',
            //     // displayName: 'Radiation Therapy training simulator and controllers',
            //     id: '3.2.1',
            //     parent: '3.2',
            //     value: 10250,
            //     detailContent: 'Labore laborum aliquip duis ad enim exercitation.'    
            // },            
            // {
            //     name: 'Ultrasound reproductive organ simulators',
            //     // displayName: 'Ultrasound reproductive organ simulators',
            //     id: '3.2.2',
            //     parent: '3.2',
            //     value: 17941,
            //     detailContent: 'Anim magna sit nisi cillum aliqua mollit.'    
            // },
            // {
            //     name: 'Radiologic Technology (training tools and computers)',
            //     // displayName: 'Radiologic Technology (training tools and computers)',
            //     id: '3.2.3',
            //     parent: '3.2',
            //     value: 10250,
            //     detailContent: 'Minim et in nostrud cupidatat nostrud dolor.'    
            // },
            // {
            //     name: 'Clinical Internship Support and Evaluation work',
            //     // displayName: 'Clinical Internship Support and Evaluation work',
            //     id: '3.2.4',
            //     parent: '3.2',
            //     value: 10250,
            //     detailContent: 'Consequat qui cupidatat aute et aliquip ex aute minim proident irure amet sit.'    
            // },
            // 
        {
            name: 'Nursing Scholarships',
            // formerly 'Highline College'
            displayName: 'Nursing Scholarships',
            id: '3.3',
            parent: '3',
            value: 1,
            detailContent: 'We provided funding for Kaplan courses to prepare all Highline College nursing students for their certification exams, licensing exam fees for 40 medical assistant students, and emergency academic funding for five nursing and five MA students.',
            linkText: '',
            url: ''
        },
            // Highline College Funding Details
            // {
            //     name: 'NCLEX Prep',
            //     // displayName: 'NCLEX Prep',
            //     id: '3.3.1',
            //     parent: '3.3',
            //     value: 1,
            //     detailContent: 'We provided funding for all nursing students Kaplan course to prepare nursing students for certification exam, NCLEX'    
            // },   
            // {
            //     name: 'MA-C',
            //     // displayName: 'MA-C',
            //     id: '3.3.2',
            //     parent: '3.3',
            //     value: 1,
            //     detailContent: 'Funding for 40 medical assistant students to take their licensing exam, MA-C'    
            // },            
            // {
            //     name: 'Emergency Academic Funding',
            //     // displayName: 'Emergency Academic Funding',
            //     id: '3.3.3',
            //     parent: '3.3',
            //     value: 1,
            //     detailContent: 'Emergency academic funding for 5 nursing and 5 MA students (tuition gaps, college related fees and supplies)'    
            // },                     
            // 
        {
            name: 'High School Healthcare Program',
            // formerly 'Sno-Isle High School Healthcare Program'
            displayName: 'High School Healthcare Program',
            id: '3.4',
            parent: '3',
            value: 1,
            detailContent: 'We worked with the Mukilteo School District to provide both classroom equipment and curriculum to encourage students to pursue their interests in healthcare careers. We were interested in supporting career paths that didn’t require a four-year degree, so we focused on Nursing and Medical Assisting.',
            detailContentCont: 'We helped update their training mannequins and hospital beds, provided medical terminology curriculum that supported soft-skill development, and supplied support for anatomy and physiology learning that instructors felt would strongly assist their students.',
            linkText: '',
            url: ''
        },
        {
            name: 'INSIGHT Scholarships',
            displayName: 'INSIGHT Scholarships',
            id: '3.5',
            parent: '3',
            value: 1,
            detailContent: 'With this scholarship program, high school students completed a public health project in conjunction with mentors over the summer. Our funds provided scholarships for students who could not readily afford to participate in this internship program, thus opening up the powerful learning that occurs in the UW program to more students.',
            linkText: 'Learn More',
            url: 'https://www.foundry10.org/insight'
        },
]

export default SunburstData;