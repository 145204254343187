import React, { Component } from 'react';
import './threeB.css';
import lottie from 'lottie-web';

export default class THREEB extends Component {
  componentDidMount() {
    lottie.loadAnimation({
      container: document.getElementById("threeB"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "/svg/3b.json"
    });
  }
  render() {
    return (
      <div id="threeB-container">
        <div class="everyday-section-title">
          <h4>THREE (THE SPACE)</h4>
        </div>
        <div class="threeB-content-container">
          <div class="threeB-svg-container">
            <div id="threeB" class="threeB-svg-content"></div>
          </div>
          <div class="threeB-text-container">
            <h2>We took time to refocus on the purpose of the gallery.</h2>
            <p>We wanted this experience to directly benefit the young artists involved.</p>
            <p>We asked each of them to define their work as part of a gallery book, and gave them the opportunity to sell their work at a price they set. </p>         
            <p>We organized artist meetups and workshops while designing an event experience that would truly celebrate this new community.</p>
          </div>
        </div>
      </div>
    );
  }
}