import React, { Component } from 'react';
import './ib.css';
import lottie from 'lottie-web';

class IB extends Component {
  componentDidMount() {
    lottie.loadAnimation({
      container: document.getElementById("ib"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "/svg/Ib.json"
    });
  }
  render() {
    return (
      <div id="ib-container">
        <div class="everyday-section-title">
          <h4>INTRO (THE IDEA)</h4>
        </div>
        <div class="ib-content-container">
          <div class="ib-svg-container">
            <div id="ib" class="ib-svg-content"></div>
          </div>
          <div class="ib-text-container">
              <h2>Seattle is a great place for art.</h2>
              <p>Huge international art festivals with artists from all over the world. Amazing events featuring local art made right here in the city. Even professional development workshops for artisans to hone their craft.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default IB;