import React, { Component } from 'react';
import './twoF.css';
import lottie from 'lottie-web';

export default class TWOF extends Component {
  componentDidMount() {
    lottie.loadAnimation({
      container: document.getElementById("twoF"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "/svg/2f_artist.json"
    });
  }
  render() {
    return (
      <div id="twoF-container">
        <div class="everyday-section-title">
          <h4>TWO (THE ARTISTS)</h4>
        </div>
        <div class="twoF-content-container">
          <div class="twoF-svg-container">
            <div id="twoF" class="twoF-svg-content"></div>
          </div>
          <div class="twoF-text-container">
            <h2>With our new name,</h2>
            <p>we launched a full on outreach campaign.</p>
            <p>All of a sudden we were swarmed with young painters, photographers, illustrators, painters, ceramics makers, fashion designers, mixed media artists, even performing artists and someone who made a spear!</p>
            <p>By the time our recruiting wrapped up, we had 80 artists apply!</p>
            <p>We guess the name worked.</p>
          </div>
        </div>
      </div>
    );
  }
}
