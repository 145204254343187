import React, { Component } from 'react';
import './centerHumanServices-Header.css';

export default class CenterHumanServicesHeader extends Component {
  render() {
    return(
      <div id="centerHumanServices-header-container">
          <div class="centerHumanServices-header-content-container">
            <h1>Center for Human Services Partnership</h1>
          </div>
      </div>
    );
  }
}