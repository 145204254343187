import React, { Component } from 'react';
import './creativityDesignPlay-Header.css';

export default class CreativityDesignPlayHeader extends Component {
  render() {
    return(
      <div id="creativityDesignPlay-header-container">
          <div class="creativityDesignPlay-header-content-container">
            <h1>Creativity, Design, and Play</h1>
          </div>
      </div>
    );
  }
}