import React, { Component } from 'react';
import './centerHumanServices-Description.css';

export default class CenterHumanServicesDescription extends Component {
  render() {
    return(
      <div id="centerHumanServices-description-container">
        <div class="centerHumanServices-description-content-container">
          <div class="centerHumanServices-desciption-paragraph">
            <p>Mental health is foundational to the success of students, affecting their ability to function, learn, and navigate daily challenges in their school and home life. Those challenges&mdash;and their impact on youth’s mental well-being&mdash;have been exacerbated by the increased levels of isolation, anxiety, and family stressors during COVID. Throughout the pandemic, foundry10 has partnered with schools to support student mental health by creating safe spaces, fostering mindfulness, and reducing anxiety for youth.</p>
          </div>
          <div class="centerHumanServices-desciption-paragraph">
            <p>We’ve seen first-hand how educators are going above and beyond to not only teach students academics, but also redevelop their social skills, counsel them, and help them deal with the trauma of the past years. However, the social-emotional needs of today’s students far exceed what educators can reasonably be expected to shoulder alone, especially since most do not have specialized training as mental health service providers. Even school counselors are struggling to keep up with the high demand for student support, leaving few mental health services available to youth from less resourced families.</p>
          </div>
          <div class="centerHumanServices-desciption-paragraph">
            <p>As part of our exploratory <a href="https://www.foundry10.org/programs/health-education" target="_blank" rel="noopener noreferrer">Health Education</a> initiative, foundry10 is partnering with the <a href="https://www.chs-nw.org" target="_blank" rel="noopener noreferrer">Center for Human Services</a> in 2021-2022 to increase the accessibility of mental health support for Seattle-area students. Based in Shoreline, Washington, CHS brings trained therapists to schools for one-one and group sessions, working with School Advocates and teachers to identify students who are most in need of help.</p>
          </div>
          <div class="centerHumanServices-desciption-paragraph">
            <p>Foundry10’s partnership with CHS will provide the Edmonds School District with a full-time therapist who will offer free-of-charge services to students during the school day. CHS will gather data on student experiences with the services provided, as well as feedback from school staff. foundry10 will use that data to help better understand how this type of direct service can benefit youth, and to help inform our decision-making with future Health Education programs.</p>
          </div>
          <div class="centerHumanServices-desciption-paragraph">
            <p>Be sure to bookmark this page and check back for project updates in Summer 2022!</p>
          </div>
        </div>
      </div>
    );
  }
}