import React, { Component } from 'react';
import './autoResearch-Slides.css';

export default class AutoResearchSlides extends Component {
  render() {
    return(
      <div id="autoResearch-womenInAuto-container">
        <div class="autoResearch-womenInAuto-content-container">
          <div class="interestArea-domain-title">
            <h2>Women in Auto Conference</h2>
          </div>
          <div class="autoResearch-womenInAuto-paragraph">
            <p>We were asked to give a keynote speech at the Women in Automotive winter conference in Los Angeles in 2018. Here are the slides from that talk, highlighting our research and practical tips for improving educational opportunities for women within the automotive industry.</p>
          </div>
          <a href="https://drive.google.com/open?id=1ckqHXAXIHtt7459TSyk0KlYThnFEjQaw" target="_blank" rel="noopener noreferrer">    
            <div class="autoResearch-cards-container">
              <img src="https://f10-programs.s3-us-west-2.amazonaws.com/automotive-tech/auto+page+pictures/Women+In+Auto+Slides.png" alt="women in auto slides" />
              <p class="autoResearch-cards-title">Women in Auto Presentation Slides</p>
              <div class="autoResearch-cards-overlay"></div>
            </div>  
          </a>
        </div>
      </div>
    );
  }
}