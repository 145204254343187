import React, { Component } from 'react';
import TripsLandingPageIntro from './tripsLandingPage-Intro';
import TripsLandingPageDescription from './tripsLandingPage-Description';
import TripsLandingPageLinks from './tripsLandingPage-Links';
import NewFooter from '../../../pages-other/NewFooter';
import './tripsLandingPage-Content.css';

export default class TripsLandingPageContent extends Component {
  render() {
    return(
      <div id="tripsLandingPage-content-container">
        <TripsLandingPageIntro />
        <TripsLandingPageDescription />
        <TripsLandingPageLinks />
        <NewFooter />
      </div>
    );
  }
}