import React, { Component } from 'react';

export default class AutoLandingPageLinks extends Component {
  render() {
    return(
      <div id="projectsLandingPage-links-container">
        <div class="projectsLandingPage-links-content-container">
          <div class="interestArea-cardsList2-container">
            {/* <div class="interestArea-domain-title">
              <h2>Projects</h2>
            </div> */}
            <ul id="interestArea-cardsList2" class="interestArea-events-list2">
              <li>
                <a href="https://www.foundry10.org/automotive-projects">
                  <div class="student-work-card-container">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/automotive-tech/Automotive+Projects+Big+Card.jpg" alt="automotive projects" />
                    <p id="Everyday Unseen" class="student-work-card-title">Automotive Projects</p>
                    <div id="Everyday Unseen" class="student-work-card-overlay"></div>
                 </div>
                </a>
              </li>
              <li>
                <a href="https://www.foundry10.org/automotive-research">
                  <div class="student-work-card-container">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/automotive-tech/auto+page+pictures/auto+research+big+card.jpg" alt="automotive research" />
                    <p id="Everyday Unseen" class="student-work-card-title">Automotive Research</p>
                    <div id="Everyday Unseen" class="student-work-card-overlay"></div>
                 </div>
                </a>
              </li>
              <li>
                <a href="https://www.foundry10.org/automotive-trips">
                  <div class="student-work-card-container">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/automotive-tech/auto+page+pictures/auto+trips+big+card.jpg" alt="automotive trips" />
                    <p id="Everyday Unseen" class="student-work-card-title">Automotive Trips</p>
                    <div id="Everyday Unseen" class="student-work-card-overlay"></div>
                 </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}