import React, { Component } from 'react';
import './annualReport2021Philanthropy-Partnerships.css';

export default class AnnualReportPhilanthropyPartnerships extends Component {
  render() {
    return(
      <div id="annualReport2021philanthropy-partnerships-container">
        <div className="annualReport2021philanthropy-partnerships-intro-container">
            <div className="annualReport2021philanthropy-partnerships-text-container">
                <h1>Working In Partnerships</h1>
                <p>In our work to expand the way people think about learning and bringing direct value to kids, foundry10 does not operate alone. Working towards this mission requires intentional collaboration with youth, educators, community members, and organizations. The Community Partnerships team at foundry10 worked over the past 18 months to provide internal support to staff around creating and sustaining partnerships with each of these groups and more. The team has done this through three specific areas of vision and action:</p>
                <div className="annualReport2021philanthropy-partnerships-banner-container">
                  <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Philanthropy+Landing+Page/org+level-8.png" alt="organizational level" />
                  <h2>ORGANIZATIONAL LEVEL</h2>
                </div>
                <p>The first goal this team focused on was encouraging greater intentionality in our partnerships at the organizational level. The team has worked to create a partnership mapping system that allows the whole organization to understand and see who and where our partners and community connections are, with the vision that we choose our partners with intention and through an equity lens.</p>
                <div className="annualReport2021philanthropy-partnerships-banner-container">
                  <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Philanthropy+Landing+Page/teams+level-8.png" alt="teams level" />
                  <h2>TEAMS LEVEL</h2>
                </div>
                <p>At the teams level, the Community Partnerships team worked to build internal communication structures so teams can more easily share and learn about partnership practices from one another, with the vision of more proactive transparency across teams.</p>
                <div className="annualReport2021philanthropy-partnerships-banner-container">
                  <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Philanthropy+Landing+Page/individual+level-8.png" alt="individual level" />
                  <h2>INDIVIDUAL LEVEL</h2>
                </div>
                <p>Finally, the team worked to support individuals across the organization in their partnership efforts. Every individual at foundry10 belongs to a community of practitioners, and many are embedded in the very communities we work with. We recognize the skill and knowledge that each individual brings with them through their community memberships and partnership experiences. We are also continuing to further develop these skills, so all staff are confident at building and sustaining partnerships at foundry10.</p>
            </div>
            <div className="annualReport2021philanthropy-partnerships-img-container">
                <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Philanthropy+Landing+Page/Asset+93-8.png" alt="working in partnership" />
            </div>
          </div>
      </div>
    );
  }
}

