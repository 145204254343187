import React, { Component } from 'react';
import MarineInternshipsIntro from './marineInternships-Intro';
import MarineInternshipsBody from './marineInternships-Body';
import NewFooter from '../../../pages-other/NewFooter';
import './marineInternships-Content.css';

export default class MarineInternshipsContent extends Component {
  render() {
    return(
      <div id="marineInternships-content-container">
        <MarineInternshipsIntro />
        <MarineInternshipsBody />
        <NewFooter />
      </div>
    );
  }
}