import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import MarineEndlessBlueHeader from './marineEndlessBlue-Header';
import MarineEndlessBlueContent from './marineEndlessBlue-Content';
import './marineEndlessBlue.css';

export default class MarineEndlessBlue extends Component {
  render() {
    return(
      <div id="marineEndlessBlue-container">
        <Helmet>
          <title>foundry10 Marine Science: Endless Blue, A Table-Top RPG</title>
          <meta
            name="title"
            content="foundry10 Marine Science: Endless Blue, A Table-Top RPG"
          />
          <meta
            name="description"
            content="Explore Endless Blue, a Table-Top RPG introducing youth to ocean careers through role play, problem-solving, and communication skills."
          />
          <meta property="og:title" content="foundry10 Marine Science: Endless Blue, A Table-Top RPG" />
          <meta
            property="og:description"
            content="Explore Endless Blue, a Table-Top RPG introducing youth to ocean careers through role play, problem-solving, and communication skills."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/marine-science-endless-blue" />
          <link rel="canonical" href="https://www.foundry10.org/marine-science-endless-blue" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Marine Science: Endless Blue, A Table-Top RPG",
                "description": "Explore Endless Blue, a Table-Top RPG introducing youth to ocean careers through role play, problem-solving, and communication skills.",
                "url": "https://www.foundry10.org/marine-science-endless-blue"
              }
            `}
          </script>
        </Helmet>
        <MarineEndlessBlueHeader />
        <MarineEndlessBlueContent />
      </div>
    );
  }
}