import React, { Component } from 'react';
import './annualReport2021GamesLearning-Header.css';

export default class AnnualReportGamesLearningHeader extends Component {
  render() {
    return(
      <div id="annualReport2021GamesLearning-header-container">
        <div className="annualReport2021GamesLearning-header-content">
          <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Subpages/Final+Header+Images/games-8.png" alt="foundry10 annual report 2020-2021 games and learning team header" />
        </div>
      </div>
    );
  }
}