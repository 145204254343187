import React, { Component } from 'react';
import './homeRedesign2023Header.css';

export default class HomeRedesign2023Header extends Component {
  render() {
    return(
      <div id="homeRedesign2023-header-container">
          <div className="homeRedesign2023-header-image-container" />
          <div className="homeRedesign2023-header-text-container">
            <h1>We are an education research organization with a philanthropic focus on <span className= "homeRedesign2023-header-emphasized-text">expanding ideas about learning and creating direct value for youth</span>.</h1>
            <a href="/what-we-do">Learn More →</a>
          </div>
      </div>
    );
  }
}