import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ResourcesLandingPageHeader from './resourcesLandingPage-Header';
import ResourcesLandingPageContent from './resourcesLandingPage-Content';
import './resourcesLandingPage.css';

export default class ResourcesLandingPage extends Component {
  render() {
    return(
      <div id="resourcesLandingPage-container">
        <Helmet>
          <title>foundry10 Marine Science Resources</title>
          <meta
            name="title"
            content="foundry10 Marine Science Resources"
          />
          <meta
            name="description"
            content="Discover a number of marine science resources, including a growing list of books, comics, and articles to fuel your passion for marine science."
          />
          <meta property="og:title" content="foundry10 Marine Science Resources" />
          <meta
            property="og:description"
            content="Discover a number of marine science resources, including a growing list of books, comics, and articles to fuel your passion for marine science."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/marine-science-resources" />
          <link rel="canonical" href="https://www.foundry10.org/marine-science-resources" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Marine Science Resources",
                "description": "Discover a number of marine science resources, including a growing list of books, comics, and articles to fuel your passion for marine science.",
                "url": "https://www.foundry10.org/marine-science-resources"
              }
            `}
          </script>
        </Helmet>
        <ResourcesLandingPageHeader />
        <ResourcesLandingPageContent />
      </div>
    );
  }
}