// Stories Archive Page
import React from "react";
import { Helmet } from 'react-helmet';
import { useQuery, gql } from "@apollo/client";
import PageHeader from "../components/page-header.js";
import ArchiveFilter from "../components/archive-filter.js";
import ArchiveList from "../components/archive-list.js";
import PreFooter from "../components/pre-footer.js";
import NewFooter from '../../pages-other/NewFooter.js';

// Get Page content
const entryQuery = gql`
  query pageQuery($slug: [String]) {
    entries(slug: $slug) {
      title
      ... on storiesArchive_storiesArchive_Entry {
        blurb
        guidingPrinciples {
          ... on guidingPrinciples_BlockType {
            guidingPrinciplesPrinciple
          }
        }
        footerQuote
        footerQuoteAttribution
        footerQuoteBg {
          url
        }
        footerCTA {
          ... on footerCTA_BlockType {
            footerCTATitle
            footerCTAContent
            footerCTALinkUrl {
              ariaLabel
              customText
              target
              text
              title
              type
              url
            }
          }
        }
      }
      ... on pressArchive_pressArchive_Entry {
        blurb
        guidingPrinciples {
          ... on guidingPrinciples_BlockType {
            guidingPrinciplesPrinciple
          }
        }
        footerQuote
        footerQuoteAttribution
        footerQuoteBg {
          url
        }
        footerCTA {
          ... on footerCTA_BlockType {
            footerCTATitle
            footerCTAContent
            footerCTALinkUrl {
              ariaLabel
              customText
              target
              text
              title
              type
              url
            }
          }
        }
      }
      ... on programsArchive_programsArchive_Entry {
        blurb
        guidingPrinciples {
          ... on guidingPrinciples_BlockType {
            guidingPrinciplesPrinciple
          }
        }
        footerQuote
        footerQuoteAttribution
        footerQuoteBg {
          url
        }
        footerCTA {
          ... on footerCTA_BlockType {
            footerCTATitle
            footerCTAContent
            footerCTALinkUrl {
              ariaLabel
              customText
              target
              text
              title
              type
              url
            }
          }
        }
      }
      ... on projectArchives_projectArchives_Entry {
        blurb
        guidingPrinciples {
          ... on guidingPrinciples_BlockType {
            guidingPrinciplesPrinciple
          }
        }
        footerQuote
        footerQuoteAttribution
        footerQuoteBg {
          url
        }
        footerCTA {
          ... on footerCTA_BlockType {
            footerCTATitle
            footerCTAContent
            footerCTALinkUrl {
              ariaLabel
              customText
              target
              text
              title
              type
              url
            }
          }
        }
      }
      ... on opportunitiesArchive_opportunitiesArchive_Entry {
        blurb
        guidingPrinciples {
          ... on guidingPrinciples_BlockType {
            guidingPrinciplesPrinciple
          }
        }
        footerQuote
        footerQuoteAttribution
        footerQuoteBg {
          url
        }
        footerCTA {
          ... on footerCTA_BlockType {
            footerCTATitle
            footerCTAContent
            footerCTALinkUrl {
              ariaLabel
              customText
              target
              text
              title
              type
              url
            }
          }
        }
      }
    }
  }
`;

const PageRender = (slug) => {
  const { data, loading, error } = useQuery(entryQuery, {
    variables: { slug },
  });
  if (loading) return null;
  if (error) return `Error! ${error}`;
  return data.entries.map((block, index) => {
    const preFooter = block.guidingPrinciples;
    if (typeof preFooter) {
      return <PreFooter data={data} />;
    }
    switch (block) {
      case "guidingPrinciples":
        return <PreFooter block={block} />;
      case "blurb":
        return <div>There's a blurb</div>;
    }
  });
};

const StoryArchive = ({ slug, channel }) => (
  <section id="contentArchive">
    <Helmet>
      <title>foundry10 Stories: Explore Blogs and Press Releases</title>
      <meta
        name="title"
        content="foundry10 Stories: Explore Blogs and Press Releases"
      />
      <meta
        name="description"
        content="Stay informed and inspired with our latest blogs, press releases, and research news about educators, researchers, and youth who expand ideas about learning."
      />
      <meta property="og:title" content="foundry10 Stories: Explore Blogs and Press Releases" />
      <meta
        property="og:description"
        content="Stay informed and inspired with our latest blogs, press releases, and research news about educators, researchers, and youth who expand ideas about learning."
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.foundry10.org/stories" />
      <link rel="canonical" href="https://www.foundry10.org/stories" />
      {/* Structured Data Script Tag */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "foundry10 Stories: Explore Blogs and Press Releases",
            "description": "Stay informed and inspired with our latest blogs, press releases, and research news about educators, researchers, and youth who expand ideas about learning.",
            "url": "https://www.foundry10.org/stories"
          }
        `}
      </script>
    </Helmet>
    <PageHeader slug={slug} />
    <ArchiveFilter />
    <ArchiveList channel={channel} />
    <PageRender slug={slug} />
    <NewFooter />
  </section>
);

export default StoryArchive;
