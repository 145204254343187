import React, { Component } from 'react';
import Slider from "react-slick";
import Avatar from '@material-ui/core/Avatar';

const QuoteSlide = (data) => {
  // console.log(data.data);
  let imageUrl = null;

    // Set Image URL if availible
    if (data.data.quotesQuoteImage.length > 0) {
        imageUrl = data.data.quotesQuoteImage[0].url;
    }
    //console.log(imageUrl)
  return (
    <div className="quoteSlide" key='Test'>
      <blockquote>
        {imageUrl && ( 
          <Avatar
            alt="Quote Attribution"
            src={imageUrl}
            // style={{ height: '120px', width: '120px', position:'absolute', top: '-140px', right:'45%' }}
            className="quoteAvitar"
          />
        )}
        {data.data.quotesQuote}
      </blockquote>
      
      {data.data.quotesQuoteAttribution && (
        <footer>
          <div
            dangerouslySetInnerHTML={{
              __html: data.data.quotesQuoteAttribution,
            }}
          ></div>
        </footer>
      )}
    </div>  
  )
}

const QuoteSlideshow = ({ block }) => {

    const settings = {
      dots: true,
      swipeToSlide: true,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1800,
          settings: {
            centerPadding: '350px'
          }
        },
        {
          breakpoint: 1615,
          settings: {
            centerPadding: '250px'
          }
        },
        {
          breakpoint: 1370,
          settings: {
            centerPadding: '200px'
          }
        },
        {
            breakpoint: 1250,
            settings: {
              centerPadding: '150px'
            }
          },
        {
            breakpoint: 1150,
            settings: {
              arrows: true
            }
          },
        {
            breakpoint: 1000,
            settings: {
              arrows: false
            }
          },
        {
            breakpoint: 800,
            settings: {
              centerPadding: '80px',
            }
          },
        {
            breakpoint: 675,
            settings: {
              centerMode: false,
              arrows: true
            }
          },
          {
              breakpoint: 450,
              settings: {
                centerMode: true,
                centerPadding: '0px',
                arrows: false
              }
            }
      ]
    };
    
    //const image = block.quoteAttribution[0]
    //console.log(block.quotesQuoteImage);
    let imageUrl = null;

    return (
      <section className='quoteSlideshow'>
        <div className='container'>
          {block.quoteSliderTitle && (
              <h3 className='text-xxl_bold'>
                {block.quoteSliderTitle}
              </h3>
            )}
          <div className='slideShowContainer'>
          
          <Slider {...settings}>
            {block.quotes.map((block) => (
              <QuoteSlide data={block} />
            ))}
          </Slider>
          </div>
        </div>
      </section>
    //<Slider {...settings}>
    //   {ResearchThemesData.map((eachTheme) => (
    //     <div className="researchRedesign-Slideshow-card" key={eachTheme.theme.title}>
    //                                     <div className="researchRedesign-Slideshow-card-content">   
    //                                         <div className="researchRedesign-Slideshow-card-content-container">
    //                                             <div className="researchRedesign-Slideshow-card-image">
    //                                                 <img src={eachTheme.theme.img} width="100%" alt={eachTheme.theme.alt}/>
    //                                             </div>
    //                                             <div className="researchRedesign-Slideshow-text">
    //                                                 <h2>{eachTheme.theme.title}</h2>
    //                                                 <p>{eachTheme.theme.content}</p>
    //                                                 <a href={eachTheme.theme.url}>
    //                                                     View related publications and resources
    //                                                     <i class="fa-solid fa-arrow-right-long"></i>
    //                                                 </a>
    //                                             </div>
    //                                         </div>                 
    //                                     </div>
    //                                 </div>
    //                     ))}
    // </Slider>
  )
}

export default QuoteSlideshow