import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ResearchRedesignHeader from './researchRedesign-Header';
// import ResearchRedesignIntro from './researchRedesign-Intro';
// import ResearchRedesignBreadcrumb from './researchRedesign-Breadcrumb';
import ResearchRedesignContent from './researchRedesign-Content';
import './researchRedesign.css';

export default class ResearchRedesign extends Component {
  render() {
    return(
      <div id="researchRedesign-container">
        <Helmet>
          <title>foundry10 Research: Our Approach, Labs, and Latest News</title>
          <meta
            name="title"
            content="foundry10 Research: Our Approach, Labs, and Latest News"
          />
          <meta
            name="description"
            content="Rigorous education research grounds every aspect of our work as an organization. Learn about foundry10's research labs, latest insights, and recent news."
          />
          <meta property="og:title" content="foundry10 Research: Our Approach, Labs, and Latest News" />
          <meta
            property="og:description"
            content="Rigorous education research grounds every aspect of our work as an organization. Learn about foundry10's research labs, latest insights, and recent news."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/research" />
          <link rel="canonical" href="https://www.foundry10.org/research" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Research: Our Approach, Labs, and Latest New",
                "description": "Rigorous education research grounds every aspect of our work as an organization. Learn about foundry10's research labs, latest insights, and recent news.",
                "url": "https://www.foundry10.org/research"
              }
            `}
          </script>
        </Helmet>
        <ResearchRedesignHeader />
        {/* <ResearchRedesignIntro /> */}
        {/* <ResearchRedesignBreadcrumb /> */}
        <ResearchRedesignContent />
      </div>
    );
  }
}
