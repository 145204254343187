import React from "react";
import './Marine-Biology.css';

export default function MarineBiology({ title, subtitle, dark, id }) {
  return (
    // <div className={"section" + (dark ? " section-dark" : "")}>
    <div className="marineBiology-container">
      <div className="marineBiology-content-container" id={id}>
        <div className="marineBiology-final-content-container">
          <div className="marineBiology-illustration">
            <h1>Click below to learn more!</h1>
            <a class="marineBiology-link" href="/marine-science-careers/marine-biology">
              <img src='https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/marinebiology1.png' alt='marine biology illustration'/>
              <p class="marineBiology-title">Meet Melissa!</p>
              <div class="marineBiology-cards-overlay"></div>
            </a>
          </div>
          <div className="marineBiology-description">
              <h2>"Ocean Rock Star"</h2>
              <p>RELATED TITLES: Ecologist, Conservationist, Educator, Researcher</p>
              <p>Like a pop celebrity, marine biologists get a lot of attention especially for direct work with charismatic creatures like sharks and dolphins. The behind the scenes research, data analysis, grant-writing, and education requirements are often the less-glamorous bulk of this career.</p>
            <div className="marineBiology-requirements">
              <h2>Basic Degree/Training Requirements</h2>
              <ul className="marineBiology-iconList">
                <li>
                  <div class="marineBiology-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/white-HS-Diploma.png" alt="high school diploma" />
                  </div>
                </li>
                <li>
                  <div class="marineBiology-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/white-Undergrad-Cap.png" alt="undergrad degree" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}