import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import AnnualReportCovidHeader from './annualReport2021Covid-Header';
import AnnualReportCovidContent from './annualReport2021Covid-Content';
import './annualReport2021Covid.css';

export default class AnnualReport2021Covid extends Component {
  render() {
    return(
      <div id="annualReport2021covid-container">
        <Helmet>
          <title>foundry10 Annual Report 2021-2022: COVID-19 Response</title>
          <meta
            name="title"
            content="foundry10 Annual Report 2021-2022: COVID-19 Response"
          />
          <meta
            name="description"
            content="Explore the foundry10 Annual Report 2021 for insights into our COVID-19 response. Learn more about our efforts in research, programming, and philanthropy."
          />
          <meta property="og:title" content="foundry10 Annual Report 2021-2022: COVID-19 Response" />
          <meta
            property="og:description"
            content="Explore the foundry10 Annual Report 2021 for insights into our COVID-19 response. Learn more about our efforts in research, programming, and philanthropy."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/annual-report/2021/covid-19-response" />
          <link rel="canonical" href="https://www.foundry10.org/annual-report/2021/covid-19-response" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Annual Report 2021-2022: COVID-19 Response",
                "description": "Explore the foundry10 Annual Report 2021 for insights into our COVID-19 response. Learn more about our efforts in research, programming, and philanthropy.",
                "url": "https://www.foundry10.org/annual-report/2021/covid-19-response"
              }
            `}
          </script>
        </Helmet>
        <AnnualReportCovidHeader />
        <AnnualReportCovidContent />
      </div>
    );
  }
}