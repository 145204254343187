import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import './AnnualReport-Philanthropy.css';

export default class AnnualReportPhilanthropy extends Component {
  render() {
    return(
      <div id="annualReportPhilanthropy-container">
        <div class="annualReportPhilanthropy-content-container">
          <div class="annualReportPhilanthropy-imageDescription-container">
            <div class="annualReportPhilanthropy-image-container">
              <img src='https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Landing+Page/FINAL/Philan_Landingpage-8+final.png' alt='philanthropy illustration'/>
            </div>
            <div class="annualReportPhilanthropy-description-container">
              <h1>PHILANTHROPY</h1>
              <div class="annualReportPhilanthropy-titleLine"></div>
              <p>Philanthropy at foundry10 takes a collaborative, community-based approach. We partner with schools and community organizations to problem-solve and enhance learning opportunities for youth. There is a flexibility and responsiveness to our work; at times we are working to support curriculum adoption, other times the need we are working to fill involves student social emotional support and, in this past year, much of our philanthropy was focused on meeting basic needs. We come into our conversations curious to learn more about the communities we serve and what they see as most necessary to help children, and then we work to ethically, efficiently and collaboratively address those needs.</p>
            </div>
          </div>
          <div class="annualReportPhilanthropy-subpages-container">
            <ul id="annualReportPhilanthropy-cardsList2" class="annualReportPhilanthropy-events-lists2">
                <li>
                  <div class="annualReportPhilanthropy-cards-container">
                    <h3>Scholarships</h3>
                    <p>We envision a world where all students have the resources they need to pursue their educational goals. To help meet this goal, we have been providing a range of scholarships to students for the last three years. We have partnered with college access organizations, local high schools, community colleges, and trades schools to offer scholarships tailored to the needs of particular student communities.</p>
                  </div>
                </li>
                <li>
                  <div class="annualReportPhilanthropy-cards-container">
                    <h3>Responsive Giving</h3>
                    <p>All of the work we do at foundry10 involves philanthropy, but one of the hallmarks of our philanthropic work is the flexible way that we work within communities. Though there are often similar needs in various youth serving communities, there are also an array of differences that we attempt to address as smoothly and efficiently as possible.</p>
                  </div>
                </li>
               <li>
                  <div class="annualReportPhilanthropy-cards-container">
                    <h3>Direct Giving</h3>
                    <p>While most of our philanthropic work focuses on creating valuable learning experiences for youth, we recognized an acute need during COVID to provide emergency relief funding directly to families and individuals.</p>
                  </div>
                </li>
            </ul>
            <Button style={{ fontFamily: 'Helvetica Neue Light', fontWeight: 'bold', color: '#ffffff', textTransform: 'capitalize', boxShadow: '1px 1px #b1b1b1', width: '150px', borderRadius: '12px', background: 'radial-gradient(#cc886f, #d14e1d)' }} href="/annual-report/2021/philanthropy">Learn more</Button>
          </div>
        </div>
      </div>
    );
  }
}