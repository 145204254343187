import React from "react";
import './Professional-Merfolk.css';

export default function ProfessionalMerfolk({ title, subtitle, dark, id }) {
  return (
    // <div className={"section" + (dark ? " section-dark" : "")}>
    <div className="professionalMerfolk-container">
      <div className="professionalMerfolk-content-container" id={id}>
        <div className="professionalMerfolk-final-content-container">
          <div className="professionalMerfolk-illustration">
            <h1>Click below to learn more!</h1>
            <a class="professionalMerfolk-link" href="/marine-science-careers/professional-merfolk">
              <img src='https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/mermaiding1.png' alt='professional merfolk illustration'/>
              <p class="professionalMerfolk-title">Meet Sara & Jax!</p>
              <div class="professionalMerfolk-cards-overlay"></div>
            </a>
          </div>
          <div className="professionalMerfolk-description">
            <h2>"Ocean Ambassador"</h2>
            <p>RELATED TITLES: Professional Mermaid, Professional Merman, Free Diver</p>
            <p>As a spokesperson, advocate, and ambassador for the ocean, the professional mermaid swims a fine line between entertainment and education. Whether freediving in an aquarium or hiring out for special events, mermaids amplify the magic and wonder of the sea to the widest possible audience.</p>
            <div className="professionalMerfolk-requirements">
              <h2>Basic Degree/Training Requirements</h2>
              <ul className="professionalMerfolk-iconList">
                <li>
                  <div class="professionalMerfolk-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/black-HS-Diploma.png" alt="high school diploma" />
                  </div>
                </li>
                <li>
                  <div class="marineGeologist-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/black-SCUBA.png" alt="scuba certificattion" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}