import React, { Component } from 'react';
import './annualReport2021Interventions-Header.css';

export default class AnnualReportInterventionsHeader extends Component {
  render() {
    return(
      <div id="annualReport2021Interventions-header-container">
        <div className="annualReport2021Interventions-header-content">
          <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Subpages/Final+Header+Images/intervention-8.png" alt="foundry10 annual report 2020-2021 interventions team header" />
        </div>
      </div>
    );
  }
}