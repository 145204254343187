import React, { Component } from 'react';
import './educatorResources-Header.css';

export default class EducatorResourcesHeader extends Component {
  render() {
    return(
      <div id="educatorResources-header-container">
          <div class="educatorResources-header-content-container">
            <h1>Resources for Educators</h1>
          </div>
      </div>
    );
  }
}