import React from "react";
import './Academia.css';

export default function Academia({ title, subtitle, dark, id }) {
  return (
    // <div className={"section" + (dark ? " section-dark" : "")}>
    <div className="academia-container">
      <div className="academia-content-container" id={id}>
        <div className="academia-final-content-container">
          <div className="academia-illustration">
            <h1>Click below to learn more!</h1>
            <a class="academia-link" href="/marine-science-careers/academia">
              <img src='https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/academia1.png' alt='academia illustration'/>
              <p class="academia-title">Meet Jose!</p>
              <div class="academia-cards-overlay"></div>
            </a>
          </div>
          <div className="academia-description">
            <h2>"Ocean Coach"</h2>
            <p>RELATED TITLES: Teacher, Educator, Academic Researcher</p>
            <p>Lectures, field trips, and class assignments! Marine biology professors are entrusted with inspiring the next generation of ocean-professionals and supporting their growth through innovative learning. Most professors are also balancing their own research and field activities at the same time.</p>
            <div className="academia-requirements">
              <h2>Basic Degree/Training Requirements</h2>
              <ul className="academia-iconList">
                <li>
                  <div class="academia-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/black-HS-Diploma.png" alt="high school diploma" />
                  </div>
                </li>
                <li>
                  <div class="academia-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/black-Undergrad-Cap.png" alt="undergrad degree" />
                  </div>
                </li>
                <li>
                  <div class="academia-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/black-Grad-Degree.png" alt="graduate degree" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}