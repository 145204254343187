import React from 'react';

const ThirdPartyCode = ({ block }) => {
  // if (!block.thirdPartyCodeTitle || !block.thirdPartyCodeCode) {
  //   return null;
  // }
  if (block.thirdPartyCodeTitle || block.thirdPartyCodeCode) {
    return (
      <section className='thirdPartyCode'>
        <div className='container'>
          {block.thirdPartyCodeTitle && (
            <h3 className='text-xl_bold'>{block.thirdPartyCodeTitle}</h3>
          )}
          {block.thirdPartyCodeCode && (
            <div 
              dangerouslySetInnerHTML={{
              __html: block.thirdPartyCodeCode,
              }}
            ></div>
          )}
        </div>
      </section>
    );
  } else {
    return null;
  }
}

export default ThirdPartyCode