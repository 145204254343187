import React, { Component } from 'react';
import KcptIntro from './kcpt-Intro';
import KcptBody from './kcpt-Body';
import NewFooter from '../../../../pages-other/NewFooter';
import './kcpt-Content.css';

export default class KcptContent extends Component {
  render() {
    return(
      <div id="kcpt-content-container">
        <KcptIntro />
        <KcptBody />
        <NewFooter />
      </div>
    );
  }
}