import React, { Component } from 'react';
import './threeA.css';
import lottie from 'lottie-web';

export default class THREEA extends Component {
  componentDidMount() {
    lottie.loadAnimation({
      container: document.getElementById("threeA"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "/svg/3a.json"
    });
  }
  render() {
    return (
      <div id="threeA-container">
        <div class="everyday-section-title">
          <h4>THREE (THE SPACE)</h4>
        </div>
        <div class="threeA-content-container">
          <div class="threeA-svg-container">
            <div id="threeA" class="threeA-svg-content"></div>
          </div>
          <div class="threeA-text-container">
            <h2>We pushed ahead with a marketing campaign!</h2>
            <p>We made posters, pins and buttons. We reached out to schools and community centers and anyone else we could think to email!</p>
          </div>
        </div>
      </div>
    );
  }
}