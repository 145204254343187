import React, { Component } from 'react';
import './oneAB.css';
import lottie from 'lottie-web';

class ONEAB extends Component {
  componentDidMount() {
    lottie.loadAnimation({
      container: document.getElementById("oneAB"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "/svg/1b.json"
    });
  }
  render() {
    return (
      <div id="oneAB-container">
        <div class="everyday-section-title">
          <h4>ONE (THE TEAM)</h4>
        </div>
        <div class="oneAB-content-container">
          <div class="oneAB-text-container">
            <h2>We had a cool idea...and nothing else.</h2>
          </div>
          <div class="oneAB-svg-container">
            <div id="oneAB" class="oneAB-svg-content"></div>
          </div>
          <div class="oneAB-text-container-two">
            <p>• We had no artistic design team, but we did know some hip hop artists who were willing to help!</p>
            <p>• We had no budget, but when we pitched it to the organization, we got a big green light and the support to move forward!</p>
            <p>• We had no space for art, but someone we knew had a converted auto body shop with plenty of space for art!</p>
          </div>
        </div>
      </div>
    );
  }
}

export default ONEAB;