import React from "react";
import ThemesSlideshow from "./educatorResources-TestimonialsSlideshow";
import './educatorResources-Testimonials.css'

function EducatorResourcesTestimonials() {

return (
<div id="educatorResources-testimonials-container">
    <div className="educatorResources-testimonials-content-container">
        <h2 className="educatorResources-testimonials-title"><span>Hear from Peers</span></h2>
        <div className="educatorResources-testimonials-slideshow-container">
          <ThemesSlideshow />
        </div>
    </div>
</div>
)

}

export default EducatorResourcesTestimonials;