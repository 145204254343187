import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';


// window.location = 'https://www.foundry10.org' //use for heroku deployment
ReactDOM.render(<App />, document.getElementById('root'));
//registerServiceWorker();
unregister();
