import React, { Component } from 'react';
import './annualReport2021Philanthropy-Header.css';

export default class AnnualReportPhilanthropyHeader extends Component {
  render() {
    return(
      <div id="annualReport2021philanthropy-header-container">
        <div className="annualReport2021philanthropy-header-content">
          <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Philanthropy+Landing+Page/header.png" alt="foundry10 annual report 2020-2021 philanthropy header" />
        </div>
      </div>
    );
  }
}