import React, { Component } from 'react';
import './autoResearch-WIA.css';

export default class AutoResearchWIA extends Component {
  render() {
    return(
      <div id="autoResearch-womenInAuto-container">
        <div class="autoResearch-womenInAuto-content-container">
          <div class="interestArea-domain-title">
            <h2>Women in Auto</h2>
          </div>
          <div class="autoResearch-womenInAuto-paragraph">
            <p>Through our work in the automotive field, we are particularly intrigued by the educational pathways of women who have become professionals in the automotive world. Though there is still a gender imbalance within the industry, more women are seeking career opportunities in automotive related professions. Our goal with this study is to inform, expand and provide evidence for educational strategies that work to support women in their career aspirations within the automotive industry.</p>
          </div>
          <a href="https://drive.google.com/open?id=1vYVo887qNLcce9DRIquOfaeCc9miQndK" target="_blank" rel="noopener noreferrer">    
            <div class="autoResearch-cards-container">
              <img src="https://f10-programs.s3-us-west-2.amazonaws.com/automotive-tech/auto+page+pictures/Women+In+Auto+GET.png" alt="women in auto graphic" />
              <p class="autoResearch-cards-title">Women in Auto Graphic</p>
              <div class="autoResearch-cards-overlay"></div>
            </div>  
          </a>
        </div>
      </div>
    );
  }
}