import React, { Component } from 'react';
import './AnnualReport-Header.css';

export default class AnnualReportHeader extends Component {
  render() {
    return(
      <div id="annualReport2021landing-header-container">
          <div class="annualReport2021landing-header-content-container">
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Landing+Page/Header+Title.png" alt="foundry10 annual report 2020-2021 responding to the moment"/>
          </div>
      </div>
    );
  }
}