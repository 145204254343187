import React, { Component } from 'react';
import './annualReport2023.css';
import NewFooter from '../../pages-other/NewFooter';

export default class AnnualReport2023 extends Component {

  render() {
      return (
        <div id="annualReport2023-main-container">
          {/* HEADER */}
          <div class="annualReport2023-header-container" style={{backgroundImage: `url(https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2023/2023+Annual+Report+Header.JPG`}}>
            <div class="annualReport2023-header-content-container">
              <h1>Leaning into Strengths and Looking Forward: 2022-2023 Annual Report</h1>
            </div>
          </div>
          {/* BODY */}
          <div id="annualReport2023-main-content-container">
            {/* BRIEF & DOCUMENT */}
            <div id="annualReport2023-briefDoc-container">
              <div class="annualReport2023-briefDoc-content-container">
                <div class="annualReport2023-brief-container">
                  <div class="annualReport2023-brief-description-container">
                    <p>This report celebrates foundry10’s key contributions, collaborations, and impacts within education and learning spaces in 2022-2023.</p>
                  </div>
                </div>
                <div class="annualReport2023-document-container">
                    <p>Read the report here!</p>
                    <div className='research-documentViewerHolder'>
                        <a className='research-documentViewerEmbed' 
                            target='_blank' 
                            rel ="noopener noreferrer"
                            href='https://drive.google.com/file/d/1-qvE1yYV5OCV8Iu3PvEfSoz93FY_Co2s/view?usp=sharing' 
                            alt='firming up foundations and forging ahead.  foundry10 fy2022-2023 annual report'
                            >
                            <img class={document.name} alt='fy2022-2023 annual report' longdesc='firming up foundations and forging ahead.  foundry10 fy2022-2023 annual report' src='https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2023/2023+Annual+Report_Thumbnail_130x200.jpg' />
                            <span class="research-documentViewerTitleNew">
                                <div class="research-documentViewerTitleNew2">FY2022-2023 Annual Report</div>
                            </span>
                        </a>
                    </div>
                </div>
              </div>
            </div>
            {/* DESCRIPTION */}
            <div id="annualReport2023-description-container">
              <div class="annualReport2023-description-content-container">
                {/* <h4>November 2023</h4> */}
                <p>This report highlights the many ways that foundry10 has been “Leaning into Strengths and Looking Forward” over the past year, including updates across our research, education programming, and philanthropy. Dive in to learn about foundry10’s new “Interest Areas,” highlights from our first 10 years as an organization, stories and voices from our partners and collaborators, and more.</p>
              </div>
            </div>
            <NewFooter />
          </div>
        </div>

      )
  }
}