import React, { useState } from 'react';
import './ResearchTopics-Three.css';

function ResearchTopicsThree() {
    const [isActive, setIsActive] = useState(false);
    // const [hasMore, setHasMore] = useState(false);

    return (


            <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-item">
                <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-title" onClick={() => setIsActive(!isActive)}>
                    <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-image">
                        <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Technology%2C+Media%2C+and+Information+Literacy/Research+Topics/IA+Team+Page+Icons_Build+Technical+Skills+to+Create+Digital+Tools+Responsibly+1-.png" alt="two classmates using computers"/>
                    </div>
                    <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-text">
                        <h2 className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-title-text">Build Technical Skills to Create Digital Tools Responsibly</h2>
                        {/* <h3>{research.subtitle}</h3> */}
                    </div>
                    <i className={ isActive ?  `fa-solid fa-angle-up`:`fa-solid fa-angle-down`}></i>                  
                </div>
                {isActive && <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-content">
                    <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-description">
                        <p>In an increasingly interconnected world, the role of youth and educators extends beyond only consuming technology, media, and information to active and responsible creation in these areas. Empowering them to be proficient and ethical developers and creators is a pivotal step in shaping the future of our digital landscape. This area of research will focus on better understanding how to equip young people with essential skills and ethical considerations that allow them to understand broader implications of how they engage with the digital space, whether they’re creating an app, a digital campaign, or a piece of content.</p>
                    </div>  
                    <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-descriptionTwo">
                        <h2>Guiding Questions:</h2>
                        <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How can young people be meaningfully engaged in the development of ethical digital technologies, media, and information?</p>
                        </div>
                        <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>What are the hopes young people have for the future of digital technologies, media, and information, and how can we equip them with the opportunities and skills to make those hopes a reality?</p>
                        </div>
                    </div>
                    {/* <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-descriptionThree">
                        <h2>Curriculum + Education</h2>
                        <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>What are important considerations for school-based civic learning opportunities that support meaningful youth engagement online?</p>
                        </div>
                        <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How should we approach curriculum focused on digital citizenship more effectively to support the skills youth need to thrive in today’s society?</p>
                        </div>
                        <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>To what extent are young people’s perspectives included in the development & implementation of curriculum about being a digital citizen?</p>
                        </div>
                    </div> */}
                </div>}
            </div>

    );
}

export default ResearchTopicsThree;
