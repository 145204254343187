import React, { Component } from 'react';
import AnnualReportTradesOverview from './annualReport2021Trades-Overview';
import AnnualReportTradesSpotlights from './annualReport2021Trades-Spotlights';
import AnnualReport2021ProgramLinks from '../annualReport2021ProgramLinks/AnnualReport2021-ProgramLinks';
import NewFooter from '../../../../pages-other/NewFooter';
import './annualReport2021Trades-Content.css';

export default class AnnualReportTradesContent extends Component {
  render() {
    return(
      <div id="annualReport2021Trades-content-container">
        <AnnualReportTradesOverview />
        <AnnualReportTradesSpotlights />
        <AnnualReport2021ProgramLinks />
        <NewFooter />
      </div>
    );
  }
}