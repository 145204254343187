import React, { useState, useMemo } from "react";

import { useQuery, gql } from "@apollo/client";
import Grid from "@material-ui/core/Grid";

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});
// const year = queryParams.get("year")

// Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
let searchParam = params.filter;

// Set search param as empty in value not availible
if (!searchParam) {
  searchParam = "";
}

// Get Page content
function NewsletterTimes() {
  var [year, setYear] = useState();
  const [page, setPage] = useState(0);
  const [startDate, setstartDate] = useState("2022-01-01");
  const [endDate, setendDate] = useState("2022-12-01");
  let fullpath = "";
  fullpath = window.location.search;
  var urlYear = "";
  urlYear = fullpath.replace("?year=", "");

  const newsletterQuery = gql`
    query newsletterQuery {
      entries(
        section: "newsletters"
        postDate: ["and", ">= 2010-01-01", "< 2050-12-31"]
      ) {
        title
        postDate @formatDateTime(format: "Y")
        ... on newsletters_default_Entry {
          blurb
          linkUrl
        }
      }
    }
  `;

  const PAGE_SIZE = 10;
  const { data, loading, error } = useQuery(newsletterQuery, {
    variables: {
      limit: page * PAGE_SIZE + 10,
      startDate: startDate,
      endDate: endDate,
    },
  });
  if (loading) return null;
  if (error) return `Error! ${error}`;

  const setYearUrl = (Year) => {
    if (urlYear == Year) {
      window.location.href = "/news/newsletters";
    } else {
      setYear(Year);
      var queryParams = new URLSearchParams(window.location.search);
      const p = data.entries.map((block) => {
        return block.postDate;
      });
      // Set new or modify existing parameter value.
      queryParams.set("year", Year);
      window.location.href = "/news/newsletters?" + queryParams;
    }
  };
  var res = [];
  var l = [];
  return (
    <Grid item md={3} sm={12} className="newsletterSidebar">
      <h3 className="text-half-step_strong">Year</h3>
      <ul style={{ list_style_type: "none" }} className="yearList">
        {data.entries.map((block) => {
          {
            l.push(block.postDate);
          }
        })}
        <p style={{ display: "none" }}> {(res = [...new Set(l)])}</p>

        {res.map((filterDate, index) => {
          return (
            <li key={index}>
              <input
                onClick={(e) => setYearUrl(filterDate)}
                checked={
                  urlYear == filterDate
                    ? true
                    : year == filterDate
                    ? true
                    : false
                }
                type="checkbox"
                value={filterDate}
                className="form-checkbox checkbox"
              />
              <span>{filterDate}</span>
            </li>
          );
        })}
        {/* <li><input onClick={(e)=>setYear(e.target.value)} checked = {year == '2023' ? true : false} type="checkbox" value="2023" className="form-checkbox checkbox"/><span>2023</span></li>
       <li><input onClick={(e)=>setYear(e.target.value)} checked = {year == '2024' ? true : false} type="checkbox" value="2024" className="form-checkbox checkbox"/><span>2024</span></li>
       <li><input onClick={(e)=>setYear(e.target.value)} checked = {year == '2025' ? true : false} type="checkbox" value="2025" className="form-checkbox checkbox"/><span>2025</span></li>
       <li><input onClick={(e)=>setYear(e.target.value)} checked = {year == '2026' ? true : false} type="checkbox" value="2026" className="form-checkbox checkbox"/><span>2026</span></li> */}
      </ul>
    </Grid>
  );
}
const NewsletterList = () => {
  var fullpath = "";
  fullpath = window.location.search;
  var year = "";
  year = fullpath.replace("?year=", "");
  const start = ">= " + year + "-01-01";
  const end = "< " + year + "-12-31";
  const newsletterQuery = gql`
    query newsletterQuery(
      $start: String!
      $end: String!
      $limit: Int!
      $offset: Int!
    ) {
      entries(
        section: "newsletters"
        postDate: ["and", $start, $end]
        limit: $limit
        offset: $offset
      ) {
        title
        postDate @formatDateTime(format: "F j, Y")
        ... on newsletters_default_Entry {
          blurb
          linkUrl
        }
      }
      entryCount(section: "newsletters", postDate: ["and", $start, $end])
    }
  `;
  const newsletterAllQuery = gql`
    query pageQuery($limit: Int!, $offset: Int!) {
      entries(
        section: "newsletters"
        before: "2050"
        after: "2000"
        limit: $limit
        offset: $offset
      ) {
        title
        postDate @formatDateTime(format: "F j, Y")
        ... on newsletters_default_Entry {
          blurb
          linkUrl
        }
      }
      entryCount(section: "newsletters", before: "2050", after: "2000")
    }
  `;
  const { data, loading, error, fetchMore } = useQuery(
    year == "" ? newsletterAllQuery : newsletterQuery,
    {
      variables: {
        offset: 0,
        limit: 10,
        ...(year == ""
          ? {}
          : {
              start: start,
              end: end,
            }),
      },
    }
  );

  const showLoadMore = useMemo(() => {
    if (data) {
      return data.entryCount > data.entries.length;
    }

    return false;
  }, [data]);

  const onLoadMore = () => {
    fetchMore({
      variables: {
        offset: data.entries.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          entries: [...prev.entries, ...fetchMoreResult.entries],
        });
      },
    });
  };
  if (loading) return null;

  if (error) return `Error! ${error}`;

  return (
    <Grid item md={8} sm={12} className="newsletterListContent">
      <h3 className="text-large_bold">Explore our past newsletters</h3>
      {data.entries.map((block, index) => {
        return (
          <div key={index} className="newsletterCard large">
            <a className="text-half-step" href={block.linkUrl} target="_blank">
              {" "}
              <h4 className="text-medium_bold">{block.title}</h4>
            </a>
            <div className="resourceBlurb">
              <div
                dangerouslySetInnerHTML={{
                  __html: block.blurb,
                }}
              ></div>
            </div>
          </div>
        );
      })}
      {showLoadMore && (
        <button className="button loadMore" onClick={onLoadMore}>
          Load More
        </button>
      )}
    </Grid>
  );
};

const ArchiveList = () => (
  <section id="newsletterList">
    <div className="container">
      <Grid container spacing={40}>
        <NewsletterTimes />
        <NewsletterList />
      </Grid>
    </div>
  </section>
);

export default ArchiveList;
