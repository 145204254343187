import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
});


const BlockData = ( { block } ) => {
  //console.log(block);
  const classes = styles;
  const heading = block.contentTitle;
  const content = block.contentContent;
  const link = block.linkUrl;
  const alignment = block.contentAlignment;
  

  return (
    <div className="container">
      <Grid container spacing={24} className={alignment}>
        <Grid item xs={12}>
          {heading && (
            <h2 className='text-xl_bold'>
              {heading}
            </h2>
          )}
          {block.contentContent && (
            <div
                dangerouslySetInnerHTML={{
                __html: block.contentContent,
                }}
            ></div>
          )}
          {link.customText ? (
            <a className='button' href={link.url}>{link.customText}</a>
          ) : (
            link.url && (
              <a className='button' href={link.url}>{link.url}</a>
            )
          )}
        </Grid>  
      </Grid>
    </div>
  ) 
}

export default class StandardContent extends Component {
  render() {
    return(
      <section className="contentBlock standardContent">
          <BlockData block={this.props.block} />
      </section>
    );
  }
}
