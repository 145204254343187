import React, { Component } from 'react';
import AnnualReportCovidNeeds from './annualReport2021Covid-Needs';
import AnnualReportCovidSupport from './annualReport2021Covid-Support';
import AnnualReportCovidEquity from './annualReport2021Covid-Equity';
// import AnnualReportSharingWeb from './annualReport2021Sharing-Web';
import './annualReport2021Covid-Highlights.css';

export default class AnnualReportCovidContent extends Component {
  render() {
    return(
      <div id="annualReport2021covid-highlights-container">
        <AnnualReportCovidNeeds />
        <AnnualReportCovidSupport />
        <AnnualReportCovidEquity />
        {/* <AnnualReportSharingWeb /> */}
      </div>
    );
  }
}