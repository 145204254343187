import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import NewFooter from '../../../../pages-other/NewFooter';
import './People.css';

export default class SaraJax extends Component {
  render() {
    return(
        <div id="marineCareers-main-container">
          <Helmet>
          <title>foundry10 Guide to Marine Science Careers: Professional Merfolk</title>
          <meta
            name="title"
            content="foundry10 Guide to Marine Science Careers: Professional Merfolk"
          />
          <meta
            name="description"
            content="Explore an exciting marine science career in professional merfolk. Dive into what life is like in this career with interviews and resource links."
          />
          <meta property="og:title" content="foundry10 Guide to Marine Science Careers: Professional Merfolk" />
          <meta
            property="og:description"
            content="Explore an exciting marine science career in professional merfolk. Dive into what life is like in this career with interviews and resource links."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/marine-science-careers/professional-merfolk" />
          <link rel="canonical" href="https://www.foundry10.org/marine-science-careers/professional-merfolk" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Guide to Marine Science Careers: Professional Merfolk",
                "description": "Explore an exciting marine science career in professional merfolk. Dive into what life is like in this career with interviews and resource links.",
                "url": "https://www.foundry10.org/marine-science-careers/professional-merfolk"
              }
            `}
          </script>
        </Helmet>
        {/* MAIN */}
        <div id="marineCareers-main-content-container">
          {/* HEADER */}
          <div id="marineCareers-header-container">
            <div class="marineCareers-header-title-container">
              <h3>Professional Merfolk</h3>
              <h2>Meet Sara</h2>
              <div class="marineCareers-headerImage-container">
                <div class="marineCareers-headerImage-content-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/People/Sara+Ferguson.jpg" alt="sara ferguson"/>
                </div>
              </div>
              <p>My name is Sara Ferguson, but many call me The Mindful Mermaid. I am a professional mermaid, and I teach mermaid classes, freediving, and scuba certification classes. As a professional mermaid, I am comfortable swimming in the ocean in my tail, diving down and navigating without goggles or a mask. I have swam in oceans all around the world with amazing critters like whale sharks, turtles, and wild dolphins. I'm in this job because I love the oceans deeply, I want to protect them and I love working in the water. As a mermaid, I am an ocean ambassador, sharing fun facts about sea life (octopus are nearly deaf, eagle rays purr and seahorses communicate through a series of clicks) and helping people get excited about taking better care of the oceans and their amazing wildlife.</p>
            </div>
            <div class="marineCareers-header-title-container">
              <h2>Meet Jax</h2>
              <div class="marineCareers-headerImage-container">
                <div class="marineCareers-headerImage-content-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/People/Merman+Jax.jpeg" alt="merman jax"/>
                </div>
              </div>
              <p>Merman Jax has been the most actively working professional merman for about 8 years. Based out of Los Angeles CA, though born and raised in the San Francisco area, he also founded and runs his own event entertainment company, Dark Tide Productions. Not only does he actively work in the private event industry, he also works in the Los Angeles entertainment industry as well, being featured in multiple commercials. A frequent guest swimmer of Dive Bar in Sacramento, but also a consultant for The California Mermaid Convention, the longest running mermaid convention in the world. His company is rooted in diversity and has become a leading force in supplying mermen and entertainers of color. Merman Jax seeks to create a world where the mers of the world who feel different, not only feel accepted, but celebrated and given the professional opportunities that many do not afford them. He and his company also support a variety of charity events including AIDS Walk Los Angeles, Wolf Connection, Breakfast with Santa and shark ‘adoption’ through the Aquarium of the Pacific.</p>
            </div>
          </div>
          {/* BODY */}
          <div id="marineCareers-review-container">
            <div class="marineCareers-review-content-container">
              {/* INTERVIEW SARA*/}
              <div class="marineCareers-header-title-container">
                <h2>Interview with Sara</h2>
              </div>
              <div class="marineCareers-interview">
                <h2>Describe an average day in the life of a professional mermaid. What do you look forward to the most?</h2>
                <p>I teach in tail up at one of our local hotels here in Maui, Hawaii. I lead mermaid classes and course certification, and teach classes on ocean conservation up there. During a typical day, I get up at about 7am, head up to the hotel, set up a full range of tail racks with different sizes for all the different kids, and then when they check in, we do the paperwork. We do a little photo shoot for them, and then we start the basics of swimming in a tail in the pool. I like to play different games throughout the classes. We go through the names of different sea animals, and I tell them facts about each one, they pick a favorite and we just share stories.</p>
                <p>I tell them what it's like to swim as a mermaid in my tail with those creatures, whether that's monk seals, dolphins, or turtles, and how those creatures interact with me when I'm swimming in my tail, because it's a bit different than when you're in fins. They are usually more accepting, and much more curious, trying to figure out what kind of creature you are, so we talk through that, and then I teach them how to do tricks and games.</p>
                <p>We basically just enjoy the freedom of swimming like a mermaid in a tail, and then towards the end of the day when I'm done teaching classes, I check out with the hotel. Then, I usually have some sort of project that I'm working on, whether that's making private stunt videos, or working on filming things for music videos, we also do different shoots for murals, and things out in the ocean. So, my job kind of has two parts: I interact with the public, and get them excited about loving the ocean, and teach them how to be a mermaid and certify them, but I also work out in the ocean swimming and interacting with the wildlife there.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What are some common misconceptions about your job/field?</h2>
                <p>I'd say that the most common misconception is that a professional mermaid is just someone who dresses up, puts on a tail and a crown, and looks pretty for photo shoots. There's so much more behind it than that, and one of the key reasons why I chose it was that I'm able to excite people about helping the ocean. That's a huge piece of the job for me that's beyond a crown and a tail, really reaching people to make a difference for the ocean in the long run.</p>
                <p>I have a lot of training and certification, it took me years to get to the place where I can lead classes safely. I need to be able to swim without a mask or goggles, and have trained my eyes to be able to navigate underwater. Normally, it's extremely blurry for people, but I need to know the difference between coral and sea urchins, as I'm swimming over the bottom, I need to be able to read the turtles’ expressions, and understand if they feel I'm too close, or if I should back off so that I can most respectfully interact with them. Then also within this ability is the freediving aspect, being able to go down to depths around 80 feet, being able to hold my breath for over three minutes and 45 seconds to make the videos and images that excite people to connect to the ocean.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>Describe your education, training and/or experience that prepared you for this career.</h2>
                <p>I was a Divemaster leading people out on dives in the sea and then I learned how to teach them how to scuba dive by becoming a SCUBA instructor. I did my master freediving and then I did my freediving instructor course. The Divemaster alone took about six months, and then teaching as an instructor took a couple of years. I also taught SNUBA, where the lines come off of the tank off of a raft up top, so people don't have heavy gear. That way more kids can get into being underwater and feel what it's like to SCUBAdive, but without the heavy gear. Then through the mermaid certification, I actually wrote the programs myself because I'm a part of PADI (Professional Association of Diving Instructors). So, I wrote the curriculum and the standards for my classes, and they approved it, created a medical for it, and a certification card process. Then I was allowed to teach my classes to the masses.</p>
                <p>Then the other things, like getting to know the reef, and the animals and learning what that fishes’ life is like, what their personality is like in regards to others, how they spend their day, what type of food they're going after. That took two years of diving on the same reef, I dove over 900 times on the same section of land that's about a quarter of a mile underwater. Just got to know those corals, like the back of my hand, got to know when they bloom In accordance to the moon, and what time of year the sea algae would grow over and kind of strangle the corals, how long it took the corals to come back, how they reacted at the end of summer to all this really warm water over time, and the coral bleaching, to know if they were able to come back from that or not. Also understanding which fish left the reef when that happened, and then which fish came back and returned, some I no longer see there, which is really sad, but It's all just a process of evolution to understand that change is the only constant, right. So I became really familiar, and intimate, I would say, with the reef and its creatures. That gave me the ability to really teach people, so this reef is right out front of the hotel that they're staying at, and I could just tell them what was down there, and we could have amazing conversations about it. When I was teaching, I could draw off of things that I could actually show them the ocean, and that was really fun, so building a knowledge base that way.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What have you learned about yourself and the world while working in your field?</h2>
                <p>That's an amazing question, I like that one. By diving into the ocean and doing the freediving aspects. It's just you down there. When you dive down you're completely alone and cut away from all that happens on dry land.  It's just this quiet stillness in your mind and quiet stillness in your body. You get to know how your body changes how your mind deals with being uncomfortable, as you're holding your breath because it gets more and more uncomfortable after the first minute. Really understanding how much I have within myself to get through things so on land, when I come up against uncomfortable situations, that quiet stillness is there for me to draw on. Yeah, so an inner strength came from it. I keep continually going back to connect to my love of the sea, that grows deeper the more I work as a mermaid, and I try to show people that and give them that same love that I have for the sea, so that we have a better chance of protecting the future.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What is your favorite marine science fact? And if you could be any marine animal, what would you be?</h2>
                <p>Oh my goodness that's fun. I always love learning about sea horses and how they communicate through a series of clicks. They mate for life. They're like true romantics, right, and they stay next to one another once they have found each other, for the rest of their lives. So, in the morning when they wake up, after sleeping on the seabed and being curled around the seagrasses on the bottom, they wake up and they look at the day around them, and they go for their first morning swim. But they do it together, they intertwine tails, and swim off into the blue together to go around, kind of like an old couple will walk through the park early in the morning together. They also shift to turn the same color as one another, so if one is woken up silver, the other one goes silver to match, and they swim together, and go out and around, and back. I thought that was really cute.</p>
                <p>I think I’d be a whale, I'm very fascinated by them, and in understanding that they have the highest compassion receptors of any mammal, that their brain is so huge. They just have this amazing heart, I think it would be incredible to see the world the way that they do, like whales are always rescuing baby animals that are not their own species. Finding things that are having trouble in the ocean, and helping them breathe, helping them get back to where they need to be, protecting them from predators, and I find that fascinating. I think that if we could live with that type of compassion for not only the human race, but for other mammals and creatures, our world would be incredible.</p>
              </div>
              {/* INTERVIEW JAX*/}
              <div class="marineCareers-header-title-container">
                <h2>Interview with Jax</h2>
              </div>
              <div class="marineCareers-interview">
                <h2>Describe an average day in the life of a professional merman. What do you look forward to most during your workday?</h2>
                <p>It absolutely is fun, but isn’t what I would call easy; this isn’t even getting into the rigorous physical training or the acting/performance training. I don’t know that I really have an average day, as the entertainment industry is so fluid and fast-paced. Ideally you get a workout in at some point, but on a non-event day, it’s a lot of emails and social media work. For an event day, usually the entire day is blocked out for prep, travel, performance and then organizing, rinsing gear at the end of a thrilling, but exhausting day.</p>
                <p>Honestly, so much of my work can be administrative, sitting behind a computer like a lot of people. It takes a lot of planning and tons of logistics to make these events happen. So, while I do enjoy the actual day-of performances, I think I really look forward to the creation of projects. The inspired concept phase of events/photoshoots, where the sky's the limit, is pretty fun. I try to enjoy those moments before the inevitable stress of, “Ok, now I have to figure out how to pull this off!” Seeing the final photos from projects is so rewarding too.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What are some common misconceptions about your job/field? What do people often find most surprising about your work?</h2>
                <p>So many people think what I do is so “easy”. We are in skin-tight silicone tails that can weigh up to 40 lbs, while swimming, modeling, doing choreography, holding your breath, interacting with guests etc. This is also while dealing with sun burns, chlorine damage to hair/skin/eyes, painful cramps due to the costume… we do all of this with a smile. Not to mention we often work on weekends & holidays, so it is a tricky balancing act with your social life. Professional Merfolk are trained athletes with competitive swimming backgrounds, lifeguard or free-diving certifications, not to mention general acting/performance training. If what we did was easy, everyone would do it.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What have you learned about yourself and the world working in your field?</h2>
                <p>I have learned and grown so much as a human being in this career path. The type of businessman I have had to become, was something I never could have envisioned as a child. I was painfully shy when I was young and am still very much an introvert outside of performing. I have learned how to be a strong, capable informed business owner, while also learning how to navigate the complicated world of public relations and interpersonal relations in the workplace.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What is your favorite marine science fact and if you could be any marine animal, what would you be?</h2>
                <p>I do love all marine animals, but do have a special affinity for seahorses. I am fascinated by these delicate little creatures. I also very much appreciate how the male seahorses give “birth”. Antiquated and toxic ideas of gender roles in what I do can be pretty pervasive, so the idea of nature never being as judgemental as human minds is a beautiful thing.</p>
              </div>
              {/* LEARNING MORE */}
              <div id="marineCareersLearning-container">
                <div class="marineCareersLearning-content-container">
                  <h2>Interested in learning more about Professional Merfolking?</h2>
                  <ul class="marineCareersLearning-list">
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p>Do you want to read more about Sara's responses? <a href="https://drive.google.com/file/d/1hUEbix4GkvWCGkgAdfZ9YT1R9VMeMSGf/view" target="_blank" rel="noopener noreferrer">Here's the link to the full interview</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p>Do you want to read more about Jax's responses? <a href="https://drive.google.com/file/d/14wqClzZssM3uIh8e2G7wdOSGZ0p-mHkl/view" target="_blank" rel="noopener noreferrer">Here's the link to the full interview</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.padi.com/courses/basic-freediver" target="_blank" rel="noopener noreferrer">Learn more about freediving here.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://aquamermaid.com/blogs/news/how-to-become-a-professional-mermaid" target="_blank" rel="noopener noreferrer">Read about advice and tips on how to become a professional merfolk.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.youtube.com/watch?v=tQLOgp3LLpg&feature=youtu.be" target="_blank" rel="noopener noreferrer">Listen to insight on becoming a mermaid working in aquariums.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://mermaidsinmotion.com/media/" target="_blank" rel="noopener noreferrer">Get inspired by watching these videos of professional mermaids in motion.</a></p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <a class="marineCareers-backButton" href={'/marine-science-careers'}>
                    <p>Back to the careers!</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <NewFooter />
        </div>
      </div>
    );
  }
}