import React, { Component } from 'react';
import './marineEndlessBlue-Intro.css';

export default class MarineScienceCollaborationIntro extends Component {
  render() {
    return(
      <div id="marineEndlessBlue-intro-container">
        <div class="marineEndlessBlue-intro-content-container">
          <div class="marineEndlessBlue-intro-bold">
            <h3>An engaging, quest-based Table-Top RPG to explore ocean careers.</h3>
          </div>
          {/* <div class="marineEndlessBlue-intro-normal">
            <p>We aim to invest in marine science programs, research, and philanthropy that empower youth to consider their impact on the world and take environmental action in their community.</p>
          </div> */}
        </div>
      </div>
    );
  }
}