import React, { Component } from 'react';
import './projectsLandingPage-Links.css';

export default class ProjectsLandingPageLinks extends Component {
  render() {
    return(
      <div id="projectsLandingPage-links-container">
        <div class="projectsLandingPage-links-content-container">
          <div class="interestArea-cardsList2-container">
            <div class="interestArea-domain-title">
              <h2>Learn from Past Projects</h2>
            </div>
            <ul id="interestArea-cardsList2" class="interestArea-events-list2">
              <a href={'/automotive-projects/electric-dragster'}>
               <li>
                <div class="projectsLandingPage-cards-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/automotive-tech/dragster+small.jpg" alt="electric dragster" />
                  <p class="projectsLandingPage-cards-title">Electric Dragster Project</p>
                  <div class="projectsLandingPage-cards-overlay"></div>
                </div>
                </li>
              </a>
              <a href={'/automotive-projects/hot-rod-combustion-and-electric-technology'}>
               <li>
                <div class="projectsLandingPage-cards-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/automotive-tech/Hot+Rod+Combustion+small.jpg" alt="hot rod combustion and electric technology" />
                  <p class="projectsLandingPage-cards-title">Hot Rod Combustion & Electric Technology</p>
                  <div class="projectsLandingPage-cards-overlay"></div>
                </div>
                </li>
              </a>
              <a href={'/automotive-projects/circuit-boards'}>
               <li>
                <div class="projectsLandingPage-cards-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/automotive-tech/circuit+small.jpg" alt="circuit board" />
                  <p class="projectsLandingPage-cards-title">Circuit Training Boards</p>
                  <div class="projectsLandingPage-cards-overlay"></div>
                </div>
                </li>
              </a>
              <a rel="noopener noreferrer" target="_blank" href='https://drive.google.com/file/d/1L7e2AKnkWZo-_zFwnNGsANw6cHZYzfY8/view?usp=sharing'>
               <li>
                <div class="projectsLandingPage-cards-container">
                  <img src="https://f10-programs.s3.us-west-2.amazonaws.com/trades/Automotive+Projects/Tool+Kit+Project+300x250.jpg" alt="tool kit" />
                  <p class="projectsLandingPage-cards-title">Tool Kit Project With Small Motors & Brakes<br/>[MINI REPORT]</p>
                  <div class="projectsLandingPage-cards-overlay"></div>
                </div>
                </li>
              </a>
              <a rel="noopener noreferrer" target="_blank" href='https://drive.google.com/file/d/1W5VD4vKXhZjtjnRuuMMo3xfS0vjV9-hu/view?usp=sharing'>
               <li>
                <div class="projectsLandingPage-cards-container">
                  <img src="https://f10-programs.s3.us-west-2.amazonaws.com/trades/Automotive+Projects/Small+Engine+Kit+300x250.jpg" alt="engine parts" />
                  <p class="projectsLandingPage-cards-title">Small Engine Kit<br/>[MINI REPORT]</p>
                  <div class="projectsLandingPage-cards-overlay"></div>
                </div>
                </li>
              </a>
              <a rel="noopener noreferrer" target="_blank" href='https://drive.google.com/file/d/1GKKZyYHG5Ok7Le1Xhr6ZkFm-pY4IDujr/view?usp=sharing'>
               <li>
                <div class="projectsLandingPage-cards-container">
                  <img src="https://f10-programs.s3.us-west-2.amazonaws.com/trades/Automotive+Projects/RC+Car+Project+300x250+copy.jpg" alt="rc car project" />
                  <p class="projectsLandingPage-cards-title">RC Car Project<br/>[MINI REPORT]</p>
                  <div class="projectsLandingPage-cards-overlay"></div>
                </div>
                </li>
              </a>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}