import React, { Component } from 'react';
import {
  useQuery,
  gql
} from "@apollo/client";
import Grid from '@material-ui/core/Grid';

//Get page header based on ID
const pageQuery = gql`
  query pageHeaderQuery($slug: [String]) {
    entries(slug: $slug) {
      ... on homepage_homepage_Entry {
        blurb
        blurbLink {
            url
            customText
        }
        featuredImage {
          url
        }
        featuredItem {
            title
            url
            sectionHandle
            ... on stories_default_Entry {
                blurb
                categories {
                    title
                }
            }
            ... on projects_default_Entry {
                blurb
                projectType {
                    title
                }
            }
            ... on resources_default_Entry {
                blurb
                resourceType {
                    title
                }
            }
            ... on opportunities_default_Entry {
                blurb
                opportunityType {
                    title
                }
            }
        }
      }
    }
  }
`
function Hero(slug) {
  const { data, loading, error } = useQuery(pageQuery, {
    variables: {slug}
  });
  if (loading) return null;
  if (error) return `Error! ${error}`;
  let bgImage = null;
 
  const pageHeader = data.entries[0];
  //console.log(pageHeader);
  const blurb = pageHeader.blurb;

  let category = null;
  if ( pageHeader.featuredItem[0].categories && pageHeader.featuredItem[0].categories[0].title) {
    category = pageHeader.featuredItem[0].categories[0].title;
  } else if (pageHeader.featuredItem[0].projectType && pageHeader.featuredItem[0].projectType[0].title) {
    category = pageHeader.featuredItem[0].projectType[0].title;
  } else if (pageHeader.featuredItem[0].resourceType && pageHeader.featuredItem[0].resourceType[0].title) {
    category = pageHeader.featuredItem[0].resourceType[0].title;
  } else if (pageHeader.featuredItem[0].opportunityType && pageHeader.featuredItem[0].opportunityType.length) {
        category = pageHeader.featuredItem[0].opportunityType[0].title;
  } else {
    category = pageHeader.featuredItem[0].sectionHandle;
  }

  const section = pageHeader.featuredItem[0].sectionHandle;
  
  
  //console.log(data);
  //const bgImage = pageHeader.featuredImage[0];
  //const bgImage = pageHeader.featuredImage[0];
  if (pageHeader.featuredImage.length > 0) {
    //console.log('image here')
    bgImage = pageHeader.featuredImage[0];
  }


  let myStyle = {};

  let title;
  if (pageHeader.customTitle) {
    title = pageHeader.customTitle
  } else {
    title = pageHeader.title
  }
  const bg = bgImage ? 'bgImage' : 'orange';
  const withBlurb = blurb ? 'withBlurb' : '';
  const classes = `${bg} ${withBlurb}`

  //console.log(withBlurb);
  // console.log(bgImage);
  // console.log(bgImage.url);
   // Set Image URL if availible
   
  if(bgImage !== null ) {
    //console.log('getting here');
    myStyle = {
      backgroundImage: `url(${bgImage.url})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }
  } else {
    myStyle = {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }
  }
  
  //console.log( myStyle );
  
  //let bgImage = pageHeader.featuredImage[0].url;
  //console.log(bgImage);
  return (
    <div id="heroContent" className={classes}>
       
        <Grid container spacing={40}>
            <Grid item sm={12} md={7} style={myStyle}>
            </Grid>
            <Grid item sm={12} md={5} className="featuredItem">
                {category && (
                    <p className="label">
                        {category}
                    </p>
                )}
                <h2>{pageHeader.featuredItem[0].title}</h2>
                <div
                    dangerouslySetInnerHTML={{
                        __html: pageHeader.featuredItem[0].blurb,
                    }}
                ></div>
                <a href={pageHeader.featuredItem[0].url}>Learn More <i className="fas fa-arrow-right"></i></a>
            </Grid>
        </Grid>
        <Blurb slug={slug} />
    </div>
  );
  

}

//This query has to address ALL Channels
function Blurb(slug) {
  const { data, loading, error } = useQuery(pageQuery, {
    variables: slug.slug
  });
  if (loading) return null;
  if (error) return `Error! ${error}`;
 
  const blurb = data.entries[0].blurb;
  const blurbLinkText = data.entries[0].blurbLink.customText;
  const blurbLinkUrl = data.entries[0].blurbLink.url;

  if(blurb) {
    return (
      
        <div className="blurb">
           <div
              dangerouslySetInnerHTML={{
                __html:blurb,
              }}
          ></div>

          {blurbLinkText && (
            <a className='button orange' href= {blurbLinkUrl}>{blurbLinkText}</a>
          )}
         
        </div>
      
    );
  } else {
    return ( "" );
  }
  
}

export default class HomepageHeader extends Component {
  render() {
    return(
      <section id="hero">
        <Hero slug={this.props.slug}/>
          <div className="container">
            {this.props.blurb && (
              <Blurb slug={this.props.slug}/>
            )}
          </div>
        </section>
    );
  }
}