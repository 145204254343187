import React, { Component } from 'react';
import './PhilanthropyDirector-Extra.css';

export default class PhilanthropyDirectorExtra extends Component {
  render() {
    return(
      <div id="philanthropyDirectorExtra-intro-container">
        <div class="philanthropyDirectorExtra-intro-content-container">
          <h2>Foundry10 offers competitive salary and benefits that include:</h2>
          <ul>
            <li>A 4-day (32 hour) workweek</li>
            <li>A flexible and hybrid work schedule</li>
            <li>Full Medical, Dental, and Vision coverage for employees and 50% coverage for dependents</li>
            <li>$1500 yearly education stipend</li>
            <li>Flexible vacation time</li>
            <li>401(k) retirement plan with employer match</li>
            <li>Flexible Spending Account and Dependent Care Reimbursement Account</li>
            <li>Employee Assistance Program at no cost to the employee</li>
            <li>Short Term Disability, Long Term Disability, Life Insurance paid fully by foundry10</li>
          </ul>
          <h2>The range salary for this position is $87,000 to 93,000/year DOE.</h2>
          <p>Foundry10 is committed to creating a diverse environment where everyone can bring their full selves to work. Employment decisions are based on merit and business needs. The organization strives to provide a work environment free from discrimination and harassment because of a protected characteristic. Foundry10 does not discriminate against employees or applicants based on race, color, creed, citizenship, status, national origin, ancestry, gender, genetic information, sexual orientation, gender expression or identity, age, religion, pregnancy or pregnancy-related condition, physical or mental disability, marital status, parental status, veteran status, political affiliation, or any other characteristic protected by law.</p>
          <p>We are committed to providing reasonable accommodations to assist individuals with disabilities with the application and interviewing process as well as essential job functions. If you have questions about the accessibility of our office environment, please reach out to info@foundry10.org.</p>
        </div>
      </div>
    );
  }
}