import React, { Component } from 'react';
import './legacySubjects.css';
import { LegacySubjectsData } from './legacySubjectsData';

export default class LegacySubjects extends Component {
    render() {
      return(
        <div className='legacySubjectsContainer'>
            <h2 className="legacySubjects-title">Explore Legacy Programs by Subject</h2>
            <div className='legacySubjectsInterestAreas'>
                <div className='legacySubjectsCards'>
                {LegacySubjectsData.map((item, index) => {
                console.log(item);
                return (<div className='legacySubjectsCardIndivid' key={index}>
                    <a href={item.path}>
                      <span className='legacySubjectsCardIcon'>{item.icon}</span>
                      <p>{item.title}</p>
                    </a>
                  </div>);
              })}
                </div>
            </div>
        </div>
      );
    }
  }
