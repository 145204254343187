import React from "react";
import './Veterinary-Medicine.css';

export default function VeterinaryMedicine({ title, subtitle, dark, id }) {
  return (
    // <div className={"section" + (dark ? " section-dark" : "")}>
    <div className="veterinaryMedicine-container">
      <div className="veterinaryMedicine-content-container" id={id}>
        <div className="veterinaryMedicine-final-content-container">
          <div className="veterinaryMedicine-illustration">
            <h1>Click below to learn more!</h1>
            <a class="veterinaryMedicine-link" href="/marine-science-careers/veterinary-medicine">
              <img src='https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/veterinarymed1.png' alt='veterinary medicine illustration'/>
              <p class="veterinaryMedicine-title">Meet Joe & Caitlin!</p>
              <div class="veterinaryMedicine-cards-overlay"></div>
            </a>
          </div>
          <div className="veterinaryMedicine-description">
            <h2>"Fish Doctor"</h2>
            <p>RELATED TITLES: Animal Husbandry Specialist, Animal Care Technician</p>
            <p>Although this may seem like a career in sea creature cuddling, most marine veterinarians avoid intervening so long as animals are healthy. While they are typically concerned with marine mammals, sea turtles, and birds, veterinarians who specialise in marine life may also have opportunities to care for fish and invertebrates as well.</p>
            <div className="veterinaryMedicine-requirements">
              <h2>Basic Degree/Training Requirements</h2>
              <ul className="veterinaryMedicine-iconList">
                <li>
                  <div class="veterinaryMedicine-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/black-HS-Diploma.png" alt="high school diploma" />
                  </div>
                </li>
                <li>
                  <div class="veterinaryMedicine-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/black-Undergrad-Cap.png" alt="undergrad degree" />
                  </div>
                </li>
                <li>
                  <div class="veterinaryMedicine-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/black-Grad-Degree.png" alt="graduate degree" />
                  </div>
                </li>
                <li>
                  <div class="veterinaryMedicine-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/black-Vet.png" alt="vet degree" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}