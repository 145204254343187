import React, { useState } from 'react';
import './AreasOfResearch-ImprovingResearchBasedResourcesAccordion.css';

function AreasOfResearchImprovingResearchBasedResourcesAccordion() {
    const [isActive, setIsActive] = useState(false);
    // const [hasMore, setHasMore] = useState(false);

    return (


            <div className="areasOfResearchAccordion-item">
                <div className="areasOfResearchAccordion-title" onClick={() => setIsActive(!isActive)}>
                    <div className="areasOfResearchAccordion-image">
                        <img src="https://f10-research.s3.us-west-2.amazonaws.com/Conceptual+Development+Lab/IA_Team_Page_Icons_2024.02.27_Improving+Research-Based+Resources+for+Caregivers+and+Educators.png" alt="parent and child waving"/>
                    </div>
                    <div className="areasOfResearchAccordion-text">
                        <h2 className="areasOfResearchAccordion-title-text">Improving Research-Based Resources for Caregivers and Educators</h2>
                        {/* <h3>{research.subtitle}</h3> */}
                    </div>
                    <i className={ isActive ?  `fa-solid fa-angle-up`:`fa-solid fa-angle-down`}></i>                  
                </div>
                {isActive && <div className="areasOfResearchAccordion-content">
                    <div className="areasOfResearchAccordion-description">
                        <p>It is important to create resources that caregivers and educators can readily incorporate into their routines to support early learning. Designing interventions that are sufficiently feasible, engaging, and culturally responsive requires an understanding of how resources and messages will be interpreted and used by the adults in children’s lives. This area of research will explore questions such as:</p>
                    </div>  
                    <div className="areasOfResearchAccordion-descriptionTwo">
                        {/* <h2>Participation In A Connected World</h2> */}
                        <div className="areasOfResearchAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How can we more effectively translate research findings to support caregivers and educators in supporting youth’s cognitive development and academic success?</p>
                        </div>
                        <div className="areasOfResearchAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How can we translate research findings into public messages and interventions that are engaging, fun, and do not contribute to caregiver/educator burnout?</p>
                        </div>
                        <div className="areasOfResearchAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How can we best promote learning and development in specific areas while also addressing broader educational outcomes and parenting goals?</p>
                        </div>
                    </div>
                    {/* <div className="areasOfResearchAccordion-descriptionThree">
                        <h2>Curriculum + Education</h2>
                        <div className="areasOfResearchAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>What are important considerations for school-based civic learning opportunities that support meaningful youth engagement online?</p>
                        </div>
                        <div className="areasOfResearchAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How should we approach curriculum focused on digital citizenship more effectively to support the skills youth need to thrive in today’s society?</p>
                        </div>
                        <div className="areasOfResearchAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>To what extent are young people’s perspectives included in the development & implementation of curriculum about being a digital citizen?</p>
                        </div>
                    </div> */}
                </div>}
            </div>

    );
}

export default AreasOfResearchImprovingResearchBasedResourcesAccordion;