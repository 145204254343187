import React, { Component } from 'react';
import './PhilanthropyLandingPage-Pillars.css';

export default class PhilanthropyLandingPagePillars extends Component {
  render() {
    return(
      <div id="philanthropyLandingPage-pillars-container">
        <div class="philanthropyLandingPage-pillars-content-container">
          <div class="interestArea-cardsList2-container-phil">
            <ul id="interestArea-cardsList2-phil" class="interestArea-events-list2-phil">
              <li>
                <div class="phil-card">
                  <div class="phil-card-image">
                    <img src='https://f10-philanthropy.s3-us-west-2.amazonaws.com/Images/direct+value.jpg' width="100%" alt="direct value for kids"/>
                  </div>
                  <div class="phil-card-content">   
                    <div class="phil-card-content-container">
                      <div class="philTitle-bottom-directValue">
                        <h1>Direct value for kids</h1>
                      </div>
                      <div class="phil-text">
                        <p>• We use our resources to directly benefit kids</p>
                        <p>• We use research and program feedback to understand what does, or does not, work and ensure we serve kids as best we can</p>
                        <p>• We remove as many barriers to access as we can</p>
                        <p>• We eliminate costs that don’t directly benefit the communities we serve</p>
                      </div>
                    </div>                 
                  </div>
                </div>
              </li>
              <li>
                <div class="phil-card">
                  <div class="phil-card-image">
                    <img src='https://f10-philanthropy.s3-us-west-2.amazonaws.com/Images/collaborative.jpg' width="100%" alt="collaborative"/>
                  </div>
                  <div class="phil-card-content">   
                    <div class="phil-card-content-container">
                      <div class="philTitle-bottom-collaborative">
                        <h1>Collaborative</h1>
                      </div>
                      <div class="phil-text">
                        <p>• We partner with organizations that demonstrate ethical judgment about resources and kids</p>
                        <p>• Ideas for projects are developed with partners, and we share the process of developing them</p>
                      </div>
                    </div>                 
                  </div>
                </div>
              </li>
              <li>
                <div class="phil-card">
                  <div class="phil-card-image">
                    <img src='https://f10-philanthropy.s3-us-west-2.amazonaws.com/Images/flexible.jpg' width="100%" alt="flexible"/>
                  </div>
                  <div class="phil-card-content">   
                    <div class="phil-card-content-container">
                      <div class="philTitle-bottom-flexible">
                        <h1>Flexible</h1>
                      </div>
                      <div class="phil-text">
                        <p>• We’re open in how we evaluate project success, and willingly adapt our process to make projects more successful</p>
                        <p>• We connect partners with resources that serve the specific needs of their project, whether that be monetary, on-the-ground program support, equipment, or talented people</p>
                      </div>
                    </div>                 
                  </div>
                </div>
              </li>
              <li>
                <div class="phil-card">
                  <div class="phil-card-image">
                    <img src='https://f10-philanthropy.s3-us-west-2.amazonaws.com/Images/ethical.jpg' width="100%" alt="ethical"/>
                  </div>
                  <div class="phil-card-content">   
                    <div class="phil-card-content-container">
                      <div class="philTitle-bottom-ethical">
                        <h1>Ethical</h1>
                      </div>
                      <div class="phil-text">
                        <p>• We aim to establish sustainable projects that are set up for continued success</p>
                        <p>• We strive to distribute resources equitably and consider the unique needs of each community we work with</p>
                      </div>
                    </div>                 
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}