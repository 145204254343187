import React, { Component } from 'react';
import './annualReport2021Covid-Support.css';

export default class AnnualReportCovidSupport extends Component {
  render() {
    return(
      <div id="annualReport2021covid-support-container">
        <div className="annualReport2021covid-support-content-container">
                <div className="annualReport2021covid-support-banner-container" id="annualReport2021covid-support-banner-presenting">
                  <img src="https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Covid+Page/remotelearning-8.png" alt="remote learning support"/>
                  <h2>Remote Learning Support</h2>
                </div>
            <div className="annualReport2021covid-support-highlight">
                <br />
                <br />
                <h3>
                As an education-focused organization with strong relationships in local schools, we were in an excellent position to support the sudden transition to  remote learning brought on by the stay-at-home order.  While the foundry10 EdTech team jumped into action to connect youth to reliable internet so they could continue learning, three different program teams rapidly developed educational activities and procured covid-safe school supplies to support early learning educators at the YMCA in their efforts to care for children of essential workers. Our broad community reach and organizational flexibility allowed us to quickly support remote learning in the ways that students, educators, and administrators needed it most. 
                </h3>
                <h1>
                Hotspots in Granite Falls
                </h1>
                  <img src="https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Covid+Page/hotspots.jpg" alt="hotspots in granite falls"/>
                <h3>
                When schools switched to remote learning online, youth who didn’t have access to reliable internet couldn’t connect with teachers and peers at school.  To help bridge this gap, we partnered with administrators in the Granite Falls School District  to provide mobile hotspots to families without access to the internet so that their students could continue to participate in classes. We frequently needed to adjust our solutions as we encountered new problems. Many school staff members didn’t have access to laptops that supported mobile hotspots. Different families needed different hotspot devices depending on their exact location in the area. By recognizing the complexity of the situation and responding to each obstacle as it arose, we were able to connect many students in Granite Falls with distance learning online.
                </h3>
            </div>
            <div className="annualReport2021covid-support-highlight">
                <h1>
                Multi-faceted support for the YMCA
                </h1>
                  <img src="https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Covid+Page/ymca.jpg"  alt="multi-faceted support for the YMCA"/>
                <h3>
                Community centers and organizations became even more critical during the pandemic, with many offering food support, childcare for essential workers, and access to learning activities that were hard to get elsewhere.  Youth programming at these centers was impacted, but our local YMCAs were able to provide in person programming, albeit with many special procedures.  To support this effort, our STEM, Drama, and Games teams all engaged with the YMCA to enable them to continue to provide meaningful services for youth.  While STEM and Drama developed engaging hands-on activities and provided teaching artists, Games team connected the YMCA to local game shops and provided funding for the Y to purchase learning-centered games and activities. This work helped the YMCA to continue its role of providing programming for youth and support for families.  Throughout this, we were also learning more about what community needs looked like in this time of crisis.  Many of the needs we noticed in our work with the YMCA were also being felt by other community centers and organizations around the nation.  Our hope is that the activities and lessons we developed will be useful for other communities even beyond the pandemic crisis.
                </h3>
                {/* <h3>
                This work helped the YMCA to continue its role of providing programming for youth and support for families.  Throughout this, we were also learning more about what community needs looked like in this time of crisis.  Many of the needs we noticed in our work with the YMCA were also being felt by other community centers and organizations around the nation.  Our hope is that the activities and lessons we developed will be useful for other communities even beyond the pandemic crisis.    
                </h3> */}
            </div>
        </div>
      </div>
    );
  }
}
