import React, { useState } from 'react';
import './Benefits-Accordion.css';

function BenefitsAccordion({ benefit }) {
    const [isActive, setIsActive] = useState(false);
    // const [hasMore, setHasMore] = useState(false);

    return (


            <div className="benefitsAccordion-item">
                <div className="benefitsAccordion-title" onClick={() => setIsActive(!isActive)}>
                    <div className="benefitsAccordion-image">
                        <img src={benefit.img} alt={benefit.alt}/>
                    </div>
                    <div className="benefitsAccordion-text">
                        <h2 className="benefitsAccordion-title-text">{benefit.title}</h2>
                        <h3>{benefit.subtitle}</h3>
                    </div>
                    <i className={ isActive ?  `fa-solid fa-angle-up`:`fa-solid fa-angle-down`}></i>                  
                </div>
                {isActive && <div className="benefitsAccordion-content">
                    <div className="benefitsAccordion-description">
                        <p>{benefit.content}</p>
                        <p>{benefit.contentCont}</p>
                    </div>  
                </div>}
            </div>

    );
}

export default BenefitsAccordion;
