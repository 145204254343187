import React, { Component } from 'react';
import SavvyLadiesDescription from './savvyLadies-Description';
import NewFooter from '../../../../pages-other/NewFooter';
import './savvyLadies-Content.css';

export default class SavvyLadiesContent extends Component {
  render() {
    return(
      <div id="savvyLadies-content-container">
        <SavvyLadiesDescription />
        <NewFooter />
      </div>
    );
  }
}