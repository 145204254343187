import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import './shortRun.css';
import ShortRunHeader from './shortRun-header';
import ShortRunContent from './shortRun-content';

export default class ShortRun extends Component {
  render() {
    return (
      <div id="shortRun-container">
        <Helmet>
          <title>foundry10 Partnership with Short Run 2018</title>
          <meta
            name="title"
            content="foundry10 Partnership with Short Run 2018"
          />
          <meta
            name="description"
            content="foundry10 collaborates with Short Run, Seattle's local comic art festival, offering teens opportunities in visual arts."
          />
          <meta property="og:title" content="foundry10 Partnership with Short Run 2018" />
          <meta
            property="og:description"
            content="foundry10 collaborates with Short Run, Seattle's local comic art festival, offering teens opportunities in visual arts."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/short-run-2018" />
          <link rel="canonical" href="https://www.foundry10.org/short-run-2018" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Partnership with Short Run 2018",
                "description": "foundry10 collaborates with Short Run, Seattle's local comic art festival, offering teens opportunities in visual arts.",
                "url": "https://www.foundry10.org/short-run-2018"
              }
            `}
          </script>
        </Helmet>
        <ShortRunHeader />
        <ShortRunContent />
      </div>
    );
  }
}