import React, { Component } from 'react';
import './researchRedesign-Intro.css';

export default class ResearchRedesignIntro extends Component {
  render() {
    return(
      <div id="researchRedesign-intro-container">
        <div class="researchRedesign-intro-content-container">
          <div class="researchRedesign-intro-bold">
            <h2>Expanding Ideas About Learning</h2>
          </div>
          <div class="researchRedesign-intro-normal">
            <p>In collaboration with educators, industry professionals, young people, and other researchers, we conduct research to advance understanding of the learning process, support practitioners, and empower learning communities to create value for youth. Read below to learn about our research labs, keep up with our latest news, and explore our research publications and resources.</p>
          </div>
          <div class="researchRedesign-intro-bold">
            <h2>Join Our Research Database</h2>
          </div>
          <div class="researchRedesign-intro-normal">
            <p>Interested in hearing about future opportunities for you and your child(ren) to participate in our education research studies in Seattle? <a href="https://airtable.com/appBGujBORRnB0YtY/pagVKLeUWNAEe86fw/form" target="_blank" rel="noopener noreferrer" style={{ color: "#FFFFFF" }}>Click here</a> for more information about what to expect when you participate and to join our database of interested families (all personal information will be stored securely and never shared).</p>
          </div>
        </div>
      </div>
    );
  }
}

