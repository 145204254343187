import React, { Component } from 'react';
import NewFooter from '../NewFooter';
import './Error404Page-Content.css';

export default class Error404PageContent extends Component {
  render() {
    return(
      <div id="error404Page-content-container">
        <div className="error404Page-text-container">
            <p>
                Oops!  Looks like that page doesn't exist.  If you think it ought to, please reach out to our <a href="mailto:web@foundry10.org?subject=404 Error">web team</a> with details of your experiece.
            </p>
        </div>
        <NewFooter />
      </div>
    );
  }
}

