import React, { Component } from 'react';
import './PhilanthropyDirector-Description.css';

export default class PhilanthropyDirectorDescription extends Component {
  render() {
    return(
      <div id="philanthropyDirectorDescription-intro-container">
        <div class="philanthropyDirectorDescription-intro-content-container">
          <p>Foundry10 is a philanthropic education research organization located in the University District neighborhood in Seattle. We work in collaboration with program developers and external partners to conduct research that expands how practitioners, communities, and other researchers think about learning. Foundry10 offers competitive salaries, benefits, and vacation time. This position is located in Seattle, WA and will require the candidate to work in the office when it is safe to do so.</p>
          <p>The successful Director of Community and Philanthropic Relationships will help to guide and direct our non-traditional approach to philanthropic work. This leader will help us further connect and embed philanthropy in our research and programming while continuing to find ways for foundry10 to be responsive and flexible in our broad array of philanthropic work. The Director will help strengthen our philanthropic effectiveness, grow our organizational philanthropic capabilities, help us create direct value for children, and assist teams and individuals across the organization in the successful integration of philanthropy in their day-to-day work.</p>
        </div>
      </div>
    );
  }
}