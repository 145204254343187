import React, { Component } from 'react';
import './everydayUnseenStory.css';
import SeattleIntro from './story/ia-seattleIntro';
import IB from './story/ib';
import IC from './story/ic';
import ID from './story/id';
import IE from './story/ie';
import ONEAB from './story/oneAB';
import ONEC from './story/oneC';
import TWOA from './story/twoA';
import TWOB from './story/twoB';
import TWOD from './story/twoD';
import TWOE from './story/twoE';
import TWOF from './story/twoF';
import TWOG from './story/twoG';
import THREEA from './story/threeA';
import THREEB from './story/threeB';
import THREECD from './story/threeCD';
import FOURA from './story/fourA';
import FOURB from './story/fourB';
import FOURC from './story/fourC';
import FOURD from './story/fourD';
import FOUREF from './story/fourEF';
import FIVEAB from './story/fiveAB';
import FIVEC from './story/fiveC';
import FIVED from './story/fiveD';

class EverydayUnseenStory extends Component {
  render() {
    return (
      <div id="everydayUnseenStory-container">
        <div class="everydayUnseenStory-sticky-container">
          <SeattleIntro />
          <IB />
          <IC />
          <ID />
          <IE />
          <ONEAB />
          <ONEC />
          <TWOA />
          <TWOB />
          <TWOD />
          <TWOE />
          <TWOF />
          <TWOG />
          <THREEA />
          <THREEB />
          <THREECD />
          <FOURA />
          <FOURB />
          <FOURC /> 
          <FOURD />    
          <FOUREF />
          <FIVEAB />
          <FIVEC />
          <FIVED />
        </div>
      </div>
    );
  }
}

export default EverydayUnseenStory;