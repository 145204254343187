import React from 'react';
import { SocialIcon } from 'react-social-icons';
import './shortRun-teacherCards.css';

const ShortRunTeacherCards = ({props}) => {
    const teacherData = [
      {
        'name': 'Valerie Niemeyer',
        'cardContent': 'Originally from Southern Washington, she moved up to Seattle in 2013 to pursue a degree in Studio Art from the University of Washington. After graduating, she decided to separate herself a bit from the "fine art" world (whatever that means) and veer more into the realm of comics and illustration!',
        'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Short+Run/Valerie_2.png',
        'userImg': 'https://s3-us-west-2.amazonaws.com/f10-people/Re-edited+Team+Photos+230x300/Tim.jpg',
        'imgUrl': 'https://www.espn.com',
        // 'twitter': '',
        'instagram': 'https://www.instagram.com/valrose157',
        'website': 'https://www.valerieniemeyer.com',
        'websiteTitle': 'Valerie\'s Website'
      },
      {
        'name': 'Jon Garaizar',
        'cardContent': 'Jon Garaizar is a bay area artist living in Capitol Hill. After studying Printmaking at AAU in SF, showing and printing in the east bay and internationally, he now makes scary pictures and books in Seattle.',
        'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Short+Run/jon+g.png',
        'userImg': 'https://s3-us-west-2.amazonaws.com/f10-people/Re-edited+Team+Photos+230x300/Tim.jpg',
        'imgUrl': 'https://www.espn.com',
        // 'twitter': '',
        'instagram': 'https://www.instagram.com/enchanting_stranger',
        'website': 'https://www.jongaraizar.com',
        'websiteTitle': 'Jon\'s Portfolio'
      },
      {
        'name': 'Handa',
        'cardContent': 'Handa\'s work has appeared in local collections such as Intruder, Extruder, Thick as Thieves, Short Run\'s Relay Anthology, and the Seattle Weekly. In the daytime, she is a teacher\'s assistant at a technical college for 3D animation. In the wild, she is a cartoonist, animator, and dedicated cyclist.',
        'cardImg': 'https://s3-us-west-2.amazonaws.com/f10-image/Short+Run/handa_selfie.jpg',
        'userImg': 'https://s3-us-west-2.amazonaws.com/f10-people/Re-edited+Team+Photos+230x300/Tim.jpg',
        'imgUrl': 'https://www.espn.com',
        // 'twitter': 'https://twitter.com/hellahanda',
        'instagram': 'https://www.instagram.com/hellahanda',
        'website': 'https://www.whoishanda.com',
        'websiteTitle': 'Handa\'s Website'
      }
    ];
    const socialIconStyles = {
      height: 30,
      width: 30,
      marginRight: 5,
      marginLeft: 5
    };
    const allTeacherData = teacherData.map((records, index) => (
      <li key={index}>
        <div class="shortRun-card">
          <div class="shortRun-card-image">
            <img src={records.cardImg} alt={records.name}/>
          </div>
          <div class="shortRun-card-content">
            <h4>{records.name}</h4>
            <div class="shortRun-card-text-teacher">
              <p>{records.cardContent}</p>
            </div>
            <div class="shortRun-social">
              <SocialIcon url={records.instagram} style={socialIconStyles}/>
              {/* <SocialIcon url={records.twitter} style={socialIconStyles}/> */}
            </div>
            <p>
              <a href={records.website} target="_blank" rel="noopener noreferrer" class="hashtag">{records.websiteTitle}</a>
            </p>
          </div>
        </div>
      </li>
    ));
  
    return (
      <div id="shortRun-teacherCards-Container">
        <div class="everyday-section-title-shortRun">
          <h4>TEACHERS</h4>
        </div>
        <div class="shortRun-teacherCards-content-container">
          <ul class="shortRun-teacherCards-list">
            {allTeacherData}
          </ul>
        </div>
      </div>
        
    );
  }
  
  export default ShortRunTeacherCards;