import React from 'react';
import './technologyMediaInformationLiteracy-Intro.css';

const TechnologyMediaInformationLiteracyIntro = () => {
   return (
    <div class="technologyMediaInformationLiteracy-intro-container">
      <div class="technologyMediaInformationLiteracy-intro-content-container">
        <div class="technologyMediaInformationLiteracy-intro-paragraph">
          <p>The internet and other emerging tech, like AI chatbots and adaptive learning tools, continue to transform learning environments and viable career pathways for today's youth. Through a blend of research, educational programming, and philanthropy, foundry10 seeks to ensure young people are equipped with the tools to safely, responsibly, and effectively navigate online spaces and create with digital technologies.</p>
        </div>
      </div>
    </div>
   )
}
export default TechnologyMediaInformationLiteracyIntro;