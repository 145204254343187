import React, { Component } from 'react';
import './fourEF.css';
import lottie from 'lottie-web';

class FOUREF extends Component {
  componentDidMount() {
    lottie.loadAnimation({
      container: document.getElementById("fourEF"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "/svg/4ef.json"
    });
  }
  render() {
    return (
      <div id="fourEF-container">
        <div class="everyday-section-title">
          <h4>FOUR (EVERYDAY UNSEEN)</h4>
        </div>
        <div class="fourEF-content-container">
          <div class="fourEF-text-container">
            <h2>Everyone began to say their goodbyes and as the artists left, the art did too.</h2>
            <p>Some pieces had been sold, providing a few artists with their first professional experience. Most artists chose to keep their pieces and packed them up to hang on their own walls.</p>
            <p>Soon, there was nothing left but a former autoshop and the few of us who had first come together to plan, create and share a gallery exclusively for young artists.</p>
            <h4>We had helped make the Everyday Unseen seeable, at least for one day.</h4>
          </div>
          <div class="fourEF-svg-container">
            <div id="fourEF" class="fourEF-svg-content"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default FOUREF;