import React, { Component } from 'react';
import './autoLandingPage-Intro.css';

export default class AutoLandingPageIntro extends Component {
  render() {
    return(
      <div id="autoLandingPage-intro-container">
        <div class="autoLandingPage-intro-content-container">
          <div class="autoLandingPage-intro-bold">
            <h3>Modern society revolves around transportation from logistics and shipping to personal commuter vehicles and ridesharing apps.</h3>
            {/* <h3>Outside the Shop: Automotive Trips for Education</h3> */}
          </div>
          <div class="autoLandingPage-intro-normal">
            <p>None of this would be possible without the developments within the field of automotive technology. This rapidly-evolving field has seen advances from electric racecars and advanced driver-assists, to some of the safest vehicles that have reduced accidents and injuries. foundry10 knows that students in auto tech programs are our future technicians, engineers, test drivers, and innovators.</p>
          </div>
        </div>
      </div>
    );
  }
}