import React, { Component } from 'react';
import Accordion from './Accordion';
import './bandLab-Description.css';
import bandLabAccordionData from './bandLab-LessonData';

export default class BandLabDescription extends Component {

  
  render() {

    return(
      <div id="bandLab-description-container">
        <div className="bandLab-description-content-container">
          <div className="bandLab-description-intro-container">
            <h1>Course Description</h1>
            <p>In this course, you will learn the basics of digital audio production. You will use BandLab, an online DAW (digital audio workstation) to complete and return your work. Before the course, please go to <a href="https://www.bandlab.com" target="_blank" rel="noopener noreferrer">www.bandlab.com</a> and create a free account.</p>
          </div>
          <div className="bandLab-objectives-container">
            <h1>Learning Objectives</h1>
            <ul>
              <li>Build technical skills with audio equipment and software</li>
              <li>Use audio production tools as a medium for self-expression</li>
            </ul>
          </div>
          <div className="bandLab-outcomes-container">
            <h1>Expected Outcomes</h1>
            <ul>
              <li>Students will be able to demonstrate mastery of basic audio production tools, techniques, and vocabulary</li>
              <li>Students will create their own original audio pieces in formats of their choice (i.e. music, podcast, sound design, etc.)</li>
              <li>Students will share their work with peers and family as part of a culminating showcase at the end of the semester</li>
            </ul>
          </div>
          <div className="bandLab-rubric-container">
            <h1>Grading Rubric (optional)</h1>
            <div className="bandLab-rubric-grid-container">
              <div className="bandLab-rubric-grid-box">
                <p>0</p>
                <p>I did not attempt the lesson.</p>
              </div>
              <div className="bandLab-rubric-grid-box">
                <p>5</p>
                <p>I demonstrated one technical skill taught in the lesson.</p>
              </div>
              <div className="bandLab-rubric-grid-box">
                <p>10</p>
                <p>I demonstrated some of the technical skills taught in the lesson.</p>
              </div>
              <div className="bandLab-rubric-grid-box">
                <p>15</p>
                <p>I demonstrated most of the technical skills taught in the lesson.</p>
              </div>
              <div className="bandLab-rubric-grid-box">
                <p>20</p>
                <p>I demonstrated all of the technical skills taught in the lesson.</p>
              </div>
            </div>
          </div>
          <div className="bandLab-curriculum-container">
            <h1>Curriculum</h1>
            <p>There are a total of 11 lessons and assignments for this course. The first 6 lessons cover the technical skills you will need to create music-based and dialogue-based audio. The final 3 assignments will be open-ended opportunities to choose the type of audio you are interested in creating. Lessons 07 and 08 will overlap with Assignments 01 and 02. These lessons on mixing and mastering audio will be based on what type of project you’re working on, and will help you put a final polish on your audio.</p>
          </div>
          <div className="bandLab-accordion-container">
            <div className="bandLab-accordion-content">
              {bandLabAccordionData.map(({ lesson }) => (
                <Accordion lesson={lesson} />
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}