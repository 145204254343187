import React, { Component } from 'react';
import './annualReport2021GamesLearning-Spotlights.css';

export default class AnnualReportGamesLearningSpotlights extends Component {
  render() {
    return(
      <div id="annualReport2021GamesLearning-spotlights-container">
        <div className="annualReport2021GamesLearning-spotlight-first-title-bar">
          <p>Spotlight: Tabletop Roleplaying</p>
        </div>
        <div className="annualReport2021GamesLearning-spotlights-content-container">
          <div className="annualReport2021GamesLearning-spotlights-text-container">
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Subpages/tabletop.png" alt="cartoon characters and d20 in line art style"/>
            <p>
            Dungeons and Dragons has seen a renaissance during the pandemic as digital tools have advanced to support remote gameplay. We partnered with <a href="https://gametogrow.org/" target="_blank" rel="noopener noreferrer">Game to Grow</a>, a Seattle-based non-profit that runs tabletop roleplaying games (TTRPGs) for neurodiverse youth, to explore how these games can help youth develop social and emotional skills. We have also developed our own TTRPG, Endless Blue, that offers young players the opportunity to explore what it is like to be a marine professional on a research vessel.  Through these two efforts, we endeavor to understand more about the potential of TTRPGs as a tool to empower youth and for educators to learn through collective storytelling and roleplay.
            <br /> <br />
            <a href="https://medium.com/the-foundry10-voice/all-a-board-game-for-marine-science-learning-bbcd3b5c20e" target="_blank" rel="noopener noreferrer">All A-Board Game for Marine Science Learning</a>
            </p>
          </div>
        </div>
        <div className="annualReport2021GamesLearning-spotlight-second-title-bar">
          <p>Spotlight: Providing Games to Families</p>
        </div>
        <div className="annualReport2021GamesLearning-spotlights-content-container">
          <div className="annualReport2021GamesLearning-spotlights-text-container">
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Subpages/familygames.jpg" alt="young children playing at a table in orange and teal"/>
            <p>
            A well-publicized challenge facing children and families during the COVID-19 pandemic was isolation and loneliness. Children lost many opportunities to engage with peers, and families often found themselves at home with little option for outside interaction. Research on games has shown positive benefits for collaboration, team-building, self-efficacy and well-being. We wanted to keep kids playing and learning while also giving families something fun and creative to do together. To do that, we worked with our network of community partners, game publishers, and local game shops to get games and game design materials into the hands of families. Through this effort, we were able to distribute thousands of dollars worth of games to families and children during the pandemic. We look forward to further expanding our work on tabletop games this coming year.
            <br /> <br />
            <a href="https://drive.google.com/file/d/1rnV8RhnUekgTJPCH1uSlPWHdyepn8OOM/view" target="_blank" rel="noopener noreferrer">Endless Blue Players Guide</a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}