//imported in LearningConcept.js, SubjectArea.js, People.js, Research.js

import React, { Component } from 'react';
// 
import './GetTechFromDb2.css';
// import Tooltip from '@material-ui/core/Tooltip';

class GetTechFromDb extends Component {
  constructor(props) {
    super(props)
    this.state = {
      programList: []
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      programList: nextProps.all
    })
  }

  render() {
    // //FIND ITEMS GOING OVER THE VIEWPORT
    // var docWidth = document.documentElement.offsetWidth;
    //
    // [].forEach.call(
    //   document.querySelectorAll('*'),
    //   function(el) {
    //     // console.log('going over...');
    //     if (el.offsetWidth > docWidth) {
    //       console.log('you are going over the edge in getprogramsfromdb.js. I will find you and get you... ', el);
    //     }
    //   }
    // );
       
       if(this.state.programList.length > 0) {
        const techDomainsCards = this.state.programList.map((records, index) => (
          <a href={'/programs/'+records.interestArea+'/'+records.nameShort}>
            <li>
              <div class="final-cards-container">
                <img src={records.programCardImage} alt={records.name} />
                <p id={records.name} class="final-cards-title">{records.name}</p>
                <div id={records.name} class="final-cards-overlay"></div>
              </div>
            </li>
          </a>
        ));
          return(
                <div class="interestArea-cardsList2-container">
                    <div class="interestArea-domain-title">
                      <h2>Tech Domain</h2>
                    </div>
                    <ul id="interestArea-cardsList2" class="interestArea-events-list2">
                      {techDomainsCards}
                    </ul>
                </div>
          );
       } else {
        return (
          <p className='VisibilityOff'>
  
          </p>
        )
      }
    
    // if (this.state.programList.length > 0) {
    //   const techDomainCards = this.state.programList.map((records, index) => {
    //     return (
    //       <a href={'/programs/'+records.domain+'/'+records.nameShort}>
    //         <li>
    //           <div class="final-cards-container">
    //             <img src={records.programCardImage} alt={records.name} />
    //             <p id={records.name} class="final-cards-title">{records.name}</p>
    //             <div id={records.name} class="final-cards-overlay"></div>
    //           </div>
    //         </li>
    //       </a>
    //     )
    //   })
    //   return (
    //             <div class="interestArea-cardsList2-container">
    //                 <ul id="interestArea-cardsList2" class="interestArea-events-list2">
    //                   {techDomainCards}
    //                 </ul>
    //             </div>
    //   )
    // } else {
    //   return (
    //     <p></p>
    //   )
    // }
  }
}

export default GetTechFromDb;