import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import './AnnualReport2021-ProgramLinks.css';

export default class AnnualReport2021ProgramLinks extends Component {

  render() {
    return (
        <div id="annualReport2021-ProgramLinkslanding-main-container">
          {/* BODY */}
          <div id="annualReport2021-ProgramLinkslanding-main-content-container">
            <div class="annualReport2021-ProgramLinks-titleLine"></div>
            <h1>EXPLORE MORE PROGRAMS</h1>
            {/* NAV LINKS */}
            <div id="annualReport2021-ProgramLinksList-main-container">
              <ul class="annualReport2021-ProgramLinksList-unorderedList">
                <li>
                  <Button style={{ fontFamily: 'Helvetica Neue Light', fontWeight: 'bold', color: '#ffffff', textTransform: 'capitalize', boxShadow: '1px 1px #b1b1b1', width: '150px', borderRadius: '12px', background: 'radial-gradient(#4a7880, #00464f)' }} href="/annual-report/2021/programs/artistic-design">ARTISTIC DESIGN</Button>
                </li>
                <li>
                  <Button style={{ fontFamily: 'Helvetica Neue Light', fontWeight: 'bold', color: '#ffffff', textTransform: 'capitalize', boxShadow: '1px 1px #b1b1b1', width: '150px', borderRadius: '12px', background: 'radial-gradient(#4a7880, #00464f)' }} href="/annual-report/2021/programs/dance">DANCE</Button>
                </li>
                <li>
                  <Button style={{ fontFamily: 'Helvetica Neue Light', fontWeight: 'bold', color: '#ffffff', textTransform: 'capitalize', boxShadow: '1px 1px #b1b1b1', width: '150px', borderRadius: '12px', background: 'radial-gradient(#4a7880, #00464f)' }} href="/annual-report/2021/programs/digital-audio">DIGITAL AUDIO</Button>
                </li>
                <li>
                  <Button style={{ fontFamily: 'Helvetica Neue Light', fontWeight: 'bold', color: '#ffffff', textTransform: 'capitalize', boxShadow: '1px 1px #b1b1b1', width: '150px', borderRadius: '12px', background: 'radial-gradient(#4a7880, #00464f)' }} href="/annual-report/2021/programs/dramatic-arts">DRAMATIC ARTS</Button>
                </li>
                <li>
                  <Button style={{ fontFamily: 'Helvetica Neue Light', fontWeight: 'bold', color: '#ffffff', textTransform: 'capitalize', boxShadow: '1px 1px #b1b1b1', width: '150px', borderRadius: '12px', background: 'radial-gradient(#4a7880, #00464f)' }} href="/annual-report/2021/programs/ed-tech">EDUCATION TECHNOLOGY</Button>
                </li>
                <li>
                  <Button style={{ fontFamily: 'Helvetica Neue Light', fontWeight: 'bold', color: '#ffffff', textTransform: 'capitalize', boxShadow: '1px 1px #b1b1b1', width: '150px', borderRadius: '12px', background: 'radial-gradient(#4a7880, #00464f)' }} href="/annual-report/2021/programs/fin-ed">FINANCIAL EDUCATION</Button>
                </li>
                <li>
                  <Button style={{ fontFamily: 'Helvetica Neue Light', fontWeight: 'bold', color: '#ffffff', textTransform: 'capitalize', boxShadow: '1px 1px #b1b1b1', width: '150px', borderRadius: '12px', background: 'radial-gradient(#4a7880, #00464f)' }} href="/annual-report/2021/programs/games-learning">GAMES & LEARNING</Button>
                </li>
                <li>
                  <Button style={{ fontFamily: 'Helvetica Neue Light', fontWeight: 'bold', color: '#ffffff', textTransform: 'capitalize', boxShadow: '1px 1px #b1b1b1', width: '150px', borderRadius: '12px', background: 'radial-gradient(#4a7880, #00464f)' }} href="/annual-report/2021/programs/internships">INTERNSHIPS</Button>
                </li>
                <li>
                  <Button style={{ fontFamily: 'Helvetica Neue Light', fontWeight: 'bold', color: '#ffffff', textTransform: 'capitalize', boxShadow: '1px 1px #b1b1b1', width: '150px', borderRadius: '12px', background: 'radial-gradient(#4a7880, #00464f)' }} href="/annual-report/2021/programs/interventions">INTERVENTIONS</Button>
                </li>
                <li>
                  <Button style={{ fontFamily: 'Helvetica Neue Light', fontWeight: 'bold', color: '#ffffff', textTransform: 'capitalize', boxShadow: '1px 1px #b1b1b1', width: '150px', borderRadius: '12px', background: 'radial-gradient(#4a7880, #00464f)' }} href="/annual-report/2021/programs/marine-science">MARINE SCIENCE</Button>
                </li>
                <li>
                  <Button style={{ fontFamily: 'Helvetica Neue Light', fontWeight: 'bold', color: '#ffffff', textTransform: 'capitalize', boxShadow: '1px 1px #b1b1b1', width: '150px', borderRadius: '12px', background: 'radial-gradient(#4a7880, #00464f)' }} href="/annual-report/2021/programs/stem">STEM</Button>
                </li>
                <li>
                  <Button style={{ fontFamily: 'Helvetica Neue Light', fontWeight: 'bold', color: '#ffffff', textTransform: 'capitalize', boxShadow: '1px 1px #b1b1b1', width: '150px', borderRadius: '12px', background: 'radial-gradient(#4a7880, #00464f)' }} href="/annual-report/2021/programs/trades">TRADES</Button>
                </li>
                <li>
                  <Button style={{ fontFamily: 'Helvetica Neue Light', fontWeight: 'bold', color: '#ffffff', textTransform: 'capitalize', boxShadow: '1px 1px #b1b1b1', width: '150px', borderRadius: '12px', background: 'radial-gradient(#4a7880, #00464f)' }} href="/annual-report/2021/programs">ALL PROGRAMS</Button>
                </li>
              </ul>
            </div>
          </div>
        </div>
    );
  }
}