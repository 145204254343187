import React, { Component } from 'react';
import AnnualReportInterventionsOverview from './annualReport2021Interventions-Overview';
import AnnualReportInterventionsSpotlights from './annualReport2021Interventions-Spotlights';
import AnnualReport2021ProgramLinks from '../annualReport2021ProgramLinks/AnnualReport2021-ProgramLinks';
import NewFooter from '../../../../pages-other/NewFooter';
import './annualReport2021Interventions-Content.css';

export default class AnnualReportInterventionsContent extends Component {
  render() {
    return(
      <div id="annualReport2021Interventions-content-container">
        <AnnualReportInterventionsOverview />
        <AnnualReportInterventionsSpotlights />
        <AnnualReport2021ProgramLinks />
        <NewFooter />
      </div>
    );
  }
}