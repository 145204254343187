import React, { Component } from 'react';
import './annualReport2021DigitalAudio-Header.css';

export default class AnnualReportDigitalAudioHeader extends Component {
  render() {
    return(
      <div id="annualReport2021DigitalAudio-header-container">
        <div className="annualReport2021DigitalAudio-header-content">
          <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Subpages/Final+Header+Images/audio-8.png" alt="foundry10 annual report 2020-2021 digital audio team header" />
        </div>
      </div>
    );
  }
}