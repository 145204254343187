import React from "react";
import './researchRedesign-Breadcrumb.css'

function ResearchBreadcrumb() {


return (
<div className="researchRedesignBreadcrumb-container">
    <div className="researchRedesignBreadcrumb-content-container">
        <a href="/"><i className="fa-solid fa-house" /></a>
        <p>/</p>
        <h2>Research</h2>
    </div>
</div>
);

}

export default ResearchBreadcrumb;
