import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player';
import './electric-dragster.css';
import NewFooter from '../../../pages-other/NewFooter';

export default class ElectricDragster extends Component {
  render() {
    return(
        <div id="programProject-main-container">
        <Helmet>
          <title>foundry10 Automotive Education Project: Electric Dragster</title>
          <meta
            name="title"
            content="foundry10 Automotive Education Project: Electric Dragster"
          />
          <meta
            name="description"
            content="Explore foundry10's automotive technology project-based learning for youth featuring the Electric Dragster Project."
          />
          <meta property="og:title" content="foundry10 Automotive Education Project: Electric Dragster" />
          <meta
            property="og:description"
            content="Explore foundry10's automotive technology project-based learning for youth featuring the Electric Dragster Project."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/automotive-projects/electric-dragster" />
          <link rel="canonical" href="https://www.foundry10.org/automotive-projects/electric-dragster" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Automotive Education Project: Electric Dragster",
                "description": "Explore foundry10's automotive technology project-based learning for youth featuring the Electric Dragster Project.",
                "url": "https://www.foundry10.org/automotive-projects/electric-dragster"
              }
            `}
          </script>
        </Helmet>
        {/* HEADER */}
        <div class="programProject-header-container-dragster">
          <div class="programProject-header-content-container">
            <h1>Electric Dragster Project</h1>
          </div>
        </div>
        {/* BODY */}
        <div id="programProject-main-content-container">
          {/* DESCRIPTION */}
          <div id="vrLandingPage-description-container-dragster">
            <div class="vrLandingPage-description-content-container-detroit">
              <p>We visited local high school auto programs to meet teachers and hear about their programs, and when we visited Bothell High School’s magnet auto program in the Northshore School District, we knew we had good fit philosophically and technically. The students pitched different ideas for special build projects in the fall of 2014, with ideas ranging from a drift car, a gas-powered drag car, an electric dragster, and a baja-style truck. Ultimately, in a close vote, the electric dragster won out.</p>
              <p>The Northshore Automotive Technology Program undertook a tremendous challenge: To not only build out a fully fledged drag car, but to set the world record for an AC powered dragster, a relatively new and unexplored sector of drag racing cars. This is not a project typically undertaken by high school students, but under the guidance of their teacher, these students rose to the challenge.</p>
              <p>Local electric car racing experts came in to work with the students and teacher, there were special field trips to meet with motor and battery makers, and even local paint shops took an interest in the project and had those students interested in paint come in to do work. In addition, students who were interested in doing the vinyl wrap on the car worked with experts in wrapping to get their designs on the vehicle.</p>
            </div>
          </div>
          {/* VIDEO */}
          <div id="programProject-video-container-autoProjects">
            <div class="programProject-video-content-container-autoProjects">
              <div class="videoCard-container">
                <ReactPlayer
                url='https://youtu.be/mkVK4kofuGA'
                className='react-player'
                //   playing
                controls
                width='100%'
                height='100%'
                />
              </div>
            </div>
          </div>
          <NewFooter />
        </div>
      </div>
    );
  }
}