import React from 'react';
import './DocumentViewer.css';

const DocumentViewer = ({documentUrl}) => {
  let renderDocuments = documentUrl.map((document, index) => {
    if (document.url !== '') {
      return (
        <div className='research-documentViewerHolder'>
          <a className='research-documentViewerEmbed' 
            target={document.name} 
            href={document.url} 
            alt={document.name} 
            >
              <img class={document.name} alt={document.name} longdesc={document.name} src={document.type === 'doc' ? 'https://s3-us-west-2.amazonaws.com/f10-misc/doc-icon.png' : document.thumbnailUrl} width="130" height="200" />
              <span class="research-documentViewerTitleNew">
                <div class="research-documentViewerTitleNew2">{document.name}</div>
              </span>
          </a>
        </div>
      )
    } else {
      return null
    }
  })
  if (documentUrl.length > 0) {
    return (
        <div className='research-documentViewerContainer'>
          {documentUrl.length !== 0 ? renderDocuments : null}
        </div>
    )
  } else {
    return (
      <p style={{display: 'none'}}></p>
    )
  }
}

export default DocumentViewer;
