import React, { Component } from 'react';
import './annualReport2021FinEd-Overview.css';

export default class AnnualReportFinEdOverview extends Component {
  render() {
    return(
      <div id="annualReport2021FinEd-overview-container">
          <div className="annualReport2021FinEd-overview-text-container">
            <h1>Financial Education Team</h1>
            <p>
            Financial Education is a newer interest area at foundry10. The team emerged as a result of hearing from youth and educators that consistent, relevant financial education for high school and college students was lacking. Financial education consistently poses challenges for educators in terms of meaningful course integration and adequate student exposure in regular classes. We wanted to understand what questions, worries, and areas of interest youth had around financial education. Additionally, the literature shows gender discrepancies in terms of exposure to financial education content. For that reason, we wanted our work to emphasize women. Our hope is that, in the coming years, this team can use research-based findings to help construct valuable Financial Education opportunities for youth and educators.
            </p>
          </div>
      </div>
    );
  }
}