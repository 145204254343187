import React, { Component } from 'react';
import WorkshopsLandingPageIntro from './workshopsLandingPage-Intro';
import WorkshopsLandingPageDescription from './workshopsLandingPage-Description';
import WorkshopsLandingPageLinks from './workshopsLandingPage-Links';
import NewFooter from '../../../../pages-other/NewFooter';
import './workshopsLandingPage-Content.css';

export default class WorkshopsLandingPageContent extends Component {
  render() {
    return(
      <div id="workshopsLandingPage-content-container">
        <WorkshopsLandingPageIntro />
        <WorkshopsLandingPageDescription />
        <WorkshopsLandingPageLinks />
        <NewFooter />
      </div>
    );
  }
}