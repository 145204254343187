import React, { Component } from 'react';
import './roboticsLandingPage-Links.css';

export default class RoboticsLandingPageLinks extends Component {
  render() {
    return(
      <div id="roboticsLandingPage-links-container">
        <div class="roboticsLandingPage-links-content-container">
          <div class="interestArea-cardsList2-container">
            <div class="interestArea-domain-title">
              <h2>Resources</h2>
            </div>
            <ul id="interestArea-cardsList2" class="interestArea-events-list2">
              <a href={'http://www.foundry10.org/research/elementary-lego-and-robotics'} target="_blank">
               <li>
                <div class="roboticsLandingPage-cards-container">
                  <img src="https://f10-image.s3-us-west-2.amazonaws.com/New+Site/Research/elementary+lego+and+robotics/elementary+lego+and+robotics+header.jpg" alt="Elementary Lego and Robotics" />
                  <p class="roboticsLandingPage-cards-title">Elementary Robotics</p>
                  <div class="roboticsLandingPage-cards-overlay"></div>
                </div>
                </li>
              </a>
              <a href={'http://www.foundry10.org/research/robotics-fall-2016-brief'} target="_blank">
               <li>
                <div class="roboticsLandingPage-cards-container">
                  <img src="https://f10-image.s3-us-west-2.amazonaws.com/New+Site/Research/robotics+fall+2016+brief/robotics+fall+2016+brief+header.jpg" alt="Robotics Fall 2016 Brief" />
                  <p class="roboticsLandingPage-cards-title">Robotics - Fall 2016</p>
                  <div class="roboticsLandingPage-cards-overlay"></div>
                </div>
                </li>
              </a>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}