import React from "react";
import './Biological-Oceanographer.css';

export default function BiologicalOceanographer({ title, subtitle, dark, id }) {
  return (
    // <div className={"section" + (dark ? " section-dark" : "")}>
    <div className="biologicalOceanographer-container">
      <div className="biologicalOceanographer-content-container" id={id}>
        <div className="biologicalOceanographer-final-content-container">
          <div className="biologicalOceanographer-illustration">
            <h1>Click below to learn more!</h1>
            <a class="biologicalOceanographer-link" href="/marine-science-careers/oceanography">
              <img src='https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/oceanography1.png' alt='biological oceanographer illustration'/>
              <p class="biologicalOceanographer-title">Meet BethElLee & Marisa!</p>
              <div class="biologicalOceanographer-cards-overlay"></div>
            </a>
          </div>
          <div className="biologicalOceanographer-description">
            <h2>"Sea Sleuth"</h2>
            <p>RELATED TITLES: Ocean Scientist, Ocean Chemist, Ocean Geophysicist, Marine Researcher</p>
            <p>From ocean chemistry to wave physics, from photosynthesizers at the surface to chemosynthesizers on the depths, oceanographers are dedicated to collecting evidence of the unseen forces that power our ocean and climate.</p>
            <div className="biologicalOceanographer-requirements">
              <h2>Basic Degree/Training Requirements</h2>
              <ul className="biologicalOceanographer-iconList">
                <li>
                  <div class="biologicalOceanographer-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/black-HS-Diploma.png" alt="high school diploma" />
                  </div>
                </li>
                <li>
                  <div class="biologicalOceanographer-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/black-Undergrad-Cap.png" alt="undergrad degree" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}