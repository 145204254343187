import React, { Component } from 'react';
import ProjectsLandingPageIntro from './projectsLandingPage-Intro';
// import ProjectsLandingPageDescription from './projectsLandingPage-Description';
import ProjectsLandingPageLinks from './projectsLandingPage-Links';
import NewFooter from '../../../pages-other/NewFooter';
import './projectsLandingPage-Content.css';

export default class ProjectsLandingPageContent extends Component {
  render() {
    return(
      <div id="projectsLandingPage-content-container">
        <ProjectsLandingPageIntro />
        {/* <ProjectsLandingPageDescription /> */}
        <ProjectsLandingPageLinks />
        <NewFooter />
      </div>
    );
  }
}