import React, { Component } from 'react';
import './bandLab-Links.css';

export default class bandLabLinks extends Component {
  render() {
    return(
      <div id="bandLab-links-container">
        <div class="bandLab-links-content-container">
          <div class="interestArea-cardsList2-container">
            <div class="interestArea-domain-title">
              <h2>Resources</h2>
            </div>
            <ul id="interestArea-cardsList2" class="interestArea-events-list2">
              <a href={'https://drive.google.com/file/d/1z8lzDQ959OUOCljrBeRYbjGv_gzNhuZF/view?usp=sharing'} target="_blank">
               <li>
                <div class="bandLab-cards-container">
                  <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Digital+Audio/Bandlab.jpg" alt="BandLab Logo" />
                  <p class="bandLab-cards-title">CURRICULUM (PDF)</p>
                  <div class="bandLab-cards-overlay"></div>
                </div>
                </li>
              </a>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}