import React, { Component } from 'react';
import './annualReport2021Philanthropy-Overview.css';

export default class AnnualReportPhilanthropyOverview extends Component {
  render() {
    return(
      <div id="annualReport2021philanthropy-overview-container">
          <div className="annualReport2021philanthropy-overview-text-container">
            <h1>Philanthropy Overview</h1>
            <p>
            Philanthropy at foundry10 takes a collaborative, community-based approach. We partner with schools and community organizations to problem-solve and enhance learning opportunities for youth. There is a flexibility and responsiveness to our work; at times we are working to support curriculum adoption, other times the need we are working to fill involves student social emotional support and, in this past year, much of our philanthropy was focused on meeting basic needs. We come into our conversations curious to learn more about the communities we serve and what they see as most necessary to help children, and then we work to ethically, efficiently and collaboratively address those needs.
            </p>
          </div>
      </div>
    );
  }
}