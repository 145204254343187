import React, { useState } from 'react';
import './ResearchTopics-Four.css';

function ResearchTopicsFour() {
    const [isActive, setIsActive] = useState(false);
    // const [hasMore, setHasMore] = useState(false);

    return (


            <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-item">
                <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-title" onClick={() => setIsActive(!isActive)}>
                    <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-image">
                        <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Technology%2C+Media%2C+and+Information+Literacy/Research+Topics/IA+Team+Page+Icons_Equitable+Access+to+Digital+Tools+and+Media+1-.png" alt="students doing work"/>
                    </div>
                    <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-text">
                        <h2 className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-title-text">Equitable Access to Digital Tools and Media</h2>
                        {/* <h3>{research.subtitle}</h3> */}
                    </div>
                    <i className={ isActive ?  `fa-solid fa-angle-up`:`fa-solid fa-angle-down`}></i>                  
                </div>
                {isActive && <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-content">
                    <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-description">
                        <p>Ensuring that every individual has an equal opportunity to access and harness technology, media, and information is more than just a goal—it's a necessity. This research focuses on identifying meaningful opportunities for ensuring that youth and educators, regardless of their socioeconomic status, geographic location, or other factors, have the tools and resources they need to become informed and literate consumers and developers of technology, media, and information.</p>
                    </div>  
                    <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-descriptionTwo">
                        <h2>Guiding Questions:</h2>
                        <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>What are the barriers to technology, media, and information literacy that young people face?</p>
                        </div>
                        <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>What opportunities can we create to make sure young people have access to technology, media, and information resources they need?</p>
                        </div>
                    </div>
                    <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-descriptionTwo">
                        <h2>Explore foundry10 Research and Resources Related to This Topic:</h2>
                        <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p><a href="https://www.foundry10.org/research/lets-draw-chocolate-milk-together" target="_blank" rel="noopener noreferrer">Supports and Challenges for Families' Joint Engagement with Artistic Tablet Apps (peer-reviewed publication)</a></p>
                        </div>
                    </div>
                    {/* <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-descriptionThree">
                        <h2>Curriculum + Education</h2>
                        <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>What are important considerations for school-based civic learning opportunities that support meaningful youth engagement online?</p>
                        </div>
                        <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How should we approach curriculum focused on digital citizenship more effectively to support the skills youth need to thrive in today’s society?</p>
                        </div>
                        <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>To what extent are young people’s perspectives included in the development & implementation of curriculum about being a digital citizen?</p>
                        </div>
                    </div> */}
                </div>}
            </div>

    );
}

export default ResearchTopicsFour;
