import React, { Component } from 'react';
import StemDbrLabBody from './stemDbrLab-Body';
import NewFooter from '../../../pages-other/NewFooter';
import './stemDbrLab-Content.css';

export default class StemDbrLabContent extends Component {
  render() {
    return(
      <div id="stemDbrLab-content-container">
        <StemDbrLabBody />
        <NewFooter />
      </div>
    );
  }
}