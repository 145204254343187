import React, { Component } from 'react';
import './annualReport2021Research-Highlights.css';

export default class AnnualReportResearchHighlights extends Component {
  render() {
    return(
      <div id="annualReport2021research-highlights-container">
          <div className="annualReport2021research-highlights-content-container">
            <div className="annualReport2021research-highlights-text-container">
              <h1>Research Highlights</h1>
            </div>
            <div className="annualReport2021research-highlights-card-container">
              <div className="annualReport2021research-highlights-card-individ">
                <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Research+Landing+Page/gapyear.jpg" alt="Gap Year" />
                <div className="annualReport2021research-highlights-card-individ-text-container">
                  <h1>Gap Year White Paper</h1>
                  <p>
                  The research team at foundry10 surveyed over 300 WA State students who graduated high school in 2020 to see how the pandemic impacted their plans to pursue post-secondary education, the challenges and barriers it presented, and their overall feelings about college in the current climate. Findings suggest that traditionally underserved students may be more likely to suffer hardships as a result of the coronavirus pandemic and are in need of assistance as they plan for future undergraduate enrollment. The white paper offers implications and recommendations to ensure that those youth most impacted by the pandemic receive the greatest support. We were excited to see that local partners in the Community Colleges found the report useful for their policy work with the state.
                  </p>
                  <a href="https://drive.google.com/file/d/1F1pc3Oy-5-lRGhWx6k02rUMwd6y6n5iu/view" target="_blank" rel="noopener noreferrer">
                    Gap Year White Paper
                  </a>
                </div>
              </div>
              <div className="annualReport2021research-highlights-card-individ">
                <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Research+Landing+Page/wastate.jpg" alt="student raising hand while working on laptop" />
                <div className="annualReport2021research-highlights-card-individ-text-container">
                  <h1>Washington State Teacher Technology Use Study</h1>
                  <p>
                  Understanding technology instruction practices can yield a better understanding of existing trends and help identify targets for refining pedagogical practice. This study surveyed 196 Washington State K-12 teachers on their instructional technology use to provide a snapshot of how teachers use technology in a geographical region with relatively high access to educational technology. Teachers used technology most for instruction, writing & testing, and student research, and least for programming, analysis, and recreation. Cluster analysis identified five distinct profiles based on teachers’ technology use: Consistent, Investigators, Traditionalists, Communicators, and Low Users. The paper identifies factors that may influence technology integration practices, and discusses implications for research on higher-order thinking skills and teacher professional development.                  </p>
                </div>
              </div>
              <div className="annualReport2021research-highlights-card-individ">
                <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Research+Landing+Page/gametogrow.jpg" alt="d20 on a laptop near a ttrpg rulebook" />
                <div className="annualReport2021research-highlights-card-individ-text-container">
                  <h1>Game to Grow</h1>
                  <p>
                  Our researchers are currently conducting a mixed-methods research study examining youth’s experiences with social-emotional learning development after participating in a tabletop gaming program run by Game to Grow— a Seattle-based non-profit organization founded on the belief that games of all kinds have the power to improve people’s lives. The goal of this study is to explore how guided storytelling and character development in weekly Dungeons and Dragons sessions can help youth expand their perspective-taking, communication and collaboration, and imaginative play. We aim for this research to inform the community about the role of tabletop role-playing games in the development of young people.                  </p>
                  <a href="https://www.geekwire.com/2020/nonprofit-takes-dd-digital-help-kids-struggle-socially-study-impact/" target="_blank" rel="noopener noreferrer">
                    Read about the project in Geekwire
                  </a>
                </div>
              </div>
              <div className="annualReport2021research-highlights-card-individ">
                <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Research+Landing+Page/selfstudy.jpg" alt="student writing in joural in front of computer screen" />

                <div className="annualReport2021research-highlights-card-individ-text-container">
                  <h1>Self-Regulated Learning Studies</h1>
                  <p>
                  This year, as many students were learning remotely and asynchronously, developing skills to plan, monitor, and evaluate their own learning were more important than ever. At the same time, it can be hard for students to develop these skills in remote learning environments because of limited social interaction with teachers and other students. foundry10 partnered with local schools to get feedback from middle and high school students on the quality of school interactions during the pandemic, and to understand how students are thinking about managing their own learning in this unique time. Through surveys and focus group interviews, we learned that many students were facing challenges related to self-management, motivation, and communication. We shared the results of these studies back with the school teachers and staff, and worked together to identify tangible ways for teachers and schools to further support students.                  </p>
                  {/* <a href="" target="_blank" rel="noopener noreferrer">
                    Link: Gap Year White Paper
                  </a> */}
                </div>
              </div>
              <div className="annualReport2021research-highlights-card-individ">
                <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Research+Landing+Page/vr.jpg" alt="student wearing vr headset while raising hand in class" />

                <div className="annualReport2021research-highlights-card-individ-text-container">
                  <h1>VR Assessment</h1>
                  <p>
                  As emerging technologies like virtual reality (VR) become more prominent in K-12 classrooms, it is imperative that we study the value these tools can bring to schools from the perspective of both educators and students. In this study, we worked with a group of high school teachers to explore how they integrated VR into their classrooms and developed assessments related to those virtual experiences for their students. This research will help educators and administrators to further consider the value, as well as the complexity, of incorporating emerging technologies in the classroom and developing assessments to evaluate student learning with those technologies.  We were proud to share our findings at NCCE and ASCD and currently have two papers in process, one in a journal for research purposes and one in a more accessible format for classroom teachers.                  </p>
                  {/* <a href="" target="_blank" rel="noopener noreferrer">
                    Link: Gap Year White Paper
                  </a> */}
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }
}