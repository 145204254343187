import React, { Component } from 'react';
import './stemGoBabyGo-Header.css';

export default class StemGoBabyGoHeader extends Component {
  render() {
    return(
      <div id="stemGoBabyGo-header-container">
          <div class="stemGoBabyGo-header-content-container">
            <h1>Go Baby Go: Tackling Real-World Problems to Build Excitement in STEM</h1>
          </div>
      </div>
    );
  }
}