import React, { Component } from 'react';
import PhilanthropyDirectorHeader from './PhilanthropyDirector-Header';
import PhilanthropyDirectorContent from './PhilanthropyDirector-Content';
import './Philanthropy-Director.css';

export default class PhilanthropyDirector extends Component {
  render() {
    return(
      <div id="philanthropyDirector-container">
        <PhilanthropyDirectorHeader />
        <PhilanthropyDirectorContent />
      </div>
    );
  }
}