import React, { Component } from 'react';
import './workshopsLandingPage-Header.css';

export default class WorkshopsLandingPageHeader extends Component {
  render() {
    return(
      <div id="workshopsLandingPage-header-container">
          <div class="workshopsLandingPage-header-content-container">
            <h1>One-Time STEM Workshops</h1>
          </div>
      </div>
    );
  }
}