import React, { Component } from 'react';
import ConceptualDevelopmentLabBody from './conceptualDevelopmentLab-Body';
import NewFooter from '../../../pages-other/NewFooter';
import './conceptualDevelopmentLab-Content.css';

export default class ConceptualDevelopmentLabContent extends Component {
  render() {
    return(
      <div id="conceptualDevelopmentLab-content-container">
        <ConceptualDevelopmentLabBody />
        <NewFooter />
      </div>
    );
  }
}