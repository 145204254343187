import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

const BlockData = ( { block } ) => {
  //const classes = styles;
  const heading = block.icpTitle;
  const image = block.icpImage[0];
  const imageCaption = block.icpImageCaption;
  const content = block.icpContent;
  const imagePosition = block.icpPositionOfImage;
  const bgColor = block.icpBackgroundColor;
  const link = block.linkUrl;
  const alignment = block.contentAlignment;

  const classes = `contentBlock icp ${bgColor}`;
  
  //console.log(classes)
  if (heading) {
    return (
        <section className={classes}>
            <div className="container">
                <Grid container spacing={24} className={imagePosition} >
                    <Grid item md={8} xs={12} className="icpContent">
                        {heading && (
                        <h2>
                            {heading}
                        </h2>
                        )}
                        {content && (
                        <div
                            dangerouslySetInnerHTML={{
                            __html: content,
                            }}
                        ></div>
                        )}
                    </Grid>
                    <Grid item md={4} xs={12}>
                    
                        {image && (
                        <img src={image.url} alt="" />
                        )}
                    </Grid>  
                </Grid>
            </div>
        </section>
    )
  }
}

export default class ImageContent extends Component {
  render() {
    return(
        <BlockData block={this.props.block} />
    );
  }
}
