import React, { Component } from 'react';
import './brierTerrace-Description.css';
import BarChartGender from './Charts/barChartGender';
import BarChartGrade from './Charts/barChartGrade';
import BarChartHome from './Charts/barChartHome';
import BarChartRace from './Charts/barChartRace';
import BarChartDifficulty from './Charts/barChartDifficulty';
import BarChartSkills from './Charts/barChartSkills';
import BoxPlotSupport from './Charts/boxPlotSupport';
import BoxPlotSelfEfficacy from './Charts/boxPlotSelfEfficacy';
import ScatterPlotPeer from './Charts/scatterPlotPeer';
import BoxPlotSelfRegulated from './Charts/boxPlotSelfRegulated';
import BoxPlotGenderSplit from './Charts/boxPlotGenderSplit';
// import LineChartSupport from './Charts/lineChartSupport';
// import LineChartSelfEfficacy from './Charts/lineChartSelfEfficacy';
// import LineChartSelfRegulated from './Charts/lineChartSelfRegulated';

export default class brierTerraceDescription extends Component {

  render() {
    return(
      <div id="brierTerrace-description-container">
        <div class="brierTerrace-description-content-container">
        
          <div class="brierTerrace-topic-container">Key Findings</div>
            <p>
            <span class='focus'>Students reported on difficulties with learning during the pandemic:</span>
              <ul>
                <li>
                The <em>workload</em> for learning online can feel overwhelming and hard to manage.
                </li>
                <li>
                <em>Asynchronous work</em> can be difficult for students.
                </li>
                <li>
                <em>Immediate or real-time feedback</em> from teachers is sometimes harder online than in-person.
                </li>
                <li>
                <em>Timely and helpful communication</em> may be difficult, given the school setup.
                </li>
              </ul> 
              <span class='focus'>Students reflected on personal strengths that are particularly useful for learning during the pandemic:</span>
              <ul>
                <li>
                Many students see remote learning as an <em>opportunity to develop or practice</em> their <em>organization</em> and <em>self-management skills</em>.
                </li>
                <li>
                Many students <em>weren’t able to name particular skills that they’re using</em> during the pandemic.
                </li>
              </ul> 
              <span class='focus'>Students were asked about their existing learning needs:</span>
              <ul>
                <li>
                  Generally, <em>survey respondents had few problems with access</em> to school and school technology;  however, <em>opportunities for increasing access</em> are better access to physical materials, support for  problems with Chromebooks, and additional help for disabled students.
                </li>
              </ul> 
              <span class='focus'>Students completed survey measures that showed:</span>
              <ul>
                <li>
                  Out of all the support types measured, <em>students perceived lower levels of support from their  peers</em> and <em>the highest amount of social support from their families</em>.
                </li>
                <li>
                Out of the three kinds of self-efficacy measured (academic, social, and emotional), <em>students were most confident of their academic skills</em> and <em>least confident of their emotional skills</em>.
                </li>
                <li>
                Out of all the strategic learning skills measured, <em>students scored lowest for planning</em> and <em>scored  highest for reflecting</em>.
                </li>
                <li>
                Students’ self-rated <em>emotional skills were closely related to the amount of support from peers</em> they reported.
                </li>
                <li>
                One group of students (15% of the sample) <em>reported lower levels of teacher and peer support</em>,  and this difference in support appears to be <em>related to lower confidence in academic skills and  lower self-regulated learning skills</em>.
                </li>      
              </ul>
              <span class='focus'>The study looked at differences across student types (grade, gender, and, home context) and found:</span>
              <ul>
                <li>
                No differences between students based on grade.
                </li>
                <li>
                Minimal (and developmentally expected) differences based on gender.
                </li>
                <li>
                No differences based on home context.
                </li>
              </ul>
            </p>
          <div class="brierTerrace-topic-container">Introduction</div>
            <p>
            Brier Terrace Middle School students have been learning remotely throughout the 2020-21 academic  year so far. This study seeks to better understand the impact of the pandemic on learning from the  perspective of students.
             <br/>  
             <br/>
             <span class='focus'>This study focuses on aspects of student learning that may be impacted by remote learning, based on  previous research:</span>
              <ul>
                <li>
                There may be <em>gaps in access</em> that make it difficult for some students to participate in school remotely. 
                </li>
                <li>
                The <em>support students experience</em> may have a substantial effect on student learning, and could  possibly exacerbate existing equity gaps in support. This study looks at three kinds of support: <em>teacher-student connection, peer support, and family support</em>. 
                </li>
                <li>
                For middle schoolers, practice with <em>strategic learning skills</em> in a social and supportive learning  environment is important. Using strategic learning skills independently is important for remote  or asynchronous work, and remote learning may be an opportunity for youth to develop strong  self-management skills. At the same time, it may be difficult for students to cultivate strategic  learning skills while learning relatively isolated from others. For this study, <em>planning,  monitoring, evaluating, reflecting</em> on school work, as well as <em>effort</em> towards school work, are  considered strategic learning skills. 
                </li>
                <li>
                <em>Self-efficacy beliefs</em> determine how people feel, think, motivate themselves and behave.  Students’ impressions of their own competence and their skills might be impacted by the  pandemic, and are a good indicator of student well-being. For this study, we measured students’ <em>academic, social, and emotional self-efficacy</em>. 
                </li>
              </ul>
            Foundry10 conducted a survey with students in December 2020 to ask how students have been thinking  about the school year so far. In particular, the survey examined how students feel about the support  they are receiving for their schoolwork at home and at schools, how students think about their own  capabilities and competencies, and how students think about their ability to plan, monitor, and reflect  on their learning. 

            </p>
          <div class="brierTerrace-topic-container">Survey Questions</div>
            <p>
            <span class='focus'>The survey asked three open-ended questions:</span>
              <ul>
                <li>
                What has been a difficult part of doing schoolwork this year? 
                </li>
                <li>
                Are there skills you have now that are important for you to use in school this year?
                </li>
                <li>
                Is there any kind of support or materials you need right now for school that you’re not getting? 
                </li>
              </ul>
              <span class='focus'>Open-ended responses were coded by theme and summarized.</span>
              <ul>
                <li>
                <em>School connectedness and support</em>: The study uses 19 items from the Student Engagement Instrument  (SEI; Appleton et al., 2006) to assess students’ perceived school connectedness and academic support  from teachers, peers, and family. 
                </li>
                <li>
                <em>Self-efficacy</em>: The Self-Efficacy Questionnaire for Children (SEQ-C; Muris, 2002; Suldo & Schaffer, 2008) is  a 19-item measure that assesses how competent youth feel in three areas: academics, emotional skills,  and social skills. 
                </li>
                <li>
                <em>Self-regulated learning</em>: The study used 36 items from the Self-Regulation of Learning Self-Report Scale  (SRL-SRS; Toering et al., 2012), a measure that elicits students’ practice in planning, self-monitoring,  evaluation, reflection, and effort. 
                </li>
                <li>
                <em>Demographics</em>: The survey included questions on participants’ grade level, gender, and race/ethnicity  (taken from the California Healthy Kids Survey; Austin & Duerr, 2005). A question about the kinds of out of-school support students get while learning at home (shown below as ‘home context’) was also  included.
                </li>      
              </ul>
            </p>
          <div class="brierTerrace-topic-container">Results</div>
            <div class="brierTerrace-topic-content">Summary statistics</div>
              <p>A total of 91 students completed the survey.</p>
              <BarChartGender />
              <p>Respondents were majority (53%) female and majority (63%) 7th graders.</p>
              <BarChartGrade />
              <p>The majority of students (52%) reported that they mostly do their schoolwork by themselves.</p>
              <BarChartHome />
              <p>Respondents were majority (59%) white.</p>
              <BarChartRace />
            <div class="brierTerrace-topic-content">Qualitative Responses</div>
              <p>
                <span class='focus'>“What has been a difficult part of doing schoolwork this year?”</span>
                <br/> 
              Students gave a wide variety of answers for this question. Responses were coded by theme and  summarized. Twenty-three percent of students mentioned a heavy school workload. Twenty percent of  students said that they had problems with staying focused and motivated. Nineteen percent noted that  they have had some technical problems. Thirteen percent said that time management was a struggle,  and another 13% said that communicating with the school or asking for help were hard.</p>
              <BarChartDifficulty />
                <br/>
              <p>
                <span class='focus'>“Are there skills you have now that are important for you to use in school this year?”</span>
                <br/>
              A large proportion of students (23%) noted that their organizational skills were very important for  school this year, and a number of other skills were mentioned. However, 24% of students responded  that they had no applicable skills for school this year. A smaller number of students said that they were  using technical knowledge (14%), drawing on their skills in specific subjects (9%), drawing on their  emotional skills (6%), or utilizing their communication skills (5%).</p>
              <BarChartSkills />
                <br/>
              <p>
                <span class='focus'>“Is there any kind of support or materials you need right now for school that you’re not getting?”</span>
                <br/>
              Most students (90%) did not name anything that they still needed to participate in school. However, students did note that they would like:
                <ul>
                  <li>Physical textbooks, and more times for pick up/drop off</li>
                  <li>Time with peers and friends (e.g., more spirit days)</li>
                  <li>Support for technical problems with Chromebooks</li>
                  <li>Additional help for disabled students</li>
                </ul> 
              </p>
            <div class="brierTerrace-topic-content">School connectedness and support</div>
              <p>For the school connectedness and support scale, <em>scores were highest for family support</em> for learning  (mean=3.5, sd=0.5), followed by teacher-student relationships (mean=3.2, sd=0.5).  <em>Peer support for  learning was lowest</em> out of the measured kinds of support (mean=3.0, sd=0.5). No significant differences  in school connectedness and support were found based on student gender, grade, or home context. </p>
              <BoxPlotSupport />

            <div class="brierTerrace-topic-content">Self-efficacy</div>
              <p>For self-efficacy, <em>scores were highest for academic confidence</em> (mean=3.7, sd=0.8), followed by confidence in social skills (mean=3.4, sd=0.6) . Out of the three, <em>confidence in emotional skills was  lowest</em> (mean=3.1, sd=0.8).</p>
              <BoxPlotSelfEfficacy />

              <p>Relationships between support and confidence in emotional skills were examined. <em>Confidence in  emotional skills was correlated strongly with peer support (r=0.47) and with family support (r=0.36).</em>  No significant differences in self-efficacy were found based on student gender, grade, or home context. </p>
              <ScatterPlotPeer />

            <div class="brierTerrace-topic-content">Self-regulated learning</div>
              <p>Among the self-regulated learning variables, <em>students scored highest on Reflecting (mean=3.73, sd=0.6)  and Evaluating (mean=3.68, sd=0.7). Students scored lowest on Planning (mean=3.04, sd=0.8).</em> </p>
              <BoxPlotSelfRegulated />

              <p>No differences in SRL scores were found based on grade or home context. Girls’ SRL scores were slightly  higher than boys, which is expected for students at this age.</p>
              <BoxPlotGenderSplit />
              
            {/* <div class="brierTerrace-topic-content">Grouping students based on survey scores</div>
              <p>For the survey measures, it may be useful to look not just at overall scores but to <em>understand how the scores relate to one another</em>. We performed a cluster analysis to see if students could be grouped based  on their support, self-efficacy, and self-regulation skills. <em>Three groups were identified.</em> Group 2 (n=35) is  made up of students who report high levels of support, self-efficacy, and self-regulation skills across the  board. Group 1 (n=37) has less family support than group 2, and has slightly lower scores for self-efficacy  and self-regulation skills. Group 3 (n=14) is smaller, has slightly lower scores for teacher-student  relationships and peer support, and has the lowest scores of the 3 groups for all self-efficacy and self regulated learning skills. In other words, <em>roughly 15% of students report lower levels of teacher and peer support, and this difference in support appears to be related to lower confidence in academic  skills and lower self-regulated learning skills.</em></p>
              <LineChartSupport />
              <LineChartSelfEfficacy />
              <LineChartSelfRegulated /> */}
          {/* <div class="brierTerrace-topic-container">Appendix. Survey questions</div> */}

        </div>
      </div>
    );
  }
}