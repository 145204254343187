import React, { Component } from 'react';
import './autoResearch-Description.css';

export default class AutoResearchDescription extends Component {
  render() {
    return(
      <div id="autoResearch-description-container">
        <div class="autoResearch-description-content-container">
          <p>The research questions we explore with regard to automotive technology are very focused on what is creating value for students in terms of their exposure to technology and how students are considering career pathways. Often, we are able to merge these two ideas at once and investigate how access to a particular learning opportunity, such as working on electric cars or working with advanced electrical circuits, may help them prepare for their future in the industry. It is fairly unusual for high school automotive students to have exposure to more advanced electrical technologies or to get first-hand experience at a race track. Our goal in both providing the opportunities and asking the research questions is to better understand how we can enhance learning activities at the high school level and have a positive impact on student career outcomes.</p>
          <p>Equity and issues around diversity with regard to opportunities within the automotive industry are also areas we constantly think about at foundry10. Our study of Women in Auto was a direct result of interview and survey data with high school students that compelled us to better understand the educational pathways of women who have experienced success in the industry.</p>
          <p>We are most excited when our research can be directly applied to help improve learning opportunities for all students and hopefully strengthen outcomes for graduating students. We also work to provide data and analysis of student learning outcomes back to the schools, directly, so they may continue to improve and evolve their programs.</p>
        </div>
      </div>
    );
  }
}