import React, { Component } from 'react';
import './brierTerrace-Header.css';

export default class brierTerraceHeader extends Component {
  render() {
    return(
      <div id="brierTerrace-header-container">
          <div class="brierTerrace-header-content-container">
            <h1>Brier Terrace Survey Study</h1>
          </div>
      </div>
    );
  }
}