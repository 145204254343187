import React, { Component } from 'react';
import StemGoBabyGoIntro from './stemGoBabyGo-Intro';
import StemGoBabyGoQuote from './stemGoBabyGo-Quote';
import StemGoBabyGoBody from './stemGoBabyGo-Body';
import NewFooter from '../../../../pages-other/NewFooter';
import './stemGoBabyGo-Content.css';

export default class StemGoBabyGoContent extends Component {
  render() {
    return(
      <div id="stemGoBabyGo-content-container">
        <StemGoBabyGoIntro />
        <StemGoBabyGoQuote />
        <StemGoBabyGoBody />
        <NewFooter />
      </div>
    );
  }
}