import React, { Component } from 'react';
import October21Header from './october21-Header';
import October21Content from './october21-Content';
import './october21.css';

export default class October21Page extends Component {
  render() {
    return(
      <div id="october21-container">
        <October21Header />
        <October21Content />
      </div>
    );
  }
}