import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player';
import './hotRodCombustion-meadowdale.css';
import NewFooter from '../../../pages-other/NewFooter';

export default class HotRodCombustionMeadowdale extends Component {
  render() {
    return(
        <div id="programProject-main-container">
        <Helmet>
          <title>foundry10 Automotive Education Project: Meadowdale High School</title>
          <meta
            name="title"
            content="foundry10 Automotive Education Project: Meadowdale High School"
          />
          <meta
            name="description"
            content="Explore foundry10's automotive technology project-based learning for youth featuring the Meadowdale High School Automotive Program."
          />
          <meta property="og:title" content="foundry10 Automotive Education Project: Meadowdale High School" />
          <meta
            property="og:description"
            content="Explore foundry10's automotive technology project-based learning for youth featuring the Meadowdale High School Automotive Program."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/automotive-projects/hot-rod-combustion-and-electric-technology" />
          <link rel="canonical" href="https://www.foundry10.org/automotive-projects/hot-rod-combustion-and-electric-technology" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Automotive Education Project: Meadowdale High School",
                "description": "Explore foundry10's automotive technology project-based learning for youth featuring the Meadowdale High School Automotive Program.",
                "url": "https://www.foundry10.org/automotive-projects/hot-rod-combustion-and-electric-technology"
              }
            `}
          </script>
        </Helmet>
        {/* HEADER */}
        <div class="programProject-header-container-hotrod">
          <div class="programProject-header-content-container">
            <h1>Meadowdale High School Automotive Program</h1>
          </div>
        </div>
        {/* BODY */}
        <div id="programProject-main-content-container">
          {/* DESCRIPTION */}
          <div id="vrLandingPage-description-container-hotrod">
            <div class="vrLandingPage-description-content-container-hotrod">
              <p>The next project we undertook with a school was another version of an electric car, albeit a very a different one. We visited the Meadowdale High School automotive program and let them know we were interested in sponsoring a project if they were interested in adding one to their regular curriculum.</p>
              <p>A couple of weeks later, foundry10 was invited out to meet with the students and we were treated to a fantastic presentation, complete with violin music! The students had a game plan: building kit car Ford Model T hot rods, with V8 engines built from the ground up. Then, for the second phase of the project, the cars would undergo full conversion to an electric motor. One aspect of the project that was really appealing to students at Meadowdale was the opportunity to compete a bit with each other. In their pitch, students wanted to work on two cars.</p>
              <p>Through price negotiation with the manufacturers of the kits, and with the support and funding from foundry10, the students were able to obtain six fully-fledged model T kits. Working in small teams, collaboratively, gave students the chance to hone their teamwork skills and work on goal-setting and communication. The advanced projects really allow for students to apply a wide variety of skills in a project that gives them a strong sense of ownership and ability. Students at Meadowdale will be able to utilize the hot rod kits for years to come in the advanced program!</p>
            </div>
          </div>
          {/* DOCUMENT */}
          <div id="autoProject-hotrod-document-container">
            <div class="autoProject-hotrod-document-content-container">
              <a href="https://drive.google.com/open?id=1Y7cd1dJ45mi6icnqtf-DDfWdtiDKCCH8" target="_blank" rel="noopener noreferrer">    
                <div class="autoResearch-cards-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/automotive-tech/An+Interview+with+Bryan+Robbins.jpg" alt="an interview with bryan robbins" />
                  <p class="autoResearch-cards-title">An Interview with Bryan Robbins, Meadowdale High School's Auto Tech Teacher</p>
                  <div class="autoResearch-cards-overlay"></div>
                </div>  
              </a>
            </div>
          </div>
          {/* VIDEO */}
          <div id="programProject-video-container-autoProjects">
            <div class="programProject-video-content-container-autoProjects">
              <div class="videoCard-container">
                <ReactPlayer
                url='https://youtu.be/WNkHu2NbVGw'
                className='react-player'
                //   playing
                controls
                width='100%'
                height='100%'
                />
              </div>
            </div>
          </div>
          <NewFooter />
        </div>
      </div>
    );
  }
}