import React from "react";
import { useQuery, gql } from "@apollo/client";

const QUERY = gql`
  query ResourceTeam($slug: [String]) {
    entry(slug: $slug) {
      ... on resources_default_Entry {
        interestAreaTeam {
          ... on interestAreaTeam_BlockType {
            interestAreaTeamMember {
              title
              url
            }
          }
        }
        researchExternalAuthors {
          ... on researchExternalAuthors_BlockType {
            researchExternalAuthorsAuthor
          }
        }
      }
    }
  }
`;

function ResearchTeam(slug) {
  const { data, loading, error } = useQuery(QUERY, {
    variables: { slug },
  });
  if (loading) {
    return <div>loading</div>;
  }
  if (error) {
    return <div>{error}</div>;
  }
  if (data.entry?.interestAreaTeam[0]?.interestAreaTeamMember?.length) {
    return (
      <section className="researchTeam">
        <div className="container">
          <h3 className="text-xl">Author(s)/Creator(s)</h3>
          {data.entry.interestAreaTeam.map((block) => (
            <div className="jss1 MuiGrid-container-1 MuiGrid-spacing-xs-40-27 authorsGrid" style={{ border: "none" }}>
              {block.interestAreaTeamMember.map((blocks) => {
                return (
                  <div className="authorsGrid-item">
                    <p className="text-medium">
                      <a href={blocks.url}>{blocks.title}</a>
                    </p>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </section>
    );
  } else {
    return false;
  }
}

export default ResearchTeam;
