import React, { Component } from 'react';
import './resourcesLandingPage-Puget.css';

export default class ResourcesLandingPagePuget extends Component {
  render() {
    return(
      <div id="autoResearch-womenInAuto-container">
        <div class="autoResearch-womenInAuto-content-container">
          <div class="interestArea-domain-title">
            <h2>We Are Puget Sound - Discovering & Recovering the Salish Sea</h2>
          </div>
          <div class="autoResearch-womenInAuto-paragraph">
            <p>This book was shared during an event at Town Hall Seattle that the foundry10 Marine Science Team attended. At this event, a panel comprised of contributors to the book spoke from the Suquamish Tribe, the Washington Environmental Council, and the Orca Recovery Task Force.</p>
            <p>About the book: “We Are Puget Sound is a campaign and new book available in October 2019 that amplifies the voices and ideas working to protect and restore Puget Sound. Our goal is to help engage and inspire people around the region to join together to preserve its ecosystem and the livelihoods that depend on it.”</p>
          </div>
          <a href="https://www.wearepugetsound.org/" target="_blank" rel="noopener noreferrer">    
            <div class="autoResearch-cards-container">
              <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Resources/We+Are+Puget+Sound.png" alt="we are puget sound"/>
              <p class="autoResearch-cards-title">We Are Puget Sound</p>
              <div class="autoResearch-cards-overlay"></div>
            </div>  
          </a>
        </div>
      </div>
    );
  }
}