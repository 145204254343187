import React, { Component } from 'react';
import './kcpt-Body.css';

export default class KcptBody extends Component {
  render() {
    return(
      <div id="kcpt-container">
        <div class="kcpt-content-container">
          <div class="kcpt-paragraph">
            <p>For youth who aren’t interested in performing on stage, there are very few meaningful opportunities to participate in elementary school theatre productions. To help address that gap, foundry10 and Key City Public Theatre are developing portable theatre tech kits that youth as young as seven can safely use to design theatrical sound and lighting.</p>
            <p>We completed our first trial with the kits in February 2022 in partnership with a Jefferson County elementary school, where a fourth-grade youth designed 26 different lighting scenes for a 45-minute student production. In addition to quickly mastering the basics, the youth designer was able to apply some more advanced techniques to create simulated lighting motion on stage. They were able to do all of this primarily on their own thanks to an intuitive iPad interface that controlled 7 light-weight, cool-running LED fixtures.</p>
            <p>Following that initial test, foundry10 is now working with Key City Public Theatre on the next iteration of the kit, which will include some more durable equipment stands and better wireless sound control. The hope is to create an easy-to-use “theatre in a box” kit that can be provided to elementary schools, along with our curriculum, to help launch new afterschool drama programs. The goal is that it will be immediately usable for both elementary youth and adult program leaders who have no prior technical theatre experience.</p>
            <p>If you are an elementary educator interested in potentially participating in future versions of this project, please email Duncan Frost at <a href="mailto:duncan@foundry10.org">duncan@foundry10.org</a> to be added to the project update list.</p>
          </div>
        </div>
      </div>
    );
  }
}