import React, { Component } from 'react';
import './workshopsLandingPage-Intro.css';

export default class WorkshopsLandingPageIntro extends Component {
  render() {
    return(
      <div id="workshopsLandingPage-intro-container">
        <div class="workshopsLandingPage-intro-content-container">
          <div class="workshopsLandingPage-intro-bold">
            <h3>foundry10 supports the needs of students, teachers, and community members.</h3>
          </div>
          <div class="workshopsLandingPage-intro-normal">
            <p>We collaborate on one-time STEM workshops to support youth learning through innovative STEM activities. Topics for these workshops are driven by teachers and their students.</p>
          </div>
        </div>
      </div>
    );
  }
}