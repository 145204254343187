import React, { Component } from 'react';
import './marineScienceCollaboration-Header.css';

export default class MarineScienceCollaborationHeader extends Component {
  render() {
    return(
      <div id="marineScienceCollaboration-header-container">
          <div class="marineScienceCollaboration-header-content-container">
            <h1>Marine Science and Technology Collaboration Opportunities</h1>
          </div>
      </div>
    );
  }
}