import React, { Component } from 'react';
import './ie.css';
import lottie from 'lottie-web';

export default class IE extends Component {
  componentDidMount() {
    lottie.loadAnimation({
      container: document.getElementById("ie-lightbulb"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "/svg/Ie-lightbulb.json"
    });
  }
  render() {
    return (
      <div id="ie-container">
        <div class="ie-everyday-section-title">
          <h4>INTRO (THE IDEA)</h4>
        </div>
        <div class="ie-content-container">
          <div class="ie-svg-container">
            <div id="ie-lightbulb" class="ie-svg-content"/>
          </div>
          <div class="ie-text-container">
            <p>And so, in Summer 2017, "The Gallery" was born.</p>
          </div>
        </div>
      </div>
    );
  }
}