// Stories, In The News, Resources and Publications Archive
import React, { Component } from 'react';
import {
    useQuery,
    gql
  } from "@apollo/client";
import PageHeader from '../components/page-header.js';
import ArchiveFilter from '../components/archive-filter.js';
import ArchiveList from '../components/archive-list.js';
import PreFooter from '../components/pre-footer.js';
// import NewsPageWhatsNew from './NewsPage-WhatsNew';
// import NewsPageSocialMediaFeed from './NewsPage-SocialMediaFeed';
// import NewFooter from '../pages-other/NewFooter';


// Get Page content
const entryQuery = gql`
    query pageQuery($slug: [String]) {
      entries(slug: $slug) {
        title
        ... on storiesArchive_storiesArchive_Entry {
          blurb
          guidingPrinciples {
            ... on guidingPrinciples_BlockType {
              guidingPrinciplesPrinciple
            }
          }
          footerQuote
          footerQuoteAttribution
          footerQuoteBg {
            url
          }
          footerCTA {
            ... on footerCTA_BlockType {
              footerCTATitle
              footerCTAContent
              footerCTALinkUrl {
                ariaLabel
                customText
                target
                text
                title
                type
                url
              }
            }
          }
        }
        ... on pressArchive_pressArchive_Entry {
          blurb
          guidingPrinciples {
            ... on guidingPrinciples_BlockType {
              guidingPrinciplesPrinciple
            }
          }
          footerQuote
          footerQuoteAttribution
          footerQuoteBg {
            url
          }
          footerCTA {
            ... on footerCTA_BlockType {
              footerCTATitle
              footerCTAContent
              footerCTALinkUrl {
                ariaLabel
                customText
                target
                text
                title
                type
                url
              }
            }
          }
        }
        ... on programsArchive_programsArchive_Entry {
          blurb
          guidingPrinciples {
            ... on guidingPrinciples_BlockType {
              guidingPrinciplesPrinciple
            }
          }
          footerQuote
          footerQuoteAttribution
          footerQuoteBg {
            url
          }
          footerCTA {
            ... on footerCTA_BlockType {
              footerCTATitle
              footerCTAContent
              footerCTALinkUrl {
                ariaLabel
                customText
                target
                text
                title
                type
                url
              }
            }
          }
        }
        ... on projectArchives_projectArchives_Entry {
          blurb
          guidingPrinciples {
            ... on guidingPrinciples_BlockType {
              guidingPrinciplesPrinciple
            }
          }
          footerQuote
          footerQuoteAttribution
          footerQuoteBg {
            url
          }
          footerCTA {
            ... on footerCTA_BlockType {
              footerCTATitle
              footerCTAContent
              footerCTALinkUrl {
                ariaLabel
                customText
                target
                text
                title
                type
                url
              }
            }
          }
        }
        ... on opportunitiesArchive_opportunitiesArchive_Entry {
          blurb
          guidingPrinciples {
            ... on guidingPrinciples_BlockType {
              guidingPrinciplesPrinciple
            }
          }
          footerQuote
          footerQuoteAttribution
          footerQuoteBg {
            url
          }
          footerCTA {
            ... on footerCTA_BlockType {
              footerCTATitle
              footerCTAContent
              footerCTALinkUrl {
                ariaLabel
                customText
                target
                text
                title
                type
                url
              }
            }
          }
        }
      }
    }
`

const PageRender = (slug) => {
  const { data, loading, error } = useQuery(entryQuery, {
      variables: {slug}
  });
  if (loading) return null;
  if (error) return `Error! ${error}`;
  
  // console.log(data);

  return(
    data.entries.map(
      (block, index) => {    
        const preFooter = block.guidingPrinciples;
        if(typeof(preFooter) ) {
          return (
            <PreFooter data={data}/>
          );
        }
        switch (block) {
          case 'guidingPrinciples':
            return (
              <PreFooter block={block} />
            )
          case 'blurb':
              return (
                <div>
                  There's a blurb
                </div>
              )
          }
      }
    )
  );
}

export default class ContentArchive extends Component {
  render() {
    // console.log('props for content archive');
    // console.log(this.props);
    return(
      <section id="contentArchive">
        <PageHeader slug={this.props.slug} />
        <ArchiveFilter/>
        <ArchiveList channel={this.props.channel} />
        <PageRender slug={this.props.slug} />
      </section>
    );
  }
}