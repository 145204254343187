import React, { Component } from 'react';
import './Program-Research.css';

export default class ProgramResearch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      allResearch: []
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      allResearch: nextProps.all
    })
  }

  render() {
    // console.log(this.state.allResearch);
    // console.log(this.state.allResearch.length);
    if(this.state.allResearch) {
      const programResearchCards = this.state.allResearch.map((records, index) => (
        <a href={'/research/'+records.nameShort} class="research-interestArea-link">
          <li>
            <div class="researchList-card-container">
              <div class="researchList-card-content-container">
                <div class="researchList-card-image-container">
                  <img src={records.researchCardImageUrl} alt={records.name}/>
                </div>
                <div class="researchList-card-text-container">
                  <div class="researchList-card-mainText-research">
                    <h2>{records.name}</h2>
                    <p>{records.brief}</p>
                  </div>
                  <div class="researchList-card-bottomText">
                    <h4>{records.date}</h4>
                    <h4>{records.researchType}</h4>
                    {/* <div class="researchList-card-names">{records.allPeople.map((people, index) => <h4 key={index}>{people.name}</h4>)}</div> */}
                  </div>
                </div>
              </div>
            </div>
          </li>
        </a>
      ));
        return(
              <div id="research-interestArea-container">
                <div class="research-interestArea-content-container">
                  <div class="research-interestArea-title-research">
                    <h2>Program Research</h2>
                  </div>
                  <ul id="research-interestArea-cardsList2">
                    {programResearchCards}
                  </ul>
                </div>
              </div>
        );
     } else {
      return (
        <p className='VisibilityOff'>

        </p>
      )
    }
  }
}