import React, { Component } from 'react';
import './annualReport2021DramaticArts-Spotlights.css';
import ReactPlayer from 'react-player';

export default class AnnualReportDramaticArtsSpotlights extends Component {
  render() {
    return(
      <div id="annualReport2021DramaticArts-spotlights-container">
        <div className="annualReport2021DramaticArts-spotlight-first-title-bar">
          <p>Spotlight: High School Shakespeare</p>
        </div>
        <div className="annualReport2021DramaticArts-spotlights-content-container">
          <div className="annualReport2021DramaticArts-spotlights-text-container">
            <div className="annualReport2021DramaticArtsVideoCard-component-container">
              <div className="annualReport2021DramaticArtsVideoCard-container">
                <ReactPlayer
                // Shakespeare Video
                url='https://youtube.com/playlist?list=PLxtu9__AnmvmwjDNt33NivJLApUo_lZOo'
                className='annualReport2021DramaticArts-react-player'
                //   playing
                controls
                width='100%'
                height='100%'
                />
              </div>
            </div>            
            <p>
            The initial plan for this program was awesome!  We had been collaborating with Ingraham HS for months on a 9th grade residency focused on William Shakespeare when COVID-19 hit.  The pandemic altered this in-person plan tremendously, but we quickly adjusted and were able to move our twelve teaching artists, along with their instruction and mentoring of student projects online for a video series and mentorship program based on Romeo and Juliet.  Classroom teachers identified some significant curriculum touchpoints, and the actors then recorded their own performances of these key speeches and provided instructional context around their process and methods.  Designers shared their approach to design for heightened text and then shared their work in response to specific scenes and elements of the play. Students had the opportunity to model their own creative process for their final projects; in design, performance, and adaptation after the professional artists who participated in the series. Though the program looked different online, we were able to provide students the opportunity to learn from and be supported by professional artists, all while engaging with the same play.
            {/* <br /> <br /> */}
            {/* (<a href="" target="_blank" rel="noopener noreferrer">Professional Artist Video</a>) */}
            </p>
          </div>
        </div>
        <div className="annualReport2021DramaticArts-spotlight-second-title-bar">
          <p>Spotlight: Social Connection Intervention Development</p>
        </div>
        <div className="annualReport2021DramaticArts-spotlights-content-container">
          <div className="annualReport2021DramaticArts-spotlights-text-container">
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Subpages/drama2.jpg" alt="three friends leaping in white teal and orange"/>
            <p>
            As local schools moved toward a hybrid classroom model this past spring, one of our partner educators asked us to create a social connection intervention program that could help youth build deeper bonds with their school, their teachers, and each other. We responded with a “Curriculum Design Sprint” to rapidly develop curricula that could be tested and employed by teachers immediately. Co-developed by the Dramatic Arts, Games and Learning, and Interventions teams, the design sprint brought together educators across these various disciplines and equipped them with game kits, crafting supplies, and their imaginations to make distinct and fully-usable approaches to connection building in one week’s time. The results of the project took shape as a card-based adventure game, an immersive creative drama experience with a prankster dragon-goose and a modular space adventure. Next year, we will be developing and testing these programs further while researching their ability to help youth build meaningful social connections.
            </p>
          </div>
        </div>
      </div>
    );
  }
}