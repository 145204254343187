import React, { Component } from 'react';
import './lcpTest-WIA.css';

export default class LcpTestWIA extends Component {
  render() {
    return (
      <section id="lcpTest-womenInAuto-container">
        <div className="lcpTest-womenInAuto-content-container">
          <header className="interestArea-domain-title">
            <h2>Women in Auto</h2>
          </header>
          <article className="lcpTest-womenInAuto-paragraph">
            <p>
              Through our work in the automotive field, we are particularly intrigued by the educational pathways of women who have become professionals in the automotive world. Though there is still a gender imbalance within the industry, more women are seeking career opportunities in automotive-related professions. Our goal with this study is to inform, expand, and provide evidence for educational strategies that support women in their career aspirations within the automotive industry.
            </p>
          </article>
          <a href="https://drive.google.com/open?id=1vYVo887qNLcce9DRIquOfaeCc9miQndK" target="_blank" rel="noopener noreferrer">
            <figure className="lcpTest-cards-container">
              <img
                src="https://d1dyzrv677gyi1.cloudfront.net/wia.webp"
                alt="Women in Auto graphic"
                loading="lazy" 
                width="100%"
              />
              <figcaption className="lcpTest-cards-title">Women in Auto Graphic</figcaption>
              <div className="lcpTest-cards-overlay"></div>
            </figure>
          </a>
        </div>
      </section>
    );
  }
}
