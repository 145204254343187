// NEW RESOURCES FOR EDUCATORS STORIES CARDS PULLING FROM CRAFT (AUTOUPDATING)
import React, { Component } from 'react';
import {
  useQuery,
  gql
} from "@apollo/client";
import Grid from '@material-ui/core/Grid';
import StoryCard from '../../craft-graphql/components/storyCard';
import './educatorResources-CraftStories.css';


const teensStoriesQuery = gql`
    query researchQuery {
      entries(section: "stories", limit: 3, relatedToCategories: [{ id: 10290 }]) {
        title
        slug
        url
        author {
          fullName
        }
        postDate @formatDateTime (format: "M j, Y")
        ... on stories_default_Entry {
          researchExternalAuthors {
            ... on researchExternalAuthors_BlockType {
              researchExternalAuthorsAuthor
            }
          }
          interestAreaTeam {
            ... on interestAreaTeam_BlockType {
              interestAreaTeamMember {
                title
              }
            }
          }
          blurb
          featuredImage {
            url
          }  
          categories {
            title
          }
        }
      }
    }
`

const StoryHighlights = () => {
  const { data, loading, error } = useQuery(teensStoriesQuery);
  if (loading) return null;
  if (error) return `Error! ${error}`;

  return (
    data.entries.map(
      (block, index) => {
       
        //console.log(block.slug);
        //console.log(block.categoryId);
        let props = {
          block: {block}
        }
        return (
          <Grid item xs={12} sm={4}>
            <StoryCard {...props} />
          </Grid>
        )
      }
    )
  )
}


const StoryBlock = (teamData) => {

  return (
    <section className='educatorResourcesCraftStories'>
      <div className="container">
        <Grid container spacing={4}>
          <StoryHighlights />
        </Grid>
      </div>

    </section>
  )
}


export default class EducatorResourcesStoriesCards extends Component {
  render() {
    return(
        <div id="educatorResources-craftStories-container">
        <div className="educatorResources-craftStories-content-container">
          <h2 className="educatorResources-craftStories-title"><span>Recent Stories for Educators</span></h2>
        <section className='educatorResourcesCraftHighlightCards'>
            <StoryBlock />
        </section>   
        <div className="educatorResources-craftStories-bottomLink">
              <a href='/stories?cat=10290' className="educatorResources-craftStories-content-bottomLink">Explore more stories<i className="fa-solid fa-arrow-right-long"></i></a>
            </div>
        </div>
        </div>
    );
  }
}