import React, { Component } from 'react';
import './twoG.css';

export default class TWOG extends Component {
  render() {
    return (
      <div id="twoG-container">
        <div class="everyday-section-title-twoG">
          <h4>TWO (THE ARTISTS)</h4>
        </div>
        <div class="twoG-content-container">
          <h2>We had everything we needed!</h2>
          <p>We were ready to launch a gallery dedicated to young artists and we were barreling toward a February 2018 opening!</p>
          <p>But then, we got bumped! A professional installation artist who had their eye on the converted auto shop wanted to run their gallery in February and so, Everyday Unseen would have to wait...until MAY</p>        
          <p>But that was ok! We had plenty to do in the meantime!</p>
        </div>
      </div>
    );
  }
}