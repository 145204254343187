import React, { Component } from 'react';
import Slider from "react-slick";
import ResearchThemesData from './researchThemes-Data';
import './researchRedesign-ThemesSlideshow.css';

export default class ThemesSlideshow extends Component {
    render() {
        const settings = {
          dots: true,
          swipeToSlide: true,
          arrows: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: true,
          centerPadding: '450px',
          responsive: [
            {
              breakpoint: 1800,
              settings: {
                centerPadding: '350px'
              }
            },
            {
              breakpoint: 1700,
              settings: {
                centerPadding: '250px'
              }
            },
            {
              breakpoint: 1500,
              settings: {
                centerPadding: '200px'
              }
            },
            {
                breakpoint: 1400,
                settings: {
                  centerPadding: '150px'
                }
              },
            {
                breakpoint: 1150,
                settings: {
                  centerPadding: '120px',
                  centerMode: false,
                  arrows: true
                }
              },
            {
                breakpoint: 1000,
                settings: {
                  centerMode: true,
                  centerPadding: '100px',
                  arrows: false
                }
              },
            {
                breakpoint: 800,
                settings: {
                  centerPadding: '80px',
                }
              },
            {
                breakpoint: 675,
                settings: {
                  centerMode: false,
                  arrows: true
                }
              },
              {
                  breakpoint: 450,
                  settings: {
                    centerMode: true,
                    centerPadding: '0px',
                    arrows: false
                  }
                }
          ]
        };

    return (
        <div className="researchRedesign-content-container">
            <div id="researchRedesign-Slideshow-container">
                <div className="researchRedesign-Slideshow-content-container">
                    <div className="researchRedesign-Slideshow-slider">
                    <Slider {...settings}>
                        {ResearchThemesData.map((eachTheme) => (
                                    <div className="researchRedesign-Slideshow-card" key={eachTheme.theme.title}>
                                        <div className="researchRedesign-Slideshow-card-content">   
                                            <div className="researchRedesign-Slideshow-card-content-container">
                                                <div className="researchRedesign-Slideshow-card-image">
                                                    <img src={eachTheme.theme.img} width="100%" alt={eachTheme.theme.alt}/>
                                                </div>
                                                <div className="researchRedesign-Slideshow-text">
                                                    <h2>{eachTheme.theme.title}</h2>
                                                    <p>{eachTheme.theme.content}</p>
                                                    <a href={eachTheme.theme.url}>
                                                        View related publications and resources
                                                        <i class="fa-solid fa-arrow-right-long"></i>
                                                    </a>
                                                </div>
                                            </div>                 
                                        </div>
                                    </div>
                        ))}
                    </Slider>
                    </div>
                </div> 
            </div>
        </div>          
        );
      }
}