import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import HomeRedesign2023Header from './homeRedesign2023Header';
import HomeRedesign2023Content from './homeRedesign2023-Content';
import './homeRedesign2023.css';

export default class HomeRedesign2023 extends Component {
  render() {
    return (
      <div id="homeRedesign2023-container">
        <Helmet>
          <title>foundry10 - Education Research Organization</title>
          <meta
            name="title"
            content="foundry10 - Education Research Organization"
          />
          <meta
            name="description"
            content="foundry10 is an education research organization with a philanthropic focus on expanding ideas about learning and creating direct value for youth."
          />
          <meta property="og:title" content="foundry10 - Education Research Organization" />
          <meta
            property="og:description"
            content="foundry10 is an education research organization with a philanthropic focus on expanding ideas about learning and creating direct value for youth."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org" />
          <link rel="canonical" href="https://www.foundry10.org" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "foundry10 - Education Research Organization",
                "description": "foundry10 is an education research organization with a philanthropic focus on expanding ideas about learning and creating direct value for youth.",
                "url": "https://www.foundry10.org"
              }
            `}
          </script>
        </Helmet>
        <HomeRedesign2023Header />
        <HomeRedesign2023Content />
      </div>
    );
  }
}