const TestimonialsData = [
    {
        testimonial: {
            quote: 'It was awesome to see the students open up and share their songs with industry experts who did a great job hyping up the students\' progress and offering insightful feedback.',
            person: '—Levin Betron, Education Manager, The Vera Project (from the foundry10 blog "Celebrated Seattle Music Artists Teach Youth Workshop Series")'
        }
    },
    {
        testimonial: {
            quote: 'XR tools are so powerful for education because they’re open ended — you can build everything from extraordinary scenes that would be hard to get to in real life, exaggerated or simplified models that highlight important concepts, or extremely realistic interactions that let students fail gracefully, learning from their errors without harm.',
            person: '—Corinne Brenner, Director of Learning at Killer Snails (SXSW EDU 2022 Panel Discussion)'
        }
    }
]

export default TestimonialsData;
