import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player';
import './detroit.css';
import NewFooter from '../../../pages-other/NewFooter';

export default class Detroit extends Component {
  render() {
    return(
        <div id="programProject-main-container">
        <Helmet>
          <title>foundry10 Automotive Education Trip: Detroit</title>
          <meta
            name="title"
            content="foundry10 Automotive Education Trip: Detroit"
          />
          <meta
            name="description"
            content="Explore an automotive trip for youth to the Detroit Belle Isle Grand Prix. Discover how students benefit from technology exposure and consider career pathways."
          />
          <meta property="og:title" content="foundry10 Automotive Education Trip: Detroit" />
          <meta
            property="og:description"
            content="Explore an automotive trip for youth to the Detroit Belle Isle Grand Prix. Discover how students benefit from technology exposure and consider career pathways."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/automotive-trips/detroit" />
          <link rel="canonical" href="https://www.foundry10.org/automotive-trips/detroit" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Automotive Education Trip: Detroit",
                "description": "Explore an automotive trip for youth to the Detroit Belle Isle Grand Prix. Discover how students benefit from technology exposure and consider career pathways.",
                "url": "https://www.foundry10.org/automotive-trips/detroit"
              }
            `}
          </script>
        </Helmet>
        {/* HEADER */}
        <div class="programProject-header-container-detroit">
          <div class="programProject-header-content-container">
            <h1>Detroit Belle Isle Grand Prix</h1>
          </div>
        </div>
        {/* BODY */}
        <div id="programProject-main-content-container">
          {/* DESCRIPTION */}
          <div id="vrLandingPage-description-container-detroit">
            <div class="vrLandingPage-description-content-container-detroit">
              <p>For this trip, we were excited to arrange something that put students in a place at the heart of American automotive history, Detroit, Michigan. The Grand Prix was a terrific choice for students because it had such a variety of racing classes. Not only could students see open-wheel Indy car racing, but also a range of classes like Super Trucks, IMSA cars, and Super Stock.</p>
              <p>Additionally, the Motor City provided us close access to the remarkable Henry Ford Museum, where we were able to see automotive progress from an historical perspective. One of the teachers was also able to help us arrange a visit to a General Motors Racing facility, where students were treated to a special tour, the chance to interact with a range of techs and engineers and better understand the high-end engines. Our contacts at General Motors were also able to get students special access at the track, which again got them up close to the action and the many pros.</p>
            </div>
          </div>
          {/* VIDEO */}
          <div id="programProject-video-container-autoTrips">
            <div class="programProject-video-content-container-autoTrips">
              <div class="videoCard-container">
                <ReactPlayer
                url='https://youtu.be/5EoSewW7Y0A'
                className='react-player'
                //   playing
                controls
                width='100%'
                height='100%'
                />
              </div>
            </div>
          </div>
          <NewFooter />
        </div>
      </div>
    );
  }
}