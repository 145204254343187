import React from "react";
import ThemesSlideshow from "./researcherResources-TestimonialsSlideshow";
import './researcherResources-Testimonials.css'

function ResearcherResourcesTestimonials() {

return (
<div id="researcherResources-testimonials-container">
    <div className="researcherResources-testimonials-content-container">
        <h2 className="researcherResources-testimonials-title"><span>Hear from Peers</span></h2>
        <div className="researcherResources-testimonials-slideshow-container">
          <ThemesSlideshow />
        </div>
    </div>
</div>
)

}

export default ResearcherResourcesTestimonials;