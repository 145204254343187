import React, { Component } from 'react';
import './clubSupportForm-Header.css';

export default class ClubSupportFormHeader extends Component {
  render() {
    return(
      <div id="clubSupportForm-header-container">
          <div class="clubSupportForm-header-content-container">
            <h1>Dance Team/Club Support Request Form</h1>
          </div>
      </div>
    );
  }
}