import React from 'react';
import './careerConnectedLearningLifeSkills-Intro.css';

const CareerConnectedLearningLifeSkillsIntro = () => {
   return (
    <div class="careerConnectedLearningLifeSkills-intro-container">
      <div class="careerConnectedLearningLifeSkills-intro-content-container">
        {/* <div class="stemDbrLabBody-definition-bold">
          <h1>What is the STEM DBR Lab?</h1>
        </div> */}
        <div class="careerConnectedLearningLifeSkills-intro-paragraph">
          {/* <h2>To put it simply, conceptual development means 'learning'</h2> */}
          {/* <p>The STEM DBR lab works to expand human understanding of how science, technology, engineering, and math instructional activities designed for youth work in real world settings such as classrooms, after school programs, and informal environments.</p>
          <p>DBR stands for “design based research,” which is a research approach that explores how learning theories can be better integrated into designed “interventions” (instructional measures such as programs and other learning experiences) and seeks to expand insight into how these designs work with specific types of learners and contexts. The goal is to test assumptions, conjectures, and claims about these designed experiences, develop effective and context sensitive learning design principles grounded in practice, as well as build and test theories that emerge from these design principles.</p> */}
          <p>As career pathways and in-demand skills in the 21st-century workforce continue to evolve, it is necessary for youth to have access to high-quality, relevant education opportunities that prepare them for lifelong success. foundry10 helps young people find their purpose and prepares them to thrive in their careers and adult lives through a unique blend of research, educational programming, and philanthropy.</p>
        </div>
      </div>
    </div>
   )
}
export default CareerConnectedLearningLifeSkillsIntro;