import React, { Component } from 'react';
import './stemGoBabyGo-Intro.css';

export default class StemGoBabyGoIntro extends Component {
  render() {
    return(
      <div id="stemGoBabyGo-intro-container">
        <div class="stemGoBabyGo-intro-content-container">
          <div class="stemGoBabyGo-intro-bold">
            <h3>At a Glance</h3>
          </div>
          <div class="stemGoBabyGo-intro-normal">
            <p>High school students engaged in engineering design projects based on the Go Baby Go program&#8212;which supports children living with locomotor disabilities by providing modified, ride-on vehicles. As reflected by a foundry10 survey, participating students felt that they learned useful skills from the project, and that Go Baby Go offers a particularly exciting and meaningful experience for youth as an opportunity to promote social good.</p>
          </div>
        </div>
      </div>
    );
  }
}