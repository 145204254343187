import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ContactHeader from './Contact-Header';
import NewFooter from '../../pages-other/NewFooter';
import './Contact.css';

export default class Contact2 extends Component {

  render() {
    return (
      <div id="newContact-container">
        <Helmet>
          <title>Get in Touch: Contact foundry10</title>
          <meta
            name="title"
            content="Get in Touch: Contact foundry10"
          />
          <meta
            name="description"
            content="Contact foundry10 for inquiries about our educational programs, research, and philanthropic initiatives. We're here to support youth development and learning."
          />
          <meta property="og:title" content="Get in Touch: Contact foundry10" />
          <meta
            property="og:description"
            content="Contact foundry10 for inquiries about our educational programs, research, and philanthropic initiatives. We're here to support youth development and learning."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/contact" />
          <link rel="canonical" href="https://www.foundry10.org/contact" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "AboutPage",
                "name": "Get in Touch: Contact foundry10",
                "description": "Contact foundry10 for inquiries about our educational programs, research, and philanthropic initiatives. We're here to support youth development and learning.",
                "url": "https://www.foundry10.org/contact"
              }
            `}
          </script>
        </Helmet>
        <ContactHeader />
        <div id="newContact-content-container">
        <div class="newContact-description-container">
          <div class="newContact-description-content-container">
            <div>
              <div class="newContact-inquiries-container">
                <h2>General Inquiries</h2>
                <p>Contact us at <a href="mailto:info@foundry10.org">info@foundry10.org</a>.</p>
              </div>
              <div class="newContact-inquiries-container">
                <h2>Media Inquiries</h2>
                <p>Visit our <a href="https://www.foundry10.org/press-resources">Press Resources Page</a>.</p>
              </div>
              <div class="newContact-inquiries-container">
                <h2>Research Inquiries</h2>
                <p>Contact us at <a href="mailto:study@foundry10.org">study@foundry10.org</a>.</p>
              </div>
              <div class="newContact-inquiries-container">
                <h2>Programs Inquiries</h2>
                <p>Contact our Program Pillar Coordinator at <a href="mailto:info@foundry10.org">melanie@foundry10.org</a>.</p>
              </div>
              <div class="newContact-inquiries-container">
                <h2>Philanthropy Inquiries</h2>
                <p>Contact our Philanthropy Coordinator at <a href="mailto:jennamohl@foundry10.org">jennamohl@foundry10.org</a>.</p>
              </div>
            </div>
          </div>
        </div>
          <div className="newContact-footer-container">
            <NewFooter />
          </div>
        </div>
      </div>
    );
  }
}