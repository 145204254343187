import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ReturnToLearnHeader from './returnToLearn-Header';
import ReturnToLearnContent from './returnToLearn-Content';
import './returnToLearn.css';

export default class ReturnToLearn extends Component {
  render() {
    return(
      <div id="returnToLearn-container">
        <Helmet>
          <title>foundry10 Return to Learn</title>
          <meta
            name="title"
            content="foundry10 Return to Learn"
          />
          <meta
            name="description"
            content="foundry10 supports research at UW's Harborview Injury Prevention and Research Center, validating tools for students' post-concussion return to learning."
          />
          <meta property="og:title" content="foundry10 Return to Learn" />
          <meta
            property="og:description"
            content="foundry10 supports research at UW's Harborview Injury Prevention and Research Center, validating tools for students' post-concussion return to learning."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/return-to-learn" />
          <link rel="canonical" href="https://www.foundry10.org/return-to-learn" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Return to Learn",
                "description": "foundry10 supports research at UW's Harborview Injury Prevention and Research Center, validating tools for students' post-concussion return to learning.",
                "url": "https://www.foundry10.org/return-to-learn"
              }
            `}
          </script>
        </Helmet>
        <ReturnToLearnHeader />
        <ReturnToLearnContent />
      </div>
    );
  }
}