import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import './educatorResources-Video.css';

export default class EducatorResourcesVideo extends Component {
  render() {
    return(
      <div id="educatorResources-video-container">
        <div className="educatorResources-video-content-container">
          <h2 className="educatorResources-video-title"><span>Featured Video</span></h2>
          <div class="educatorResources-videoCard-container">
            <ReactPlayer
            url='https://youtu.be/elJVk_Ro4VU?si=R4ZuhBCSJTJivHGV'
            className='react-player'
            controls
            width='100%'
            height='100%'
            />
          </div>
        </div>
      </div>
    );
  }
}