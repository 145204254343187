import React, { Component } from 'react';
import './autoLandingPage-Description.css';

export default class AutoLandingPageDescription extends Component {
  render() {
    return(
      <div id="autoLandingPage-description-container">
        <div class="autoLandingPage-description-content-container">
          <p>foundry10 automotive technology programs provide support for youth with avenues to explore automotive career interests and pathways. We support numerous career and technical education programs in schools to help students connect with industry experts, engage in relevant real-world settings, and talk with professionals about their pathways into different automotive careers.</p>
        </div>
      </div>
    );
  }
}