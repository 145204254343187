import React, { Component } from 'react';
import './id.css';
import lottie from 'lottie-web';

class ID extends Component {
  componentDidMount() {
    lottie.loadAnimation({
      container: document.getElementById("id-oneBigEvent"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "/svg/Id-onebigevent.json"
    });
  }
  render() {
    return (
      <div id="id-container">
        <div class="id-content-container">
          <div id="id-oneBigEvent" class="id-svg-content"/>
        </div>
      </div>
    );
  }
}

export default ID;