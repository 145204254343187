import React, { Component } from 'react';
import './vrLearningMap-Header.css';

export default class VrLearningMapHeader extends Component {
  render() {
    return(
      <div id="vrLearningMap-header-container">
          <div class="vrLearningMap-header-content-container">
            <h1>VR Learning Map</h1>
          </div>
      </div>
    );
  }
}