import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player';
import './lagunaSeca.css';
import NewFooter from '../../../pages-other/NewFooter';

export default class LagunaSeca extends Component {
  render() {
    return(
        <div id="programProject-main-container">
        <Helmet>
          <title>foundry10 Automotive Education Trip: Laguna Seca</title>
          <meta
            name="title"
            content="foundry10 Automotive Education Trip: Laguna Seca"
          />
          <meta
            name="description"
            content="Explore an automotive trip for youth to the Laguna Seca IMSA. Discover how students benefit from technology exposure and consider career pathways."
          />
          <meta property="og:title" content="foundry10 Automotive Education Trip: Laguna Seca" />
          <meta
            property="og:description"
            content="Explore an automotive trip for youth to the Laguna Seca IMSA. Discover how students benefit from technology exposure and consider career pathways."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/automotive-trips/laguna-seca" />
          <link rel="canonical" href="https://www.foundry10.org/automotive-trips/laguna-seca" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Automotive Education Trip: Laguna Seca",
                "description": "Explore an automotive trip for youth to the Laguna Seca IMSA. Discover how students benefit from technology exposure and consider career pathways.",
                "url": "https://www.foundry10.org/automotive-trips/laguna-seca"
              }
            `}
          </script>
        </Helmet>
        {/* HEADER */}
        <div class="programProject-header-container-lagunaSeca">
          <div class="programProject-header-content-container">
            <h1>Laguna Seca IMSA</h1>
          </div>
        </div>
        {/* BODY */}
        <div id="programProject-main-content-container">
          {/* DESCRIPTION */}
          <div id="vrLandingPage-description-container-lagunaSeca">
            <div class="vrLandingPage-description-content-container-lagunaSeca">
              <p>Our first trip was in conjunction with the Seattle IMSA team, The Heart of Racing, at the challenging raceway Laguna Seca. foundry10 had a connection with the team manager, Don Kitsch, and he helped us arrange the trip. Students had the opportunity to talk with the team manager, lead engineers, drivers, and technicians. They had VIP access to the track and had garage access where they could talk with the team as they worked on the Porsche GT3. Since it was an IMSA race, students also had a chance to see an array of high-end vehicles and talk with drivers from other teams.</p>
              <p>In addition to the race, we took students to Bruce Canepa’s fantastic restoration shop in Scotts Valley, California. Students not only received a personal tour of this amazing shop, they had a chance to talk with an array of employees who worked in all aspects of restoration and rebuilds.</p>
            </div>
          </div>
          {/* VIDEO */}
          <div id="programProject-video-container-autoTrips">
            <div class="programProject-video-content-container-autoTrips">
              <div class="videoCard-container">
                <ReactPlayer
                url='https://youtu.be/1ICAYgZNO1U'
                className='react-player'
                //   playing
                controls
                width='100%'
                height='100%'
                />
              </div>
            </div>
          </div>
          <NewFooter />
        </div>
      </div>
    );
  }
}