import React, { Component } from 'react';
import AnnualReportSharingGraphics from './annualReport2021Sharing-Graphics';
import AnnualReportSharingVideo from './annualReport2021Sharing-Video';
import AnnualReportSharingBlog from './annualReport2021Sharing-Blog';
import AnnualReportSharingWeb from './annualReport2021Sharing-Web';
import './annualReport2021Sharing-Highlights.css';

export default class AnnualReportSharingContent extends Component {
  render() {
    return(
      <div id="annualReport2021sharing-highlights-container">
        <AnnualReportSharingGraphics />
        <AnnualReportSharingVideo />
        <AnnualReportSharingBlog />
        <AnnualReportSharingWeb />
      </div>
    );
  }
}