import React, { Component } from 'react';
import './shortRun-webcomic.css';

export default class ShortRunWebComic extends Component {
  render() {
    return (
      <div id="shortRun-webComic-container">
        <div class="shortRun-self-comic">
          <img src='https://s3-us-west-2.amazonaws.com/f10-image/Short+Run/Final+Self+2.png' alt='webcomic strip'/>
        </div>
        <div class="shortRun-title-content-container">
          <h4>Short Run</h4>
          <h2>Who Are We?</h2>
          <p>We work at foundry10, a philanthropic and educational research organization. We look at a diverse range of topics including visual arts. We did a partnership with Short-Run, which provided opportunities for our students.</p>
        </div>
        <div class="shortRun-shortRun-content">
          <div class="shortRun-content-image">
            <img src='https://s3-us-west-2.amazonaws.com/f10-image/Short+Run/Short+Run+Logo+copy.png' alt='webcomic strip'/>
          </div>
          <div class="shortRun-content-text">
            <h4>What is Short Run?</h4>
            <p>Short Run Comix and Arts Festival is Seattle's local comix art festival, with almost 50% of the exhibitors being from the Pacific Northwest. This was the first year that we had a designated table for teens.</p>
          </div>
        </div>
        <div class="shortRun-webComic-content-container-one">
          <img src='https://s3-us-west-2.amazonaws.com/f10-image/Short+Run/webcomic_2.png' alt='webcomic strip'/>
        </div>
        <div class="shortRun-webComic-content-container-two">
          <img src='https://s3-us-west-2.amazonaws.com/f10-image/Short+Run/webcomic_%232.png' alt='webcomic strip'/>
        </div>
        <div class="shortRun-webComic-content-container-three">
          <img src='https://s3-us-west-2.amazonaws.com/f10-image/Short+Run/webcome_%231.png' alt='webcomic strip'/>
        </div>
      </div>    
    );
  }
}