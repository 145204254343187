import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import './Video.css';

export default class Video extends Component {
  constructor(props) {
    super(props)
    this.state = {
      videoUrl: []
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      videoUrl: nextProps.all
    })
  }

  render() {
    if(this.state.videoUrl) {
      const videoCards = this.state.videoUrl.map((records, index) => (
        <div class="videoCard-container">
          <ReactPlayer
          url={records.url}
          className='react-player'
        //   playing
          controls
          width='100%'
          height='100%'
        />
        </div>
      ));
        return(
              <div id="video-component-container">
                <div class="video-component-content-container">
                  {/* <div class="research-interestArea-title-research">
                    <h2>Video</h2>
                  </div> */}
                  <ul id="video-component-cardsList2">
                    {videoCards}
                  </ul>
                </div>
              </div>
        );
     } else {
      return (
          <div class="invisibleDiv">

          </div>
        // <p className='VisibilityOff'>

        // </p>
      )
    }
  }
}