import React, { Component } from 'react';
import CareerConnectedLearningLifeSkillsBreadcrumb from './careerConnectedLearningLifeSkills-Breadcrumb';
import CareerConnectedLearningLifeSkillsIntro from './careerConnectedLearningLifeSkills-Intro';
// import CareerConnectedLearningLifeSkillsStories from './careerConnectedLearningLifeSkills-Stories';
import ResearchTopics from './ResearchTopics/ResearchTopics';
import CareerConnectedLearningLifeSkillsEducationProgramming from './careerConnectedLearningLifeSkills-EducationProgramming';
import CareerConnectedLearningLifeSkillsTeam from './careerConnectedLearningLifeSkills-Team';
import CareerConnectedLearningLifeSkillsContact from './careerConnectedLearningLifeSkills-Contact';
import NewFooter from '../../pages-other/NewFooter';
import './careerConnectedLearningLifeSkills-Content.css';

export default class CareerConnectedLearningLifeSkillsContent extends Component {
  render() {
    return(
      <div id="careerConnectedLearningLifeSkills-content-container">
        <CareerConnectedLearningLifeSkillsBreadcrumb />
        <CareerConnectedLearningLifeSkillsIntro />
        {/* <CareerConnectedLearningLifeSkillsStories /> */}
        <ResearchTopics />
        <CareerConnectedLearningLifeSkillsEducationProgramming />
        <CareerConnectedLearningLifeSkillsTeam />
        <CareerConnectedLearningLifeSkillsContact />
        <NewFooter />
      </div>
    );
  }
}