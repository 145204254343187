import React, { Component } from 'react';
import './annualReport2021Philanthropy-Highlights.css';

export default class AnnualReportPhilanthropyHighlights extends Component {
  render() {
    return(
      <div id="annualReport2021philanthropy-highlights-container">
          <div className="annualReport2021philanthropy-highlights-content-container">
            <div className="annualReport2021philanthropy-highlights-text-container">
              <h1>Scholarships</h1>
              <p>
                We envision a world where all students have the resources they need to pursue their educational goals. To help meet this goal, we have been providing a range of scholarships to students for the last three years. We have partnered with college access organizations, local high schools, community colleges, and trade schools to offer scholarships tailored to the needs of particular student communities. All of our scholarships:
              </p>
              <span className="annualReport2021philanthropy-highlights-scholarships-1">
                <p>Have a low-barrier application process</p>
              </span>
              <span className="annualReport2021philanthropy-highlights-scholarships-2">
                <p>Are available to all students regardless of citizenship status, GPA, or other factors that might disqualify them from receiving other forms of aid</p>
              </span>
              <span className="annualReport2021philanthropy-highlights-scholarships-3">
                <p>Can be used for any type of educational expense, from tuition to rent to school supplies</p>
              </span>
            </div>
            <div className="annualReport2021philanthropy-highlights-card-container">
              <div className="annualReport2021philanthropy-highlights-card-individ">
                <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Philanthropy+Landing+Page/IMG_1403.jpg" alt="foundry10 2021 Continuing Education Scholarship" />
                <div className="annualReport2021philanthropy-highlights-card-individ-text-container">
                  <h1>Continuing Education Scholarships</h1>
                  <p>
                  One of the challenges many students face is the continually rising cost of higher education. At foundry10, we, like many organizations, offer scholarships for high school students looking to continue their learning. At the same time, we have heard from many college and trade school students that it can sometimes be challenging to find funds to stay in school and continue their post-high school educational endeavors. We realized this was a gap we could help to fill.
                  <br /><br />
                  Our Philanthropy and Research Teams have been working to provide scholarship and funding assistance for students already enrolled in post-secondary education. These scholarship funds assist students specifically in trade programs, community college, undergraduate university programs and graduate school. We are able to tailor them to meet the needs of students who perhaps are not eligible for other scholarships, such as DACA students or non-citizens of the United States. We also work to highlight groups that might need extra support but perhaps get overlooked, such as college students who are also parents and are working to support their families while continuing their education and sometimes even conducting research.
                  <br /><br />
                  It has been thrilling to support a wide array of students at varying points in their educational careers. The scholarships we offer are intentionally flexible so that students can apply them toward traditional things, like tuition, but also toward items such as tools, housing expenses or even transportation costs. The goal is to help students stay in their programs by meeting their financial needs so they can finish out their degrees and successfully transition into the next phases of their academic and career pathways.
                  </p>
                </div>
              </div>
            </div>
            <div className="annualReport2021philanthropy-highlights-text-container">
              <h1>Responsive Giving</h1>
            </div>
            <div className="annualReport2021philanthropy-highlights-card-container">
              <div className="annualReport2021philanthropy-highlights-card-individ">
                <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Philanthropy+Landing+Page/responsive+giving-8.png" alt="philanthropic work with Third Place Books" />
                <div className="annualReport2021philanthropy-highlights-card-individ-text-container">
                  <h1>Teaming up with community</h1>
                  <p>
                  All of the work we do at foundry10 involves philanthropy, but one of the hallmarks of our philanthropic work is the flexible way that we work within communities. Though there are often similar needs in various youth serving communities, there are also an array of differences that we attempt to address as smoothly and efficiently as possible. Just this year alone, we provided hardware, such as headphones, to students who had to work remotely to help eliminate environmental distractions and worked with both districts and local community colleges to provide the school with devices, like Chromebooks, and wireless hotspots.
                  <br /><br />
                  Our arts based teams worked to pull together art kits and dramatic play kits, and found ways to provide instruments and musical software so students could continue their artistic exploration at home. We provided STEM kits through partnerships with The Imagine Children's Museum and the YMCA, and worked hand in hand with Third Place Books and Math ‘n’ Stuff to bring educational materials to children all around the Puget Sound region. Knowing that teachers were also experiencing hardships, we provided hardware, such as second monitors, to ease remote teaching, supported specific grants to teachers for things like professional development, special projects of interest like a virtual mural, and curriculum, software and classroom materials.
                  <br /><br />
                  In many cases, what was needed was not even educational in nature, but supplies to meet basic needs. Here, we worked with schools to provide families with concrete supplies, including Walmart, Target, and Safeway gift cards, food for youth in local homeless shelters, and support for basic supplies and food pick-ups/drop-offs.
                  <br /><br />
                  We believe that all of these philanthropic gifts support the all-around well-being of children, teachers and families, and are pleased that we were able to step-in and support the community in a variety of ways.
                  </p>
                </div>
              </div>
            </div>
            <div className="annualReport2021philanthropy-highlights-text-container">
              <h1>Direct Giving Pivot</h1>
            </div>
            <div className="annualReport2021philanthropy-highlights-card-container">
              <div className="annualReport2021philanthropy-highlights-card-individ">
                <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Philanthropy+Landing+Page/community.jpg" alt="community hand in hand" />
                <div className="annualReport2021philanthropy-highlights-card-individ-text-container">
                  <h1>Funding for essential needs</h1>
                  <p>
                  While most of our philanthropic work focuses on creating valuable learning experiences for youth, we recognized an acute need during COVID to provide emergency relief funding directly to families and individuals. We partnered with over 30 organizations to distribute funding for essential needs like rent, utilities, food, health care, and child care. We worked with a wide range of organizations, including school districts, community-based organizations, social service agencies, hospital and health care organizations, and mutual aid networks. We sought out community partners who prioritized people who had been most impacted by COVID, including BIPOC communities, migrant communities, elders, people impacted by incarceration, people experiencing housing insecurity, and survivors of domestic violence.
                  </p>
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }
}