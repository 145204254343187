import React from "react";
import ImageContent from "../../components/blocks/ImageContent";
import QuoteSlideshow from "../../components/blocks/QuoteSlideshow";

const BottomInlineContent = ({ entry }) => {
  return entry.contentOptions.map((block, index) => (
    <React.Fragment key={index}>
        {block.__typename === "contentOptions_icp_BlockType" && (
        <ImageContent block={block} />
        )}
        {block.__typename === "contentOptions_quoteSlideshow_BlockType" && (
        <QuoteSlideshow block={block} />
        )}
    </React.Fragment>
  ));
};

const BottomContentBlocks = ({ data }) => {
  return (
    <div className="contentBlocks-container">
      <BottomInlineContent entry={data} />
    </div>
  );
};

export default BottomContentBlocks;
