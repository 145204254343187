import React, { Component } from 'react';
import './resourcesLandingPage-Description.css';

export default class ResourcesLandingPageDescription extends Component {
  render() {
    return(
      <div id="resourceLandingPage-description-container">
        <div class="resourceLandingPage-description-content-container">
          <p>We think it is of utmost importance for students and educators to fuel their passion for marine science with engaging resources. This is a growing list of books, comics, and articles we have encountered that we think you may find interesting.</p>
          <p>Do you know of a book, webpage, or other resource you think students or educators may find interesting? Please share it with us at MarineScience@foundry10.org</p>
        </div>
      </div>
    );
  }
}