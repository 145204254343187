import React, { Component } from 'react';
import './annualReport2021GamesLearning-Overview.css';

export default class AnnualReportGamesLearningOverview extends Component {
  render() {
    return(
      <div id="annualReport2021GamesLearning-overview-container">
          <div className="annualReport2021GamesLearning-overview-text-container">
            <h1>Games and Learning Team</h1>
            <p>
            The Games and Learning team has spent the last 18 months focusing on programs and research that center tabletop and roleplaying games.  We feel like there is great potential in these games for social and academic development, and were excited by the opportunities we had this year to explore and work in this area more deeply. In the last year or so, the team has built partnerships in the tabletop and roleplaying communities, produced content to help families create games together while stuck at home, donated board games to youth-serving organizations and families during COVID, and supported initiatives that bring games to youth. Games can bring much needed social interaction and levity during the pandemic while also providing mental stimulation and cognitive development. We look forward to being back in person but, in the meantime, we will continue to find ways to keep kids connected through games and play!
            </p>
          </div>
      </div>
    );
  }
}