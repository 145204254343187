import React, { Component } from 'react';
import './annualReport2021DigitalAudio-Overview.css';

export default class AnnualReportDigitalAudioOverview extends Component {
  render() {
    return(
      <div id="annualReport2021DigitalAudio-overview-container">
          <div className="annualReport2021DigitalAudio-overview-text-container">
            <h1>Digital Audio Team</h1>
            <p>
            In the past year, the Digital Audio team at foundry10 has devoted itself to providing music and audio equipment, robust composition and podcasting classes, and curriculum design for youth learning from everywhere. We found out early on that digital audio tools are well-suited for a remote learning environment as long as students have access to a laptop and a stable internet connection. We ran with that knowledge as far as we could; we not only provided educational opportunities, we have even been able to expand our programming! With a full audio toolbelt, we have been helping students connect with one another and find healthy avenues for self-expression through digital audio. In addition to supporting digital music classrooms, we have also connected with traditional music classrooms and alternative learning spaces to further broaden our community and make music accessible to all.
            </p>
          </div>
      </div>
    );
  }
}