import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import AnnualReportDigitalAudioHeader from './annualReport2021DigitalAudio-Header';
import AnnualReportDigitalAudioContent from './annualReport2021DigitalAudio-Content';
import './annualReport2021DigitalAudio.css';

export default class AnnualReport2021DigitalAudio extends Component {
  render() {
    return(
      <div id="annualReport2021DigitalAudio-container">
        <Helmet>
          <title>foundry10 Annual Report 2021-2022: Digital Audio</title>
          <meta
            name="title"
            content="foundry10 Annual Report 2021-2022: Digital Audio"
          />
          <meta
            name="description"
            content="Explore the foundry10 Annual Report 2021 for insights into our Digital Audio work. Learn more about our efforts in research, programming, and philanthropy."
          />
          <meta property="og:title" content="foundry10 Annual Report 2021-2022: Digital Audio" />
          <meta
            property="og:description"
            content="Explore the foundry10 Annual Report 2021 for insights into our Digital Audio work. Learn more about our efforts in research, programming, and philanthropy."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/annual-report/2021/programs/digital-audio" />
          <link rel="canonical" href="https://www.foundry10.org/annual-report/2021/programs/digital-audio" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Annual Report 2021-2022: Digital Audio",
                "description": "Explore the foundry10 Annual Report 2021 for insights into our Digital Audio work. Learn more about our efforts in research, programming, and philanthropy.",
                "url": "https://www.foundry10.org/annual-report/2021/programs/digital-audio"
              }
            `}
          </script>
        </Helmet>
        <AnnualReportDigitalAudioHeader />
        <AnnualReportDigitalAudioContent />
      </div>
    );
  }
}