import React, { Component } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import './charts.css'

export default class ScatterPlotPeer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // To avoid unnecessary update keep all options in the state.
    chartOptions: {
        chart: {
          type: 'scatter',
          zoomType: 'xy'
        },

        title: {
          text: 'Correlation Between Peer Support and Emotional Self-Efficacy'
      },
      // subtitle: {
      //     text: ''
      // },
      xAxis: {
          title: {
              enabled: true,
              text: 'Peer Support Score'
          },
          startOnTick: true,
          endOnTick: true,
          showLastLabel: true
      },
      yAxis: {
          title: {
              text: 'Emotional Score'
          }
      },
      // legend: {
      //     layout: 'vertical',
      //     align: 'left',
      //     verticalAlign: 'top',
      //     x: 100,
      //     y: 70,
      //     floating: true,
      //     backgroundColor: Highcharts.defaultOptions.chart.backgroundColor,
      //     borderWidth: 1
      // },
      plotOptions: {
          scatter: {
              marker: {
                  radius: 5,
                  states: {
                      hover: {
                          enabled: true,
                          lineColor: 'rgb(100,100,100)'
                      }
                  }
              },
              states: {
                  hover: {
                      marker: {
                          enabled: false
                      }
                  }
              },
              tooltip: {
                  headerFormat: '<b>{series.name}</b><br>',
                  pointFormat: 'Peer Support Score: {point.x}, Emotional Score: {point.y}'
              }
          }
      },
      series: [
        {
          name: 'Data Points',
          color: 'rgba(209, 78, 29)',
          marker: {
            lineColor: '#D14E1D',
            lineWidth: 2,
            fillColor: 'rgba(231, 124, 83, 0.5)'
          },
          data: [[1.33, 1.57], [1.33, 1.71], [1.5, 5], [1.5, 2.57], [2, 1.57], 
          [2.17, 2.29], [2.33, 3.57], [2.33, 2.29], [2.33, 2.71], [2.33, 1.71], 
          [2.5, 3.86], [2.5, 2.86], [2.67, 2.14], [2.67, 3.43], [2.67, 1], 
          [2.67, 2], [2.67, 2.43], [2.67, 3.57], [2.67, 2.57], [2.83, 3], 
          [2.83, 2.71], [2.83, 1.71], [2.83, 2.43], [2.83, 2.71], [2.83, 2.86], 
          [2.83, 3.14], [2.83, 2.86], [3, 3.43], [3, 3.57], [3, 2.43], 
          [3, 2.71], [3, 3.15], [3, 3.29], [3, 2.43], [3, 3.29], 
          [3, 3.14], [3, 3.14], [3, 3.71], [3, 3.86], [3, 2.57], 
          [3, 2.86], [3, 3], [3, 2.57], [3, 2], [3, 3.57], 
          [3, 3.71], [3, 4], [3, 2.29], [3, 3.71], [3, 3.86], 
          [3, null], [3.17, 2.29], [3.17, 2.14], [3.17, 3.71], [3.17, 3], 
          [3.17, 3.14], [3.17, 2.14], [3.17, 2.86], [3.17, 3], [3.17, 3.29], 
          [3.17, 3.14], [3.17, 3.71], [3.17, 2.71], [3.17, 3.57], [3.17, 3], 
          [3.17, 3.43], [3.17, 3.57], [3.17, 3.57], [3.33, 4], [3.33, 4], 
          [3.33, 3.29], [3.33, 2.14], [3.33, 4], [3.33, 4.14], [3.33, 3.71], 
          [3.33, 4.14], [3.33, 4.43], [3.33, 2.86], [3.33, 4.43], [3.67, 3.86], 
          [3.67, 4.29], [3.83, 3.14], [3.83, 4.43], [3.83, 3], [3.83, 4.29], 
          [3.83, 3.86], [4, 2.57], [4, 4.29], [4, 4.14], [4, 3.71], 
          [4, 4.57]]
  
      },
      {
        type: 'line',
        name: 'Trend Line',
        color: 'rgba(0, 70, 79)',
        data:[[1.33, 1.96], [1.5, 2.07], [2, 2.42], [2.17, 2.54], [2.33, 2.66],
        [2.5, 2.77], [2.67, 2.89], [2.83, 3.01], [3, 3.12], [3.17, 3.24], 
        [3.33, 3.36], [3.67, 3.59], [3.83, 3.71], [4, 3.82]],
        lineWidth: 1
      }]
      }
    };
  }


  render() {
    const { chartOptions } = this.state;

    return (
      <div class="chart">
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
        />
      </div>
    )
  }
}
