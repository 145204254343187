import React, { Component } from 'react';
import './researcherResources-EmphasizedContent.css';

export default class ResearcherResourcesEmphasizedContent extends Component {
  render() {
    return(
        <div id="researcherResources-emphasizedContent-container">
          <div className="researcherResources-emphasizedContent-content-container">
            <div className="researcherResources-emphasizedContent-info-container">
              <div className="researcherResources-emphasizedContent-titles">
                <h2>Overline Text</h2>
                <h1>This is the secion headline</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              </div>
              <div className="researcherResources-emphasizedConten-image">    
                <img src="https://f10-resources.s3.us-west-2.amazonaws.com/Resources+for+Researchers/Research+Partnership+with+Game+to+Grow.png" alt="young kid on skateboard jumps off ramp over two other young kids"/>
              </div>
              <div className="researcherResources-emphasizedContent-text">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
              </div>
            </div>
          </div>
        </div>
    );
  }
}