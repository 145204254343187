import React, { Component } from 'react';
import CreativityDesignPlayIntro from './creativityDesignPlay-Intro';
import CreativityDesignPlayBreadcrumb from './creativityDesignPlay-Breadcrumb';
// import CreativityDesignPlayStories from './creativityDesignPlay-Stories';
import ResearchTopics from './ResearchTopics/ResearchTopics';
import CreativityDesignPlayEducationProgramming from './creativityDesignPlay-EducationProgramming';
import CreativityDesignPlayTeam from './creativityDesignPlay-Team';
import CreativityDesignPlayContact from './creativityDesignPlay-Contact';
import NewFooter from '../../pages-other/NewFooter';
import './creativityDesignPlay-Content.css';

export default class CreativityDesignPlayContent extends Component {
  render() {
    return(
      <div id="creativityDesignPlay-content-container">
        <CreativityDesignPlayBreadcrumb />
        <CreativityDesignPlayIntro />
        {/* <CreativityDesignPlayStories /> */}
        <ResearchTopics />
        <CreativityDesignPlayEducationProgramming />
        <CreativityDesignPlayTeam />
        <CreativityDesignPlayContact />
        <NewFooter />
      </div>
    );
  }
}