import React, { Component } from 'react';
import './vrStressTest-Intro.css';

export default class VrStressTestIntro extends Component {
  render() {
    return(
        <div id="vr-stress-test-intro-container">
          <div class="vr-stress-test-intro-content-container">
            <div class="vr-stress-test-intro-normal">
              <p>How do you know if a VR software or game is a beneficial addition to your classroom? What questions should you ask when considering one?</p>
              <p>The foundry10 VR Software Stress Test offers a method for answering those questions. 
                To download a printable version of the prompt questions, <a href="https://drive.google.com/file/d/1p6qjAvMpB7ITFsvs6YCYZ4yuOS8GwDpp/view?usp=sharing" target="_blank" rel="noopener noreferrer">click here</a>.
              </p>
            </div>
        </div>
      </div>
    );
  }
}