import React, { Component } from 'react';
import AnnualReportHeader from './AnnualReport-Header';
import AnnualReportContent from './AnnualReport-Content';
import './annualReport2021.css';
import { Helmet } from 'react-helmet';

export default class AnnualReport2021 extends Component {
  render() {
    return(
      <div id="annualReport2021-container">
        <Helmet>
          <title>foundry10 Annual Report 2021-2022: Responding to the Moment</title>
          <meta
            name="title"
            content="foundry10 Annual Report 2021-2022: Responding to the Moment"
          />
          <meta
            name="description"
            content="Explore the foundry10 Annual Report 2021 for insights into our work. Learn more about our efforts in research, programming, philanthropy, and sharing."
          />
          <meta property="og:title" content="foundry10 Annual Report 2021-2022: Responding to the Moment" />
          <meta
            property="og:description"
            content="Explore the foundry10 Annual Report 2021 for insights into our work. Learn more about our efforts in research, programming, philanthropy, and sharing."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/annual-report/2021" />
          <meta property="og:image" content="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Landing+Page/AR2020_2021Responding_To_The_Moment.png"/>
          <meta property="og:image:secure_url" content="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Landing+Page/AR2020_2021Responding_To_The_Moment.png" /> 
          <meta property="og:image:type" content="image/jpeg" /> 
          <meta property="og:image:width" content="400" /> 
          <meta property="og:image:height" content="300" />
          <link rel="canonical" href="https://www.foundry10.org/annual-report/2021" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Annual Report 2021-2022: Responding to the Moment",
                "description": "Explore the foundry10 Annual Report 2021 for insights into our work. Learn more about our efforts in research, programming, philanthropy, and sharing.",
                "url": "https://www.foundry10.org/annual-report/2021"
              }
            `}
          </script>
        </Helmet>
        <AnnualReportHeader />
        <AnnualReportContent />
      </div>
    );
  }
}