import React, { Component } from 'react';
import HealthWellBeingBreadcrumb from './healthWellBeing-Breadcrumb';
import HealthWellBeingIntro from './healthWellBeing-Intro';
// import HealthWellBeingStories from './healthWellBeing-Stories';
import ResearchTopics from './ResearchTopics/ResearchTopics';
import HealthWellBeingEducationProgramming from './healthWellBeing-EducationProgramming';
import HealthWellBeingTeam from './healthWellBeing-Team';
import HealthWellBeingContact from './healthWellBeing-Contact';
import NewFooter from '../../pages-other/NewFooter';
import './healthWellBeing-Content.css';

export default class HealthWellBeingContent extends Component {
  render() {
    return(
      <div id="healthWellBeing-content-container">
        <HealthWellBeingBreadcrumb />
        <HealthWellBeingIntro />
        {/* <HealthWellBeingStories /> */}
        <ResearchTopics />
        <HealthWellBeingEducationProgramming />
        <HealthWellBeingTeam />
        <HealthWellBeingContact />
        <NewFooter />
      </div>
    );
  }
}