import React, { Component } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import './charts.css'

HighchartsMore(Highcharts);

export default class BoxPlotSupport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // To avoid unnecessary update keep all options in the state.
    chartOptions: {
        chart: {
          type: 'boxplot',
          // backgroundColor: '#007A8A',
      },

      title: {
        text: 'School Connectedness and Support Scores',
        style: {
          color: '#00464F'
        }
      },

      legend: {
          enabled: false
      },

      xAxis: {
        categories: ['Family Support Score', 'Peer Support Score', 'Teacher Student Score'],
        title: {
          style: {
            color: '#00464F'
          },
          text: ''
          },
      },

      yAxis: {
          title: {
            style: {
              color: '#00464F'
            },  
            text: 'Score'
          },
          // plotLines: [{
          //     value: 932,
          //     color: 'red',
          //     width: 1,
          //     label: {
          //         text: 'Theoretical mean: 932',
          //         align: 'center',
          //         style: {
          //             color: 'gray'
          //         }
              // }
          // }]
      },
      //Data: An array of arrays with 6 or 5 values. In this case, the values correspond to x,low,q1,median,q3,high. If the first value is a string, it is applied as the name of the point, and the x value is inferred. The x value can also be omitted, in which case the inner arrays should be of length 5. Then the x value is automatically calculated, either starting at 0 and incremented by 1, or from pointStart and pointInterval given in the series options.
      plotOptions: {
        series: {
            colorByPoint: true
        }
    },
      colors: ['#D14E1D','#00464F','#FFA75E'],
      series: [{
          name: 'Observations',
          data: [
              ['Family Support Score',2,3,3.5,4,4],
              ['Peer Support Score',1.33,2.833,3,3.25,4],
              ['Teacher Student Score',1.33,2.944,3,3.556,4]
          ],
          style: {
            color: '#E77C53'
          },
          tooltip: {
              headerFormat: '<em>{point.key}</em><br/>'
          },
      }, {
          name: 'Data Points',
          color: Highcharts.getOptions().colors[0],
          type: 'scatter',
          jitter: {
            x: .24
          },
          data: [ // x, y positions where 0 is the first category
              [0, 2],[0, 2.5],[0, 2.75],[0, 2.75],[0, 2.75],[0, 2.75],[0, 2.75],[0, 2.75],[0, 3],[0, 3],[0, 3],[0, 3],[0, 3],[0, 3],[0, 3],[0, 3],[0, 3],[0, 3],[0, 3],[0, 3],[0, 3],[0, 3],[0, 3],[0, 3],[0, 3],[0, 3],[0, 3],[0, 3],[0, 3],[0, 3],[0, 3],[0, 3.25],[0, 3.25],[0, 3.25],[0, 3.25],[0, 3.25],[0, 3.25],[0, 3.25],[0, 3.25],[0, 3.25],[0, 3.5],[0, 3.5],[0, 3.5],[0, 3.5],[0, 3.5],[0, 3.5],[0, 3.75],[0, 3.75],[0, 3.75],[0, 3.75],[0, 3.75],[0, 3.75],[0, 3.75],[0, 3.75],[0, 3.75],[0, 3.75],[0, 3.75],[0, 3.75],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],

              [1, 1.33],[1, 1.33],[1, 1.5],[1, 1.5],[1, 2],[1, 2.166],[1, 2.33],[1, 2.33],[1, 2.33],[1, 2.33],[1, 2.5],[1, 2.5],[1, 2.66],[1, 2.66],[1, 2.66],[1, 2.66],[1, 2.66],[1, 2.66],[1, 2.66],[1, 2.833],[1, 2.833],[1, 2.833],[1, 2.833],[1, 2.833],[1, 2.833],[1, 2.833],[1, 2.833],[1, 3],[1, 3],[1, 3],[1, 3],[1, 3],[1, 3],[1, 3],[1, 3],[1, 3],[1, 3],[1, 3],[1, 3],[1, 3],[1, 3],[1, 3],[1, 3],[1, 3],[1, 3],[1, 3],[1, 3],[1, 3],[1, 3],[1, 3],[1, 3],[1, 3.166],[1, 3.166],[1, 3.166],[1, 3.166],[1, 3.166],[1, 3.166],[1, 3.166],[1, 3.166],[1, 3.166],[1, 3.166],[1, 3.166],[1, 3.166],[1, 3.166],[1, 3.166],[1, 3.166],[1, 3.166],[1, 3.166],[1, 3.33],[1, 3.33],[1, 3.33],[1, 3.33],[1, 3.33],[1, 3.33],[1, 3.33],[1, 3.33],[1, 3.33],[1, 3.33],[1, 3.33],[1, 3.66],[1, 3.66],[1, 3.833],[1, 3.833],[1, 3.833],[1, 3.833],[1, 3.833],[1, 4],[1, 4],[1, 4],[1, 4],[1, 4],

              [2, 1.33],[2, 1.88],[2, 2],[2, 2.11],[2, 2.22],[2, 2.66],[2, 2.66],[2, 2.66],[2, 2.66],[2, 2.66],[2, 2.66],[2, 2.77],[2, 2.77],[2, 2.77],[2, 2.77],[2, 2.77],[2, 2.88],[2, 2.88],[2, 2.88],[2, 2.88],[2, 2.88],[2, 2.88],[2, 2.88],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3.11],[2, 3.11],[2, 3.11],[2, 3.11],[2, 3.11],[2, 3.11],[2, 3.22],[2, 3.22],[2, 3.22],[2, 3.22],[2, 3.22],[2, 3.22],[2, 3.22],[2, 3.33],[2, 3.33],[2, 3.44],[2, 3.44],[2, 3.44],[2, 3.44],[2, 3.44],[2, 3.44],[2, 3.55],[2, 3.55],[2, 3.55],[2, 3.55],[2, 3.55],[2, 3.66],[2, 3.66],[2, 3.66],[2, 3.66],[2, 3.77],[2, 3.77],[2, 3.77],[2, 3.77],[2, 3.77],[2, 3.88],[2, 3.88],[2, 3.88],[2, 3.88],[2, 3.88],[2, 4],[2, 4],[2, 4],[2, 4],[2, 4]
          ],
          marker: {
              fillColor: 'white',
              lineWidth: 1,
              lineColor: Highcharts.getOptions().colors[0]
          },
          tooltip: {
              pointFormat: 'Observation: {point.y}'
          }
      }]
      }
    };
  }


  render() {
    const { chartOptions } = this.state;

    return (
      <div class="chart">
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
        />
      </div>
    )
  }
}
