import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import './stemGoBabyGo-Video.css';

export default class StemGoBabyGoVideo extends Component {
  render() {
    return(
      <div id="stemGoBabyGoVideo-video-container">
        <div class="stemGoBabyGoVideo-video-content-container">
          <div class="stemGoBabyGoVideo-videoCard-container">
            <ReactPlayer
            url='https://youtu.be/IyqnzunKQuw'
            className='react-player'
            controls
            width='100%'
            height='100%'
            />
          </div>
          {/* <div class="stemGoBabyGoVideo-video-title"></div> */}
        </div>
      </div>
    );
  }
}