import React, { Component } from 'react';
import PhilanthropyDirectorDescription from './PhilanthropyDirector-Description';
import PhilanthropyDirectorQualifications from './PhilanthropyDirector-Qualifications';
import PhilanthropyDirectorResponsibilities from './PhilanthropyDirector-Responsibilities';
import PhilanthropyDirectorExtra from './PhilanthropyDirector-Extra';
import PhilanthropyDirectorApply from './PhilanthropyDirector-Apply';
import NewFooter from '../../../../pages-other/NewFooter';
import './PhilanthropyDirector-Content.css';

export default class PhilanthropyDirectorContent extends Component {
  render() {
    return(
      <div id="philanthropyDirector-content-container">
        <PhilanthropyDirectorDescription />
        <PhilanthropyDirectorQualifications />
        <PhilanthropyDirectorResponsibilities />
        <PhilanthropyDirectorExtra />
        <PhilanthropyDirectorApply />
        <NewFooter />
      </div>
    );
  }
}