// import React, { Component, Fragment } from 'react';
// import {
//   useQuery,
//   gql
// } from "@apollo/client";
// import {
//   Switch,
//   BrowserRouter,
//   // withRouter,
//   Route
// } from 'react-router-dom';
// import { Redirect } from 'react-router';
// import Error404Page from '../../pages-other/NewSite404/newSite404';

import React, { useEffect, useState } from 'react';
import { useQuery, gql } from "@apollo/client";
import { BrowserRouter, Route } from 'react-router-dom';
import Error404Page from '../../pages-other/NewSite404/newSite404';

//Get page header based on ID
const pageQuery = gql`
  query pageHeaderQuery($slug: [String]) {
    entries(slug: $slug) {
      title
      ... on team_team_Entry {
        customTitle
        blurb
        featuredImage {
          url
        }
      }
      ... on pages_default_Entry {
        blurb
        featuredImage {
          url
        }
      }
      ... on newsArchive_newsArchive_Entry {
        customTitle
        blurb
        featuredImage {
          url
        }
      }
      ... on storiesArchive_storiesArchive_Entry {
        customTitle
        blurb
        featuredImage {
          url
        }
      }
      ... on pressArchive_pressArchive_Entry {
        customTitle
        blurb
        featuredImage {
          url
        }
      }
      ... on resourcesArchive_resourcesArchive_Entry {
        customTitle
        blurb
        featuredImage {
          url
        }
      }
      ... on researchArchive_researchArchive_Entry {
        customTitle
        blurb
        featuredImage {
          url
        }
      }
      ... on programsArchive_programsArchive_Entry {
        customTitle
        blurb
        featuredImage {
          url
        }
      }
      ... on projectArchives_projectArchives_Entry {
        customTitle
        blurb
        featuredImage {
          url
        }
      }
      ... on opportunitiesArchive_opportunitiesArchive_Entry {
        customTitle
        blurb
        featuredImage {
          url
        }
      }
      ... on newslettersArchive_newslettersArchive_Entry {
        customTitle
        blurb
        featuredImage {
          url
        }
      }
      ... on equityCommitment_equityCommitment_Entry {
        customTitle
        blurb
        featuredImage {
          url
        }
      }
      ... on research_research_Entry {
        customTitle
        blurb
        featuredImage {
          url
        }
      }
      ... on resources_default_Entry {
        customTitle
        blurb
        featuredImage {
          url
        }
      }
    }
  }
`;
// function Hero(slug) {
//   const { data, loading, error } = useQuery(pageQuery, {
//     variables: {slug}
//   });
//   if (loading) return <p>Loading</p>;
//   if (error) return `Error! ${error}`;

//   if (data.entries.length < 1 ) {
//     return (
//       <Fragment>
//         <BrowserRouter>
//           <Route component={Error404Page}/>
//         </BrowserRouter>
//       </Fragment>
//     );
//   }
//   // params = [{ parent: data.entry.sectionHandle }, { pageTitle: data.entry.sectionHandle }, { style: 'dark' }];
//   console.log(data.entries);

//   let bgImage = null;

//   const pageHeader = data.entries[0];
//   //console.log(pageHeader.featuredImage[0].url);
//   const blurb = pageHeader.blurb;
//   //console.log(blurb);
//   //const bgImage = pageHeader.featuredImage[0];
//   //const bgImage = pageHeader.featuredImage[0];
//   if (pageHeader.featuredImage.length > 0) {
//     //console.log('image here')
//     bgImage = pageHeader.featuredImage[0];
//   }


//   let myStyle = {};

//   let title;
//   if (pageHeader.customTitle) {
//     title = pageHeader.customTitle
//   } else {
//     title = pageHeader.title
//   }
//   const bg = bgImage ? 'bgImage' : 'orange';
//   const withBlurb = blurb ? 'withBlurb' : '';
//   const classes = `${bg} ${withBlurb}`

//   // console.log(withBlurb);
//   // console.log(bgImage);
//   // console.log(bgImage.url);
//   // Set Image URL if availible
   
//   if (bgImage !== null ) {
//     //console.log('getting here');
//     myStyle = {
//       backgroundImage: `url(${bgImage.url})`,
//       backgroundSize: 'cover',
//       backgroundRepeat: 'no-repeat',
//     }
//   } else {
//     myStyle = {
//       backgroundSize: 'cover',
//       backgroundRepeat: 'no-repeat',
//     }
//   }
  
//   //console.log( myStyle );
  
//   //let bgImage = pageHeader.featuredImage[0].url;
//   //console.log(bgImage);
//   return (
//     <div id="heroContent" className={classes}>
//       <div className="mainHeroContent" style={myStyle}>
//         <h2>{title}</h2>
//       </div>
      
//       <Blurb slug={slug} />
//     </div>
//   );

// }

// //This query has to address ALL Channels
// function Blurb(slug) {
//   const { data, loading, error } = useQuery(pageQuery, {
//     variables: slug.slug
//   });
//   if (loading) return null;
//   if (error) return `Error! ${error}`;
 
//   const blurb = data.entries[0].blurb;

//   if (blurb) {
//     return (
//       <div className="blurb">
//         <div className='container'>
//           <div
//             dangerouslySetInnerHTML={{
//               __html:blurb,
//             }}
//           ></div>
//         </div>
//       </div>

//     );
//   } else {
//     return ( "" );
//   }
  
// }

// export default class PageHeader extends Component {
//   render() {
//     return(
//       <section id="hero">
//         <Hero slug={this.props.slug}/>
//           <div className="container">
//             {this.props.blurb && (
//               <Blurb slug={this.props.slug}/>
//             )}
//           </div>
//         </section>
//     );
//   }
// }

// WORKING

function Hero({ slug }) {
  const { data, loading, error } = useQuery(pageQuery, {
    variables: { slug },
  });

  const [bgImage, setBgImage] = useState(null);
  const [title, setTitle] = useState('');
  const [blurb, setBlurb] = useState('');

  useEffect(() => {
    if (!loading && !error && data?.entries?.length > 0) {
      const pageHeader = data.entries[0];
      setBlurb(pageHeader.blurb || '');

      if (pageHeader.featuredImage.length > 0) {
        setBgImage(pageHeader.featuredImage[0]);
      }

      setTitle(pageHeader.customTitle || pageHeader.title || '');
    }
  }, [data, loading, error]);

  const myStyle = bgImage
    ? {
        backgroundImage: `url(${bgImage.url})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }
    : {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      };

  const bg = bgImage ? 'bgImage' : 'orange';
  const withBlurb = blurb ? 'withBlurb' : '';
  const classes = `${bg} ${withBlurb}`;

  return (
    <div id="heroContent" className={classes}>
      <div className="mainHeroContent" style={myStyle}>
        <h2>{title}</h2>
      </div>
      {blurb && (
        <div className="blurb">
          <div className="container">
            <div dangerouslySetInnerHTML={{ __html: blurb }}></div>
          </div>
        </div>
      )}
    </div>
  );

  // return (
  //   <div id="heroContent" className={classes}>
  //     <div className="mainHeroContent" style={myStyle}>
  //       <h2>{title}</h2>
  //     </div>
  //     {blurb && <Blurb blurb={blurb} />}
  //   </div>
  // );
}

// function Blurb({ blurb }) {
//   return (
//     <div className="blurb">
//       <div className="container">
//         <div dangerouslySetInnerHTML={{ __html: blurb }}></div>
//       </div>
//     </div>
//   );
// }

function PageHeader({ slug }) {
  const { data, loading, error } = useQuery(pageQuery, {
    variables: { slug },
  });

  if (loading) return null;
  // if (error) {
  //   console.error("Error fetching data:", error);
  //   return (
  //     <BrowserRouter>
  //       <Route component={Error404Page} />
  //     </BrowserRouter>
  //   );
  // }

  return (
    <section id="hero">
      <Hero slug={slug} />
      {/* <div className="container">
        {data.entries[0]?.blurb && <Blurb blurb={data.entries[0].blurb} />}
      </div> */}
    </section>
  );
}

export default PageHeader;