import React, { Component } from 'react';
import './ourMission.css';

class OurMission extends Component {
  render() {
    return(
      <div id="everydayUnseen-mission-container">
        <div class="everydayUnseen-mission-content-container">
          <h4>GALLERY</h4>
          <h2>Event Mission</h2>
          <p>Everyday Unseen is meant to be a celebration of the immense talent of young artists; an idea that can often go overlooked among the many stressors and strains put on youth today. Finding the time and a place to be creative isn't always easy, but in providing this space for youth art we hope to help develop an understanding of the role art plays in life. This gallery is for these up and coming artists to see that there are others with the same interests and passions, that their art can create value for society, and that what they have to express through their work is important and should be seen, heard, and most importantly, understood.</p>
          <p>The design of the gallery was centered on treating student artists as professionals. As with so many projects we do at foundry10, we wanted to see how this changed the way youth envision themselves and their role in the arts. Our hope is that this will inspire the artists to re-think the value they put on their own work, and fuel them forward as they explore their talents. To support this, this experience also features numerous opportunities, before, during, and after the opening night, for applicants to gather and network with artists from  many different backgrounds and experiences.</p>
          <p>Finally, we want to thank all of the youth who spent their time and creative energy to bring their art to Everyday Unseen. We hope this will act as a model of inclusivity for future events and gathering spaces that are geared towards supporting artistic youth.</p>
        </div>
      </div>
    );
  }
}

export default OurMission;