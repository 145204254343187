const bandLabAccordionData = [
    {
    lesson: {
        number: 'Lesson 01',
        title: 'Overview of Mix Editor',
        week: '1',
        points: '20',
        dueDate: 'End of week 9',
        overview: {
        title:'',
        content:''},
        objective: {
        title:'Objective',
        content:'In this lesson, you will get comfortable with BandLab’s Mix Editor interface, and create a song using only premade loops. BandLab is an online DAW (digital audio workstation). A DAW is a software program used to create and edit audio or music. There are many DAWs out there, but they all have the same basic functions and controls. Once you learn about one DAW, you’ll have an easier time learning about another.'},
        outcome: {
        title:'Outcomes',
        bullets: [
            'Students will have created 4-8 bars of music with at least 4 tracks (ex. Drums, Melody, Bass, Chords) using premade loops.',
            'Students will gain comfort and confidence in navigating BandLab’s Mix Editor interface to Stop/Start audio, drag and drop loops, change the key, tempo, or time signature of their music, and operate the metronome.'  
            ]
        },
        sAndS: {
        title: 'Saving and Submitting Your Work',
        content: 'The Save and Submit buttons are at the top right corner of the Mix Editor. Be sure to save your work frequently! Every time you work out a new element to your song that you want to keep, click the Save button. When you are ready to turn your work in, use the Submit button.',
        },
        setup: {
        title: 'Set Up Your Session',
        steps: [
            'If you haven’t already, go to bandlab.com and create a free account.',
            'Follow the question prompts (make the best choices that work for you).',
            'Once completed, you will land on the live feed page. This page is where you will see the latest news, updates from followers, your latest projects, and more.',
            'Click on the red Create button at the top right to start a new project.'
        ]
        },
        instructions: {
        title: 'Instructions',
        steps: [
            ['Browse Loops: When you first open your session, you will see the following options. Choose to browse loops.','https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Digital+Audio/BandLab+Curriculum/Lesson+01/01_01.png'],
            ['This will open up the Loops Menu. You can navigate to the Loops Menu by clicking the Loops button in the bottom right of your screen. You can also hide this menu by clicking on the Loops button again.','https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Digital+Audio/BandLab+Curriculum/Lesson+01/01_02.png'],
            ['Choose a loop, and then drag and drop it into the dotted area towards the top of the screen that reads “Drop a loop or an audio/MIDI file.” This will create a new audio track and place your loop on that track for you. You can also create audio tracks by clicking on the +Add Track button in the top left of your screen, and then add loops to the new track.','https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Digital+Audio/BandLab+Curriculum/Lesson+01/01_03.png'],
            ['Continue browsing and adding loops to create a song of your own. You can use loops all from one loop pack, or mix and match - there’s no wrong answer here! Take a look at the example below, which even has an Intro, Verse, Chorus, and Outro song form. You can also add FX loops as transition elements in your song.','https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Digital+Audio/BandLab+Curriculum/Lesson+01/01_04.png'],
            ['As you create, here are some tools you can use to navigate the DAW.',null],
            ['The Undo and Redo buttons allow you to undo or redo your most recent actions.','https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Digital+Audio/BandLab+Curriculum/Lesson+01/01_06.png'],
            ['The Cycle button will loop a section of your song when it’s active.','https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Digital+Audio/BandLab+Curriculum/Lesson+01/01_07.png'],
            ['Next, let’s look at the transport and the playhead. The playhead is the vertical line with a triangle on top that moves across the screen as your song plays. It gives you a visual indication of where you are in the song. The transport is the collection of the back, play, forward, and record buttons, and the time indicator. The time indicator can be set to minutes and seconds, or beats and measures by clicking on it and selecting your preference from the drop down menu.','https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Digital+Audio/BandLab+Curriculum/Lesson+01/01_08.png'],
            ['You can also change the key, tempo, and time signature of your song at any time. These controls are located in the top center of the Mix Editor.','https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Digital+Audio/BandLab+Curriculum/Lesson+01/01_09.png'],
            ['The metronome clicks once for every beat to help you keep your loops on rhythm and sound like they go together. You adjust your metronome settings using the drop down menu next to the metronome button. Since we’re working with only pre-made loops in this lesson the metronome isn’t necessary, but it will be very helpful when we create our own original loops in the next lesson.','https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Digital+Audio/BandLab+Curriculum/Lesson+01/01_10.png'],
        ]
        },
        endNotes: {
        title: 'Final Thoughts',
        content: 'Pre-made loops are a great way to get started using audio tools and gather musical inspiration. Feel free to explore and experiment further!',
        links: [
            ['Just Loops - Frank Walton','https://www.bandlab.com/revisions/f519c605-82f4-eb11-b563-501ac5b31db1?sharedKey=6bRDZuN3w0GdIW7Twuam5w'],
            ['Glitch Loop - Chelsi Gorzelsky','https://www.bandlab.com/chelsigorzelsky/2021-02-26-beat-with-pre-made-loops-0a474371?revId=cec454a4-7288-eb11-85aa-0050f28a50ba']
        ]
        }}
    },
    {
    lesson: {
        number: 'Lesson 02',
        title: 'Create Music with MIDI',
        week: '2-3',
        points: '20',
        dueDate: 'End of week 9',
        overview: {
        title:'',
        content:''},
        objective: {title:'',
                    content:''},
        outcome: {
        title:'',
        bullets: []
        },
        sAndS: {
        title: '',
        content: '',
        },
        setup: {
        title: '',
        steps: []
        },
        instructions: {
        title: '',
        steps: [
            // comma-separated arrays of text/img
        ]
        },
        endNotes: {
        title: '',
        content: '',
        links: [
            //comma-separated arrays of text/hyperlink
        ]
        }}
    },
    {
    lesson: {
        number: 'Lesson 03',
        title: 'Effects and Automation Basics',
        week: '4',
        points: '20',
        dueDate: 'End of week 9',
        overview: {
            title:'',
            content:''},
            objective: {title:'',
                        content:''},
            outcome: {
            title:'',
            bullets: []
            },
            sAndS: {
            title: '',
            content: '',
            },
            setup: {
            title: '',
            steps: []
            },
            instructions: {
            title: '',
            steps: [
                // comma-separated arrays of text/img
            ]
            },
            endNotes: {
            title: '',
            content: '',
            links: [
                //comma-separated arrays of text/hyperlink
            ]
            }}
    },
    {
    lesson: {
        number: 'Lesson 04A',
        title: 'How to Record Audio',
        week: '5-6',
        points: '20',
        dueDate: 'End of week 9',
        overview: {
            title:'',
            content:''},
            objective: {title:'',
                        content:''},
            outcome: {
            title:'',
            bullets: []
            },
            sAndS: {
            title: '',
            content: '',
            },
            setup: {
            title: '',
            steps: []
            },
            instructions: {
            title: '',
            steps: [
                // comma-separated arrays of text/img
            ]
            },
            endNotes: {
            title: '',
            content: '',
            links: [
                //comma-separated arrays of text/hyperlink
            ]
            }}
    },
    {
    lesson: {
        number: 'Lesson 04B',
        title: 'Announce Yourself: Demo Reel',
        week: '5-6',
        points: '20',
        dueDate: 'End of week 9',
        overview: {
            title:'',
            content:''},
            objective: {title:'',
                        content:''},
            outcome: {
            title:'',
            bullets: []
            },
            sAndS: {
            title: '',
            content: '',
            },
            setup: {
            title: '',
            steps: []
            },
            instructions: {
            title: '',
            steps: [
                // comma-separated arrays of text/img
            ]
            },
            endNotes: {
            title: '',
            content: '',
            links: [
                //comma-separated arrays of text/hyperlink
            ]
            }}
    },
    {
    lesson: {
        number: 'Lesson 05',
        title: 'All About Me Podcast',
        week: '7',
        points: '20',
        dueDate: 'End of week 9',
        overview: {
            title:'',
            content:''},
            objective: {title:'',
                        content:''},
            outcome: {
            title:'',
            bullets: []
            },
            sAndS: {
            title: '',
            content: '',
            },
            setup: {
            title: '',
            steps: []
            },
            instructions: {
            title: '',
            steps: [
                // comma-separated arrays of text/img
            ]
            },
            endNotes: {
            title: '',
            content: '',
            links: [
                //comma-separated arrays of text/hyperlink
            ]
            }}
    },
    {
    lesson: {
        number: 'Lesson 06A',
        title: 'Find the Hook',
        week: '8-9',
        points: '20',
        dueDate: 'End of week 18',
        overview: {
            title:'',
            content:''},
            objective: {title:'',
                        content:''},
            outcome: {
            title:'',
            bullets: []
            },
            sAndS: {
            title: '',
            content: '',
            },
            setup: {
            title: '',
            steps: []
            },
            instructions: {
            title: '',
            steps: [
                // comma-separated arrays of text/img
            ]
            },
            endNotes: {
            title: '',
            content: '',
            links: [
                //comma-separated arrays of text/hyperlink
            ]
            }}
    },
    {
    lesson: {
        number: 'Lesson 06B',
        title: 'Building a Hook',
        week: '8-9',
        points: '20',
        dueDate: 'End of week 18',
        overview: {
            title:'',
            content:''},
            objective: {title:'',
                        content:''},
            outcome: {
            title:'',
            bullets: []
            },
            sAndS: {
            title: '',
            content: '',
            },
            setup: {
            title: '',
            steps: []
            },
            instructions: {
            title: '',
            steps: [
                // comma-separated arrays of text/img
            ]
            },
            endNotes: {
            title: '',
            content: '',
            links: [
                //comma-separated arrays of text/hyperlink
            ]
            }}
    },
    {
    lesson: {
        number: 'Assignment 01',
        title: 'Open-ended Project 01',
        week: '10-12',
        points: '80',
        dueDate: 'End of week 18',
        overview: {
            title:'',
            content:''},
            objective: {title:'',
                        content:''},
            outcome: {
            title:'',
            bullets: []
            },
            sAndS: {
            title: '',
            content: '',
            },
            setup: {
            title: '',
            steps: []
            },
            instructions: {
            title: '',
            steps: [
                // comma-separated arrays of text/img
            ]
            },
            endNotes: {
            title: '',
            content: '',
            links: [
                //comma-separated arrays of text/hyperlink
            ]
            }}
    },
    {
    lesson: {
        number: 'Lesson 07',
        title: 'Creating Original FX',
        week: '4',
        points: '20',
        dueDate: 'End of week 18',
        overview: {
            title:'',
            content:''},
            objective: {title:'',
                        content:''},
            outcome: {
            title:'',
            bullets: []
            },
            sAndS: {
            title: '',
            content: '',
            },
            setup: {
            title: '',
            steps: []
            },
            instructions: {
            title: '',
            steps: [
                // comma-separated arrays of text/img
            ]
            },
            endNotes: {
            title: '',
            content: '',
            links: [
                //comma-separated arrays of text/hyperlink
            ]
            }}
    },
    {
    lesson: {
        number: 'Assignment 02',
        title: 'Open-ended Project 02',
        week: '13-15',
        points: '80',
        dueDate: 'End of week 18',
        overview: {
            title:'',
            content:''},
            objective: {title:'',
                        content:''},
            outcome: {
            title:'',
            bullets: []
            },
            sAndS: {
            title: '',
            content: '',
            },
            setup: {
            title: '',
            steps: []
            },
            instructions: {
            title: '',
            steps: [
                // comma-separated arrays of text/img
            ]
            },
            endNotes: {
            title: '',
            content: '',
            links: [
                //comma-separated arrays of text/hyperlink
            ]
            }}
    },
    {
    lesson: {
        number: 'Lesson 08A',
        title: 'Mixing and Mastering Music',
        week: '14',
        points: '20',
        dueDate: 'End of week 18',
        overview: {
            title:'',
            content:''},
            objective: {title:'',
                        content:''},
            outcome: {
            title:'',
            bullets: []
            },
            sAndS: {
            title: '',
            content: '',
            },
            setup: {
            title: '',
            steps: []
            },
            instructions: {
            title: '',
            steps: [
                // comma-separated arrays of text/img
            ]
            },
            endNotes: {
            title: '',
            content: '',
            links: [
                //comma-separated arrays of text/hyperlink
            ]
            }}
    },
    {
    lesson: {
        number: 'Lesson 08B',
        title: 'Mixing and Mastering Dialogue',
        week: '14',
        points: '20',
        dueDate: 'End of week 18',
        overview: {
            title:'',
            content:''},
            objective: {title:'',
                        content:''},
            outcome: {
            title:'',
            bullets: []
            },
            sAndS: {
            title: '',
            content: '',
            },
            setup: {
            title: '',
            steps: []
            },
            instructions: {
            title: '',
            steps: [
                // comma-separated arrays of text/img
            ]
            },
            endNotes: {
            title: '',
            content: '',
            links: [
                //comma-separated arrays of text/hyperlink
            ]
            }}
    },
    {
    lesson: {
        number: 'Assignment 03',
        title: 'Open-ended Project 03',
        week: '16-18',
        points: '80',
        dueDate: 'End of week 18',
        overview: {
            title:'',
            content:''},
            objective: {title:'',
                        content:''},
            outcome: {
            title:'',
            bullets: []
            },
            sAndS: {
            title: '',
            content: '',
            },
            setup: {
            title: '',
            steps: []
            },
            instructions: {
            title: '',
            steps: [
                // comma-separated arrays of text/img
            ]
            },
            endNotes: {
            title: '',
            content: '',
            links: [
                //comma-separated arrays of text/hyperlink
            ]
            }}
    }
];

export default bandLabAccordionData;