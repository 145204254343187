import React, { Component } from 'react';
import './niiModo.css';

class NiiModo extends Component {
  render() {
    return(
      <div id="niiModo-container">
        <div class="niiModo-content-container">
          <div class="niiModo-image-container"
          >
            <img src="https://s3-us-west-2.amazonaws.com/f10-image/Nii-Modo-2.jpg" alt="niiModo logo"/>
          </div>
          <div class="niiModo-text-container">
            <h4>SPACE</h4>
            <h2>Nii Modo</h2>
            <p>Nii Modo is a gallery and community art space in Seattle, Washington. C.M. Ruiz curates and programs the space with a focus on women, artists of color, and first time exhibitors. Nii Modo's goal is to occupy empty spaces in community and bring accessible cultural events for artists and art lovers at every level.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default NiiModo;