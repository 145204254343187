import React, { Component } from 'react';
import './annualReport2021Programs-Highlights.css';

export default class AnnualReportProgramsHighlights extends Component {
  render() {
    return(
      <div id="annualReport2021programs-highlights-container">
          <div className="annualReport2021programs-highlights-content-container" id="annualReport2021-programs-spotlight-story">
            <div className="annualReport2021programs-highlights-text-container">
              <h1>PROGRAMS SPOTLIGHT</h1>
              <h2>Combining Games and Marine Science</h2>
              <p>
              <a href="https://drive.google.com/file/d/1rnV8RhnUekgTJPCH1uSlPWHdyepn8OOM/view" rel="noopener noreferrer" target="_blank">Endless Blue</a> is a fusion of creative work from our Games and Learning and Marine Science Teams. One of the most exciting aspects of foundry10 is the cross-pollination of ideas across teams of different disciplines that results in the development of a new approach to learning. In this case, the teams approached the question: Could a tabletop role-playing game, like Dungeons and Dragons, be used to help youth explore ocean careers?
              <br /><br />
              Together, the two teams created this unique game; Marine Science brought the subject knowledge, while Games and Learning created the game design.  The result was an experience where players could take on the role of an ocean professional in the sciences, logistics, or communications areas.  Players select their profession, personal attributes, skills, and equipment, and then they are off to discover the wonders of the ocean!
              <br /><br />
              Since starting this project in summer 2020, the game has been run a few times with real students alongside our partners at Aspiring Youth.  We have been thrilled with the results, as the players enjoy their explorations and get inspired about taking action to preserve the ocean.  We will continue to develop the game design, improve the visuals and immersive elements, and test it with as many youth as possible.
              <br /><br />
              This project showcases the power of cross-disciplinary approaches to idea development. The project demonstrates our expansive approach to learning and the importance of youth feedback and voice in our project design process.
              </p>
            </div>
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/program+highlight-8.png" alt="dice and fish"/>
          </div>
      </div>
    );
  }
}