import React, { Component } from 'react';
import './Big-Card.css';

export default class BigCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bigCard: []
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      bigCard: nextProps.all
    })
  }

  render() {
    if(this.state.bigCard) {
      const bigCards = this.state.bigCard.map((records, index) => (
        <li>
            {/* <a href={`/${records.url}`}> */}
            <a href={`${records.url}`}>
              <div class="student-work-card-container">
                <img src={records.imageUrl} alt={records.altText} />
                <p id="Everyday Unseen" class="student-work-card-title">{records.name}</p>
                <div id="Everyday Unseen" class="student-work-card-overlay"></div>
                {/* <div class="student-work-card-button"><a id="Everyday Unseen" href="/student-work/everyday-unseen">GALLERY STORY</a></div> */}
              </div>
            </a>
          </li>
      ));
        return(
          <div id="bigCard-container">
            <div class="bigCard-content-container">
              <div class="interestArea-cardsList2-container">
                <ul id="interestArea-cardsList2" class="interestArea-events-list2">
                  {bigCards}
                </ul>
              </div>
            </div>
          </div>
        );
     } else {
      return (
          <div class="invisibleDiv">

          </div>
        // <p className='VisibilityOff'>

        // </p>
      )
    }
  }
}