import React, { Component } from 'react';
import './annualReport2021Programs-Header.css';

export default class AnnualReportProgramsHeader extends Component {
  render() {
    return(
      <div id="annualReport2021programs-header-container">
        <div className="annualReport2021programs-header-content">
          <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Landing+Page/header+final.png" alt="foundry10 annual report 2020-2021 programs header" />
        </div>
      </div>
    );
  }
}