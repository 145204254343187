import React, { useState } from 'react';
import './vrStressTest-CostsAccordion.css';

function CostsAccordion({ element }) {
    const [isActive, setIsActive] = useState(false);

    return (


            <div className="costsAccordion-item">
                <div className="costsAccordion-title" onClick={() => setIsActive(!isActive)}>
                    <div className="costsAccordion-image">
                        <img src={element.img} alt={element.alt}/>
                    </div>
                    <div className="costsAccordion-text">
                        <h2 className="costsAccordion-title-text">{element.title}</h2>
                        <h3>{element.subtitle}</h3>
                    </div>
                    <i className={ isActive ?  `fa-solid fa-angle-up`:`fa-solid fa-angle-down`}></i>                  
                </div>
                {isActive && <div className="costsAccordion-content">
                    <div className="costsAccordion-description">
                        <p>{element.content}</p>
                        <p>{element.contentCont}</p>
                    </div>  
                </div>}
            </div>

    );
}

export default CostsAccordion;
