import React, { Component } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import './charts.css'


export default class BarChartGender extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // To avoid unnecessary update keep all options in the state.
    chartOptions: {
      chart: {
        type: 'bar'
      },
      title: {
          text: 'Gender Breakdown of Sample'
      },
      xAxis: {
          categories: ['Gender']
      },
      yAxis: {
          min: 0,
          title: {
              text: 'Count'
          }
      },
      legend: {
          reversed: true
      },
      plotOptions: {
          series: {
              stacking: 'normal'
          }
      },
      colors: ['#D14E1D','#00464F','#FFA75E','#575757','#007A8A'],
      series: [{
          name: 'Female',
          data: [48]
      }, {
          name: 'Male',
          data: [38]
      }, {
          name: 'Prefer Not to Respond',
          data: [3]
      }, {
          name: 'Nonbinary',
          data: [1]
      }, {
          name: 'Something Else',
          data: [1]
      }]
      }
    };
  }


  render() {
    const { chartOptions } = this.state;

    return (
      <div class="chart">
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
        />
      </div>
    )
  }
}
