import React, { Component } from 'react';
import AutoLandingPageIntro from './autoLandingPage-Intro';
import AutoLandingPageDescription from './autoLandingPage-Description';
import AutoLandingPageLinks from './autoLandingPage-Links';
import NewFooter from '../../../pages-other/NewFooter';
import './autoLandingPage-Content.css';

export default class AutoLandingPageContent extends Component {
  render() {
    return(
      <div id="autoLandingPage-content-container">
        <AutoLandingPageIntro />
        <AutoLandingPageDescription />
        <AutoLandingPageLinks />
        <NewFooter />
      </div>
    );
  }
}