import React from "react";
import CustomTreeView from "./CustomTreeView";

const TreeFilter = (props) => {
  const { data, page } = props;
  const [expanded, setExpanded] = React.useState(["root"]);
  const [selected, setSelected] = React.useState([]);
  const [subjectData, setSubjectData] = React.useState();

  React.useEffect(() => {
    setSubjectData(() => data);
  }, [data]);

  const handleToggle = (event, nodeIds) => {
    let expandedTemp = expanded;
    expandedTemp = nodeIds;
    setExpanded(expandedTemp);
  };

  return (
    <div>
      <CustomTreeView
        data={subjectData}
        page={page}
        expanded={expanded}
        selected={selected}
        handleToggle={handleToggle}
      />
    </div>
  );
};

export default TreeFilter;
