import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import './researcherResources-Video.css';

export default class ResearcherResourcesVideo extends Component {
  render() {
    return(
      <div id="researcherResources-video-container">
        <div className="researcherResources-video-content-container">
          <h2 className="researcherResources-video-title"><span>Featured Video</span></h2>
          <div class="researcherResources-videoCard-container">
            <ReactPlayer
            url='https://youtu.be/aEn6p_crDic'
            className='react-player'
            controls
            width='100%'
            height='100%'
            />
          </div>
        </div>
      </div>
    );
  }
}