const ResearchInterestAreaData = [
    {
        interestArea: {
            title: 'Artistic Design',
            url: '/resources?cat=695'
        }
    },    
    {
        interestArea: {
            title: 'Career and Technical Education',
            url: '/resources?cat=3509'
        }
    },
    {
        interestArea: {
            title: 'Dance',
            url: '/resources?cat=696'
        }
    },
    {
        interestArea: {
        title: 'Digital Civics',
        url: '/resources?cat=697'
    }
    },
    {
        interestArea: {
        title: 'Digital Audio',
        url: '/resources?cat=3510'
    }
    },
    {
        interestArea: {
            title: 'Dramatic Arts',
            url: '/resources?cat=3511'
        }
    },
    {
        interestArea: {
            title: 'EdTech',
            url: '/resources?cat=3512'
        }
    },
    {
        interestArea: {
            title: 'Film',
            url: '/resources?cat=3513'
        }
    },
    {
        interestArea: {
            title: 'Financial Education',
            url: '/resources?cat=3514'
        }
    },
    {
        interestArea: {
            title: 'Games and Learning',
            url: '/resources?cat=3515'
        }
    },
    {
        interestArea: {
            title: 'Health Education',
            url: '/resources?cat=3516'
        }
    },
    {
        interestArea: {
            title: 'Internships',
            url: '/resources?cat=3517'
        }
    },
    {
        interestArea: {
            title: 'Interventions',
            url: '/resources?cat=3518'
        }
    },
    {
        interestArea: {
            title: 'Marine Science',
            url: '/resources?cat=3519'
        }
    },
    {
        interestArea: {
            title: 'STEM',
            url: '/resources?cat=3520'
        }
    }
]

export default ResearchInterestAreaData;
