// Stories, In The News, Resources and Publications Archive
import React from "react";
import { Helmet } from 'react-helmet';
import { useQuery, gql } from "@apollo/client";
import PageHeader from "../components/page-header.js";
import ArchiveList from "../components/archive-list.js";
import PreFooter from "../components/pre-footer.js";
import NewFooter from '../../pages-other/NewFooter.js';

// Get Page content
const entryQuery = gql`
  query pageQuery($slug: [String]) {
    entries(slug: $slug) {
      title
      ... on storiesArchive_storiesArchive_Entry {
        blurb
        guidingPrinciples {
          ... on guidingPrinciples_BlockType {
            guidingPrinciplesPrinciple
          }
        }
        footerQuote
        footerQuoteAttribution
        footerQuoteBg {
          url
        }
        footerCTA {
          ... on footerCTA_BlockType {
            footerCTATitle
            footerCTAContent
            footerCTALinkUrl {
              ariaLabel
              customText
              target
              text
              title
              type
              url
            }
          }
        }
      }
      ... on pressArchive_pressArchive_Entry {
        blurb
        guidingPrinciples {
          ... on guidingPrinciples_BlockType {
            guidingPrinciplesPrinciple
          }
        }
        footerQuote
        footerQuoteAttribution
        footerQuoteBg {
          url
        }
        footerCTA {
          ... on footerCTA_BlockType {
            footerCTATitle
            footerCTAContent
            footerCTALinkUrl {
              ariaLabel
              customText
              target
              text
              title
              type
              url
            }
          }
        }
      }
      ... on programsArchive_programsArchive_Entry {
        blurb
        guidingPrinciples {
          ... on guidingPrinciples_BlockType {
            guidingPrinciplesPrinciple
          }
        }
        footerQuote
        footerQuoteAttribution
        footerQuoteBg {
          url
        }
        footerCTA {
          ... on footerCTA_BlockType {
            footerCTATitle
            footerCTAContent
            footerCTALinkUrl {
              ariaLabel
              customText
              target
              text
              title
              type
              url
            }
          }
        }
      }
      ... on projectArchives_projectArchives_Entry {
        blurb
        guidingPrinciples {
          ... on guidingPrinciples_BlockType {
            guidingPrinciplesPrinciple
          }
        }
        footerQuote
        footerQuoteAttribution
        footerQuoteBg {
          url
        }
        footerCTA {
          ... on footerCTA_BlockType {
            footerCTATitle
            footerCTAContent
            footerCTALinkUrl {
              ariaLabel
              customText
              target
              text
              title
              type
              url
            }
          }
        }
      }
      ... on opportunitiesArchive_opportunitiesArchive_Entry {
        blurb
        guidingPrinciples {
          ... on guidingPrinciples_BlockType {
            guidingPrinciplesPrinciple
          }
        }
        footerQuote
        footerQuoteAttribution
        footerQuoteBg {
          url
        }
        footerCTA {
          ... on footerCTA_BlockType {
            footerCTATitle
            footerCTAContent
            footerCTALinkUrl {
              ariaLabel
              customText
              target
              text
              title
              type
              url
            }
          }
        }
      }
    }
  }
`;

const PageRender = (slug) => {
  const { data, loading, error } = useQuery(entryQuery, {
    variables: { slug },
  });
  if (loading) return null;
  if (error) return `Error! ${error}`;
  return data.entries.map((block, index) => {
    const preFooter = block.guidingPrinciples;
    if (typeof preFooter) {
      return <PreFooter data={data} />;
    }
    switch (block) {
      case "guidingPrinciples":
        return <PreFooter block={block} />;
      case "blurb":
        return <div>There's a blurb</div>;
    }
  });
};

const PressArchive = ({ slug, channel }) => (
  <section id="contentArchive">
    <Helmet>
          <title>foundry10 in the News</title>
          <meta
            name="title"
            content="foundry10 in the News"
          />
          <meta
            name="description"
            content="Discover foundry10 in the news. Explore media mentions and coverage of our impactful education research, programs, and philanthropy."
          />
          <meta property="og:title" content="foundry10 in the News" />
          <meta
            property="og:description"
            content="Discover foundry10 in the news. Explore media mentions and coverage of our impactful education research, programs, and philanthropy."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/news/press" />
          <link rel="canonical" href="https://www.foundry10.org/news/press" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 in the News",
                "description": "Discover foundry10 in the news. Explore media mentions and coverage of our impactful education research, programs, and philanthropy.",
                "url": "https://www.foundry10.org/news/press"
              }
            `}
          </script>
    </Helmet>
    <PageHeader slug={slug} />
    <ArchiveList channel={channel} />
    <PageRender slug={slug} />
    <NewFooter />
  </section>
);

export default PressArchive;
