import React, { Suspense, Component } from 'react';
import LcpTestDescription from './lcpTest-Description';
import LcpTestWIA from './lcpTest-WIA';
import NewFooter from '../../pages-other/NewFooter';
import './lcpTest-Content.css';

// Lazy load non-critical components
const LcpTestSlides = React.lazy(() => import('./lcpTest-Slides'));
const LcpTestCircuit = React.lazy(() => import('./lcpTest-Circuit'));
// const VrLandingPageLinks = React.lazy(() => import('./vrLandingPage-Links'));

export default class LcpTestContent extends Component {
  render() {
    return (
      <div id="lcpTest-content-container">
        <LcpTestDescription />
        <LcpTestWIA />
        
        {/* Suspense for lazy-loaded components */}
        <Suspense fallback={<div>Loading...</div>}>
          <LcpTestSlides />
          <LcpTestCircuit />
          {/* <VrLandingPageLinks /> */}
        </Suspense>
        
        <NewFooter />
      </div>
    );
  }
}
