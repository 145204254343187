// Craft translation of the Research Landing Page
import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import {
    useQuery,
    gql
  } from "@apollo/client";
import PageHeader from '../../components/page-header.js';
// import Breadcrumbs from '../components/breadcrumbs.js';
import Grid from '@material-ui/core/Grid';
import ContentBlocks from '../../components/ContentBlocks.js';
import StoryCard from '../../components/storyCard.js';
import CraftResourceDropdown from './resourceDropdown.js';
import NewFooter from '../../../pages-other/NewFooter.js';
import '../../styles/pages/research.css';

const segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
const segment_array = segment_str.split( '/' );
const currentUrl=window.location.href.split('/')
const pathName=currentUrl
const lastItem = segment_array[segment_array.length - 1];
const last_segment = segment_array.pop();

//Set Breadcrumb values
const params = [
  { parent: segment_array[1]},
  { pageTitle: last_segment},
  { style: '' }
]

// Get Recent Research Stories
const researchQuery = gql`
    query researchQuery {
      entries(section: "stories", limit: 3, relatedToCategories: [{ id: 689 }]) {
        title
        slug
        url
        author {
          fullName
        }
        postDate @formatDateTime (format: "M j, Y")
        ... on stories_default_Entry {
          researchExternalAuthors {
            ... on researchExternalAuthors_BlockType {
              researchExternalAuthorsAuthor
            }
          }
          interestAreaTeam {
            ... on interestAreaTeam_BlockType {
              interestAreaTeamMember {
                title
              }
            }
          }
          blurb
          featuredImage {
            url
          }  
          categories {
            title
          }
        }
      }
    }
`

// Get Page content
const QUERY = gql`
query NewQuery($slug: [String]) {
  entry(slug: $slug) {
    title
    postDate @formatDateTime(format: "d M Y")
    postDateAlt: postDate @formatDateTime(format: "Y-m-d")
    url
    ... on research_research_Entry {
      customTitle
      blurb
      breadcrumbTitle
      featuredImage {
        title
        url
      }
      featuredImageCaption
      contentOptions {
        ... on contentOptions_standardContent_BlockType {
          contentTitle
          contentContent
          linkUrl {
            url
            customText
            target
          }
          contentAlignment
        }
        ... on contentOptions_icp_BlockType {
          icpTitle
          icpContent
          icpImage {
            url
          }
          icpImageCaption
          icpPositionOfImage
          icpBackgroundColor
        }
        ... on contentOptions_fwi_BlockType {
          fwiTitle
          fwiImage {
            url
          }
          fwiCaption
          fwiSize
        }
        ... on contentOptions_quoteSlideshow_BlockType {
          quoteSliderTitle
          quotes {
            __typename
          }
        }
            ... on contentOptions_slider_BlockType {
              sliderTitle
              slides {
                __typename
                ... on slides_BlockType {
                  slideImage {
                    url
                  }
                  slideHeadline
                  slideContent
                  slideLink {
                    customText
                    url
                    target
                  }
                }
              }
            }
            ... on contentOptions_thirdPartyCode_BlockType {
            thirdPartyCodeTitle
            thirdPartyCodeCode
          }
            ... on contentOptions_icp_BlockType {
            icpTitle
            icpContent
              icpImage {
                  url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
          }
                    ... on contentOptions_emphasized_BlockType {
            emphasizedTitle
            emphasizedSubheadline
            emphasizedOverline
            emphasizedImage {
                url
            }
            emphasizedImageCaption
            emphasizedContent
            emphasizedLink
            emphasizedBackgroundColor
          }
          ... on contentOptions_accordion_BlockType {
            accordionTitle
            accordion {
              __typename  
              ... on accordion_BlockType {
                accordionItemTitle
                accordionItemBlurb
                accordionItemImage {
                  url
                }
                accordionItemIcon {
                  url
                }
                accordionItemContent
              }
            }
          }
        }     
    }
  }
}

`

const ResearchHighlights = () => {
    const { data, loading, error } = useQuery(researchQuery);
    if (loading) return null;
    if (error) return `Error! ${error}`;
  
    return (
      data.entries.map(
        (block, index) => {
         
          //console.log(block.slug);
          //console.log(block.categoryId);
          let props = {
            block: {block}
          }
          return (
            <Grid item xs={12} sm={4}>
              <StoryCard {...props} />
            </Grid>
          )
        }
      )
    )
  }

  const ResearchBlock = (teamData) => {

    return (
      <section className='featuredResearch'>
        <div className="container">
          <h2 className="text-xl_bold">Latest Research News</h2>
          <Grid container spacing={4}>
            <ResearchHighlights />
          </Grid>
          <a className="button orange viewAllButton" href="/stories?cat=689">View All  <i class="fas fa-arrow-right"></i></a>
        </div>
  
      </section>
    )
  }

  const InlineContent = (slug) => {
    const { data, loading, error } = useQuery(QUERY, {
      variables: {slug}
    });
    if (loading) { return <div>loading</div> }
    if (error) { return <div>{error}</div> }
  
    return (
      <Fragment>
      <ContentBlocks data={data.entry} />
      {console.log('Research Data', data.entry.contentOptions)}
      </Fragment>
    );
  }
  

export default class ResearchLandingCraft extends Component {
  render() {
    console.log('props for content archive');
    console.log(this.props);
    return(
      <section className="researchLandingCraft">
                    <Helmet>
              <title>foundry10 Research: Our Approach, Labs, and Latest News</title>
              <meta
                name="title"
                content="foundry10 Research: Our Approach, Labs, and Latest News"
              />
              <meta
                name="description"
                content="Rigorous education research grounds every aspect of our work as an organization. Learn about foundry10's research labs, latest insights, and recent news."
              />
              <meta property="og:title" content="foundry10 Research: Our Approach, Labs, and Latest News" />
              <meta
                property="og:description"
                content="Rigorous education research grounds every aspect of our work as an organization. Learn about foundry10's research labs, latest insights, and recent news."
              />
              <meta property="og:type" content="website" />
              <meta property="og:url" content="https://www.foundry10.org/research" />
              <link rel="canonical" href="https://www.foundry10.org/research" />
              {/* Structured Data Script Tag */}
              <script type="application/ld+json">
                {`
                  {
                    "@context": "https://schema.org",
                    "@type": "WebPage",
                    "name": "foundry10 Research: Our Approach, Labs, and Latest News",
                    "description": "Rigorous education research grounds every aspect of our work as an organization. Learn about foundry10's research labs, latest insights, and recent news.",
                    "url": "https://www.foundry10.org/research"
                  }
            `   }
              </script>
            </Helmet>
        <PageHeader slug={this.props.slug} />
        {/* <Breadcrumbs slug={last_segment} /> */}
        <ResearchBlock />
        <CraftResourceDropdown />
        <InlineContent slug={this.props.slug}/>
        <NewFooter />
      </section>
    );
  }
}