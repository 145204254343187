import React, { Component } from 'react';
import AreasOfResearch from './AreasOfResearch/AreasOfResearch';
import ConceptualDevelopmentLabMembers from './conceptualDevelopmentLab-Members';
import './conceptualDevelopmentLab-Body.css';

export default class ConceptualDevelopmentLabBody extends Component {
  render() {
    return(
      <div id="conceptualDevelopmentLabBody-container">
        <div class="conceptualDevelopmentLabBody-content-container">
          {/* LAB DEFINITION */}
          <div class="conceptualDevelopmentLabBody-definition-container">
            <div class="conceptualDevelopmentLabBody-definition-content-container">
              <div class="conceptualDevelopmentLabBody-definition-bold">
                <h1>What is conceptual development?</h1>
              </div>
              <div class="conceptualDevelopmentLabBody-definition-paragraph">
                <h2>To put it simply, conceptual development means "learning"</h2>
                <p>More specifically, it describes how our understanding of the world changes over time with the goal of explaining how students come to understand the many abstract ideas that they learn during their education, e.g., "gravity," "infinity," "evolution," and so on. Many of these concepts require instruction that goes beyond our everyday experience. The ultimate goal of the lab is to find new ways for teachers and caregivers to overcome common challenges that students face when learning difficult concepts like these.</p>
              </div>
            </div>
          </div>
          {/* AREAS OF RESEARCH */}
          <AreasOfResearch />
          {/* LAB STUDIES */}
          <div id="conceptualDevelopmentLabBody-highlights-container">
            <div className="conceptualDevelopmentLabBody-highlights-content-container">
              <h2 className="conceptualDevelopmentLabBody-titleContent-container"><span>Lab Studies</span></h2>
                <div className="conceptualDevelopmentLabBody-highlights-grid-container">
                  <div className="conceptualDevelopmentLabBody-highlights-image-container">
                    <img src="https://f10-research.s3.us-west-2.amazonaws.com/Conceptual+Development+Lab/Playful+Parenting+Study.png" alt="parent with child working on schoolwork"/>
                  </div>
                    <div className="conceptualDevelopmentLabBody-highlights-text">
                      <h2><a className="conceptualDevelopmentLabBody-highlightsLink" href="https://www.foundry10.org/stories/new-study-playful-parenting-study" target="_blank" rel="noopener noreferrer">Playful Parenting Study</a></h2>
                      <p>This research collaboration with the <a href="https://www.foundry10.org/interest-areas/creativity-design-and-play" target="_blank" rel="noopener noreferrer">Creativity, Design, and Play team</a> investigates how caregivers engage in play with young learners, focusing on their perceptions of the role they have in shaping youth's play experiences. We are particularly interested in guided play, an approach that involves caregivers offering open-ended support that enables youth to direct their own play. Using both survey and observational methods, we will explore caregivers' beliefs about play and their current play approaches to offer insights that effectively support caregivers in fostering meaningful play experiences with youth.</p>
                    </div>
                </div>
                <div className="conceptualDevelopmentLabBody-highlights-grid-container">
                  <div className="conceptualDevelopmentLabBody-highlights-image-container">
                    <img src="https://f10-research.s3.us-west-2.amazonaws.com/Conceptual+Development+Lab/Family+Understanding+of+Number+Knowledge.png" alt="parent showing number card to smiling child"/>
                  </div>
                    <div className="conceptualDevelopmentLabBody-highlights-text">
                      <h2><a className="conceptualDevelopmentLabBody-highlightsLink" href="https://www.foundry10.org/stories/family-understanding-of-number-knowledge" target="_blank" rel="noopener noreferrer">Family Understanding of Number Knowledge</a></h2>
                      <p>This study explores what early learners know about number words and gestures and how caregivers' understanding of this topic relates to their parenting behavior. As part of this study, we will examine how early learners develop an understanding of number words (i.e., one, two, three) and number gestures (i.e., holding up two fingers to indicate the quantity two) to shed light on the process of their early number learning and determine what types of instruction are most helpful to them.</p>
                    </div>
                </div>                 
                <div className="conceptualDevelopmentLabBody-highlights-grid-container">
                  <div className="conceptualDevelopmentLabBody-highlights-image-container">
                    <img src="https://f10-research.s3.us-west-2.amazonaws.com/Conceptual+Development+Lab/Power+of+Pretend.png" alt="two young children playing with lab instruments"/>
                  </div>
                    <div className="conceptualDevelopmentLabBody-highlights-text">
                      <h2><a className="conceptualDevelopmentLabBody-highlightsLink" href="https://www.foundry10.org/stories/power-of-pretend-effects-of-dramatic-play-on-persistence-in-stem" target="_blank" rel="noopener noreferrer">Power of Pretend</a></h2>
                      <p>This study explores the links between dramatic play and early learners' persistence in STEM activities. By comparing the effects different dramatic play interventions and messages about the importance of persisting through challenges have on preschool and early elementary students' success and persistence on a basic STEM-related task, we hope to inform our understanding of how to make math and science more engaging for youth and how to help students overcome challenges in school.</p>
                    </div>
                </div>           
                <div className="conceptualDevelopmentLabBody-highlights-grid-container">
                  <div className="conceptualDevelopmentLabBody-highlights-image-container">
                    <img src="https://f10-research.s3.us-west-2.amazonaws.com/Conceptual+Development+Lab/FinTok+-+Financial+Advice+on+TikTok.jpg" alt="two teenagers watching a video on their mobile phone"/>
                  </div>
                    <div className="conceptualDevelopmentLabBody-highlights-text">
                      <h2><a className="conceptualDevelopmentLabBody-highlightsLink" href="https://www.foundry10.org/stories/dominic-gibson-discusses-fintok-research-study-on-good-day-seattle" target="_blank" rel="noopener noreferrer">#FinTok: Financial Advice on TikTok</a></h2>
                      <p>This study explores the financial information that young people receive through TikTok and the judgments they make about that advice. In this study, we examineTikTok videos in order to understand the types and characteristics of financial advice that young people encounter online. We also investigate how young people evaluate financial advice on TikTok and how their evaluations compare to expert evaluations. You can learn more about this area of research in this <a href="https://www.foundry10.org/stories/what-can-tiktok-teach-us-about-financial-education" target="_blank" rel="noopener noreferrer">foundry10 blog</a> or in the <a href="https://www.foundry10.org/news/press/fox-fintok-2023" target="_blank" rel="noopener noreferrer">Fox13 segment</a> that highlights this research.</p>
                    </div>
                </div>          
            </div>
          </div>
          {/* GET INVOLVED */}
          <div class="conceptualDevelopmentLabBody-getInvolved-container">
            <div class="conceptualDevelopmentLabBody-getInvolved-content-container">
              <div class="conceptualDevelopmentLabBody-getInvolved-bold">
                <h2>Get Involved!</h2>
              </div>
              <div class="conceptualDevelopmentLabBody-getInvolved-paragraph">
                <h3>Play the “Give-A-Number” Game</h3>
                <p>Interested in discovering what your 2- to 5-year-old knows about numbers? <a href="/resources/give-a-number-game-guide" target="_blank" rel="noopener noreferrer" style={{ color: "#FFFFFF" }}>Explore this step-by-step guide</a>  to a game that reveals your child’s number knowledge!</p>
              </div>
              <div class="conceptualDevelopmentLabBody-getInvolved-paragraph">
                <h3>Join Our Research Database</h3>
                <p>Interested in hearing about future opportunities for you and your child(ren) to participate in our education research studies in Seattle? <a href="https://airtable.com/appBGujBORRnB0YtY/pagVKLeUWNAEe86fw/form" target="_blank" rel="noopener noreferrer" style={{ color: "#FFFFFF" }}>Click here</a> for more information about what to expect when you participate and to join our database of interested families (all personal information will be stored securely and never shared).</p>
              </div>
            </div>
          </div>
          {/* LAB NEWS */}
          <div id="conceptualDevelopmentLabBody-highlights-container">
            <div className="conceptualDevelopmentLabBody-highlights-content-container">
              <h2 className="conceptualDevelopmentLabBody-titleContent-container"><span>Lab News</span></h2>
                <div className="conceptualDevelopmentLabBody-highlights-grid-container">
                  <div className="conceptualDevelopmentLabBody-highlights-image-container">
                    <img src="https://f10-research.s3.us-west-2.amazonaws.com/Conceptual+Development+Lab/Presentation+at+the+Cognitive+Development+Society+2024+Meeting.png" alt="conference building"/>
                  </div>
                    <div className="conceptualDevelopmentLabBody-highlights-text">
                      <h2><a className="conceptualDevelopmentLabBody-highlightsLink" href="https://www.foundry10.org/stories/upcoming-presentation-at-the-cognitive-development-society-2024-meeting" target="_blank" rel="noopener noreferrer">Presentation at the Cognitive Development Society 2024 Meeting</a></h2>
                      <p>Conceptual Development Lab team members presented their latest research related to <a href="https://www.foundry10.org/resources/cds-2024-predictors-and-barriers-to-caregiver-child-play" target="_blank" rel="noopener noreferrer">young children's play experiences</a> and <a href="https://www.foundry10.org/resources/parents-perceptions-of-their-own-childrens-number-knowledge" target="_blank" rel="noopener noreferrer">number learning</a> at the Cognitive Development Society conference on March 23.</p>
                    </div>
                </div>
                <div className="conceptualDevelopmentLabBody-highlights-grid-container">
                  <div className="conceptualDevelopmentLabBody-highlights-image-container">
                    <img src="https://f10-research.s3.us-west-2.amazonaws.com/Conceptual+Development+Lab/Activity+at+UW+MisinfoDay+for+High+Schoolers.png" alt="MisinfoDay logo"/>
                  </div>
                    <div className="conceptualDevelopmentLabBody-highlights-text">
                      <h2><a className="conceptualDevelopmentLabBody-highlightsLink" href="https://www.foundry10.org/stories/activity-at-uw-misinfoday-for-high-schoolers" target="_blank" rel="noopener noreferrer">Activity at UW MisinfoDay for High Schoolers</a></h2>
                      <p>foundry10 researchers Allie Tung and Mikka Hoffman will be hosting an interactive activity to help high schoolers learn about the quality of financial information on TikTok at the UW Center for an Informed Public's MisinfoDay.</p>
                    </div>
                </div>
                <div className="conceptualDevelopmentLabBody-highlights-grid-container">
                  <div className="conceptualDevelopmentLabBody-highlights-image-container">
                    <img src="https://f10-research.s3.us-west-2.amazonaws.com/Conceptual+Development+Lab/Meet+the+foundry10+Research+Assistant+-+Amaris+Ilar.png" alt="amaris ilar smiling"/>
                  </div>
                    <div className="conceptualDevelopmentLabBody-highlights-text">
                      <h2><a className="conceptualDevelopmentLabBody-highlightsLink" href="https://www.foundry10.org/stories/meet-the-foundry10-research-assistant-amaris-ilar" target="_blank" rel="noopener noreferrer">Meet the foundry10 Research Assistant: Amaris Ilar</a></h2>
                      <p>Amaris is an Education and Psychology double major at University of Washington and works in the Conceptual Development Lab as an undergraduate research assistant.</p>
                    </div>
                </div>
                <div className="conceptualDevelopmentLabBody-highlights-grid-container">
                  <div className="conceptualDevelopmentLabBody-highlights-image-container">
                    <img src="https://f10-research.s3.us-west-2.amazonaws.com/Conceptual+Development+Lab/FOX13+Highlights+foundry10's+FinTok+Research.jpg" alt="senior research dominic"/>
                  </div>
                    <div className="conceptualDevelopmentLabBody-highlights-text">
                      <h2><a className="conceptualDevelopmentLabBody-highlightsLink" href="https://www.foundry10.org/stories/dominic-gibson-discusses-fintok-research-study-on-good-day-seattle" target="_blank" rel="noopener noreferrer">FOX13 Highlights foundry10's FinTok Research</a></h2>
                      <p>Dominic Gibson was interviewed on Good Day Seattle about how young people are turning to TikTok for financial advice.</p>
                    </div>
                </div>
                <div className="conceptualDevelopmentLabBody-highlights-grid-container">
                  <div className="conceptualDevelopmentLabBody-highlights-image-container">
                    <img src="https://f10-research.s3.us-west-2.amazonaws.com/Conceptual+Development+Lab/Young+Children+and+Number+Gestures.png" alt="senior research dominic"/>
                  </div>
                    <div className="conceptualDevelopmentLabBody-highlights-text">
                      <h2><a className="conceptualDevelopmentLabBody-highlightsLink" href="https://onlinelibrary.wiley.com/doi/10.1111/desc.13335" target="_blank" rel="noopener noreferrer">Young Children and Number Gestures</a></h2>
                      <p>foundry10 Senior Researcher Dominic Gibson recently published “Young Children Interpret Number Gestures Differently Than Non-Symbolic Sets” in the journal Developmental Science. The article was based on data he collected in a previous position at The University of Chicago.</p>
                    </div>
                </div>                                
                <div className="conceptualDevelopmentLabBody-highlights-grid-container">
                  <div className="conceptualDevelopmentLabBody-highlights-image-container">
                    <img src="https://f10-research.s3.us-west-2.amazonaws.com/Conceptual+Development+Lab/New+America+Learning+Sciences+Exchange.png" alt="New America Learning Sciences Exchange"/>
                  </div>
                    <div className="conceptualDevelopmentLabBody-highlights-text">
                      <h2><a className="conceptualDevelopmentLabBody-highlightsLink" href="https://foundry10.medium.com/780dae2806d5" target="_blank" rel="noopener noreferrer">Making Math Fun for Early Learners: Researchers in Conversation</a></h2>
                      <p>In a recent conversation, researchers from foundry10 and the New America Learning Sciences Exchange discussed how (and why) to make math fun for early learners and their families.</p>
                    </div>
                </div>         
            </div>
          </div>
          {/* CURRENT LAB MEMBERS */}
          <ConceptualDevelopmentLabMembers />
          {/* PUBLICATIONS */}
          <div class="conceptualDevelopmentLabBody-publications-container">
            <div class="conceptualDevelopmentLabBody-publications-content-container">
              <div class="conceptualDevelopmentLabBody-publications-bold">
                <h2>Related Publications from Lab Members</h2>
              </div>
              <ul class="conceptualDevelopmentLabBody-publications-list">
                <li>
                  <p>Gibson, D.J., Berkowitz, T., Butts, J., Goldin-Meadow, S., & Levine, S. C. (2022). Young children interpret number gestures differently than nonsymbolic sets. Developmental Science, 00, e13335.</p>
                  <a href="https://onlinelibrary.wiley.com/doi/10.1111/desc.13335" target="_blank" rel="noopener noreferrer">https://onlinelibrary.wiley.com/doi/10.1111/desc.13335</a>
                </li>
                <li>
                  <p>Berkowitz, T., Gibson, D.J. & Levine, S. C. (2021) Parent Math Anxiety Predicts Early Number Talk, Journal of Cognition and Development, 22:4, 523-536.</p>
                  <a href="https://www.tandfonline.com/doi/full/10.1080/15248372.2021.1926252" target="_blank" rel="noopener noreferrer">https://www.tandfonline.com/doi/full/10.1080/15248372.2021.1926252</a>
                </li>
                <li>
                  <p>Gibson, D.J., Gunderson, E.A. and Levine, S.C. (2020), Causal Effects of Parent Number Talk on Preschoolers’ Number Knowledge. Child Dev, 91: e1162-e1177.</p>
                  <a href="https://srcd.onlinelibrary.wiley.com/doi/10.1111/cdev.13423" target="_blank" rel="noopener noreferrer">https://srcd.onlinelibrary.wiley.com/doi/10.1111/cdev.13423</a>
                </li>
                <li>
                  <p>Gibson, D.J., Gunderson, EA, Spaepen, E, Levine, SC, Goldin-Meadow, S. Number gestures predict learning of number words. Dev Sci. 2019; 22:e12791.</p>
                  <a href="https://onlinelibrary.wiley.com/doi/10.1111/desc.12791" target="_blank" rel="noopener noreferrer">https://onlinelibrary.wiley.com/doi/10.1111/desc.12791</a>
                </li>
                <li>
                  <p>Gibson, D.J., Congdon, E.L. and Levine, S.C. (2015), The Effects of Word-Learning Biases on Children's Concept of Angle. Child Dev, 86: 319-326.</p>
                  <a href="https://srcd.onlinelibrary.wiley.com/doi/10.1111/cdev.12286" target="_blank" rel="noopener noreferrer">https://srcd.onlinelibrary.wiley.com/doi/10.1111/cdev.12286</a>
                </li>
                <p>Visit <a href="https://scholar.google.com/citations?user=v6ViARQAAAAJ&hl=en" target="_blank" rel="noopener noreferrer">Dominic's Google Scholar Page</a> for a complete list of his publications.</p>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}