import React, { Component } from 'react';
import './annualReport2021Research-Sharing.css';

export default class AnnualReportResearchSharing extends Component {
  render() {
    return(
      <div id="annualReport2021research-sharing-container">
        <div className="annualReport2021research-sharing-intro-container">
            <div className="annualReport2021research-sharing-text-container">
                <h1>Sharing Research</h1>
                <p>
                At foundry10, we aim to make our research findings as accessible as possible. Getting our research into the hands of multiple stakeholders is critical to our mission of expanding the way people think about learning. We share our research in three main ways: at conferences with other researchers, educators, and industry professionals; in share back reports and presentations directly with the schools and communities who participate in our research; and by publishing articles in peer-reviewed, academic journals.
                </p>
                <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Research+Landing+Page/Asset+40-8.png" alt="Conferences, Share Back Reports & Research Papers" />
            </div>
            <div className="annualReport2021research-sharing-img-container">
                <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Research+Landing+Page/Asset+41-8.png" alt="Researcher Illustration" />
            </div>
          </div>
          <div className="annualReport2021research-sharing-content-container">
            <div className="annualReport2021research-sharing-presentation-container">
              <div className="annualReport2021research-sharing-banner-container" id="annualReport2021research-sharing-banner-presenting">
                <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Research+Landing+Page/presenting+at+conference-8.png" alt="Presenting at Conferences" />
                <h2>Presenting at Conferences</h2>
              </div>
                <p>
                The Research team presented at a variety of conferences in the 2020-2021 year, including at the <a href="https://www.acacamps.org/sites/default/files/resource_library/2021-Camp-Research-Forum-Book-Abstracts.pdf" target="_blank" rel="noopener noreferrer"> American Camp Association (ACA)</a>, <a href="https://events.ascd.org/main" target="_blank" rel="noopener noreferrer"> Association for Supervision and Curriculum Development (ASCD)</a>, 
                <a href="https://conference.iste.org/2020/program/search/detail_session.php?id=113556123" target="_blank" rel="noopener noreferrer"> International Society for Technology in Education (ISTE)</a>, 
                Northwest Council for Computer Education (NCCE) in <a href="https://conference.ncce.org/2020/program/search/search.php" target="_blank" rel="noopener noreferrer">2020</a> and <a href="https://conference.ncce.org/2021/program/search/search.php" target="_blank" rel="noopener noreferrer">2021</a>, <a href="https://convention2.allacademic.com/one/sra/sra20/" target="_blank" rel="noopener noreferrer">Society for Research on Adolescence (SRA)</a>, <a href="https://convention2.allacademic.com/one/srcd/srcd21/index.php?cmd=Prepare+Online+Program&program_focus=main&PHPSESSID=srpt2j3gvebqkqgfbs65ct4nee" target="_blank" rel="noopener noreferrer"> Society for Research in Child Development (SRCD)</a>, 
                and <a href="https://www.wla.org/2020-wla-sessions" target="_blank" rel="noopener noreferrer">Washington Library Association (WLA)</a>. Those presentations covered a variety of topics, from our work on how to foster youth-adult partnerships in online summer programming to how teachers incorporate Portal 2 in their classrooms.
                </p>
                <h3>
                Conference highlight: Northwest Council for Computer Education Research
                </h3>
                <p>
                Researchers from foundry10 presented at the Northwest Council for Computer Education (NCCE) in both 2020 and 2021. NCCE is an educator-oriented regional conference that aims to lead, engage, and assist educational communities to reach higher levels of student and educator success through the use of 21st century technology. In our most recent presentation, we used our prior research and experience to provide suggestions and examples for how to collect valuable feedback from students when integrating emerging technology in the classroom.
                    <br />
                    <br />
                Media: <a href="https://conference.ncce.org/2021/program/search/search.php" target="_blank" rel="noopener noreferrer">NCCE</a>
                </p>
            </div>
            <div className="annualReport2021research-sharing-stakeholders-container">
              <div className="annualReport2021research-sharing-banner-container" id="annualReport2021research-sharing-banner-communities">
                <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Research+Landing+Page/stakeholders_1-8.png" alt="Sharing Research Back With Stakeholders and Communities" />
                <h2>Sharing Research Back With Stakeholders and Communities</h2>
              </div>
              <p>In addition to sharing at conferences and in academic journals, the Research team also prioritizes sharing data back with the people and communities who participated in the research. In 2020-2021, we created shareback reports, talked through the results, and collaborated to make sense of our research findings with community partners like Space Between, Mariner High School, Brier Terrace Middle School, and more.</p>
            </div>
            <div className="annualReport2021research-sharing-published-container">
              <div className="annualReport2021research-sharing-banner-container" id="annualReport2021research-sharing-banner-published">
                <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Research+Landing+Page/papers-8.png" alt="foundry10 Published Papers" />
                <h2>foundry10 Published Papers</h2>
              </div>
              <div className="annualReport2021research-sharing-published-papers-container">
                <div className="annualReport2021research-sharing-published-paper-individ">
                  <div className="annualReport2021research-sharing-published-paper-img-container">
                    <a href="https://www.sciencedirect.com/science/article/abs/pii/S0160289620300283" target="_blank" rel="noopener noreferrer">
                      <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Research+Landing+Page/gamereport-8.png" alt="game report" />
                    </a>
                  </div>
                  <div className="annualReport2021research-sharing-published-paper-text-container">
                    <h2>Variations in psychological factors and experience-dependent changes in team-based video game performance</h2>
                    <p>In the present study, the degree to which psychological factors interacted with accumulated experience with regard to skilled performance was examined within a team-based activity. Longitudinal models revealed that performance improved with the number of matches played, with the rate of improvement affected by how teammates were selected and individual differences in specific psychological measures, number processing ability, fluid intelligence, theory of mind ability, and neuroticism. In line with previous research, these findings indicated that psychological factors, in addition to experience, contributed to the development of skilled performance within a team-based activity.</p>
                    <p className="annualReport2021research-sharing-published-paper-citation">Bonny, J. W., Scanlon, M., & Castaneda, L. M. (2020). Variations in psychological factors and experience-dependent changes in team-based video game performance. Intelligence, 80, 101450.</p>
                  </div>
                </div>
                <div className="annualReport2021research-sharing-published-paper-individ">
                  <div className="annualReport2021research-sharing-published-paper-text-container">
                    <h2>“Here I can just be myself”: How youth and adults collaboratively develop an identity-safe community across difference</h2>
                    <p>The aim of this study was to investigate the development of identity safety—where all participants are valued, included, and can engage without fear of stigmatization—among underrepresented youth and adults in a community‐based youth development program. Data analysis revealed that participants developed identity safety through engaging in programmatic activities that explored youth's identities, practicing authenticity in daily interactions, and facilitating dynamic communication across intergenerational friendships. Participants described sustaining identity safety through formal social support spaces, mutual support in group settings, and peer support. Ultimately, these experiences set the foundation for youth and adults to engage in positive risk‐taking and self‐reflection.</p>
                    <p className="annualReport2021research-sharing-published-paper-citation">Rubin, J. D., Scanlon, M., Cechony, A., & Chen, K. (2021). “Here I can just be myself”: How youth and adults collaboratively develop an identity‐safe community across difference. Journal of Community Psychology.</p>
                  </div>
                  <div className="annualReport2021research-sharing-published-paper-img-container">
                    <a href="https://onlinelibrary.wiley.com/doi/10.1002/jcop.22526" target="_blank" rel="noopener noreferrer">
                      <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Research+Landing+Page/identity+report-8.png" alt="identity report" />
                    </a>
                  </div>
                </div>
                <div className="annualReport2021research-sharing-published-paper-individ">
                  <div className="annualReport2021research-sharing-published-paper-img-container">
                    <a href="https://dl.acm.org/doi/abs/10.1145/3459990.3460693" target="_blank" rel="noopener noreferrer">
                      <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Research+Landing+Page/Tablet+apps+report-8.png" alt="tablet apps report" />
                    </a>
                  </div>
                  <div className="annualReport2021research-sharing-published-paper-text-container">
                    <h2>“Let’s Draw Chocolate Milk”: Supports and Challenges for Families’ Joint Engagement with Artistic Tablet Apps</h2>
                    <p>Although research shows that children benefit from co-using digital media with caregivers, there is more to learn about how interactive touchscreen apps support joint media engagement (JME) and how families interact with design features that support JME. We reviewed and condensed JME design recommendations and then evaluated currently available apps according to these guidelines. Apps that support artistic creation (i.e., music, drawings) emerged as fulfilling many extant design recommendations for JME. We highlight key challenges to JME plus design supports and parent strategies to help families jointly engage and create with media.</p>
                    <p className="annualReport2021research-sharing-published-paper-citation">Bindman, S. W., Sobel, K., Behbakht, S. M., Divanji, R. A., Shahn, E., & Scanlon, M. (2021, June). “Let’s Draw Chocolate Milk Together”: Supports and Challenges for Families’ Joint Engagement with Artistic Tablet Apps. In Interaction Design and Children (pp. 183-195).</p>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    );
  }
}

