import React, { Component } from 'react';
import './fourB.css';

export default class FOURB extends Component {
  render() {
    return (
      <div id="fourB-container">
        <div class="everyday-section-title-fourB">
          <h4>QUOTE (STUDENT ARTISTS)</h4>
        </div>
        <div class="fourB-content-container">
          <div class="fourB-image-container">
            <div class="fourB-image-one">
              <img src="https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/Artist-Quote-1.png" alt="quote one"/>
            </div>
            <div class="fourB-image-two">
              <img src="https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/Artist-Quote-2.png" alt="quote two"/>
            </div>
            <div class="fourB-image-three">
              <img src="https://s3-us-west-2.amazonaws.com/f10-image/Everyday+Unseen+/Artist-Quote-3.png" alt="quote three"/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}