import React, { useState } from 'react';
import './Accordion.css';

function Accordion({ lesson }) {
    const [isActive, setIsActive] = useState(false);

    return (
        <div className="accordion-item">
            <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
                <div>{lesson.number}</div>
                <div>{lesson.title}</div>
                <div>{lesson.week}</div>
                <div>{lesson.points}</div>
                <div>{lesson.dueDate}</div>
                <div>{isActive ? '-' : '+'}</div>
            </div>

            {isActive && <div className="accordion-content">
            
                <div className="accordion-overview">
                    <h2>{lesson.overview.title}</h2>
                    <p>{lesson.overview.content}</p>
                </div>

                <div className="accordion-objective">
                    <h2>{lesson.objective.title}</h2>
                    <p>{lesson.objective.content}</p>
                </div>  

                <div className="accordion-outcome">
                    <h2>{lesson.outcome.title}</h2>
                    <ul>
                    {lesson.outcome.bullets.map((bullet, index) => <p key={index}><li>{bullet}</li></p>)}
                    </ul>
                </div>

                <div className="accordion-sAndS">
                    <h2>{lesson.sAndS.title}</h2>
                    <p>{lesson.sAndS.content}</p>
                </div>

                <div className="accordion-setup">
                    <h2>{lesson.setup.title}</h2>
                    <ol>
                    {lesson.setup.steps.map((step, index) => <p key={index}><li>{step}</li></p>)}
                    </ol>
                </div>

                <div className="accordion-instructions">
                    <h2>{lesson.instructions.title}</h2>
                    <div className="accordion-instructions-steps">
                        <ol>
                        {lesson.instructions.steps.map((step, index) => 
                        <p key={index}><li>{step[0]}</li><img src={step[1]} alt="screencap visualizing step instruction"/></p>)}
                        </ol>
                    </div>
                </div>

                <div className="accordion-endNotes">
                    <h2>{lesson.endNotes.title}</h2>
                    <p>{lesson.endNotes.content}</p>
                    <div className="accordion-endNotes-links">
                        <ul>
                        {lesson.endNotes.links.map((link, index) => 
                        <p key={index}><li><a href={link[1]}  target="_blank" rel="noopener noreferrer">{link[0]}</a></li></p>)}
                        </ul>
                    </div>
                </div>

                <h2>Make sure to save your work frequently!</h2>


            </div>}

        </div>
    );
}

export default Accordion;