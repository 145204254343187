import React, { Component } from 'react';
import October21PageDescription from './october21-Description';
import October21HighlightZero from './october21-HighlightZero';
import October21HighlightOne from './october21-HighlightOne';
import October21HighlightTwo from './october21-HighlightTwo';
import October21HighlightThree from './october21-HighlightThree';
// import October21HighlightFour from './october21-HighlightFour';
import October21TeamContent from './october21-TeamContent';
import October21NewsletterLink from './october21-NewsletterLink';
import NewFooter from '../../pages-other/NewFooter';
import './october21-Content.css';


export default class october21PageContent extends Component {
  render() {
    return(
      <div id="october21Page-content-container">
        <October21PageDescription />
        <October21HighlightOne />
        <October21HighlightTwo />
        <October21HighlightThree />
        <October21HighlightZero />
        {/* <October21HighlightFour /> */}
        <October21TeamContent />
        <October21NewsletterLink />
        <NewFooter />
      </div>
    );
  }
}