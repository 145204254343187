import React, { Component } from 'react';
import './PhilanthropyDirector-Apply.css';

export default class PhilanthropyDirectorApply extends Component {
  render() {
    return(
      <div id="philanthropyDirectorApply-container">
        <div class="philanthropyDirectorApply-content-container">
          <h2>Join Our Team!</h2>
          <p>To be considered for this position, please submit your resume with a cover letter to <a href="mailto:jobs@foundry10.org">jobs@foundry10.org</a>.  We are committed to diversity, equity, and inclusion at foundry10, and we welcome you to submit a video cover letter if that format better reflects your voice and strengths. However, since this position will require written communication, we ask that you also include a short writing sample (one page or less) if you choose to submit a video cover letter.</p>
        </div>
      </div>
    );
  }
}