import React, { Component } from 'react';
import { Facebook, Twitter } from 'react-sharingbuttons'

const PullQuote = ({ block }) => {
  const quote = block.pullQuote;
  const attribution = block.quoteAttribution;
  const image = block.quoteImage;
  // console.log(image);

  const SharingButtons = () => {
    const url = 'https://found10-staging.herokuapp.com/'
    const shareText = block.quoteSocial

    if (shareText) {
      return (
        <div className='quoteSocial'>
          <Facebook url={url} />
          <Twitter url={url} shareText={shareText} />
        </div>
      )
    }

    return null;
  }
  

  
  return (
    <section class="contentBlock pullQuote">
      <div className="container">
        <div className="content">
          <blockquote className={`text-medium rich-text`}>
            <div
              dangerouslySetInnerHTML={{
                __html: quote,
              }}
            ></div>
          </blockquote>
          {attribution && (
            <footer>
              <div className='quoteImage'>
                {image[0] && (
                  <img src={image[0].url} alt="Quote Attribution" />
                )}
                </div>
              <div
              dangerouslySetInnerHTML={{
                __html: attribution,
              }}
            ></div>
            <SharingButtons />
            </footer>
          )}
        </div>
      </div>
    </section>
  )
}

export default PullQuote