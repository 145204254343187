import React, { Component } from 'react';

const Video = ({ block }) => {
  const title = block.videoTitle;
  const videoUrl = block.videoLink;
  const videoCaption = block.videoCaption;
  const image = block.videoImage[0]

  return (
    <section className='videoEmbed'>
      <div className='container'>
        {title && (
          <h3 className='text-xxl_bold emphHeader'>
            <span>{title}</span>
          </h3>
        )}
        {videoCaption && (
          <p className='text-body'>
            {videoCaption}
          </p>
        )}
        {image
          ? <a href={block.videoLink}><img src={image.url} alt={title} /></a>
          : <iframe width="100%" height="515" src={block.videoLink} title={title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        }
        
      </div>
    </section>
  )
}

export default Video