import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import AnnualReportGamesLearningHeader from './annualReport2021GamesLearning-Header';
import AnnualReportGamesLearningContent from './annualReport2021GamesLearning-Content';
import './annualReport2021GamesLearning.css';

export default class AnnualReport2021GamesLearning extends Component {
  render() {
    return(
      <div id="annualReport2021GamesLearning-container">
        <Helmet>
          <title>foundry10 Annual Report 2021-2022: Games and Learning</title>
          <meta
            name="title"
            content="foundry10 Annual Report 2021-2022: Games and Learning"
          />
          <meta
            name="description"
            content="Explore the foundry10 Annual Report 2021 for insights into our Games & Learning work. Learn more about our efforts in research, programming, and philanthropy."
          />
          <meta property="og:title" content="foundry10 Annual Report 2021-2022: Games and Learning" />
          <meta
            property="og:description"
            content="Explore the foundry10 Annual Report 2021 for insights into our Games & Learning work. Learn more about our efforts in research, programming, and philanthropy."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/annual-report/2021/programs/games-learning" />
          <link rel="canonical" href="https://www.foundry10.org/annual-report/2021/programs/games-learning" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Annual Report 2021-2022: Games and Learning",
                "description": "Explore the foundry10 Annual Report 2021 for insights into our Games & Learning work. Learn more about our efforts in research, programming, and philanthropy.",
                "url": "https://www.foundry10.org/annual-report/2021/programs/games-learning"
              }
            `}
          </script>
        </Helmet>
        <AnnualReportGamesLearningHeader />
        <AnnualReportGamesLearningContent />
      </div>
    );
  }
}