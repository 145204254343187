import React from "react";
import StandardContent from "../../../components/blocks/StandardContent";
import ImageContent from "../../../components/blocks/ImageContent";
import LinkList from "../../../components/blocks/LinkList";
import Emphasized from "../../../components/blocks/Emphasized";

const BottomInlineContent = ({ entry }) => {
  return entry.contentOptions.map((block, index) => (
    <React.Fragment key={index}>
      {block.__typename === "contentOptions_standardContent_BlockType" && (
        <StandardContent block={block} />
      )}
        {block.__typename === "contentOptions_icp_BlockType" && (
        <ImageContent block={block} />
        )}
      {block.__typename === "contentOptions_linkList_BlockType" && (
        <LinkList block={block} />
      )}
      {block.__typename === "contentOptions_emphasized_BlockType" && (
        <Emphasized block={block} />
      )}
    </React.Fragment>
  ));
};

const TMILBottomContentBlocks = ({ data }) => {
  return (
    <div className="contentBlocks-container">
      <BottomInlineContent entry={data} />
    </div>
  );
};

export default TMILBottomContentBlocks;
