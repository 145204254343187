// Stories, In The News, Resources and Publications Archive
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import {
    useQuery,
    gql
  } from "@apollo/client";
import PageHeader from '../components/page-header.js';
import NewsletterList from '../components/newsletter-list.js';
import PreFooter from '../components/pre-footer.js';
import NewFooter from '../../pages-other/NewFooter.js';


// Get Page content
const entryQuery = gql`
    query pageQuery($slug: [String]) {
      entries(slug: $slug) {
        title
        ... on newslettersArchive_newslettersArchive_Entry {
          blurb
          guidingPrinciples {
            ... on guidingPrinciples_BlockType {
              guidingPrinciplesPrinciple
            }
          }
          footerQuote
          footerQuoteAttribution
          footerQuoteBg {
            url
          }
          footerCTA {
            ... on footerCTA_BlockType {
              footerCTATitle
              footerCTAContent
              footerCTALinkUrl {
                url
                customText
                target
              }
            }
          }
        }
      }
    }
`

const PageRender = (slug) => {
  const { data, loading, error } = useQuery(entryQuery, {
      variables: {slug}
  });
  if (loading) return null;
  if (error) return `Error! ${error}`;
  
  console.log(data);

  return(
    data.entries.map(
      (block, index) => {    
        const preFooter = block.guidingPrinciples;
        if(typeof(preFooter) ) {
          return (
            <PreFooter key={index} data={data}/>
          );
        }
        switch (block) {
          case 'guidingPrinciples':
            return (
              <PreFooter key={index} block={block} />
            )
          case 'blurb':
              return (
                <div key={index}>
                  There's a blurb
                </div>
              )
          }
      }
    )
  );
}

export default class ContentArchive extends Component {
  render() {
    // console.log('props for content archive');
    // console.log(this.props);
    return(
      <section id="contentArchive">
             <Helmet>
                  <title>foundry10 - Newsletters Archive</title>
                  <meta
                    name="title"
                    content="foundry10 - Newsletters Archive"
                  />
                  <meta
                    name="description"
                    content="Explore our past newsletters for insights into founry10's education research, resources, and philanthropic endeavors."
                  />
                  <meta property="og:title" content="foundry10 - Newsletters Archive" />
                  <meta
                    property="og:description"
                    content="Explore our past newsletters for insights into founry10's education research, resources, and philanthropic endeavors."
                  />
                  <meta property="og:type" content="website" />
                  <meta property="og:url" content="https://www.foundry10.org/news/newsletters" />
                  <link rel="canonical" href="https://www.foundry10.org/news/newsletters" />
                  {/* Structured Data Script Tag */}
                  <script type="application/ld+json">
                  {`
                    {
                      "@context": "https://schema.org",
                      "@type": "WebPage",
                      "name": "foundry10 - Newsletters Archive",
                      "description": "Explore our past newsletters for insights into founry10's education research, resources, and philanthropic endeavors.",
                      "url": "https://www.foundry10.org/news/newsletters"
                    }
                  `}
                  </script>
            </Helmet>
        <PageHeader slug={this.props.slug} />
        <NewsletterList />
        <PageRender slug={this.props.slug} />
        <NewFooter />
      </section>
    );
  }
}