import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { styled } from '@material-ui/core/styles';
import { string } from 'prop-types';

const ContentTable = ({ block }) => {
  if (!block.table || block.table.length === 0) {
    return null;
  }
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${string}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${string}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const ColumnTitle = {
    col1:"Column Title1",
    col2:"Column Title2",
    col3:"Column Title3",
  }

  return (
    <section className='contentTable'>
      <div className="container">
        <TableContainer component={Paper} className="content-table-pc">
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                {Object.keys(block.table[0]).map((key, idx)=>{
                  if (idx > 0) {
                    return (
                      <StyledTableCell align="left">{ColumnTitle[key]}</StyledTableCell>
                    )
                  }
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {block.table.map((row, index) => (
                <StyledTableRow key={index}>
                  {Object.keys(row).map((key, idx)=>{
                    if (idx > 0) {
                      return (
                        <StyledTableCell align="left">{row[key]}</StyledTableCell>
                      ) 
                    } 
                  })}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer component={Paper} className="content-table-mobile">
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {block.table.map((row, index) => (
                Object.keys(row).map((key, idx)=>{
                  if (idx > 0) {
                    return (
                      <StyledTableRow key={index + '-' + idx}>
                        <StyledTableCell align="left">
                          <div className="mobile-col-title">{ColumnTitle[key]}</div>
                          <div className="mobile-col-val">{row[key]}</div>
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                  }
                })
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </section>
  )
}

export default ContentTable