import React, { Component } from 'react';
import './researcherResources-Stories.css';

export default class ResearcherResourcesStories extends Component {
  render() {
    return(
      <div id="researcherResources-stories-container">
        <div className="researcherResources-stories-content-container">
          <h2 className="researcherResources-stories-title"><span>Featured Stories for Researchers</span></h2>
          <div className="researcherResources-stories-card-container">
            <div className="researcherResources-stories-grid-container">
              <div className="researcherResources-stories-columnOne">
                {/* MAIN STORY CARD */}
                <div className="researcherResources-stories-columnOne-highlightCard">
                  <div className="researcherResources-stories-columnOne-image">
                    {/* <div className="researcherResources-stories-columnOne-category-tag">RESEARCH NEWS</div> */}
                    <img src="https://f10-resources.s3.us-west-2.amazonaws.com/Resources+for+Researchers/Research+Partnership+with+Game+to+Grow.png" alt="young kid on skateboard jumps off ramp over two other young kids"/>
                  </div>
                  <div className="researcherResources-stories-columnOne-info"> 
                    <div className="researcherResources-stories-tags">
                      <div className="researcherResources-stories-tagCategory">BLOG</div>
                      {/* <div className="researcherResources-stories-hash">
                        <div className="researcherHash-stories-teal">GAME</div>
                        <div className="researcherHash-stories-hash">#SEL #Trades</div>
                      </div> */}
                    </div>
                    <div className="researcherResources-stories-columnOne-text">
                      <h2>Research Partnership with Game to Grow featured on KING 5 News!</h2>
                      <p>A new report from foundry10 and Game to Grow on how TTRPGs like D&D can support social emotional learning was featured on the local evening news.</p>
                    </div>
                    <div className="researcherResources-stories-columnOne-link">
                      <p>Mar 9th, 2023</p>
                      <a href='https://medium.com/the-foundry10-voice/research-partnership-with-game-to-grow-featured-on-king-5-news-d24cd4d4a1b1' className="researcherResources-stories-columnOne-content-link" target="_blank" rel="noopener noreferrer"><i class="fa-solid fa-arrow-right"></i></a>
                    </div>
                  </div>
                </div>
                {/* MAIN STORY CARD END */}
              </div>
              <div className="researcherResources-stories-columnTwo">
                <div className="researcherResources-stories-columnTwo-container">
                  {/* FIRST CARD */}
                  <div className="researcherResources-stories-columnTwo-highlightCard">
                    <div className="researcherResources-stories-columnTwo-image">
                      {/* <div className="researcherResources-stories-columnTwo-category-tag">PRESS RELEASE</div> */}
                      <img src="https://f10-resources.s3.us-west-2.amazonaws.com/Resources+for+Researchers/6+Soft+Skills+to+Teach+for+a+Career+in+Todays+Trades.png" alt="group of researcher dancers perform on stage at beyond boundaries seven"/>
                    </div>
                    <div className="researcherResources-stories-columnTwo-info"> 
                      <div className="researcherResources-stories-tags">
                        <div className="researcherResources-stories-tagCategory">BLOG</div>
                        {/* <div className="researcherResources-stories-hash">
                          <div className="researcherHash-stories-hash">#SEL #Trades</div>
                        </div> */}
                      </div>
                      <div className="researcherResources-stories-columnTwo-text">
                        <h2>6 Soft Skills to Teach for a Career in Today’s Trades</h2>
                        {/* <p>Why peer support is key for teens' social-emotional learning, confidence, and academic performance - and how researchers can help foster it.</p> */}
                      </div>
                      <div className="researcherResources-stories-columnTwo-link">
                        <p>Feb 14th, 2023</p>
                        <a href='https://medium.com/the-foundry10-voice/6-soft-skills-to-teach-for-a-career-in-todays-trades-e8bdbaba7952' className="researcherResources-stories-columnTwo-content-link" target="_blank" rel="noopener noreferrer"><i class="fa-solid fa-arrow-right"></i></a>
                      </div>
                    </div>
                  </div>
                  {/* SECOND CARD */}
                  <div className="researcherResources-stories-columnTwo-highlightCard">
                    <div className="researcherResources-stories-columnTwo-image">
                      <img src="https://f10-resources.s3.us-west-2.amazonaws.com/Resources+for+Researchers/Making+Math+Fun+for+Early+Learners.png" alt="young male intern smiling in yellow sweater"/>
                    </div>
                    <div className="researcherResources-stories-columnTwo-info"> 
                      <div className="researcherResources-stories-tags">
                        <div className="researcherResources-stories-tagCategory">BLOG</div>
                        {/* <div className="researcherResources-stories-hash">
                          <div className="researcherHash-stories-hash">#SEL #Trades</div>
                        </div> */}
                      </div>
                      <div className="researcherResources-stories-columnTwo-text">
                        <h2>Making Math Fun for Early Learners: Researchers in Conversation</h2>
                        {/* <p>Why peer support is key for teens' social-emotional learning, confidence, and academic performance - and how researchers can help foster it.</p> */}
                      </div>
                      <div className="researcherResources-stories-columnTwo-link">
                        <p>Feb 9th, 2023</p>
                        <a href='https://medium.com/the-foundry10-voice/making-math-fun-for-early-learners-researchers-in-conversation-780dae2806d5' className="researcherResources-stories-columnTwo-content-link" target="_blank" rel="noopener noreferrer"><i class="fa-solid fa-arrow-right"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="researcherResources-stories-bottomLink">
              <a href='https://medium.com/the-foundry10-voice' className="researcherResources-stories-content-bottomLink" target="_blank" rel="noopener noreferrer">Explore more stories from our blog<i className="fa-solid fa-arrow-right-long"></i></a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}