import React, { Component } from 'react';
import './twoB.css';
import lottie from 'lottie-web';

class TWOB extends Component {
  componentDidMount() {
    lottie.loadAnimation({
      container: document.getElementById("twoB"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "/svg/2c_20artist.json"
    });
  }
  render() {
    return (
      <div id="twoB-container">
        <div class="everyday-section-title">
          <h4>TWO (THE ARTISTS)</h4>
        </div>
        <div class="twoB-content-container">
          <div class="twoB-text-container">
            <h2>If we built it, would they come?</h2>
            <p>foundry10 had never done this type of event. Would our gallery even get any applications?</p>
            <p>We reached out to community centers and libraries and before we knew it...we had 20 artists! Yay!</p>
          </div>
          <div class="twoB-svg-container">
            <div id="twoB" class="twoB-svg-content"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default TWOB;