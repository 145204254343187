import React, { Component } from 'react';
import './ia-seattleIntro.css';
import lottie from 'lottie-web';

class SeattleIntro extends Component {
  componentDidUpdate() {
    lottie.loadAnimation({
      container: document.getElementById("seattleIntro"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "/svg/Ia-seattle.json"
    });
  }
  render() {
    return (
      <div id="seattleIntro-container">
        <div class="everyday-section-title-start">
          <h4>OUR STORY (EVERYDAY UNSEEN)</h4>
        </div>
        <div class="seattleIntro-content-container">
          <div id="seattleIntro" class="seattleIntro-svg-content"/>
        </div>
      </div>
    );
  }
}

export default SeattleIntro;