import React, { Component } from 'react';
import AnnualReportSharingOverview from './annualReport2021Sharing-Overview';
import AnnualReportSharingHighlights from './annualReport2021Sharing-Highlights';
import AnnualReportLinks from '../annualReport2021_Components/10_Links/AnnualReport-Links';
import NewFooter from '../../../pages-other/NewFooter';
import './annualReport2021Sharing-Content.css';

export default class AnnualReportSharingContent extends Component {
  render() {
    return(
      <div id="annualReport2021sharing-content-container">
        <AnnualReportSharingOverview />
        <AnnualReportSharingHighlights />
        <AnnualReportLinks />
        <NewFooter />
      </div>
    );
  }
}