import React, { Component } from 'react';
import './annualReport2022.css';
import NewFooter from '../../pages-other/NewFooter';

export default class AnnualReport2022 extends Component {

  render() {
      return (
        <div id="annualReport2022-main-container">
          {/* HEADER */}
          <div class="annualReport2022-header-container" style={{backgroundImage: `url(https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2022/2022+Annual+Report+Alternate+Page+Header.jpg`}}>
            <div class="annualReport2022-header-content-container">
              <h1>Firming Up Foundations and Forging Ahead: 2021-2022 Annual Report</h1>
            </div>
          </div>
          {/* BODY */}
          <div id="annualReport2022-main-content-container">
            {/* BRIEF & DOCUMENT */}
            <div id="annualReport2022-briefDoc-container">
              <div class="annualReport2022-briefDoc-content-container">
                <div class="annualReport2022-brief-container">
                  <div class="annualReport2022-brief-description-container">
                    <p>This report celebrates foundry10’s key contributions, collaborations, and impacts within education and learning spaces in 2021-2022.</p>
                  </div>
                </div>
                <div class="annualReport2022-document-container">
                    <p>Read the report here!</p>
                    <div className='research-documentViewerHolder'>
                        <a className='research-documentViewerEmbed' 
                            target='_blank' 
                            rel ="noopener noreferrer"
                            href='https://drive.google.com/file/d/1Y2sD4pH0-IoO4plCAZuMSTMLvVYsg7Cn/view?usp=sharing' 
                            alt='firming up foundations and forging ahead.  foundry10 fy2021-2022 annual report'
                            >
                            <img class={document.name} alt='fy2021-2022 annual report' longdesc='firming up foundations and forging ahead.  foundry10 fy2021-2022 annual report' src='https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2022/2021-2022+Annual+Report_130x200+Thumbnail.jpg' />
                            <span class="research-documentViewerTitleNew">
                                <div class="research-documentViewerTitleNew2">FY2021-2022 Annual Report</div>
                            </span>
                        </a>
                    </div>
                </div>
              </div>
            </div>
            {/* DESCRIPTION */}
            <div id="annualReport2022-description-container">
              <div class="annualReport2022-description-content-container">
                {/* <h4>November 2022</h4> */}
                <p>Within this report, you’ll find stories and highlights from foundry10’s research, educational programming, and philanthropic collaborations in 2021-2022, including quotes from youth participants and detailed looks at projects and the communities we’ve served—and continue to serve. Across these stories, you will learn about the many ways our organization has found firmer footing in this time of change and has, simultaneously, taken great leaps forward. That balance is reflected by the theme for this year’s report, “Firming Up Foundations and Forging Ahead.”</p>
              </div>
            </div>
            <NewFooter />
          </div>
        </div>

      )
  }
}