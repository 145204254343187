import React from "react";
import './Ocean-Policy.css';

export default function OceanPolicy({ title, subtitle, dark, id }) {
  return (
    // <div className={"section" + (dark ? " section-dark" : "")}>
    <div className="oceanPolicy-container">
      <div className="oceanPolicy-content-container" id={id}>
        <div className="oceanPolicy-final-content-container">
          <div className="oceanPolicy-illustration">
            <h1>Click below to learn more!</h1>
            <a class="oceanPolicy-link" href="/marine-science-careers/ocean-policy">
              <img src='https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/ocean+policy1.png' alt='ocean policy illustration'/>
              <p class="oceanPolicy-title">Meet Tanya & Ryan!</p>
              <div class="oceanPolicy-cards-overlay"></div>
            </a>
          </div>
          <div className="oceanPolicy-description">
            <h2>"Speaker for the Seas"</h2>
            <p>RELATED TITLES: Ocean Policy Fellow, Campaign Organizer, Policy Manager, Legislative Aide</p>
            <p>Decision making and negotiating never end in this field where regulations on fishing must be balanced with economic concerns, where port improvements must be weighed by environmental stakeholders, and planning for future generations of people must always take into account the future of the oceans as well.</p>
            <div className="oceanPolicy-requirements">
              <h2>Basic Degree/Training Requirements</h2>
              <ul className="oceanPolicy-iconList">
                <li>
                  <div class="oceanPolicy-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/white-HS-Diploma.png" alt="high school diploma" />
                  </div>
                </li>
                <li>
                  <div class="oceanPolicy-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/white-Undergrad-Cap.png" alt="undergrad cap" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}