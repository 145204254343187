import React, { Component } from 'react';
import './fiveAB.css';
import lottie from 'lottie-web';

export default class FIVEAB extends Component {
  componentDidMount() {
    lottie.loadAnimation({
      container: document.getElementById("fiveAB"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "/svg/5a.json"
    });
  }
  render() {
    return (
      <div id="fiveAB-container">
        <div class="everyday-section-title">
          <h4>FIVE (THE END)</h4>
        </div>
        <div class="fiveAB-content-container">
          <div class="fiveAB-svg-container">
            <div id="fiveAB" class="fiveAB-svg-content"></div>
          </div>
          <div class="fiveAB-text-container">
            <h2>Yet, it had been so much more than that.</h2>
            <p>Along the way, we had learned about making space for art.</p>
            <p>We changed how people think about youth art and how young artists feel about their work.</p>
          </div>
        </div>
      </div>
    );
  }
}