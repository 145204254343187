import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import './Everyday-Unseen.css';
import EverydayHeader from './everydayHeader';
import EverydayContent from './Everyday-Content';

export default class EverydayUnseen extends Component {
  render() {
    return(
      <div id="everyday-unseen-container">
        <Helmet>
          <title>foundry10 Artistic Design Project: Everyday Unseen 2018</title>
          <meta
            name="title"
            content="foundry10 Artistic Design Project: Everyday Unseen 2018"
          />
          <meta
            name="description"
            content="Everyday Unseen: Celebrating Young Artists. Explore the impact of art in youth development and this professional approach to showcasing student artwork."
          />
          <meta property="og:title" content="foundry10 Artistic Design Project: Everyday Unseen 2018" />
          <meta
            property="og:description"
            content="Everyday Unseen: Celebrating Young Artists. Explore the impact of art in youth development and this professional approach to showcasing student artwork."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/everyday-unseen-2018" />
          <link rel="canonical" href="https://www.foundry10.org/everyday-unseen-2018" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Artistic Design Project: Everyday Unseen 2018",
                "description": "Everyday Unseen: Celebrating Young Artists. Explore the impact of art in youth development and this professional approach to showcasing student artwork.",
                "url": "https://www.foundry10.org/everyday-unseen-2018"
              }
            `}
          </script>
        </Helmet>
        <EverydayHeader />
        <EverydayContent />
      </div>
    );
  }
}
