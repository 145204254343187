import React, { Component } from 'react';
import './annualReport2021DigitalAudio-Spotlights.css';
import ReactPlayer from 'react-player';


export default class AnnualReportDigitalAudioSpotlights extends Component {
  render() {
    return(
      <div id="annualReport2021DigitalAudio-spotlights-container">
        <div className="annualReport2021DigitalAudio-spotlight-first-title-bar">
          <p>Spotlight: BandLab Curriculum for Remote Learning</p>
        </div>
        <div className="annualReport2021DigitalAudio-spotlights-content-container">
          <div className="annualReport2021DigitalAudio-spotlights-text-container">
            <div className="annualReport2021DigitalAudioVideoCard-component-container">
                <div className="annualReport2021DigitalAudioVideoCard-container">
                  <ReactPlayer
                  // BandLab Video
                  url='https://www.youtube.com/watch?v=ljh88c-bzMo'
                  className='annualReport2021DigitalAudio-react-player'
                  //   playing
                  controls
                  width='100%'
                  height='100%'
                  />
                </div>
              </div>
            <p>
            Education has shifted massively this year, and remote learning has been at the forefront of how students receive instruction. These urgent changes gave us the opportunity to explore online digital audio workstation (DAWs) tools like BandLab. This flexible online tool made it possible for students to receive instruction in a variety of ways, even if the only piece of technology they had access to was their phone. BandLab became an immediate asset in our classroom, as it is a browser-based Mix Editor that did not require downloading or installing software to students’ computers, and the educator platform made assigning work and receiving student submissions a breeze. Looking to the future, BandLab will continue to be a DAW of choice for us when planning classes and programs.
            <br /> <br />
            <a href="https://youtu.be/ljh88c-bzMo" target="_blank" rel="noopener noreferrer">VOICES: BandLab</a> - <a href="https://drive.google.com/file/d/1z8lzDQ959OUOCljrBeRYbjGv_gzNhuZF/view" target="_blank" rel="noopener noreferrer">BandLab Curriculum</a>
            </p>
          </div>
        </div>
        <div className="annualReport2021DigitalAudio-spotlight-second-title-bar">
          <p>Spotlight: Podcasting Under Quarantine</p>
        </div>
        <div className="annualReport2021DigitalAudio-spotlights-content-container">
          <div className="annualReport2021DigitalAudio-spotlights-text-container">
            <div className="annualReport2021DigitalAudioVideoCard-component-container">
                <div className="annualReport2021DigitalAudioVideoCard-container">
                  <ReactPlayer
                  // Steep Stairs Podcast Video
                  url='https://youtu.be/2fnxKNv4e_A'
                  className='annualReport2021DigitalAudio-react-player'
                  //   playing
                  controls
                  width='100%'
                  height='100%'
                  />
                </div>
              </div>
            <p>Through the chaos of the early days of the pandemic, the <a href="https://www.steepstairspress.com/home" target="_blank" rel="noopener noreferrer">Steep Stairs Press</a> Podcast program at Scriber Lake High School was born. Scriber Lake already had school-issued Chromebooks for their students, and together we bridged the disconnect and produced our first podcast episode titled <span className="annualReport2021DigitalAudio-spotlights-text-emphasis">“Scriber Under Quarantine”</span>. After a successful pilot, we came back for Fall 2020 with a new group of podcasters and continued to explore, discuss, and podcast about topics important to youth. Students wove their experiences together with stories written by authors in the Steep Stairs Press writing program and were inspired to contribute their own voices along the way.
            <br /> <br />
            Click here to listen to the most recent episode of the Steep Stairs Podcast on <a href="https://www.youtube.com/channel/UCP32F9fgck8Fwa0xU4os29w/featured" target="_blank" rel="noopener noreferrer">YouTube</a> and <a href="https://open.spotify.com/show/5puqordx2ES4Ur44nhDuvl?si=qOo-Du6GSEeBvXg75tu3GQ" target="_blank" rel="noopener noreferrer">Spotify</a>!
            </p>
          </div>
        </div>
      </div>
    );
  }
}