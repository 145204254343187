import React, { Component } from 'react';
import OurMission from './ourMission';
import ArtistTeam from './artistTeam';
import NiiModo from './niiModo';
import EverydayUnseenStory from './everydayUnseenStory';
import NewFooter from '../../pages-other/NewFooter.js';
import './Everyday-Content.css';

class EverydayContent extends Component {
  render() {
    return(
      <div id="everyday-content-container">
          <OurMission />   
          <NiiModo />
          <EverydayUnseenStory />
          <ArtistTeam />
          <NewFooter />
      </div>
    );
  }
}

export default EverydayContent;