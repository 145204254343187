import React, { Component } from 'react';
import CostsAccordion from './accordions/vrStressTest-CostsAccordion';
import CostsData from './accordions/Costs-Data';
import ClassAccordion from './accordions/vrStressTest-ClassAccordion';
import ClassData from './accordions/Class-Data';
import QualityAccordion from './accordions/vrStressTest-QualityAccordion';
import QualityData from './accordions/Quality-Data';
import './vrStressTest-Accordion.css'

export default class VrStressTestAccordion extends Component {

  
  render() {

    return(
        <div className="vr-stress-test-accordion-section-container">
          <div className="vr-stress-test-accordion-content-container">
            <h2><span>Costs</span></h2>
            <div className="vr-stress-test-accordion-container">
              <div className="vr-stress-test-accordion-content">
                {CostsData.map(({ element }) => (
                  <CostsAccordion element={element} />
                  ))}
              </div>
          </div>
        </div>

        <div className="vr-stress-test-accordion-content-container">
            <h2><span>Class</span></h2>
            <div className="vr-stress-test-accordion-container">
              <div className="vr-stress-test-accordion-content">
                {ClassData.map(({ element }) => (
                  <ClassAccordion element={element} />
                  ))}
              </div>
          </div>
        </div>

        <div className="vr-stress-test-accordion-content-container">
            <h2><span>Quality</span></h2>
            <div className="vr-stress-test-accordion-container">
              <div className="vr-stress-test-accordion-content">
                {QualityData.map(({ element }) => (
                  <QualityAccordion element={element} />
                  ))}
              </div>
          </div>
        </div>

    </div>
    );
  }
}