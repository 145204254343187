import React, { Component } from 'react';
import './returnToLearn-Header.css';

export default class ReturnToLearnHeader extends Component {
  render() {
    return(
      <div id="returnToLearn-header-container">
          <div class="returnToLearn-header-content-container">
            <h1>Return to Learn</h1>
          </div>
      </div>
    );
  }
}