import React, { Component } from 'react';
import './PhilanthropyDirector-Header.css';

export default class PhilanthropyDirectorHeader extends Component {
  render() {
    return(
      <div id="philanthropyDirector-header-container">
        <div class="philanthropyDirector-header-content-container">
          <h1>Director of Community and Philanthropic Relationships</h1>
        </div>
      </div>
    );
  }
}