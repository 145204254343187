import React, { Component } from 'react';
import './autoLandingPage-Header.css';

export default class AutoLandingPageHeader extends Component {
  render() {
    return(
      <div id="autoLandingPage-header-container">
          <div class="autoLandingPage-header-content-container">
            <h1>Automotive Technology</h1>
          </div>
      </div>
    );
  }
}