import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './newEquity-equityAccess.css';

export default class NewEquityAccess extends Component {
  render() {
    return (
      <div className="newEquityAccess-container">
        <div className="newEquityAccess-content-container">
          <h2>foundry10 Research On Equity and Access</h2>
          <div className="newEquityAccess-info-container">
            <div className="newEquityAccess-info-image">
              <img src="https://f10-image.s3.us-west-2.amazonaws.com/Test+Images/Equity-and-Access.png" alt="equity and access illustration"/>
            </div>
            <div className="newEquityAccess-info-text">
              <p>foundry10 researches equity and access to understand how educational pathways can present both barriers and opportunities for youth, and to learn how to create more equitable educational environments.</p>
              <Link to='/research/equity-and-access' className="newEquityAccess-link">Learn more<i className="fa-solid fa-arrow-right-long"></i></Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}