import React, { Component } from 'react';
import AnnualReportSTEMOverview from './annualReport2021STEM-Overview';
import AnnualReportSTEMSpotlights from './annualReport2021STEM-Spotlights';
import AnnualReport2021ProgramLinks from '../annualReport2021ProgramLinks/AnnualReport2021-ProgramLinks';
import NewFooter from '../../../../pages-other/NewFooter';
import './annualReport2021STEM-Content.css';

export default class AnnualReportSTEMContent extends Component {
  render() {
    return(
      <div id="annualReport2021STEM-content-container">
        <AnnualReportSTEMOverview />
        <AnnualReportSTEMSpotlights />
        <AnnualReport2021ProgramLinks />
        <NewFooter />
      </div>
    );
  }
}