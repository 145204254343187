import React, { Component } from 'react';
import './annualReport2021EdTech-Spotlights.css';

export default class AnnualReportEdTechSpotlights extends Component {
  render() {
    return(
      <div id="annualReport2021EdTech-spotlights-container">
        <div className="annualReport2021EdTech-spotlight-first-title-bar">
          <p>Spotlight: Connecting Granite Falls to Digital Devices</p>
        </div>
        <div className="annualReport2021EdTech-spotlights-content-container">
          <div className="annualReport2021EdTech-spotlights-text-container">
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Subpages/granitefalls.jpg" alt="students talking through a laptop computer"/>
            <p>
            Through conversations with school partners during the beginning of the pandemic, we consistently heard that the biggest barrier to student learning at home was access to digital devices and reliable internet. In 2020, we partnered with Granite Falls School District, located northeast of Seattle, to provide internet hotspots for families to access learning at home. Later in the school year, as more districts considered bringing students back into school buildings for in-person or hybrid learning, we supported the Granite Falls School District by providing funding for laptops and other portable computer accessories. District leadership conducted a survey of teachers to inform these decisions around technology-related professional development and education technology purchases in order to meet the most immediate needs that teachers were facing.
            </p>
          </div>
        </div>
        <div className="annualReport2021EdTech-spotlight-second-title-bar">
          <p>Spotlight: Student Voices on the Value of VR</p>
        </div>
        <div className="annualReport2021EdTech-spotlights-content-container">
          <div className="annualReport2021EdTech-spotlights-text-container">
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Subpages/vr1.jpg" alt="students wearing vr headsets"/>
            <p>One of our major areas of interest is student voice and feedback around the emerging technologies they utilize for learning. In addition to the <a href="http://www.foundry10.org/research/technology-and-learning" target="_blank" rel="noopener noreferrer">several years of data</a> we gathered from students across the country about their use of Virtual Reality (VR), we specifically asked for students and teachers to reflect on their learning and assessment practices after having used VR in school. It was eye-opening to see areas of alignment, where both students and teachers felt VR really added value by enhancing student understanding of challenging topics, as well as areas of misalignment, where teachers felt VR had value but students felt another technology or approach to learning may have been more useful. As one student said, “Using VR allowed us to utilize new vocabulary in a more realistic situation, which I feel made my brain make stronger connections of when to use those certain expressions and vocabulary in a real setting.” We were able to share the data from this research at educator conferences, as well as in the larger research community.
            </p>
          </div>
        </div>
      </div>
    );
  }
}