import React, { Component } from 'react';
import './vrLearningMap-Body.css';

export default class VrLearningMapBody extends Component {
  render() {
    return(
      <div id="vrLearningMapBody-container">
        <div class="vrLearningMapBody-content-container">
          <tableau-viz 
            id='tableau-viz' 
            src='https://public.tableau.com/views/VRLearningMap_2/VRMap?:language=en-US&publish=yes&:display_count=n&:origin=viz_share_link'
          >
          </tableau-viz>
        </div>
      </div>
    );
  }
}