import React, { Component } from 'react';
import './afterschoolLandingPage-Links.css';

export default class AfterschoolLandingPageLinks extends Component {
  render() {
    return(
      <div id="afterschoolLandingPage-links-container">
        <div class="afterschoolLandingPage-links-content-container">
          <div class="interestArea-cardsList2-container">
            <div class="interestArea-domain-title">
              <h2>Resources</h2>
            </div>
            <ul id="interestArea-cardsList2" class="interestArea-events-list2">
              <a href={'https://drive.google.com/file/d/1weYYlXyIHLmyUmQLd3SZmyvpGLv-RV9b/view?usp=sharing'} target="_blank">
               <li>
                <div class="afterschoolLandingPage-cards-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/stem/Mars+Landing+Rover+Challenge+Poster.png" alt="mars rover landing challenge poster" />
                  <p class="afterschoolLandingPage-cards-title">Mars Rover Landing - Poster</p>
                  <div class="afterschoolLandingPage-cards-overlay"></div>
                </div>
                </li>
              </a>
              <a href={'https://drive.google.com/file/d/1VqpxKyYINhKk9eYqVMMTilk-xv6yXtTb/view?usp=sharing'} target="_blank">
               <li>
                <div class="projectsLandingPage-cards-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/stem/Mars+Rover+Landing+Challenge.png" alt="mars rover landing challenge activity details" />
                  <p class="projectsLandingPage-cards-title">Mars Rover Landing - Activity Details</p>
                  <div class="projectsLandingPage-cards-overlay"></div>
                </div>
                </li>
              </a>
              <a href={'https://drive.google.com/file/d/14pEMzfW0sSlfTDgHncln-qa8lkCGLz8T/view?usp=sharing'} target="_blank">
               <li>
                <div class="afterschoolLandingPage-cards-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/stem/Engineering+Design+Process+Thumbnail.png" alt="the engineering design process - developing problem solving skills in a world full of challenges" />
                  <p class="afterschoolLandingPage-cards-title">The Engineering Design Process</p>
                  <div class="afterschoolLandingPage-cards-overlay"></div>
                </div>
                </li>
              </a>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}