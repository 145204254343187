import React, { Component } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import './charts.css'

HighchartsMore(Highcharts);

export default class BoxPlotSelfRegulated extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // To avoid unnecessary update keep all options in the state.
    chartOptions: {
        chart: {
          type: 'boxplot',
          // backgroundColor: '#007A8A',
      },

      title: {
        text: 'Self-Regulated Learning Scores',
        style: {
          color: '#00464F'
        }
      },

      legend: {
          enabled: false
      },

      xAxis: {
        categories: ['Planning', 'Monitoring', 'Evaluating','Reflecting','Effort'],
        title: {
          style: {
            color: '#00464F'
          },
          text: ''
          },
      },

      yAxis: {
          title: {
            style: {
              color: '#00464F'
            },  
            text: 'Score'
          },
          // plotLines: [{
          //     value: 932,
          //     color: 'red',
          //     width: 1,
          //     label: {
          //         text: 'Theoretical mean: 932',
          //         align: 'center',
          //         style: {
          //             color: 'gray'
          //         }
              // }
          // }]
      },
      //Data: An array of arrays with 6 or 5 values. In this case, the values correspond to x,low,q1,median,q3,high. If the first value is a string, it is applied as the name of the point, and the x value is inferred. The x value can also be omitted, in which case the inner arrays should be of length 5. Then the x value is automatically calculated, either starting at 0 and incremented by 1, or from pointStart and pointInterval given in the series options.
      plotOptions: {
        series: {
            colorByPoint: true
        }
    },
      colors: ['#007A8A','#D14E1D','#575757','#FFA75E','#00464F'],
      series: [{
          name: 'Observations',
          data: [
              ['Planning',1,2.667,3.167,3.5,5],
              ['Monitoring',1,3,3.444,3.889,5],
              ['Evaluating',1,3.375,3.75,4.125,5],
              ['Reflecting',1,3.5,3.8,4.2,5],
              ['Effort',1,2.926,3.519,3.963,5]
          ],
          style: {
            color: '#E77C53'
          },
          tooltip: {
              headerFormat: '<em>{point.key}</em><br/>'
          },
      }, {
          name: 'Data Points',
          color: Highcharts.getOptions().colors[0],
          type: 'scatter',
          jitter: {
            x: .24
          },
          data: [ // x, y positions where 0 is the first category
              [0, 1],[0, 1],[0, 1.33],[0, 1.50],[0, 1.50],[0, 1.67],[0, 1.67],[0, 1.67],[0, 1.83],[0, 2],[0, 2],[0, 2.17],[0, 2.17],[0, 2.17],[0, 2.33],[0, 2.33],[0, 2.33],[0, 2.33],[0, 2.50],[0, 2.50],[0, 2.67],[0, 2.67],[0, 2.67],[0, 2.67],[0, 2.67],[0, 2.67],[0, 2.83],[0, 2.83],[0, 2.83],[0, 2.83],[0, 2.83],[0, 2.83],[0, 2.83],[0, 2.83],[0, 3],[0, 3],[0, 3],[0, 3],[0, 3],[0, 3],[0, 3],[0, 3],[0, 3.17],[0, 3.17],[0, 3.17],[0, 3.17],[0, 3.17],[0, 3.17],[0, 3.17],[0, 3.17],[0, 3.17],[0, 3.33],[0, 3.33],[0, 3.33],[0, 3.33],[0, 3.33],[0, 3.33],[0, 3.33],[0, 3.33],[0, 3.50],[0, 3.50],[0, 3.50],[0, 3.50],[0, 3.50],[0, 3.50],[0, 3.50],[0, 3.50],[0, 3.50],[0, 3.67],[0, 3.67],[0, 3.67],[0, 3.67],[0, 3.67],[0, 3.67],[0, 3.67],[0, 3.67],[0, 3.83],[0, 3.83],[0, 3.83],[0, 3.83],[0, 3.83],[0, 3.83],[0, 4],[0, 4.17],[0, 4.17],[0, 4.17],[0,4.17],[0, 4.17],[0, 4.33],[0, 5],[0, null],

              [1, 1],[1, 1.44],[1, 1.67],[1, 1.89],[1, 2.11],[1, 2.11],[1, 2.11],[1, 2.11],[1, 2.33],[1, 2.33],[1, 2.33],[1, 2.56],[1, 2.56],[1, 2.56],[1, 2.56],[1, 2.78],[1, 2.78],[1, 2.78],[1, 2.78],[1, 2.78],[1, 2.78],[1, 2.78],[1, 3],[1, 3],[1, 3],[1, 3],[1, 3],[1, 3.22],[1, 3.22],[1, 3.22],[1, 3.22],[1, 3.22],[1, 3.22],[1, 3.22],[1, 3.22],[1, 3.44],[1, 3.44],[1, 3.44],[1, 3.44],[1, 3.44],[1, 3.44],[1, 3.44],[1, 3.44],[1, 3.44],[1, 3.44],[1, 3.44],[1, 3.67],[1, 3.67],[1, 3.67],[1, 3.67],[1, 3.67],[1, 3.67],[1, 3.67],[1, 3.67],[1, 3.67],[1, 3.67],[1, 3.67],[1, 3.67],[1, 3.67],[1, 3.67],[1, 3.67],[1, 3.67],[1, 3.89],[1, 3.89],[1, 3.89],[1, 3.89],[1, 3.89],[1, 3.89],[1, 3.89],[1, 3.89],[1, 4.11],[1, 4.11],[1, 4.11],[1, 4.11],[1, 4.11],[1, 4.11],[1, 4.33],[1, 4.33],[1, 4.33],[1, 4.33],[1, 4.56],[1, 4.56],[1, 4.56],[1, 4.56],[1, 4.56],[1, 4.56],[1, 4.77],[1, 4.77],[1, 5],[1, null],[1, null],

              [2, 1],[2, 2],[2, 2],[2, 2.125],[2, 2.375],[2, 2.5],[2, 2.5],[2, 2.5],[2, 2.75],[2, 2.875],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3.125],[2, 3.125],[2, 3.125],[2, 3.125],[2, 3.25],[2, 3.25],[2, 3.25],[2, 3.375],[2, 3.375],[2, 3.375],[2, 3.375],[2, 3.5],[2, 3.5],[2, 3.5],[2, 3.5],[2, 3.5],[2, 3.5],[2, 3.5],[2, 3.5],[2, 3.625],[2, 3.625],[2, 3.625],[2, 3.625],[2, 3.625],[2, 3.625],[2, 3.625],[2, 3.625],[2, 3.625],[2, 3.625],[2, 3.625],[2, 3.75],[2, 3.75],[2, 3.75],[2, 3.75],[2, 3.75],[2, 3.875],[2, 3.875],[2, 3.875],[2, 3.875],[2, 3.875],[2, 3.875],[2, 3.875],[2, 3.875],[2, 4],[2, 4],[2, 4],[2, 4],[2, 4],[2, 4],[2, 4],[2, 4],[2, 4.125],[2, 4.125],[2, 4.125],[2, 4.125],[2, 4.125],[2, 4.125],[2, 4.125],[2, 4.25],[2, 4.25],[2, 4.25],[2, 4.25],[2, 4.25],[2, 4.375],[2, 4.375],[2, 4.375],[2, 4.5],[2, 4.625],[2, 4.625],[2, 4.625],[2, 4.75],[2, 4.75],[2, 4.875],[2, 4.875],[2, 5],[2, 5],[2, null],

              [3, 1],[3, 1.4],[3, 2],[3, 2.6],[3, 2.6],[3, 2.8],[3, 2.8],[3, 3],[3, 3],[3, 3],[3, 3],[3, 3],[3, 3.2],[3, 3.4],[3, 3.4],[3, 3.4],[3, 3.4],[3, 3.4],[3, 3.4],[3, 3.4],[3, 3.4],[3, 3.4],[3, 3.4],[3, 3.6],[3, 3.6],[3, 3.6],[3, 3.6],[3, 3.6],[3, 3.6],[3, 3.6],[3, 3.6],[3, 3.6],[3, 3.6],[3, 3.6],[3, 3.6],[3, 3.6],[3, 3.6],[3, 3.6],[3, 3.6],[3, 3.6],[3, 3.6],[3, 3.8],[3, 3.8],[3, 3.8],[3, 3.8],[3, 3.8],[3, 3.8],[3, 3.8],[3, 3.8],[3, 3.8],[3, 3.8],[3, 3.8],[3, 3.8],[3, 3.8],[3, 3.8],[3, 4],[3, 4],[3, 4],[3, 4],[3, 4],[3, 4],[3, 4],[3, 4],[3, 4],[3, 4],[3, 4],[3, 4],[3, 4.2],[3, 4.2],[3, 4.2],[3, 4.2],[3, 4.2],[3, 4.2],[3, 4.2],[3, 4.2],[3, 4.2],[3, 4.2],[3, 4.2],[3, 4.2],[3, 4.4],[3, 4.4],[3, 4.4],[3, 4.4],[3, 4.4],[3, 4.6],[3, 4.6],[3, 4.6],[3, 4.6],[3, 4.8],[3, 5],[3, 5],

              [4, 1],[4, 1.15],[4, 1.74],[4, 1.89],[4, 2.04],[4, 2.04],[4, 2.04],[4, 2.19],[4, 2.19],[4, 2.19],[4, 2.33],[4, 2.48],[4, 2.48],[4, 2.48],[4, 2.63],[4, 2.63],[4, 2.63],[4, 2.63],[4, 2.63],[4, 2.78],[4, 2.78],[4, 2.78],[4, 2.93],[4, 2.93],[4, 2.93],[4, 2.93],[4, 2.93],[4, 2.93],[4, 2.93],[4, 2.93],[4, 2.93],[4, 2.93],[4, 2.93],[4, 2.93],[4, 2.93],[4, 3.07],[4, 3.07],[4, 3.07],[4, 3.07],[4, 3.22],[4, 3.22],[4, 3.37],[4, 3.37],[4, 3.37],[4, 3.37],[4, 3.52],[4, 3.52],[4, 3.52],[4, 3.52],[4, 3.52],[4, 3.52],[4, 3.67],[4, 3.67],[4, 3.67],[4, 3.67],[4, 3.67],[4, 3.67],[4, 3.67],[4, 3.67],[4, 3.81],[4, 3.81],[4, 3.81],[4, 3.81],[4, 3.81],[4, 3.81],[4, 3.81],[4, 3.96],[4, 3.96],[4, 3.96],[4, 3.96],[4, 3.96],[4, 4.11],[4, 4.11],[4, 4.11],[4, 4.11],[4, 4.11],[4, 4.26],[4, 4.26],[4, 4.26],[4, 4.41],[4, 4.41],[4, 4.56],[4, 4.56],[4, 4.56],[4, 4.70],[4, 4.70],[4, 4.70],[4, 4.85],[4, 4.85],[4, 5],[4, 5]
          ],
          marker: {
              fillColor: 'white',
              lineWidth: 1,
              lineColor: Highcharts.getOptions().colors[0]
          },
          tooltip: {
              pointFormat: 'Observation: {point.y}'
          }
      }]
      }
    };
  }


  render() {
    const { chartOptions } = this.state;

    return (
      <div class="chart">
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
        />
      </div>
    )
  }
}
