import React, { Component } from 'react';
import HomePartnershipBlend from './homeRedesign2023-Partnership';
import HomeResources from './homeRedesign2023-Resources';
import HomeResearch from './homeRedesign2023-Research';
import HomeInterestAreas from './homeRedesign2023-IAs';
import NewFooter from '../../pages-other/NewFooter';
import './homeRedesign2023-Content.css';

export default class HomeRedesign2023Content extends Component {
  render() {
    return(
      <div id="homeRedesign2023-content-container">
        <HomePartnershipBlend />
        <HomeResources />
        <HomeResearch />
        <HomeInterestAreas />
        <NewFooter />
      </div>
    );
  }
}