import React, {useState} from "react";
import ResearchThemesData from './researchThemes-Data';
import ResearchInterestAreaData from "./researchInterestAreas-Data";
import './researchRedesign-ResourceDropDown.css'

function ResourceDropDown() {

    const themeOptions = [
        ResearchThemesData.map(({ theme }) => (
            <li key={theme.title}><a href={theme.url}>{theme.title}</a></li>
        ))
    ]

    const interestAreaOptions = [
        ResearchInterestAreaData.map(({ interestArea }) => (
            <li key={interestArea.title}><a href={interestArea.url}>{interestArea.title}</a></li>
        ))
    ]

    const [isThemeActive, setThemeActive] = useState(false);
    
    const [isInterestActive, setInterestActive] = useState(false);


return (
<div className="resourceDropDown-container">
    <h2>Explore our research publications and resources</h2>
    <div className="resourceDropDown-categories">
        <div className="resourceDropDown-themes">
            <div className="resourceDropDown-category-title" onClick={() => setThemeActive(!isThemeActive)}>
                <h3>View by Theme</h3>
                <i className={ isThemeActive ?  `fa-solid fa-angle-up`:`fa-solid fa-angle-down`}></i>
            </div>
                {isThemeActive && 
                <ul className="resourceDropDown-category-contents">
                    {themeOptions}
                </ul>}
        </div>
        <div className="resourceDropDown-interest-areas">
            <div className="resourceDropDown-category-title" onClick={() => setInterestActive(!isInterestActive)}>
                <h3>View by Subject</h3>
                <i className={ isInterestActive ?  `fa-solid fa-angle-up`:`fa-solid fa-angle-down`}></i>
            </div>
                {isInterestActive && 
                <ul className="resourceDropDown-interest-area-contents">
                    {interestAreaOptions}
                </ul>}
        </div>
        <div className="resourceDropDown-published">
            <a href='/resources?cat=1417' className="resourceDropDown-category-title">
                <h3>View Peer-Reviewed Publications</h3>
                <i className="fa-solid fa-angle-right"></i>
            </a>
        </div>

    </div>
</div>
);

}

export default ResourceDropDown;
