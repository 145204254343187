import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import TechnologyMediaInformationLiteracyHeader from './technologyMediaInformationLiteracy-Header';
import TechnologyMediaInformationLiteracyContent from './technologyMediaInformationLiteracy-Content';
import './technologyMediaInformationLiteracy.css';

export default class TechnologyMediaInformationLiteracy extends Component {
  render() {
    return(
      <div id="technologyMediaInformationLiteracy-container">
        <Helmet>
          <title>foundry10 - Technology, Media, and Information Literacy</title>
          <meta
            name="title"
            content="foundry10 - Technology, Media, and Information Literacy"
          />
          <meta
            name="description"
            content="Discover ways to help young people navigate online space and create content with digital tools from foundry10 research, education programs, and philanthropy."
          />
          <meta property="og:title" content="foundry10 - Technology, Media, and Information Literacy" />
          <meta
            property="og:description"
            content="Discover ways to help young people navigate online space and create content with digital tools from foundry10 research, education programs, and philanthropy."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/interest-areas/technology-media-and-information-literacy" />
          <link rel="canonical" href="https://www.foundry10.org/interest-areas/technology-media-and-information-literacy" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 - Technology, Media, and Information Literacy",
                "description": "Discover ways to help young people navigate online space and create content with digital tools from foundry10 research, education programs, and philanthropy.",
                "url": "https://www.foundry10.org/interest-areas/technology-media-and-information-literacy"
              }
            `}
          </script>
        </Helmet>
        <TechnologyMediaInformationLiteracyHeader />
        <TechnologyMediaInformationLiteracyContent />
      </div>
    );
  }
}