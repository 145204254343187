import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import AnnualReportEdTechHeader from './annualReport2021EdTech-Header';
import AnnualReportEdTechContent from './annualReport2021EdTech-Content';
import './annualReport2021EdTech.css';

export default class AnnualReport2021EdTech extends Component {
  render() {
    return(
      <div id="annualReport2021EdTech-container">
        <Helmet>
          <title>foundry10 Annual Report 2021-2022: Educational Tech</title>
          <meta
            name="title"
            content="foundry10 Annual Report 2021-2022: Educational Tech"
          />
          <meta
            name="description"
            content="Explore the foundry10 Annual Report 2021 for insights into our EdTech work. Learn more about our efforts in research, programming, and philanthropy."
          />
          <meta property="og:title" content="foundry10 Annual Report 2021-2022: Educational Tech" />
          <meta
            property="og:description"
            content="Explore the foundry10 Annual Report 2021 for insights into our EdTech work. Learn more about our efforts in research, programming, and philanthropy."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/annual-report/2021/programs/ed-tech" />
          <link rel="canonical" href="https://www.foundry10.org/annual-report/2021/programs/ed-tech" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Annual Report 2021-2022: Educational Tech",
                "description": "Explore the foundry10 Annual Report 2021 for insights into our EdTech work. Learn more about our efforts in research, programming, and philanthropy.",
                "url": "https://www.foundry10.org/annual-report/2021/programs/ed-tech"
              }
            `}
          </script>
        </Helmet>
        <AnnualReportEdTechHeader />
        <AnnualReportEdTechContent />
      </div>
    );
  }
}