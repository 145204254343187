import React, { Component } from 'react';
import EducatorResourcesIntro from './educatorResources-Intro';
// import EducatorResourcesOpportunity from './educatorResources-Opportunity';
import EducatorResourcesPublications from './educatorResources-Publications';
import EducatorResourcesVideo from './educatorResources-Video';
// import EducatorResourcesStories from './educatorResources-Stories';
import EducatorResourcesStoriesCards from './educatorResources-CraftStories';
import EducatorResourcesTestimonials from './educatorResources-Testimonials';
import NewFooter from '../../pages-other/NewFooter';
import './educatorResources-Content.css';

export default class EducatorResourcesContent extends Component {
  render() {
    return(
      <div id="educatorResources-content-container">
        <EducatorResourcesIntro />
        {/* <EducatorResourcesOpportunity /> */}
        <EducatorResourcesPublications />
        <EducatorResourcesVideo />
        {/* <EducatorResourcesStories /> */}
        <EducatorResourcesStoriesCards />
        <EducatorResourcesTestimonials />
        <NewFooter />
      </div>
    );
  }
}