import React, { Component } from 'react';
import './Careers-Header.css';

export default class CareersHeader extends Component {
  render() {
    return(
      <header class="marineScienceCareersHeader">
        <div class="marineScienceCareersHeader-container">
          <h1>Ultimate Guide to Underwater Work</h1>
        </div>
      </header>
    );
  }
}