import React, { Component } from 'react';
import './newEquity-Intro.css';

export default class NewEquityIntro extends Component {
  render() {
    return(
      <div id="newEquity-container">
        <div className="newEquity-content-container">
          <div className="newEquity-normal">
            <p>At foundry10, strive to be ethical, responsive, and proactive in everything we do to expand ideas about learning and create direct value for youth. To that end, our team is committed to continually searching for, identifying, and addressing equity gaps across our work, both externally and internally. Read below to learn more about our equity goals, activities, and guiding considerations.</p>
          </div>
        </div>
      </div>
    );
  }
}