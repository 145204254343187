import React, { Component } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import './charts.css'

export default class BarChartWhy extends Component {
  constructor(props) {
    super(props);

    this.state = {
    chartOptions: {
      chart: {
        type: 'bar'
    },
    title: {
        text: 'Why did students choose to work on this project?'
    },
    subtitle: {
        text: ''
    },
    xAxis: {
        categories: ['Responses'],
        // title: {
            // text: 'Responses'
        // }
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Number of participants (N)',
            align: 'high'
        },
        labels: {
            overflow: 'justify'
        }
    },
    tooltip: {
        valueSuffix: ''
    },
    plotOptions: {
        bar: {
            dataLabels: {
                enabled: true
            }
        }
    },
    legend: {
        layout: 'horizontal',
        align: 'center',
        // verticalAlign: 'top',
        // x: -40,
        // y: 80,
        floating: false,
        borderWidth: 0,
        backgroundColor:
            '#FFFFFF',
        shadow: false
    },
    credits: {
        enabled: false
    },
    colors: ['#D14E1D','#00464F','#FFA75E','#575757','#007A8A'],
    series: [{
        name: 'I was intrigued by the challenge presented',
        data: [15]
    }, {
        name: 'I wanted to make an impact on the community experiencing an issue',
        data: [12]
    }, {
        name: 'I wanted to develop my skills in this area',
        data: [7]
    }, {
        name: 'I have a personal connection to the issue',
        data: [1]
    }, {
        name: 'I was assigned to this project',
        data: [0]
    }]
      }
    };
  }


  render() {
    const { chartOptions } = this.state;

    return (
      <div class="go-baby-go-chart">
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
        />
      </div>
    )
  }
}
