import React, { Component } from 'react';
import AnnualReportArtisticDesignOverview from './annualReport2021ArtisticDesign-Overview';
import AnnualReportArtisticDesignSpotlights from './annualReport2021ArtisticDesign-Spotlights';
import AnnualReport2021ProgramLinks from '../annualReport2021ProgramLinks/AnnualReport2021-ProgramLinks';
import NewFooter from '../../../../pages-other/NewFooter';
import './annualReport2021ArtisticDesign-Content.css';

export default class AnnualReportArtisticDesignContent extends Component {
  render() {
    return(
      <div id="annualReport2021ArtisticDesign-content-container">
        <AnnualReportArtisticDesignOverview />
        <AnnualReportArtisticDesignSpotlights />
        <AnnualReport2021ProgramLinks />
        <NewFooter />
      </div>
    );
  }
}