import React, { Component } from 'react';
import './afterschoolLandingPage-Intro.css';

export default class AfterschoolLandingPageIntro extends Component {
  render() {
    return(
      <div id="afterschoolLandingPage-intro-container">
        <div class="afterschoolLandingPage-intro-content-container">
          <div class="afterschoolLandingPage-intro-bold">
            <h3>foundry10 supports local engineering afterschool clubs.</h3>
          </div>
          <div class="afterschoolLandingPage-intro-normal">
            <p>We provide resources, such as curriculum, materials, and/or STEM instructors, to foster student learning and engineering exploration.</p>
          </div>
        </div>
      </div>
    );
  }
}