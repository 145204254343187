import React, { Component } from 'react';
import Slider from "react-slick";
import './october21-TeamContent.css';

export default class October21TeamContent extends Component {
    render() {
        var settings = {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 0,
          responsive: [
            {
              breakpoint: 1650,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 1240,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 850,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                fade: true
              }
            }
          ]
        };

        return (
        <div className="october21Team-content-container">
            <div className="october21Team-content-header">
                <div className="october21Team-content-title">
                    <h2>New on Our Blog</h2>
                </div>
                {/* <div className="october21Team-content-subtitle-text">
                    <h3>Our latest research, programs, and philanthropic endeavors</h3>
                </div> */}
            </div>
            <div id="october21TeamContentCarousel-container">
                <div className="october21TeamContentCarousel-content-container">
                    <div className="october21TeamContentCarousel-slider">
                    <Slider {...settings}>
                    {/* Begin Individual Card Content */}                                       
                    <a href="https://medium.com/the-foundry10-voice/wildfires-spark-interest-in-stem-for-south-seattle-students-dc6dddcba198" target="_blank" rel="noopener noreferrer" className="october21TeamContentCarousel-card-link">
                        <div className="october21TeamContentCarousel-card">
                            <div className="october21TeamContentCarousel-card-image">
                            <img src='https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Hub/Oct+2021/300x200_Blog-Thumbnail_ACHD_STEM.jpg' width="100%" alt="Student participants in the ACHD Curiosity Lab"/>
                            </div>
                            <div className="october21TeamContentCarousel-card-content">   
                                <div className="october21TeamContentCarousel-card-content-container">
                                    <div className="october21TeamContentCarousel-text">
                                        <h1>Wildfires Spark Interest in STEM for South Seattle Students</h1>
                                        <p>The Curiosity Lab at African Community Housing and Development (ACHD) invites students to lead the way on their own STEM learning journey.</p>
                                    </div>
                                </div>                 
                            </div>
                        </div>
                    </a>
                    {/* --- */}
                    <a href="https://medium.com/the-foundry10-voice/youth-and-elders-create-community-through-art-at-providence-elderplace-87a7986d5948" target="_blank" rel="noopener noreferrer" className="october21TeamContentCarousel-card-link">
                        <div className="october21TeamContentCarousel-card">
                            <div className="october21TeamContentCarousel-card-image">
                            <img src='https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Hub/Oct+2021/300x200_Blog-Thumbnail_Urban-ArtWorks.jpg' width="100%" alt="Finished youth-created mural for Providence ElderPlace"/>
                            </div>
                            <div className="october21TeamContentCarousel-card-content">   
                                <div className="october21TeamContentCarousel-card-content-container">
                                    <div className="october21TeamContentCarousel-text">
                                        <h1>Youth and Elders Create Community Through Art at Providence ElderPlace</h1>
                                        <p>At Urban ArtWorks, mural apprentices work alongside professional teaching artists to learn art skills and transform community spaces.</p>
                                    </div>
                                </div>                 
                            </div>
                        </div>
                    </a>
                    {/* --- */}
                    <a href="https://medium.com/the-foundry10-voice/teaching-soft-skills-shouldnt-be-so-hard-2a2db3b98030" target="_blank" rel="noopener noreferrer" className="october21TeamContentCarousel-card-link">
                        <div className="october21TeamContentCarousel-card">
                            <div className="october21TeamContentCarousel-card-image">
                                <img src='https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Hub/Oct+2021/300x200_Blog-Thumbnail_Soft-Skills.jpg' width="100%" alt="audience watching two presenters on stage"/>
                            </div>
                            <div className="october21TeamContentCarousel-card-content">   
                                <div className="october21TeamContentCarousel-card-content-container">
                                    <div className="october21TeamContentCarousel-text">
                                        <h1>Teaching Soft Skills Shouldn’t Be So Hard</h1>
                                        <p>Here’s how Career and Technical Education (CTE) educators can seamlessly integrate soft skill learning into their vocational curriculum.</p>
                                    </div>
                                </div>                 
                            </div>
                        </div>
                    </a>
                    {/* --- */}
                    <a href="https://medium.com/the-foundry10-voice/deepening-our-understanding-of-rural-schools-52f4b6bae1f6" target="_blank" rel="noopener noreferrer" className="october21TeamContentCarousel-card-link">
                        <div className="october21TeamContentCarousel-card">
                            <div className="october21TeamContentCarousel-card-image">
                                <img src='https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Hub/Oct+2021/300x200_Blog-Thumbnail_Deepening-Rural-Schools-Understanding.jpg' width="100%" alt="school bus driving down rural road"/>
                            </div>
                            <div className="october21TeamContentCarousel-card-content">   
                                <div className="october21TeamContentCarousel-card-content-container">
                                    <div className="october21TeamContentCarousel-text">
                                        <h1>Deepening Our Understanding of Rural Schools</h1>
                                        <p> foundry10 launched the Rural Schools Project in 2021 to address the pressing needs of rural students and teachers through direct partnerships.</p>
                                    </div>
                                </div>                 
                            </div>
                        </div>
                    </a>
                    {/* --- */}
                    <a href="https://medium.com/the-foundry10-voice/how-games-can-grow-our-capacity-for-connection-ca4b4fe8cd6f" target="_blank" rel="noopener noreferrer" className="october21TeamContentCarousel-card-link">
                        <div className="october21TeamContentCarousel-card">
                            <div className="october21TeamContentCarousel-card-image">
                            <img src='https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Hub/Oct+2021/300x200+Blog+Thumb+GtG+Guest+Post.png' width="100%" alt="assortment of dice in wooden box next to journal, mug, and crystals"/>
                            </div>
                            <div className="october21TeamContentCarousel-card-content">   
                                <div className="october21TeamContentCarousel-card-content-container">
                                    <div className="october21TeamContentCarousel-text">
                                        <h1>How Games Can Grow Our Capacity for Connection</h1>
                                        <p>The intentional therapeutic application of tabletop role-playing games can help youth develop the Core Capacities of social connection: Regulation, Collaboration, Planning, Perspective, and Pretend Play.</p>
                                    </div>
                                </div>                 
                            </div>
                        </div>
                    </a>
                    {/* --- */}
                    <a href="https://medium.com/the-foundry10-voice/how-to-build-a-better-parent-teacher-relationship-dd027d86c54" target="_blank" rel="noopener noreferrer" className="october21TeamContentCarousel-card-link">
                        <div className="october21TeamContentCarousel-card">
                            <div className="october21TeamContentCarousel-card-image">
                            <img src='https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Hub/Oct+2021/300x200+Blog+Thumb+Parent+Teacher+Relationships.png' width="100%" alt="A preschool age boy watches quietly as his mom and his female teacher smile and laugh during the parent teacher conference."/>
                            </div>
                            <div className="october21TeamContentCarousel-card-content">   
                                <div className="october21TeamContentCarousel-card-content-container">
                                    <div className="october21TeamContentCarousel-text">
                                        <h1>How to Build a Better Parent-Teacher Relationship</h1>
                                        <p>Research shows that healthy communication between parents and teachers has a positive impact on student learning.</p>
                                    </div>
                                </div>                 
                            </div>
                        </div>
                    </a>
                    {/* --- */}
                    <a href="https://medium.com/the-foundry10-voice/new-makerspace-at-federal-way-library-makes-space-for-foundry10-digital-audio-program-e534511d890d" target="_blank" rel="noopener noreferrer" className="october21TeamContentCarousel-card-link">
                        <div className="october21TeamContentCarousel-card">
                            <div className="october21TeamContentCarousel-card-image">
                            <img src='https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Hub/Oct+2021/300x200+Blog+Thumb+Federal+Way+Makerspace.png' width="100%" alt="Christine Warner practices sound mixing during a foundry10 Digital Audio Workshop"/>
                            </div>
                            <div className="october21TeamContentCarousel-card-content">   
                                <div className="october21TeamContentCarousel-card-content-container">
                                    <div className="october21TeamContentCarousel-text">
                                        <h1>New Makerspace at Federal Way Library Makes Space for foundry10 Digital Audio Program</h1>
                                        <p>foundry10 staff led digital audio learning workshops for teens at the brand new Federal Way Library Makerspace.</p>
                                    </div>
                                </div>                 
                            </div>
                        </div>
                    </a>
                    {/* --- */}
                    <a href="https://medium.com/the-foundry10-voice/5-tips-for-teaching-mindfulness-through-fiber-arts-dbf968974e6e" target="_blank" rel="noopener noreferrer" className="october21TeamContentCarousel-card-link">
                        <div className="october21TeamContentCarousel-card">
                            <div className="october21TeamContentCarousel-card-image">
                            <img src='https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Hub/Oct+2021/300x200+Blog+Thumb+5+Fiber+Arts+Tips.png' width="100%" alt="foundry10 Program Developer Kahle Gorzelsky leads a Mindful Fibers mini-course"/>
                            </div>
                            <div className="october21TeamContentCarousel-card-content">   
                                <div className="october21TeamContentCarousel-card-content-container">
                                    <div className="october21TeamContentCarousel-text">
                                        <h1>5 Tips for Teaching Mindfulness Through Fiber Arts</h1>
                                        <p>The foundry10 “Mindful Fibers” program blends fiber arts learning activities with mindfulness practices including guided meditations, body scans, and breathing exercises.</p>
                                    </div>
                                </div>                 
                            </div>
                        </div>
                    </a>
                    {/* --- */}
                    <a href="https://medium.com/the-foundry10-voice/youth-learn-carpentry-and-build-community-at-sawhorse-revolution-422f03332b89" target="_blank" rel="noopener noreferrer" className="october21TeamContentCarousel-card-link">
                        <div className="october21TeamContentCarousel-card">
                            <div className="october21TeamContentCarousel-card-image">
                            <img src='https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Hub/Oct+2021/300x200+Blog+Thumb+Sawhorse+Revolution.png' width="100%" alt="Wunderbar tiny home"/>
                            </div>
                            <div className="october21TeamContentCarousel-card-content">   
                                <div className="october21TeamContentCarousel-card-content-container">
                                    <div className="october21TeamContentCarousel-text">
                                        <h1>Youth Learn Carpentry and Build Community at Sawhorse Revolution</h1>
                                        <p>foundry10 was excited to partner with Sawhorse Revolution to support the construction of the Wunderbar Tiny House this spring.</p>
                                    </div>
                                </div>                 
                            </div>
                        </div>
                    </a>
                    {/* --- */}
                    <a href="https://medium.com/the-foundry10-voice/meet-the-intern-helping-youth-reach-their-full-potential-36dabd704d4" target="_blank" rel="noopener noreferrer" className="october21TeamContentCarousel-card-link">
                        <div className="october21TeamContentCarousel-card">
                            <div className="october21TeamContentCarousel-card-image">
                            <img src='https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Hub/Oct+2021/300x200+Blog+Thumb+Omar+EdTech.png' width="100%" alt="Omar Shaban - EdTech Undergraduate Intern"/>
                            </div>
                            <div className="october21TeamContentCarousel-card-content">   
                                <div className="october21TeamContentCarousel-card-content-container">
                                    <div className="october21TeamContentCarousel-text">
                                        <h1>Meet the Intern Helping Youth Reach Their Full Potential</h1>
                                        <p>Omar Shaban hopes EdTech will one day provide a personalized education for every student.</p>
                                    </div>
                                </div>                 
                            </div>
                        </div>
                    </a>
                    {/* --- */}
                    <a href="https://medium.com/the-foundry10-voice/meet-the-intern-designing-an-interactive-map-tool-for-seattle-educators-ce001c1b8df7" target="_blank" rel="noopener noreferrer" className="october21TeamContentCarousel-card-link">
                        <div className="october21TeamContentCarousel-card">
                            <div className="october21TeamContentCarousel-card-image">
                            <img src='https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Hub/Oct+2021/300x200+Blog+Thumb+Kriti.png' width="100%" alt="Kriti Vajjula - EdTech Undergraduate Intern"/>
                            </div>
                            <div className="october21TeamContentCarousel-card-content">   
                                <div className="october21TeamContentCarousel-card-content-container">
                                    <div className="october21TeamContentCarousel-text">
                                        <h1>Meet the Intern Designing an Interactive Map Tool for Seattle Educators</h1>
                                        <p>This Q&A with Kriti Vajjhula explores her project designing and prototyping a tool that locates VR exhibits and learning experiences in the Seattle area.</p>
                                    </div>
                                </div>                 
                            </div>
                        </div>
                    </a>
                        {/* --- */}
                        {/* <a href="https://www.foundry10.org/" target="_blank" rel="noopener noreferrer" className="october21TeamContentCarousel-card-link">
                            <div className="october21TeamContentCarousel-card">
                                <div className="october21TeamContentCarousel-card-image">
                                    <img src='https://via.placeholder.com/230/d14e1d/FFFFFF?text=Image+Coming+Soon' width="100%" alt="alt text description here"/>
                                </div>
                            <div className="october21TeamContentCarousel-card-content">   
                                    <div className="october21TeamContentCarousel-card-content-container">
                                        <div className="october21TeamContentCarousel-text">
                                            <h1>Occaecat aliquip</h1>
                                            <p>Dolore reprehenderit minim fugiat sunt elit duis voluptate dolore ad officia esse dolore. Duis commodo enim velit dolor.</p>
                                        </div>
                                    </div>                 
                                </div>
                            </div>
                        </a> */}
                    </Slider>
                    </div>
                </div> 
            </div>
        </div>          
        );
      }
}