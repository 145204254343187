import React, { Component } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import './charts.css'

HighchartsMore(Highcharts);

export default class BoxPlotSelfEfficacy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // To avoid unnecessary update keep all options in the state.
    chartOptions: {
        chart: {
          type: 'boxplot',
          // backgroundColor: '#007A8A',
      },

      title: {
        text: 'Self-Efficacy Scores',
        style: {
          color: '#00464F'
        }
      },

      legend: {
          enabled: false
      },

      xAxis: {
        categories: ['Academic Score', 'Emotional Score', 'Social Score'],
        title: {
          style: {
            color: '#00464F'
          },
          text: ''
          },
      },

      yAxis: {
          title: {
            style: {
              color: '#00464F'
            },  
            text: 'Score'
          },
          // plotLines: [{
          //     value: 932,
          //     color: 'red',
          //     width: 1,
          //     label: {
          //         text: 'Theoretical mean: 932',
          //         align: 'center',
          //         style: {
          //             color: 'gray'
          //         }
              // }
          // }]
      },
      //Data: An array of arrays with 6 or 5 values. In this case, the values correspond to x,low,q1,median,q3,high. If the first value is a string, it is applied as the name of the point, and the x value is inferred. The x value can also be omitted, in which case the inner arrays should be of length 5. Then the x value is automatically calculated, either starting at 0 and incremented by 1, or from pointStart and pointInterval given in the series options.
      plotOptions: {
        series: {
            colorByPoint: true
        }
    },
      colors: ['#D14E1D','#00464F','#FFA75E'],
      series: [{
          name: 'Observations',
          data: [
              ['Academic Score',1.143,3.214,3.714,4.286,5],
              ['Emotional Score',1,2.571,3.143,3.714,5],
              ['Social Score',1.857,3,3.429,3.964,4.857]
          ],
          style: {
            color: '#E77C53'
          },
          tooltip: {
              headerFormat: '<em>{point.key}</em><br/>'
          },
      }, {
          name: 'Data Points',
          color: Highcharts.getOptions().colors[0],
          type: 'scatter',
          jitter: {
            x: .24
          },
          data: [ // x, y positions where 0 is the first category
              [0, 1.14],[0, 1.43],[0, 2.14],[0, 2.29],[0, 2.29],[0, 2.43],[0, 2.43],[0, 2.43],[0, 2.57],[0, 2.71],[0, 2.71],[0, 2.71],[0, 2.71],[0, 2.71],[0, 2.71],[0, 2.71],[0, 2.86],[0, 3],[0, 3.14],[0, 3.14],[0, 3.14],[0, 3.14],[0, 3.14],[0, 3.29],[0, 3.29],[0, 3.29],[0, 3.43],[0, 3.43],[0, 3.43],[0, 3.43],[0, 3.43],[0, 3.43],[0, 3.57],[0, 3.57],[0, 3.57],[0, 3.57],[0, 3.57],[0, 3.57],[0, 3.57],[0, 3.57],[0, 3.57],[0, 3.71],[0, 3.71],[0, 3.71],[0, 3.71],[0, 3.71],[0, 3.86],[0, 3.86],[0, 3.86],[0, 3.86],[0, 3.86],[0, 3.86],[0, 3.86],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4],[0, 4.14],[0, 4.14],[0, 4.14],[0, 4.14],[0, 4.14],[0, 4.29],[0, 4.29],[0, 4.29],[0, 4.29],[0, 4.29],[0, 4.43],[0, 4.43],[0, 4.43],[0, 4.43],[0, 4.43],[0, 4.43],[0, 4.43],[0, 4.43],[0, 4.43],[0, 4.43],[0, 4.57],[0, 4.71],[0, 4.71],[0, 4.71],[0, 4.86],[0, 4.86],[0, 4.86],[0, 4.86],[0, 4.86],[0, 5],

              [1, 1],[1, 1.57],[1, 1.57],[1, 1.71],[1, 1.71],[1, 1.71],[1, 2],[1, 2],[1, 2.14],[1, 2.14],[1, 2.14],[1, 2.14],[1, 2.29],[1, 2.29],[1, 2.29],[1, 2.29],[1, 2.43],[1, 2.43],[1, 2.43],[1, 2.43],[1, 2.57],[1, 2.57],[1, 2.57],[1, 2.57],[1, 2.57],[1, 2.71],[1, 2.71],[1, 2.71],[1, 2.71],[1, 2.71],[1, 2.86],[1, 2.86],[1, 2.86],[1, 2.86],[1, 2.86],[1, 2.86],[1, 3],[1, 3],[1, 3],[1, 3],[1, 3],[1, 3],[1, 3.14],[1, 3.14],[1, 3.14],[1, 3.14],[1, 3.14],[1, 3.14],[1, 3.14],[1, 3.29],[1, 3.29],[1, 3.29],[1, 3.29],[1, 3.43],[1, 3.43],[1, 3.43],[1, 3.57],[1, 3.57],[1, 3.57],[1, 3.57],[1, 3.57],[1, 3.57],[1, 3.57],[1, 3.71],[1, 3.71],[1, 3.71],[1, 3.71],[1, 3.71],[1, 3.71],[1, 3.71],[1, 3.86],[1, 3.86],[1, 3.86],[1, 3.86],[1, 3.86],[1, 4],[1, 4],[1, 4],[1, 4],[1, 4.14],[1, 4.14],[1, 4.14],[1, 4.29],[1, 4.29],[1, 4.29],[1, 4.43],[1, 4.43],[1, 4.43],[1, 4.57],[1, 5],[1, null],

              [2, 1.86],[2, 1.86],[2, 2],[2, 2.14],[2, 2.29],[2, 2.29],[2, 2.43],[2, 2.43],[2, 2.57],[2, 2.57],[2, 2.71],[2, 2.71],[2, 2.71],[2, 2.86],[2, 2.86],[2, 2.86],[2, 2.86],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3],[2, 3.14],[2, 3.14],[2, 3.14],[2, 3.14],[2, 3.14],[2, 3.14],[2, 3.29],[2, 3.29],[2, 3.29],[2, 3.29],[2, 3.29],[2, 3.29],[2, 3.29],[2, 3.43],[2, 3.43],[2, 3.43],[2, 3.43],[2, 3.43],[2, 3.43],[2, 3.57],[2, 3.57],[2, 3.57],[2, 3.57],[2, 3.57],[2, 3.71],[2, 3.71],[2, 3.71],[2, 3.71],[2, 3.71],[2, 3.71],[2, 3.71],[2, 3.71],[2, 3.86],[2, 3.86],[2, 3.86],[2, 3.86],[2, 3.86],[2, 3.86],[2, 3.86],[2, 4],[2, 4],[2, 4],[2, 4],[2, 4],[2, 4],[2, 4.14],[2, 4.14],[2, 4.14],[2, 4.14],[2, 4.14],[2, 4.14],[2, 4.14],[2, 4.14],[2, 4.14],[2, 4.29],[2, 4.29],[2, 4.29],[2, 4.43],[2, 4.43],[2, 4.43],[2, 4.57],[2, 4.86],[2, null]
          ],
          marker: {
              fillColor: 'white',
              lineWidth: 1,
              lineColor: Highcharts.getOptions().colors[0]
          },
          tooltip: {
              pointFormat: 'Observation: {point.y}'
          }
      }]
      }
    };
  }


  render() {
    const { chartOptions } = this.state;

    return (
      <div class="chart">
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
        />
      </div>
    )
  }
}
