import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
// import ReactPlayer from 'react-player';
import './circuit-boards.css';
import NewFooter from '../../../pages-other/NewFooter';

export default class CircuitBoards extends Component {
  render() {
    return(
        <div id="programProject-main-container">
        <Helmet>
          <title>foundry10 Automotive Education Project: Circuit Boards</title>
          <meta
            name="title"
            content="foundry10 Automotive Education Project: Circuit Boards"
          />
          <meta
            name="description"
            content="Explore foundry10's automotive technology project-based learning for youth featuring the Circuit Boards Project. "
          />
          <meta property="og:title" content="foundry10 Automotive Education Project: Circuit Boards" />
          <meta
            property="og:description"
            content="Explore foundry10's automotive technology project-based learning for youth featuring the Circuit Boards Project. "
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/automotive-projects/circuit-boards" />
          <link rel="canonical" href="https://www.foundry10.org/automotive-projects/circuit-boards" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Automotive Education Project: Circuit Boards",
                "description": "Explore foundry10's automotive technology project-based learning for youth featuring the Circuit Boards Project. ",
                "url": "https://www.foundry10.org/automotive-projects/circuit-boards"
              }
            `}
          </script>
        </Helmet>
        {/* HEADER */}
        <div class="programProject-header-container-circuit">
          <div class="programProject-header-content-container">
            <h1>Circuit Training Boards</h1>
          </div>
        </div>
        {/* BODY */}
        <div id="programProject-main-content-container">
          {/* DESCRIPTION */}
          <div id="vrLandingPage-description-container-dragster">
            <div class="vrLandingPage-description-content-container-detroit">
              <p>Though it was impressive to see the two schools build cars and work with advanced electrical technology, we began to wonder if there might be a way to give students access to electrical systems without necessarily having them situated in cars. We began brainstorming with the automotive tech teacher at Sno-Isle Skills Center and trying to think through ways we could challenge students, expand curriculum and give them advanced automotive skills without necessarily having them build a car from the ground up. The instructor and his students came up with the idea to do circuit training boards. These are electrical circuit boards that are manufacturer specific and allow students to model the various electrical systems of the car and work on understanding the physics behind the electricity.</p>
              <p>We ordered enough kits so that students could really get individual hands-on experience. Each manufacturer’s kit came with an instructor’s manual and workbook for students. The instructor was able to set up a separate room where students could just work on electrical. The kits were a success in that students felt their learning was enhanced and the kits themselves sparked additional questions for students about how all this comes together within the frame of the car itself.</p>
            </div>
          </div>
          {/* DOCUMENT */}
          <div id="autoProject-document-container">
            <div class="autoProject-document-content-container">
              <a href="https://drive.google.com/file/d/1-cgidojBmyMwJJUHtmG2J0uMOpglRh5e/view?usp=sharing" target="_blank" rel="noopener noreferrer">    
                <div class="autoResearch-cards-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/automotive-tech/auto+page+pictures/Circuit+Board.png" alt="circuit training board summary" />
                  <p class="autoResearch-cards-title">Project Summary</p>
                  <div class="autoResearch-cards-overlay"></div>
                </div>  
              </a>
            </div>
          </div>
              

          {/* VIDEO */}
          {/* <div id="programProject-video-container-autoProjects">
            <div class="programProject-video-content-container-autoProjects">
              <div class="videoCard-container">
                <ReactPlayer
                url='https://youtu.be/mkVK4kofuGA'
                className='react-player'
                //   playing
                controls
                width='100%'
                height='100%'
                />
              </div>
            </div>
          </div> */}
          <NewFooter />
        </div>
      </div>
    );
  }
}