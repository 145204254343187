import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import NewFooter from '../../../../pages-other/NewFooter';
import './People.css';

export default class HeidiJessie extends Component {
  render() {
    return(
        <div id="marineCareers-main-container">
          <Helmet>
          <title>foundry10 Guide to Marine Science Careers: Scuba Operations</title>
          <meta
            name="title"
            content="foundry10 Guide to Marine Science Careers: Scuba Operations"
          />
          <meta
            name="description"
            content="Explore an exciting marine science career in scuba operations. Dive into what life is like in this career with interviews and resource links."
          />
          <meta property="og:title" content="foundry10 Guide to Marine Science Careers: Scuba Operations" />
          <meta
            property="og:description"
            content="Explore an exciting marine science career in scuba operations. Dive into what life is like in this career with interviews and resource links."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/marine-science-careers/scuba-operations" />
          <link rel="canonical" href="https://www.foundry10.org/marine-science-careers/scuba-operations" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Guide to Marine Science Careers: Scuba Operations",
                "description": "Explore an exciting marine science career in scuba operations. Dive into what life is like in this career with interviews and resource links.",
                "url": "https://www.foundry10.org/marine-science-careers/scuba-operations"
              }
            `}
          </script>
        </Helmet>
        {/* MAIN */}
        <div id="marineCareers-main-content-container">
          {/* HEADER */}
          <div id="marineCareers-header-container">
            <div class="marineCareers-header-title-container">
              <h3>Scuba Operations</h3>
              <h2>Meet Heidi</h2>
              <div class="marineCareers-headerImage-container">
                <div class="marineCareers-headerImage-content-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/People/Heidi+Wilken.jpg" alt="heidi wilken"/>
                </div>
              </div>
              <p>Heidi Wilken is currently the Dive Safety Officer at Point Defiance Zoo and Aquarium. She was hired in 2013 to help launch the Eye to Eye Shark Dive, a program focused at shattering negative misconceptions about sharks. She overseas numerous aspects of diving at PDZA these days, including that program still. Heidi grew up homeschooled on a boat, mostly overseas…  a side effect of being born to parents who are wanderers. In her teens she took a Scuba class…  and hated it, struggling to even reach the deep end of the swimming pool, and only continuing so her brother would have a dive buddy. If you told her at the time that she’d end up as a professional diver, she’d have laughed heartily. Somewhere in the midst of dive 10 – 15, she had things figured out enough she could start focusing on the amazing sea life… and she’s never wanted to leave the underwater world since. Heidi has a Bachelor of Science in Conservation Biology, has taught thousands of people to Scuba dive, and has taken care of a variety of creatures from Jellies to Sharks. Before working at PDZA she most recently had worked in Australia, pursuing a dream to dive with Nemo on the Great Barrier Reef. Showing that life is rarely what you expect, she quickly figured out that Nemo is in fact a jerk when he swam right up to her and bit her finger – being a member of the small but pugnacious damselfish family. When not having the time of her life at work she can be found diving for fun, cooking large quantities of food for friends, and juggling, not as in life...but numerous small objects.</p>
            </div>
            <div class="marineCareers-header-title-container">
              <h2>Meet Jessie</h2>
              <div class="marineCareers-headerImage-container">
                <div class="marineCareers-headerImage-content-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/People/Jessie+Miles.jpg" alt="jessie miles"/>
                </div>
              </div>
              <p>Hello! I am Jessie Miles, an ocean advocate with experience as a scientific diver and working in a career in dive safety. I have been diving for over ten years where I have dove mostly in the cold waters of Puget Sound with a few trips to warmer water in Mexico and Australia. Once I started diving I knew I found my career path and earned my Bachelor's degree at the University of Washington with a major in Aquatic and Fisheries Sciences and a minor in marine biology. I went on to be a lab technician for a couple of field seasons in the Sebens Subtidal Ecology Lab at the University of Washington's Friday Harbor Labs. I have worked for a marine surveying company and have had a wide variety of projects hired on as a scientific diver. I have assisted with many scientific diving courses and also run my own business as a SCUBA Instructor. My current full time job is the Eye to Eye Dive Safety Officer at the Point Defiance Zoo and Aquarium where I oversee the daily operations of a guest immersion program as well as other duties that include animal husbandry, gear servicing, the occasional field dive, and inspiring conservation of our marine environment.</p>
            </div>
          </div>
          {/* BODY */}
          <div id="marineCareers-review-container">
            <div class="marineCareers-review-content-container">
              {/* INTERVIEW WITH HEIDI */}
              <div class="marineCareers-header-title-container">
                <h2>Interview with Heidi</h2>
              </div>
              <div class="marineCareers-interview">
                <h2>Describe an average day in the life of a dive safety officer.</h2>
                <p>That is actually a pretty hard question because my day can vary from working on life support equipment, diving in the field to help collect an octopus, or helping an aquarist develop new safety procedures for diving in an exhibit, so it can be pretty varied. We also have a guest dive program where folks can dive with sharks, and I oversee that, so it can be anything to do with any of those programs. What I look forward to most is seeing people get out of the water after their first time diving with sharks, or just diving in general, and seeing that magical look on their face when they had that experience.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>Why should youth want to pursue this marine science career, and why is it important right now?</h2>
                <p>It's important right now because dive safety officers really support an immense array of diving whether it's diving in the aquarium, which is in support of educating the public about conservation means, or whether it's supporting scientists at a research institution or university, who are trying to discover more about the underwater world that can then be explained at an aquarium . You support such an array of diving, you should be interested in it because it's just so fun, and you get to do so many different things. Some days we’re helping to move sea turtles, and then the next day we're figuring out how to drill into fake rocks to work underwater, and then the next day we might be doing something completely different. So, if you like variety this is a really good job for you.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>Was there a specific moment that you knew this was the career for you?</h2>
                <p>I think that was during my advanced open water class, and diving in Monterey, and it was the first time that I could say I actually really enjoyed it. I did not immediately take to diving, I didn't enjoy my first few times, and it was a struggle for me to get certified. I had a dive where finally my buoyancy felt good, and I just felt comfortable, and I got to actually just start to see the cool stuff that was down there, the amazing underwater world. Once I had that feeling, I wanted to spend as much time as possible having that feeling and sharing that with others.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>Can you describe your education, training, or experience that prepared you for this career?</h2>
                <p>My specialty, even though I'm also a biologist, I came to it from the diving side of things. I'm an instructor, and I actually taught mostly recreational diving for a few years, I certified several thousand people to scuba dive, and then I became a scientific diver with a local university right about the time that I finished my Bachelor's of Science degree in Biology. It took me a while to figure out how I would combine those because with that skill set, you can become a staff biologist, or you can become a professional diver and I'm lucky that it all kind of came together to a job that worked the best for me.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What advice would you give someone starting out on this career path?</h2>
                <p>Spend as much time diving as you can to become a really competent diver. I know that dive gear can be really expensive, and it's a little bit of a barrier, and that training can also be a bit of a barrier. There are various grant programs out there that can help with that, and a lot of times if you can get a job at a dive shop, you can get a lot of discounts which can help. I know that the entry into this field can be steep, it was steep for me. Also, just learn as much as you can about mechanical things, a lot of my job is taking apart things like scuba regulators, and overhauling them, and putting them back together. So, the more time you spend getting familiar with tools, all the basic ones like a screwdriver, and a vise grip, and stuff like that, but also power tools like drills. You spend a lot of time building things in this career, and working on things with your hands, so the more more you practice, and get that in, the better.</p>
              </div>
              {/* INTERVIEW WITH BRIDGET */}
              <div class="marineCareers-header-title-container">
                <h2>Interview with Jessie</h2>
              </div>
              <div class="marineCareers-interview">
                <h2>Describe an average day in the life of a dive safety officer. What do you look forward to most during your work day?</h2>
                <p>I would say describing the average day of a dive safety officer is a very tricky challenge because depending on where you are, if you're in a university versus an aquarium, even within those separate areas, there's a huge variety. For me, there's also a lot of variety in my day. So typically, pre-COVID and hopefully in the future, my day consists of mostly overseeing a guest immersion program where we take kids and adults in the water with sharks. So, I oversee the daily operations of that. I do the gear repair, the maintenance, I help if there are any questions from guests, or if there are any medical issues that come up that they may need questions or extra assistance on, to help out with that. I also, on a typical day, might go and get into one of the exhibits and do exhibit cleaning, and maintenance, or feed the animals. Sometimes I do food prep. So throughout the day, I do a lot of different things.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What are some common misconceptions about your job and field? What do people often find the most surprising about your work?</h2>
                <p>Probably one is that a lot of what we do is just sit and write the rules, and come up with what we don't want people to do. In reality, people work closely with their dive safety officers. We're a really good source of information for helping them. I've also worked, in the past, at a university as a lab technician, and I utilized the dive safety officer a lot, because she was a big source of knowledge, and I learned a lot from her when I was doing research. So I think just realizing that they have such a broad spectrum of knowledge to gain from.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>Describe your education, training, and/or experience that prepared you for this career.</h2>
                <p>I have my bachelor's from the University of Washington, from SAFS, with a minor in marine biology because the major wasn't an option yet. And then after that, I did a lot of dive certifications. I became a scientific diver, and then after that, I got my dive master and I became an instructor. So I've been an instructor for a couple of years now, which is necessary if you want to be a dive safety officer. And then I also went for gear certification, so I became a gear technician for Oceanic and a couple of other different brands. Being able to work on gear is really important for this career path.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>How has the field and topic changed in the last five years? What do you predict might happen in the next five to 10 years?</h2>
                <p>I think one of the biggest differences that I noticed a lot, because when I first started diving, and that was almost 10 years ago, all the people that I dove with were older men and I was the only woman that was diving in the group that I was at. So I think one of the big differences that we're seeing as time goes on, we're finding a lot more women in dive careers and dive safety officer roles, which I think is pretty awesome. I think that's one of the biggest changes I've seen, and in the next five years, hopefully, we just continue to be inclusive and get more people from different backgrounds and different varieties and get more knowledge there, or maybe more viewpoints would be great.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What have you learned about yourself and the world while working in your field?</h2>
                <p>Everybody makes a difference. Most of what I do is inspire people for shark conservation, the guest program that I work in. The heart and soul of the program is conservation and specifically shark conservation. Every day we have 16 people come through, sometimes we have four people come through, but when you look at the grand scheme of things, since 2013, we've had over 17,000 people that have come to dive with our sharks. So then when you look at it that way, it's like, "Wow, that's over 17,000 people that have heard this message that is going to go on and pass this message on to other people." And just knowing that presentation you give and that kid that you inspired can make such a huge difference, and so in my life, it's just realizing that what I do does make a difference and I want to be a part of that and be a part of that change, and see kids who were absolutely terrified to get in the water with sharks, to leave wanting to be marine biologists... It's pretty incredible.</p>
              </div>
              {/* LEARNING MORE */}
              <div id="marineCareersLearning-container">
                <div class="marineCareersLearning-content-container">
                  <h2>Interested in learning more about Scuba Operations?</h2>
                  <ul class="marineCareersLearning-list">
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p>Do you want to read more about Heidi's responses? <a href="https://drive.google.com/file/d/1zPnO3V6WqM9ap7k-wdxObub7dNqZmolC/view" target="_blank" rel="noopener noreferrer">Here's the link to the full interview</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p>Do you want to read more about Jessie's responses? <a href="https://drive.google.com/file/d/1Bv5Z2WYkfFs8Egj_2M-hLH2wQ0RWtYuO/view" target="_blank" rel="noopener noreferrer">Here's the link to the full interview</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.padi.com/" target="_blank" rel="noopener noreferrer">Explore information about scuba diving, classes, and the different types of diving here.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://dtmag.com/thelibrary/oh-places-scuba-divers-go/" target="_blank" rel="noopener noreferrer">Read more about what the different diving careers are out there.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.girlsthatscuba.com/how-to-become-a-scientific-diver/" target="_blank" rel="noopener noreferrer">Learn about how to become a scientific diver on this webppage.</a></p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <a class="marineCareers-backButton" href={'/marine-science-careers'}>
                    <p>Back to the careers!</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <NewFooter />
        </div>
      </div>
    );
  }
}