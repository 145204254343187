import React, { Component } from 'react';
import './PhilanthropyLandingPage-Intro.css';

export default class PhilanthropyLandingPageIntro extends Component {
  render() {
    return(
      <div id="philanthropyLandingPage-intro-container">
        <div class="philanthropyLandingPage-intro-content-container">
          <h1>Everything we do is philanthropic.</h1>
          <p>We are a philanthropic educational research organization that works to directly benefit kids and expand the way people think about learning.  We do this through our work in programs for children and youth, associated research and philanthropic outreach. Our approach to philanthropy is flexible, collaborative, and ethical. We are responsive to the needs of communities and work with them to identify the resources that serve them best. We work with our partners throughout the process of developing a project. We strive to establish sustainable projects and make our resources available equitably. Finally, we share about this work so that we can inspire and inform efforts to create direct value for kids.</p>
        </div>
      </div>
    );
  }
}