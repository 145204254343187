import React from 'react';
import './creativityDesignPlay-Breadcrumb.css';

const CreativityDesignPlayBreadcrumb = () => {

  const handleLinkClick = (url) => {
    // Perform a full page reload by setting window.location.href to the specified URL
    window.location.href = url;
  };

   return (
    <div class="creativityDesignPlay-breadcrumb-container">
      <div class="creativityDesignPlay-breadcrumb-content-container">
        <div class="creativityDesignPlay-breadcrumb-title">
          <div className="creativityDesignPlay-breadcrumb-titleWhite">
            <p><a href="/"><i class="fa-solid fa-house"></i></a></p>
            <p><i class="fa-solid fa-angle-right"></i></p>
            <p><a href="/interest-areas" onClick={() => handleLinkClick('/interest-areas')}>Interest Areas</a></p>
            <p><i class="fa-solid fa-angle-right"></i></p>
          </div>
          <div className="creativityDesignPlay-breadcrumb-titleOrange">
            <p>Creativity, Design, and Play</p>
          </div>
        </div>
      </div>
    </div>
   )
}
export default CreativityDesignPlayBreadcrumb;