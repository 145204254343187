import React, { Component } from 'react';
import AnnualReportTeam from '../annualReport2021_Components/02_Team/AnnualReport-Team';
import AnnualReportCovid from '../annualReport2021_Components/04_Covid/AnnualReport-Covid';
import AnnualReportResearch from '../annualReport2021_Components/05_Research/AnnualReport-Research';
import AnnualReportPrograms from '../annualReport2021_Components/06_Programs/AnnualReport-Programs';
import AnnualReportPhilanthropy from '../annualReport2021_Components/07_Philanthropy/AnnualReport-Philanthropy';
import AnnualReportSharing from '../annualReport2021_Components/08_Sharing/AnnualReport-Sharing';
import AnnualReportLinks from '../annualReport2021_Components/10_Links/AnnualReport-Links';
import NewFooter from '../../../pages-other/NewFooter';
import './AnnualReport-Content.css';
// Import Component Elements Here

export default class AnnualReportContent extends Component {
  render() {
    return(
      <div id="annualReport2021landing-content-container">
        <AnnualReportTeam />
        <AnnualReportCovid />
        <AnnualReportResearch />
        <AnnualReportPrograms />
        <AnnualReportPhilanthropy />
        <AnnualReportSharing />
        <AnnualReportLinks />
        <NewFooter />
      </div>
    );
  }
}