import React from "react";
import Grid from "@material-ui/core/Grid";

const FeaturedResource = ({ block }) => {
  if (block?.featuredResource?.length > 0) {
    const cur_url = window.location.href;
    const prefix_url = cur_url.split("/")[0];
    return (
      <section className="featuredResource">
        <div className="container">
          <h3 className="text-xxl_bold text-left emphHeader">
            <span>Resource Highlight(s)</span>
          </h3>
          {block.featuredResource.map((featuredResource, index) => (
            <div className="content">
              <Grid container spacing={8} key={index}>
                <Grid item md={8}>
                  <h4 className="text-large">
                    <a href={prefix_url + "/resources/" + featuredResource.slug}>
                    {featuredResource.title}
                    </a>
                  </h4>
                  <div
                    className="resourceBlurb"
                    dangerouslySetInnerHTML={{
                      __html: featuredResource.blurb,
                    }}
                  ></div>
                  {/* {featuredResource.researchAsset && (
                    <a
                      className="text-half-step_bold"
                      // href={featuredResource.researchAsset.url}
                      href={prefix_url + "/resources/" + featuredResource.slug}
                    >
                      Learn More
                    </a>
                  )} */}

                </Grid>
                <Grid item md={4}>
                  {featuredResource.thumbnailImage[0] && (                   
                   <a href={prefix_url + "/resources/" + featuredResource.slug}>
                    <img
                      src={featuredResource.thumbnailImage[0]?.url}
                      alt="Resource Download"
                    />
                    </a>
                  )}
                  {/* {featuredResource.featuredImageCaption && (
                    <div className="resourceCitation">
                      <h4 className="text-small_bold">Citation:</h4>
                      <i
                        onClick={() =>
                          navigator.clipboard.writeText(
                            featuredResource.featuredImageCaption
                          )
                        }
                        className="fa-solid fa-copy copyBtn"
                      />
                      <p className="text-small_bold">
                        {featuredResource.featuredImageCaption}
                      </p>
                    </div>
                  )} */}
                </Grid>
              </Grid>
            </div>
          ))}
        </div>
      </section>
    );
  }

  return null;
};

export default FeaturedResource;
