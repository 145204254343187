import React from "react";
import './researchRedesign-Collaborate.css'

function ResearchCollaborate() {


return (
<div className="researchCollaborate-container">
    <div className="researchCollaborate-content-container">
        <div className="researchCollaborate-img-container">
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Research+Landing+Page/2022+Redesign/New+Collaborate+Placeholder.jpg" alt="foundry10 research team laughing"/>
        </div>
        <div className="researchCollaborate-text-container">
            <h2>Collaborate with us</h2>
            <p>We believe that research provides a powerful avenue for communication and partnerships to strengthen evidence based learning practices. Our research process is flexible and customized to meet the needs of the partners and communities with whom we work. </p>
            <p>Please contact us with questions and opportunities to collaborate on study design and program implementation.</p>
            <div className="researchCollaborate-button-container">
                <a href="mailto:study@foundry10.org">Contact our team</a>
            </div>
        </div>
    </div>
</div>
);

}

export default ResearchCollaborate;
