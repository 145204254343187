import React, { Component } from 'react';
import StemDbrLabMembers from './stemDbrLab-Members';
import './stemDbrLab-Body.css';

export default class StemDbrLabBody extends Component {
  render() {
    return(
      <div id="stemDbrLabBody-container">
        <div class="stemDbrLabBody-content-container">
          {/* LAB DEFINITION */}
          <div class="stemDbrLabBody-definition-container">
            <div class="stemDbrLabBody-definition-content-container">
              {/* <div class="stemDbrLabBody-definition-bold">
                <h1>What is the STEM DBR Lab?</h1>
              </div> */}
              <div class="stemDbrLabBody-definition-paragraph">
                {/* <h2>To put it simply, conceptual development means 'learning'</h2> */}
                {/* <p>The STEM DBR lab works to expand human understanding of how science, technology, engineering, and math instructional activities designed for youth work in real world settings such as classrooms, after school programs, and informal environments.</p>
                <p>DBR stands for “design based research,” which is a research approach that explores how learning theories can be better integrated into designed “interventions” (instructional measures such as programs and other learning experiences) and seeks to expand insight into how these designs work with specific types of learners and contexts. The goal is to test assumptions, conjectures, and claims about these designed experiences, develop effective and context sensitive learning design principles grounded in practice, as well as build and test theories that emerge from these design principles.</p> */}
                <p>foundry10's STEM Design-Based Research (DBR) Lab strives to expand understanding of how science, technology, engineering, and math instructional activities designed for youth work in real world settings such as classrooms, afterschool programs, and informal environments.</p>
              </div>
            </div>
          </div>
          {/* DBR DEFINITION */}
          <div class="stemDbrLabBody-dbrDefinition-container">
            <div class="stemDbrLabBody-dbrDefinition-content-container">
              <div class="stemDbrLabBody-dbrDefinition-bold">
                <h1>What is Design-Based Research (DBR)?</h1>
              </div>
              <div class="stemDbrLabBody-dbrDefinition-paragraph">
                {/* <h2>To put it simply, conceptual development means 'learning'</h2> */}
                <p>Design-Based Research (DBR) is a research approach that explores how learning theories can be better integrated into designed “interventions” (instructional measures such as programs and other learning experiences) and seeks to expand insight into how these designs work with specific types of learners and contexts. The goal is to test assumptions, conjectures, and claims about these designed experiences, develop effective and context sensitive learning design principles grounded in practice, as well as build and test theories that emerge from these design principles.</p>
                <p>Alan Collins and Anne Brown developed DBR in 1992 through their discussions about using “design experiments” as a way to explore how learning interventions actually work in practice. These researchers, and others within this school of thought, developed DBR to move away from laboratory settings and into the classrooms where learning actually occurs. In many types of research, individual learners are the focus of study, but in DBR, the elements of a designed experience themselves are also studied and explored.</p>
                <p>For example, a DBR study might compare two different design features of a robotics program with two different groups to see how these features actually impact the outcomes of the program. DBR integrates other learning theories and frameworks into interventions and supplements, rather than replacing them. DBR seeks to contribute to principles, frameworks, and theory that predict the qualities, elements, and characteristics of particular types of interventions and experiences that best lead to intended outcomes for particular programs.</p>
                <p>Two good introductory readings you can review to familiarize yourself with DBR are "Design-Based Research: A Methodological Toolkit for Engineering Change" by Sasha Barab in the third edition of The Cambridge Handbook of the Learning Sciences, and the foreword to Design-Based Research in Education: Theory and Applications (Philippakos, Howell, Pellegrino, eds., 2021).</p>
              </div>
            </div>
          </div>
          {/* GET INVOLVED */}
          {/* <div class="stemDbrLabBody-getInvolved-container">
            <div class="stemDbrLabBody-getInvolved-content-container">
              <div class="stemDbrLabBody-getInvolved-bold">
                <h2>How can I get involved?</h2>
              </div>
              <div class="stemDbrLabBody-getInvolved-paragraph">
                <p>Two good introductory readings you can review to familiarize yourself with DBR are "Design-Based Research: A Methodological Toolkit for Engineering Change" by Sasha Barab in the third edition of The Cambridge Handbook of the Learning Sciences, and the foreword to Design-Based Research in Education: Theory and Applications (Philippakos, Howell, Pellegrino, eds., 2021).</p>
                <p>As a result, when youth "don't know" something, it rarely means they have no ideas about the topic. Instead, they might have a partial understanding or hold some common misconception.</p>
              </div>
            </div>
          </div> */}
          {/* LAB PROJECTS */}
          <div id="stemDbrLabBody-labProjects-container">
            <div className="stemDbrLabBody-labProjects-content-container">
              <h2 className="stemDbrLabBody-labProjects-title">2022-2023 Lab Projects</h2>
              {/* <div className="stemDbrLabBody-labProjects-card-container"> */}
                <div className="stemDbrLabBody-labProjects-grid-container">
                  <div className="stemDbrLabBody-labProjects-image-container">
                    <img src="https://f10-research.s3.us-west-2.amazonaws.com/STEM+DBR+Lab/Mathematical+Modeling+with+Ocean+Data.jpg" alt="cover page of high school interns report"/>
                  </div>
                  {/* <div className="stemDbrLabBody-labProjects-info-container">  */}
                    {/* <div className="stemDbrLabBody-labProjects-tags">
                      <div className="stemDbrLabBody-labProjects-hash">
                        <div className="youthHash-teal">REPORT</div>
                        <div className="youthHash-hash">#Drama</div>
                      </div>
                    </div> */}
                    <div className="stemDbrLabBody-labProjects-text">
                      <h2>Mathematical Modeling with Ocean Data</h2>
                      <p>This project will research intervention designs exploring how youth use mathematical models to explore marine science concepts.</p>
                    </div>
                    {/* <div className="stemDbrLabBody-labProjects-link">
                      <p>Dec 7th, 2021</p>
                      <a href='https://drive.google.com/file/d/1k3kbUQE1OC-aZ663sFm5mXOvqrP211yL/view?usp=sharing' className="stemDbrLabBody-labProjects-content-link" target="_blank" rel="noopener noreferrer"><i class="fa-solid fa-arrow-right"></i></a>
                    </div> */}
                  {/* </div> */}
                </div>
              {/* </div> */}
              <div className="stemDbrLabBody-labProjects-grid-container">
                  <div className="stemDbrLabBody-labProjects-image-container">
                    <img src="https://f10-research.s3.us-west-2.amazonaws.com/STEM+DBR+Lab/Engineering+Design+Process+Project.jpeg" alt="cover page of high school interns report"/>
                  </div>
                  {/* <div className="stemDbrLabBody-labProjects-info-container">  */}
                    {/* <div className="stemDbrLabBody-labProjects-tags">
                      <div className="stemDbrLabBody-labProjects-hash">
                        <div className="youthHash-teal">REPORT</div>
                        <div className="youthHash-hash">#Drama</div>
                      </div>
                    </div> */}
                    <div className="stemDbrLabBody-labProjects-text">
                      <h2>Engineering Design Process Project</h2>
                      <p>This research project seeks to understand what properties of hands-on engineering instructional design best facilitate learning the engineering design process (EDP) and the extent to which these interventions promote the conceptual application of the EDP to problems outside the engineering domain.</p>
                    </div>
                    {/* <div className="stemDbrLabBody-labProjects-link">
                      <p>Dec 7th, 2021</p>
                      <a href='https://drive.google.com/file/d/1k3kbUQE1OC-aZ663sFm5mXOvqrP211yL/view?usp=sharing' className="stemDbrLabBody-labProjects-content-link" target="_blank" rel="noopener noreferrer"><i class="fa-solid fa-arrow-right"></i></a>
                    </div> */}
                  {/* </div> */}
              </div>
              <div className="stemDbrLabBody-labProjects-grid-container">
                  <div className="stemDbrLabBody-labProjects-image-container">
                    <img src="https://f10-research.s3.us-west-2.amazonaws.com/STEM+DBR+Lab/Ocean+Sensors+and+Learning.JPG" alt="cover page of high school interns report"/>
                  </div>
                  {/* <div className="stemDbrLabBody-labProjects-info-container">  */}
                    {/* <div className="stemDbrLabBody-labProjects-tags">
                      <div className="stemDbrLabBody-labProjects-hash">
                        <div className="youthHash-teal">REPORT</div>
                        <div className="youthHash-hash">#Drama</div>
                      </div>
                    </div> */}
                    <div className="stemDbrLabBody-labProjects-text">
                      <h2><a className="technologyMediaInformationLiteracyEducationProgramming-highlightsLink" href="https://www.foundry10.org/research/sensors-students-and-self" target="_blank" rel="noopener noreferrer">Ocean Sensors and Learning v.2.0</a></h2>
                      <p>This project seeks to understand how integrating instruction on the technical properties of ocean sensors at a chemical and physical level can change participant learning, self-efficacy, and STEM career interest outcomes.</p>
                    </div>
                    {/* <div className="stemDbrLabBody-labProjects-link">
                      <p>Dec 7th, 2021</p>
                      <a href='https://drive.google.com/file/d/1k3kbUQE1OC-aZ663sFm5mXOvqrP211yL/view?usp=sharing' className="stemDbrLabBody-labProjects-content-link" target="_blank" rel="noopener noreferrer"><i class="fa-solid fa-arrow-right"></i></a>
                    </div> */}
                  {/* </div> */}
              </div>
              <div className="stemDbrLabBody-labProjects-grid-container">
                  <div className="stemDbrLabBody-labProjects-image-container">
                    <img src="https://f10-research.s3.us-west-2.amazonaws.com/STEM+DBR+Lab/Childrens+Museum+SEL.jpeg" alt="cover page of high school interns report"/>
                  </div>
                  {/* <div className="stemDbrLabBody-labProjects-info-container">  */}
                    {/* <div className="stemDbrLabBody-labProjects-tags">
                      <div className="stemDbrLabBody-labProjects-hash">
                        <div className="youthHash-teal">REPORT</div>
                        <div className="youthHash-hash">#Drama</div>
                      </div>
                    </div> */}
                    <div className="stemDbrLabBody-labProjects-text">
                      <h2>Children's Museum SEL (CHISEL)</h2>
                      <p>What types of designed STEM and non-STEM experiences in children’s museums are most effective at facilitating and providing practice with SEL skills, and lead to more robust outcomes?</p>
                    </div>
                    {/* <div className="stemDbrLabBody-labProjects-link">
                      <p>Dec 7th, 2021</p>
                      <a href='https://drive.google.com/file/d/1k3kbUQE1OC-aZ663sFm5mXOvqrP211yL/view?usp=sharing' className="stemDbrLabBody-labProjects-content-link" target="_blank" rel="noopener noreferrer"><i class="fa-solid fa-arrow-right"></i></a>
                    </div> */}
                  {/* </div> */}
              </div>
              <div className="stemDbrLabBody-labProjects-grid-container">
                  <div className="stemDbrLabBody-labProjects-image-container">
                    <img src="https://f10-research.s3.us-west-2.amazonaws.com/STEM+DBR+Lab/STEM+SEL.jpeg" alt="cover page of high school interns report"/>
                  </div>
                  {/* <div className="stemDbrLabBody-labProjects-info-container">  */}
                    {/* <div className="stemDbrLabBody-labProjects-tags">
                      <div className="stemDbrLabBody-labProjects-hash">
                        <div className="youthHash-teal">REPORT</div>
                        <div className="youthHash-hash">#Drama</div>
                      </div>
                    </div> */}
                    <div className="stemDbrLabBody-labProjects-text">
                      <h2>STEM-SEL</h2>
                      <p>This project explores whether STEM instruction can be used as a vehicle for SEL, and it will seek to understand the best way to design SEL learning into STEM programming.</p>
                      <a href="https://drive.google.com/file/d/1Wv0paTQvqPbohvYorJdKA610_T1Ua-mB/view?usp=sharing" className="stemDbrLabBody-highlightsCard-link" target="_blank" rel="noopener noreferrer">View Curriculum<i className="fa-solid fa-arrow-right-long"></i></a>

                    </div>
                    {/* <div className="stemDbrLabBody-labProjects-link">
                      <p>Dec 7th, 2021</p>
                      <a href='https://drive.google.com/file/d/1k3kbUQE1OC-aZ663sFm5mXOvqrP211yL/view?usp=sharing' className="stemDbrLabBody-labProjects-content-link" target="_blank" rel="noopener noreferrer"><i class="fa-solid fa-arrow-right"></i></a>
                    </div> */}
                  {/* </div> */}
              </div>
              <div className="stemDbrLabBody-labProjects-grid-container">
                  <div className="stemDbrLabBody-labProjects-image-container">
                    <img src="https://f10-research.s3.us-west-2.amazonaws.com/STEM+DBR+Lab/Digital+Audio+and+Traditional+Audio+Musicality.jpeg" alt="cover page of high school interns report"/>
                  </div>
                  {/* <div className="stemDbrLabBody-labProjects-info-container">  */}
                    {/* <div className="stemDbrLabBody-labProjects-tags">
                      <div className="stemDbrLabBody-labProjects-hash">
                        <div className="youthHash-teal">REPORT</div>
                        <div className="youthHash-hash">#Drama</div>
                      </div>
                    </div> */}
                    <div className="stemDbrLabBody-labProjects-text">
                      <h2>Digital Audio and Traditional Audio Musicality (DATAM)</h2>
                      <p>This research project will seek to determine whether outcomes of learning core musicality concepts within a digital audio platform learning design differ from learning via a traditional music instruction design.</p>
                    </div>
                    {/* <div className="stemDbrLabBody-labProjects-link">
                      <p>Dec 7th, 2021</p>
                      <a href='https://drive.google.com/file/d/1k3kbUQE1OC-aZ663sFm5mXOvqrP211yL/view?usp=sharing' className="stemDbrLabBody-labProjects-content-link" target="_blank" rel="noopener noreferrer"><i class="fa-solid fa-arrow-right"></i></a>
                    </div> */}
                  {/* </div> */}
              </div>
            </div>
          </div>
          {/* CURRENT LAB MEMBERS */}
          <StemDbrLabMembers />
          {/* PUBLICATIONS */}
          <div class="stemDbrLabBody-publications-container">
            <div class="stemDbrLabBody-publications-content-container">
              <div class="stemDbrLabBody-publications-bold">
                <h2>Related Publications from Lab Members</h2>
              </div>
              <ul class="stemDbrLabBody-publications-list">
                <li>
                  <p>Windleharth, T. W., & Katagiri, C. (2022). Sensors, Students, and Self: Exploring Knowledge, Self-Efficacy, and Interest Impact of Ocean Sensor Learning on High School Marine Science Students. Sensors, 22(4), 1534.</p>
                  <a href="https://www.mdpi.com/1424-8220/22/4/1534" target="_blank" rel="noopener noreferrer">https://www.mdpi.com/1424-8220/22/4/1534</a>
                </li>
                <li>
                  <p>Yip, J., Windleharth, T. W., & Lee, J. H. (2017). Collaborative Scientizing in Pokémon GO Online Communities. Philadelphia, PA: International Society of the Learning Sciences.</p>
                  <a href="https://repository.isls.org//handle/1/228" target="_blank" rel="noopener noreferrer">https://repository.isls.org//handle/1/228</a>
                </li>
                <li>
                  <p>Bhattacharya, A., Windleharth, T. W., Ishii, R. A., Acevedo, I. M., Aragon, C. R., Kientz, J. A., ... & Lee, J. H. (2019, May). Group interactions in location-based gaming: A case study of raiding in pokémon go. In Proceedings of the 2019 CHI Conference on Human Factors in Computing Systems (pp. 1-12).</p>
                  <a href="https://dl.acm.org/doi/abs/10.1145/3290605.3300817" target="_blank" rel="noopener noreferrer">https://dl.acm.org/doi/abs/10.1145/3290605.3300817</a>
                </li>
                <li>
                  <p>Luke, J. J., & Windleharth, T. W. (2018). The Learning Value of Children’s Museums: Building a Field-Wide Research Agenda—A Landscape Review.</p>
                  {/* <a href="https://www.mdpi.com/1424-8220/22/4/1534" target="_blank" rel="noopener noreferrer">https://www.mdpi.com/1424-8220/22/4/1534</a> */}
                </li>
                <li>
                  <p>Pearce, K. E., Yip, J. C., Lee, J. H., Martinez, J. J., Windleharth, T. W., Bhattacharya, A., & Li, Q. (2022). Families playing animal crossing together: Coping with video games during the COVID-19 pandemic. Games and Culture, 17(5), 773-794.</p>
                  <a href="https://journals.sagepub.com/doi/pdf/10.1177/15554120211056125" target="_blank" rel="noopener noreferrer">https://journals.sagepub.com/doi/pdf/10.1177/15554120211056125</a>
                </li>
                <li>
                  <p>Martinez, J. J., Windleharth, T. W., Li, Q., Bhattacharya, A., Pearce, K. E., Yip, J., & Lee, J. H. (2022). Joint Media Engagement in Families Playing Animal Crossing: New Horizons during the COVID-19 Pandemic. Proceedings of the ACM on Human-Computer Interaction, 6(CSCW1), 1-22.</p>
                  <a href="https://dl.acm.org/doi/abs/10.1145/3512947" target="_blank" rel="noopener noreferrer">https://dl.acm.org/doi/abs/10.1145/3512947</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}