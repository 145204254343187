import React, { Component } from 'react';
import {
  useQuery,
  gql
} from "@apollo/client";
import PageHeader from '../components/page-header.js';
import Grid from '@material-ui/core/Grid';
import ContentBlocks from '../components/ContentBlocks';
import StoryCard from '../components/storyCard';
// import GlobalFooter from '../components/base/GlobalFooter';
import NewFooter from '../../pages-other/NewFooter.js';


const QUERY = gql`
    query NewQuery($slug: [String]) {
    entry(slug: $slug) {
      title
      postDate @formatDateTime(format: "d M Y")
      postDateAlt: postDate @formatDateTime(format: "Y-m-d")
      url
      ... on equityCommitment_equityCommitment_Entry {
        customTitle
        blurb
        featuredImage {
          title
          url
        }
        featuredImageCaption
        equityPrioritiesHeadline
        equityPrioritiesContent
        equityFeaturedPerson {
              title
              ... on teamMembers_default_Entry {
                teamMemberPosition
                teamMembersPronouns
                teamMemberImage {
                  url
                }
                teamMemberBio
                teamMemberEmail
              }
           
        }
        contentOptions {
          ... on contentOptions_standardContent_BlockType {
            contentTitle
            contentContent
            linkUrl {
              url
              customText
              target
            }
            contentAlignment
          }
          ... on contentOptions_icp_BlockType {
            icpTitle
            icpContent
              icpImage {
                  url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
          }
          ... on contentOptions_fwi_BlockType {
            fwiTitle
            fwiImage {
                url
            }
            fwiCaption
            fwiSize
          }
          ... on contentOptions_emphasized_BlockType {
            emphasizedTitle
            emphasizedSubheadline
            emphasizedOverline
            emphasizedImage {
              url
            }
            emphasizedImageCaption
            emphasizedContent
            emphasizedLink
            emphasizedBackgroundColor
          }
          ... on contentOptions_quote_BlockType {
                pullQuote
                quoteSocial
                quoteImage {
                  url
                }
                quoteAttribution
          }
          ... on contentOptions_quoteSlideshow_BlockType {
            quoteSliderTitle
            quotes {
              __typename
            }
          }
          ... on contentOptions_slider_BlockType {
            sliderTitle
            slides {
              __typename
              ... on slides_BlockType {
                slideImage {
                  url
                }
                slideHeadline
                slideContent
                slideLink {
                  customText
                  url
                  target
                }
              }
            }
          }
          ... on contentOptions_accordion_BlockType {
            accordionTitle
            accordion {
              __typename  
              ... on accordion_BlockType {
                accordionItemTitle
                accordionItemBlurb
                accordionItemImage {
                  url
                }
                accordionItemIcon {
                  url
                }
                accordionItemContent
              }
            }
          }
          ... on contentOptions_list_BlockType {
            listTitle
            list {
              __typename  
              ... on list_BlockType {
                listItemHeadline
                listItemContent
                listItemImage {
                  url
                }
                listItemLink {
                  url
                  customText
                  target
                }
              }
            }
          }
          ... on contentOptions_linkList_BlockType {
            linkListTitle
            linkListList {
              __typename  
              ... on linkListList_BlockType {
                linkListTitle
                linkUrl
                linkListBg {
                  url
                }
                linkListHoverTxt
                linkListTitle
              }
            }
            linkListDisplay
            linkListSize
            columnCount
          }
          ... on contentOptions_video_BlockType {
            videoTitle
            videoLink
            videoCaption
            videoImage {
              url
            }
          }
          ... on contentOptions_featuredResource_BlockType {
            featuredResource {
              title 
              slug
              ... on resources_default_Entry {
                blurb
                researchAsset {
                  url
                }
                interestAreaTeam {
                  ... on interestAreaTeam_BlockType {
                    interestAreaTeamMember {
                      title
                      url
                    }
                  }
                }
                researchExternalAuthors {
                  ... on researchExternalAuthors_BlockType {
                    researchExternalAuthorsAuthor
                  }
                }
                featuredImageCaption
              }
            }
          }
          ... on contentOptions_featuredPerson_BlockType {
            featuredPerson {
              title 
              url
              ... on teamMembers_default_Entry {
                teamMembersPronouns
                teamMemberRoles {
                  title
                }
                teamMemberImage {
                  url
                }
                teamMemberPosition
                teamMemberBio
              }
            }
          }
          ... on contentOptions_featuredStory_BlockType {
            featuredstory {
              title 
              url
              slug
              author {
                fullName
              }
              postDate @formatDateTime(format: "F j, Y")
              dateCreated @formatDateTime (format: "M j, Y")
              ... on stories_default_Entry {
                blurb
                featuredImage {
                  title
                  url
                }
                categories {
                  title
                }
                themes {
                  title
                }
                interestAreas {
                  title
                }
                researchExternalAuthors {
                  ... on researchExternalAuthors_BlockType {
                    researchExternalAuthorsAuthor
                  }
                }
                interestAreaTeam {
                  ... on interestAreaTeam_BlockType {
                    interestAreaTeamMember {
                      title
                    }
                  }
                }
              }
            }
          }
          ... on contentOptions_thirdPartyCode_BlockType {
            thirdPartyCodeTitle
            thirdPartyCodeCode 
          }
        }
      }
    }
  }
`

const newsQuery = gql`
  query latestNewsQuery {
    entries(section: "stories", limit: 3, orderBy: "dateCreated DESC", relatedToCategories: [{ id: 3069 }]) {
      title
      slug
      url
      dateCreated @formatDateTime (format: "M j, Y")
      postDate @formatDateTime (format: "F j, Y")id
      author {
        fullName
      }
      ... on stories_default_Entry {
        featuredImage {
          title
          url
        }
        featuredImageCaption
        categories {
          title
        }
        themes {
          title
        }
        interestAreas {
          title
        }
        interestAreaTeam {
          ... on interestAreaTeam_BlockType {
            interestAreaTeamMember {
              title
            }
          }
        }
        researchExternalAuthors {
          ... on researchExternalAuthors_BlockType {
            researchExternalAuthorsAuthor
          }
        }
      }
    }
  }
`

const NewsList = (teamData) => {

  return (
    <section className='latestNews'>
      <div className="container">
        <h2 className="text-xl_bold">Latest News</h2>
        <Grid container spacing={24}>
          <News />
        </Grid>
        <a className="button orange" href="/stories?themeCat=3069">View All  <i className="fas fa-arrow-right"></i></a>
      </div>

    </section>
  )
}


const News = () => {
  const { data, loading, error } = useQuery(newsQuery);
  if (loading) return null;
  if (error) return `Error! ${error}`;

  return (
    data.entries.map(
      (block) => {
        
        let props = {
          block: {block}
        }
        return (
          <Grid item xs={12} sm={6} md={4}>
            <StoryCard {...props} />
          </Grid>
        )
      }
    )
  )
}

const StrategicPriorities = (slug) => {
  const { data, loading, error } = useQuery(QUERY, {
    variables: {slug}
  });
  if (loading) return null;
  if (error) return `Error! ${error}`;

  // console.log('strategy');
  // console.log(data);
  return (
    <section className='stategicPriorities'>
      <div className='container'>
      <Grid container spacing={40}>
        <Grid item sm={12}>
          <h3 className='text-xl_bold text-center'>{data.entry.equityPrioritiesHeadline}</h3>
        </Grid>
        <Grid item md={8}>
          <div
            dangerouslySetInnerHTML={{
            __html: data.entry.equityPrioritiesContent,
            }}
          ></div>
        </Grid>
        <Grid item md={4}>
          {data.entry.equityFeaturedPerson[0].teamMemberImage && (
            <img src={data.entry.equityFeaturedPerson[0].teamMemberImage[0].url} alt={data.entry.equityFeaturedPerson.title} />
          )}
          <h4>Have questions? &nbsp;
            {data.entry.equityFeaturedPerson[0].teamMemberEmail && (
               <a href={"mailto:" + data.entry.equityFeaturedPerson[0].teamMemberEmail}>Email Me!</a>
            )}
          </h4>
          <h4>{data.entry.equityFeaturedPerson[0].title}</h4>
          <p>{data.entry.equityFeaturedPerson[0].teamMemberPosition}</p>
        </Grid>
      </Grid>
      </div>
    </section>
  )
}

const InlineContent = (slug) => {
  const { data, loading, error } = useQuery(QUERY, {
    variables: {slug}
  });
  if (loading) { return <div>loading</div> }
  if (error) { return <div>{error}</div> }

  return (
    <ContentBlocks data={data.entry} />
  );
}


export default class EquityCommitment extends Component {
  render() {
    return(
        <section className='equityCommitment'>
            <PageHeader slug={this.props.slug} />
            <NewsList />
            <StrategicPriorities slug='equity-commitment'/>
            <InlineContent slug='equity-commitment'/>
            {/* <GlobalFooter /> */}
            <NewFooter />
        </section>
      
    );
  }
}