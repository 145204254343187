import React, { Component } from 'react';
import './annualReport2021Sharing-Overview.css';

export default class AnnualReportSharingOverview extends Component {
  render() {
    return(
      <div id="annualReport2021sharing-overview-container">
          <div className="annualReport2021sharing-overview-text-container">
            <h1>Building relationships through sharing</h1>
            <p>
            We approach sharing as a way to use our communications resources to build relationships in the education community.  Whether we are connecting with individuals, partners, or the public at large, we strive to form meaningful connections and build on those relationships to inspire others in exploring broad approaches to learning and creating value for youth.  We hope that people find our work useful, reach out to build new connections with us, and check back from time to time to see what is new.              <br />
              <br />
              <br />
              One of the most rewarding aspects of sharing is when folks are able to utilize our work to enhance their own. Throughout the past 18 months, we have had the opportunity to see some fantastic outcomes from our collaborations.  For example, some community partners have used our data to apply for new grant-funding or to make youth-centered cases to legislators. Others have been able to expand their community reach through our partnerships and inspire other educators in their fields by sharing about their projects with students. Those same partners have also shared their work with us, and we have found ourselves drawing on their immense knowledge and experience in our own work. This two-way communication is crucial to the way we maximize the value for youth jointly with our partners.
            </p>
          </div>
      </div>
    );
  }
}