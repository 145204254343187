import React, { Component } from 'react';
import './resourcesLandingPage-Header.css';

export default class ResourcesLandingPageHeader extends Component {
  render() {
    return(
      <div id="resourcesLandingPage-header-container">
          <div class="resourcesLandingPage-header-content-container">
            <h1>Marine Science Resources</h1>
          </div>
      </div>
    );
  }
}