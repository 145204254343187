import React, { Component } from 'react';
import './brierTerrace-Links.css';

export default class brierTerraceLinks extends Component {
  render() {
    return(
      <div id="brierTerrace-links-container">
        <div class="brierTerrace-links-content-container">
          <div class="interestArea-cardsList2-container">
            <div class="interestArea-domain-title">
              <h2>Resources</h2>
            </div>
            <ul id="interestArea-cardsList2" class="interestArea-events-list2">
              <a href={'https://drive.google.com/file/d/1Q0L4qa6QRri7CR3b8sKH8F2sfZECtcAr/view?usp=sharing'} target="_blank">
               <li>
                <div class="brierTerrace-cards-container">
                  <img src="https://f10-image.s3-us-west-2.amazonaws.com/New+Site/Research/brier+terrace/Brier+Terrace+Whitepaper.png" alt="exterior of Brier Terrace" />
                  <p class="brierTerrace-cards-title">View Survey Results [PDF]</p>
                  <div class="brierTerrace-cards-overlay"></div>
                </div>
                </li>
              </a>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}