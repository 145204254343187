import React, { Component } from 'react';
import TechnologyMediaInformationLiteracyBreadcrumb from './technologyMediaInformationLiteracy-Breadcrumb';
import TechnologyMediaInformationLiteracyIntro from './technologyMediaInformationLiteracy-Intro';
// import TechnologyMediaInformationLiteracyStories from './technologyMediaInformationLiteracy-Stories';
import ResearchTopics from './ResearchTopics/ResearchTopics';
import TechnologyMediaInformationLiteracyEducationProgramming from './technologyMediaInformationLiteracy-EducationProgramming';
import TechnologyMediaInformationLiteracyTeam from './technologyMediaInformationLiteracy-Team';
import TechnologyMediaInformationLiteracyContact from './technologyMediaInformationLiteracy-Contact';
import NewFooter from '../../pages-other/NewFooter';
import './technologyMediaInformationLiteracy-Content.css';

export default class TechnologyMediaInformationLiteracyContent extends Component {
  render() {
    return(
      <div id="technologyMediaInformationLiteracy-content-container">
        <TechnologyMediaInformationLiteracyBreadcrumb />
        <TechnologyMediaInformationLiteracyIntro />
        {/* <TechnologyMediaInformationLiteracyStories /> */}
        <ResearchTopics />
        <TechnologyMediaInformationLiteracyEducationProgramming />
        <TechnologyMediaInformationLiteracyTeam />
        <TechnologyMediaInformationLiteracyContact />
        <NewFooter />
      </div>
    );
  }
}