import React, { Component } from 'react';
// import AnnualReportCovidOverview from './annualReport2021Covid-Overview';
import AnnualReportCovidHighlights from './annualReport2021Covid-Highlights';
import AnnualReportLinks from '../annualReport2021_Components/10_Links/AnnualReport-Links';
import NewFooter from '../../../pages-other/NewFooter';
import './annualReport2021Covid-Content.css';

export default class AnnualReportCovidContent extends Component {
  render() {
    return(
      <div id="annualReport2021covid-content-container">
        {/* <AnnualReportCovidOverview /> */}
        <AnnualReportCovidHighlights />
        <AnnualReportLinks />
        <NewFooter />
      </div>
    );
  }
}