import React, { Component } from 'react';
import './insight-Description.css';

export default class InsightDescription extends Component {
  render() {
    return(
      <div id="insight-description-container">
        <div class="insight-description-content-container">
            <p>foundry10 provides scholarship support to promote additional access to high school students who might not otherwise be able to attend Harborview’s Pediatric Injury Prevention Student Internship Training (INSIGHT) program. This intensive 4-week program is for individuals interested in public health, biomedical research and medicine. Learners take a deep dive into injury prevention research by participating in a variety of project-based, didactic and social activities.</p>
        </div>
      </div>
    );
  }
}