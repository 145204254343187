import React, { Component } from 'react';
import './newEquity-Statements.css';

export default class NewEquityStatements extends Component {
  render() {
    return (
      <div className="newEquityStatements-container">
        <div className="newEquityStatements-content-container">
          <h1>Statements</h1>
          <div className="newEquityStatements-info-container">
            <div className="newEquityStatements-info-image">
              <img src="https://f10-equity.s3-us-west-2.amazonaws.com/Equity+Page+Images/AAPI+Solidarity.png" alt="foundry10 solidarity statement"/>
            </div>
            <div className="newEquityStatements-info-text">
              <h2>AAPI Solidarity Statement</h2>
              <p>We stand with the Asian American and Pacific Islander (AAPI) youth, educators and partners in our community.</p>
              <a href="https://medium.com/the-foundry10-voice/statement-of-support-and-solidarity-with-the-aapi-community-7544e8ab12d0" className="newEquityStatements-link">Read<i className="fa-solid fa-arrow-right-long"></i></a>
            </div>
          </div>
          <div className="newEquityStatements-info-container">
            <div className="newEquityStatements-info-image">
              <img src="https://f10-equity.s3-us-west-2.amazonaws.com/Equity+Page+Images/BLM+Solidarity.png" alt="foundry10 black lives matter"/>
            </div>
            <div className="newEquityStatements-info-text">
              <h2>BLM Solidarity Statement</h2>
              <p>Black Lives Matter. foundry10's statement on the murder of George Floyd.</p>
              <a href="https://drive.google.com/file/d/1mcqbFwmuCKu4iASNQre2dRYB3zIr4Tgy/view" className="newEquityStatements-link">Read<i className="fa-solid fa-arrow-right-long"></i></a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}