import React from "react";
import './Ocean-Engineering.css';

export default function OceanEngineering({ title, subtitle, dark, id }) {
  return (
    // <div className={"section" + (dark ? " section-dark" : "")}>
    <div className="oceanEngineering-container">
      <div className="oceanEngineering-content-container" id={id}>
        <div className="oceanEngineering-final-content-container">
          <div className="oceanEngineering-illustration">
            <h1>Click below to learn more!</h1>
            <a class="oceanEngineering-link" href="/marine-science-careers/ocean-engineering">
              <img src='https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/oceanengineering1.png' alt='ocean engineering illustration'/>
              <p class="oceanEngineering-title">Meet Brandi, Dom, & Jake!</p>
              <div class="oceanEngineering-cards-overlay"></div>
            </a>
          </div>
          <div className="oceanEngineering-description">
            <h2>"At-Sea Architect"</h2>
            <p>RELATED TITLES: Project Technician, Ship Tech, Marine Mechanic</p>
            <p>Solving complex technical problems too easy? How about solving them while at sea for weeks at a time? That’s the life for marine technicians and engineers as they work to coordinate scientific efforts aboard exploration and research vessels.</p>
            <div className="oceanEngineering-requirements">
              <h2>Basic Degree/Training Requirements</h2>
              <ul className="oceanEngineering-iconList">
                <li>
                  <div class="oceanEngineering-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/black-HS-Diploma.png" alt="high school diploma" />
                  </div>
                </li>
                <li>
                  <div class="oceanEngineering-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/black-Undergrad-Cap.png" alt="undergrad degree" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}