import React, { Component } from 'react';
import ResearchBreadcrumb from './researchRedesign-Breadcrumb';
import ResearchHighlights from './researchRedesign-Highlights';
import ResearchLabs from './researchRedesign-Labs';
import ThemesCards from './researchRedesign-ThemesCards';
import ResourceDropDown from './researchRedesign-ResourceDropDown';
import ResearchCollaborate from './researchRedesign-Collaborate';
import NewFooter from '../../../pages-other/NewFooter';
import './researchRedesign-Content.css';

export default class ResearchRedesignBody extends Component {
  render() {
    return(
      <div id="researchRedesign-body-container">
        <ResearchBreadcrumb />
        <ResearchHighlights />
        <ResearchLabs />
        <ThemesCards />
        <ResourceDropDown />
        <ResearchCollaborate />
        <NewFooter />
      </div>
    );
  }
}
