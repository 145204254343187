import React, { Component } from 'react';
import AnnualReportDramaticArtsOverview from './annualReport2021DramaticArts-Overview';
import AnnualReportDramaticArtsSpotlights from './annualReport2021DramaticArts-Spotlights';
import AnnualReport2021ProgramLinks from '../annualReport2021ProgramLinks/AnnualReport2021-ProgramLinks';
import NewFooter from '../../../../pages-other/NewFooter';
import './annualReport2021DramaticArts-Content.css';

export default class AnnualReportDramaticArtsContent extends Component {
  render() {
    return(
      <div id="annualReport2021DramaticArts-content-container">
        <AnnualReportDramaticArtsOverview />
        <AnnualReportDramaticArtsSpotlights />
        <AnnualReport2021ProgramLinks />
        <NewFooter />
      </div>
    );
  }
}