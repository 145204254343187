import React from 'react';
import './healthWellBeing-Intro.css';

const HealthWellBeingIntro = () => {
   return (
    <div class="healthWellBeing-intro-container">
      <div class="healthWellBeing-intro-content-container">
        <div class="healthWellBeing-intro-paragraph">
          <p>Supporting the physical and mental well-being of young people is vital for ensuring they can learn, grow, and fully participate as members of society. foundry10 takes a holistic approach to promoting the physical, mental, social, and emotional health of young people through a unique blend of research, programming and philanthropy.</p>
        </div>
      </div>
    </div>
   )
}
export default HealthWellBeingIntro;