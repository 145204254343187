import React, { Component } from 'react';
import './ic.css';

class IC extends Component {
  render() {
    return (
      <div id="ic-container">
        <div class="everyday-section-title-ic">
          <h4>INTRO (THE IDEA)</h4>
        </div>
        <div class="ic-content-container">
          <h2>But one thing was missing at all of these...</h2>
          <p>Where was the work by young artists? These events were all for adults.</p>
          <p>With youth voice seemingly absent from every art event we attended, we decided to do something about it.</p>
          <p>But what? A series of workshops? A bunch of small events? OR...</p>
        </div>
      </div>
    );
  }
}

export default IC;