import React from "react";
import Emphasized from "../../components/blocks/Emphasized";
import Video from "../../components/blocks/Video";
import FeaturedResource from "../../components/blocks/FeaturedResource";

const TopInlineContent = ({ entry }) => {
  return entry.contentOptions.map((block, index) => (
    <React.Fragment key={index}>
      {block.__typename === "contentOptions_emphasized_BlockType" && (
        <Emphasized block={block} />
      )}
      {block.__typename === "contentOptions_featuredResource_BlockType" && (
        <FeaturedResource block={block} />
      )}
      {block.__typename === "contentOptions_video_BlockType" && (
        <Video block={block} />
      )}
    </React.Fragment>
  ));
};

const TopContentBlocks = ({ data }) => {
  return (
    <div className="contentBlocks-container">
      <TopInlineContent entry={data} />
    </div>
  );
};

export default TopContentBlocks;
