import React, { Component } from 'react';
import './annualReport2021Internships-Overview.css';

export default class AnnualReportInternshipsOverview extends Component {
  render() {
    return(
      <div id="annualReport2021Internships-overview-container">
          <div className="annualReport2021Internships-overview-text-container">
            <h1>Internships Team</h1>
            <p>
            When the pandemic hit, we were very worried about our High School Internship program. Would it be able to survive when students could not come on site to work on their projects? We were thrilled when our team was able to come together and not only continue but expand our internship program to include new high schools and reach many more students. Things have gone so well, we plan to continue doing virtual internships along with in-person internships post-pandemic. Going remote has also allowed us to continue the important research that we do with our interns. Previously, our team explored how today’s high school students are preparing to enter the workforce after high school or secondary education. Next, we plan to delve deep into the research we conducted during the summer of 2020 and find the best ways to share the stories of our summer interns and what we learned about how to conduct remote internships.
            </p>
          </div>
      </div>
    );
  }
}