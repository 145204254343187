import React, { Component } from 'react';
import './annualReport2021ArtisticDesign-Overview.css';

export default class AnnualReportArtisticDesignOverview extends Component {
  render() {
    return(
      <div id="annualReport2021ArtisticDesign-overview-container">
          <div className="annualReport2021ArtisticDesign-overview-text-container">
            <h1>Artistic Design Team</h1>
            <p>
            This year, the Artistic Design focused on addressing two questions: How do we support young artists during a pandemic? How do the events of 2020 inform our team's work around equity at foundry10? Through donations of artistic supplies and tools, school workshops, and even hosting our own annual youth art gallery virtually, we pivoted in a time of crisis.
            </p>
          </div>
      </div>
    );
  }
}