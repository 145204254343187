import React, { Component } from 'react';
import './stemDbrLab-Header.css';

export default class StemDbrLabHeader extends Component {
  render() {
    return(
      <div id="stemDbrLab-header-container">
          <div class="stemDbrLab-header-content-container">
            <h1>STEM Design-Based Research Lab</h1>
          </div>
      </div>
    );
  }
}