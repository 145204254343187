import React, { useState } from 'react';
import './ResearchTopics-Two.css';

function ResearchTopicsTwo() {
    const [isActive, setIsActive] = useState(false);
    // const [hasMore, setHasMore] = useState(false);

    return (


            <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-item">
                <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-title" onClick={() => setIsActive(!isActive)}>
                    <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-image">
                        <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Technology%2C+Media%2C+and+Information+Literacy/Research+Topics/IA+Team+Page+Icons_Healthy+and+Responsible+Digital+Habits+1.png" alt="youth using mobile phone"/>
                    </div>
                    <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-text">
                        <h2 className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-title-text">Healthy and Responsible Digital Habits</h2>
                        {/* <h3>{research.subtitle}</h3> */}
                    </div>
                    <i className={ isActive ?  `fa-solid fa-angle-up`:`fa-solid fa-angle-down`}></i>                  
                </div>
                {isActive && <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-content">
                    <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-description">
                        <p>We aim to prepare youth and educators to be informed and literate consumers and users of digital technologies, media, and information. This area of research focuses on filling knowledge gaps about the technical, critical thinking, and social and emotional skills that young people need to safely and responsibly navigate the vast sea of digital technology, media, and information.</p>
                    </div>  
                    <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-descriptionTwo">
                        <h2>Guiding Questions:</h2>
                        <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How can developmental science inform the ways we help young people develop healthy relationships with digital tools, media, and other people in online communities?</p>
                        </div>
                        <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How can we support young people in being critical users of digital technologies and media sources?</p>
                        </div>
                    </div>
                    <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-descriptionTwo">
                        <h2>Explore foundry10 Research and Resources Related to This Topic:</h2>
                        <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p><a href="https://medium.com/the-foundry10-voice/thoughtful-and-ethical-approaches-to-using-ai-in-schools-7c36797f2c68" target="_blank" rel="noopener noreferrer">Thoughtful and Ethical Approaches to Using AI in Schools (blog)</a></p>
                        </div>
                        <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p><a href="https://www.foundry10.org/research/understanding-social-medias-influence-on-teen-well-being" target="_blank" rel="noopener noreferrer">Understanding Social Media's Influence on Teen Well-Being (white paper)</a></p>
                        </div>
                    </div>
                    {/* <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-descriptionThree">
                        <h2>Curriculum + Education</h2>
                        <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>What are important considerations for school-based civic learning opportunities that support meaningful youth engagement online?</p>
                        </div>
                        <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How should we approach curriculum focused on digital citizenship more effectively to support the skills youth need to thrive in today’s society?</p>
                        </div>
                        <div className="technologyMediaInformationLiteracy-researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>To what extent are young people’s perspectives included in the development & implementation of curriculum about being a digital citizen?</p>
                        </div>
                    </div> */}
                </div>}
            </div>

    );
}

export default ResearchTopicsTwo;
