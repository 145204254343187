import React, { Component } from 'react';
import {
  useQuery,
  gql
} from "@apollo/client";
import { InlineShareButtons } from 'sharethis-reactjs';
import Breadcrumbs from '../components/breadcrumbs.js';
import ContentBlocks from '../components/ContentBlocks';
// import GlobalFooter from '../components/base/GlobalFooter';
import NewFooter from '../../pages-other/NewFooter.js';
import Grid from '@material-ui/core/Grid';
import StoryCard from '../components/storyCard';

const segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
const segment_array = segment_str.split( '/' );
const currentUrl=window.location.href.split('/')
const pathName=currentUrl
const last_segment = segment_array.pop();

// Get the preview token from the URL
let m = document.location.href.match(/\btoken=([^&]+)/);
let token = m ? m[1] : '';

// Then forward that on whenever you are sending an API request
let url = `...?token=${token}`;

console.log(url);

const QUERY = gql`
    query GetStories($slug: [String]) {
    entry(slug: $slug) {
      sectionHandle
      title
      postDate @formatDateTime(format: "d M Y")
      postDateAlt: postDate @formatDateTime(format: "F j, Y")
      author {
        fullName
      }
      url
      ... on stories_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        featuredImageCaption
        categories {
          title
        }
        themes {
          title
        }
        interestAreas {
          title
        }
        researchExternalAuthors {
          ... on researchExternalAuthors_BlockType {
            researchExternalAuthorsAuthor
            researchExternalAuthorsPosition
            researchExternalAuthorsBio
            researchExternalAuthorsImage {
              url
            }
            researchExternalAuthorsLink {
              url
              customText
            }
          }
        }
        interestAreaTeam {
          ... on interestAreaTeam_BlockType {
            interestAreaTeamMember {
              title
              ... on teamMembers_default_Entry {
                teamMemberPosition
                teamMembersPronouns
                teamMemberImage {
                  url
                }
                teamMemberBio
                teamMemberEmail
              }
            }
          }
        }
        contentOptions {
          ... on contentOptions_standardContent_BlockType {
            contentTitle
            contentContent
            linkUrl {
              url
              customText
              target
            }
            contentAlignment
          }
          ... on contentOptions_icp_BlockType {
            icpTitle
            icpContent
              icpImage {
                  url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
          }
          ... on contentOptions_fwi_BlockType {
            fwiTitle
            fwiImage {
                url
            }
            fwiCaption
            fwiSize
          }
          ... on contentOptions_emphasized_BlockType {
                emphasizedTitle
                emphasizedSubheadline
                emphasizedOverline
                emphasizedImage {
                    url
                }
                emphasizedImageCaption
                emphasizedContent
                emphasizedLink
                emphasizedBackgroundColor
            }
            ... on contentOptions_quote_BlockType {
                pullQuote
                quoteSocial
                quoteImage {
                  url
                }
                quoteAttribution
            }
            ... on contentOptions_quoteSlideshow_BlockType {
              quoteSliderTitle
              quotes {
                __typename  
                ... on quotes_BlockType {
                  quotesQuote
                  quotesQuoteImage {
                    url
                  }
                  quotesQuoteAttribution
                }
              }
            }
            ... on contentOptions_accordion_BlockType {
              accordionTitle
              accordion {
                __typename  
                ... on accordion_BlockType {
                  accordionItemTitle
                  accordionItemBlurb
                  accordionItemImage {
                    url
                  }
                  accordionItemIcon {
                    url
                  }
                  accordionItemContent
                }
              }
            }
            ... on contentOptions_linkList_BlockType {
              linkListTitle
              linkListList {
                __typename  
                ... on linkListList_BlockType {
                  linkListTitle
                  linkUrl
                  linkListBg {
                    url
                  }
                  linkListHoverTxt
                  linkListTitle
                }
              }
              linkListDisplay
              linkListSize
              columnCount
            }
            ... on contentOptions_video_BlockType {
              videoTitle
              videoLink
              videoCaption
              videoImage {
                url
              }
            }
        }
      }

      ... on resources_default_Entry {
        customTitle
        blurb
        featuredImage {
          title
          url
        }
        featuredImageCaption
        contentOptions {
          ... on contentOptions_standardContent_BlockType {
            contentTitle
            contentContent
            linkUrl {
              url
              customText
              target
            }
            contentAlignment
          }
          ... on contentOptions_icp_BlockType {
            icpTitle
            icpContent
              icpImage {
                  url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
          }
          ... on contentOptions_fwi_BlockType {
            fwiTitle
            fwiImage {
                url
            }
            fwiCaption
            fwiSize
          }
          ... on contentOptions_emphasized_BlockType {
            emphasizedTitle
            emphasizedSubheadline
            emphasizedOverline
            emphasizedImage {
                url
            }
            emphasizedImageCaption
            emphasizedContent
            emphasizedLink
            emphasizedBackgroundColor
          }
          ... on contentOptions_quote_BlockType {
            pullQuote
            quoteSocial
            quoteImage {
              url
            }
            quoteAttribution
          }
          ... on contentOptions_quoteSlideshow_BlockType {
            quoteSliderTitle
            quotes {
              __typename  
              ... on quotes_BlockType {
                quotesQuote
                quotesQuoteImage {
                  url
                }
                quotesQuoteAttribution
              }
            }
          }
          ... on contentOptions_accordion_BlockType {
            accordionTitle
            accordion {
              __typename  
              ... on accordion_BlockType {
                accordionItemTitle
                accordionItemBlurb
                accordionItemImage {
                  url
                }
                accordionItemIcon {
                  url
                }
                accordionItemContent
              }
            }
          }
          ... on contentOptions_video_BlockType {
              videoTitle
              videoLink
              videoCaption
              videoImage {
                url
              }
            }
        }
      }
      ... on programs_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        featuredImageCaption
        interestAreaTeam {
          ... on interestAreaTeam_BlockType {
            interestAreaTeamMember {
              title
              ... on teamMembers_default_Entry {
                teamMemberPosition
                teamMembersPronouns
                teamMemberImage {
                  url
                }
                teamMemberBio
                teamMemberEmail
              }
            }
          }
        }
        contentOptions {
          ... on contentOptions_standardContent_BlockType {
            contentTitle
            contentContent
            linkUrl {
              url
              customText
              target
            }
            contentAlignment
          }
          ... on contentOptions_icp_BlockType {
            icpTitle
            icpContent
              icpImage {
                  url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
          }
          ... on contentOptions_fwi_BlockType {
            fwiTitle
            fwiImage {
                url
            }
            fwiCaption
            fwiSize
          }
          ... on contentOptions_emphasized_BlockType {
            emphasizedTitle
            emphasizedSubheadline
            emphasizedOverline
            emphasizedImage {
                url
            }
            emphasizedImageCaption
            emphasizedContent
            emphasizedLink
            emphasizedBackgroundColor
          }
          ... on contentOptions_quote_BlockType {
            pullQuote
            quoteSocial
            quoteImage {
              url
            }
            quoteAttribution
          }
          ... on contentOptions_quoteSlideshow_BlockType {
            quoteSliderTitle
            quotes {
              __typename  
              ... on quotes_BlockType {
                quotesQuote
                quotesQuoteImage {
                  url
                }
                quotesQuoteAttribution
              }
            }
          }
          ... on contentOptions_accordion_BlockType {
            accordionTitle
            accordion {
              __typename  
              ... on accordion_BlockType {
                accordionItemTitle
                accordionItemBlurb
                accordionItemImage {
                  url
                }
                accordionItemIcon {
                  url
                }
                accordionItemContent
              }
            }
          }
          ... on contentOptions_video_BlockType {
              videoTitle
              videoLink
              videoCaption
              videoImage {
                url
              }
            }
        }
      }

      ... on opportunities_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        featuredImageCaption
        contentOptions {
          ... on contentOptions_standardContent_BlockType {
            contentTitle
            contentContent
            linkUrl {
              url
              customText
              target
            }
            contentAlignment
          }
          ... on contentOptions_icp_BlockType {
            icpTitle
            icpContent
              icpImage {
                  url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
          }
          ... on contentOptions_fwi_BlockType {
            fwiTitle
            fwiImage {
                url
            }
            fwiCaption
            fwiSize
          }
          ... on contentOptions_emphasized_BlockType {
            emphasizedTitle
            emphasizedSubheadline
            emphasizedOverline
            emphasizedImage {
                url
            }
            emphasizedImageCaption
            emphasizedContent
            emphasizedLink
            emphasizedBackgroundColor
          }
          ... on contentOptions_quote_BlockType {
            pullQuote
            quoteSocial
            quoteImage {
              url
            }
            quoteAttribution
          }
          ... on contentOptions_quoteSlideshow_BlockType {
            quoteSliderTitle
            quotes {
              __typename  
              ... on quotes_BlockType {
                quotesQuote
                quotesQuoteImage {
                  url
                }
                quotesQuoteAttribution
              }
            }
          }
          ... on contentOptions_accordion_BlockType {
            accordionTitle
            accordion {
              __typename  
              ... on accordion_BlockType {
                accordionItemTitle
                accordionItemBlurb
                accordionItemImage {
                  url
                }
                accordionItemIcon {
                  url
                }
                accordionItemContent
              }
            }
          }
          ... on contentOptions_video_BlockType {
              videoTitle
              videoLink
              videoCaption
              videoImage {
                url
              }
            }
        }
      }

      ... on projects_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        featuredImageCaption
        interestAreaTeam {
          ... on interestAreaTeam_BlockType {
            interestAreaTeamMember {
              title
              ... on teamMembers_default_Entry {
                teamMemberPosition
                teamMembersPronouns
                teamMemberImage {
                  url
                }
                teamMemberBio
                teamMemberEmail
              }
            }
          }
        }
        contentOptions {
          ... on contentOptions_standardContent_BlockType {
            contentTitle
            contentContent
            linkUrl {
              url
              customText
              target
            }
            contentAlignment
          }
          ... on contentOptions_icp_BlockType {
            icpTitle
            icpContent
              icpImage {
                  url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
          }
          ... on contentOptions_fwi_BlockType {
            fwiTitle
            fwiImage {
                url
            }
            fwiCaption
            fwiSize
          }
          ... on contentOptions_emphasized_BlockType {
            emphasizedTitle
            emphasizedSubheadline
            emphasizedOverline
            emphasizedImage {
                url
            }
            emphasizedImageCaption
            emphasizedContent
            emphasizedLink
            emphasizedBackgroundColor
          }
          ... on contentOptions_quote_BlockType {
            pullQuote
            quoteSocial
            quoteImage {
              url
            }
            quoteAttribution
          }
          ... on contentOptions_quoteSlideshow_BlockType {
            quoteSliderTitle
            quotes {
              __typename  
              ... on quotes_BlockType {
                quotesQuote
                quotesQuoteImage {
                  url
                }
                quotesQuoteAttribution
              }
            }
          }
          ... on contentOptions_accordion_BlockType {
            accordionTitle
            accordion {
              __typename  
              ... on accordion_BlockType {
                accordionItemTitle
                accordionItemBlurb
                accordionItemImage {
                  url
                }
                accordionItemIcon {
                  url
                }
                accordionItemContent
              }
            }
          }
          ... on contentOptions_video_BlockType {
              videoTitle
              videoLink
              videoCaption
              videoImage {
                url
              }
            }
        }
      }
      ... on press_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        featuredImageCaption
        themes {
          title
        }
        interestAreas {
          title
        }
        contentOptions {
          ... on contentOptions_standardContent_BlockType {
            contentTitle
            contentContent
            linkUrl {
              url
              customText
              target
            }
            contentAlignment
          }
          ... on contentOptions_icp_BlockType {
            icpTitle
            icpContent
              icpImage {
                  url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
          }
          ... on contentOptions_fwi_BlockType {
            fwiTitle
            fwiImage {
                url
            }
            fwiCaption
            fwiSize
          }
          ... on contentOptions_emphasized_BlockType {
                emphasizedTitle
                emphasizedSubheadline
                emphasizedOverline
                emphasizedImage {
                    url
                }
                emphasizedImageCaption
                emphasizedContent
                emphasizedLink
                emphasizedBackgroundColor
            }
            ... on contentOptions_quote_BlockType {
                pullQuote
                quoteSocial
                quoteImage {
                  url
                }
                quoteAttribution
            }
            ... on contentOptions_quoteSlideshow_BlockType {
              quoteSliderTitle
              quotes {
                __typename  
                ... on quotes_BlockType {
                  quotesQuote
                  quotesQuoteImage {
                    url
                  }
                  quotesQuoteAttribution
                }
              }
            }
            ... on contentOptions_accordion_BlockType {
              accordionTitle
              accordion {
                __typename  
                ... on accordion_BlockType {
                  accordionItemTitle
                  accordionItemBlurb
                  accordionItemImage {
                    url
                  }
                  accordionItemIcon {
                    url
                  }
                  accordionItemContent
                }
              }
            }
            ... on contentOptions_linkList_BlockType {
              linkListTitle
              linkListList {
                __typename  
                ... on linkListList_BlockType {
                  linkListTitle
                  linkUrl
                  linkListBg {
                    url
                  }
                  linkListHoverTxt
                  linkListTitle
                }
              }
              linkListDisplay
              linkListSize
              columnCount
            }
            ... on contentOptions_video_BlockType {
              videoTitle
              videoLink
              videoCaption
              videoImage {
                url
              }
            }
        }
      }

    }
  }
`

const RelatedPostsQuery = gql`
  query GetRelatedStories($channel: [String], $cat: [QueryArgument]) {
    entries(section: $channel, limit: 3, relatedToCategories: [{ id: $cat }]) {
      title
      slug
      url
      author {
        fullName
      }
      dateCreated @formatDateTime (format: "M j, Y")
      ... on stories_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        researchExternalAuthors {
          ... on researchExternalAuthors_BlockType {
            researchExternalAuthorsAuthor
          }
        }
        interestAreaTeam {
          ... on interestAreaTeam_BlockType {
            interestAreaTeamMember {
              title
            }
          }
        }
        categories {
          title
        }
      }
    }
  }
`
const RelatedPosts = () => { 
  const { data, loading, error } = useQuery(RelatedPostsQuery, {
    variables: {
      channel: 'stories',
      cat: pathName[4] == 'press' ? 691:690
    }
  });
  if (loading) { return <div>loading</div> }
  if (error) { return <div>{error}</div> }
  return (
    
    <div className='relatedPosts'>
      <div className='container'>
        <h2 className='text-large'>Explore related stories</h2>
        <Grid container spacing={24}>
          {data.entries.map(
            (block, index) => {
            
              let props = {
                block: {block}
              }
              return (
                <Grid item sm={6} md={4}>
                  <StoryCard {...props} />
                </Grid>
              )
            }
          )}
        </Grid>
        <a class="button" href="/stories">View more stories  <i class="fas fa-arrow-right" aria-hidden="true"></i></a>
      </div>
    </div>
  );
}

const InlineContent = (slug) => {
  const { data, loading, error } = useQuery(QUERY, {
    variables: {slug}
  });
  const shareThisStyle = {
    paddingLeft: "20px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  };
  if (loading) { return <div>loading</div> }
  if (error) { return <div>{error}</div> }
  if (data.entry) {
    console.log('**** here');
    return (
      <div className='entryContent'>
        <div className='container'>
          {data.entry.categories && (
            <h5 className='catLabel strong'>{data.entry.categories[0].title}</h5>
          )}
          <h1 className='text-xl_bold'>{data.entry.title}</h1>
          {data.entry.blurb && (
              <div className='entryBlurb'
                  dangerouslySetInnerHTML={{
                  __html: data.entry.blurb,
                  }}
              ></div>
          )}
          
          {data.entry.featuredImage[0] && (
            <img className='featuredImage' src={data.entry.featuredImage[0].url} alt={data.entry.title} />
          )}
          {data.entry.featuredImageCaption && ( 
            <p className='featuredImageCaption text-half-step'>{data.entry.featuredImageCaption}</p>
          )}
          <ContentBlocks data={data.entry} />
          <div className='catTags'>
            {data.entry.themes && (
              data.entry.themes.map((block) => {
                return <a href="/"><i class="fa-solid fa-tag"></i>{block.title}</a>
              })
            )}
  
            {data.entry.interestAreas && (
              data.entry.interestAreas.map((block) => {
                return <a href="/"><i class="fa-solid fa-tag"></i>{block.title}</a>
              })
            )}
          
            <div className="share">
              <a className="text-half-step_bold st-custom-button" data-network="sharethis" style={shareThisStyle}>
                <InlineShareButtons
                  config={{
                      alignment: 'center',  // alignment of buttons (left, center, right)
                      color: 'social',      // set the color of buttons (social, white)
                      // enabled: true,        // show/hide buttons (true, false)
                      font_size: 16,        // font size for the buttons
                      labels: 'cta',        // button labels (cta, counts, null)
                      language: 'en',       // which language to use (see LANGUAGES)
                      networks: [           // which networks to include (see SHARING NETWORKS)
                          'whatsapp',
                          'linkedin',
                          'messenger',
                          'facebook',
                          'twitter'
                      ],
                      padding: 12,          // padding within buttons (INTEGER)
                      radius: 4,            // the corner radius on each button (INTEGER)
                      show_total: true,
                      size: 40,             // the size of each button (INTEGER)
                      // OPTIONAL PARAMETERS
                  }}
                >
                  Share This
                </InlineShareButtons>
                Share This
                <img src="/shareIcon.svg" alt="Share This" style={{ paddingLeft: "4px"}} />
              </a>
            </div>
  
          </div>
          
        </div>
      </div>
    );
  }
  return null;
}

export default class Programs extends Component {
  render() {
    return(
      <div className="pageContainer">
        <Breadcrumbs slug={last_segment} />
        <InlineContent slug={last_segment}/>
        <RelatedPosts />
        {/* <GlobalFooter /> */}
        <NewFooter />
      </div>
    );
  }
}