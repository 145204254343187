import React, { Component } from 'react';
import AnnualReportResearchOverview from './annualReport2021Research-Overview';
import AnnualReportResearchHighlights from './annualReport2021Research-Highlights';
import AnnualReportResearchSharing from './annualReport2021Research-Sharing';
import AnnualReportLinks from '../annualReport2021_Components/10_Links/AnnualReport-Links';
import NewFooter from '../../../pages-other/NewFooter';
import './annualReport2021Research-Content.css';

export default class AnnualReportResearchContent extends Component {
  render() {
    return(
      <div id="annualReport2021research-content-container">
        <AnnualReportResearchOverview />
        <AnnualReportResearchHighlights />
        <AnnualReportResearchSharing />
        <AnnualReportLinks />
        <NewFooter />
      </div>
    );
  }
}