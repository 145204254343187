const TestimonialsData = [
    {
        testimonial: {
            quote: 'Students want to make informed financial decisions for their future. They want to know how to finance college, budget, buy a home, pay taxes, invest in the stock market and manage debt when they leave high school.',
            person: '—Lisa Castaneda from Filling Financial Education Gaps study'
        }
    },
    {
        testimonial: {
            quote: 'Kids benefit when parents introduce them to early math concepts through play and conversation, but families are more likely to incorporate math into their daily routines if it does not feel like a burden.',
            person: '—Dominic Gibson from the Making Math Fun for Early Learners blog'
        }
    }
]

export default TestimonialsData;
