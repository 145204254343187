import React, { useState } from 'react';
import './AreasOfResearch-InformalLearningAccordion.css';

function AreasOfResearchInformalLearningAccordion() {
    const [isActive, setIsActive] = useState(false);
    // const [hasMore, setHasMore] = useState(false);

    return (


            <div className="areasOfResearchAccordion-item">
                <div className="areasOfResearchAccordion-title" onClick={() => setIsActive(!isActive)}>
                    <div className="areasOfResearchAccordion-image">
                        <img src="https://f10-research.s3.us-west-2.amazonaws.com/Conceptual+Development+Lab/IA_Team_Page_Icons_2024.02.27_Informal_Learning.png" alt="youth waving with numbered play cubes"/>
                    </div>
                    <div className="areasOfResearchAccordion-text">
                        <h2 className="areasOfResearchAccordion-title-text">Informal Learning</h2>
                        {/* <h3>{research.subtitle}</h3> */}
                    </div>
                    <i className={ isActive ?  `fa-solid fa-angle-up`:`fa-solid fa-angle-down`}></i>                  
                </div>
                {isActive && <div className="areasOfResearchAccordion-content">
                    <div className="areasOfResearchAccordion-description">
                        <p>Learning extends beyond the classroom, starting at home with daily caregiver interactions that prepare children for formal instruction in school. These early experiences help youth navigate broader realms of informal learning, including online information and misinformation. This area of research will explore questions such as:</p>
                    </div>  
                    <div className="areasOfResearchAccordion-descriptionTwo">
                        {/* <h2>Participation In A Connected World</h2> */}
                        <div className="areasOfResearchAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How do youth learn from informal sources such as their caregivers, peers, and traditional/social media?</p>
                        </div>
                        <div className="areasOfResearchAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How do stories, play, and everyday conversations contribute to learning?</p>
                        </div>
                        <div className="areasOfResearchAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How does prior knowledge and intuition help youth navigate sources of information that vary in their reliability (and how can we better support them)?</p>
                        </div>
                    </div>
                    {/* <div className="areasOfResearchAccordion-descriptionThree">
                        <h2>Curriculum + Education</h2>
                        <div className="areasOfResearchAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>What are important considerations for school-based civic learning opportunities that support meaningful youth engagement online?</p>
                        </div>
                        <div className="areasOfResearchAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How should we approach curriculum focused on digital citizenship more effectively to support the skills youth need to thrive in today’s society?</p>
                        </div>
                        <div className="areasOfResearchAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>To what extent are young people’s perspectives included in the development & implementation of curriculum about being a digital citizen?</p>
                        </div>
                    </div> */}
                </div>}
            </div>

    );
}

export default AreasOfResearchInformalLearningAccordion;
