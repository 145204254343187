import React, { Component } from 'react';
import './october21-HighlightTwo.css';

export default class HighlightTwo21PageStory extends Component {
  render() {
    return(
      <div id="highlightTwo21Page-story-container">
        <div className="highlightTwo21Page-story-text-container">
            <h2 className="highlightTwo21Page-story-title">Understanding Social Media's Influence on Teen Well-Being</h2>
            <p className="highlightTwo21Page-story-content">This white paper highlights insights from a review of research focused on the link between social media use and well-being among young people, including findings from a range of disciplines.</p>
            <a href="https://www.foundry10.org/research/understanding-social-medias-influence-on-teen-well-being" target="_blank" rel="noopener noreferrer">Read Now</a>
        </div>
        <div className="highlightTwo21Page-story-img-container">
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/News+Page/Understanding+Social+Media's+Influence+on+Teen+Well-Being.jpg" alt="emojis drifting up from a phone screen"/>
        </div>
      </div>
    );
  }
}