import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import StemDbrLabHeader from './stemDbrLab-Header';
import StemDbrLabContent from './stemDbrLab-Content';
import './stemDbrLab.css';

export default class StemDbrLab extends Component {
  render() {
    return(
      <div id="stemDbrLab-container">
        <Helmet>
          <title>foundry10 STEM Design-Based Research Lab</title>
          <meta
            name="title"
            content="foundry10 STEM Design-Based Research Lab"
          />
          <meta
            name="description"
            content="Learn how foundry10 researchers are expanding understandings of how science, technology, engineering, and math activities for youth work in real-world settings."
          />
          <meta property="og:title" content="foundry10 STEM Design-Based Research Lab" />
          <meta
            property="og:description"
            content="Learn how foundry10 researchers are expanding understandings of how science, technology, engineering, and math activities for youth work in real-world settings."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/stem-design-based-research-lab" />
          <link rel="canonical" href="https://www.foundry10.org/stem-design-based-research-lab" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 STEM Design-Based Research Lab",
                "description": "Learn how foundry10 researchers are expanding understandings of how science, technology, engineering, and math activities for youth work in real-world settings.",
                "url": "https://www.foundry10.org/stem-design-based-research-lab"
              }
            `}
          </script>
        </Helmet>
        <StemDbrLabHeader />
        <StemDbrLabContent />
      </div>
    );
  }
}