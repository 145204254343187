import React, { Component } from 'react';
import Slider from "react-slick";
import './insight-Links.css';

export default class InsightLinks extends Component {
    render() {
        var settings = {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 0,
          responsive: [
            {
              breakpoint: 1650,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 1340,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 850,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                fade: true
              }
            }
          ]
        };

        return (
        <div className="insight-Links-content-container">
            <div className="insight-Links-content-header">
                <div className="insight-Links-content-title">
                    <h2>Further Reading</h2>
                </div>
                {/* <div className="insight-Links-content-subtitle-text">
                    <h3>Our latest research, programs, and philanthropic endeavors</h3>
                </div> */}
            </div>
            <div id="insight-LinksContentCarousel-container">
                <div className="insight-LinksContentCarousel-content-container">
                    <div className="insight-LinksContentCarousel-slider">
                    <Slider {...settings}>
                    {/* Begin Individual Card Content */}
                        {/* --- */}
                        <a href="https://hiprc.org/" target="_blank" rel="noopener noreferrer" className="insight-LinksContentCarousel-card-link">
                            <div className="insight-LinksContentCarousel-card">
                                <div className="insight-LinksContentCarousel-card-image">
                                <img src='https://f10-programs.s3.us-west-2.amazonaws.com/health-ed/Harborview+Injury+Prevention.jpg' width="100%" alt="Harborview Injury Prevention & Research Center University of Washington"/>
                                </div>
                                <div className="insight-LinksContentCarousel-card-content">   
                                    <div className="insight-LinksContentCarousel-card-content-container">
                                        <div className="insight-LinksContentCarousel-text">
                                            <h1>Harborview Injury Prevention and Research Center</h1>
                                        </div>
                                    </div>                 
                                </div>
                            </div>
                        </a>
                        {/* --- */}
                        <a href="https://hiprc.org/training/high-school/insight-hs/" target="_blank" rel="noopener noreferrer" className="insight-LinksContentCarousel-card-link">
                            <div className="insight-LinksContentCarousel-card">
                                <div className="insight-LinksContentCarousel-card-image">
                                    <img src='https://f10-programs.s3.us-west-2.amazonaws.com/health-ed/INSIGHT+snippet.png' width="100%" alt="our youth matters"/>
                                </div>
                                <div className="insight-LinksContentCarousel-card-content">   
                                    <div className="insight-LinksContentCarousel-card-content-container">
                                        <div className="insight-LinksContentCarousel-text">
                                            <h1>INSIGHT High School Program</h1>
                                        </div>
                                    </div>                 
                                </div>
                            </div>
                        </a>
                    {/* --- */}
                        <a href="https://hiprc.org/blog/2022-insight-high-school-recap/" target="_blank" rel="noopener noreferrer" className="insight-LinksContentCarousel-card-link">
                            <div className="insight-LinksContentCarousel-card">
                                <div className="insight-LinksContentCarousel-card-image">
                                    <img src='https://f10-programs.s3.us-west-2.amazonaws.com/health-ed/INSIGHT+snippet.png' width="100%" alt="our youth matters"/>
                                </div>
                                <div className="insight-LinksContentCarousel-card-content">   
                                    <div className="insight-LinksContentCarousel-card-content-container">
                                        <div className="insight-LinksContentCarousel-text">
                                            <h1>2022 INSIGHT High School Program Recap</h1>
                                        </div>
                                    </div>                 
                                </div>
                            </div>
                        </a>
                        {/* --- */}
                    </Slider>
                    </div>
                </div> 
            </div>
        </div>          
        );
      }
}