import React, { Component } from 'react';
import ShortRunWebComic from './shortRun-webcomic';
import ShortRunStudentCards from './shortRun-studentCards';
import ShortRunTeacherCards from './shortRun-teacherCards';
// import Gallery from './shortRun-gallery';
// import NavTest from './NavTest';
import NewFooter from '../../pages-other/NewFooter';
// import Footer from '../../pages-events/Footer';
import './shortRun-content.css';

export default class ShortRunContent extends Component {
  render() {
    return (
      <div id="shortRun-content-container">
        <ShortRunWebComic />
        <ShortRunStudentCards />
        <ShortRunTeacherCards />
        {/* <Gallery /> */}
        <NewFooter />
        {/* <NavTest /> */}
        {/* <Footer /> */}
      </div>    
    );
  }
}