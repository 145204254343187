import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import AnnualReportInterventionsHeader from './annualReport2021Interventions-Header';
import AnnualReportInterventionsContent from './annualReport2021Interventions-Content';
import './annualReport2021Interventions.css';

export default class AnnualReport2021Interventions extends Component {
  render() {
    return(
      <div id="annualReport2021Interventions-container">
        <Helmet>
          <title>foundry10 Annual Report 2021-2022: Interventions</title>
          <meta
            name="title"
            content="foundry10 Annual Report 2021-2022: Interventions"
          />
          <meta
            name="description"
            content="Explore the foundry10 Annual Report 2021 for insights into our Interventions work. Learn more about our efforts in research, programming, and philanthropy."
          />
          <meta property="og:title" content="foundry10 Annual Report 2021-2022: Interventions" />
          <meta
            property="og:description"
            content="Explore the foundry10 Annual Report 2021 for insights into our Interventions work. Learn more about our efforts in research, programming, and philanthropy."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/annual-report/2021/programs/interventions" />
          <link rel="canonical" href="https://www.foundry10.org/annual-report/2021/programs/interventions" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Annual Report 2021-2022: Interventions",
                "description": "Explore the foundry10 Annual Report 2021 for insights into our Interventions work. Learn more about our efforts in research, programming, and philanthropy.",
                "url": "https://www.foundry10.org/annual-report/2021/programs/interventions"
              }
            `}
          </script>
        </Helmet>
        <AnnualReportInterventionsHeader />
        <AnnualReportInterventionsContent />
      </div>
    );
  }
}