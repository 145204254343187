import React from "react";
import FeaturedStory from "../../components/blocks/FeaturedStory";
import LinkList from "../../components/blocks/LinkList";

const TopInlineContent = ({ entry }) => {
  return entry.contentOptions.map((block, index) => (
    <React.Fragment key={index}>
        {block.__typename === "contentOptions_featuredStory_BlockType" && (
        <FeaturedStory block={block} />
        )}
        {block.__typename === "contentOptions_linkList_BlockType" && (
        <LinkList block={block} />
        )}
    </React.Fragment>
  ));
};

const TopContentBlocks = ({ data }) => {
  return (
    <div className="contentBlocks-container">
      <TopInlineContent entry={data} />
    </div>
  );
};

export default TopContentBlocks;