import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import NewFooter from '../../../../pages-other/NewFooter';
import './People.css';

export default class GaryGreene extends Component {
  render() {
    return(
        <div id="marineCareers-main-container">
          <Helmet>
          <title>foundry10 Guide to Marine Science Careers: Ed Outreach</title>
          <meta
            name="title"
            content="foundry10 Guide to Marine Science Careers: Ed Outreach"
          />
          <meta
            name="description"
            content="Explore an exciting marine science career in education outreach. Dive into what life is like in this career with interviews and resource links."
          />
          <meta property="og:title" content="foundry10 Guide to Marine Science Careers: Ed Outreach" />
          <meta
            property="og:description"
            content="Explore an exciting marine science career in education outreach. Dive into what life is like in this career with interviews and resource links."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/marine-science-careers/education-outreach" />
          <link rel="canonical" href="https://www.foundry10.org/marine-science-careers/education-outreach" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Guide to Marine Science Careers: Ed Outreach",
                "description": "Explore an exciting marine science career in education outreach. Dive into what life is like in this career with interviews and resource links.",
                "url": "https://www.foundry10.org/marine-science-careers/education-outreach"
              }
            `}
          </script>
        </Helmet>
        {/* MAIN */}
        <div id="marineCareers-main-content-container">
          {/* HEADER */}
          <div id="marineCareers-header-container">
            <div class="marineCareers-header-title-container">
              <h3>Education Outreach</h3>
              <h2>Meet Joanne</h2>
              <div class="marineCareers-headerImage-container">
                <div class="marineCareers-headerImage-content-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/People/Joanne+Park.jpg" alt="joanne park"/>
                </div>
              </div>
              <p>Joanne Park joined the team at Marine Science and Technology (MaST) Center of Highline College in February 2019. Originally from Southern California, she graduated from the University of California Irvine with a B.S. in Public Health Science and a minor in Education. After her undergraduate studies, she worked for various organizations designing marine science curriculum, leading education programs, and assisting in marine mammal identification research. She has her M.S. in Informal Science Education from California State University Long Beach and conducted her master’s thesis at the Point Defiance Zoo and Aquarium. Joanne has a passion for inspiring ocean stewardship through engaging community experiences. She brings this passion and knowledge to further expand education and volunteer programming at the incredible hidden gem that is the MaST Center in Redondo Beach.</p>
            </div>
          </div>
          {/* BODY */}
          <div id="marineCareers-review-container">
            <div class="marineCareers-review-content-container">
              {/* INTERVIEW */}
              <div class="marineCareers-header-title-container">
                <h2>Interview with Joanne</h2>
              </div>
              <div class="marineCareers-interview">
                <h2>For your particular position are there degrees that are required, or preferred, when you're applying for jobs like this?</h2>
                <p>You know, every organization is a little different. I think ultimately they're going to want a lot of experience. So they want more years of experience because the more years of experience you have, the more that they can trust that you know what you're doing. For informal science education, there's really not a ton of programs out there because it's such a specific niche.The program that I did was in California and it was through Cal State Long Beach.I know OSU, they do something, I don't know if they have it anymore, they do like certificates, but they also do a master's program. I think they call it out of school learning or non-formal education, it's also called informal education. Actually, one of my former coworkers, she's doing a program there and it's a master's in museology, so that one's pretty cool. The more I hear about her program I'm like, "Oh man, I wish I did that one," but it's really cool because it kind of hits on all the different aspects of these museums and all these different things that you would need, not just the education, but it's just every aspect, like exhibit development and volunteering, and all that stuff. So I think that's all really, really helpful.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>Describe an average day in the life of an education and volunteer program coordinator. What do you look forward to most during your workday?</h2>
                <p>Typically, I worked a lot on long-term projects. So, one of the big things that I am tasked with working on is this artificial reef project that is kind of still in the process of being completely permitted and all that, but they are planning to build an artificial reef in Redondo Beach right next to our aquarium under the water. So essentially, I'd be coming up with education program curriculum for the public, for school groups that come and visit our center, coming up with interpretive panel materials that we can put up for people to read and educate themselves on, coming up with different activities that are engaging to the different audiences that come by our center. Sometimes I'd be working on immediate needs for volunteers, we have just over 100 volunteers. We've got a lot of different kinds of teams. We've got citizen science volunteer teams, we have an education volunteer opportunity, which is the most popular one where they would come in on Saturdays when we're normally open to the public.</p>
                <p>We always need a lot of volunteer support for those days because we probably get on average 300-400 visitors each week. They would be there to help interpret all the different artifacts we have, like the different marine mammal skeletons, or different reserve specimens, and of course we have a lot of different life in our aquarium. Our aquarium only has native Puget Sound species, so we have probably close to 300 species there, and for a pretty small aquarium that means there's still a lot to see. Which means we need a lot of volunteer support to help run those, to be able to just interpret and facilitate guests’ experience there.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What type of education, training, or experience really prepares you for this field?</h2>
                <p>The biggest experience is the hands on experience. To be honest, the first time that I ever taught, I think I stopped halfway through because I was like, "I ran out of stuff to talk about. I don't know what to talk about" because I was so nervous. However, the more I did it, the more I started developing my own system, methods, techniques, and routine, on how I teach. Then the more I started doing it with different audiences, I started realizing, "Oh, this age group reacts a little bit differently than this age group so I need to modify my teaching a little bit," so lots of experience is super, super valuable. When I'm writing these education programs, most of the time those programs are being sought out by teachers. So I want to make sure that those teachers have standards met.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>Why should youth want to pursue this marine science career and why is it important right now?</h2>
                <p>I think it's really important for youth to understand how the scientific process works. And then when they become a well-informed citizen as an adult and they're voting, they know what they're voting for. So those are things that I really try to instill in the students that I teach. Another thing is to make sure that you love it. I mean, I think we spend so much of our lives at work that you might as well enjoy what you're doing. There's no point in being miserable when you're at a job eight hours a day. So, get involved, go out and do beach cleanups. Or volunteer somewhere and share your knowledge with others. Everything is a great learning opportunity. I guess that's all I've got.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What advice would you give someone starting out on this career?</h2>
                <p>I mean, you won't really know anything until you actually are in it, and you're actually doing it, and you're getting your hands dirty and you're experiencing things that way. So that's the advice that I give to everyone because we have youth volunteers up in our center as well and that's the advice that I always give them is keep volunteering, keep coming back. Don't just volunteer at the MaST Center, volunteer everywhere, try out everything, because you're not going to find that as you get older, you have a lot less time to be able to take on opportunities like that because you have to start taking care of yourself, you have to start making money, things like that. So right now, when you're young and you don't have as many responsibilities, it's so important to just go out there and get involved.</p>
              </div>
              {/* LEARNING MORE */}
              <div id="marineCareersLearning-container">
                <div class="marineCareersLearning-content-container">
                  <h2>Interested in learning more about Marine Science Education and Outreach?</h2>
                  <ul class="marineCareersLearning-list">
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p>Do you want to read more about Joanne's responses? <a href="https://drive.google.com/file/d/1FrE-pHsybF_NLR8WDW7vpKTV0sCGeduY/view" target="_blank" rel="noopener noreferrer">Here's the link to the full interview</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://mast.highline.edu/" target="_blank" rel="noopener noreferrer">Check out the Highline College Marine Science & Technology Center Public Aquarium on Redondo Beach in Demoines, WA!</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.pdza.org/" target="_blank" rel="noopener noreferrer">Visit the Point Defiance Zoo and Aquarium in Tacoma, WA!</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://dornsife.usc.edu/uscseagrant/habwatch/" target="_blank" rel="noopener noreferrer">Learn more about Harmful Algal Bloom Watch (HABWatch) at the University of Southern California.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://nautiluslive.org/education" target="_blank" rel="noopener noreferrer">Explore resources for ocean educators.</a></p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <a class="marineCareers-backButton" href={'/marine-science-careers'}>
                    <p>Back to the careers!</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <NewFooter />
        </div>
      </div>
    );
  }
}