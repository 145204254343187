import React, { Component } from 'react';
import AreasOfResearchInformalLearningAccordion from './AreasOfResearch-InformalLearningAccordion';
import AreasOfResearchEarlyNumeracyAbstractConceptsAccordion from './AreasOfResearch-EarlyNumeracyAbstractConceptsAccordion';
import AreasOfResearchImprovingResearchBasedResourcesAccordion from './AreasOfResearch-ImprovingResearchBasedResourcesAccordion';
// import AreasOfResearchData from './AreasOfResearch-Data';
import './AreasOfResearch.css'

export default class AreasOfResearch extends Component {
  
  render() {

    return(
        <div className="areasOfResearchAccordion-section-container">
          <div className="areasOfResearchAccordion-content-container">

            <h2 className="areasOfResearchAccordion-titleContent-container"><span>Areas of Research</span></h2>
            {/* <h2><span>Areas of Research</span></h2> */}

            <div className="researchBenefits-accordion-container">
              <div className="researchBenefits-accordion-content">
                {/* {AreasOfResearchData.map(({ research }) => (
                  <AreasOfResearchAccordion research={research} />
                  ))} */}
                <AreasOfResearchInformalLearningAccordion />
                <AreasOfResearchEarlyNumeracyAbstractConceptsAccordion />
                <AreasOfResearchImprovingResearchBasedResourcesAccordion />
              </div>
          </div>

        </div>
    </div>
    );
  }
}