import React from 'react';
import './careerConnectedLearningLifeSkills-EducationProgramming.css'; // You can create this CSS file for styling

const EducationProgramming = () => {
  return (
    <div className="careerConnectedLearningLifeSkillsEducationProgramming-container">
      <div className="careerConnectedLearningLifeSkillsEducationProgramming-content-container">
        <h2 className="careerConnectedLearningLifeSkillsEducationProgramming-title">Education Programming</h2>
        {/* <h2 className="careerConnectedLearningLifeSkillsEducationProgramming-titleContent-container"><span>Education Programming</span></h2> */}
      {/* <ol className="image-list">
        {images.map((image, index) => (
          <li key={index} className="image-list-item">
            <div className="image-box">
              <img src={image.imageUrl} alt={image.title} />
              <div className="title-overlay">{image.title}</div>
            </div>
          </li>
        ))}
      </ol> */}


                <div className="careerConnectedLearningLifeSkillsEducationProgramming-highlights-grid-container">
                  <div className="careerConnectedLearningLifeSkillsEducationProgramming-highlights-image-container">
                    <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Career+Connected+Learning+and+Life+Skills/Automotive+Trips.jpg" alt="class of automotive students gathered around race car"/>
                  </div>
                  {/* <div className="careerConnectedLearningLifeSkillsEducationProgramming-highlights-info-container">  */}
                    {/* <div className="careerConnectedLearningLifeSkillsEducationProgramming-highlights-tags">
                      <div className="careerConnectedLearningLifeSkillsEducationProgramming-highlights-hash">
                        <div className="youthHash-teal">REPORT</div>
                        <div className="youthHash-hash">#Drama</div>
                      </div>
                    </div> */}
                    <div className="careerConnectedLearningLifeSkillsEducationProgramming-highlights-text">
                      <h2><a className="careerConnectedLearningLifeSkillsEducationProgramming-highlightsLink" href="https://www.foundry10.org/automotive-trips" target="_blank" rel="noopener noreferrer">Automotive Trips</a></h2>
                      <p>In order to expose students to experts in their fields of study, we partner with a local high school automotive program each year to take students and their teachers to a racing event where they will get up-close, personal interaction with professionals from the racing industry. Each trip includes special stops to other automotive-related places within the states we visit. Teachers are always included on the trips because they provide great opportunities for professional development, networking, and experiences they can bring back to the classroom to improve their practice.</p>
                    </div>
                    {/* <div className="careerConnectedLearningLifeSkillsEducationProgramming-highlights-link">
                      <p>Dec 7th, 2021</p>
                      <a href='https://drive.google.com/file/d/1k3kbUQE1OC-aZ663sFm5mXOvqrP211yL/view?usp=sharing' className="careerConnectedLearningLifeSkillsEducationProgramming-highlights-content-link" target="_blank" rel="noopener noreferrer"><i class="fa-solid fa-arrow-right"></i></a>
                    </div> */}
                  {/* </div> */}
                </div>
                <div className="careerConnectedLearningLifeSkillsEducationProgramming-highlights-grid-container">
                  <div className="careerConnectedLearningLifeSkillsEducationProgramming-highlights-image-container">
                    <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Career+Connected+Learning+and+Life+Skills/High+School+Internships.jpg" alt="two high school interns talking"/>
                  </div>
                  {/* <div className="careerConnectedLearningLifeSkillsEducationProgramming-highlights-info-container">  */}
                    {/* <div className="careerConnectedLearningLifeSkillsEducationProgramming-highlights-tags">
                      <div className="careerConnectedLearningLifeSkillsEducationProgramming-highlights-hash">
                        <div className="youthHash-teal">REPORT</div>
                        <div className="youthHash-hash">#Drama</div>
                      </div>
                    </div> */}
                    <div className="careerConnectedLearningLifeSkillsEducationProgramming-highlights-text">
                      <h2><a className="careerConnectedLearningLifeSkillsEducationProgramming-highlightsLink" href="https://www.foundry10.org/programs/internships/high-school-internships" target="_blank" rel="noopener noreferrer">High School Internships</a></h2>
                      <p>foundry10’s high school internship program provides students with the opportunity to pursue their passions in a project of their choosing. We match students with mentors that specialize in their area of interest for support, but otherwise give them complete freedom and with their project goals and time management. Students work either in teams or individually to create their project.</p>
                    </div>
                    {/* <div className="careerConnectedLearningLifeSkillsEducationProgramming-highlights-link">
                      <p>Dec 7th, 2021</p>
                      <a href='https://drive.google.com/file/d/1k3kbUQE1OC-aZ663sFm5mXOvqrP211yL/view?usp=sharing' className="careerConnectedLearningLifeSkillsEducationProgramming-highlights-content-link" target="_blank" rel="noopener noreferrer"><i class="fa-solid fa-arrow-right"></i></a>
                    </div> */}
                  {/* </div> */}
                </div>
                {/* <div class="careerConnectedLearningLifeSkillsEducationProgramming-paragraph">
                  <p>foundry10's Career-Connected Learning and Life Skills team is excited to support career exploration at the 43rd Washington Women in Trades Fair!</p>
                </div> */}
      </div>
    </div>
  );
};

export default EducationProgramming;
