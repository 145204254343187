import React, { Component } from 'react';
import './cteEd-Intro.css';

export default class CteEdIntro extends Component {
  render() {
    return(
      <div id="cteEd-intro-container">
        <div class="cteEd-intro-content-container">
          <div class="cteEd-intro-normal">
            <p>How can we support your students to learn at home? In response to COVID-19 regulations and remote learning, many career and technical education teachers have had to pivot from in-person, project-based learning to new and unfamiliar formats.</p>
          </div>
        </div>
      </div>
    );
  }
}