import React, { Component } from 'react';
import './annualReport2021STEM-Spotlights.css';

export default class AnnualReportSTEMSpotlights extends Component {
  render() {
    return(
      <div id="annualReport2021STEM-spotlights-container">
        <div className="annualReport2021STEM-spotlight-first-title-bar">
          <p>Spotlight: Presenting the EDP at SOWA’s Bridge Conference</p>
        </div>
        <div className="annualReport2021STEM-spotlights-content-container">
          <div className="annualReport2021STEM-spotlights-text-container">
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Subpages/engineer+desing+process.png" alt="the engineering design process - developing problem solving skills in a world full of challenges"/>
            <p>
            The School’s Out Washington Associations’ (SOWA) Bridge Conference was the perfect place for the team to give a presentation on The Engineering Design Process: Developing problem solving skills in a world full of challenges. The Bridge Conference provided a venue to talk with teachers and out-of-school instructors about the Engineering Design Process and have open discussions about the challenges of tackling problem solving with youth. During the presentation, educators had the opportunity to work in small groups to discuss student learning outcomes during each step of the EDP and work through various scenarios that students (and facilitators) face during engineering design challenge activities. We believe integrated, hands-on STEM activities, such as engineering design challenges, can spark curiosity for younger students. With the right tools and support, any educator can successfully guide students through problem solving!
            <br /> <br />
            <a href="https://drive.google.com/file/d/14pEMzfW0sSlfTDgHncln-qa8lkCGLz8T/view" target="_blank" rel="noopener noreferrer">The Engineering Design Process: Developing Problem Solving Skills in a World Full of Challenges</a>
            </p>
          </div>
        </div>
        <div className="annualReport2021STEM-spotlight-second-title-bar">
          <p>Spotlight: STEM/SEL Curriculum</p>
        </div>
        <div className="annualReport2021STEM-spotlights-content-container">
          <div className="annualReport2021STEM-spotlights-text-container">
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Subpages/STEM+CURRICULUM.jpg" alt="person working out equation on a whiteboard"/>
            <p>Our team is passionate about developing integrated, hands-on activities for youth that any teacher or instructor can facilitate, regardless of the teacher’s personal experience with STEM. Hands-on design challenges that utilize the Engineering Design Process are also a valuable opportunity for youth to learn and practice social-emotional learning (SEL) skills. SEL has been an important topic in schools, particularly during the pandemic, so finding creative ways to integrate SEL across subject areas is becoming more and more important. This year, our team set out to create a curriculum integrating STEM with SEL. By engaging youth in group design challenges, teachers can emphasize STEM concepts as well as important SEL skills in one integrated activity as students work to effectively, and collaboratively, problem solve together.
            <br /> <br />
            <a href="https://medium.com/the-foundry10-voice/stem-activities-are-ideal-for-social-emotional-learning-79b981946abf" target="_blank" rel="noopener noreferrer">STEM Activities are Ideal for Social Emotional Learning</a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}