import React from "react";
import ThemesSlideshow from "./researchRedesign-ThemesSlideshow";
import './researchRedesign-ThemesCards.css'

function ThemesCards() {


return (
<div className="themesCards-container">
    <div className="themesCards-content-container">
        <div className="themesCards-intro-container">
            <h2>Themes</h2>
            <p>foundry10’s themes are prominent topics and concepts that thread together projects and stories across our organization and our work with partners. Explore the slideshow below to learn more and to view research publications and resources related to specific themes.</p>
        </div>
        <div className="themesCards-slideshow-container">
            <ThemesSlideshow />
        </div>
    </div>
</div>
);

}

export default ThemesCards;
