import React from 'react';
import './careerConnectedLearningLifeSkills-Breadcrumb.css';

const CareerConnectedLearningLifeSkillsBreadcrumb = () => {

  const handleLinkClick = (url) => {
    // Perform a full page reload by setting window.location.href to the specified URL
    window.location.href = url;
  };

   return (
    <div class="careerConnectedLearningLifeSkills-breadcrumb-container">
      <div class="careerConnectedLearningLifeSkills-breadcrumb-content-container">
        <div class="careerConnectedLearningLifeSkills-breadcrumb-title">
          <div className="careerConnectedLearningLifeSkills-breadcrumb-titleWhite">
            <p><a href="/"><i class="fa-solid fa-house"></i></a></p>
            <p><i class="fa-solid fa-angle-right"></i></p>
            <p><a href="/interest-areas" onClick={() => handleLinkClick('/interest-areas')}>Interest Areas</a></p>
            <p><i class="fa-solid fa-angle-right"></i></p>
          </div>
          <div className="careerConnectedLearningLifeSkills-breadcrumb-titleOrange">
            <p>Career-Connected Learning and Life Skills</p>
          </div>
        </div>
      </div>
    </div>
   )
}
export default CareerConnectedLearningLifeSkillsBreadcrumb;