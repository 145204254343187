import React, { Component } from 'react';
import ResearchTopicsOne from './ResearchTopics-One';
import ResearchTopicsTwo from './ResearchTopics-Two';
// import ResearchTopicsData from './ResearchTopics-Data';
import './ResearchTopics.css'

export default class ResearchTopics extends Component {
  
  render() {

    return(
        <div className="researchTopics-section-container">
          <div className="researchTopics-content-container">
          <h2 className="researchTopics-title">Research Topics</h2>
            {/* <h2 className="researchTopics-titleContent-container"><span>Research Topics</span></h2> */}
            {/* <h2><span>Areas of Research</span></h2> */}

            <div className="researchBenefits-accordion-container">
              <div className="researchBenefits-accordion-content">
                {/* {AreasOfResearchData.map(({ research }) => (
                  <researchTopics research={research} />
                  ))} */}
                <ResearchTopicsOne />
                <ResearchTopicsTwo />
              </div>
          </div>

        </div>
    </div>
    );
  }
}