// NEW RESEARCH NEWS CARDS PULLING FROM CRAFT (AUTOUPDATING)
import React, { Component } from 'react';
import {
  useQuery,
  gql
} from "@apollo/client";
import Grid from '@material-ui/core/Grid';
import StoryCard from '../../../craft-graphql/components/storyCard';
import './researchRedesign-CraftHighlightCards.css';


const researchQuery = gql`
    query researchQuery {
      entries(section: "stories", limit: 3, relatedToCategories: [{ id: 689 }]) {
        title
        slug
        url
        author {
          fullName
        }
        postDate @formatDateTime (format: "M j, Y")
        ... on stories_default_Entry {
          researchExternalAuthors {
            ... on researchExternalAuthors_BlockType {
              researchExternalAuthorsAuthor
            }
          }
          interestAreaTeam {
            ... on interestAreaTeam_BlockType {
              interestAreaTeamMember {
                title
              }
            }
          }
          blurb
          featuredImage {
            url
          }  
          categories {
            title
          }
        }
      }
    }
`

const ResearchHighlights = () => {
  const { data, loading, error } = useQuery(researchQuery);
  if (loading) return null;
  if (error) return `Error! ${error}`;

  return (
    data.entries.map(
      (block, index) => {
       
        //console.log(block.slug);
        //console.log(block.categoryId);
        let props = {
          block: {block}
        }
        return (
          <Grid item xs={12} sm={4}>
            <StoryCard {...props} />
          </Grid>
        )
      }
    )
  )
}


const ResearchBlock = (teamData) => {

  return (
    <section className='featuredResearch'>
      <div className="container">
        {/* <h2 className="text-xl_bold">Latest Research News</h2> */}
        <Grid container spacing={4}>
          <ResearchHighlights />
        </Grid>
        {/* <a className="button orange" href="/stories?cat=689">View All  <i class="fas fa-arrow-right"></i></a> */}
      </div>

    </section>
  )
}


export default class CraftHighlightCards extends Component {
  render() {
    return(
        <section className='craftHighlightCards'>
            <ResearchBlock />
        </section>   
    );
  }
}