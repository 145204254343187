import React, { Component } from 'react';
import './stemGoBabyGo-Quote.css';

export default class StemGoBabyGoQuote extends Component {
  render() {
    return(
      <div id="stemGoBabyGo-quote-container">
        <div class="stemGoBabyGo-quote-content-container">
          <div class="stemGoBabyGo-quoteParagraph-content-container">
            <p>"I'm always looking for opportunities to help people. Go Baby Go not only did that but also presented itself as a way I could improve my engineering abilities and work in a group towards a real goal (not a fake scenario in an assigned school project)."</p>
            <h2>&#8212;Tesla STEM High School Student</h2>
          </div>
        </div>
      </div>
    );
  }
}