import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import './AnnualReport-Programs.css';

export default class AnnualReportPrograms extends Component {
  render() {
    return(
      <div id="annualReportPrograms-container">
        <div class="annualReportPrograms-content-container">
          <div class="annualReportPrograms-imageDescription-container">
            <div class="annualReportPrograms-description-container">
              <h1>PROGRAMS</h1>
              <div class="annualReportPrograms-titleLine"></div>
              <p>Programs are how we take ideas for youth experiences and bring them into reality.  We develop programs across a wide array of interest areas, in collaboration with partners and in-house, and in settings ranging from schools to our own office space.  Programs create unique and engaging opportunities for youth to explore their passions and interests. Additionally, they allow us to see how ideas, techniques, and curricula work in real-life.  Though it has been challenging to run in-person programming in the past 18 months, we were still able to offer many opportunities for youth and are excited to share what we learned from pivoting our work.</p>
            </div>
            <div class="annualReportPrograms-image-container">
              <img src='https://f10-image.s3.us-west-2.amazonaws.com/Annual+Report+2021/Landing+Page/FINAL/Programs_Landingpage-8+final.png' alt='programs illustration'/>
            </div>
          </div>
          <div class="annualReportPrograms-subpages-container">
            <ul id="annualReportPrograms-cardsList2" class="annualReportPrograms-events-lists2">
                <li>
                  <div class="annualReportPrograms-cards-container">
                    <h3>OUR PROGRAMS</h3>
                    <p>Our approach to programs combines an expansive view of the possibilities in learning with a drive to put those ideas into action in applied settings.  We seek out exciting and innovative ideas, then put them to the test with real students, teachers, and communities.  foundry10 has a wide variety of program interest areas that work both independently of, and collaboratively with, each other. We all benefit from the cross-pollination of ideas and techniques that comes from applying ourselves to new subjects.</p>
                  </div>
                </li>
            </ul>
            <Button style={{ fontFamily: 'Helvetica Neue Light', fontWeight: 'bold', color: '#ffffff', textTransform: 'capitalize', boxShadow: '1px 1px #b1b1b1', width: '150px', borderRadius: '12px', background: 'radial-gradient(#cc886f, #d14e1d)' }} href="/annual-report/2021/programs">Learn more</Button>
          </div>
        </div>
      </div>
    );
  }
}