import React, { Component } from 'react';
import './afterschoolLandingPage-Description.css';

export default class AfterschoolLandingPageDescription extends Component {
  render() {
    return(
      <div id="afterschoolLandingPage-description-container">
        <div class="afterschoolLandingPage-description-content-container">
        
          <h3>
          Why Afterschool Engineering Clubs?
          </h3>

          <p>We believe all students should have the opportunity to explore the world around them through STEM. Afterschool programs are an ideal place to lead students in open-ended STEM activities because they are not limited by the same constraints placed upon school-day teachers. For example, out-of-school time educators have more flexibility over content, timing, and materials and do not have the pressure of formal assessment and testing. This freedom allows educators and students to focus more on the process of solving problems and less on the products that are created. By engaging students in engineering challenges and scientific inquiry in the afterschool space, we can strengthen youths' critical thinking, creativity, curiosity, and collaboration skills so they are prepared to be the problem solvers of the future.</p>

          <h3>
          What are we currently doing?
          </h3>

          <p>foundry10 partners with local elementary schools to offer afterschool engineering clubs that are designed to engage students in the engineering design process and maker challenges. For example, one week students might be structural engineers, building free-standing towers out of index cards, and the next week they may take on the role of chemical engineers, designing formulas to create long-lasting bubbles.  Typical engineering clubs meet one hour afterschool every week for about 9-11 weeks, but club length and duration can be tailored to meet the needs of the schools/organizations and their students.</p>

          <p>Interested in learning more? Contact us at <a href="mailto:STEM@foundry10.org" target="_blank" rel="noopener noreferrer">STEM@foundry10.org</a></p>

          <h3>
          What have we learned so far?
          </h3>

          <p>
          While designing and facilitating youth afterschool programs we have learned a few things along the way:
            <ol>
              <li>
              <em>Choice: </em>With all of our  STEM programming, we have seen how choice can increase engagement for youth participants. Incorporating youth choice into an activity can be as simple as having youth select the color of LED light, or as complex as setting out various materials and letting students pick and choose from the wide selection to build their creation. Giving youth the freedom to make their own choices helps increase student buy-in, allows youth to express their creativity, and shows kids that there is always more than one way to solve a problem.
              </li>
              <li>
              <em>Take-home Activities: </em>Youth often want to share  the fun and exciting activities they do in afterschool with parents and family members. During our programs, we have seen how designing  an activity that allows the students to take home their designs can extend learning beyond the afterschool setting. When kids share their work at home it encourages families to continue the STEM exploration and allows students to reflect on what they have learned by recreating the experiment or demonstrating the design challenge. 
              </li>
              <li>
              <em>Get Hands-on Early: </em>In the afterschool space, we have to constantly remind ourselves that these students have already spent an entire day sitting in class. We want the afterschool space to be full of excitement and exploration. Often that means that even a 5 or 10 minute introduction to an activity can be a struggle for students who are eager to start exploring. Think about ways to get materials into students’ hands quickly, so they can manipulate the materials, generate ideas, and start building. This also supports students who need to physically interact with the materials or equipment to understand how to complete the design challenge.
              </li>
            </ol>
          </p>
          

        </div>
      </div>
    );
  }
}