import React, { Component } from 'react';
import './october21-HighlightOne.css';

export default class HighlightOne21PageStory extends Component {
  render() {
    return(
      <div id="highlightOne21Page-story-container">
        <div className="highlightOne21Page-story-text-container">
            <h2 className="highlightOne21Page-story-title">foundry10 Research Featured on Good Day Seattle</h2>
            <p className="highlightOne21Page-story-content">Senior Researcher Dominic Gibson was interviewed on FOX13 about how young people are turning to TikTok for financial advice.</p>
            <a href="https://medium.com/the-foundry10-voice/dominic-gibson-discusses-fintok-research-study-on-good-day-seattle-a5cebf17e831" target="_blank" rel="noopener noreferrer">Watch Now</a>
        </div>
        <div className="highlightOne21Page-story-img-container">
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Hub/Oct+2021/FinTok_Fox_Interview_Thumbail_480x480.jpg" alt="financial advice on tiktok"/>
        </div>
      </div>
    );
  }
}