import React, { Component } from 'react';

const FullWidthImage = ({ block }) => {
    const heading = block.fwiTitle;
    const image = block.fwiImage;
    const imageCaption = block.fwiCaption;
    const imageSize = block.fwiSize;

    const classes = `contentBlock fwi ${imageSize}`;

  return (
    <section className={classes}>
      {heading && (
        <div className="container no-pad">
          <h2 className="text-jumbo_bold">
            {heading}
          </h2>
        </div>
      )}
      
      {image[0] && (
        <div className="imageContainer">
          <img src={image[0].url} alt={imageCaption} />
        </div>
      )}
     {imageCaption && (
        <div className="container no-pad">
          <p className="imageCaption text-medium">
            <span>{imageCaption}</span>
          </p>
        </div>
      )}
    </section>
  )
}

export default FullWidthImage