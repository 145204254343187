import React, { Component } from 'react';
import './annualReport2021Trades-Spotlights.css';

export default class AnnualReportTradesSpotlights extends Component {
  render() {
    return(
      <div id="annualReport2021Trades-spotlights-container">
        <div className="annualReport2021Trades-spotlight-first-title-bar">
          <p>Spotlight: Automotive Technology Kits</p>
        </div>
        <div className="annualReport2021Trades-spotlights-content-container">
          <div className="annualReport2021Trades-spotlights-text-container">
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Subpages/trades-auto.jpeg" alt="automotive technology kits"/>
            <p>
            Without access to a classroom, remote learning was a challenge for many academic disciplines. Those challenges were amplified for Automotive Technology classes, where the curriculum revolves around cars and specialty tools that easily fill up an entire garage. Bryan Robbins at Meadowdale High School helped his students transition to a remote learning environment by creating instructional videos on YouTube, providing personal toolkits for students, and providing car parts for them to work on small modules. foundry10 was excited to partner with Bryan, as well as several other automotive educators in our local area, to provide a variety of remote kits, engines, RC Cars and tools to ensure that students could get hands-on experience, even outside the shop.
            <br /> <br />
            <a href="https://myedmondsnews.com/2021/03/edmonds-school-district-automotive-technology-students-continue-their-winning-ways/" target="_blank" rel="noopener noreferrer">Edmonds School District Automotive Technology Students Continue Their Winning Ways</a>
            </p>
          </div>
        </div>
        <div className="annualReport2021Trades-spotlight-second-title-bar">
          <p>Spotlight: Culinary Gift Cards</p>
        </div>
        <div className="annualReport2021Trades-spotlights-content-container">
          <div className="annualReport2021Trades-spotlights-text-container">
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Subpages/trades-culinary.png" alt="cinnamon rolls"/>
            <p>Culinary Arts programs were another area of trades education that suffered major setbacks during the pandemic. Many students have limited resources to purchase ingredients and may not have kitchen equipment like pans, knives, and measuring cups at home. Culinary Arts teachers at Seattle Public Schools collaborated to develop creative remote lessons to help students grow their culinary skills at home. To ensure that students had everything they needed for the class, foundry10 partnered with culinary teachers across Seattle Public Schools to send home grocery gift cards and kitchen equipment to students who could use extra support in purchasing ingredients and supplies. We were also able to support virtual culinary internships in the Seattle area for high school students who needed additional supplies and materials in order to complete their training. These programs served the dual benefit of supporting an educational experience as well as an opportunity to bring additional food home to families during the pandemic.</p>
          </div>
        </div>
      </div>
    );
  }
}