import React, { useState } from 'react';
import './ResearchTopics-One.css';

function ResearchTopicsOne() {
    const [isActive, setIsActive] = useState(false);
    // const [hasMore, setHasMore] = useState(false);

    return (


            <div className="researchTopicsOneAccordion-item">
                <div className="researchTopicsOneAccordion-title" onClick={() => setIsActive(!isActive)}>
                    <div className="researchTopicsOneAccordion-image">
                        <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Creativity%2C+Design%2C+and+Play/Research+Topics/IA+Team+Page+Icons_Creativity+and+Play+in+the+Classroom+1.png" alt="young children playing in the classroom"/>
                    </div>
                    <div className="researchTopicsOneAccordion-text">
                        <h2 className="researchTopicsOneAccordion-title-text">Creativity and Play in the Classroom</h2>
                        {/* <h3>{research.subtitle}</h3> */}
                    </div>
                    <i className={ isActive ?  `fa-solid fa-angle-up`:`fa-solid fa-angle-down`}></i>                  
                </div>
                {isActive && <div className="researchTopicsOneAccordion-content">
                    <div className="researchTopicsOneAccordion-description">
                        <p>From drama interventions to youth art workshops, creative and playful learning opportunities have the power to expand connections in the classroom and beyond. Our team is interested in how experiences rooted in curiosity, play, and self-expression can shape identity, build creative confidence, and empower youth to think critically about the world around them.</p>
                    </div>  
                    <div className="researchTopicsOneAccordion-descriptionTwo">
                        <h2>This area of research will explore questions such as:</h2>
                        <div className="researchTopicsOneAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>What are the access gaps for youth in arts and play-based learning?</p>
                        </div>
                        <div className="researchTopicsOneAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How do creative learning experiences prepare youth to be critical thinkers and innovators?</p>
                        </div>
                        <div className="researchTopicsOneAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How do curious and joyful learning experiences foster personal growth and academic success?</p>
                        </div>
                    </div>
                    {/* <div className="researchTopicsOneAccordion-descriptionThree">
                        <h2>VR/AR</h2>
                        <div className="researchTopicsOneAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How are young people using AR/VR as a tool to participate, create, and innovate?</p>
                        </div>
                        <div className="researchTopicsOneAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How can VR be used as a training tool for soft skills (e.g., creative thinking skills, decision-making ability, interpersonal communication skills)? How do young people implement soft skills experienced through VR in non-virtual settings?</p>
                        </div>
                        <div className="researchTopicsOneAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>We plan to use a variety of approaches to answer these questions such as: usability studies (measuring if the emerging technology is easy to use), feasibility studies (measuring if the emerging technology is feasible to use as intended within the classroom), and correlational studies (what relationship might exist between VR and soft skills attainment).</p>
                        </div>
                    </div> */}
                </div>}
            </div>

    );
}

export default ResearchTopicsOne;
