import React, { Component } from 'react';
import './fiveC.css';

export default class FIVEC extends Component {
  render() {
    return (
      <div id="fiveC-container">
      <div class="everyday-section-title">
          <h4>FIVE (THE END)</h4>
        </div>
        <div class="fiveC-content-container">
          <h2>Ultimately, we achieved what we set out to do.</h2>
          <p>We carved out space and time to celebrate youth art. Perhaps more importantly, we helped develop a new community and network of artists.</p>
          <h4>And we realized that there was still room to do so much more.</h4>
        </div>
      </div>
    );
  }
}