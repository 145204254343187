import React, { Component } from 'react';
import './homeRedesign2023-Partnership.css';

export default class HomePartnershipBlend extends Component {
  render() {
    return(
      <div id="homeRedesign2023-Partnership-container">
        <div className="homeRedesign2023-Partnership-content-container">
          <div className="homeRedesign2023-Partnership-top">
            <p>A UNIQUE, CROSS-DISCIPLINARY APPROACH</p>
          </div>
          <div className="homeRedesign2023-Partnership-bottom">
            <p>Our Projects and Partnerships Blend:</p>
            <div className="homeRedesign2023-Partnership-images">
              <a href="/research" className="homeRedesign2023-Partnership-images-card">
                <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Home+Page/2023+Redesign/Homepage_Research-Thumbnail.jpg" alt="" />
                <div className="homeRedesign2023-Partnership-images-text">Research</div>
              </a>
              <a href="/programs" className="homeRedesign2023-Partnership-images-card">
                <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Home+Page/2023+Redesign/Homepage_Programs_Thumbnail.jpg" alt="" />
                <div className="homeRedesign2023-Partnership-images-text">Education Programs</div>
              </a>
              <a href="/philanthropy" className="homeRedesign2023-Partnership-images-card">
                <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Home+Page/2023+Redesign/Homepage_Philanthropy_Thumbnail.jpg" alt="" />
                <div className="homeRedesign2023-Partnership-images-text">Philanthropy</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}