import React from 'react';

const HERO_IMAGE_URL = 'https://d1dyzrv677gyi1.cloudfront.net/LCP_test_hero_2.webp'; // Replace with your actual hero image URL

const LcpTestHeader = () => {
  const containerStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    backgroundBlendMode: 'multiply',
    display: 'flex',
    position: 'absolute',
    height: '70%',
    width: '100%',
    left: 0,
    top: '10%',
    backgroundImage: `url(${HERO_IMAGE_URL})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
  };

  const contentContainerStyle = {
    transform: 'translate(-50%, -50%)',
    color: 'white',
    padding: '30px',
    textAlign: 'center',
    position: 'absolute',
    left: '50%',
    top: '45%',
    width: '100%',
    maxWidth: '900px',
  };

  const headingStyle = {
    fontSize: '65px',
    fontFamily: 'Helvetica Neue Light',
  };

  // Media query styles as inline styles can be tricky, but we can adjust styles based on window size in a useEffect
  const updateStylesForResponsive = () => {
    const width = window.innerWidth;
    if (width <= 1000) {
      contentContainerStyle.top = '50%';
      contentContainerStyle.maxWidth = '600px';
      headingStyle.fontSize = '55px';
    }
    if (width <= 700) {
      containerStyle.top = '0';
      contentContainerStyle.maxWidth = '450px';
      headingStyle.fontSize = '45px';
    }
    if (width <= 500) {
      containerStyle.height = '60%';
      contentContainerStyle.maxWidth = '300px';
      headingStyle.fontSize = '35px';
    }
    if (width <= 376) {
      contentContainerStyle.maxWidth = '280px';
      headingStyle.fontSize = '30px';
    }
    if (width <= 350) {
      contentContainerStyle.maxWidth = '260px';
      headingStyle.fontSize = '25px';
    }
  };

  React.useEffect(() => {
    window.addEventListener('resize', updateStylesForResponsive);
    updateStylesForResponsive(); // Call it once to set initial styles

    return () => window.removeEventListener('resize', updateStylesForResponsive);
  }, []);

  return (
    <div style={containerStyle}>
      <div style={contentContainerStyle}>
        <h1 style={headingStyle}>Automotive Research</h1>
        {/* Additional header content can go here */}
      </div>
    </div>
  );
};

export default LcpTestHeader;
