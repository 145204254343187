import React, { Component } from 'react';
import './tripsLandingPage-Links.css';

export default class TripsLandingPageLinks extends Component {
  render() {
    return(
      <div id="tripsLandingPage-links-container">
        <div class="tripsLandingPage-links-content-container">
          <div class="interestArea-cardsList2-container">
            <div class="interestArea-domain-title">
              <h2>Trips</h2>
            </div>
            <ul id="interestArea-cardsList2" class="interestArea-events-list2">
              <a href={'/automotive-trips/kansas'}>
               <li>
                <div class="tripsLandingPage-cards-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/automotive-tech/auto+page+pictures/Kansas+Small.jpg" alt="kansas trip" />
                  <p class="tripsLandingPage-cards-title">Kansas</p>
                  <div class="tripsLandingPage-cards-overlay"></div>
                </div>
                </li>
              </a>
              <a href={'/automotive-trips/laguna-seca'}>
               <li>
                <div class="tripsLandingPage-cards-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/automotive-tech/auto+page+pictures/Laguna+Seca+Small.jpg" alt="laguna seca trip" />
                  <p class="tripsLandingPage-cards-title">Laguna Seca</p>
                  <div class="tripsLandingPage-cards-overlay"></div>
                </div>
                </li>
              </a>
              <a href={'/automotive-trips/long-beach'}>
               <li>
                <div class="tripsLandingPage-cards-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/automotive-tech/auto+page+pictures/Long+Beach+Small.jpg" alt="long beach trip" />
                  <p class="tripsLandingPage-cards-title">Long Beach</p>
                  <div class="tripsLandingPage-cards-overlay"></div>
                </div>
                </li>
              </a>
              <a href={'/automotive-trips/detroit'}>
               <li>
                <div class="tripsLandingPage-cards-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/automotive-tech/auto+page+pictures/Detroit+Small.jpg" alt="detroit trip" />
                  <p class="tripsLandingPage-cards-title">Detroit</p>
                  <div class="tripsLandingPage-cards-overlay"></div>
                </div>
                </li>
              </a>
              {/* <li>
                <div class="tripsLandingPage-cards-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/virtual-reality/VR+Blog+Photos/Fun+Small.jpg" alt="fun" />
                  <p class="tripsLandingPage-cards-title">Being Planned Right Now!</p>
                  <div class="tripsLandingPage-cards-overlay"></div>
                </div>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}