import React, { Component } from 'react';
import './threeCD.css';
import lottie from 'lottie-web';

class THREECD extends Component {
  componentDidMount() {
    lottie.loadAnimation({
      container: document.getElementById("threeCD"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "/svg/3cd.json"
    });
  }
  render() {
    return (
      <div id="threeCD-container">
        <div class="everyday-section-title">
          <h4>THREE (THE SPACE)</h4>
        </div>
        <div class="threeCD-content-container">
          <div class="threeCD-text-container">
            <h2>Finally, we got into the space!</h2>
            {/* <p>we were able to get into the former auto shop and check out the space. It was great! But it didn't have enough...walls??? We had a boatload of awesome art and not enough room to hang it.</p>
            <p>So...we built two more walls! Well, first we learned how to build an interior wall. THEN we built two more walls and covered them in art!</p>
            <p>With everything in place it was time to open...</p> */}
            <p>With the event approaching, we wanted to somehow fit as many artists as we could with the overwhemling amount of amazing applicants. We figured a way to build additional walls for more art!</p>
            <p>With everything in place, it was time to open!</p>
          </div>
          <div class="threeCD-svg-container">
            <div id="threeCD" class="threeCD-svg-content"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default THREECD;
