import React, { Component } from 'react';
import WorkCulture from './Work-Culture';
// import CareersImage from './Careers-Image';
import CareersAttributes from './Careers-Attributes';
import Benefits from './Benefits/Benefits';
import CurrentOpportunities from './Current-Opportunities';
import NewFooter from '../../../pages-other/NewFooter';
import './Careers-Content.css';

export default class CareersContent extends Component {
  render() {
    return(
      <div id="newCareers-content-container">
        <WorkCulture />
        {/* <CareersImage /> */}
        <CareersAttributes />
        <Benefits />
        <CurrentOpportunities />
        <NewFooter />
      </div>
    );
  }
}