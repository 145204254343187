import React, { Component } from 'react';
import {
  useQuery,
  gql
} from "@apollo/client";
import ThemeHeader from '../components/theme-header.js';
import Grid from '@material-ui/core/Grid';
import ContentBlocks from '../components/ContentBlocks';
import StoryCard from '../components/storyCard';
// import GlobalFooter from '../components/base/GlobalFooter';
import NewFooter from '../../pages-other/NewFooter.js';

// Get Last Segment
const segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
const last_segment = segment_str.split("/").pop();

const QUERY = gql`
    query NewQuery($slug: [String]) {
    entry(slug: $slug) {
      title
      postDate @formatDateTime(format: "d M Y")
      postDateAlt: postDate @formatDateTime(format: "Y-m-d")
      url
      author {
        fullName
      }
      ... on newsArchive_newsArchive_Entry {
        customTitle
        blurb
        featuredImage {
          title
          url
        }
        featuredImageCaption
        contentOptions {
          ... on contentOptions_standardContent_BlockType {
            contentTitle
            contentContent
            linkUrl {
              url
              customText
              target
            }
            contentAlignment
          }
          ... on contentOptions_icp_BlockType {
            icpTitle
            icpContent
              icpImage {
                  url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
          }
          ... on contentOptions_fwi_BlockType {
            fwiTitle
            fwiImage {
                url
            }
            fwiCaption
            fwiSize
          }
          ... on contentOptions_emphasized_BlockType {
            emphasizedTitle
            emphasizedSubheadline
            emphasizedOverline
            emphasizedImage {
              url
            }
            emphasizedImageCaption
            emphasizedContent
            emphasizedLink
            emphasizedBackgroundColor
          }
          ... on contentOptions_quote_BlockType {
                pullQuote
                quoteSocial
                quoteImage {
                  url
                }
                quoteAttribution
          }
          ... on contentOptions_quoteSlideshow_BlockType {
            quoteSliderTitle
            quotes {
              __typename
            }
          }
          ... on contentOptions_video_BlockType {
            videoTitle
            videoLink
            videoCaption
            videoImage {
              url
            }
          }
        }
      }
    }
  }
`

const opportunityQuery = gql`
    query opportunityQuery {
      entries(section: "opportunities", limit: 1) {
        title
        slug
        url
        dateCreated @formatDateTime (format: "M j, Y")
        postDate @formatDateTime(format: "d M Y")
        author {
            fullName
        } 
        ... on opportunities_default_Entry {
          blurb
          featuredImage {
            url
          }  
        }
      }
    }
`

// The categories aren't defined, so this is in preparation for this
// const opportunityQuery = gql`
//     query opportunityQuery($cat: [String]) {
//       entries(section: "opportunities", limit: 1, relatedToCategories: [{slug: $cat}]) {
//         title
//         slug
//         url
//         dateCreated @formatDateTime (format: "M j, Y")
//         author {
//             fullName
//         } 
//         ... on opportunities_default_Entry {
//           blurb
//           featuredImage {
//             url
//           }  
//         }
//       }
//     }
// `
const blogQuery = gql`
  query blogQuery {
    entries(section: "stories", limit: 3) {
      title
      slug
      url
      postDate @formatDateTime(format: "d M Y")
      author {
        fullName
      }
      dateCreated @formatDateTime (format: "M j, Y")
      ... on stories_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        researchExternalAuthors {
            ... on researchExternalAuthors_BlockType {
              researchExternalAuthorsAuthor
            }
          }
          interestAreaTeam {
            ... on interestAreaTeam_BlockType {
              interestAreaTeamMember {
                title
              }
            }
          }
        categories {
          title
        }
      }
    }
  }
`
// The categories aren't defined, so this is in preparation for this
// const blogQuery = gql`
//   query blogQuery($cat: [String]) {
//     entries(section: "stories", limit: 3, relatedToCategories: [{ slug: $cat }]) {
//       title
//       slug
//       url
//       author {
//         fullName
//       }
//       dateCreated @formatDateTime (format: "M j, Y")
//       ... on stories_default_Entry {
//         blurb
//         featuredImage {
//           title
//           url
//         }
//         researchExternalAuthors {
//             ... on researchExternalAuthors_BlockType {
//               researchExternalAuthorsAuthor
//             }
//           }
//           interestAreaTeam {
//             ... on interestAreaTeam_BlockType {
//               interestAreaTeamMember {
//                 title
//               }
//             }
//           }
//         categories {
//           title
//         }
//       }
//     }
//   }
// `

const FeaturedOpportunity = (cat) => {
  const { data, loading, error } = useQuery(opportunityQuery, {
    variables: {
      cat: last_segment
    }
  });
  if (loading) return null;
  if (error) return `Error! ${error}`;

  return (
    data.entries.map(
      (block, index) => {
       
        //console.log(block.slug);
        //console.log(block.categoryId);
        let props = {
          block: {block},
          orientation: 'wide'
        }
        return (
          <Grid item xs={12}>
            <StoryCard {...props} />
          </Grid>
        )
      }
    )
  )
}

const Blog = () => {
  const { data, loading, error } = useQuery(blogQuery, {
    variables: {
      cat: last_segment
    }
  });
  if (loading) return null;
  if (error) return `Error! ${error}`;

  return (
    data.entries.map(
      (block, index) => {
      
        let props = {
          block: {block},
          orientation: 'wide'
        }
        return (
          <Grid item sm={12}>
            <StoryCard {...props} />
          </Grid>
        )
      }
    )
  )
}

const OpportunitiesBlock = (cat) => {

  return (
    <section className='featuredOpportunity'>
      <div className="container">
        <h2 className="text-xl_bold">Featured Opportunity</h2>
        <Grid container spacing={24}>
          <FeaturedOpportunity cat={cat}/>
        </Grid>
      </div>

    </section>
  )
}

const BlogBlock = (teamData) => {

  return (
    <section className='featuredBlog'>
      <div className="container">
        <h2 className="text-xl_bold">From the Blog</h2>
        <Blog />
        <a className="button" href="/stories">View All  <i className="fas fa-arrow-right"></i></a>
      </div>

    </section>
  )
}

const InlineContent = (slug) => {
  const { data, loading, error } = useQuery(QUERY, {
    variables: {slug}
  });
  if (loading) { return <div>loading</div> }
  if (error) { return <div>{error}</div> }

  return (
    <ContentBlocks data={data.entry} />
  );
}


export default class AudienceArchive extends Component {
  render() {
    return(
        <section className='audienceArchive'>
            <ThemeHeader slug={this.props.slug} />
            <OpportunitiesBlock cat={last_segment} />
            <BlogBlock />
            {/* <PressReleaseBlock />
            <InlineContent slug='news-media-archive'/>
            <NewsletterNewsSplit /> */}
            {/* <GlobalFooter /> */}
            <NewFooter />
        </section>
      
    );
  }
}