import React, { Component } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import './charts.css'

export default class BarChartDifficulty extends Component {
  constructor(props) {
    super(props);

    this.state = {
    chartOptions: {
      chart: {
        type: 'bar'
    },
    title: {
        text: '“What has been a difficult part of doing schoolwork this year?”'
    },
    subtitle: {
        text: ''
    },
    xAxis: {
        categories: ['Responses'],
        title: {
            text: null
        }
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Percentage',
            align: 'high'
        },
        labels: {
            overflow: 'justify'
        }
    },
    tooltip: {
        valueSuffix: ''
    },
    plotOptions: {
        bar: {
            dataLabels: {
                enabled: true
            }
        }
    },
    legend: {
        // layout: 'vertical',
        // align: 'right',
        // verticalAlign: 'top',
        // x: -40,
        // y: 80,
        // floating: true,
        // borderWidth: 2,
        // backgroundColor:
        //     Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
        // shadow: true
    },
    credits: {
        enabled: false
    },
    colors: ['#D14E1D','#00464F','#FFA75E','#575757','#007A8A'],
    series: [{
        name: 'Workload',
        data: [23]
    }, {
        name: 'Focus & Motivation',
        data: [20]
    }, {
        name: 'Technical Problems',
        data: [19]
    }, {
        name: 'Time Management',
        data: [13]
    }, {
        name: 'Communication / Asking for Help',
        data: [13]
    }]
      }
    };
  }


  render() {
    const { chartOptions } = this.state;

    return (
      <div class="chart">
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
        />
      </div>
    )
  }
}
