import React, { Component } from 'react';
import './fourA.css';

export default class FOURA extends Component {
  render() {
    return (
      <div id="fourA-container">
        <div class="everyday-section-title">
          <h4>FOUR (EVERYDAY UNSEEN)</h4>
        </div>
        <div class="fourA-content-container">
          <h2>We began with an artists' opening.</h2>
          <p>Soon the gallery was buzzing with the energy of 54 artists in a space dedicated to their artistry.</p>
        </div>
      </div>
    );
  }
}