import React, { Component } from 'react';
import './marineInternships-Header.css';

export default class MarineInternshipsHeader extends Component {
  render() {
    return(
      <div id="marineInternships-header-container">
          <div class="marineInternships-header-content-container">
            <h1>Marine Science Internships</h1>
          </div>
      </div>
    );
  }
}