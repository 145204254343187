import React, { Component } from 'react';
import './roboticsLandingPage-Intro.css';

export default class RoboticsLandingPageIntro extends Component {
  render() {
    return(
      <div id="roboticsLandingPage-intro-container">
        <div class="roboticsLandingPage-intro-content-container">
          <div class="roboticsLandingPage-intro-bold">
            <h3>foundry10 supports teachers and schools in integrating robotics into the school day.</h3>
          </div>
          <div class="roboticsLandingPage-intro-normal">
            <p>We work with classroom teachers and school administrations to fund robotics equipment, supplement teachers’ skills through professional development, develop curriculum, and offer technical assistance.</p>
          </div>
        </div>
      </div>
    );
  }
}