import React, { useState } from 'react';
import './ResearchTopics-Two.css';

function ResearchTopicsTwo() {
    const [isActive, setIsActive] = useState(false);
    // const [hasMore, setHasMore] = useState(false);

    return (


            <div className="healthWellBeing-researchTopicsTwoAccordion-item">
                <div className="healthWellBeing-researchTopicsTwoAccordion-title" onClick={() => setIsActive(!isActive)}>
                    <div className="healthWellBeing-researchTopicsTwoAccordion-image">
                        <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Health+and+Well-Being/Reseach+Topics/IA+Team+Page+Icons_Belonging+and+Adolescent+Well-Being+1-.png" alt="figure sitting within a clock holding minute hand"/>
                    </div>
                    <div className="healthWellBeing-researchTopicsTwoAccordion-text">
                        <h2 className="healthWellBeing-researchTopicsTwoAccordion-title-text">Belonging and Adolescent Well-Being</h2>
                        {/* <h3>{research.subtitle}</h3> */}
                    </div>
                    <i className={ isActive ?  `fa-solid fa-angle-up`:`fa-solid fa-angle-down`}></i>                  
                </div>
                {isActive && <div className="healthWellBeing-researchTopicsTwoAccordion-content">
                    <div className="healthWellBeing-researchTopicsTwoAccordion-description">
                        <p>A sense of belonging is a core human need that can shape our psychological and physical well-being. For young people, not feeling connected to others is a significant risk factor for the development of emotional and behavioral challenges, while feeling connected protects against negative health behaviors and enhances well-being. However, there is still much to learn about how schools and youth-serving spaces can cultivate and sustain a sense of belonging for all youth, particularly those who experience social isolation the most.</p>
                    </div>  
                    <div className="healthWellBeing-researchTopicsTwoAccordion-descriptionTwo">
                        <h2>Questions this research area will explore include:</h2>
                        <div className="healthWellBeing-researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>What school-based preventions and interventions promote positive interactions between students and between students and staff? What are the elements of successful preventions/interventions (e.g., one-off supports, ongoing support, or mentor)?</p>
                        </div>
                        <div className="healthWellBeing-researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>What preventions and interventions promote adolescent belonging in out-of-school spaces? What are the elements of successful preventions/interventions (e.g., one-off supports, ongoing support, or mentoring)?</p>
                        </div>
                    </div>
                    {/* <div className="healthWellBeing-researchTopicsTwoAccordion-descriptionThree">
                        <h2>Curriculum + Education</h2>
                        <div className="healthWellBeing-researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>What are important considerations for school-based civic learning opportunities that support meaningful youth engagement online?</p>
                        </div>
                        <div className="healthWellBeing-researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How should we approach curriculum focused on digital citizenship more effectively to support the skills youth need to thrive in today’s society?</p>
                        </div>
                        <div className="healthWellBeing-researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>To what extent are young people’s perspectives included in the development & implementation of curriculum about being a digital citizen?</p>
                        </div>
                    </div> */}
                </div>}
            </div>

    );
}

export default ResearchTopicsTwo;