import React, { Component } from 'react';
import './kcpt-Header.css';

export default class KcptHeader extends Component {
  render() {
    return(
      <div id="kcpt-header-container">
          <div class="kcpt-header-content-container">
            <h1>Elementary Theatre Lighting and Sound Design Tech Kits</h1>
          </div>
      </div>
    );
  }
}