import React from "react";
import './Science-Communication.css';

export default function ScienceCommunication({ title, subtitle, dark, id }) {
  return (
    // <div className={"section" + (dark ? " section-dark" : "")}>
    <div className="scienceCommunication-container">
      <div className="scienceCommunication-content-container" id={id}>
        <div className="scienceCommunication-final-content-container">
          <div className="scienceCommunication-illustration">
            <h1>Click below to learn more!</h1>
            <a class="scienceCommunication-link" href="/marine-science-careers/science-communication">
              <img src='https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/sciencecommunicator1.png' alt='science communication illustration'/>
              <p class="scienceCommunication-title">Meet Brenna & Bridget!</p>
              <div class="scienceCommunication-cards-overlay"></div>
            </a>
          </div>
          <div className="scienceCommunication-description">
            <h2>"Word Wizard"</h2>
            <p>RELATED TITLES: Science Communicator, Journalist</p>
            <p>Ever picked up a National Geographic and wondered who is behind the magical articles of adventure and exploration? The alchemy of science writing comes from diving deep into first hand research and exploration accounts and then meticulously translating those stories to a general audience.</p>
            <div className="scienceCommunication-requirements">
              <h2>Basic Degree/Training Requirements</h2>
              <ul className="scienceCommunication-iconList">
                <li>
                  <div class="scienceCommunication-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/white-HS-Diploma.png" alt="high school diploma" />
                  </div>
                </li>
                <li>
                  <div class="scienceCommunication-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/white-Undergrad-Cap.png" alt="undergrad cap" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}