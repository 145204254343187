import React, { Component } from 'react';
import './october21-HighlightThree.css';

export default class HighlightThree21PageStory extends Component {
  render() {
    return(
      <div id="highlightThree21Page-story-container">
        <div className="highlightThree21Page-story-text-container">
            <h2 className="highlightThree21Page-story-title">How Youth Can Build SEL Skills with TTRPGs</h2>
            <p className="highlightThree21Page-story-content">This white paper highlights findings from a collaborative study on the potential benefits of tabletop role-playing games as tools for supporting youth SEL skill development.</p>
            <a href="https://www.foundry10.org/research/how-youth-can-build-social-and-emotional-skills-with-tabletop-role-playing-games" target="_blank" rel="noopener noreferrer">Read Now</a>
        </div>
        <div className="highlightThree21Page-story-img-container">
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/News+Page/How+Youth+Can+Build+SEL+Skills+with+TTRPGs.jpg" alt="young lady holding a game dice"/>
        </div>
      </div>
    );
  }
}