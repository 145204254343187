import React, { Component } from 'react';
import './marineInternships-Body.css';

export default class MarineInternshipsBody extends Component {
  render() {
    return(
      <div id="autoResearch-womenInAuto-container">
        <div class="autoResearch-womenInAuto-content-container">
          {/* <div class="interestArea-domain-title">
            <h2>We Are Puget Sound - Discovering & Recovering the Salish Sea</h2>
          </div> */}
          <div class="autoResearch-womenInAuto-paragraph">
            <p>The Marine Science Team is committed to mentoring undergraduate and graduate student interns to support their professional skills, experience, and interests. Paid internships are seasonal for ten weeks in winter (January-March) and summer (June-August).</p>
            <p>Internship projects are co-developed based on individual intern strengths, needs of the Marine Science Team, and community opportunities.</p>
          </div>
          <div class="interestArea-domain-title">
            <h2>Past Marine Science Undergraduate Internship projects have included:</h2>
            <br/>
          </div>
          <a href="/marine-science-bycatch22" rel="noopener noreferrer">    
            <div class="autoResearch-cards-container">
              <img src="https://f10-programs.s3.us-west-2.amazonaws.com/marine-science/Marine+Science+Bycatch22/Bycatch22+Big+Card.png" alt="bycatch22 link"/>
              <p class="autoResearch-cards-title">Bycatch-22</p>
              <div class="autoResearch-cards-overlay"></div>
            </div>  
          </a>
          <br/>
          <br/>
          {/* <div class="interestArea-domain-title">
            <h2>Ultimate Guide to Underwater Work</h2>
            <br/>
          </div> */}
          <a href="https://www.etsy.com/listing/757788845/live-from-the-deep-comic-an-ocean?ref=shop_home_feat_1" target="_blank" rel="noopener noreferrer">    
            <div class="autoResearch-cards-container">
              <img src="https://f10-programs.s3.us-west-2.amazonaws.com/marine-science/Marine+Science+Internships/Live+From+the+Deep+Big+Card.jpg" alt="live from the deep comic etsy link"/>
              <p class="autoResearch-cards-title">Live From the Deep Comic</p>
              <div class="autoResearch-cards-overlay"></div>
            </div>  
          </a>
          <br/>
          <br/>
          {/* <div class="interestArea-domain-title">
            <h2>Pollution Survey & Report</h2>
            <br/>
          </div>
          <a href="https://www.wearepugetsound.org/" target="_blank" rel="noopener noreferrer">    
            <div class="autoResearch-cards-container">
              <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Resources/We+Are+Puget+Sound.png" alt="we are puget sound"/>
              <p class="autoResearch-cards-title">Pollution Survey & Report</p>
              <div class="autoResearch-cards-overlay"></div>
            </div>  
          </a>
          <br/>
          <br/> */}
          {/* <div class="interestArea-domain-title">
            <h2>Live from the Deep</h2>
            <br/>
          </div> */}
          <a href="/marine-science-careers" rel="noopener noreferrer">    
            <div class="autoResearch-cards-container">
              <img src="https://f10-image.s3-us-west-2.amazonaws.com/New+Site/Interest+Areas/Marine+Science/Marine+Science+Careers+big+card.jpg" alt="ultimate guide to underwater work link"/>
              <p class="autoResearch-cards-title">Ultimate Guide to Underwater Work</p>
              <div class="autoResearch-cards-overlay"></div>
            </div>  
          </a>
          <br/>
          <br/>
          <div class="interestArea-domain-title">
            <h2>Past Marine Science Graduate Internship projects have included:</h2>
          </div>
          <div class="autoResearch-womenInAuto-paragraph">
            <p>•  Developing a cross institutional evaluation for a consortium of small aquariums.</p>
          </div>
          <div class="autoResearch-womenInAuto-paragraph">
            <p>If you or someone you know is interested in applying for a Marine Science Internship at foundry10, please <a href="https://foundry10.us3.list-manage.com/subscribe?u=61e3e87d0bc76df778693653e&id=65c221485b" target="_blank" rel="noopener noreferrer">sign up for the newsletter</a> to stay informed of the next open application cycle.</p>
          </div>
        </div>
      </div>
    );
  }
}