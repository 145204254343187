import React, { Component } from 'react';
import './returnToLearn-Description.css';

export default class ReturnToLearnDescription extends Component {
  render() {
    return(
      <div id="returnToLearn-description-container">
        <div class="returnToLearn-description-content-container">
            <p>foundry10 supports research at the University of Washington Harborview Injury Prevention and Research Center measuring the effectiveness of the Return to Learn (RTL) Implementation Bundle for Schools after Youth Concussion. The goal is to validate easy-to-use tools, including best-practice academic accommodations and care coordination, to help students return to their full classroom potential earlier.</p>
        </div>
      </div>
    );
  }
}