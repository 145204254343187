import React, { Component } from 'react';
import './projectsLandingPage-Intro.css';

export default class ProjectsLandingPageIntro extends Component {
  render() {
    return(
      <div id="projectsLandingPage-intro-container">
        <div class="projectsLandingPage-intro-content-container">
          <div class="projectsLandingPage-intro-bold">
            <h3>Project-Based Learning: Automotive Programs for Education</h3>
            {/* <h3>Outside the Shop: Automotive Trips for Education</h3> */}
          </div>
          <div class="projectsLandingPage-intro-normal">
            <p>Run in collaboration with dedicated educators, foundry10’s automotive projects emphasize advanced technology and professional industry experiences for students.</p>
          </div>
        </div>
      </div>
    );
  }
}