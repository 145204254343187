import React from 'react';
import './technologyMediaInformationLiteracy-EducationProgramming.css'; // You can create this CSS file for styling

const EducationProgramming = () => {
  return (
    <div className="technologyMediaInformationLiteracyEducationProgramming-container">
      <div className="technologyMediaInformationLiteracyEducationProgramming-content-container">
      <h2 className="technologyMediaInformationLiteracyEducationProgramming-title">Education Programming</h2>
                <div className="technologyMediaInformationLiteracyEducationProgramming-highlights-grid-container">
                  <div className="technologyMediaInformationLiteracyEducationProgramming-highlights-image-container">
                    <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Technology%2C+Media%2C+and+Information+Literacy/Ocean+Sensors.jpg" alt="youth students working on ocean sensors project at the dock"/>
                  </div>
                    <div className="technologyMediaInformationLiteracyEducationProgramming-highlights-text">
                      <h2><a className="technologyMediaInformationLiteracyEducationProgramming-highlightsLink" href="https://www.foundry10.org/research/sensors-students-and-self" target="_blank" rel="noopener noreferrer">Ocean Sensors</a></h2>
                      <p>New technologies, sensors, and tools are expanding our ability to explore the ocean system, so students will gain an understanding of both the benefits and constraints of ocean sensors in this unit led by foundry10 program developers. Lincoln High School students will build their own sensors, test them, and collect data with them in local waterways. They will also learn to think critically about what their data means and what the connections the sensor development process has to larger topics like resource management and e-waste.</p>
                    </div>
                    {/* <div className="technologyMediaInformationLiteracyEducationProgramming-highlights-link">
                      <p>Dec 7th, 2021</p>
                      <a href='https://drive.google.com/file/d/1k3kbUQE1OC-aZ663sFm5mXOvqrP211yL/view?usp=sharing' className="technologyMediaInformationLiteracyEducationProgramming-highlights-content-link" target="_blank" rel="noopener noreferrer"><i class="fa-solid fa-arrow-right"></i></a>
                    </div> */}
                  {/* </div> */}
                </div>
              {/* <a className="technologyMediaInformationLiteracyEducationProgramming-highlightsLink" href="https://www.foundry10.org/research/sensors-students-and-self" target="_blank" rel="noopener noreferrer"> */}
                <div className="technologyMediaInformationLiteracyEducationProgramming-highlights-grid-container">
                  <div className="technologyMediaInformationLiteracyEducationProgramming-highlights-image-container">
                    <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Technology%2C+Media%2C+and+Information+Literacy/Physical+Computing+in+the+Classroom+-+One+Week+Robotics+Course.jpg" alt="high school students working on project in robotics course"/>
                  </div>
                  {/* <div className="technologyMediaInformationLiteracyEducationProgramming-highlights-info-container">  */}
                    {/* <div className="technologyMediaInformationLiteracyEducationProgramming-highlights-tags">
                      <div className="technologyMediaInformationLiteracyEducationProgramming-highlights-hash">
                        <div className="youthHash-teal">REPORT</div>
                        <div className="youthHash-hash">#Drama</div>
                      </div>
                    </div> */}
                    <div className="technologyMediaInformationLiteracyEducationProgramming-highlights-text">
                      <h2><a className="technologyMediaInformationLiteracyEducationProgramming-highlightsLink" href="https://www.foundry10.org/resources/physical-computing-in-the-classroom-one-week-robotics-course" target="_blank" rel="noopener noreferrer">Physical Computing in the Classroom: One-Week Robotics Course</a></h2>
                      <p>Piloted at Scriber Lake High School, this program is designed to expose youth to robotics through hands-on experience. Learning is scaffolded so that groups start by constructing and coding simple robots, using mini-challenges as introductions to basic coding principles. Over the course of a week, groups are given more complicated challenges that require them to modify their robots and use more complex coding techniques.</p>
                    </div>
                    {/* <div className="technologyMediaInformationLiteracyEducationProgramming-highlights-link">
                      <p>Dec 7th, 2021</p>
                      <a href='https://drive.google.com/file/d/1k3kbUQE1OC-aZ663sFm5mXOvqrP211yL/view?usp=sharing' className="technologyMediaInformationLiteracyEducationProgramming-highlights-content-link" target="_blank" rel="noopener noreferrer"><i class="fa-solid fa-arrow-right"></i></a>
                    </div> */}
                  {/* </div> */}
                </div>
              {/* </a> */}
              {/* <div class="technologyMediaInformationLiteracyEducationProgramming-publications-container">
                <div class="technologyMediaInformationLiteracyEducationProgramming-publications-content-container">
                  <ul class="technologyMediaInformationLiteracyEducationProgramming-publications-list">
                    <li>
                      <a href="/" target="_blank" rel="noopener noreferrer">Lorem ipsum dolor sit amet, consectetur adipiscing elit</a>
                    </li>
                    <li>
                      <a href="/" target="_blank" rel="noopener noreferrer">Lorem ipsum dolor sit amet, consectetur adipiscing elit</a>
                    </li>
                    <li>
                      <a href="/" target="_blank" rel="noopener noreferrer">Lorem ipsum dolor sit amet, consectetur adipiscing elit</a>
                    </li>
                  </ul>
                </div>
              </div> */}
      </div>
    </div>
  );
};

export default EducationProgramming;
