import React, { Component } from 'react';
import SmartDescription from './smart-Description';
import SmartAbstracts from './smart-Abstracts';
import NewFooter from '../../../../pages-other/NewFooter';
import './smart-Content.css';

export default class SmartContent extends Component {
  render() {
    return(
      <div id="smart-content-container">
        <SmartDescription />
        <SmartAbstracts />
        <NewFooter />
      </div>
    );
  }
}