import React, { Component } from 'react';
import './annualReport2021FinEd-Spotlights.css';

export default class AnnualReportFinEdSpotlights extends Component {
  render() {
    return(
      <div id="annualReport2021FinEd-spotlights-container">
        <div className="annualReport2021FinEd-spotlight-first-title-bar">
          <p>Spotlight: Youth Want More Financial Education</p>
        </div>
        <div className="annualReport2021FinEd-spotlights-content-container">
          <div className="annualReport2021FinEd-spotlights-text-container">
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Subpages/numbers-money-calculating-calculation.jpg" alt="coins stacked by a calculator in orange and teal"/>
            <p>
            A primary goal of ours is to understand youth experiences with personal finance so we can better support their needs as they transition to young adulthood. To help accomplish this goal, we conducted a mixed-methods study using surveys and interviews to examine the financial attitudes and knowledge of young people between the ages of 15-22 in the Greater Seattle area. Preliminary results indicate that youth want more opportunities to learn about managing finances in high school and college. Since many youth did not have access to financial education at school, they often searched for outside resources (e.g., parents, social media) to learn about topics such as distinguishing between wants and needs, saving, college debt, and taxes. We will build off the initial findings of this study to more closely examine how youth are interpreting financial information they receive in a future iteration of the study.
            <br /> <br />
            <a href="https://medium.com/the-foundry10-voice/youth-want-more-opportunities-for-financial-education-survey-48877063abbd" target="_blank" rel="noopener noreferrer">Youth Want More Opportunities for Financial Education: Survey</a>
            </p>
          </div>
        </div>
        <div className="annualReport2021FinEd-spotlight-second-title-bar">
          <p>Spotlight: Exploring Women’s Financial Education with Savvy Ladies</p>
        </div>
        <div className="annualReport2021FinEd-spotlights-content-container">
          <div className="annualReport2021FinEd-spotlights-text-container">
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Subpages/savy+ladies.jpg" alt="foundry10 + Savvy Ladies - Women and Money - The benefits of a financial education course for women's financial attitudes"/>
            <p>In line with our goal to support and understand the benefits of adult financial literacy, particularly with women, we teamed with the non-profit financial education organization Savvy Ladies to support a 6-week session of their debt education program. Additionally, foundry10 and Savvy Ladies were interested in studying the potential benefits of this course on women’s financial attitudes and behaviors. We found that participants reported less financial anxiety, greater financial self-confidence, and greater financial self-efficacy after participating in the program. Participants also reported greater positive attitudes towards financial planning, less anxiety about financial literacy, and less gender stereotyping about money after participating in the program. We were excited to collaborate with Savvy Ladies and study the impacts of a program aimed at improving women’s financial education.
            <br /> <br />
            <a href="https://medium.com/the-foundry10-voice/foundry10-announces-new-research-partnership-with-savvy-ladies-inc-824f63d8c1b7" target="_blank" rel="noopener noreferrer">foundry10 Announces New Research Partnership with Savvy Ladies Inc.</a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}