import React, { Component } from 'react';
import AfterschoolLandingPageIntro from './afterschoolLandingPage-Intro';
import AfterschoolLandingPageDescription from './afterschoolLandingPage-Description';
import AfterschoolLandingPageLinks from './afterschoolLandingPage-Links';
import NewFooter from '../../../../pages-other/NewFooter';
import './afterschoolLandingPage-Content.css';

export default class AfterschoolLandingPageContent extends Component {
  render() {
    return(
      <div id="afterschoolLandingPage-content-container">
        <AfterschoolLandingPageIntro />
        <AfterschoolLandingPageDescription />
        <AfterschoolLandingPageLinks />
        <NewFooter />
      </div>
    );
  }
}