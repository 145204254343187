import React, { Component, Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import LcpTestHeader from './lcpTest-Header';
import LcpTestContent from './lcpTest-Content';
// import './lcpTest.css';

const HERO_IMAGE_URL = 'https://d1dyzrv677gyi1.cloudfront.net/LCP_test_hero_2.webp';

export default function LcpTest() {
    const [isLoading, setIsLoading] = useState(true);
  
    // Preload the hero image and set loading state
    useEffect(() => {
      const img = new Image();
      img.src = HERO_IMAGE_URL;
      img.onload = () => setIsLoading(false); // Update loading state when the image is loaded
    }, []);
  
    return (
      <div id="lcpTest-container">
        <Helmet>
          <title>foundry10 Automotive Education Research for Youth</title>
          <meta
            name="title"
            content="foundry10 Automotive Education Research for Youth"
          />
          <meta
            name="description"
            content="Explore research questions in automotive technology with foundry10. Discover how students benefit from technology exposure and consider career pathways."
          />
          <meta property="og:title" content="foundry10 Automotive Education Research for Youth" />
          <meta
            property="og:description"
            content="Explore research questions in automotive technology with foundry10. Discover how students benefit from technology exposure and consider career pathways."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/automotive-research" />
          <link rel="canonical" href="https://www.foundry10.org/automotive-research" />

          {/* Prevents search engine crawlers from indexing page */}
          <meta name="robots" content="noindex" />
          
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Automotive Education Research for Youth",
                "description": "Explore research questions in automotive technology with foundry10. Discover how students benefit from technology exposure and consider career pathways.",
                "url": "https://www.foundry10.org/automotive-research"
              }
            `}
          </script>
          {/* Preload the hero image */}
          <link rel="preload" href={HERO_IMAGE_URL} as="image" />
        </Helmet>
  
        {/* Show skeleton loader while the image is loading */}
        {isLoading ? (
          <div className="skeleton-loader">Loading...</div>
        ) : (
          <LcpTestHeader />
        )}
        <LcpTestContent />
      </div>
    );
  }
