import React, { Component } from 'react';
import PhilanthropyLandingPageIntro from './PhilanthropyLandingPage-Intro';
import PhilanthropyLandingPagePillars from './PhilanthropyLandingPage-Pillars';
import PhilanthropyLandingPageStories from './PhilanthropyLandingPage-Stories';
import NewFooter from '../pages-other/NewFooter';
import './PhilanthropyLandingPage-Content.css';

export default class PhilanthropyLandingPageContent extends Component {
  render() {
    return(
      <div id="philanthropyLandingPage-content-container">
        <PhilanthropyLandingPageIntro />
        <PhilanthropyLandingPagePillars />
        <PhilanthropyLandingPageStories />
        <NewFooter />
      </div>
    );
  }
}