import React from 'react';
import './healthWellBeing-Breadcrumb.css';

const HealthWellBeingBreadcrumb = () => {

  const handleLinkClick = (url) => {
    // Perform a full page reload by setting window.location.href to the specified URL
    window.location.href = url;
  };

   return (
    <div class="healthWellBeing-breadcrumb-container">
      <div class="healthWellBeing-breadcrumb-content-container">
        <div class="healthWellBeing-breadcrumb-title">
          <div className="healthWellBeing-breadcrumb-titleWhite">
            <p><a href="/"><i class="fa-solid fa-house"></i></a></p>
            <p><i class="fa-solid fa-angle-right"></i></p>
            <p><a href="/interest-areas" onClick={() => handleLinkClick('/interest-areas')}>Interest Areas</a></p>
            <p><i class="fa-solid fa-angle-right"></i></p>
          </div>
          <div className="healthWellBeing-breadcrumb-titleOrange">
            <p>Health and Well-Being</p>
          </div>
        </div>
      </div>
    </div>
   )
}
export default HealthWellBeingBreadcrumb;