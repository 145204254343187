import React, { Component } from 'react';
import CenterHumanServicesDescription from './centerHumanServices-Description';
import NewFooter from '../../../../pages-other/NewFooter';
import './centerHumanServices-Content.css';

export default class CenterHumanServicesContent extends Component {
  render() {
    return(
      <div id="centerHumanServices-content-container">
        <CenterHumanServicesDescription />
        <NewFooter />
      </div>
    );
  }
}