import React, { Component } from 'react';
import './autoResearch-Circuit.css';

export default class AutoResearchCiruit extends Component {
  render() {
    return(
      <div id="autoResearch-womenInAuto-container">
        <div class="autoResearch-womenInAuto-content-container">
          <div class="interestArea-domain-title">
            <h2>Circuit Training Boards</h2>
          </div>
          <div class="autoResearch-womenInAuto-paragraph">
            <p>In this case study, we were investigating the usefulness of skill-building electrical knowledge through industry standard circuit training boards at the high school level. Typically these boards are not used until students are in post-secondary educational settings but there is interest in providing expanded opportunities for younger students to explore these concepts and ideas.</p>
          </div>
          <a href="https://drive.google.com/file/d/1vKDM8rySN08MTK-JnXBcDO-wX72THKth/view?usp=sharing" target="_blank" rel="noopener noreferrer">    
            <div class="autoResearch-cards-container">
              <img src="https://f10-programs.s3-us-west-2.amazonaws.com/automotive-tech/auto+page+pictures/Circuit+Board.png" alt="circuit training board summary" />
              <p class="autoResearch-cards-title">Project Summary</p>
              <div class="autoResearch-cards-overlay"></div>
            </div>  
          </a>
        </div>
      </div>
    );
  }
}