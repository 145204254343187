import React, { Component } from 'react';
import './projectsLandingPage-Header.css';

export default class ProjectsLandingPageHeader extends Component {
  render() {
    return(
      <div id="projectsLandingPage-header-container">
          <div class="projectsLandingPage-header-content-container">
            <h1>Automotive Projects</h1>
          </div>
      </div>
    );
  }
}