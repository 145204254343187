import React, { Component } from 'react';
import './artistTeam.css';

class ArtistTeam extends Component {
  render() {
    const artisticDesignTeam = [
      {
        'name': 'Sarah Stanger'
      },
      {
        'name': 'Arabella Bautista'
      },
      {
        'name': 'Marc Pacampara'
      },
      {
        'name': 'Anna Cechony'
      },
      {
        'name': 'John Roque'
      },
      {
        'name': 'Chloe Wright'
      },
      {
        'name': 'Jon Garaizar'
      }
    ];
    const contributingArtists = [
      {
        'name': 'Ace Huang'
      },
      {
        'name': 'Alexandra McDowell'
      },
      {
        'name': 'Alice Wang'
      },
      {
        'name': 'Anakin Saephanh'
      },
      {
        'name': 'Ardelle Ning'
      },
      {
        'name': 'Audrey Kang'
      },
      {
        'name': 'Ayana Muwwakkil'
      },
      {
        'name': 'Candace Chang'
      },
      {
        'name': 'Cathy Tran'
      },
      {
        'name': 'Elias Papadimas'
      },
      {
        'name': 'Ellie Works'
      },
      {
        'name': 'Eowyn Morningstar'
      },
      {
        'name': 'Esther Angeles'
      },
      {
        'name': 'Eva Smerekanych'
      },
      {
        'name': 'Gabriel Escobedo'
      },
      {
        'name': 'Hosana Zewoldi'
      },
      {
        'name': 'Irene Jin'
      },
      {
        'name': 'Isa Lewis'
      },
      {
        'name': 'Isabel Warn'
      },
      {
        'name': 'Isabella (Izzy) Des Roches'
      },
      {
        'name': 'Jey Vargas'
      },
      {
        'name': 'Judy Kim'
      },
      {
        'name': 'Julia Neils'
      },
      {
        'name': 'Kaeylob Johnson'
      },
      {
        'name': 'Kristie Martz'
      },
      {
        'name': 'Lily Molloy'
      },
      {
        'name': 'Mabel Baumgardner'
      },
      {
        'name': 'Madison Kim'
      },
      {
        'name': 'Mimi Lucking'
      },
      {
        'name': 'Mollie McCarthy'
      },
      {
        'name': 'Rachel Ho'
      },
      {
        'name': 'Rey Daoed'
      },
      {
        'name': 'Rianne Nelwan'
      },
      {
        'name': 'Savannah Johnson'
      },
      {
        'name': 'Sebastian Robbins'
      },
      {
        'name': 'Simone Williams'
      },
      {
        'name': 'Sophie Engle'
      },
      {
        'name': 'Teddy Avestruz'
      },
      {
        'name': 'Tiffany Howell'
      },
      {
        'name': 'Tony Walters'
      },
      {
        'name': 'Yulia Goysman'
      }
    ];
    const allDesignTeam = artisticDesignTeam.map((records, index) => (
      <li>
        <p>{records.name}</p>
      </li>
    ));
    const allContributingArtists = contributingArtists.map((records, index) => (
      <li>
        <p>{records.name}</p>
      </li>
    )); 
    return(
      <div id="artistTeam-container">
        <div class="artistTeam-content-container">
          <div class="artistTeam-content">
            <div class="artistTeam-title">
              <h4>TEAM</h4>
              <h2>Design Team & Artists</h2>
            </div>
            <div>
              <div class="artisticDesignTeam-container">
                <h4>ARTISTIC DESIGN TEAM</h4>
                <ul id="artistCardsList2" class="artist-list2">
                  {allDesignTeam}
                </ul> 
              </div>
              <div class="contributingArtists-container">
                <h4>CONTRIBUTING ARTISTS</h4>
                <ul id="artistCardsList2" class="artist-list2">
                  {allContributingArtists}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ArtistTeam;