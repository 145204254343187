import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ConceptualDevelopmentLabHeader from './conceptualDevelopmentLab-Header';
import ConceptualDevelopmentLabContent from './conceptualDevelopmentLab-Content';
import './conceptualDevelopmentLab.css';

export default class ConceptualDevelopmentLab extends Component {
  render() {
    return(
      <div id="conceptualDevelopmentLab-container">
        <Helmet>
          <title>foundry10 Conceptual Development Research Lab</title>
          <meta
            name="title"
            content="foundry10 Conceptual Development Research Lab"
          />
          <meta
            name="description"
            content="Understand how children grasp abstract ideas, like gravity, and find new teaching methods to overcome learning challenges from foundry10 researchers."
          />
          <meta property="og:title" content="foundry10 Conceptual Development Research Lab" />
          <meta
            property="og:description"
            content="Understand how children grasp abstract ideas, like gravity, and find new teaching methods to overcome learning challenges from foundry10 researchers."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/conceptual-development-lab" />
          <link rel="canonical" href="https://www.foundry10.org/conceptual-development-lab" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Conceptual Development Research Lab",
                "description": "Understand how children grasp abstract ideas, like gravity, and find new teaching methods to overcome learning challenges from foundry10 researchers.",
                "url": "https://www.foundry10.org/conceptual-development-lab"
              }
            `}
          </script>
        </Helmet>
        <ConceptualDevelopmentLabHeader />
        <ConceptualDevelopmentLabContent />
      </div>
    );
  }
}