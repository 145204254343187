import React, { Component } from 'react';
import Slider from "react-slick";
import ResearcherData from './testimonials-Data';
import './researcherResources-TestimonialsSlideshow.css';

export default class TestimonialsSlideshow extends Component {
    render() {
        const settings = {
          dots: true,
          swipeToSlide: true,
          // fade: true,
          arrows: false,
          infinite: true,
          speed: 400,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          // centerMode: true,
          // centerPadding: '450px',
          responsive: [
            {
              breakpoint: 1800,
              settings: {
                centerPadding: '350px'
              }
            },
            {
              breakpoint: 1615,
              settings: {
                centerPadding: '250px'
              }
            },
            {
              breakpoint: 1370,
              settings: {
                centerPadding: '200px'
              }
            },
            {
                breakpoint: 1250,
                settings: {
                  centerPadding: '150px'
                }
              },
            {
                breakpoint: 1150,
                settings: {
                  centerMode: false,
                  arrows: false
                }
              },
            {
                breakpoint: 800,
                settings: {
                  centerPadding: '80px',
                }
              },
            {
                breakpoint: 675,
                settings: {
                  centerMode: false,
                  arrows: false
                }
              }
          ]
        };

    return (
        <div className="researcherResources-testimonials-content-container">
            <div id="researcherResources-testimonials-Slideshow-container">
                <div className="researcherResources-testimonials-Slideshow-content-container">
                    <div className="researcherResources-testimonials-Slideshow-slider">
                    <Slider {...settings}>
                        {ResearcherData.map((eachTestimonial) => (
                                    <div className="researcherResources-testimonials-Slideshow-card" key={eachTestimonial.testimonial.title}>
                                        <div className="researcherResources-testimonials-Slideshow-card-content">   
                                            <div className="researcherResources-testimonials-Slideshow-card-content-container">
                                                <div className="researcherResources-testimonials-Slideshow-quoteSymbol">
                                                    <i class="fa-solid fa-quote-left"></i>                                        
                                                </div>
                                                <div className="researcherResources-testimonials-Slideshow-text">
                                                    <p>{eachTestimonial.testimonial.quote}</p>
                                                    <h2>{eachTestimonial.testimonial.person}</h2>
                                                </div>
                                            </div>                 
                                        </div>
                                    </div>
                        ))}
                    </Slider>
                    </div>
                </div> 
            </div>
        </div>          
        );
      }
}