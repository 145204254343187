import React, { Component } from 'react';
import './roboticsLandingPage-Description.css';

export default class RoboticsLandingPageDescription extends Component {
  render() {
    return(
      <div id="roboticsLandingPage-description-container">
        <div class="roboticsLandingPage-description-content-container">
        
          <h3>
          Why Robotics in the Classroom?
          </h3>

          <p>Technological innovations have led to the development of new and exciting education products, such as robots like Ozobots, Spheros, and LEGO EV3s. However, we know new does not always mean better. The foundry10 STEM Team strives to look objectively at how educational robotic products enhance and add value to students’ learning.</p>

          <h3>
          What are we currently doing?
          </h3>

          <p>foundry10 is interested in how students engage with robotics in their classrooms and how teachers can bring STEM to life using innovative robotic technologies. To better understand how robotics can add value to student learning, we provide curriculum, equipment and professional development resources for teachers who want to integrate robotics into their school day. To date, we have brought Ozobots, Dash robots, Spheros, LEGO EV3s, and Sumo Bots into classrooms around the state of Washington. We are continuously looking for new ideas from teachers about how to integrate robotics in their classrooms.</p>

          <p>Interested in learning more? Contact us at <a href="mailto:STEM@foundry10.org" target="_blank" rel="noopener noreferrer">STEM@foundry10.org</a></p>

          <h3>
          What have we learned so far?
          </h3>

          <p>
          Robotics in classrooms can be highly engaging and has an opportunity to truly extend learning beyond a pencil and paper. As we continue to work with classroom teachers to implement robotics in classrooms we have learned a few key elements to successful implementation:
            <ol>
              <li>
              Successful implementation depends on a highly engaged and interested teacher. The teacher doesn’t necessarily need to be an expert user, but without a high interest and a willingness to learn, we find that robotics equipment often gets used once and then shelved and forgotten. It is important to work with the teacher to really understand the type of equipment they can utilize in their classroom without creating waste. The right fit is key. 
              </li>
              <li>
              Equipment connectivity can often be a barrier when bringing robotics into a classroom. Selecting the right robotics equipment is key to a successful classroom implementation. A few things to consider before buying robotics equipment for your classroom:
              <ul>
                <li>
                Can the robot achieve your lesson objectives without connecting to another screen device?
                </li>
                <li>
                Do your students have access to a computer lab, one-to-one computers, tablets, or no access to computing devices at all?  
                </li>
                <li>
                How does the robot interface with the screen device? Do you need WiFi or a USB port? Does your school or program location have a strong WiFi connection?
                </li>
                <li>
                Will you need to download a specific app or is the robotics software web-based?
                </li>
              </ul> 
              </li>
            </ol>
            While we have seen some teachers have success integrating robotics into their classroom, we are continuing to look at student engagement and how robotics can enhance and extend learning. We’re interested in taking a closer look at how teachers are making the distinction between engagement with robotics and enhancement of concepts through robotics. If you are already using robotics in your classroom or would like to incorporate robotics into your lessons, reach out to the foundry10 STEM Team at <a href="mailto:STEM@foundry10.org">STEM@foundry10.org</a>.
          </p>
        </div>
      </div>
    );
  }
}