import React, { Component } from 'react';
import RoboticsLandingPageIntro from './roboticsLandingPage-Intro';
import RoboticsLandingPageDescription from './roboticsLandingPage-Description';
import RoboticsLandingPageLinks from './roboticsLandingPage-Links';
import NewFooter from '../../../../pages-other/NewFooter';
import './roboticsLandingPage-Content.css';

export default class RoboticsLandingPageContent extends Component {
  render() {
    return(
      <div id="roboticsLandingPage-content-container">
        <RoboticsLandingPageIntro />
        <RoboticsLandingPageDescription />
        <RoboticsLandingPageLinks />
        <NewFooter />
      </div>
    );
  }
}