import React from "react";
import './Shark-Research.css';

export default function SharkResearch({ title, subtitle, dark, id }) {
  return (
    // <div className={"section" + (dark ? " section-dark" : "")}>
    <div className="sharkScientist-container">
      <div className="sharkScientist-content-container" id={id}>
        <div className="sharkScientist-final-content-container">
          <div className="sharkScientist-illustration">
            <h1>Click below to learn more!</h1>
            <a class="sharkScientist-link" href="/marine-science-careers/shark-research">
              <img src='https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/sharkresearch1.png' alt='shark research illustration'/>
              <p class="sharkScientist-title">Meet Carlee!</p>
              <div class="sharkScientist-cards-overlay"></div>
            </a>
          </div>
          <div className="sharkScientist-description">
            <h2>"Cartilaginous Champion"</h2>
            <p>RELATED TITLES: Marine Biologist, Chondrichthyologist</p>
            <p>Shark week is every week for a shark scientist. These specialists work to understand sharks’ crucial role in the oceans’ ecosystems and inform the public’s understanding around this threatened group of vilified fish.</p>
            <div className="sharkScientist-requirements">
              <h2>Basic Degree/Training Requirements</h2>
              <ul className="sharkScientist-iconList">
                <li>
                  <div class="sharkScientist-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/white-HS-Diploma.png" alt="high school diploma" />
                  </div>
                </li>
                <li>
                  <div class="sharkScientist-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/white-Undergrad-Cap.png" alt="undergrad degree" />
                  </div>
                </li>
                <li>
                  <div class="sharkScientist-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/white-Grad-Degree.png" alt="undergrad degree" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}