import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import NewEquityHeader from './newEquity-Header';
import NewEquityContent from './newEquity-Content';
import './newEquity.css';

export default class NewEquity extends Component {
  render() {
    return(
      <div id="newEquity-container">
        <Helmet>
          <title>foundry10 Equity Commitment</title>
          <meta
            name="title"
            content="foundry10 Equity Commitment"
          />
          <meta
            name="description"
            content="Explore foundry10's commitment to equity in education. Learn about our goals, activities, and considerations as we expand ideas about learning."
          />
          <meta property="og:title" content="foundry10 Equity Commitment" />
          <meta
            property="og:description"
            content="Explore foundry10's commitment to equity in education. Learn about our goals, activities, and considerations as we expand ideas about learning."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/equity" />
          <link rel="canonical" href="https://www.foundry10.org/equity" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Equity Commitment",
                "description": "Explore foundry10's commitment to equity in education. Learn about our goals, activities, and considerations as we expand ideas about learning.",
                "url": "https://www.foundry10.org/equity"
              }
            `}
          </script>
        </Helmet>
        <NewEquityHeader />
        <NewEquityContent />
      </div>
    );
  }
}