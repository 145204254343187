import React, { Component } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import './charts.css'

export default class BarChartHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // To avoid unnecessary update keep all options in the state.
    chartOptions: {
      chart: {
        type: 'bar'
      },
      title: {
          text: 'Home Context Breakdown of Sample'
      },
      xAxis: {
          categories: ['Home Context'],
      },
      yAxis: {
          min: 0,
          title: {
              text: 'Count'
          },
      },
      legend: {
          reversed: true
      },
      plotOptions: {
          series: {
              stacking: 'normal'
          }
      },
      colors: ['#D14E1D','#00464F','#FFA75E','#007A8A'],
      series: [{
          name: 'Help from Adults',
          data: [25]
      }, {
          name: 'Help from Peers',
          data: [10]
      }, {
          name: 'Help from Adults & Peers',
          data: [9]
      }, {
          name: 'Schoolwork by Myself',
          data: [47]
      }]
      }
    };
  }


  render() {
    const { chartOptions } = this.state;

    return (
      <div class="chart">
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
        />
      </div>
    )
  }
}
