const ResearchThemesData = [
    {
        theme: {
            title: 'Career Development',
            img: 'https://f10-image.s3-us-west-2.amazonaws.com/New+Site/Research+Landing+Page/Research+Constructs/Header+Illustrations/career+development+small.png',
            alt: 'illustrated traveler examining guidepost',
            content: 'Career development describes how learners explore their futures and choose their pathways. Our work focuses on both internal processes of career exploration, motivation, and interest, as well as external processes that influence career pathways such as mentorship and systemic barriers.',
            url: 'https://www.foundry10.org/resources?cat=29215'
        }
    },
    {
        theme: {
            title: 'Cognitive Development',
            img: 'https://f10-image.s3-us-west-2.amazonaws.com/New+Site/Research+Landing+Page/Research+Constructs/Header+Illustrations/cognitive+development+small.png',
            alt: 'illustrated students interacting with a video game system and numbers on a chalkboard',
            content: 'Cognitive development broadly refers to the ways in which youth construct thought processes that include problem solving, remembering, decision-making, metacognition, and executive functioning. It is essential to traditional and non-traditional learning opportunities.',
            url: 'https://www.foundry10.org/resources?cat=3504'
        }
    },
    {
        theme: {
            title: 'Creativity',
            img: 'https://f10-image.s3-us-west-2.amazonaws.com/New+Site/Research+Landing+Page/Research+Constructs/Header+Illustrations/creativity+small.png',
            alt: 'illustrated figure sculpting at a table',
            content: 'Everyone has the capacity to be creative, and cultivating creativity is fundamental to learners’ long-term success. Creativity involves multi-faceted processes that include a wide range of idea creation techniques, the restructuring of ideas with novel contributions, positive risk-taking, and the application of prior skills and knowledge to new circumstances.',
            url: 'https://www.foundry10.org/resources?cat=29216'
        }
    },
    {
        theme: {
            title: 'Equity & Access',
            img: 'https://f10-image.s3-us-west-2.amazonaws.com/New+Site/Research+Landing+Page/Research+Constructs/Header+Illustrations/equity+and+access+small.png',
            alt: 'illustrated figure putting together a puzzle of their own image',
            content: 'Equity describes fair and just conditions in which one’s social identity does not differentially influence overall well-being. foundry10 researches equity and access to understand how educational pathways can present both barriers and opportunities for youth, and to learn how to create more equitable educational environments.',
            url: 'https://www.foundry10.org/resources?cat=3069'
        }
    },
    {
        theme: {
            title: 'Group Learning',
            img: 'https://f10-image.s3-us-west-2.amazonaws.com/New+Site/Research+Landing+Page/Research+Constructs/Header+Illustrations/group+learning+small.png',
            alt: 'three illustrated children playing a board game',
            content: 'Group learning leverages individuals’ diverse perspectives, experiences, knowledge, and skill sets to foster deeper understandings of content, critical thinking, and interpersonal relationships. Group learning provides people with opportunities to express their ideas, uncover assumptions, engage in teamwork, and negotiate and reconcile alternative understandings.',
            url: 'https://www.foundry10.org/resources?cat=694'
        }
    },
    {
        theme: {
            title: 'Motivation',
            img: 'https://f10-image.s3-us-west-2.amazonaws.com/New+Site/Research+Landing+Page/Research+Constructs/Header+Illustrations/motivation+small.png',
            alt: 'illustration of youth and adult interacting - lightbulb above the youth indicates an idea',
            content: 'Motivation describes a wide array of skills and dispositions including: engagement, goal setting and management, hope and optimism, perseverance, self-efficacy, and growth mindsets. It is an important asset for student driven inquiry, deeper learning, and the ability to grow through challenges.',
            url: 'https://www.foundry10.org/resources?cat=3506'
        }
    },
    {
        theme: {
            title: 'Research Methods',
            img: 'https://f10-image.s3-us-west-2.amazonaws.com/New+Site/Research+Landing+Page/Research+Constructs/Header+Illustrations/research+method+small.png',
            alt: 'illustrated figures interact with a pie chart on a whiteboard',
            content: 'We have the flexibility and freedom to apply multiple methods for data collection and analysis to work towards bridging the research to practice gap. We continuously expand our use and validation of rigorous measures and evaluation processes by studying not only learning processes but the methods of research itself. We do work that asks whether existing methods are appropriate and measure what is intended, and try novel approaches to collecting and analyzing data.',
            url: 'https://www.foundry10.org/resources?cat=3507'
        }
    },
    {
        theme: {
            title: 'Social & Cultural Development',
            img: 'https://f10-image.s3-us-west-2.amazonaws.com/New+Site/Research+Landing+Page/Research+Constructs/Header+Illustrations/social+and+cultural+development+small.png',
            alt: 'illustrated youth walks through three panels of backgrounds indicating a playground, a home setting and a park',
            content: 'Social development describes how people learn to interact with others and their environments, as well as their perceptions of their own individuality and communities. Cultural development involves understanding where individuals have come from, where they are, and where they are going. We explore the development of self-concept and identity, connectedness, confidence and empowerment, and social and emotional skills to better understand how learners intersect with people, schools, and communities.',
            url: 'https://www.foundry10.org/resources?cat=2215'
        }
    },
    {
        theme: {
            title: 'Technology & Learning',
            img: 'https://f10-image.s3-us-west-2.amazonaws.com/New+Site/Research+Landing+Page/Research+Constructs/Header+Illustrations/technology+and+learning+small.png',
            alt: 'illustrated figures interact with a computer',
            content: 'Technology can be a powerful tool for learning when it is intentionally implemented with tangible goals and best practices in mind. Our work critically examines the value of technology for learning, whether that is for young children and parents learning together, students learning material in the classroom, or youth and adults engaging with news and stories through digital media.',
            url: 'https://www.foundry10.org/resources?cat=29218'
        }
    }
]

export default ResearchThemesData;
