import React from "react";
// import HighlightsCards from './researchRedesign-HighlightsCards';
import CraftHighlightCards from './researchRedesign-CraftHighlightCards';
import './researchRedesign-Highlights.css'

function ResearchHighlights() {


return (
<div className="researchRedesignHighlights-container">
    <div className="researchRedesignHighlights-content-container">
        <div className="researchRedesignHighlights-header-container">
            <h2>Recent Research News</h2>
        </div>
        <div className="researchRedesignHighlights-cards-container">
            {/* <HighlightsCards /> */}
            <CraftHighlightCards />
        </div>
        <div className="researchRedesignHighlights-footer-container">
            <a href="/stories?cat=689">
                Explore more research news
                <i class="fa-solid fa-arrow-right-long"></i>
            </a>
        </div>
    </div>
</div>
);

}

export default ResearchHighlights;
