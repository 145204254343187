import React, { Component } from 'react';
import './october21-Description.css';

export default class October21PageDescription extends Component {
  render() {
    return(
      <div id="october21Page-description-container">
        <div class="october21Page-description-content-container">
          <p>Read below to discover the latest updates from our research, programs, and philanthropic endeavors. For general media inquiries, please contact foundry10’s Communications Director Curtis Rogers at <a href="mailto:curtis@foundry10.org">curtis@foundry10.org</a>.</p>
        </div>
      </div>
    );
  }
}