import React, { Component } from 'react';
import './october21-NewsletterLink.css';

export default class October21NewsletterLink extends Component {
    render() {

        return (
        <div className="october21Newsletter-link-container">
            <div className="october21Call-to-action">
                <p>Get the latest foundry10 news delivered to your inbox every month!</p>
                <a href="https://foundry10.us3.list-manage.com/subscribe?u=61e3e87d0bc76df778693653e&id=65c221485b" target="_blank" rel="noopener noreferrer">Subscribe to Our Newsletter</a>
            </div>
        </div>          
        );
      }
}