import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import NewFooter from '../../../../pages-other/NewFooter';
import './People.css';

export default class CarleeJackson extends Component {
  render() {
    return(
        <div id="marineCareers-main-container">
          <Helmet>
          <title>foundry10 Guide to Marine Science Careers: Shark Research</title>
          <meta
            name="title"
            content="foundry10 Guide to Marine Science Careers: Shark Research"
          />
          <meta
            name="description"
            content="Explore an exciting marine science career in shark research. Dive into what life is like in this career with interviews and resource links."
          />
          <meta property="og:title" content="foundry10 Guide to Marine Science Careers: Shark Research" />
          <meta
            property="og:description"
            content="Explore an exciting marine science career in shark research. Dive into what life is like in this career with interviews and resource links."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/marine-science-careers/shark-research" />
          <link rel="canonical" href="https://www.foundry10.org/marine-science-careers/shark-research" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Guide to Marine Science Careers: Shark Research",
                "description": "Explore an exciting marine science career in shark research. Dive into what life is like in this career with interviews and resource links.",
                "url": "https://www.foundry10.org/marine-science-careers/shark-research"
              }
            `}
          </script>
        </Helmet>
        {/* MAIN */}
        <div id="marineCareers-main-content-container">
          {/* HEADER */}
          <div id="marineCareers-header-container">
            <div class="marineCareers-header-title-container">
              <h3>Shark Research</h3>
              <h2>Meet Carlee</h2>
              <div class="marineCareers-headerImage-container">
                <div class="marineCareers-headerImage-content-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/People/Carlee+Jackson.jpg" alt="Carlee Jackson"/>
                </div>
              </div>
              <p>Carlee Jackson is a co-founder of Minorities in Shark Science (MISS), an organization dedicated to amplifying the minority voices in the field of shark science. Originally from Detroit, MI, she moved to south Florida and received her B.S. in biology from Florida Atlantic University in 2016. She currently works as a marine turtle specialist, helping to protect sea turtles in Florida. This past summer she received her M.S. in Marine Biology from Nova Southeastern University. Carlee’s master’s research examined how provisioning (feeding) tourism affects the behavior, habituation and abundance of nurse sharks in Belize. She continues to work in the field of shark science, working to increase diversity in this field.</p>
            </div>
          </div>
          {/* BODY */}
          <div id="marineCareers-review-container">
            <div class="marineCareers-review-content-container">
              {/* INTERVIEW */}
              <div class="marineCareers-header-title-container">
                <h2>Interview with Carlee</h2>
              </div>
              <div class="marineCareers-interview">
                <h2>Describe an average day in the life of a marine sea turtle specialist. What do you look forward to the most during your workday?</h2>
                <p>Let's see, an average workday… we mark sea turtle nests on the beach early in the morning. We have to go out before sunrise, so usually I am out on the beach at around 6am. Then depending on how many calls, or how active the sea turtles were on the previous night, sometimes I'm on the beach for two hours, sometimes it's four hours, but we mark all the nests, take the data, and then afterwards, we'll come back to the office, fill in our data and doall of the GIS mapping.</p>
                <p>When I'm done with that, I'll go help in the sea turtle hospital and go out to the aquarium staff if they need any assistance with things, especially nowadays, since we've cut down on on a lot of staff and our nature center mostly works off of volunteers, but we can't have any volunteers at the moment, so we're making up for that.</p>
                <p>Usually my workday is over around like one or two o'clock.. Recently I’ve finished my graduate studies, so right now I'm just finalizing my thesis. I started an organization called MISS (Minorities in Shark Sciences), so we do a lot of meetings and collaborations with people. That's what we're working on right now, just trying to keep our fundraising up. It's a lot in a day.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What are some common misconceptions about your job/field?</h2>
                <p>I think a big thing that people don't understand is that with both my graduate studies, which was focusing on sharks, and with being a marine sea turtle specialist, there's a lot of writing and behind the scenes stuff done. People are like, “oh, you just go play with turtles, or play with sharks all day,” and that's actually a very small amount of what a scientist really does. Fieldwork is a big part of it, but the even bigger and more tedious part is all the writing involved in it, and also trying to get funding as well. Just entering data and running statistical analysis, which is my least favorite thing to do. I think that's definitely a big misconception that people don't realize, you know, there's a lot a lot of behind the scenes work.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What do people find the most surprising about your work?</h2>
                <p>I feel like people are most surprised at how big certain animals are. Turtles you think of like the little fresh water turtles. However, sea turtles in general get really big, like the smallest species can get up to 300 pounds, and the largest species up to like 2000 pounds. People are very surprised when they see me, in pictures with sea turtles. They're like, “oh my gosh, that thing is so big,” or when people see pictures of me with sharks. Also, the fact that, you know, I'm in the water with sharks, and I'm not like getting eaten surprises some people.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>Describe your education, training and or experience that prepared you for this career.</h2>
                <p>I went to Florida Atlantic University and I received my bachelor's degree in biology. Through that, I didn't really get much field work experience in the ocean until my senior year. I was an athlete as well, so most of my time was taken up by training. I did this program called Semester by the Sea, where all of my courses were on the water. For my fish class, we would go out and identify fish and stuff like that. So, it was definitely a big part of my decision to stay in marine science because I just had the time of my life that entire semester. After that I actually took a year off in between my undergrad and my graduate studies to kind of figure out where I wanted to go to school and figure out what I want to focus on. I ended up going to Nova Southeastern University, also here in South Florida, and there is when I actually started my shark research endeavors. I started helping with shark tagging and that is my favorite thing to do. My thesis focus was the effects of feeding tourism on sharks in Belize, specifically, nurse sharks, so that's kind of what has led me up to where I am now. I also did a program on a tall ship actually, in the Great Lakes, it was very random, but I was in the Great Lakes, and it was field work, and I learned how to do a lot of different things like microbiology techniques, and testing water, and things like that. Even though it wasn't marine science related, it is still field work related, and just learning because it's all science at the end of the day, and really a bunch of different science field techniques.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What are some accomplishments that you are proud of that you tend to highlight on a resume or a cover letter?</h2>
                <p>I just defended my master's thesis, recently, so that is definitely in bold on my resume. I am officially mastering science, but also one is the organization that I just started, MISS - Minorities in Shark Science, that is a big accomplishment, which is something that I've actually really been thinking about for the past like two years or so, trying to get more women of color into the field of shark science and finally was actually able to do something, and create something, that became very large overnight basically. Those are some accomplishments that I am currently very proud of.</p>
              </div>
              {/* LEARNING MORE */}
              <div id="marineCareersLearning-container">
                <div class="marineCareersLearning-content-container">
                  <h2>Interested in learning more about Shark Science?</h2>
                  <ul class="marineCareersLearning-list">
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p>Do you want to read more about Carlee's responses? <a href="https://drive.google.com/file/d/1RG2G9M4ezySaJnnPTxSmAwP8vMGTzkfQ/view" target="_blank" rel="noopener noreferrer">Here's the link to the full interview</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.sharktrust.org/career-advice" target="_blank" rel="noopener noreferrer">Explore this website that has more career tips and advice.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.barnesandnoble.com/w/shark-lady-jess-keating/1125556937?ean=9781492642046" target="_blank" rel="noopener noreferrer">Learn about the history of a shark scientist from this children's book.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://shark-research.com/blog/marine-career-development-and-opportunities/top-10-university-labs-to-study-sharks/" target="_blank" rel="noopener noreferrer">Find out information about places that you can study shark science and degree requirements.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.atlanticwhiteshark.org/career-exploration" target="_blank" rel="noopener noreferrer">Listen to more interviews with shark science professionals and also links to some worksheets.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.misselasmo.org/" target="_blank" rel="noopener noreferrer">If you identify as a minority interested in shark science, here are opportunities for you.</a></p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <a class="marineCareers-backButton" href={'/marine-science-careers'}>
                    <p>Back to the careers!</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <NewFooter />
        </div>
      </div>
    );
  }
}