import React, { Component } from 'react';
import StemGoBabyGoVideo from './stemGoBabyGo-Video';
import BarChartWhy from './Charts/barChartWhy';
import BarChartTopic from './Charts/barChartTopic';
import BarChartExcite from './Charts/barChartExcite';
import './stemGoBabyGo-Body.css';

export default class StemGoBabyGoBody extends Component {
  render() {
    return(
      <div id="stemGoBabyGo-container">
        <div class="stemGoBabyGo-content-container">
          <div class="stemGoBabyGo-paragraph">
            <p>Melissa Wrenchey teaches “Introduction to Engineering” at Tesla STEM High School in Redmond, Washington. In the Spring of 2021, her students were still learning remotely due to the COVID-19 pandemic, yet Wrenchey saw an opportunity for engaging them in hands-on, small group engineering projects by using a remote learning curriculum built on the <a href="https://www.yourcpf.org/cpproduct/go-baby-go-the-ultimate-toy-hack/" target="_blank" rel="noopener noreferrer">Go Baby Go</a> program.</p>
            <p>The Go Baby Go program helps young children who are living with disabilities play, socialize, and gain greater mobility by providing them with modified ride-on cars. Founded by the University of Delaware’s Professor Cole Galloway as part of a research project, Go Baby Go has been adapted and implemented by individuals with diverse backgrounds <a href="https://sites.udel.edu/gobabygo/contact/" target="_blank" rel="noopener noreferrer">across the country and beyond</a>.</p>
            <p>Foundry10 supported Wrenchey in implementing her Go Baby Go curriculum idea for Tesla High School students by funding the purchase of vehicles and materials for the students’ adaptive engineering designs. In addition, our <a href="https://www.foundry10.org/programs/trades" target="_blank" rel="noopener noreferrer">Trades Team</a> provided thought partnership to Wrenchey in considering how to adapt Go Baby Go to a curriculum for her students.</p>
            <p>In total, 32 students participated in the program at Tesla High School in Spring 2021. Foundry10 surveyed 19 of these students following the completion of their projects, asking them about their motivations for choosing the topic, how they felt their projects went, and what they gained from working on it.</p>
            <p>Below are key takeaways from those surveys, and tips from Wrenchey for other educators interested in engaging their students with a Go Baby Go curriculum.</p>
          </div>
          <div class="interestArea-domain-title">
            <h2>Students were not only up for the challenge&mdash;they were motivated by it.</h2>
          </div>
          <br/>
          <br/>
          <div className="go-baby-go-chart-container">
            <BarChartWhy />
          </div>
          <br/>
          <div class="stemGoBabyGo-paragraph">
            <p>When asked why they chose to work on Go Baby Go, students most frequently expressed that they were intrigued by the challenge it presented. In the words of one survey respondent, “I think that this is a good opportunity to see how students work under pressure and when their actions have a real impact on the world.”</p>
            <p>Common challenges that students had to overcome throughout the project included problems with materials and physically building the vehicle enhancements, such as one team who had to figure out a way that their vehicle could be turned off using Bluetooth technology. Many students also reported difficulties with project planning and coordination across their teams, including communication and scheduling obstacles.</p>
            <p>In the end, facing and overcoming these challenges proved to be valuable for the students. All respondents to foundry10’s survey indicated that they learned useful technical skills through the Go Baby Go curriculum. In addition to improving their engineering knowledge and abilities, students also felt that completing their projects helped them build skills in:</p>
            <p>
              <ul>
                <li>Solving real-world problems (50%)</li>
                <li>Collaboration (43%)</li>
                <li>Communication (21%)</li>
                <li>Working with a client (14%)</li>
                <li>Helping others (14%)</li>
              </ul>
            </p>
          </div>
          <div class="interestArea-domain-title">
            <h2>Students were particularly excited by the opportunity to help a community in need.</h2>
          </div>
          <br/>
          <br/>
          <div className="go-baby-go-chart-container">
            <BarChartTopic />
          </div>
          <br/>
          <div class="stemGoBabyGo-paragraph">
            <p>“I have been passionate about this project from the second I heard about it,” said one student. “I love helping my community and underrepresented students&#8212;such as women and those with disabilities. I have heard and read a lot about the barriers and challenges faced by these underrepresented students, and especially those with disabilities, and I wanted to help out.”</p>
            <p>Many other students who participated in the project shared that interest in directly promoting social good, including 79% of foundry10’s survey respondents. That finding aligns with several other comments from student participants, including:</p>
            <p>
              <ul>
                <li>“I got to work with others and helped build a car. I also got the chance to help people, which I was excited about.”</li>
                <li>“It was exciting to know our work would help someone.”</li>
                <li>“I felt as if this could be an exciting project that would help a child.”</li>
                <li>“I think the fact that this project would have actual impacts on a kid made it that much more exciting.”</li>
              </ul>
            </p>
            <p>Similarly, the majority (57%) of students expressed that&#8212;once the project was underway&#8212;the chance to help people in need was one of the top reasons they found the experience to be exciting. All students surveyed after the project indicated that Go Baby Go benefits others, and that they found it to be just as exciting (if not more exciting) than other remote learning projects they’ve participated in.</p>
          </div>
          <div class="interestArea-domain-title">
            <h2>Students highly valued the chance to collaborate with peers.</h2>
          </div>
          <br/>
          <br/>
          <div className="go-baby-go-chart-container">
            <BarChartExcite />
          </div>
          <br/>
          <div class="stemGoBabyGo-paragraph">
            <p>In addition to advancing social good, over half of the students surveyed said that Go Baby Go was exciting because it allowed them to work with other students, which was especially important due to the isolation of remote learning. Foundry10’s survey collected data from five student groups who participated in the program, and Tesla STEM High School engaged a number of total student groups in the project throughout the school year.</p>
            <p>Students took on a wide range of roles in these groups, including serving as a:</p>
            <p>
              <ul>
                <li>Design Lead</li>
                <li>Junior Designer</li>
                <li>Project Manager</li>
                <li>Outreach and User Experience Lead</li>
                <li>Technology Lead</li>
                <li>Junior Tech</li>
              </ul>
            </p>
            <p>While teamwork issues were frequently cited as challenges faced by students, 43% felt that they gained strong collaboration skills through the project, representing the second-largest area of skill growth (surpassed only by growth in students’ ability to address real-world problems).</p>
            <p>Similarly, when asked about how other students could benefit from a similar program, many students focused on the value of learning to build collaborative skills and relationships with peers and clients. For instance, students expressed their belief that other students could benefit from this program by learning to:</p>
            <p>
              <ul>
                <li>Collaborate ... [and] put the client first.</li>
                <li>Work with a client and understand the criteria for a project.</li>
                <li>Work well together in a group.</li>
                <li>[Solve] a real world problem ... using collaboration.</li>
              </ul>
            </p>
          </div>
          <div class="interestArea-domain-title">
            <h2>Tips for Teachers</h2>
          </div>
          <div class="stemGoBabyGo-paragraph">
            <p>“I think this project was just what we needed to connect our students with the community,” said Melissa Wrenchey. “It is a step in the right direction in connecting our students with authentic problems to solve and ongoing work in assisting with mobility issues for others. Students and their families worked together and expanded the community.”</p>
            <p>Wrenchey offers the following tips for other educators interested in replicating her success with Go Baby Go:</p>
            <p>
              <ul>
                <li>Small groups work well for Go Baby Go.</li>
                <li>Having parents who are STEM professionals host the build sessions is really a great way for parents to be involved and for families to connect to each other.</li>
                <li>It was fantastic to have the support of our district Physical Therapists. It was awesome to connect to other departments in our large district to meet the needs of students with mobility issues.</li>
              </ul>
            </p>
            <p>Going forward, Wrenchey plans to continue engaging her students with Go Baby Go. Students who rose to leadership positions as Project Manager during the previous round of the program will continue to serve in that role for the next group of 9th graders.</p>
            <p>To hear more from Wrenchey and her students, check out the below video highlight:</p>
            <StemGoBabyGoVideo />
          </div>
          <div class="stemGoBabyGo-survey-paragraph">
            <div class="stemGoBabyGo-survey-title">
              <h2>Who Took the Survey?</h2>
            </div>
            <p>Nineteen participants completed the survey. Of those nineteen participants, fourteen students completed the demographics questions and their information is included here.</p>
            <p>All participants who chose to share their demographics were in the 9th grade. Seven participants identified as men, six of the participants identified as women, and one participant identified as genderfluid.</p>
            <p>The racial/ethnic identities of participants included the following: Asian American or Asian (n=10, 71.43%); European American or White (n=2; 14.29%); Native American or American Indian (n=1, 7.14%); and Multiracial  (n=1, 7.14%).</p>
            <p>Students who completed the survey were from a variety of Go Baby Go project groups: Group A (n=3); Group B (n=5); Group C (n=3); Group D (n=1); Group E (n=1); and one participant who did not specify which group they were in (n=1).</p>
          </div>
        </div>
      </div>
    );
  }
}