import React, { Component } from 'react';
import './annualReport2021Covid-Header.css';

export default class AnnualReportCovidHeader extends Component {
  render() {
    return(
      <div id="annualReport2021covid-header-container">
        <div className="annualReport2021covid-header-content">
          <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Covid+Landing+Page/Covid+Header-8.png" alt="foundry10 annual report 2020-2021 covid-19 response header" />
        </div>
      </div>
    );
  }
}