import React, { Component } from 'react';
import BandLabDescription from './bandLab-Description';
import BandLabLinks from './bandLab-Links';
import NewFooter from '../../../../pages-other/NewFooter';
import './bandLab-Content.css';

export default class BandLabContent extends Component {
  render() {
    return(
      <div id="bandLab-content-container">
        <BandLabDescription />
        <BandLabLinks />
        <NewFooter />
      </div>
    );
  }
}