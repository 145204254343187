import React, { Component } from 'react';
import AutoResearchDescription from './autoResearch-Description';
import AutoResearchWIA from './autoResearch-WIA';
import AutoResearchSlides from './autoResearch-Slides';
import AutoResearchCircuit from './autoResearch-Circuit';
// import VrLandingPageLinks from './vrLandingPage-Links';
import NewFooter from '../../../pages-other/NewFooter';
import './autoResearch-Content.css';

export default class VrLandingPageContent extends Component {
  render() {
    return(
      <div id="autoResearch-content-container">
        <AutoResearchDescription />
        <AutoResearchWIA />
        <AutoResearchSlides />
        <AutoResearchCircuit />
        {/* <VrLandingPageLinks /> */}
        <NewFooter />
      </div>
    );
  }
}