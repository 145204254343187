import React from "react";
import './Marine-Geologist.css';

export default function MarineGeologist({ title, subtitle, dark, id }) {
  return (
    // <div className={"section" + (dark ? " section-dark" : "")}>
    <div className="marineGeologist-container">
      <div className="marineGeologist-content-container" id={id}>
        <div className="marineGeologist-final-content-container">
          <div className="marineGeologist-illustration">
            <h1>Click below to learn more!</h1>
            <a class="marineGeologist-link" href="/marine-science-careers/marine-geology">
              <img src='https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/marinegeology1.png' alt='marine geologist illustration'/>
              <p class="marineGeologist-title">Meet Gary!</p>
              <div class="marineGeologist-cards-overlay"></div>
            </a>
          </div>
          <div className="marineGeologist-description">
              <h2>"Rock Detective of the Sea"</h2>
              <p>RELATED TITLES: Marine Geophysicist, Ocean Mapper</p>
              <p>Most of us think of water when we think of researching the ocean but there is an entire world of rocks waiting to be discovered, mapped, and studied. A challenge of investigating these deep, hard-to-study rocks is getting down to the seafloor and having the right set of tools to get samples back to the surface.</p>
            <div className="marineGeologist-requirements">
              <h2>Basic Degree/Training Requirements</h2>
              <ul className="marineGeologist-iconList">
                <li>
                  <div class="marineGeologist-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/white-HS-Diploma.png" alt="high school diploma" />
                  </div>
                </li>
                <li>
                  <div class="marineGeologist-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/white-Undergrad-Cap.png" alt="undergrad degree" />
                  </div>
                </li>
                <li>
                  <div class="marineGeologist-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/white-Grad-Degree.png" alt="graduate degree" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}