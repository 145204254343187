import React from 'react';
import Grid from '@material-ui/core/Grid';

const getAuthor = (featuredstory) => {
  //Get Author - first External, then Internal, final Craft Entry author
  let author = featuredstory.author.fullName;
  if (featuredstory.researchExternalAuthors && featuredstory.researchExternalAuthors.length) {
    author = featuredstory.researchExternalAuthors[0].researchExternalAuthorsAuthor
  } else if (
    featuredstory.interestAreaTeam &&
    featuredstory.interestAreaTeam.length &&
    featuredstory.interestAreaTeam[0] &&
    featuredstory.interestAreaTeam[0].interestAreaTeamMember &&
    featuredstory.interestAreaTeam[0].interestAreaTeamMember.length
  ) {
    author = featuredstory.interestAreaTeam[0].interestAreaTeamMember[0].title
  }
  return author;
}

const FeaturedStory = ({ block }) => {
  if (!block.featuredstory || block.featuredstory.length === 0) {
    return null;
  }
  // let category = null;
  // // Set Category Label 
  // if (block.featuredstory[0].resourceType) {
  //   category = block.featuredstory[0].resourceType[0].title
  // } else if (block.featuredstory[0].categories) {
  //   category = block.featuredstory[0].categories[0].title
  // }
  const cur_url = window.location.href;
  const prefix_url = cur_url.split("/")[0];
  return (
    <section className='featuredStory'>
      <div className='container'>
        <h3 className='text-xxl_bold text-left emphHeader'><span>Featured Story</span></h3>
        {block.featuredstory.map((featuredstory, index) => (
          <div className="content">
            <Grid container spacing={40} key={index}>
              <Grid item md={6} >
                <img
                  className='featuredImage'
                  src={featuredstory.featuredImage[0] ? featuredstory.featuredImage[0].url : '/news-archive-spacer.png'}
                  alt={featuredstory.title}
                />
              </Grid>
              <Grid className='featuredStoryContent' item md={5}>
                {/* <h2 className='text-medium_bold'>{featuredstory.title}</h2> */}
                <a
                  className='text-medium_bold featuredStoryTitle'
                  href={prefix_url + "/stories/" + featuredstory.slug}
                >
                  {featuredstory.title}
                </a>
                <div 
                  dangerouslySetInnerHTML={{
                  __html: featuredstory.blurb,
                  }}
                ></div>
                {/* <p className="storyDate text-body">{getAuthor(featuredstory)} | {featuredstory.dateCreated}</p> */}
                <p className="storyDate text-body">{featuredstory.postDate}</p>
              </Grid>
            </Grid>
          </div>
        ))}
      </div>
    </section>
  )
}

export default FeaturedStory