import React, { Component } from 'react';
import './october21-Header.css';

export default class October21Header extends Component {
  render() {
    return(
      <div id="october21-header-container">
          <div class="october21-header-content-container">
            <h1>What's New at foundry10</h1>
          </div>
      </div>
    );
  }
}