import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import './AnnualReport-Links.css';

export default class AnnualReportLinks extends Component {

  render() {
    return (
        <div id="annualReportLinkslanding-main-container">
          {/* BODY */}
          <div id="annualReportLinkslanding-main-content-container">
            <div class="annualReportLinks-titleLine"></div>
            <h1>LEARN MORE</h1>
            {/* NAV LINKS */}
            <div id="annualReportLinksList-main-container">
              <ul class="annualReportLinksList-unorderedList">
                <li>
                  <Button style={{ fontFamily: 'Helvetica Neue Light', fontWeight: 'bold', color: '#ffffff', textTransform: 'capitalize', boxShadow: '1px 1px #b1b1b1', width: '150px', borderRadius: '12px', background: 'radial-gradient(#4a7880, #00464f)' }} href="/annual-report/2021">HOME</Button>
                </li>
                <li>
                  <Button style={{ fontFamily: 'Helvetica Neue Light', fontWeight: 'bold', color: '#ffffff', textTransform: 'capitalize', boxShadow: '1px 1px #b1b1b1', width: '150px', borderRadius: '12px', background: 'radial-gradient(#4a7880, #00464f)' }} href="/annual-report/2021/covid-19-response">COVID RESPONSE</Button>
                </li>
                <li>
                  <Button style={{ fontFamily: 'Helvetica Neue Light', fontWeight: 'bold', color: '#ffffff', textTransform: 'capitalize', boxShadow: '1px 1px #b1b1b1', width: '150px', borderRadius: '12px', background: 'radial-gradient(#4a7880, #00464f)' }} href="/annual-report/2021/research">RESEARCH</Button>
                </li>
                <li>
                  <Button style={{ fontFamily: 'Helvetica Neue Light', fontWeight: 'bold', color: '#ffffff', textTransform: 'capitalize', boxShadow: '1px 1px #b1b1b1', width: '150px', borderRadius: '12px', background: 'radial-gradient(#4a7880, #00464f)' }} href="/annual-report/2021/programs">PROGRAMS</Button>
                </li>
                <li>
                  <Button style={{ fontFamily: 'Helvetica Neue Light', fontWeight: 'bold', color: '#ffffff', textTransform: 'capitalize', boxShadow: '1px 1px #b1b1b1', width: '150px', borderRadius: '12px', background: 'radial-gradient(#4a7880, #00464f)' }} href="/annual-report/2021/philanthropy">PHILANTHROPY</Button>
                </li>
                <li>
                  <Button style={{ fontFamily: 'Helvetica Neue Light', fontWeight: 'bold', color: '#ffffff', textTransform: 'capitalize', boxShadow: '1px 1px #b1b1b1', width: '150px', borderRadius: '12px', background: 'radial-gradient(#4a7880, #00464f)' }} href="/annual-report/2021/sharing">SHARING</Button>
                </li>
              </ul>
            </div>
          </div>
        </div>
    );
  }
}