import React, { useState } from 'react';
import './ResearchTopics-Two.css';

function ResearchTopicsTwo() {
    const [isActive, setIsActive] = useState(false);
    // const [hasMore, setHasMore] = useState(false);

    return (


            <div className="researchTopicsTwoAccordion-item">
                <div className="researchTopicsTwoAccordion-title" onClick={() => setIsActive(!isActive)}>
                    <div className="researchTopicsTwoAccordion-image">
                        <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Creativity%2C+Design%2C+and+Play/Research+Topics/IA+Team+Page+Icons_Games+and+Learning+1.png" alt="two children playing a board game"/>
                    </div>
                    <div className="researchTopicsTwoAccordion-text">
                        <h2 className="researchTopicsTwoAccordion-title-text">Games and Learning</h2>
                        {/* <h3>{research.subtitle}</h3> */}
                    </div>
                    <i className={ isActive ?  `fa-solid fa-angle-up`:`fa-solid fa-angle-down`}></i>                  
                </div>
                {isActive && <div className="researchTopicsTwoAccordion-content">
                    <div className="researchTopicsTwoAccordion-description">
                        <p>Our games research is geared towards exploring how games can be used to enhance learning and wellness through experiential exploration, design, classroom implementation, and play. This broad approach has allowed us to investigate what makes games useful and engaging tools for teachers and learners alike. From mental model exploration to design, group dynamics, and teamwork, we want to learn more about how games can enhance learning and well-being across age groups and demographics.</p>
                    </div>  
                    <div className="researchTopicsTwoAccordion-descriptionTwo">
                        <h2>This area of research explores questions such as:</h2>
                        <div className="researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How can we look beyond engagement to better understand the measurable impact games have on youth learning?</p>
                        </div>
                        <div className="researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How can tabletop and video games promote social and emotional well-being for youth?</p>
                        </div>
                        <div className="researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How can game-based learning foster collaboration in the classroom and support meaningful connections to curricula and content?</p>
                        </div>
                    </div>
                    {/* <div className="researchTopicsTwoAccordion-descriptionThree">
                        <h2>Curriculum + Education</h2>
                        <div className="researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>What are important considerations for school-based civic learning opportunities that support meaningful youth engagement online?</p>
                        </div>
                        <div className="researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How should we approach curriculum focused on digital citizenship more effectively to support the skills youth need to thrive in today’s society?</p>
                        </div>
                        <div className="researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>To what extent are young people’s perspectives included in the development & implementation of curriculum about being a digital citizen?</p>
                        </div>
                    </div> */}
                </div>}
            </div>

    );
}

export default ResearchTopicsTwo;
