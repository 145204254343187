import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import CareersHeader from './Careers-Header';
import CareersContent from './Careers-Content';
import './Careers.css';

export default class Careers extends Component {
  render() {
    return(
      <div id="newCareers-container">
        <Helmet>
          <title>foundry10 Careers: Join Our Team!</title>
          <meta
            name="title"
            content="foundry10 Careers: Join Our Team!"
          />
          <meta
            name="description"
            content="Learn more about current openings, staff benefits, and foundry10's culture that flexibly and collaboratively explores learning and creates value for youth."
          />
          <meta property="og:title" content="foundry10 Careers: Join Our Team!" />
          <meta
            property="og:description"
            content="Learn more about current openings, staff benefits, and foundry10's culture that flexibly and collaboratively explores learning and creates value for youth."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/careers" />
          <link rel="canonical" href="https://www.foundry10.org/careers" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "AboutPage",
                "name": "foundry10 Careers: Join Our Team!",
                "description": "Learn more about current openings, staff benefits, and foundry10's culture that flexibly and collaboratively explores learning and creates value for youth.",
                "url": "https://www.foundry10.org/careers"
              }
            `}
          </script>
        </Helmet>
        <CareersHeader />
        <CareersContent />
      </div>
    );
  }
}