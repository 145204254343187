import React, { Component } from 'react';
import MarineBycatch22Intro from './marineBycatch22-Intro';
import MarineBycatch22Body from './marineBycatch22-Body';
import NewFooter from '../../../pages-other/NewFooter';
import './marineBycatch22-Content.css';

export default class MarineBycatch22Content extends Component {
  render() {
    return(
      <div id="marineBycatch22-content-container">
        <MarineBycatch22Intro />
        <MarineBycatch22Body />
        <NewFooter />
      </div>
    );
  }
}