import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

const Emphasized = ({ block }) => {
  const heading = block.emphasizedTitle;
  const overline = block.emphasizedOverline;
  const subheadline = block.emphasizedSubheadline;
  const image = block.emphasizedImage[0]
  const imageCaption = block.emphasizedImageCaption;
  const content = block.emphasizedContent;
  const link = block.emphasizedLink;
  const bgColor = block.emphasizedBackgroundColor
  const classes = `contentBlock emphasized ${bgColor}`;
  //console.log(block);
return (
  <section className={classes}>
    <div className="container">
      <div className="content">
        {overline && (
          <h5 className="text-half-step_bold">
            {overline}
          </h5>
        )} 
        {heading && (
          <h2 className="text-xl_bold">
            {heading}
          </h2>
        )} 
        <a href={link}>
        {subheadline && (
          <h4  className="text-half-step">
            {subheadline}
          </h4>
        )}    
        {image && (
          <img src={image.url} alt="" />
        )}
        </a>
        {imageCaption && (
          <p className="imageCaption">{imageCaption}</p>
        )}
        {content && (
          <div className="textBlock"
            dangerouslySetInnerHTML={{
            __html: content,
            }}
          ></div>
        )}
      </div>            
    </div>
  </section>
  )
}

export default Emphasized