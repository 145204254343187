import React, { Component } from 'react';
import './smart-Abstracts.css';

export default class SmartAbstracts extends Component {

  render() {
  const smartAbstracts = [
    {
      "title": `Exploring barriers to parent-teacher relationships in preschool: Interviews with Parents and Teachers`,
      "year": `2023`,
      "journalName": `UW Promoting Equity in Early Childhood Education (PEECE) Lab`,
      "journalType": `Research Brief`,
      "allPeople": [
          `Sehee Jung`
      ],
      "link": `https://drive.google.com/file/d/14PMGLvPm3gUGT5ySJfHBAzHQKEy94vxm/view?usp=share_link`,
      "abstract": `Building strong relationships between parents and teachers is critical to supporting young children in developing key social, emotional, and pre-academic skills. However, little is known about how to support parents and teachers in forming quality relationships. This study sought to elevate the voices of parents and preschool educators to better understand how to support them in forming quality relationships.`
    },
    {
      "title": `Forestalling Preschool Expulsion: A Mixed-Method Exploration of the Potential Protective Role of Teachers’ Perceptions of Parents`,
      "year": `2019`,
      "journalName": `American Educational Research Journal`,
      "journalType": `Full Article`,
      "allPeople": [
          `Courtney A. Zulauf-McCurdy`,
          `Katherine M. Zinsser`
      ],
      "link": `https://journals.sagepub.com/doi/abs/10.3102/0002831219838236`,
      "abstract": `Preschoolers are being expelled at an alarming rate and little is known about protective factors. One factor may be the relationship between parents and teachers. Using surveys and interviews, the present study explores teachers’ perceptions of parents and center support as they relate to teachers’ requests to have a child removed from their classroom, an action related to expulsion. Teachers who have more negative perceptions of parents and perceive less center support working with parents were more likely to have requested a removal of a child in the past year. Qualitative comparisons yield differential themes around attribution of child behavior to parents and styles of engagement with parents between teachers who have requested a removal and those who have not.`
    },
    {
        "title": `How teachers' perceptions of the parent–teacher relationship affect children's risk for early childhood expulsion`,
        "year": `2020`,
        "journalName": `Psychology in the Schools`,
        "journalType": `Full Article`,
        "allPeople": [
            `Courtney A. Zulauf-McCurdy`,
            `Katherine M. Zinsser`
        ],
        "link": `https://onlinelibrary.wiley.com/doi/abs/10.1002/pits.22440`,
        "abstract": `Rates of expulsion from early care and education settings (e.g., childcare and preschool programs) exceed those in K-12, and relatively little is known of how to prevent such disciplinary decisions. In addition, expulsion disproportionately affects children of color, especially boys. The present study explores a potential protective strategy existent in all early care and education settings, the parent–teacher relationship. Surveys with early childhood teachers (N = 295) outline the association between teachers' perceptions of the parent–teacher relationship and a child's risk for expulsion. Findings indicate that teachers' perceptions of high-quality parent–teacher relationships are related to a lower risk for expulsion for children who have not been expelled, with the strongest association found for Black children. In contrast, for children identified as previously expelled, we found no association between teachers' perceptions of the parent–teacher relationship and future expulsion risk. Each finding will be described in detail with an eye towards implications and intervention.`
    },
    {
        "title": `A Qualitative Examination of the Parent–Teacher Relationship and Early Childhood Expulsion: Capturing the Voices of Parents and Teachers`,
        "year": `2022`,
        "journalName": `Infants & Young Children`,
        "journalType": `Full Article`,
        "allPeople": [
            `Courtney A. Zulauf-McCurdy`,
            `Katherine M. Zinsser`
        ],
        "link": `https://journals.lww.com/iycjournal/Abstract/2022/01000/A_Qualitative_Examination_of_the_Parent_Teacher.3.aspx`,
        "abstract": `Children in the United States are being expelled from their early care and education settings at alarming rates. A growing body of research indicates that teachers' perceptions of parents may impact their decision to request a child's removal from their classroom. Nevertheless, few studies have simultaneously captured the voices of both parents and teachers when describing the process of expulsion and how the parent–teacher relationship can protect a child from being expelled. To identify and describe protective qualities of the parent–teacher relationship, we conducted in-depth qualitative interviews with 8 parents and 8 early childhood teachers currently working with a child who was previously expelled. The parents and teachers interviewed consistently attributed the successful retention of the child in their current program following the expulsion to the transparent and open communication between home and school. Our findings indicate that one particularly important area for intervention is promoting high-quality parent–teacher relationships for all children, with special attention to how these relationships may differ for children who have previously been expelled.`
    }
];

  const smartAbstractsCards = smartAbstracts.map((records, index) => {

        return (
          <li key={index}>
              <div className="smartAbstractsList-card-container">
                <div className="smartAbstractsList-card-content-container">
                  <div className="smartAbstractsList-card-text-container">
                    <div className="smartAbstractsList-card-mainText-art">
                            <h2>{records.title}</h2>
                        <div className="smartAbstractsList-card-abstract-text">
                            <h3>Abstract:</h3>
                            <p>{records.abstract}</p>
                        </div>
                        <div className="smartAbstractsList-card-author-names">
                        {records.allPeople.map((people, index) => <p key={index}>{people}</p>)}
                        </div>
                        <div className="smartAbstractsList-card-year-journal">
                            <p>({records.year})  Published by {records.journalName}</p>
                        </div>
                        <a className="smartAbstracts-button" href={records.link} rel="noopener noreferrer" target="_blank">
                            <div>Read {records.journalType}</div>
                        </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          )});
  
    return(
        <div id="smartAbstracts-main-container">
            <div className="smartAbstracts-content-title">
                <h2>
                Further Reading
                </h2>
            </div>
        {/* ABSTRACT CARDS */}
            <div id="smartAbstracts-interestArea-container">
              <div className="smartAbstracts-interestArea-content-container">
                <ul>
                    {smartAbstractsCards}
                </ul>
              </div>
            </div>
      </div>

);
  }
}