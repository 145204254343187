import React from "react";
import './Art-Design.css';

export default function ArtDesign({ title, subtitle, dark, id }) {
  return (
    // <div className={"section" + (dark ? " section-dark" : "")}>
    <div className="artDesign-container">
      <div className="artDesign-content-container" id={id}>
        <div className="artDesign-final-content-container">
          <div className="artDesign-illustration">
            <h1>Click below to learn more!</h1>
            <a class="artDesign-link" href="/marine-science-careers/art-and-design">
              <img src='https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/art1.png' alt='art design illustration'/>
              <p class="artDesign-title">Meet Abrian, Jaclyn & Yasmin!</p>
              <div class="artDesign-cards-overlay"></div>
            </a>
          </div>
          <div className="artDesign-description">
              <h2>"Ocean Art-repreneur"</h2>
              <p>RELATED TITLES: Artist, Illustrator, Designer, "Art-ivist"</p>
              <p>If a picture is worth a thousand words, a career in ocean-related art can take a thousand different forms. From cartooning to technical drawing, microscopic sketches to three-story murals, the field is as expansive as the ocean itself and is only limited by the imagination of the artist.</p>
            <div className="artDesign-requirements">
              <h2>Basic Degree/Training Requirements</h2>
              <ul className="artDesign-iconList">
                <li>
                  <div class="artDesign-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/black-HS-Diploma.png" alt="high school diploma" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}