import React, { Component } from 'react';
import './insight-Header.css';

export default class InsightHeader extends Component {
  render() {
    return(
      <div id="insight-header-container">
          <div class="insight-header-content-container">
            <h1>INSIGHT</h1>
          </div>
      </div>
    );
  }
}