import React, { Component } from 'react';
import './annualReport2021Dance-Spotlights.css';

export default class AnnualReportDanceSpotlights extends Component {

  render() {
    return(
      <div id="annualReport2021Dance-spotlights-container">
        <div className="annualReport2021Dance-spotlight-first-title-bar">
          <p>Spotlight: Media Kits for Local Dance Teachers</p>
        </div>
        <div className="annualReport2021Dance-spotlights-content-container">
          <div className="annualReport2021Dance-spotlights-text-container">
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Subpages/instagram-f10mediakits.png"  alt="Dance Instructor Media Support Kits"/>
            <p>
            We saw an immediate need this past year to support dance teachers during the pandemic. There was a lot of uncertainty and instability as dance studios and spaces had to fluctuate between closures, remote classes and offering limited attendance, which had a massive negative impact on the studios, teachers, and their students. In the summer of 2020, we gave 30 media kits to local dance teachers and studios to enable them to teach online and hybrid classes within the community. Kits consisted of cameras, microphones, tripods and media cards, and were customized to fit the specific needs of each individual instructor. The ability for dancers to continue to learn from and connect with others in the dance community virtually has been an absolute lifeline during these trying times, and we were honored to contribute to that effort.
            <br /> <br />
            <a href="https://www.instagram.com/p/B_2vtpsBppY/?utm_source=ig_web_copy_link" target="_blank" rel="noopener noreferrer">Instagram Post</a>
            </p>
          </div>
        </div>
        <div className="annualReport2021Dance-spotlight-second-title-bar">
          <p>Spotlight: Beyond Boundaries Virtual</p>
        </div>
        <div className="annualReport2021Dance-spotlights-content-container">
          <div className="annualReport2021Dance-spotlights-text-container">
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Sharing+Landing+Page/beyondboundaries.jpeg"  alt="beyond boundaries"/>
            <p>
            Our Beyond Boundaries Virtual event, also known as BB6, marked the first time in history that foundry10 has hosted an online dance show. While our annual event typically features high school dance team performances, which are choreographed by the students and judged by industry professionals, along with high quality workshops the next day, we needed to rethink our approach to fit the new virtual format. This year, we featured solo student performances that each participant captured on their own, which were complemented by narration and commentary from the dancers about their pieces. The foundry10 team compiled and streamed the footage in a virtual showcase. Virtual Beyond Boundaries became an opportunity for the community to share and celebrate dance, along with finding a way to reconnect with our peers through the internet.
            <br /> <br />
            <a href="https://www.instagram.com/tv/COlRiUEDjdw/?utm_medium=copy_link" target="_blank" rel="noopener noreferrer">Beyond Boundaries Video</a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}