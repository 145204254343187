import React, { useState } from 'react';
import './AreasOfResearch-EarlyNumeracyAbstractConceptsAccordion.css';

function AreasOfResearchEarlyNumeracyAbstractConceptsAccordion() {
    const [isActive, setIsActive] = useState(false);
    // const [hasMore, setHasMore] = useState(false);

    return (


            <div className="areasOfResearchAccordion-item">
                <div className="areasOfResearchAccordion-title" onClick={() => setIsActive(!isActive)}>
                    <div className="areasOfResearchAccordion-image">
                        <img src="https://f10-research.s3.us-west-2.amazonaws.com/Conceptual+Development+Lab/IA_Team_Page_Icons_2024.02.27_Early+numeracy+and+foundational+math+skills+2.png" alt="two children stacking number cubes"/>
                    </div>
                    <div className="areasOfResearchAccordion-text">
                        <h2 className="areasOfResearchAccordion-title-text">Early Numeracy and Abstract Concepts</h2>
                        {/* <h3>{research.subtitle}</h3> */}
                    </div>
                    <i className={ isActive ?  `fa-solid fa-angle-up`:`fa-solid fa-angle-down`}></i>                  
                </div>
                {isActive && <div className="areasOfResearchAccordion-content">
                    <div className="areasOfResearchAccordion-description">
                        <p>Children start learning abstract concepts before entering school, with certain ones, such as numbers, shaping their academic success and life outcomes. Interestingly, some concepts are easily grasped, while others become persistent sources of misconceptions. This area of research will explore questions such as:</p>
                    </div>  
                    <div className="areasOfResearchAccordion-descriptionTwo">
                        {/* <h2>Participation In A Connected World</h2> */}
                        <div className="areasOfResearchAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How do children learn numbers and other abstract concepts?</p>
                        </div>
                        <div className="areasOfResearchAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How do children’s intuitions shape their understanding of instruction, leading to either misconceptions or successful learning?</p>
                        </div>
                        <div className="areasOfResearchAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How can we design better instruction?</p>
                        </div>
                        <div className="areasOfResearchAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How can we support caregivers in fostering foundational knowledge of abstract concepts like numbers?</p>
                        </div>
                    </div>
                    {/* <div className="areasOfResearchAccordion-descriptionThree">
                        <h2>Curriculum + Education</h2>
                        <div className="areasOfResearchAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>What are important considerations for school-based civic learning opportunities that support meaningful youth engagement online?</p>
                        </div>
                        <div className="areasOfResearchAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How should we approach curriculum focused on digital citizenship more effectively to support the skills youth need to thrive in today’s society?</p>
                        </div>
                        <div className="areasOfResearchAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>To what extent are young people’s perspectives included in the development & implementation of curriculum about being a digital citizen?</p>
                        </div>
                    </div> */}
                </div>}
            </div>

    );
}

export default AreasOfResearchEarlyNumeracyAbstractConceptsAccordion;
