const CostsData = [
    {
        element: {
            title: 'Money',
            subtitle: 'Can you afford purchasing a copy of this software for each headset if needed? Are there any extra costs associated with the software?',
            img: 'https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/EdTech/VR+Software+Stress+Test/EdTech+VR+Stress+Test+Accordion+Icon-Cost.png',
            alt: 'dollar bill',
            content: 'Most VR software is not too expensive (on average $10-$40), but some can cost hundreds of dollars. Depending on your headset and hardware, you may or may not need to purchase a copy of the software for each headset you have. For example, something like the Quest 2 has you use the same account and app on multiple different machines.',
            contentCont: 'There can also be many hidden costs associated with VR software. These include subscriptions, extra accessories, or upgrades to existing hardware.'
        }
    },
    {
        element: {
            title: 'Time',
            subtitle: 'How long is every session of using the software? Does your student-to-headset ratio allow every student to have a session? If your headset is battery powered, can you get a desired amount of sessions in one charge?',
            img: 'https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/EdTech/VR+Software+Stress+Test/EdTech+VR+Stress+Test+Accordion+Icon-Time.png',
            alt: 'stopwatch',
            content: 'A software’s session is the amount of time your class will use the software at one time. If you group students up and have them share a headset, it is advised that you make sure every student can have a turn in one session. VR headsets aren’t known for their battery life, so your software’s sessions need to compensate for that if you are using battery-powered headsets.',
            contentCont: 'Also, a software may intend for a session to be longer than the length of your class, which can be a problem if you cannot save your progress. For example, a documentary can be an hour and a half long but your class is only an hour.'
        }
    }
]

export default CostsData;