import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import NewFooter from '../../../../pages-other/NewFooter';
import './People.css';

export default class JoeCaitlin extends Component {
  render() {
    return(
        <div id="marineCareers-main-container">
          <Helmet>
          <title>foundry10 Guide to Marine Science Careers: Veterinary Medicine</title>
          <meta
            name="title"
            content="foundry10 Guide to Marine Science Careers: Veterinary Medicine"
          />
          <meta
            name="description"
            content="Explore an exciting marine science career in veterinary medicine. Dive into what life is like in this career with interviews and resource links."
          />
          <meta property="og:title" content="foundry10 Guide to Marine Science Careers: Veterinary Medicine" />
          <meta
            property="og:description"
            content="Explore an exciting marine science career in veterinary medicine. Dive into what life is like in this career with interviews and resource links."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/marine-science-careers/veterinary-medicine" />
          <link rel="canonical" href="https://www.foundry10.org/marine-science-careers/veterinary-medicine" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Guide to Marine Science Careers: Veterinary Medicine",
                "description": "Explore an exciting marine science career in veterinary medicine. Dive into what life is like in this career with interviews and resource links.",
                "url": "https://www.foundry10.org/marine-science-careers/veterinary-medicine"
              }
            `}
          </script>
        </Helmet>
        {/* MAIN */}
        <div id="marineCareers-main-content-container">
          {/* HEADER */}
          <div id="marineCareers-header-container">
            <div class="marineCareers-header-title-container">
              <h3>Veterinary Medicine</h3>
              <h2>Meet Joe</h2>
              <div class="marineCareers-headerImage-container">
                <div class="marineCareers-headerImage-content-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/People/Joe+Gaydos.jpg" alt="joe gaydos"/>
                </div>
              </div>
              <p>Joe Gaydos is a Senior Wildlife Veterinarian and the Science Director for the SeaDoc Society, a science-based marine conservation program of UC Davis’ Karen C. Drayer Wildlife Health Center. The SeaDoc Society funds and conducts science and uses the information to help educate people about marine resources and help improve management and policy decisions regarding the health of the Salish Sea. Joe has a Doctorate in Veterinary Medicine from the University of Pennsylvania and a PhD from the University of Georgia. For two decades Joe has been working on wildlife and ecosystem health issues in the Pacific Northwest and has co-authored several books on the Salish Sea. He’s a science nerd with a passion for all things wild: wildlife, wild places and wild people. When he grows up he’d like to be a divemaster or safari guide.</p>
            </div>
            <div class="marineCareers-header-title-container">
              <h2>Meet Caitlin</h2>
              <div class="marineCareers-headerImage-container">
                <div class="marineCareers-headerImage-content-container">
                  <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/People/Caitlin+Hadfield.jpg" alt="caitlyn hadfield"/>
                </div>
              </div>
              <p>Dr. Caitlin Hadfield graduated from Cambridge University veterinary school and did an internship in aquatic animal medicine at National Aquarium in Baltimore. This became a full-time job as the associate veterinarian then senior veterinarian at the National Aquarium. She became boarded in zoo medicine, aquatics specialty, with the American College of Zoological Medicine in 2012 and the European College of Zoological Medicine in 2014. In 2017, she moved to the Seattle Aquarium as the senior veterinarian. The collection is focused on the Pacific Northwest and Hawaii, with new projects focused on the Coral Triangle.</p>
            </div>
          </div>
          {/* BODY */}
          <div id="marineCareers-review-container">
            <div class="marineCareers-review-content-container">
              {/* INTERVIEW WITH JOE*/}
              <div class="marineCareers-header-title-container">
                <h2>Interview with Joe</h2>
              </div>
              <div class="marineCareers-interview">
                <h2>What is your job title? And do you feel like that fully describes everything that you do?</h2>
                <p>My job title is Wildlife Veterinarian and Science Director, and I work for the SeaDoc Society program at UC Davis. Does that really describe what I do? I would say describes a lot of what I do. I do veterinary work, and I do wildlife animal health, I do science, I direct science programs, but definitely doesn't describe everything that I do. I have a lot of other components to my job that you would never even imagine just from my job title.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What are some common misconceptions about your job and field?</h2>
                <p>So, as a wildlife veterinarian, I think a lot of people just think like everyday we’re riding a killer whale or, you know, doing surgery on a seal, or capturing a bird out of the air with our bare hands. When we're able to do stuff like that, you know those are the days that we live for. I love that. However, if you go out, and you do science on animals, endangered species, and you don't write scientific papers, and you don't publish those, then what you've learned doesn't go into making change. A big part of my job is making sure that the science that we do makes change for the better, we change management, or we change policies, and things like that. So what that means is while I want to be out there, capturing animals and taking samples everyday, I also have to publish papers and do things I'm very uncomfortable with.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>How has the field, topic, and industry changed in the past five years?</h2>
                <p>In the last five years, a lot has changed. The technology has improved so much and we're getting better. We used to have to capture an animal, and take a blood sample or take a biopsy sample, and now we can do things where we can catch hair, or we can pick up the poop of an animal, and we can test for different diseases and things like that without the animal ever knowing that we got a sample. Which is really cool because we like those animals, we want them to be healthy, we want them to be okay, we don't want to have to stress them out if we don't have to, so these new tools are really, really amazing. I think that that's just going to continue to grow, we're going to get better tools, so we don't have to touch animals. How cool is that with little barbed wire snares, that an animal will go under the snare, and it will pull out a little bit of hair, and then you can actually get genetic material, you can get DNA from that animal, from just the little bit of stuff attached to the hair.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What have you learned about yourself and the world while working in your field?</h2>
                <p>Well, one thing I learned is, I'm a little bit of a nerd, and that's okay. My kids, my wife, my friends always make fun of me, “you're such a geek, you're always thinking about wild animals and wildness” and things like that. I think as a kid, I didn't know that I was different on that, but I think the beauty is that that's okay. I also learned that being persistent is really important. I'm not the smartest guy in the world, I'm not the best wildlife biologist, or anything like that, but I just have always been persistent about trying to do what I think is important, and be where I can make a difference.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What advice would you give to somebody starting out on this career path?</h2>
                <p>Yeah, I would say if I can do it, anybody can do it, like there's nothing special about me. I've just worked hard, I've been lucky, so don't talk yourself out of it, don't say ‘oh I don't know enough science to be a marine biologist,’ or you know, ‘I don't know enough ocean chemistry’ because you can learn that stuff. There's a lot of great mentors, and great teachers, out there. So, if you feel something in your heart about the ocean, and about marine biology, you should follow that no matter what.</p>
              </div>
              {/* INTERVIEW WITH CAITLIN*/}
              <div class="marineCareers-header-title-container">
                <h2>Interview with Caitlin</h2>
              </div>
              <div class="marineCareers-interview">
                <h2>Describe an average day in the life of a veterinarian. What do you look forward to most during your workday?</h2>
                <p>My day varies a lot! Usually I start with checking on animals under treatment. That may involve checking a fish that was anesthetized the day before or doing an eye exam on a harbor seal. We may do some training, like working on taking voluntary x-rays in sea otters. Most of our animal care is focused on preventative medicine, but sometimes we need to react to new problems, like examining and getting samples from a bird that looks a little quiet. There is usually a lot of computer time, as everything we do needs to be recorded and we are always working on improving our policies and procedures. We also do a lot of communicating; in person, through email, and in meetings. And because we want to work well as a team and be prepared for emergencies, we also do drills to practice our skills.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What are some common misconceptions about your job/field?</h2>
                <p>People often think we have hands on our animals all the time, but if that was the case, it would suggest that something is wrong! We manage our animals and habitats to maintain a good quality of life for all the animals and try to prevent problems, so that we do not have to intervene.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>Describe your education, training and/or experience that prepared you for this career.</h2>
                <p>I did an undergraduate degree in Zoology at Oxford University, then a veterinary degree at Cambridge University. I knew that I wanted to do aquatic animal medicine, so I worked to get as much experience as possible while I was in vet school. After I graduated, I got a veterinary internship at the National Aquarium, which became a permanent job.</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What are some accomplishments you are proud of that you tend to highlight on a resume or cover letter?</h2>
                <p>Like other medical specialties, we have board exams. The American zoo boards are famously difficult, so it was exciting to pass them on my first try!</p>
              </div>
              <div class="marineCareers-interview">
                <h2>What advice would you give to someone starting out on this career path?</h2>
                <p>Curiosity, an open mind, and an ability to travel are all helpful. And remember that you are never stuck on a path - you can always discover new things that you are passionate about and follow them.</p>
              </div>
              {/* LEARNING MORE */}
              <div id="marineCareersLearning-container">
                <div class="marineCareersLearning-content-container">
                  <h2>Interested in learning more about Marine Veterinary Medicine?</h2>
                  <ul class="marineCareersLearning-list">
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p>Do you want to read more about Joe's responses? <a href="https://drive.google.com/file/d/1Ja5CMBIp_9y1iJgGI76gSK1onTehQtsb/view" target="_blank" rel="noopener noreferrer">Here's the link to the full interview</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p>Do you want to read more about Caitlin's responses? <a href="https://drive.google.com/file/d/1bFUOIBD7lXR_1dQXohFwG2aRU4idwyz_/view" target="_blank" rel="noopener noreferrer">Here's the link to the full interview</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.juniorseadoctors.com/" target="_blank" rel="noopener noreferrer">Become a junior sea doctor.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.montereybayaquarium.org/for-educators/for-teens/teen-career-resources/director-of-veterinary-services" target="_blank" rel="noopener noreferrer">Learn about another aquarium veterinarian by reading through this website and watching the video.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://1zootree.weebly.com/wildlife-veterinarian.html" target="_blank" rel="noopener noreferrer">Find out more information on how to become a wildlife veterinarian and what they do here.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://careertrend.com/become-veterinarian-marine-life-10784.html" target="_blank" rel="noopener noreferrer">Explore the different types of veterinarians here.</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="marineCareersLearning-card-container">
                        <div class="marineCareersLearning-card-content-container-gary">
                          <p><a href="https://www.aavmc.org/" target="_blank" rel="noopener noreferrer">Discover more resources around veterinary medicine on this webpage.</a></p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <a class="marineCareers-backButton" href={'/marine-science-careers'}>
                    <p>Back to the careers!</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <NewFooter />
        </div>
      </div>
    );
  }
}