import React, { Component } from 'react';
import './conceptualDevelopmentLab-Members.css';

export default class ConceptualDevelopmentLabMembers extends Component {
  render() {
    return (
      <div className="conceptualDevelopmentLabMembers-container">
        <div className="conceptualDevelopmentLabMembers-content-container">
          {/* <h2>Current Lab Members</h2> */}
          <h2 className="conceptualDevelopmentLabMembers-titleContent-container"><span>Current Lab Members</span></h2>
          <ul className="conceptualDevelopmentLabMembers-cardsList">
            <li>
              <a href="/about/team/dominic-gibson" className="conceptualDevelopmentLabMembers-link">
                <div className="conceptualDevelopmentLabMembers-card">
                  <div className="conceptualDevelopmentLabMembers-card-image">
                    <img src='https://craft-images.s3.us-west-2.amazonaws.com/Team/Headshots/Staff-Headshot_Dominic-Gibson_2.png' width="100%" alt="young man in grey shirt"/>
                  </div>
                  <div className="conceptualDevelopmentLabMembers-card-content">   
                    <h2>Dominic Gibson</h2>
                    <p>Principal Investigator</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="/about/team/allie-tung" className="conceptualDevelopmentLabMembers-link">
                <div className="conceptualDevelopmentLabMembers-card">
                  <div className="conceptualDevelopmentLabMembers-card-image">
                    <img src='https://f10-people.s3.us-west-2.amazonaws.com/Re-edited+Team+Photos+230x300/Most+Recent/ALLIE-2.jpg' width="100%" alt="young woman smiling in tshirt"/>
                  </div>
                  <div className="conceptualDevelopmentLabMembers-card-content">   
                    <h2>Allie Tung</h2>
                    <p>Assistant Researcher</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="/about/team/mikka-hoffman" className="conceptualDevelopmentLabMembers-link">
                <div className="conceptualDevelopmentLabMembers-card">
                  <div className="conceptualDevelopmentLabMembers-card-image">
                    <img src='https://f10-people.s3.us-west-2.amazonaws.com/Re-edited+Team+Photos+230x300/Most+Recent/Mikka+Hoffman.png' width="100%" alt="young woman smiling in white shirt"/>
                  </div>
                  <div className="conceptualDevelopmentLabMembers-card-content">   
                    <h2>Mikka Hoffman</h2>
                    <p>Research Coordinator</p>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}