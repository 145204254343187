import React, { Component } from 'react';
import MarineBycatch22Video from './marineBycatch22-Video';
import './marineBycatch22-Body.css';

export default class MarineBycatch22Body extends Component {
  render() {
    return(
      <div id="autoResearch-womenInAuto-container">
        <div class="autoResearch-womenInAuto-content-container">
          {/* <div class="interestArea-domain-title">
            <h2>We Are Puget Sound - Discovering & Recovering the Salish Sea</h2>
          </div> */}
          <br/>
          <br/>
          <div class="autoResearch-womenInAuto-paragraph">
            <p>Bycatch-22 explores the environmental impacts of bycatch, non-desirable species that are caught as a byproduct of commercial fisheries. Onboard a fishing boat of their own, players are tasked with sorting through growing netfuls of flopping fish, scallops, prawns, and trash, and will need to think and move quickly to return bycatch to the sea in time.</p>
            <p>The experience aims to inspire players to make sustainable seafood choices, avoiding seafood that generates excess bycatch, and to join the consumer movement for sustainability.</p>
          </div>
          <MarineBycatch22Video />
          <div class="interestArea-domain-title">
            <h2>Check out Bycatch-22!</h2>
            <br/>
          </div>
          <a href="https://infinitytide.itch.io/bycatch-22" target="_blank" rel="noopener noreferrer">    
            <div class="autoResearch-cards-container">
              <img src="https://f10-programs.s3.us-west-2.amazonaws.com/marine-science/Marine+Science+Bycatch22/Bycatch22+Big+Card.png" alt="bycatch22 link"/>
              <p class="autoResearch-cards-title">Click for more information and download!</p>
              <div class="autoResearch-cards-overlay"></div>
            </div>  
          </a>
        </div>
      </div>
    );
  }
}