import React, { Component } from 'react';
import './tripsLandingPage-Description.css';

export default class TripsLandingPageDescription extends Component {
  render() {
    return(
      <div id="tripsLandingPage-description-container">
        <div class="tripsLandingPage-description-content-container">
          <p>As part of our philosophy that students benefit from being exposed to experts in their fields of study, we have teamed up with local high school automotive programs to provide race experience trips for students. Each year, since 2014, we plan an automotive excursion, taking students and their teachers to a racing event where they will get up-close, personal interaction with professionals from the racing industry. Additionally, each trip includes special stops to other automotive-related places within the states we visit. Teachers are always included on the trips because they provide great opportunities for professional development, networking and experiences they can bring back to the classroom to improve their practice.</p>
          <p>Some years, the type of trip selected is a result of student surveys. In other years, the trip is designed around professional contacts that foundry10 or the educators may have. Interested students write a short essay highlighting their interest in the trip and all student applicants are offered an interview opportunity. foundry10 staff then work with teachers to select five students from each school to attend. Below, we have provided an overview of our trip experiences.</p>
        </div>
      </div>
    );
  }
}