import React, { Component } from 'react';
import './marineEndlessBlue-Body.css';

export default class MarineEndlessBlueBody extends Component {
  render() {
    return(
      <div id="autoResearch-womenInAuto-container">
        <div class="autoResearch-womenInAuto-content-container">
          {/* <div class="interestArea-domain-title">
            <h2>We Are Puget Sound - Discovering & Recovering the Salish Sea</h2>
          </div> */}
          <div class="autoResearch-womenInAuto-paragraph">
            <p>This engaging, quest-based experience was designed to introduce middle and high school youth to ocean issues and marine careers through role play. Adaptable scenarios present real world adventures like deep sea exploration, wildlife rescue, and marine archaeology while immersing players in authentic seafaring roles like oceanographer, navigator, and underwater photographer. Players will learn about fascinating marine species, problem solve with cutting edge ocean technology, and develop communication skills with fellow players through the course of each customizable session.</p>
            <p>Endless Blue is currently being edited and a new version will be coming soon!</p>
          </div>
          {/* <a href="https://drive.google.com/file/d/1rnV8RhnUekgTJPCH1uSlPWHdyepn8OOM/view" target="_blank" rel="noopener noreferrer">    
            <div class="autoResearch-cards-container">
              <img src="https://f10-programs.s3.us-west-2.amazonaws.com/games-and-learning/endless-blue-guide-thumbnail.jpg" alt="endless blue players guide"/>
              <p class="autoResearch-cards-title">Endless Blue - Player's Guide</p>
              <div class="autoResearch-cards-overlay"></div>
            </div>  
          </a>
          <br/>
          <br/>
          <a href="https://drive.google.com/file/d/1bwvuD2NyGGAthFGxN2_TneNUvjJiFd_j/view" target="_blank" rel="noopener noreferrer">    
            <div class="autoResearch-cards-container">
              <img src="https://f10-programs.s3.us-west-2.amazonaws.com/games-and-learning/endless-blue-character-sheet-thumbnail.jpg" alt="endless blue customizable character sheet"/>
              <p class="autoResearch-cards-title">Endless Blue - Customizable Character Sheet</p>
              <div class="autoResearch-cards-overlay"></div>
            </div>  
          </a> */}
        </div>
      </div>
    );
  }
}