import React, { useState } from 'react';
import './ResearchTopics-One.css';

function ResearchTopicsOne() {
    const [isActive, setIsActive] = useState(false);
    // const [hasMore, setHasMore] = useState(false);

    return (


            <div className="healthWellBeing-researchTopicsOneAccordion-item">
                <div className="healthWellBeing-researchTopicsOneAccordion-title" onClick={() => setIsActive(!isActive)}>
                    <div className="healthWellBeing-researchTopicsOneAccordion-image">
                        <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Health+and+Well-Being/Reseach+Topics/IA+Team+Page+Icons_Nature-Based+Learning+and+Positive+Youth+Development+2-.png" alt="two youth exploring the outdoors"/>
                    </div>
                    <div className="healthWellBeing-researchTopicsOneAccordion-text">
                        <h2 className="healthWellBeing-researchTopicsOneAccordion-title-text">Nature-Based Learning and Positive Youth Development</h2>
                        {/* <h3>{research.subtitle}</h3> */}
                    </div>
                    <i className={ isActive ?  `fa-solid fa-angle-up`:`fa-solid fa-angle-down`}></i>                  
                </div>
                {isActive && <div className="healthWellBeing-researchTopicsOneAccordion-content">
                    <div className="healthWellBeing-researchTopicsOneAccordion-description">
                        <p>A growing body of research points to a range of benefits associated with nature-based learning, or learning through exposure to nature and nature-based activities. Yet, there is still much to learn about how youth development is enhanced through different forms of interactions with nature and who benefits most from these interactions.</p>
                    </div>  
                    <div className="healthWellBeing-researchTopicsOneAccordion-descriptionTwo">
                        <h2>This area of research will explore questions such as:</h2>
                        <div className="healthWellBeing-researchTopicsOneAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How do interactions with nature impact the development of youth with learning differences or who experience structural disadvantages?</p>
                        </div>
                        <div className="healthWellBeing-researchTopicsOneAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>What elements of nature-based experiences affect young people’s learning and perspectives? Do mechanisms vary for different groups in different contexts?</p>
                        </div>
                        <div className="healthWellBeing-researchTopicsOneAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>What are challenges and opportunities for integrating nature-based learning in the classroom?</p>
                        </div>
                    </div>
                    {/* <div className="healthWellBeing-researchTopicsOneAccordion-descriptionThree">
                        <h2>VR/AR</h2>
                        <div className="healthWellBeing-researchTopicsOneAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How are young people using AR/VR as a tool to participate, create, and innovate?</p>
                        </div>
                        <div className="healthWellBeing-researchTopicsOneAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How can VR be used as a training tool for soft skills (e.g., creative thinking skills, decision-making ability, interpersonal communication skills)? How do young people implement soft skills experienced through VR in non-virtual settings?</p>
                        </div>
                        <div className="healthWellBeing-researchTopicsOneAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>We plan to use a variety of approaches to answer these questions such as: usability studies (measuring if the emerging technology is easy to use), feasibility studies (measuring if the emerging technology is feasible to use as intended within the classroom), and correlational studies (what relationship might exist between VR and soft skills attainment).</p>
                        </div>
                    </div> */}
                </div>}
            </div>

    );
}

export default ResearchTopicsOne;
