import React, { useState } from 'react';
import './ResearchTopics-Three.css';

function ResearchTopicsThree() {
    const [isActive, setIsActive] = useState(false);
    // const [hasMore, setHasMore] = useState(false);

    return (


            <div className="healthWellBeing-researchTopicsTwoAccordion-item">
                <div className="healthWellBeing-researchTopicsTwoAccordion-title" onClick={() => setIsActive(!isActive)}>
                    <div className="healthWellBeing-researchTopicsTwoAccordion-image">
                        <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Interest+Areas/Health+and+Well-Being/Reseach+Topics/IA+Team+Page+Icons_Social+Media+and+Adolescent+Health+1-.png" alt="young girl using social media on phone"/>
                    </div>
                    <div className="healthWellBeing-researchTopicsTwoAccordion-text">
                        <h2 className="healthWellBeing-researchTopicsTwoAccordion-title-text">Social Media and Adolescent Health</h2>
                        {/* <h3>{research.subtitle}</h3> */}
                    </div>
                    <i className={ isActive ?  `fa-solid fa-angle-up`:`fa-solid fa-angle-down`}></i>                  
                </div>
                {isActive && <div className="healthWellBeing-researchTopicsTwoAccordion-content">
                    <div className="healthWellBeing-researchTopicsTwoAccordion-description">
                        <p>Social media use can positively impact the development of young people, but it can also create risks to their well-being. Research indicates that helping youth develop the competencies to navigate and use social media is critically important to their development, particularly amongst younger children and those from marginalized populations.</p>
                    </div>  
                    <div className="healthWellBeing-researchTopicsTwoAccordion-descriptionTwo">
                        <h2>This area of research will explore questions such as:</h2>
                        <div className="healthWellBeing-researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How can educators foster critical digital literacy in ways that promote the wellbeing of young people?</p>
                        </div>
                        <div className="healthWellBeing-researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How can social media use promote the healthy socialization of youth?</p>
                        </div>
                    </div>
                    {/* <div className="healthWellBeing-researchTopicsTwoAccordion-descriptionThree">
                        <h2>Curriculum + Education</h2>
                        <div className="healthWellBeing-researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>What are important considerations for school-based civic learning opportunities that support meaningful youth engagement online?</p>
                        </div>
                        <div className="healthWellBeing-researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>How should we approach curriculum focused on digital citizenship more effectively to support the skills youth need to thrive in today’s society?</p>
                        </div>
                        <div className="healthWellBeing-researchTopicsTwoAccordion-descriptionTwo-paragraph">
                          <i class="fa-solid fa-angles-right"></i><p>To what extent are young people’s perspectives included in the development & implementation of curriculum about being a digital citizen?</p>
                        </div>
                    </div> */}
                </div>}
            </div>

    );
}

export default ResearchTopicsThree;