import React, { Component } from 'react';
import './savvyLadies-Description.css';

export default class SavvyLadiesDescription extends Component {
  render() {
    return(
      <div id="savvyLadies-description-container">
        <div class="savvyLadies-description-content-container">

          <p>Research has found that women report lower levels of financial literacy, financial confidence/self-efficacy, and financial risk behaviors compared to men. Social and professional assistance programs, such as financial management training sessions, may help women overcome some of these setbacks.</p>

          <p>In this study, we will examine the financial attitudes and behaviors of women who participate in the Savvy Ladies “Dominate your Debt” program. We will use surveys at the beginning and end of the program to gain a better understanding of women’s attitudes around personal finance (e.g., budgeting, saving, and debt), financial anxiety, financial confidence/self-efficacy, financial risk tolerance, and gender stereotypes about finances. We hope to gain some clarity around women’s experiences with personal finance and potential changes that may occur after participating in Savvy Ladies debt management programming.</p>

          <p>Check back for more information coming soon.</p>
          
        </div>
      </div>
    );
  }
}