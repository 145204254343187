import React, { Component } from 'react';
import VrLearningMapBody from './vrLearningMap-Body';
import NewFooter from '../../pages-other/NewFooter';
import './vrLearningMap-Content.css';

export default class VrLearningMapContent extends Component {
  render() {
    return(
      <div id="vrLearningMap-content-container">
        <VrLearningMapBody />
        <NewFooter />
      </div>
    );
  }
}