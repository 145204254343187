import React, { Component } from 'react';
import './PhilanthropyDirector-Responsibilities.css';

export default class PhilanthropyDirectorResponsibilities extends Component {
  render() {
    return(
      <div id="philanthropyDirectorResponsibilities-container">
        <div class="philanthropyDirectorResponsibilities-content-container">
          <div class="philanthropyDirectorResponsibilities-content-title">
            <h2>Responsibilities</h2>
          </div>
          <div class="philanthropyDirectorResponsibilities-content-subtitle">
            <h2>Internal to the Organization</h2>
          </div>
          <ul>
            <li>Team coaching and guidance -- helping employees at all levels consider the ways in which philanthropy intersects with their projects/programs.</li>
            <li>Develop and apply knowledge and expertise about schools and organizations related to our primary impact areas, build relationships, and find further opportunities for organizational impact.</li>
            <li>Keep abreast of research and trends on innovative and effective practices related to our impact areas, specifically in education, educational philanthropy, research philanthropy and community organizational support.</li>
            <li>Manage Philanthropy Team and will have two to three direct reports.</li>
            <li>Serve as a resource for organizational members to help them grow, develop, and better meet the needs of the communities in which they are working.</li>
            <li>Develop and provide education and training for all levels at the organization to improve organizational understanding of the ways our philanthropy can make a positive impact on the education space.</li>
            <li>Design and implement systems to help philanthropic work flow more effectively within the organization in partnership with the Philanthropy Coordinator.</li>
            <li>Gather, track, and analyze information about our philanthropic endeavors as an organization to provide insights, strategic direction and help illuminate the impact of our work.</li>
            <li>Support teams in further developing strong, healthy, collaborative partnerships.</li>
            <li>Offer insight and direction into the philanthropic budgeting process.</li>
            <li>Participate in the approval process for philanthropic gifts.</li>
            <li>Work with our communications team to coordinate strategy and find appropriate communications channels for philanthropic work.</li>
            <li>Work closely with the CEO, Philanthropy Coordinator, and other Org Leaders to strategically plan and engage around our philanthropic work.</li>
            <li>Potentially design and implement research work around the organization's philanthropic projects and programs.</li>
          </ul>
          <div class="philanthropyDirectorResponsibilities-content-subtitle">
            <h2>External to the Organization</h2>
          </div>
          <ul>
            <li>Continued refinement in our areas of philanthropic work: 
                <ul>
                    <li>Expanded Learning/Research</li>
                    <li>Continuing Education (Scholarships, Certifications)</li>
                    <li>General Learning and Well-Being (e.g, Interventions, STEM)</li>
                    <li>Accessibility (broadly defined -- we help people get what they need)</li>
                    <li>Basic Health (Food and Nutrition, Injury Prevention, Health Ed)</li>
                </ul>
            </li>
            <li>Provide partner management support on the Philanthropy Team and be available to provide other support in organizational external meetings.</li>
            <li>Serve as an outside voice for the organization at conferences and other philanthropic events to grow a wider understanding of organizational philanthropic efforts.</li>
            <li>Help to cultivate new relationships with schools and districts across our state and potentially in other states.</li>
          </ul>
        </div>
      </div>
    );
  }
}