import React, { Component } from 'react';
import './resourcesLandingPage-Salish.css';

export default class ResourcesLandingPageSalish extends Component {
  render() {
    return(
      <div id="autoResearch-womenInAuto-container">
        <div class="autoResearch-womenInAuto-content-container">
          <div class="interestArea-domain-title">
            <h2>Explore the Salish Sea - A Nature Guide for Kids</h2>
          </div>
          <div class="autoResearch-womenInAuto-paragraph">
            <p>This book was given to educators by the SeaDoc Society at their <a href="https://www.juniorseadoctors.com/workshops" target="_blank" rel="noopener noreferrer">“Explore the Salish Sea Educator Workshop,”</a> which explores Next Generation Science Standards (NGSS) and helps to give teachers the tools to understand NGSS while utilizing real-world and local data.</p>
            <p>A recap of that event written by the School of Aquatic Fisheries Sciences at the University of Washington can be read here: <a href="https://fish.uw.edu/2019/12/safs-hosts-seadoc-society-educator-workshop/" target="_blank" rel="noopener noreferrer">SAFS Hosts SeaDoc Society Educator Workshop</a>.</p>
            <p>About the book: “The SeaDoc Society has published a book for the next generation. Filled with beautiful photography and engaging stories, Explore the Salish Sea: A Nature Guide for Kids inspires children to explore the unique marine ecosystem that encompasses the coastal waters from Seattle’s Puget Sound up to the Strait of Juan de Fuca and the Georgia Strait of British Columbia.”</p>
          </div>
          <a href="https://www.seadocsociety.org/explore-the-salish-sea-a-nature-guide-for-kids" target="_blank" rel="noopener noreferrer">    
            <div class="autoResearch-cards-container">
              <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Resources/Explore+the+Salish+Sea.png" alt="explore the salish sea"/>
              <p class="autoResearch-cards-title">Explore the Salish Sea</p>
              <div class="autoResearch-cards-overlay"></div>
            </div>  
          </a>
        </div>
      </div>
    );
  }
}