import React, { Component } from 'react';
import './cteEd-Body.css';

export default class CteEdBody extends Component {
  render() {
    return(
      <div id="cteEd-description-container">
        <div class="cteEd-description-content-container">
          <p>Our Career and Technical Education team believes that we can help you make this transition effectively. We are looking for ways to support career and technical education programs and to keep your students excited about these possible future careers in this difficult time.</p>
          <p>Without access to school equipment and training spaces, students will be required to do more learning at home. Whether it be sending kits home or creating engaging video content, we are here to support you and your students. We want to hear from you about your ideas for continuing career and technical education successfully during the pandemic. In what ways have you adapted your programs? What can we do to help?</p>
          <p>Let us know about your ideas for programs in the coming year and what support you may need! You can contact our Career and Technical Education team at <a href="mailto:cte@foundry10.org">cte@foundry10.org</a>. We are especially interested in supporting programs in the fields of automotive, translation and interpretation, carpentry, and culinary arts.</p>
        </div>
      </div>
    );
  }
}