import React, { Component } from 'react';
import './annualReport2021STEM-Overview.css';

export default class AnnualReportSTEMOverview extends Component {
  render() {
    return(
      <div id="annualReport2021STEM-overview-container">
          <div className="annualReport2021STEM-overview-text-container">
            <h1>STEM Team</h1>
            <p>
            STEM Team's focus this year was on creating a compiled resource of STEM lesson plans for teachers and instructors. These lessons were centered around teaching youth about the Engineering Design Process (EDP) through challenge activities that engage students in each step of the EDP. We believe that every student should be well-equipped to solve problems they encounter in their everyday lives, and EDP is a great tool for approaching and solving problems. Engineering is situated squarely in the STEM acronym, and practicing the EDP approach provides a unique opportunity to integrate math, science, and technology skills in applied learning. As more K-12 teachers are asked to add STEM into their classrooms, we see this as an opportunity to support teachers with ready-to-go lessons and tools for seamless STEM integration.
            </p>
          </div>
      </div>
    );
  }
}