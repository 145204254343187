import React, { Component } from 'react';
import './marineEndlessBlue-Header.css';

export default class MarineEndlessBlueHeader extends Component {
  render() {
    return(
      <div id="marineEndlessBlue-header-container">
          <div class="marineEndlessBlue-header-content-container">
            <h1>Endless Blue: A Table-Top RPG</h1>
          </div>
      </div>
    );
  }
}