import React, { Component } from 'react';
import './annualReport2021EdTech-Overview.css';

export default class AnnualReportEdTechOverview extends Component {
  render() {
    return(
      <div id="annualReport2021EdTech-overview-container">
          <div className="annualReport2021EdTech-overview-text-container">
            <h1>EdTech Team</h1>
            <p>
            The EdTech team focuses on exploring the role technology plays in learning through a student-centered lens. Our earlier work focused on understanding the strengths and challenges of virtual reality in the classroom. During the pandemic, we mobilized quickly to support student and teacher needs during remote learning. We have provided funds for hotspots, devices, headphones, and software subscriptions for students and teachers in multiple K-12 districts and community colleges. We continue to research learners’ and educators’ experiences with technology and are curious about exploring the possibilities and limitations of educational technology.
            </p>
          </div>
      </div>
    );
  }
}