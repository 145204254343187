// Stories, In The News, Resources and Publications Archive
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import {
    useQuery,
    gql
  } from "@apollo/client";
import PageHeader from '../components/page-header.js';
import ArchiveFilter from '../components/archive-filter.js';
import ProjectList from '../components/project-list.js';
import PreFooter from '../components/pre-footer.js';
import NewFooter from '../../pages-other/NewFooter.js';

// Get Page content
const entryQuery = gql`
    query pageQuery($slug: [String]) {
      entries(slug: $slug) {
        title
        ... on storiesArchive_storiesArchive_Entry {
          blurb
          guidingPrinciples {
            ... on guidingPrinciples_BlockType {
              guidingPrinciplesPrinciple
            }
          }
          footerQuote
          footerQuoteAttribution
          footerQuoteBg {
            url
          }
          footerCTA {
            ... on footerCTA_BlockType {
              footerCTATitle
              footerCTAContent
              footerCTALinkUrl {
                ariaLabel
                customText
                target
                text
                title
                type
                url
              }
            }
          }
        }
        ... on pressArchive_pressArchive_Entry {
          blurb
          guidingPrinciples {
            ... on guidingPrinciples_BlockType {
              guidingPrinciplesPrinciple
            }
          }
          footerQuote
          footerQuoteAttribution
          footerQuoteBg {
            url
          }
          footerCTA {
            ... on footerCTA_BlockType {
              footerCTATitle
              footerCTAContent
              footerCTALinkUrl {
                ariaLabel
                customText
                target
                text
                title
                type
                url
              }
            }
          }
        }
        ... on programsArchive_programsArchive_Entry {
          blurb
          guidingPrinciples {
            ... on guidingPrinciples_BlockType {
              guidingPrinciplesPrinciple
            }
          }
          footerQuote
          footerQuoteAttribution
          footerQuoteBg {
            url
          }
          footerCTA {
            ... on footerCTA_BlockType {
              footerCTATitle
              footerCTAContent
              footerCTALinkUrl {
                ariaLabel
                customText
                target
                text
                title
                type
                url
              }
            }
          }
        }
        ... on projectArchives_projectArchives_Entry {
          blurb
          guidingPrinciples {
            ... on guidingPrinciples_BlockType {
              guidingPrinciplesPrinciple
            }
          }
          footerQuote
          footerQuoteAttribution
          footerQuoteBg {
            url
          }
          footerCTA {
            ... on footerCTA_BlockType {
              footerCTATitle
              footerCTAContent
              footerCTALinkUrl {
                ariaLabel
                customText
                target
                text
                title
                type
                url
              }
            }
          }
        }
        ... on opportunitiesArchive_opportunitiesArchive_Entry {
          blurb
          guidingPrinciples {
            ... on guidingPrinciples_BlockType {
              guidingPrinciplesPrinciple
            }
          }
          footerQuote
          footerQuoteAttribution
          footerQuoteBg {
            url
          }
          footerCTA {
            ... on footerCTA_BlockType {
              footerCTATitle
              footerCTAContent
              footerCTALinkUrl {
                ariaLabel
                customText
                target
                text
                title
                type
                url
              }
            }
          }
        }
      }
    }
`

const PageRender = (slug) => {
  const { data, loading, error } = useQuery(entryQuery, {
    variables: {slug}
  });
  if (loading) return null;
  if (error) return `Error! ${error}`;

  return(
    data.entries.map(
      (block, index) => {    
        const preFooter = block.guidingPrinciples;
        if(typeof(preFooter) ) {
          return (
            <PreFooter data={data}/>
          );
        }
        switch (block) {
          case 'guidingPrinciples':
            return (
              <PreFooter block={block} />
            )
          case 'blurb':
              return (
                <div>
                  There's a blurb
                </div>
              )
          }
      }
    )
  );
}

export default class ProjectArchive extends Component {
  render() {
    // console.log('props for content archive');
    // console.log(this.props);
    return(
      <section id="contentArchive">
        <Helmet>
          <title>foundry10 Programs and Projects: Explore Our Education Programs</title>
          <meta
            name="title"
            content="foundry10 Programs and Projects: Explore Our Education Programs"
          />
          <meta
            name="description"
            content="Discover diverse education programs, courses, workshops, and exploratory initiatives designed to inspire and empower learners of all ages."
          />
          <meta property="og:title" content="foundry10 Programs and Projects: Explore Our Education Programs" />
          <meta
            property="og:description"
            content="Discover diverse education programs, courses, workshops, and exploratory initiatives designed to inspire and empower learners of all ages."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/programs-projects" />
          <link rel="canonical" href="https://www.foundry10.org/programs-projects" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Programs and Projects: Explore Our Education Programs",
                "description": "Discover diverse education programs, courses, workshops, and exploratory initiatives designed to inspire and empower learners of all ages.",
                "url": "https://www.foundry10.org/programs-projects"
              }
            `}
          </script>
        </Helmet>
        <PageHeader slug={this.props.slug} />
        <ArchiveFilter/>
        <ProjectList channel={this.props.channel} />
        <PageRender slug={this.props.slug} />
        <NewFooter />
      </section>
    );
  }
}