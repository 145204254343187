import React, { Component } from 'react';

const List = ({ block }) => {
  return (
    <section className='list'>
      <div className='container'>
          <h3 className='text-xxl_bold'>{block.listTitle}</h3>
          {block.list.map((listItem) => (
            <div className='listItem'>
              {listItem.listItemImage[0] && (
                <img src={listItem.listItemImage[0].url} />
              )}
              {listItem.listItemHeadline && (
                <h4 className='text-medium'>{listItem.listItemHeadline}</h4>
              )}
               {listItem.listItemContent && (
                <div className='listContent'
                  dangerouslySetInnerHTML={{
                    __html: listItem.listItemContent,
                  }}
                ></div>
               )}
               {listItem.listItemLink.url && (
                <a className='button' href={listItem.listItemLink.url} target={listItem.listItemLink.target}>
                  {listItem.listItemLink.customText}
                </a>
               )}
            </div>
          ))}
      </div>

    </section>
  )
}

export default List