import React, { Component } from 'react';
import AnnualReportDanceOverview from './annualReport2021Dance-Overview';
import AnnualReportDanceSpotlights from './annualReport2021Dance-Spotlights';
import AnnualReport2021ProgramLinks from '../annualReport2021ProgramLinks/AnnualReport2021-ProgramLinks';
import NewFooter from '../../../../pages-other/NewFooter';
import './annualReport2021Dance-Content.css';

export default class AnnualReportDanceContent extends Component {
  render() {
    return(
      <div id="annualReport2021Dance-content-container">
        <AnnualReportDanceOverview />
        <AnnualReportDanceSpotlights />
        <AnnualReport2021ProgramLinks />
        <NewFooter />
      </div>
    );
  }
}