import React, { Component } from 'react';
import BrierTerraceDescription from './brierTerrace-Description';
import BrierTerraceLinks from './brierTerrace-Links';
import NewFooter from '../../../pages-other/NewFooter';
import './brierTerrace-Content.css';

export default class brierTerraceContent extends Component {
  render() {
    return(
      <div id="brierTerrace-content-container">
        <BrierTerraceDescription />
        <BrierTerraceLinks />
        <NewFooter />
      </div>
    );
  }
}