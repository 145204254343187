import React, { Component } from 'react';
import ResearchRedesignIntro from './researchRedesign-Intro';
import ResearchRedesignBody from './researchRedesign-Body';
import './researchRedesign-Content.css';

export default class ResearchRedesignContent extends Component {
  render() {
    return(
      <div id="researchRedesign-content-container">
        <ResearchRedesignIntro />
        <ResearchRedesignBody />
      </div>
    );
  }
}
