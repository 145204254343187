import React, { Component } from 'react';
import './resourcesLandingPage-ROV.css';

export default class ResourcesLandingPageROV extends Component {
  render() {
    return(
      <div id="autoResearch-womenInAuto-container">
        <div class="autoResearch-womenInAuto-content-container">
          <div class="interestArea-domain-title">
            <h2>Researching the Deep Sea - Remotely Operated Vehicles</h2>
          </div>
          <div class="autoResearch-womenInAuto-paragraph">
            <p>This article was written in 2013 by foundry10’s own Colin Katagiri during his time aboard the R/V Thomas G. Thompson as part of the University of Washington’s work with the <a href="https://interactiveoceans.washington.edu/about/ocean-observatories-initiative/" target="_blank" rel="noopener noreferrer">Ocean Observatories Initiative</a>.</p>
            <p>About the article: “Advances in technology continue to make exploration of the oceans more accessible to both researchers and now the public. High-Definition cameras are currently being paired with state-of-the-art engineering to see what is happening kilometers below the surface of the ocean. At the forefront of deep-sea science and exploration are Remotely Operated Vehicles (ROVs). High-performance ROVs exist around the world and some can be deployed to take on tasks in one of the world’s most extreme environments, the deep ocean. It is true that other systems for aquatic research exist, but there are serious drawbacks associated with them that prohibit them from practical use on the seafloor.”</p>
          </div>
          <a href="https://drive.google.com/file/d/1hE6h_2SqHmbO2ZfnnFCd8-Hc8qzHE1Bs/view?usp=sharing" target="_blank" rel="noopener noreferrer">    
            <div class="autoResearch-cards-container">
              <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Resources/Researching+The+Deep+Sea.png" alt="researching the deep sea"/>
              <p class="autoResearch-cards-title">Researching the Deep Sea Article</p>
              <div class="autoResearch-cards-overlay"></div>
            </div>  
          </a>
        </div>
      </div>
    );
  }
}