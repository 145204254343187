import React, { Component } from 'react';
import './resourcesLandingPage-Comics.css';

export default class ResourcesLandingPageComics extends Component {
  render() {
    return(
      <div id="autoResearch-womenInAuto-container">
        <div class="autoResearch-womenInAuto-content-container">
          <div class="interestArea-domain-title">
            <h2>Comics as a platform for science communication: Lessons from the E/V Nautilus and the 2019 Short Run Comics Festival</h2>
          </div>
          <div class="autoResearch-womenInAuto-paragraph">
            <p>Peyton Lee, a foundry10 Marine Science intern, wrote about his time aboard the E/V Nautilus that prompted his ocean exploration comic, <a href="https://www.etsy.com/listing/757788845/live-from-the-deep-comic-an-ocean" target="_blank" rel="noopener noreferrer">Live From The Deep</a>, developed through the foundry10 comics workshop.</p>
            <p>About the comic: Join Peyton Lee in this graphic travelogue of his journey to American Samoa's depths, filled with tales from life aboard the E/V Nautilus and insights into the forefront of marine exploration. Paintings of coral gardens, underwater volcanoes, and the sea's stranger denizens make this a must-read for anyone looking to dive into the mysteries of our unseen oceans.</p>
          </div>
          <a href="https://drive.google.com/open?id=1Yd_kFMC3MRAa6Tj-xjEiQkxmXpMksVLD" target="_blank" rel="noopener noreferrer">    
            <div class="autoResearch-cards-container">
              <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Resources/Live+From+the+Deep+Comic.png" alt="live from the deep article"/>
              <p class="autoResearch-cards-title">Live From the Deep Article</p>
              <div class="autoResearch-cards-overlay"></div>
            </div>  
          </a>
        </div>
      </div>
    );
  }
}