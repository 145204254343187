import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import {
  useQuery,
  gql
} from "@apollo/client";
import PageHeader from '../components/page-header.js';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import ContentBlocks from '../components/ContentBlocks';
// import GlobalFooter from '../components/base/GlobalFooter';
import NewFooter from '../../pages-other/NewFooter.js';

const segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
const segment_array = segment_str.split( '/' );
const last_segment = segment_array.pop();
const params = [
    { parent: segment_array[1]},
    { pageTitle: last_segment},
    { style: 'dark'}
]

const QUERY = gql`
    query teamPage($slug: [String]) {
    entry(slug: $slug) {
      title
      postDate @formatDateTime(format: "d M Y")
      postDateAlt: postDate @formatDateTime(format: "Y-m-d")
      url
      ... on team_team_Entry {
        customTitle
        blurb
        featuredImage {
          title
          url
        }
        featuredImageCaption
        contentOptions {
          ... on contentOptions_standardContent_BlockType {
            contentTitle
            contentContent
            linkUrl {
              url
              customText
              target
            }
            contentAlignment
          }
          ... on contentOptions_icp_BlockType {
            icpTitle
            icpContent
              icpImage {
                  url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
          }
          ... on contentOptions_fwi_BlockType {
            fwiTitle
            fwiImage {
                url
            }
            fwiCaption
            fwiSize
          }
          ... on contentOptions_emphasized_BlockType {
            emphasizedTitle
            emphasizedSubheadline
            emphasizedOverline
            emphasizedImage {
              url
            }
            emphasizedImageCaption
            emphasizedContent
            emphasizedLink
            emphasizedBackgroundColor
          }
          ... on contentOptions_quote_BlockType {
                pullQuote
                quoteSocial
                quoteImage {
                  url
                }
                quoteAttribution
          }
          ... on contentOptions_quoteSlideshow_BlockType {
            quoteSliderTitle
            quotes {
              __typename
            }
          }
          ... on contentOptions_slider_BlockType {
            sliderTitle
            slides {
              __typename
              ... on slides_BlockType {
                slideImage {
                  url
                }
                slideHeadline
                slideContent
                slideLink {
                  customText
                  url
                  target
                }
              }
            }
          }
          ... on contentOptions_accordion_BlockType {
            accordionTitle
            accordion {
              __typename  
              ... on accordion_BlockType {
                accordionItemTitle
                accordionItemBlurb
                accordionItemImage {
                  url
                }
                accordionItemIcon {
                  url
                }
                accordionItemContent
              }
            }
          }
          ... on contentOptions_list_BlockType {
            listTitle
            list {
              __typename  
              ... on list_BlockType {
                listItemHeadline
                listItemContent
                listItemImage {
                  url
                }
                listItemLink {
                  url
                  customText
                  target
                }
              }
            }
          }
          ... on contentOptions_linkList_BlockType {
            linkListTitle
            linkListList {
              __typename  
              ... on linkListList_BlockType {
                linkListTitle
                linkUrl
                linkListBg {
                  url
                }
                linkListHoverTxt
                linkListTitle
              }
            }
            linkListDisplay
            linkListSize
            columnCount
          }
          ... on contentOptions_video_BlockType {
            videoTitle
            videoLink
            videoCaption
            videoImage {
              url
            }
          }
          ... on contentOptions_featuredResource_BlockType {
            featuredResource {
              title 
              slug
              ... on resources_default_Entry {
                blurb
                researchAsset {
                  url
                }
                interestAreaTeam {
                  ... on interestAreaTeam_BlockType {
                    interestAreaTeamMember {
                      title
                      url
                    }
                  }
                }
                researchExternalAuthors {
                  ... on researchExternalAuthors_BlockType {
                    researchExternalAuthorsAuthor
                  }
                }
                featuredImageCaption
              }
            }
          }
          ... on contentOptions_featuredPerson_BlockType {
            featuredPerson {
              title 
              url
              ... on teamMembers_default_Entry {
                teamMembersPronouns
                teamMemberRoles {
                  title
                }
                teamMemberImage {
                  url
                }
                teamMemberPosition
                teamMemberBio
              }
            }
          }
          ... on contentOptions_featuredStory_BlockType {
            featuredstory {
              title 
              url
              slug
              author {
                fullName
              }
              postDate @formatDateTime(format: "F j, Y")
              dateCreated @formatDateTime (format: "M j, Y")
              ... on stories_default_Entry {
                blurb
                featuredImage {
                  title
                  url
                }
                categories {
                  title
                }
                themes {
                  title
                }
                interestAreas {
                  title
                }
                researchExternalAuthors {
                  ... on researchExternalAuthors_BlockType {
                    researchExternalAuthorsAuthor
                  }
                }
                interestAreaTeam {
                  ... on interestAreaTeam_BlockType {
                    interestAreaTeamMember {
                      title
                    }
                  }
                }
              }
            }
          }
          ... on contentOptions_thirdPartyCode_BlockType {
            thirdPartyCodeTitle
            thirdPartyCodeCode 
          }
        }
      }
    }
  }
`

const catQuery = gql`
    query teamCatQuery {
        categories(relatedToEntries: {section: "teamMembers"}) {
        categoryId: id
        title
        ... on teamMembers_Category {
            body
          }
        }
    }
`
const teamQuery = gql`
    query teamQuery($catId: [QueryArgument]) {
        entries(section: "teamMembers", orderBy: "title asc", relatedToCategories: [{ id: $catId }]) {
          title
          slug
          ... on teamMembers_default_Entry {
            teamMemberPosition
            teamMembersPronouns
            teamMemberImage {
              url
            }
          }
        }
    }
`
const TeamSection = (gridData) => {
    const { data, loading, error } = useQuery(catQuery);
    if (loading) { return <div>loading</div>; }
    if (error) { return <div>{error}</div>; }
    //console.log(data);
    
    return (
      data.categories.map(
        (block, index) => {
          //console.log(block.title);
          //console.log(block.categoryId);
          return (
          <section className="teamGrid">
            <h3 className='text-xxl'>
              {block.title}
            </h3>
            {block.body && (
              <div className="teamCatContent"
                dangerouslySetInnerHTML={{
                __html: block.body,
                }}
            ></div>
            )}
            <Grid container spacing={32}>
                <TeamGrid teamData={block.categoryId}/>
            </Grid>
          </section>
          )
        }
      )
    );
}

const TeamGrid = (teamData) => {
  const catId = teamData.teamData;
  const { data, loading, error } = useQuery(teamQuery, {
    variables: {catId}
  });
  //console.log(teamData.teamData)
  const testCat = teamData
  //console.log(data);

  if (loading) return null;
  if (error) return `Error! ${error}`;

  return (
    data.entries.map(
      (block) => {
        const url = '/about/team/'+ block.slug;
        let teamImage = '/news-archive-spacer.png'
        if ( block.teamMemberImage.length ) {
          teamImage = block.teamMemberImage[0].url
        }
        console.log(block.slug);
        //console.log(block.categoryId);
        return (
          <Grid item xs={12} sm={6} md={3}>
            <Card className='teamCard'>
              <CardMedia
                className='teamImage'
                image={teamImage}
                title={block.title}
              />
              <CardContent>
                <h3 className="text-medium">
                  <a href={url}>
                    {block.title}
                  </a>
                </h3>
                {block.teamMembersPronouns && (
                  <p className="text-body teamMemberPronouns">{block.teamMembersPronouns}</p>
                )}
                {block.teamMemberPosition && (
                  <p className="text-body_bold teamMemberPosition">{block.teamMemberPosition}</p>
                )}
              </CardContent>
            </Card>
          </Grid>
        )
      }
    )
  )
}

const InlineContent = (slug) => {
  const { data, loading, error } = useQuery(QUERY, {
    variables: {slug}
  });
  if (loading) { return <div>loading</div> }
  if (error) { return <div>{error}</div> }

  return (
    <ContentBlocks data={data.entry} />
  );
}

export default class TeamArchive extends Component {
  render() {
    return(
      <Fragment>
      <section id="teamArchive">
      <Helmet>
          <title>The foundry10 Team: Learn More About Our Team Members!</title>
          <meta
            name="title"
            content="The foundry10 Team: Learn More About Our Team Members!"
          />
          <meta
            name="description"
            content="Explore our diverse team, each bringing unique backgrounds and perspectives to empower learning."
          />
          <meta property="og:title" content="The foundry10 Team: Learn More About Our Team Members!" />
          <meta
            property="og:description"
            content="Explore our diverse team, each bringing unique backgrounds and perspectives to empower learning."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/about/team" />
          <link rel="canonical" href="https://www.foundry10.org/about/team" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "AboutPage",
                "name": "The foundry10 Team: Learn More About Our Team Members!",
                "description": "Explore our diverse team, each bringing unique backgrounds and perspectives to empower learning.",
                "url": "https://www.foundry10.org/about/team"
              }
            `}
          </script>
        </Helmet>
        <PageHeader slug={last_segment} />
        <InlineContent slug='team'/>
        <div className='container'>
          <TeamSection />
        </div>
        {/* <GlobalFooter /> */}
      </section>
      <NewFooter />
      </Fragment>
    );
  }
}