import React, { Component } from 'react';
import './annualReport2021MarineScience-Spotlights.css';

export default class AnnualReportMarineScienceSpotlights extends Component {
  render() {
    return(
      <div id="annualReport2021MarineScience-spotlights-container">
        <div className="annualReport2021MarineScience-spotlight-first-title-bar">
          <p>Spotlight: SWIMS (Squad within Marine Science)</p>
        </div>
        <div className="annualReport2021MarineScience-spotlights-content-container">
          <div className="annualReport2021MarineScience-spotlights-text-container">
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Subpages/SWIMS.jpg" alt="scuba diver and fish in teal and orange"/>
            <p>
            Many ocean professionals were heavily impacted by the pandemic, particularly informal educators in local aquariums, museums, and outreach programs. In response, SWIMS (a squad within Marine Science team) was formed with the aim of bringing together a select group of these professionals to refine their educational program ideas, foster connections with the local environmental science education community, and initiate projects that could be put into action after the pandemic.  This group investigated nine projects that were grounded in intersectional environmentalism and community marine science, including: Facilitating a youth mentorship workshop for scientists, designing marine science programming for youth experiencing homelessness and youth in foster care, accessing beach exploration for refugees and recent immigrants, and providing appropriate marine science activities for neurodiverse learners.
            </p>
          </div>
        </div>
        <div className="annualReport2021MarineScience-spotlight-second-title-bar">
          <p>Spotlight: Ultimate Guide to Underwater Work</p>
        </div>
        <div className="annualReport2021MarineScience-spotlights-content-container">
          <div className="annualReport2021MarineScience-spotlights-text-container">
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Subpages/underwater+guide.jpg" alt="The Ultimate Guide to Underwater Work"/>
            <p>Marine science is a vast interdisciplinary field, and there are many other ways to work in and around the ocean that are not as well known.  In an effort to showcase the breadth of ways that one can be a part of ocean work, our intern Alijah Benbrook interviewed over 20 professionals across 14 ocean-related fields, and we compiled all those interviews into a single resource.  This expansive guide is meant to be a tool for adults and youth alike who want to find ways to engage with the ocean or help youth discover pathways into those careers.
            <br /> <br />
            <a href="https://www.foundry10.org/marine-science-careers" target="_blank" rel="noopener noreferrer">Ultimate Guide to Underwater Work</a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}