import React, { Component } from 'react';
import './homeRedesign2023-Resources.css';

export default class HomeResources extends Component {
  render() {
    return(
      <div id="homeRedesign2023-Resources-container">
        <div className="homeRedesign2023-Resources-content-container">
            <div className="homeRedesign2023-Resources-bars-desktop">
                <p>Explore insights, resources, and opportunities:</p>
                <div className="homeRedesign2023-Resources-allBars">
                    <a href="/research">
                        <div className="homeRedesign2023-Resources-eachBar">
                            <p className="homeRedesign2023-Resources-eachBar-desktop">For <span className="homeRedesign2023-Resource-boldText">Researchers</span></p>
                            <p>→</p>
                        </div>
                    </a>
                    <a href="/resources-for-educators">
                        <div className="homeRedesign2023-Resources-eachBar">
                            <p className="homeRedesign2023-Resources-eachBar-desktop">For <span className="homeRedesign2023-Resource-boldText">Educators</span></p>
                            <p>→</p>
                        </div>
                    </a>
                    <a href="/resources-for-teens">
                        <div className="homeRedesign2023-Resources-eachBar">
                            <p className="homeRedesign2023-Resources-eachBar-desktop">For <span className="homeRedesign2023-Resource-boldText">Teens and Young Adults</span></p>
                            <p>→</p>
                        </div>
                    </a>
                </div>
            </div>
            <div className="homeRedesign2023-Resources-bars-mobile">
                <div className="homeRedesign2023-Resources-allBars">
                    <a href="/research">
                        <div className="homeRedesign2023-Resources-eachBar">
                            <p className="homeRedesign2023-Resources-eachBar-mobile"><span className="homeRedesign2023-Resource-star">★</span><span className="homeRedesign2023-Resource-boldText">Research</span></p>
                            <p>→</p>
                        </div>
                    </a>
                    <a href="/programs">
                        <div className="homeRedesign2023-Resources-eachBar">
                            <p className="homeRedesign2023-Resources-eachBar-mobile"><span className="homeRedesign2023-Resource-star">★</span><span className="homeRedesign2023-Resource-boldText"><span className="homeRedesign2023-Resource-overflowText">Education </span>Programs</span></p>
                            <p>→</p>
                        </div>
                    </a>
                    <a href="/philanthropy">
                        <div className="homeRedesign2023-Resources-eachBar">
                            <p className="homeRedesign2023-Resources-eachBar-mobile"><span className="homeRedesign2023-Resource-star">★</span><span className="homeRedesign2023-Resource-boldText">Philanthropy</span></p>
                            <p>→</p>
                        </div>
                    </a>
                </div>
            </div>
            <div className="homeRedesign2023-Resources-text-box">
                <div className="homeRedesign2023-Resources-text-intro">
                    <p>We are proud to share our research insights, educational programs, and learning and teaching tools <span className="homeRedesign2023-Resource-boldText">free of charge.</span></p>
                </div>
                <div className="homeRedesign2023-Resources-text-explore-desktop">
                    <a href="/resources">
                        <p>Explore all resources and publications</p>
                        <p>→</p>
                    </a>
                </div>
                <div className="homeRedesign2023-Resources-text-explore-mobile">
                    <p className="homeRedesign2023-Resource-boldText">Explore resources and publications:</p>
                    <div className="homeRedesign2023-Resources-text-explore-mobile-links">
                        <a href="/resources"><p className="homeRedesign2023-Resource-boldText">View All</p></a>
                        <a href="/research"><p>For <span className="homeRedesign2023-Resource-boldText">Researchers</span></p></a>
                        <a href="/resources-for-educators"><p>For <span className="homeRedesign2023-Resource-boldText">Educators</span></p></a>
                        <a href="/resources-for-teens"><p>For <span className="homeRedesign2023-Resource-boldText">Teens and Young Adults</span></p></a>
                    </div>
                </div>

            </div>
        </div>
      </div>
    );
  }
}