import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import CareersHeader from './Careers-Header';
import "./MarineScienceCareers.css";
import Navbar from "./Navbar";
// import Section from "./Section";
// import dummyText from "./DummyText";
import MarineScienceCareersDescription from './MarineScienceCareers-Description';
import MarineGeologist from './Careers/Marine-Geologist';
import BiologicalOceanographer from './Careers/Biological-Oceanographer';
import SharkResearch from './Careers/Shark-Research';
import VeterinaryMedicine from './Careers/Veterinary-Medicine';
import ScienceCommunication from './Careers/Science-Communication';
import ProfessionalMerfolk from './Careers/Professional-Merfolk';
import ArtDesign from './Careers/Art-Design';
import MarineBiology from './Careers/Marine-Biology';
import ScubaOperations from './Careers/Scuba-Operations';
import SubmersibleOperations from './Careers/Submersible-Operations';
import Academia from './Careers/Academia';
import EducationOutreach from './Careers/Education-Outreach';
import OceanEngineering from './Careers/Ocean-Engineering';
import OceanPolicy from './Careers/Ocean-Policy';
import NewFooter from '../../../pages-other/NewFooter';
class MarineScienceCareers extends Component {
  render() {
    return (
      <div className="App">
        <Helmet>
          <title>foundry10 Guide to Marine Science Careers</title>
          <meta
            name="title"
            content="foundry10 Guide to Marine Science Careers"
          />
          <meta
            name="description"
            content="Explore diverse careers in marine science, from sea turtle conservation to underwater robotics. Discover overviews, biographies, and interviews for ocean-related jobs."
          />
          <meta property="og:title" content="foundry10 Guide to Marine Science Careers" />
          <meta
            property="og:description"
            content="Explore diverse careers in marine science, from sea turtle conservation to underwater robotics. Discover overviews, biographies, and interviews for ocean-related jobs."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/marine-science-careers" />
          <link rel="canonical" href="https://www.foundry10.org/marine-science-careers" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Guide to Marine Science Careers",
                "description": "Explore diverse careers in marine science, from sea turtle conservation to underwater robotics. Discover overviews, biographies, and interviews for ocean-related jobs.",
                "url": "https://www.foundry10.org/marine-science-careers"
              }
            `}
          </script>
        </Helmet>
        <CareersHeader />
        <MarineScienceCareersDescription />
        <Navbar />
        <MarineGeologist
          dark={true}
          id="marineGeology"
        />
        <BiologicalOceanographer
          dark={true}
          id="biologicalOceanographer"
        />
        <SharkResearch
          dark={true}
          id="sharkResearch"
        />
        <VeterinaryMedicine
          dark={true}
          id="veterinaryMedicine"
        />
        <ScienceCommunication
          dark={true}
          id="scienceCommunication"
        />
        <ProfessionalMerfolk
          dark={true}
          id="professionalMerfolk"
        />
        <OceanPolicy 
          dark={true}
          id="oceanPolicy"
        />
        <ArtDesign 
          dark={true}
          id="artDesign"
        />
        <MarineBiology 
          dark={true}
          id="marineBiology"
        />
        <ScubaOperations 
          dark={true}
          id="scubaOperations"
        />
        <SubmersibleOperations 
          dark={true}
          id="submersibleOperations"
        />
        <Academia 
          dark={true}
          id="academia"
        />
        <EducationOutreach 
          dark={true}
          id="educationOutreach"
        />
        <OceanEngineering 
          dark={true}
          id="oceanEngineering"
        />
        {/* <OceanPolicy 
          dark={true}
          id="oceanPolicy"
        /> */}
        {/* <Section
          title="Section 2"
          subtitle={dummyText}
          dark={false}
          id="section2"
        />
        <Section
          title="Section 3"
          subtitle={dummyText}
          dark={true}
          id="section3"
        />
        <Section
          title="Section 4"
          subtitle={dummyText}
          dark={false}
          id="section4"
        />
        <Section
          title="Section 5"
          subtitle={dummyText}
          dark={true}
          id="section5"
        /> */}
        <NewFooter />
      </div>
    );
  }
}

export default MarineScienceCareers;