import React, { Component } from 'react';
import './MarineScienceCareers-Description.css';

export default class MarineScienceCareersDescription extends Component {
  render() {
    return(
      <div id="marineScienceCareers-description-container">
        <div class="marineScienceCareers-description-content-container">
          <p>Whether you dream of swimming with sea turtles, operating underwater robots, or promoting ocean conservation as a professional mermaid, there is a career in marine science for you. Here we have collected overviews, biographies, interviews, and resource links to a wide range of amazing ocean-related jobs for you to explore.</p>
          <p>→ Educators, check out this customizable activity sheet to guide your learners - available in <a href="https://docs.google.com/drawings/d/1uuqUqCA7EWV_GB9BszEGChgn0StPwuIQ_zQmoILkEDI/edit" target="_blank" rel="noopener noreferrer">English</a> as well as <a href="https://docs.google.com/drawings/d/1TdWPb6rzBsMstd-3GXEIjAsfOu8eiAQKd2eRhbGEqCg/edit" target="_blank" rel="noopener noreferrer">Spanish</a>.</p>
        </div>
      </div>
    );
  }
}