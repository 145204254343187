import React, { Component } from 'react';
import AnnualReportInternshipsOverview from './annualReport2021Internships-Overview';
import AnnualReportInternshipsSpotlights from './annualReport2021Internships-Spotlights';
import AnnualReport2021ProgramLinks from '../annualReport2021ProgramLinks/AnnualReport2021-ProgramLinks';
import NewFooter from '../../../../pages-other/NewFooter';
import './annualReport2021Internships-Content.css';

export default class AnnualReportInternshipsContent extends Component {
  render() {
    return(
      <div id="annualReport2021Internships-content-container">
        <AnnualReportInternshipsOverview />
        <AnnualReportInternshipsSpotlights />
        <AnnualReport2021ProgramLinks />
        <NewFooter />
      </div>
    );
  }
}