import React, { Component } from 'react';
import './workshopsLandingPage-Description.css';

export default class WorkshopsLandingPageDescription extends Component {
  render() {
    return(
      <div id="workshopsLandingPage-description-container">
        <div class="workshopsLandingPage-description-content-container">
        
          <h3>
          Why One-Time STEM Workshops?
          </h3>

          <p>foundry10 STEM believes all students should have access to free, high-quality STEM activities. We know that educators work tirelessly to provide new and innovative STEM programs for their youth, but not all teachers may have the capacity, confidence, or knowledge to deliver STEM activities that meet both the educators’ needs and students’ interests. That’s where foundry10 STEM can help! We work directly with teachers and students to understand their STEM interests and goals, and we collaborate to develop solutions that work for the entire community. By providing new STEM offerings within already existing programs, foundry10 STEM can help equip teachers with new lesson plans and teaching tools while continuing to excite and inspire students in the world of STEM.</p>

          <h3>
          What are we currently doing?
          </h3>

          <p>foundry10 provides one-time STEM workshops that meet the needs of both students and teachers. Through collaborative planning, the foundry10 STEM team works with schools and community organizations to design and/or facilitate workshops encompassing a variety of STEM subjects, from learning mathematics through robotics (e.g. Ozobots, Lego EV3s, etc.) to exploring NASA engineering though unplugged coding to learning about chemical engineering through a Harry Potter-themed Potions class. In order to support the needs of communities, foundry10 can collaborate in numerous ways, such as building curriculum, assisting with planning and logistics, providing materials and equipment, and/or arranging instructors. </p>

          <p>Interested in learning more? Contact us at <a href="mailto:STEM@foundry10.org" target="_blank" rel="noopener noreferrer">STEM@foundry10.org</a></p>

          <h3>
          What have we learned so far?
          </h3>

          <p>
          During our one-time workshops, we have benefited from listening to and learning from teachers, community educators and parents about how they view STEM activities. This has helped us understand some of the many barriers educators face to implementing STEM programming. For example, we often meet elementary school teachers or children’s librarians who have never taught a STEM activity or don’t know how to push their STEM lessons further. Two ways we have seen apprehensive educators overcome their discomfort or lack of confidence are:
            <ol>
              <li>
              Having a foundry10 STEM instructor model facilitating the activity and then stay in the room to support the teacher. 
              </li>
              <li>
              Sharing low cost or no cost resources and activities that are specifically designed for educators with little STEM experience. 
              </li>
            </ol>
            We have also spoken with parents and caregivers about what they are looking for in one-time STEM workshops for their elementary-aged children. We heard that families are accessing summer STEM activities because their students expressed interest in the STEM topic. For example, kids passionate about space were eager to sign up for “Mars Rover Races”, an activity that tasked youth participants to be NASA engineers and successfully guide a Mars rover through an obstacle course. We also learned that families were seeking out opportunities for their child to interact and collaborate with other children. Communicating with families about how collaborative and team-oriented STEM activities are can encourage them to seek out more STEM programming for their children.
          </p>
        </div>
      </div>
    );
  }
}