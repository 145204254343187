import React from "react";
import './Submersible-Operations.css';

export default function SubmersibleOperations({ title, subtitle, dark, id }) {
  return (
    // <div className={"section" + (dark ? " section-dark" : "")}>
    <div className="submersibleOperations-container">
      <div className="submersibleOperations-content-container" id={id}>
        <div className="submersibleOperations-final-content-container">
          <div className="submersibleOperations-illustration">
            <h1>Click below to learn more!</h1>
            <a class="submersibleOperations-link" href="/marine-science-careers/submersible-operations">
              <img src='https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Illustrations+2/submersiveoperations1.png' alt='submersible operations illustration'/>
              <p class="submersibleOperations-title">Meet Marc!</p>
              <div class="submersibleOperations-cards-overlay"></div>
            </a>
          </div>
          <div className="submersibleOperations-description">
              <h2>"Underwater Aviator"</h2>
              <p>RELATED TITLES: Engineer, Deep Sea Explorer, ROV Pilot</p>
              <p>Ever wanted to fly underwater and see the ocean with only a piece of plexiglass separating you from thousands of pounds of pressure? Brush up on your hydraulics knowledge, pneumatics expertise, and joy-stick hand-eye coordination and you too can be a submersible pilot!</p>
            <div className="submersibleOperations-requirements">
              <h2>Basic Degree/Training Requirements</h2>
              <ul className="submersibleOperations-iconList">
                <li>
                  <div class="submersibleOperations-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/white-HS-Diploma.png" alt="high school diploma" />
                  </div>
                </li>
                <li>
                  <div class="submersibleOperations-icon">
                    <img src="https://f10-programs.s3-us-west-2.amazonaws.com/marine-science/Marine+Science+Careers/Icons/white-Undergrad-Cap.png" alt="undergrad degree" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}