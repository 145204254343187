import React, { Component } from 'react';
import './creativityDesignPlay-Team.css';

export default class CreativityDesignPlayTeam extends Component {
  render() {
    return (
      <div className="creativityDesignPlayTeam-container">
        <div className="creativityDesignPlayTeam-content-container">
          <h2 className="creativityDesignPlayTeam-title">Meet the Team Leading This Work</h2>
          {/* <h2>Current Lab Members</h2> */}
          {/* <h2 className="creativityDesignPlayEducationTeam-titleContent-container"><span>Meet the Team Leading This Work</span></h2> */}
          <div class="creativityDesignPlayTeam-paragraph">
            {/* <h2>To put it simply, conceptual development means 'learning'</h2> */}
            <p>In today’s complex and fast-paced world, creativity and critical-thinking skills are essential for success in nearly every area of life. Our team seeks to ensure young people have the space and tools they need to innovate, build creative abilities, and learn through play—both inside and outside of classrooms. Centering youth curiosity and self-directed learning, we research and help provide education opportunities across a wide range of disciplines, including visual and performing arts, design thinking, and gaming.</p>
          </div>
          <ul className="creativityDesignPlayTeam-cardsList">
            <li>
              <a href="/about/team/rachel-gaudry" className="creativityDesignPlayTeam-linkNew">
                <div className="creativityDesignPlayTeam-cardNew">
                  <div className="creativityDesignPlayTeam-card-imageNew">
                    <img src='https://f10-people.s3.us-west-2.amazonaws.com/rachel-gaudry-headshot.jpg' width="100%" alt="young woman in cream sweater smiling"/>
                  </div>
                  <div className="creativityDesignPlayTeam-card-contentNew">   
                    <h2>Rachel Gaudry</h2>
                    <p>Team Lead</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="/about/team/meredith-berlin" className="creativityDesignPlayTeam-linkNew">
                <div className="creativityDesignPlayTeam-cardNew">
                  <div className="creativityDesignPlayTeam-card-imageNew">
                    <img src='https://s3.us-west-2.amazonaws.com/craft-images/Team/Headshots/Meredith-Teams-Page-Headshot.jpg' width="100%" alt="woman wearing glasses"/>
                  </div>
                  <div className="creativityDesignPlayTeam-card-contentNew">   
                    <h2>Meredith Berlin</h2>
                    <p>Program Developer</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="/about/team/kate-falconer" className="creativityDesignPlayTeam-linkNew">
                <div className="creativityDesignPlayTeam-cardNew">
                  <div className="creativityDesignPlayTeam-card-imageNew">
                    <img src='https://s3.us-west-2.amazonaws.com/craft-images/Team/Headshots/Team-Headshot-Template.png' width="100%" alt="woman in black shirt smiling"/>
                  </div>
                  <div className="creativityDesignPlayTeam-card-contentNew">   
                    <h2>Kate Falconer</h2>
                    <p>Program Developer</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="/about/team/dominic-gibson" className="creativityDesignPlayTeam-linkNew">
                <div className="creativityDesignPlayTeam-cardNew">
                  <div className="creativityDesignPlayTeam-card-imageNew">
                    <img src='https://craft-images.s3.us-west-2.amazonaws.com/Team/Headshots/Staff-Headshot_Dominic-Gibson_2.png' width="100%" alt="young man in grey shirt smiling"/>
                  </div>
                  <div className="creativityDesignPlayTeam-card-contentNew">   
                    <h2>Dominic Gibson</h2>
                    <p>Senior Researcher</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="/about/team/mikka-hoffman" className="creativityDesignPlayTeam-linkNew">
                <div className="creativityDesignPlayTeam-cardNew">
                  <div className="creativityDesignPlayTeam-card-imageNew">
                    <img src='https://craft-images.s3.us-west-2.amazonaws.com/Team/Headshots/Staff-Headshot-Mikka-Hoffman-2024.png' width="100%" alt="young woman in green cardigan smiling"/>
                  </div>
                  <div className="creativityDesignPlayTeam-card-contentNew">   
                    <h2>Mikka Hoffman</h2>
                    <p>Research Coordinator</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="/about/team/jae-eun-kim" className="creativityDesignPlayTeam-linkNew">
                <div className="creativityDesignPlayTeam-cardNew">
                  <div className="creativityDesignPlayTeam-card-imageNew">
                    <img src='https://s3.us-west-2.amazonaws.com/craft-images/Team/Headshots/Staff-Headshot_Jae.jpg' width="100%" alt="smiling person in striped blazer and tie"/>
                  </div>
                  <div className="creativityDesignPlayTeam-card-contentNew">   
                    <h2>Jae Eun Kim</h2>
                    <p>Program Developer</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="/about/team/allie-tung" className="creativityDesignPlayTeam-linkNew">
                <div className="creativityDesignPlayTeam-cardNew">
                  <div className="creativityDesignPlayTeam-card-imageNew">
                    <img src='https://craft-images.s3.us-west-2.amazonaws.com/Team/Headshots/Staff-Headshot-Allie-Tung-2024.png' width="100%" alt="young woman in grey shirt smiling"/>
                  </div>
                  <div className="creativityDesignPlayTeam-card-contentNew">   
                    <h2>Allie Tung</h2>
                    <p>Assistant Researcher</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="/about/team/travis-willingham-windleharth" className="creativityDesignPlayTeam-linkNew">
                <div className="creativityDesignPlayTeam-cardNew">
                  <div className="creativityDesignPlayTeam-card-imageNew">
                    <img src='https://craft-images.s3.us-west-2.amazonaws.com/Team/Headshots/Staff-Headshot-Travis-Willingham-Windleharth-2024.png' width="100%" alt="mature man in orange shirt smiling"/>
                  </div>
                  <div className="creativityDesignPlayTeam-card-contentNew">   
                    <h2>Travis Willingham Windleharth</h2>
                    <p>Senior Researcher</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="/about/team/suzanne-townsend" className="creativityDesignPlayTeam-linkNew">
                <div className="creativityDesignPlayTeam-cardNew">
                  <div className="creativityDesignPlayTeam-card-imageNew">
                    <img src='https://s3.us-west-2.amazonaws.com/craft-images/Team/Headshots/Staff-Headshot_Suzanne-Townsend.png' width="100%" alt="young woman in grey blouse smiling"/>
                  </div>
                  <div className="creativityDesignPlayTeam-card-contentNew">   
                    <h2>Suzanne Townsend</h2>
                    <p>Research Administrative Assistant</p>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}