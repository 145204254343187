import React, { Component } from 'react';
import AnnualReportProgramsOverview from './annualReport2021Programs-Overview';
import AnnualReportProgramsHighlights from './annualReport2021Programs-Highlights';
import AnnualReportLinks from '../annualReport2021_Components/10_Links/AnnualReport-Links';
import NewFooter from '../../../pages-other/NewFooter';
import './annualReport2021Programs-Content.css';

export default class AnnualReportProgramsContent extends Component {
  render() {
    return(
      <div id="annualReport2021programs-content-container">
        <AnnualReportProgramsOverview />
        <AnnualReportProgramsHighlights />
        <AnnualReportLinks />
        <NewFooter />
      </div>
    );
  }
}