import React, { Component } from 'react';
import './annualReport2021MarineScience-Overview.css';

export default class AnnualReportMarineScienceOverview extends Component {
  render() {
    return(
      <div id="annualReport2021MarineScience-overview-container">
          <div className="annualReport2021MarineScience-overview-text-container">
            <h1>Marine Science Team</h1>
            <p>
            The Marine Science Team was formed in late 2019 and faced the immediate challenge of developing programs during the COVID-19 pandemic.  In lieu of in-person programs, we focused on growing our team, developing meaningful partnerships, and bringing together ocean professionals to explore the role that marine science can play in education.  From an inclusive online career guide to a novel ocean-themed role playing game, we have probed the potential marine science has to spark learning in diverse formats and are excited to share this work with our community.
            </p>
          </div>
      </div>
    );
  }
}