import React, { Component } from 'react';
import './careerConnectedLearningLifeSkills-Contact.css';

export default class CareerConnectedLearningLifeSkillsContact extends Component {
  render() {
    return(
      <div id="careerConnectedLearningLifeSkillsContact-container">
        <div class="careerConnectedLearningLifeSkillsContact-content-container">
          {/* GET IN TOUCH */}
          <div id="careerConnectedLearningLifeSkillsContact-getInTouch-container">
            <div className="careerConnectedLearningLifeSkillsContact-getInTouch-content-container">
              <h2 className="careerConnectedLearningLifeSkillsContact-title">Get in Touch!</h2>
              {/* <h2 className="careerConnectedLearningLifeSkillsContact-getInTouch-titleContent-container"><span>Get in Touch!</span></h2> */}
              <div class="careerConnectedLearningLifeSkillsContact-paragraph">
            {/* <h2>To put it simply, conceptual development means 'learning'</h2> */}
            <p>Have questions about foundry10 research, programs, or potential partnerships related to this Interest Area?</p>
          </div>
          <a href="mailto:handa@foundry10.org">
            <div className="careerConnectedLearningLifeSkillsContact-button">
                Email The Interest Area Team Lead           
                {/* <i class="fa-solid fa-arrow-right-long"></i> */}
            </div>
          </a>
                {/* <div className="careerConnectedLearningLifeSkillsContact-getInTouch-grid-container">
                  <div className="careerConnectedLearningLifeSkillsContact-getInTouch-image-container">
                    <img src="https://f10-people.s3.us-west-2.amazonaws.com/jae-eun-kim-programs-landing.jpeg" alt="senior research dominic"/>
                  </div>
                    <div className="careerConnectedLearningLifeSkillsContact-getInTouch-text">
                      <h2>Have questions about previous/upcoming foundry10 programs or potential partnerships?</h2>
                      <p>Email our Program Admin team at <a href="mailto:programs@foundry10.org" style={{ color: '#FFFFFF' }} target="_blank" rel="noopener noreferrer">programs@foundry10.org</a>.</p>
                    </div>
                </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}