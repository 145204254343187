import React, { Component } from 'react';
import ResourcesLandingPageDescription from './resourcesLandingPage-Description';
// import ResourcesLandingPageWebinars from './resourcesLandingPage-Webinars';
import ResourcesLandingPageComics from './resourcesLandingPage-Comics';
import ResourcesLandingPagePuget from './resourcesLandingPage-Puget';
import ResourcesLandingPageSalish from './resourcesLandingPage-Salish';
import ResourcesLandingPageROV from './resourcesLandingPage-ROV';
import NewFooter from '../../../pages-other/NewFooter';
import './resourcesLandingPage-Content.css';

export default class ResourcesLandingPageContent extends Component {
  render() {
    return(
      <div id="resourcesLandingPage-content-container">
        <ResourcesLandingPageDescription />
        {/* <ResourcesLandingPageWebinars /> */}
        <ResourcesLandingPageComics />
        <ResourcesLandingPagePuget />
        <ResourcesLandingPageSalish />
        <ResourcesLandingPageROV />
        <NewFooter />
      </div>
    );
  }
}