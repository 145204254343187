import React, { Component } from 'react';
import './researcherResources-Publications.css';

export default class ResearcherResourcesPublications extends Component {
  render() {
    return(
      <div id="researcherResources-publications-container">
        <div className="researcherResources-publications-content-container">
          <h2 className="researcherResources-publications-title"><span>Featured Publications & Resources</span></h2>
          <div className="researcherResources-publications-card-container">
            <div className="researcherResources-publications-grid-container">
              <div className="researcherResources-publications-image-container">
                <img src="https://f10-resources.s3.us-west-2.amazonaws.com/Resources+for+Researchers/To+Triumph+or+to+Socialize.png" alt="cover page of high school interns report"/>
              </div>
              <div className="researcherResources-publications-info-container"> 
                <div className="researcherResources-publications-tags">
                  <div className="researcherResources-publications-hash">
                    <div className="researcherHash-teal">WHITE PAPER</div>
                    {/* <div className="researcherHash-hash">#Drama</div> */}
                  </div>
                </div>
                <div className="researcherResources-publications-text">
                  <h2>To Triumph or to Socialize? The Role of Gaming Motivations in Multiplayer Online Battle Arena Gameplay Preferences</h2>
                  <p>We examined the gameplay preferences of multiplayer online battle arena (MOBA) players and connections to individual differences in video gaming motivations and psychological factors related to social interaction behaviors.</p>
                </div>
                <div className="researcherResources-publications-link">
                  {/* <p>Dec 7th, 2021</p> */}
                  <a href='https://www.foundry10.org/research/to-triumph-or-to-socialize-the-role-of-gaming-motivations-in-multiplayer-online-battle-arena-gameplay-preferences' className="researcherResources-publications-content-link" target="_blank" rel="noopener noreferrer"><i class="fa-solid fa-arrow-right"></i></a>
                </div>
              </div>
            </div>
          </div>
          <div className="researcherResources-publications-card-container">
            <div className="researcherResources-publications-grid-container">
              <div className="researcherResources-publications-image-container">
                <img src="https://f10-resources.s3.us-west-2.amazonaws.com/Resources+for+Researchers/Sensors+Students+and+Self.jpeg" alt="a collage of book covers for middle school"/>
              </div>
              <div className="researcherResources-publications-info-container"> 
                <div className="researcherResources-publications-tags">
                  <div className="researcherResources-publications-hash">
                    <div className="researcherHash-teal">ARTICLE</div>
                    {/* <div className="researcherHash-hash">#Drama</div> */}
                  </div>
                </div>
                <div className="researcherResources-publications-text">
                  <h2>Sensors, Students, and Self: Exploring Knowledge, Self-Efficacy, and Interest Impact of Ocean Sensor Learning</h2>
                  <p>The background of this work consists of prior research on student learning with sensors and is situated alongside the body of work exploring the efficacy of teaching marine and environmental science with sensor technology.</p>
                </div>
                <div className="researcherResources-publications-link">
                  {/* <p>Dec 7th, 2021</p> */}
                  <a href='https://www.foundry10.org/research/sensors-students-and-self' className="researcherResources-publications-content-link" target="_blank" rel="noopener noreferrer"><i class="fa-solid fa-arrow-right"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}