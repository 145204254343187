import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player';
import './longBeach.css';
import NewFooter from '../../../pages-other/NewFooter';

export default class LongBeach extends Component {
  render() {
    return(
        <div id="programProject-main-container">
        <Helmet>
          <title>foundry10 Automotive Education Trip: Long Beach</title>
          <meta
            name="title"
            content="foundry10 Automotive Education Trip: Long Beach"
          />
          <meta
            name="description"
            content="Explore an automotive trip for youth to the Long Beach Formula Drift. Discover how students benefit from technology exposure and consider career pathways."
          />
          <meta property="og:title" content="foundry10 Automotive Education Trip: Long Beach" />
          <meta
            property="og:description"
            content="Explore an automotive trip for youth to the Long Beach Formula Drift. Discover how students benefit from technology exposure and consider career pathways."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/automotive-trips/long-beach" />
          <link rel="canonical" href="https://www.foundry10.org/automotive-trips/long-beach" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Automotive Education Trip: Long Beach",
                "description": "Explore an automotive trip for youth to the Long Beach Formula Drift. Discover how students benefit from technology exposure and consider career pathways.",
                "url": "https://www.foundry10.org/automotive-trips/long-beach"
              }
            `}
          </script>
        </Helmet>
        {/* HEADER */}
        <div class="programProject-header-container-longBeach">
          <div class="programProject-header-content-container">
            <h1>Long Beach Formula Drift</h1>
          </div>
        </div>
        {/* BODY */}
        <div id="programProject-main-content-container">
          {/* DESCRIPTION */}
          <div id="vrLandingPage-description-container-longBeach">
            <div class="vrLandingPage-description-content-container-longBeach">
              <p>Students have repeatedly expressed much fascination with drifting when we’ve talked with them, so we knew we needed to find a way to get them up close. The Streets of Long Beach race appealed to us because the public roads of Long Beach actually become the race track. One of the teachers we worked with had met the president of Formula Drift, Jim Liaw, so we reached out to him in the hopes of providing the students with a special experience. Jim ensured there were a myriad of opportunities for the students to talk with drivers, techs, tire pros, managers and engineers. The more grassroots nature of Formula Drift was especially appealing to the students.</p>
              <p>After the races, we took students to the Petersen Automotive Museum in Los Angeles. A private tour was arranged and students were allowed into The Vault, which is a special area of the museum reserved for the most unique and classic vehicles. It was a great opportunity to contrast the more modern-day drift cars with a collection of very rare and historically significant automobiles.</p>
            </div>
          </div>
          {/* VIDEO */}
          <div id="programProject-video-container-autoTrips">
            <div class="programProject-video-content-container-autoTrips">
              <div class="videoCard-container">
                <ReactPlayer
                url='https://youtu.be/8760WKuYlzo'
                className='react-player'
                //   playing
                controls
                width='100%'
                height='100%'
                />
              </div>
            </div>
          </div>
          <NewFooter />
        </div>
      </div>
    );
  }
}