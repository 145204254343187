import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import AnnualReportProgramsHeader from './annualReport2021Programs-Header';
import AnnualReportProgramsContent from './annualReport2021Programs-Content';
import './annualReport2021Programs.css';

export default class AnnualReport2021Programs extends Component {
  render() {
    return(
      <div id="annualReport2021programs-container">
        <Helmet>
          <title>foundry10 Annual Report 2021-2022: Programs</title>
          <meta
            name="title"
            content="foundry10 Annual Report 2021-2022: Programs"
          />
          <meta
            name="description"
            content="Explore the foundry10 Annual Report 2021 for insights into our Programs work. Learn more about our efforts in research, programming, and philanthropy."
          />
          <meta property="og:title" content="foundry10 Annual Report 2021-2022: Programs" />
          <meta
            property="og:description"
            content="Explore the foundry10 Annual Report 2021 for insights into our Programs work. Learn more about our efforts in research, programming, and philanthropy."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/annual-report/2021/programs" />
          <link rel="canonical" href="https://www.foundry10.org/annual-report/2021/programs" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Annual Report 2021-2022: Programs",
                "description": "Explore the foundry10 Annual Report 2021 for insights into our Programs work. Learn more about our efforts in research, programming, and philanthropy.",
                "url": "https://www.foundry10.org/annual-report/2021/programs"
              }
            `}
          </script>
        </Helmet>
        <AnnualReportProgramsHeader />
        <AnnualReportProgramsContent />
      </div>
    );
  }
}