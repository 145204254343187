import React, { Component } from 'react';
import ResearcherResourcesHeader from './researcherResources-Header';
import ResearcherResourcesContent from './researcherResources-Content';
import './researcherResources.css';

export default class ResearcherResources extends Component {
  render() {
    return(
      <div id="researcherResources-container">
        <ResearcherResourcesHeader />
        <ResearcherResourcesContent />
      </div>
    );
  }
}