import React, { Component } from 'react';
import MarineScienceCollaborationIntro from './marineScienceCollaboration-Intro';
import MarineScienceCollaborationBody from './marineScienceCollaboration-Body';
import NewFooter from '../../../pages-other/NewFooter';
import './marineScienceCollaboration-Content.css';

export default class MarineScienceCollaborationContent extends Component {
  render() {
    return(
      <div id="marineScienceCollaboration-content-container">
        <MarineScienceCollaborationIntro />
        <MarineScienceCollaborationBody />
        <NewFooter />
      </div>
    );
  }
}