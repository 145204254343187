import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PhilanthropyLandingPageHeader from './PhilanthropyLandingPage-Header';
import PhilanthropyLandingPageContent from './PhilanthropyLandingPage-Content';
import './PhilanthropyLandingPage.css';

export default class PhilanthropyLandingPage extends Component {
  render() {
    return(
      <div id="philanthropyLandingPage-container">
        <Helmet>
          <title>foundry10 Philanthropy: Our Approach and Impact Stories</title>
          <meta
            name="title"
            content="foundry10 Philanthropy: Our Approach and Impact Stories"
          />
          <meta
            name="description"
            content="Explore stories and insights from foundry10's work to expand learning opportunities for youth by removing barriers and building philanthropic partnerships."
          />
          <meta property="og:title" content="foundry10 Philanthropy: Our Approach and Impact Stories" />
          <meta
            property="og:description"
            content="Explore stories and insights from foundry10's work to expand learning opportunities for youth by removing barriers and building philanthropic partnerships."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/philanthropy" />
          <link rel="canonical" href="https://www.foundry10.org/philanthropy" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Philanthropy: Our Approach and Impact Stories",
                "description": "Explore stories and insights from foundry10's work to expand learning opportunities for youth by removing barriers and building philanthropic partnerships.",
                "url": "https://www.foundry10.org/philanthropy"
              }
            `}
          </script>
        </Helmet>
        <PhilanthropyLandingPageHeader />
        <PhilanthropyLandingPageContent />
      </div>
    );
  }
}