import React, { Component } from 'react';
// import ReturnToLearnIntro from './returnToLearn-Intro';
import ReturnToLearnDescription from './returnToLearn-Description';
import ReturnToLearnLinks from './returnToLearn-Links';
import NewFooter from '../../../../pages-other/NewFooter';
import './returnToLearn-Content.css';

export default class returnToLearnContent extends Component {
  render() {
    return(
      <div id="returnToLearn-content-container">
        {/* <ReturnToLearnIntro /> */}
        <ReturnToLearnDescription />
        <ReturnToLearnLinks />
        <NewFooter />
      </div>
    );
  }
}