import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import HealthWellBeingHeader from './healthWellBeing-Header';
import HealthWellBeingContent from './healthWellBeing-Content';
import './healthWellBeing.css';

export default class HealthWellBeing extends Component {
  render() {
    return(
      <div id="healthWellBeing-container">
        <Helmet>
          <title>foundry10 - Health and Well-Being</title>
          <meta
            name="title"
            content="foundry10 - Health and Well-Being"
          />
          <meta
            name="description"
            content="Discover ways to support youth's physical and mental well-being to help them learn and grow from foundry10 research, education programs, and philanthropy."
          />
          <meta property="og:title" content="foundry10 - Health and Well-Being" />
          <meta
            property="og:description"
            content="Discover ways to support youth's physical and mental well-being to help them learn and grow from foundry10 research, education programs, and philanthropy."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/interest-areas/health-and-well-being" />
          <link rel="canonical" href="https://www.foundry10.org/interest-areas/health-and-well-being" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 - Health and Well-Being",
                "description": "Discover ways to support youth's physical and mental well-being to help them learn and grow from foundry10 research, education programs, and philanthropy.",
                "url": "https://www.foundry10.org/interest-areas/health-and-well-being"
              }
            `}
          </script>
        </Helmet>
        <HealthWellBeingHeader />
        <HealthWellBeingContent />
      </div>
    );
  }
}