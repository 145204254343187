import React, { Component } from 'react';
// import clubSupportFormDocumentary from './clubSupportForm-Documentary';
// import clubSupportFormPerformances from './clubSupportForm-Performances';
import './clubSupportForm-Body.css';

export default class ClubSupportFormBody extends Component {
  render() {
    return(
      <div id="clubSupportFormBody-container">
        <div class="clubSupportFormBody-content-container">
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe_LXUhup9CqZGQvAVML5TvC9Nzaf2mbR3qfsIn9k0C1NVwuA/viewform?embedded=true" title="loading" width="100%" height="1200px" frameborder="0" allowfullscreen="" >Loading…</iframe>
        </div>
      </div>
    );
  }
}