import React, { Component } from 'react';
import './annualReport2021Interventions-Overview.css';

export default class AnnualReportInterventionsOverview extends Component {
  render() {
    return(
      <div id="annualReport2021Interventions-overview-container">
          <div className="annualReport2021Interventions-overview-text-container">
            <h1>Interventions Team</h1>
            <p>
            The Interventions team at foundry10 is focused on supporting educators and organizations who are working to provide supplemental support to students to improve their academic outcomes, attitudes towards learning, and develop their social-emotional (SEL) skills. As the pandemic shuttered schools and students transitioned to remote learning, we heard from our partners that there was an overwhelming need for at-home learning materials and social-emotional support for students of all ages, their families, and for educators themselves. With this in mind, the Interventions team intentionally worked with community partners to provide students with support in these areas. Over the past year and a half, we have partnered with local bookstores to run take-home books programs with schools and districts, supported funding for social-emotional programming with community-based organizations, and provided essential hygiene supplies to schools. We also ran a research study on students’ ability to self-regulate their learning during remote schooling at multiple schools in the Seattle area.
            </p>
          </div>
      </div>
    );
  }
}