import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import CreativityDesignPlayHeader from './creativityDesignPlay-Header';
import CreativityDesignPlayContent from './creativityDesignPlay-Content';
import './creativityDesignPlay.css';

export default class CreativityDesignPlay extends Component {
  render() {
    return(
      <div id="creativityDesignPlay-container">
        <Helmet>
          <title>foundry10 - Creativity, Design, and Play</title>
          <meta
            name="title"
            content="foundry10 - Creativity, Design, and Play"
          />
          <meta
            name="description"
            content="Discover how youth can learn through play to develop creativity and critical-thinking skills through our research, education programs, and philanthropy."
          />
          <meta property="og:title" content="foundry10 - Creativity, Design, and Play" />
          <meta
            property="og:description"
            content="Discover how youth can learn through play to develop creativity and critical-thinking skills through our research, education programs, and philanthropy."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/interest-areas/creativity-design-and-play" />
          <link rel="canonical" href="https://www.foundry10.org/interest-areas/creativity-design-and-play" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 - Creativity, Design, and Play",
                "description": "Discover how youth can learn through play to develop creativity and critical-thinking skills through our research, education programs, and philanthropy.",
                "url": "https://www.foundry10.org/interest-areas/creativity-design-and-play"
              }
            `}
          </script>
        </Helmet>
        <CreativityDesignPlayHeader />
        <CreativityDesignPlayContent />
      </div>
    );
  }
}