import React, { Component } from 'react';
import ResearcherResourcesIntro from './researcherResources-Intro';
// import ResearcherResourcesOpportunity from './researcherResources-Opportunity';
import ResearcherResourcesPublications from './researcherResources-Publications';
import ResearcherResourcesEmphasizedContent from './researcherResources-EmphasizedContent';
import ResearcherResourcesVideo from './researcherResources-Video';
import ResearcherResourcesStories from './researcherResources-Stories';
import ResearcherResourcesTestimonials from './researcherResources-Testimonials';
import NewFooter from '../../pages-other/NewFooter';
import './researcherResources-Content.css';

export default class ResearcherResourcesContent extends Component {
  render() {
    return(
      <div id="researcherResources-content-container">
        <ResearcherResourcesIntro />
        {/* <ResearcherResourcesOpportunity /> */}
        <ResearcherResourcesPublications />
        <ResearcherResourcesEmphasizedContent />
        <ResearcherResourcesVideo />
        <ResearcherResourcesStories />
        <ResearcherResourcesTestimonials />
        <NewFooter />
      </div>
    );
  }
}