import React, { Component } from 'react';
import AnnualReportGamesLearningOverview from './annualReport2021GamesLearning-Overview';
import AnnualReportGamesLearningSpotlights from './annualReport2021GamesLearning-Spotlights';
import AnnualReport2021ProgramLinks from '../annualReport2021ProgramLinks/AnnualReport2021-ProgramLinks';
import NewFooter from '../../../../pages-other/NewFooter';
import './annualReport2021GamesLearning-Content.css';

export default class AnnualReportGamesLearningContent extends Component {
  render() {
    return(
      <div id="annualReport2021GamesLearning-content-container">
        <AnnualReportGamesLearningOverview />
        <AnnualReportGamesLearningSpotlights />
        <AnnualReport2021ProgramLinks />
        <NewFooter />
      </div>
    );
  }
}