import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import CareerConnectedLearningLifeSkillsHeader from './careerConnectedLearningLifeSkills-Header';
import CareerConnectedLearningLifeSkillsContent from './careerConnectedLearningLifeSkills-Content';
import './careerConnectedLearningLifeSkills.css';

export default class CareerConnectedLearningLifeSkills extends Component {
  render() {
    return(
      <div id="careerConnectedLearningLifeSkills-container">
        <Helmet>
          <title>foundry10 - Career-Connected Learning and Life Skills</title>
          <meta
            name="title"
            content="foundry10 - Career-Connected Learning and Life Skills"
          />
          <meta
            name="description"
            content="Discover how to help young people prepare to thrive in their careers and adult lives. Explore resources from our research, education programs, and philanthropy."
          />
          <meta property="og:title" content="foundry10 - Career-Connected Learning and Life Skills" />
          <meta
            property="og:description"
            content="Discover how to help young people prepare to thrive in their careers and adult lives. Explore resources from our research, education programs, and philanthropy."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/interest-areas/career-connected-learning-and-life-skills" />
          <link rel="canonical" href="https://www.foundry10.org/interest-areas/career-connected-learning-and-life-skills" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 - Career-Connected Learning and Life Skills",
                "description": "Discover how to help young people prepare to thrive in their careers and adult lives. Explore resources from our research, education programs, and philanthropy.",
                "url": "https://www.foundry10.org/interest-areas/career-connected-learning-and-life-skills"
              }
            `}
          </script>
        </Helmet>
        <CareerConnectedLearningLifeSkillsHeader />
        <CareerConnectedLearningLifeSkillsContent />
      </div>
    );
  }
}