import React, { Component } from 'react';
import './annualReport2021Research-Overview.css';

export default class AnnualReportResearchOverview extends Component {
  render() {
    return(
      <div id="annualReport2021research-overview-container">
          <div className="annualReport2021research-overview-text-container">
            <h1>Education Research Overview</h1>
            <p>
            The Research team collaborates with foundry10 program developers and external partners to examine a range of learning processes and outcomes, <a href="https://www.foundry10.org/research" target="_blank" rel="noopener noreferrer">including social and cultural development, equity and access, technology and learning, and more.</a> We design our research to look at what creates the most value for youth and grow the public’s understanding of what is possible in learning. We conduct applied and experimental research in a variety of program interest areas, from dance to STEM to academic interventions.
              <br />
              <br />
            When COVID-19 hit Washington last spring, we paused to evaluate the role research could play during this time and how we could approach research to maximize value for youth and communities most impacted by the pandemic. Despite the challenges of doing education research during a pandemic, the Research team has been able to innovate by turning to research methods that can be done remotely, expanding into new areas of research like Marine Science and Financial Education, and doing research with community partners to investigate how we can best support students and families during COVID-19. Projects like the Gap Year study and the Self-Regulated Learning (SRL) study are examples of the timely, community responsive research that we focused on this year.
              <br />
              <br />
            Though our research and data collection methods shifted due to COVID-19, our goals and values remain the same. We still aim to use research to expand our understanding of education in these core research areas and to use those findings to develop meaningful learning opportunities that have direct value for youth — we just did it a little differently this year.
            </p>
          </div>
      </div>
    );
  }
}