import React, { Component } from 'react';
import './Program-Document.css';

export default class ProgramDocument extends Component {
  constructor(props) {
    super(props)
    this.state = {
      documentUrl: []
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      documentUrl: nextProps.all
    })
  }

  render() {
    // console.log(this.state.documentUrl);
    // console.log(this.state.documentUrl.length);
    if(this.state.documentUrl) {
      const documentCards = this.state.documentUrl.map((document, index) => (
        <li>
          <div class='programDocument-documentViewerHolder'>
            <a class='programDocument-documentViewerEmbed' 
              target={document.name} 
              href={document.url} 
              alt={document.name} 
            >
              <img class={document.name} alt={document.name} longdesc={document.name} src={document.type === 'doc' ? 'https://s3-us-west-2.amazonaws.com/f10-misc/doc-icon.png' : document.thumbnailUrl} width="130" height="200" />        
              <span class="programDocument-documentViewerTitleNew">
                <div class="programDocument-documentViewerTitleNew2">{document.name}</div>
              </span>
            </a>
          </div>
        </li>
      ));
        return(
            //   <div class="programDocument-cardsList2-container">
            //       <div class="programDocument-domain-title">
            //         <h2>Program Documents</h2>
            //       </div>
            //       <ul id="programDocument-cardsList2" class="interestArea-events-list2">
            //         {documentCards}
            //       </ul>
            //   </div>

            <div class="programDocument-cardsList2-container">
                <div class="programDocument-section-title">
                    <h2>Program Documents</h2>
                    <div class="programDocument-title-border"></div>
                </div>
                <ul id="programDocument-cardsList2" class="programDocument-events-list2">
                    {documentCards}
                </ul>
            </div>

        );
     } else {
      return (
        <p className='VisibilityOff'>

        </p>
      )
    }
  }
}