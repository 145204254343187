import React, { Component } from 'react';
import './annualReport2021ArtisticDesign-Spotlights.css';

export default class AnnualReportArtisticDesignSpotlights extends Component {
  render() {
    return(
      <div id="annualReport2021ArtisticDesign-spotlights-container">
        <div className="annualReport2021ArtisticDesign-spotlight-first-title-bar">
          <p>Spotlight: Everyday Unboxed</p>
        </div>
        <div className="annualReport2021ArtisticDesign-spotlights-content-container">
          <div className="annualReport2021ArtisticDesign-spotlights-text-container">
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Subpages/everyday-unboxed-online.png" alt="foundry10 presents everyday unboxed online art exhibition"/>
            <p>
            While our annual youth art gallery couldn’t be held in person this year, the team worked together with the local arts community to produce an online gallery and a series of educational events. We highlighted the work of 26 young artists during the pandemic through a streaming event produced and hosted by our team. Each youth spoke about their piece and what inspired them, and had the opportunity to share their work virtually with their peers, families and local arts organizations. We also did a small, exploratory study with the youth artists around self-worth and social media use. We found that seventy-five percent of the youth shared their art on social media, and that those who did reported higher self-esteem than participants who did not share their art. We were pleased to hold the virtual event and gain a better understanding of youth artists during this challenging time.
            <br /> <br />
            <a href="https://www.youtube.com/watch?v=UUM0tly_0Yo&t=198s" target="_blank" rel="noopener noreferrer">Everyday Unboxed Voices Video</a> - <a href="https://everydayunboxed2021.format.com/" target="_blank" rel="noopener noreferrer">Everyday Unboxed Website</a>
            </p>
          </div>
        </div>
        <div className="annualReport2021ArtisticDesign-spotlight-second-title-bar">
          <p>Spotlight: Philanthropic Support for Art Programs Serving Marginalized Youth</p>
        </div>
        <div className="annualReport2021ArtisticDesign-spotlights-content-container">
          <div className="annualReport2021ArtisticDesign-spotlights-text-container">
            <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Annual+Report/2021/Programs+Subpages/ad1.jpg" alt="artist at work in orange teal and tan"/>
            <p>Another expansion for the team this year was a shift toward more equitable philanthropic support of the visual arts. Our philanthropic donations were targeted to meet the needs of Black and Brown communities and those that have been disproportionately affected by COVID-19. This year, we provided funding to Denny Middle School, Mountlake Terrace Elementary, University District Youth Center, El Centro De La Raza, Roots Youth Shelter, and Vera Project. Each and every one of these organizations and schools used our funding to help keep their art programs afloat.</p>
          </div>
        </div>
      </div>
    );
  }
}