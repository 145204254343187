import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import MarineScienceCollaborationHeader from './marineScienceCollaboration-Header';
import MarineScienceCollaborationContent from './marineScienceCollaboration-Content';
import './marineScienceCollaboration.css';

export default class MarineScienceCollaboration extends Component {
  render() {
    return(
      <div id="marineScienceCollaboration-container">
        <Helmet>
          <title>foundry10 Marine Science and Technology Collaboration</title>
          <meta
            name="title"
            content="foundry10 Marine Science and Technology Collaboration"
          />
          <meta
            name="description"
            content="Empowering youth to take environmental action through through marine science. Explore programs, research, and philanthropy making a difference in communities."
          />
          <meta property="og:title" content="foundry10 Marine Science and Technology Collaboration" />
          <meta
            property="og:description"
            content="Empowering youth to take environmental action through through marine science. Explore programs, research, and philanthropy making a difference in communities."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/marine-science-collaboration" />
          <link rel="canonical" href="https://www.foundry10.org/marine-science-collaboration" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Marine Science and Technology Collaboration",
                "description": "Empowering youth to take environmental action through through marine science. Explore programs, research, and philanthropy making a difference in communities.",
                "url": "https://www.foundry10.org/marine-science-collaboration"
              }
            `}
          </script>
        </Helmet>
        <MarineScienceCollaborationHeader />
        <MarineScienceCollaborationContent />
      </div>
    );
  }
}