import React, { Component } from 'react';
import InsightDescription from './insight-Description';
import InsightLinks from './insight-Links';
import NewFooter from '../../../../pages-other/NewFooter';
import './insight-Content.css';

export default class InsightContent extends Component {
  render() {
    return(
      <div id="insight-content-container">
        <InsightDescription />
        <InsightLinks />
        <NewFooter />
      </div>
    );
  }
}