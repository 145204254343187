import React, { useMemo, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TreeFilter from "./TreeFilter";
import './style/archive-filter.css';

// Which filters do we need
const segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
const last_segment = segment_str.split("/").pop();

//let
const storyCatQuery = gql`
  query storyCatQuery {
    categories(relatedToEntries: { section: "stories" }, group: "categories") {
      categoryId: id
      title
      url
    }
  }
`;
const themeCatQuery = gql`
  query themeCatQuery {
    categories(group: "themes", level: 1) {
      categoryId: id
      title
      url
      children {
        categoryId: id
        title
        url
        level
      }
    }
  }
`;
const interestAreasCatQuery = gql`
  query interestAreasCatQuery {
    categories(group: "interestAreas") {
      categoryId: id
      title
      url
    }
  }
`;
const resourceCatQuery = gql`
  query resourceCatQuery {
    categories(
      relatedToEntries: { section: "resources" }
      group: "resourceType"
    ) {
      categoryId: id
      title
      url
    }
  }
`;
const opportunityCatQuery = gql`
  query resourceCatQuery {
    categories(
      relatedToEntries: { section: "opportunities" }
      group: "opportunityType"
    ) {
      categoryId: id
      title
      url
    }
  }
`;
const projectCatQuery = gql`
  query projectCatQuery {
    categories(
      relatedToEntries: { section: "projects" }
      group: "projectType"
    ) {
      categoryId: id
      title
      url
    }
  }
`;
const audienceCatQuery = gql`
  query audienceCatQuery {
    categories(group: "audiences") {
      categoryId: id
      title
      url
    }
  }
`;

const StoriesData = (gridData) => {
  const { data, loading, error } = useQuery(storyCatQuery);
  if (loading) {
    return <div>loading</div>;
  }
  if (error) {
    return <div>{error}</div>;
  }

  return data.categories.map((block, index) => {
    return (
      <li key={index}>
        <a href={`?cat=${block.categoryId}`}>{block.title}</a>
      </li>
    );
  });
};

const InterestAreasDataPress = (gridData) => {
  const { data, loading, error } = useQuery(interestAreasCatQuery);
  if (loading) {
    return <div>loading</div>;
  }
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <React.Fragment>
      {data.categories.map((block, index) => (
        <li key={index}>
          <a href={`?interestCat=${block.categoryId}`}>{block.title}</a>
        </li>
      ))}
    </React.Fragment>
  );
};

const ThemesData = () => {
  const { data, loading, error } = useQuery(themeCatQuery);
  if (loading) {
    return (
      <ul className="resourceDropDown-category-contents">
        <div>loading</div>
      </ul>
    )
  }

  if (error) {
    return <div className="resourceDropDown-category-contents">{error}</div>;
  }

  return (
    <div className="resourceDropDown-category-contents">
      {data.categories.map((block, index) => {
        return (
          <TreeFilter key={index} data={block} page={last_segment} />
        );
      })}
    </div>
  );
};

const InterestAreasData = (gridData) => {
  const { data, loading, error } = useQuery(interestAreasCatQuery);
  if (loading) {
    return <div>loading</div>;
  }
  if (error) {
    return <div>{error}</div>;
  }
  {
    console.log(data, "data");
  }
  return data.categories.map((block, index) => (
    <li key={index}>
      <a href={`?cat=${block.categoryId}`}>{block.title}</a>
    </li>
  ));
};

const AudiencesData = () => {
  const { data, loading, error } = useQuery(audienceCatQuery);
  if (loading) {
    return <div>loading</div>;
  }
  if (error) {
    return <div>{error}</div>;
  }
  {
    console.log(data, "data");
  }
  return data.categories.map((block, index) => (
    <li key={index}>
      <a href={`?cat=${block.categoryId}`} className="audiencesDataTest">{block.title}</a>
    </li>
  ));
};

const ResourceData = (gridData) => {
  const { data, loading, error } = useQuery(resourceCatQuery);
  if (loading) {
    return <div>loading</div>;
  }
  if (error) {
    return <div>{error}</div>;
  }

  return data.categories.map((block, index) => (
    <li key={index}>
      <a href={`?cat=${block.categoryId}`}>{block.title}</a>
    </li>
  ));
};

const OpportunitiesData = (gridData) => {
  const { data, loading, error } = useQuery(opportunityCatQuery);
  if (loading) {
    return <div>loading</div>;
  }
  if (error) {
    return <div>{error}</div>;
  }

  return data.categories.map((block, index) => (
    <div key={index}>
      <li>
        <a href={`?cat=${block.categoryId}`}>{block.title}</a>
      </li>
    </div>
  ));
};

const ProjectsData = (gridData) => {
  const { data, loading, error } = useQuery(projectCatQuery);
  if (loading) {
    return <div>loading</div>;
  }
  if (error) {
    return <div>{error}</div>;
  }

  return data.categories.map((block, index) => (
    <li key={index}>
      <a href={`?cat=${block.categoryId}`}>{block.title}</a>
    </li>
  ));
};

function StoriesFilter() {
  const [isStoriesActive, setStoriesActive] = useState(false);

  return (
    <div className="styledDropdown">
      <div
        className="resourceDropDown-category-title"
        onClick={() => setStoriesActive(!isStoriesActive)}
      >
        <h3>Story Type</h3>
        <i
          className={
            isStoriesActive ? `fa-solid fa-angle-up` : `fa-solid fa-angle-down`
          }
        ></i>
      </div>
      {isStoriesActive && (
        <ul className="resourceDropDown-category-contents">
          <StoriesData />
        </ul>
      )}
    </div>
  );
}

function ThemesFilter() {
  const [isThemeActive, setThemeActive] = useState(false);

  return (
    <div className="styledDropdown">
      <div
        className="resourceDropDown-category-title"
        onClick={() => setThemeActive(!isThemeActive)}
      >
        <h3>Interest Area / Theme</h3>
        <i
          className={
            isThemeActive ? `fa-solid fa-angle-up` : `fa-solid fa-angle-down`
          }
        ></i>
      </div>
      {isThemeActive && (
        <ThemesData />
      )}
    </div>
  );
}

function InterestAreasFilter() {
  const [isInterestAreasActive, setInterestAreasActive] = useState(false);

  return (
    <div className="styledDropdown">
      <div
        className="resourceDropDown-category-title"
        onClick={() => setInterestAreasActive(!isInterestAreasActive)}
      >
        <h3>Subject</h3>
        <i
          className={
            isInterestAreasActive
              ? `fa-solid fa-angle-up`
              : `fa-solid fa-angle-down`
          }
        ></i>
      </div>
      {isInterestAreasActive && (
        <ul className="resourceDropDown-interest-area-contents">
          {last_segment != "press" ? (
            <InterestAreasData />
          ) : (
            <InterestAreasDataPress />
          )}
        </ul>
      )}
    </div>
  );
}

function AudiencesFilter() {
  const [isActive, setActive] = useState(false);

  const toggle = () => {
    setActive((prev) => !prev);
  };

  return (
    <div className="styledDropdown">
      <div className="resourceDropDown-category-title" onClick={toggle}>
        <h3>Audience</h3>
        <i
          className={
            isActive ? `fa-solid fa-angle-up` : `fa-solid fa-angle-down`
          }
        ></i>
      </div>
      {isActive && (
        <ul className="resourceDropDown-interest-area-contents">
          <AudiencesData />
        </ul>
      )}
    </div>
  );
}

function ResourceFilter() {
  const [isResourceActive, setResourceActive] = useState(false);

  return (
    <div className="styledDropdown">
      <div
        className="resourceDropDown-category-title"
        onClick={() => setResourceActive(!isResourceActive)}
      >
        <h3>Resource Type</h3>
        <i
          className={
            isResourceActive ? `fa-solid fa-angle-up` : `fa-solid fa-angle-down`
          }
        ></i>
      </div>
      {isResourceActive && (
        <ul className="resourceDropDown-interest-area-contents">
          <ResourceData />
        </ul>
      )}
    </div>
  );
}

function OppotunitiesFilter() {
  const [isOpportunitiesActive, setOpportunitiesActive] = useState(false);

  return (
    <div className="styledDropdown">
      <div
        className="resourceDropDown-category-title"
        onClick={() => setOpportunitiesActive(!isOpportunitiesActive)}
      >
        <h3>Opportunity Type</h3>
        <i
          className={
            isOpportunitiesActive
              ? `fa-solid fa-angle-up`
              : `fa-solid fa-angle-down`
          }
        ></i>
      </div>
      {isOpportunitiesActive && (
        <ul className="resourceDropDown-interest-area-contents">
          <OpportunitiesData />
        </ul>
      )}
    </div>
  );
}

function ProjectsFilter() {
  const [isProjectsActive, setProjectsActive] = useState(false);

  return (
    <div className="styledDropdown">
      <div
        className="resourceDropDown-category-title"
        onClick={() => setProjectsActive(!isProjectsActive)}
      >
        <h3>Program/Project Type</h3>
        <i
          className={
            isProjectsActive ? `fa-solid fa-angle-up` : `fa-solid fa-angle-down`
          }
        ></i>
      </div>
      {isProjectsActive && (
        <ul className="resourceDropDown-interest-area-contents">
          <ProjectsData />
        </ul>
      )}
    </div>
  );
}

function ArchiveFilter(props) {
  const showSearchInput = true;
  const [
    showStories,
    showThemes,
    showInterestAreas,
    showOpportunityType,
    showResources,
    showProjects,
    showAudiences,
  ] = useMemo(() => {
    switch (last_segment) {
      case "stories":
        return [true, true, true, false, false, false, false];
      case "resources":
        return [false, true, true, false, true, false, false];
      case "projects":
        return [false, true, true, false, false, true, false];
      case "programs":
        return [false, true, true, false, false, false, true];
      case "opportunities":
        return [false, true, true, true, false, false, false];
      case "press":
        return [false, true, true, false, false, false, false];
      case "programs-projects":
        return [false, true, true, false, false, true, false];
      default:
        return [false, false, false, false, false, false, false];
    }
  }, [last_segment]);

  const [filter, setFilter] = useState("");

  const updateFilter = (event) => {
    setFilter(event.target.value);
  };

  const forwardValue = () => {
    // let test = {updateName}
    window.location.replace("?filter=" + filter);
  };

  return (
    <section id="archiveFilters">
      <div className="container">
        {showProjects && <ProjectsFilter />}
        {showOpportunityType && <OppotunitiesFilter />}
        {showStories && <StoriesFilter />}
        {showResources && <ResourceFilter />}
        {showThemes && <ThemesFilter />}
        {showInterestAreas && <InterestAreasFilter />}
        {showAudiences && <AudiencesFilter />}
        {/* {showSearchInput && (
          <Paper className="archiveSearch" elevation={1}>
            <InputBase
              placeholder="Search"
              value={filter}
              onChange={updateFilter}
            />
            <IconButton onClick={forwardValue} aria-label="Search">
              <SearchIcon />
            </IconButton>
          </Paper>
        )} */}
      </div>
      <div className="container">
      {showSearchInput && (
        <div className="styledDropdown">
          <Paper className="archiveSearch" elevation={1}>
            <InputBase
              placeholder="Search"
              value={filter}
              onChange={updateFilter}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  forwardValue();
                }
              }}
              inputProps={{ style: { color: '#707070', opacity: '1', fontSize: '20px' } }}
            />
            <IconButton onClick={forwardValue} aria-label="Search">
              <SearchIcon />
            </IconButton>
          </Paper>
          </div>
        )}
      </div>
    </section>
  );
}

export default ArchiveFilter;
