import React, { Component } from 'react';
import './technologyMediaInformationLiteracy-Header.css';

export default class TechnologyMediaInformationLiteracyHeader extends Component {
  render() {
    return(
      <div id="technologyMediaInformationLiteracy-header-container">
          <div class="technologyMediaInformationLiteracy-header-content-container">
            <h1>Technology, Media, and Information Literacy</h1>
          </div>
      </div>
    );
  }
}