import React, { Component } from 'react';
import ClubSupportFormBody from './clubSupportForm-Body';
import NewFooter from '../../../../../pages-other/NewFooter';
import './clubSupportForm-Content.css';

export default class ClubSupportFormContent extends Component {
  render() {
    return(
      <div id="clubSupportForm-content-container">
        <ClubSupportFormBody />
        <NewFooter />
      </div>
    );
  }
}