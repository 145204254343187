import React, { Component } from 'react';
import './tripsLandingPage-Intro.css';

export default class TripsLandingPageIntro extends Component {
  render() {
    return(
      <div id="tripsLandingPage-intro-container">
        <div class="tripsLandingPage-intro-content-container">
          <div class="tripsLandingPage-intro-bold">
            <h3>Outside the Shop: Automotive Trips for Education</h3>
          </div>
          <div class="tripsLandingPage-intro-normal">
            <p>Though students can learn a great deal about cars in their classrooms, to gain a broader understanding of what the industry has to offer, leaving the classroom is essential. Where might we take students? Ah, the places we can go!</p>
          </div>
        </div>
      </div>
    );
  }
}