import React, { Component } from 'react';
import CteEdIntro from './cteEd-Intro';
import CteEdBody from './cteEd-Body';
import NewFooter from '../../../pages-other/NewFooter';
import './cteEd-Content.css';

export default class CteEdContent extends Component {
  render() {
    return(
      <div id="cteEd-content-container">
        <CteEdIntro />
        <CteEdBody />
        <NewFooter />
      </div>
    );
  }
}