import React from "react";
import TreeView from "@material-ui/lab/TreeView";
import MuiTreeItem from '@material-ui/lab/TreeItem';
import { withStyles } from '@material-ui/styles';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const CustomTreeView = (props) => {
	const { data, page, expanded, selected, handleToggle } = props;

	const handleClick = (node) => {
		if(node.level > 1){
			window.location.href = `?${page != "press" ? "cat" : "themeCat"}=${node.categoryId}`
		}
	}

	const renderTree = (nodes) => {
		if (!nodes || nodes.length === 0) {
			return null;
		}
		return (
			<TreeItem
				key={nodes.categoryId}
				nodeId={nodes.categoryId}
				label={nodes.title}
				onLabelClick={()=>handleClick(nodes)}
			>
				{nodes.children && Array.isArray(nodes.children)
					? nodes.children.map((node) => renderTree(node))
					: null}
			</TreeItem>
		);
	};

	return (
		<TreeView
			defaultCollapseIcon={<ExpandMoreIcon />}
			defaultExpandIcon={<ChevronRightIcon />}
			expanded={expanded}
			selected={selected}
			onNodeToggle={handleToggle}
		>
			{renderTree(data)}
		</TreeView>
	);
};

export default CustomTreeView;

const TreeItem = withStyles({
	root: {
		'&.MuiTreeItem-root .MuiTreeItem-content .MuiTreeItem-label': {
			color: '#007a8a !important',
			fontSize: '20px',
			fontFamily: '"Roboto", sans-serif',
			fontWeight: 300,
			lineHeight: '1.5em',
			letterSpacing: 'none',
			backgroundColor: '#FFFFFF'
		},
		'&.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
			backgroundColor: 'uncut',
			fontWeight: 'bolder'
		},
		'&.MuiTreeItem-root .MuiTreeItem-content .MuiTreeItem-label:hover': {
			textDecoration: 'underline',
			fontWeight: 'bolder'
		}
	},
})(MuiTreeItem);

