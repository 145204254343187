import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player';
import './kansas.css';
import NewFooter from '../../../pages-other/NewFooter';

export default class Kansas extends Component {
  render() {
    return(
        <div id="programProject-main-container">
        <Helmet>
          <title>foundry10 Automotive Education Trip: Kansas</title>
          <meta
            name="title"
            content="foundry10 Automotive Education Trip: Kansas"
          />
          <meta
            name="description"
            content="Explore a youth automotive trip to the NHRA Nationals in Topeka, Kansas. Discover how students benefit from technology exposure and consider career pathways."
          />
          <meta property="og:title" content="foundry10 Automotive Education Trip: Kansas" />
          <meta
            property="og:description"
            content="Explore a youth automotive trip to the NHRA Nationals in Topeka, Kansas. Discover how students benefit from technology exposure and consider career pathways."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/automotive-trips/kansas" />
          <link rel="canonical" href="https://www.foundry10.org/automotive-trips/kansas" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Automotive Education Trip: Kansas",
                "description": "Explore a youth automotive trip to the NHRA Nationals in Topeka, Kansas. Discover how students benefit from technology exposure and consider career pathways.",
                "url": "https://www.foundry10.org/automotive-trips/kansas"
              }
            `}
          </script>
        </Helmet>
        {/* HEADER */}
        <div class="programProject-header-container-kansas">
          <div class="programProject-header-content-container">
            <h1>NHRA Nationals in Tokepa, Kansas</h1>
          </div>
        </div>
        {/* BODY */}
        <div id="programProject-main-content-container">
          {/* DESCRIPTION */}
          <div id="vrLandingPage-description-container-kansas">
            <div class="vrLandingPage-description-content-container-kansas">
              <p>This trip came about as a result of the connections a teacher had made from a drag racing project he was working on. One of the schools had recently built and was racing a dragster so it made sense to let students experience the rumble of the drags up close. Racing is a big deal in Topeka and they pulled out all the stops at this race. Not only did students see funny cars, stock cars, and top fuel categories, they also were able to see the nighttime races (where the burning exhaust really lights up the night) and even jet-fuel powered cars. Through the connections the teacher had students had special opportunities to talk with drivers, techs, engineers and many other experts. Additionally, they had special access to broadcasters and other professionals affiliated with the industry.</p>
              <p>Our special stop in Kansas included a visit to the original Harley Davidson Motorcycle shop where we saw some amazing bikes and learned about the history of the Harley. We also went to the Evil Knievel Museum. There students received a special tour and learned about the stunt side of motorcycles and the mechanical difficulties that entails.</p>
            </div>
          </div>
          {/* VIDEO */}
          <div id="programProject-video-container-autoTrips">
            <div class="programProject-video-content-container-autoTrips">
              <div class="videoCard-container">
                <ReactPlayer
                url='https://youtu.be/LOVu7694TJ8'
                className='react-player'
                //   playing
                controls
                width='100%'
                height='100%'
                />
              </div>
            </div>
          </div>
          <NewFooter />
        </div>
      </div>
    );
  }
}
