import React, { Component } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import './charts.css'

export default class BarChartRace extends Component {
  constructor(props) {
    super(props);

    this.state = {
    chartOptions: {
      chart: {
        type: 'bar'
    },
    title: {
        text: 'Race / Ethnicity of Survey Respondents'
    },
    subtitle: {
        text: 'Total number greater than 91 because some respondents  selected multiple categories.'
    },
    xAxis: {
        categories: ['Race / Ethnicity'],
        title: {
            text: null
        }
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Count',
            align: 'high'
        },
        labels: {
            overflow: 'justify'
        }
    },
    tooltip: {
        valueSuffix: ''
    },
    plotOptions: {
        bar: {
            dataLabels: {
                enabled: true
            }
        }
    },
    legend: {
        // layout: 'vertical',
        // align: 'right',
        // verticalAlign: 'top',
        // x: -40,
        // y: 80,
        // floating: true,
        // borderWidth: 2,
        // backgroundColor:
        //     Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
        // shadow: true
    },
    colors: ['#D14E1D','#00464F','#FFA75E','#575757','#007A8A'],
    credits: {
        enabled: false
    },
    series: [{
        name: 'White',
        data: [44]
    }, {
        name: 'Asian or Asian American',
        data: [22]
    }, {
        name: 'Black or African American',
        data: [6]
    }, {
        name: 'Hispanic/Latinx',
        data: [3]
    }, {
        name: 'Prefer Not to Respond',
        data: [4]
    }]
      }
    };
  }


  render() {
    const { chartOptions } = this.state;

    return (
      <div class="chart">
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
        />
      </div>
    )
  }
}
