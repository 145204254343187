import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import {
  useQuery,
  gql
} from "@apollo/client";
import PageHeader from '../../components/page-header.js';
import Grid from '@material-ui/core/Grid';
// import ContentBlocks from '../../components/ContentBlocks';
import TopContentBlocks from './topContentBlock.js';
import BottomContentBlocks from './bottomContentBlock.js';
import StoryCard from '../../components/storyCard';
import NewFooter from '../../../pages-other/NewFooter.js';
import '../../styles/pages/resourcesForYouth.css';


const QUERY = gql`
    query NewQuery {
        entry(slug: "resources-for-teens-and-young-adults") {
            ... on pages_default_Entry {
              title
              slug
              id
              blurb
              contentOptions {
                ... on contentOptions_standardContent_BlockType {
                  contentTitle
                  contentContent
                  linkUrl {
                    url
                    customText
                    target
                  }
                  contentAlignment
                }
                ... on contentOptions_icp_BlockType {
                  icpTitle
                  icpContent
                    icpImage {
                        url
                    }
                    icpImageCaption
                    icpPositionOfImage
                    icpBackgroundColor
                }
                ... on contentOptions_fwi_BlockType {
                  fwiTitle
                  fwiImage {
                      url
                  }
                  fwiCaption
                  fwiSize
                }
                ... on contentOptions_emphasized_BlockType {
                  emphasizedTitle
                  emphasizedSubheadline
                  emphasizedOverline
                  emphasizedImage {
                    url
                  }
                  emphasizedImageCaption
                  emphasizedContent
                  emphasizedLink
                  emphasizedBackgroundColor
                }
                ... on contentOptions_quote_BlockType {
                      pullQuote
                      quoteSocial
                      quoteImage {
                        url
                      }
                      quoteAttribution
                }
                ... on contentOptions_quoteSlideshow_BlockType {
                  quoteSliderTitle
                  quotes {
                    __typename  
                    ... on quotes_BlockType {
                      quotesQuote
                      quotesQuoteImage {
                        url
                      }
                      quotesQuoteAttribution
                    }
                  }
                }
                ... on contentOptions_slider_BlockType {
                  sliderTitle
                  slides {
                    __typename
                    ... on slides_BlockType {
                      slideImage {
                        url
                      }
                      slideHeadline
                      slideContent
                      slideLink {
                        customText
                        url
                        target
                      }
                    }
                  }
                }
                ... on contentOptions_list_BlockType {
                  listTitle
                  list {
                    __typename  
                    ... on list_BlockType {
                      listItemHeadline
                      listItemContent
                      listItemImage {
                        url
                      }
                      listItemLink {
                        url
                        customText
                        target
                      }
                    }
                  }
                }    
                ... on contentOptions_accordion_BlockType {
                  accordionTitle
                  accordion {
                    __typename  
                    ... on accordion_BlockType {
                      accordionItemTitle
                      accordionItemBlurb
                      accordionItemImage {
                        url
                      }
                      accordionItemIcon {
                        url
                      }
                      accordionItemContent
                    }
                  }
                }
                ... on contentOptions_linkList_BlockType {
                  linkListTitle
                  linkListList {
                    __typename  
                    ... on linkListList_BlockType {
                      linkListTitle
                      linkUrl
                      linkListBg {
                        url
                      }
                      linkListHoverTxt
                      linkListTitle
                    }
                  }
                  linkListDisplay
                  linkListSize
                  columnCount
                }
                ... on contentOptions_video_BlockType {
                  videoTitle
                  videoLink
                  videoCaption
                  videoImage {
                    url
                  }
                }
                ... on contentOptions_featuredResource_BlockType {
                  featuredResource {
                    title 
                    ... on resources_default_Entry {
                      blurb
                      slug
                      researchAsset {
                        url
                      }
                      interestAreaTeam {
                        ... on interestAreaTeam_BlockType {
                          interestAreaTeamMember {
                            title
                            url
                          }
                        }
                      }
                      researchExternalAuthors {
                        ... on researchExternalAuthors_BlockType {
                          researchExternalAuthorsAuthor
                        }
                      }
                      featuredImageCaption
                      thumbnailImage {
                        id
                        url
                      }
                    }
                  }
                }
                ... on contentOptions_featuredPerson_BlockType {
                  featuredPerson {
                    title 
                    url
                    ... on teamMembers_default_Entry {
                      teamMembersPronouns
                      teamMemberRoles {
                        title
                      }
                      teamMemberImage {
                        url
                      }
                      teamMemberPosition
                      teamMemberBio
                    }
                  }
                }
                ... on contentOptions_featuredStory_BlockType {
                  featuredstory {
                    title 
                    url
                    author {
                      fullName
                    }
                    dateCreated @formatDateTime (format: "M j, Y")
                    ... on stories_default_Entry {
                      blurb
                      featuredImage {
                        title
                        url
                      }
                      categories {
                        title
                      }
                      themes {
                        title
                      }
                      interestAreas {
                        title
                      }
                      researchExternalAuthors {
                        ... on researchExternalAuthors_BlockType {
                          researchExternalAuthorsAuthor
                        }
                      }
                      interestAreaTeam {
                        ... on interestAreaTeam_BlockType {
                          interestAreaTeamMember {
                            title
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
        }
  }
`


const teensStoriesQuery = gql`
    query researchQuery {
      entries(section: "stories", limit: 3, relatedToCategories: [{ id: 1292 }]) {
        title
        slug
        url
        author {
          fullName
        }
        postDate @formatDateTime (format: "M j, Y")
        ... on stories_default_Entry {
          researchExternalAuthors {
            ... on researchExternalAuthors_BlockType {
              researchExternalAuthorsAuthor
            }
          }
          interestAreaTeam {
            ... on interestAreaTeam_BlockType {
              interestAreaTeamMember {
                title
              }
            }
          }
          blurb
          featuredImage {
            url
          }  
          categories {
            title
          }
        }
      }
    }
`

const RecentStoriesForTeensCards = () => {
    const { data, loading, error } = useQuery(teensStoriesQuery);
    if (loading) return null;
    if (error) return `Error! ${error}`;

  console.log('Recent Stories for Teens Card Data', data);

  return (
    data.entries.map(
      (block) => {
        
        let props = {
          block: {block}
        }
        return (
          <Grid item xs={12} sm={4}>
            <StoryCard {...props} />
          </Grid>
        )
      }
    )
  )
}

const RecentStoriesForTeensBlock = () => {

    return (
      <section className='youthResourcesCraftStories'>
        <Helmet>
          <title>foundry10 Resources for Teens and Young Adults</title>
          <meta
            name="title"
            content="foundry10 Resources for Teens and Young Adults"
          />
          <meta
            name="description"
            content="Explore stories, resources, and opportunities from foundry10 for youth of all ages, from early learners, to tweens and teens, to young adults."
          />
          <meta property="og:title" content="foundry10 Resources for Teens and Young Adults" />
          <meta
            property="og:description"
            content="Explore stories, resources, and opportunities from foundry10 for youth of all ages, from early learners, to tweens and teens, to young adults."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.foundry10.org/resources-for-teens" />
          <link rel="canonical" href="https://www.foundry10.org/resources-for-teens" />
          {/* Structured Data Script Tag */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "foundry10 Resources for Teens and Young Adults",
                "description": "Explore stories, resources, and opportunities from foundry10 for youth of all ages, from early learners, to tweens and teens, to young adults.",
                "url": "https://www.foundry10.org/resources-for-teens"
              }
            `}
          </script>
        </Helmet>
        <div id="youthResources-craftStories-container">
            <div className="youthResources-craftStories-content-container">
                <h2 className="youthResources-craftStories-title"><span>Recent Stories for Teens and Young Adults</span></h2>
                <Grid container spacing={4}>
                    <RecentStoriesForTeensCards />
                </Grid>
                <a className="button orange" href="/stories?cat=1292">Explore more stories  <i class="fas fa-arrow-right"></i></a>
            </div>
        </div>
  
      </section>
    )
  }

const TopInlineContent = (slug) => {
  const { data, loading, error } = useQuery(QUERY, {
    variables: {slug}
  });
  if (loading) { return <div>loading</div> }
  if (error) { return <div>{error}</div> }

  {console.log('TopInline Content Data => Resources for Youth', data.entry)}
  return (
    <TopContentBlocks data={data.entry} />
  );
}

const BottomInlineContent = (slug) => {
  const { data, loading, error } = useQuery(QUERY, {
    variables: {slug}
  });
  if (loading) { return <div>loading</div> }
  if (error) { return <div>{error}</div> }

  {console.log('BottomInline Content Data => Resources for Youth', data.entry)}
  return (
    <BottomContentBlocks data={data.entry} />
  );
}



export default class ResourcesForYouth extends Component {
  render() {
    return(
        <section className='resourcesForYouth'>
            <PageHeader slug={this.props.slug} />
            <TopInlineContent slug='resources-for-teens-and-young-adults'/>
            <RecentStoriesForTeensBlock />
            <BottomInlineContent slug='resources-for-teens-and-young-adults' />
            <NewFooter />
        </section>
      
    );
  }
}

