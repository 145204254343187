import React, { Component } from 'react';
import AnnualReportMarineScienceOverview from './annualReport2021MarineScience-Overview';
import AnnualReportMarineScienceSpotlights from './annualReport2021MarineScience-Spotlights';
import AnnualReport2021ProgramLinks from '../annualReport2021ProgramLinks/AnnualReport2021-ProgramLinks';
import NewFooter from '../../../../pages-other/NewFooter';
import './annualReport2021MarineScience-Content.css';

export default class AnnualReportMarineScienceContent extends Component {
  render() {
    return(
      <div id="annualReport2021MarineScience-content-container">
        <AnnualReportMarineScienceOverview />
        <AnnualReportMarineScienceSpotlights />
        <AnnualReport2021ProgramLinks />
        <NewFooter />
      </div>
    );
  }
}